import React, { useEffect, useState, useMemo, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import useDynamicRefs from 'use-dynamic-refs';
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell, HiHeart, HiRefresh } from "react-icons/hi";
import Avatar from '../../components/Avatar';
import Lottie from "lottie-react";
import Tooltip from '../../components/Tooltip';
import Cookies from 'js-cookie'
import CountUp from 'react-countup';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from "../../components/ProgressProvider";
import 'react-circular-progressbar/dist/styles.css';
import { BsEyeglasses, BsFillPuzzleFill, BsHeartFill, BsLightningChargeFill, BsLightningFill } from 'react-icons/bs'
import { MdLightMode, MdOutlineFaceRetouchingNatural, MdCenterFocusWeak, MdOutlineArrowBack } from 'react-icons/md'
import { BiLaugh, BiMobileAlt, BiRefresh } from 'react-icons/bi'
import { RiFocus2Line } from 'react-icons/ri'
import { SlSocialYoutube } from 'react-icons/sl'
import { Tab } from '@headlessui/react';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, getFirestore, updateDoc, onSnapshot, setDoc, getDoc, getDocs, query, orderBy, doc, } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'
import logo from '../../../assets/logo_full_32.png';
import image_analyze from "../../../assets/animations/image_analyze.json";
import Hint from '../../components/Hint';
import FocusBoardCollageImages from '../../components/FocusBoardCollageImages';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { IoIosColorPalette, IoIosGlasses } from 'react-icons/io';
import { IoTabletPortraitOutline, IoThermometerOutline } from 'react-icons/io5';
import { ComputerDesktopIcon, TvIcon } from '@heroicons/react/24/outline';
import { GiTablet } from 'react-icons/gi';
import { FiTablet } from 'react-icons/fi';
import { FaLaptop, FaLightbulb, FaMobile, FaRegLightbulb } from 'react-icons/fa';
import { RxMobile } from 'react-icons/rx';


const DeviceMockups = () => {
    let { thumbnail_id } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [thumbnailData, setThumbnailData] = useState(null);
    const [thumbnailPosition, setThumbnailPosition] = useState(1);
    const [title, setTitle] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [deviceOption, setDeviceOption] = useState(1);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const savedUser = JSON.parse(localStorage.getItem("currentUser"));
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loadedMessage, setLoadedMessage] = useState('');
    const [mapQuantity, setMapQuantity] = useState(12);

    const goBack = () => {
        // Check if there is a page to go back to
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/thumbnail-analysis/upload');
        }
    };

    const handleDeviceOptions = (e) => {
        if (e === 1) {
            setThumbnailPosition(1)
            setMapQuantity(12);
            setDeviceOption(1);
        } else if (e === 2) {
            setThumbnailPosition(1)
            setMapQuantity(9);
            setDeviceOption(2);
        } else if (e === 3) {
            setThumbnailPosition(1)
            setMapQuantity(4);
            setDeviceOption(3);
        } else if (e === 4) {
            setThumbnailPosition(1)
            setMapQuantity(5);
            setDeviceOption(4);
        }
    }

    const changePostion = () => {

        const randomNumber = Math.floor(Math.random() * (mapQuantity - 1)) + 1;
        setThumbnailPosition(randomNumber)

        // toast("Changing position...", {
        //     icon: ({ theme, type }) => <HiRefresh className='text-green-500 h-3 w-3' />
        // });
    }

    const deviceWidthMapping = {
        1: 'w-full h-[620px]',
        2: 'lg:w-[760px] 2xl:w-[960px] h-[620px] max-w-[960px]',
        3: 'w-[370px] h-[620px]',
        4: 'lg:w-[890px] 2xl:w-[1080px] h-[65%] max-h-[620px]',
    }

    const devicePreviewAsset = {
        1: ' aspect-video rounded-lg w-full h-[162px]',
        2: ' aspect-video rounded-lg w-full h-[162px]',
        3: ' aspect-video w-full h-[205px]',
        4: ' aspect-video rounded-lg w-full h-[162px]',
    }

    const devicePreviewThumbnailSpacing = {
        1: 'mb-5 w-72 mx-2',
        2: 'mb-5 w-72 mx-2',
        3: 'w-full',
        4: 'mb-5 w-72 mx-2',
    }

    useEffect(() => {
        const getThumbnailData = async () => {
            console.log(thumbnail_id);

            const docRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnail_id);
            const testDocSnap = await getDoc(docRef);
            if (testDocSnap.exists()) {
                setThumbnail(testDocSnap.data().thumbnail);
                setTitle(testDocSnap.data().title);
            } else {
                // doc.data() will be undefined in this case
                toast("Unable to load thumbnail.", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });

                console.log("No such document!");
            }
        }

        getThumbnailData();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Analysis | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Analysis | ThumbnailGeek" />
            </Helmet>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_330px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-10 right-0 top-0 justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4 inline-flex">

                            <div className="flex flex-row justify-center items-center">
                                <div className="">
                                    <h5 hidden className={`text-xl text-gray-600 font-medium lg:block`}>Device Thumbnail Preview</h5>
                                </div>
                            </div>

                            <div className="hidden absolute right-0 lg:flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <GoToYoutubeBtn /> */}
                                <Avatar />
                            </div>
                        </div>

                        <div className={`mt-16 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8`}>

                            <div className="flex flex-row">

                                <div className="mb-12 w-full">

                                    <div className="w-full flex justify-between">
                                        <ul className="flex space-x-[5px] font-medium tracking-[-0.014em] items-center justify-left lg:mb-6 2xl:mb-10 w-full">
                                            <li className='w-32'>
                                                <button onClick={() => handleDeviceOptions(1)} className={`${deviceOption === 1 ? 'bg-gray-200' : ''} 
                                                    relative px-2 py-2 cursor-pointer flex items-center justify-center hover:cursor-pointer hover:bg-gray-200 text-blue-600 space-x-2 rounded-lg  w-full`}>
                                                    <FaLaptop className='h-5 w-5' />
                                                    <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Computer</span>
                                                </button>
                                            </li>
                                            {/* <li className='w-32'>
                                                <button onClick={() => handleDeviceOptions(2)} className={`${deviceOption === 2 ? 'bg-gray-200' : ''} 
                                                    relative px-2 py-2 cursor-pointer flex items-center justify-center hover:cursor-pointer hover:bg-gray-200 text-blue-600 space-x-2 rounded-lg  w-full`}>
                                                    <IoTabletPortraitOutline className='h-5 w-5 rotate-90' />
                                                    <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Tablet</span>
                                                </button>
                                            </li> */}
                                            <li className='w-32'>
                                                <button onClick={() => handleDeviceOptions(3)} className={`${deviceOption === 3 ? 'bg-gray-200' : ''} 
                                                    relative px-2 py-2 cursor-pointer flex items-center justify-center hover:cursor-pointer hover:bg-gray-200 text-blue-600 space-x-2 rounded-lg  w-full`}>
                                                    <RxMobile className='h-5 w-5' />
                                                    <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Mobile</span>
                                                </button>
                                            </li>
                                            <li className='w-32'>
                                                <button onClick={() => handleDeviceOptions(4)} className={`${deviceOption === 4 ? 'bg-gray-200' : ''} 
                                                    relative px-2 py-2 cursor-pointer flex items-center justify-center hover:cursor-pointer hover:bg-gray-200 text-blue-600 space-x-2 rounded-lg   w-full`}>
                                                    <TvIcon className='h-5 w-5' />
                                                    <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Television</span>
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="transition-all duration-500 ease-in-out flex flex-row space-x-2 h-8">

                                            <button
                                                onClick={() => changePostion()}
                                                type="button"
                                                className={`lg:w-[140px] inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                                        text-gray-800 hover:ring-gray-600 shadow-sm`}
                                            >
                                                <HiRefresh className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                                <span className="hidden lg:block">Change Position</span>
                                            </button>

                                            {darkMode === false ? (
                                                <>
                                                    <button
                                                        onClick={() => setDarkMode(!darkMode)}
                                                        type="button"
                                                        className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                                        text-gray-800 hover:ring-gray-600 shadow-sm`}
                                                    >
                                                        <FaLightbulb className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                                        <span className="hidden lg:block">Dark</span>
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        onClick={() => setDarkMode(!darkMode)}
                                                        type="button"
                                                        className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                                        text-gray-800 hover:ring-gray-600 shadow-sm`}
                                                    >
                                                        <FaRegLightbulb className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                                        <span className="hidden lg:block">Light</span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>


                                    <div className=" flex flex-col items-center w-full">
                                        <div className={`${deviceOption === 4 ? '' : ''} ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818]'}
                                         border-4  ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} rounded-lg pt-12 transition-all ease-in-out duration-500 flex flex-col items-center`}>
                                            <div className={`flex lg:mb-3 2xl:mb-5 ${deviceWidthMapping[deviceOption]}
                                                ${deviceOption !== 4 ? 'flex-wrap overflow-scroll justify-center' : 'flex-row overflow-scroll justify-left pl-5'} `}
                                            >
                                                {[...Array(mapQuantity)].map((_, index) => (
                                                    index === thumbnailPosition ? (
                                                        <div className={`text-gray-800 pb-5 text-[0.9rem] ${devicePreviewThumbnailSpacing[deviceOption]}`}>
                                                            <div className={`@apply inline-block overflow-hidden ${devicePreviewAsset[deviceOption]} cursor-pointer duration-200 `}>
                                                                <img className={`@apply inline-block w-full aspect-video`} src={thumbnail} alt="" />
                                                            </div>

                                                            <div className={`flex ${deviceOption === 3 ? 'mx-2 mt-1' : 'mt-2'}`}>
                                                                <div className={`shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover ${deviceOption === 4 ? 'hidden' : ''}`}>
                                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                                                </div>
                                                                <div className='flex-col w-9/12'>
                                                                    <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                        <p className={`${darkMode === true ? 'text-gray-400' : ''} font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{title}</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                                            <p className={`${darkMode === true ? 'text-gray-400' : ''}`}>{channelDisplayName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={`text-gray-800 pb-5 text-[0.9rem] ${devicePreviewThumbnailSpacing[deviceOption]}`}>
                                                            <div className={`@apply inline-block overflow-hidden ${devicePreviewAsset[deviceOption]} cursor-pointer duration-200 ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'}`}>
                                                            </div>

                                                            <div className={`flex ${deviceOption === 3 ? 'mx-2 mt-1' : 'mt-2'}`}>
                                                                <div className={`shrink-0 mx-0 my-0 md:mr-2 xl:mr-1 h-9 w-9 object-cover ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'} rounded-full ${deviceOption === 4 ? 'hidden mr-2' : 'mr-4'}`}>
                                                                </div>
                                                                <div className='flex-col w-9/12'>
                                                                    <div className={`flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'} w-48 h-5 rounded-[4px]`}>
                                                                    </div>
                                                                    <div className={`inline-flex text-[12px] font-roboto lg:text-[13px] ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'} w-24 h-5 mt-1.5 rounded-[4px]`}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>

                                            {
                                                deviceOption === 4 &&
                                                <div className={`flex flex-col justify-left`}>
                                                    <h3 className="ml-7 font-semibold text-sm mb-2 text-gray-400">Continue watching</h3>
                                                    <div className={`flex flex-row overflow-scroll justify-left pl-5 ${deviceWidthMapping[deviceOption]}`}>
                                                        {[...Array(mapQuantity)].map((_, index) => (
                                                            <div className={`text-gray-800 pb-5 text-[0.9rem] ${devicePreviewThumbnailSpacing[deviceOption]}`}>
                                                                <div className={`@apply inline-block overflow-hidden ${devicePreviewAsset[deviceOption]} cursor-pointer duration-200 ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'}`}>
                                                                </div>

                                                                <div className={`flex ${deviceOption === 3 ? 'mx-2 mt-1' : 'mt-2'}`}>
                                                                    <div className='flex-col w-9/12'>
                                                                        <div className={`flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'} w-48 h-5 rounded-[4px]`}>
                                                                        </div>
                                                                        <div className={`inline-flex text-[12px] font-roboto lg:text-[13px] ${darkMode === false ? 'bg-gray-200' : 'bg-[#232323]'} w-24 h-5 mt-1.5 rounded-[4px]`}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceMockups



