import React from 'react';
import { TfiTrash } from 'react-icons/tfi';
import { CgBatteryEmpty } from 'react-icons/cg';
import SplitCollage from './SplitCollage'; // Import the SplitCollage component

const StoryboardsList = ({
    storyboards,
    handleLoadSavedStoryboard,
    openDeleteModal,
    loadingStoryboards
}) => {
    return (
        <div className="w-full rounded flex flex-wrap gap-x-4 gap-y-1">
            {
                storyboards?.length > 0
                    ? (
                        <>
                            {storyboards.map((storyboardDoc, i) => {
                                if (storyboardDoc.storyboards) {
                                    const itemsData = Object.entries(storyboardDoc.storyboards).map(([key, storyboard]) => ({
                                        thumbnail: storyboard.image_url_local_resized ?? storyboard.image_url,
                                    }));
                                    return (
                                        <div key={storyboardDoc.id} className="w-48 h-auto relative mb-5 mr-2">
                                            <div onClick={() => handleLoadSavedStoryboard(storyboardDoc.id, storyboardDoc.title)} className="border border-gray-300 hover:ring-1 hover:ring-blue-500 duration-200 rounded-md w-48 h-auto cursor-pointer overflow-hidden group">
                                                <SplitCollage itemsData={itemsData} />
                                                <div className="rounded-md absolute top-0 left-0 w-48 h-28 bg-black opacity-0 group-hover:opacity-10 group-active:opacity-20 duration-200"></div>
                                            </div>
                                            <div className="w-full inline-flex">
                                                <div onClick={() => handleLoadSavedStoryboard(storyboardDoc.id, storyboardDoc.title)} className="w-10/12 group">
                                                    <div className="m-0 p-0 mt-3 pl-1 w-full line-clamp-1 cursor-pointer leading-tight lg:text-[14px] md:text-md font-medium">
                                                        {storyboardDoc.title}
                                                    </div>
                                                </div>
                                                <div onClick={() => openDeleteModal(storyboardDoc.title, storyboardDoc.id)} className="w-2/12 m-1 mr-0 p-2 hover:bg-neutral-300 rounded-lg h-8 hover:cursor-pointer">
                                                    <TfiTrash />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </>
                    )
                    : (
                        loadingStoryboards === true
                            ? (
                                <div className="w-full flex flex-col items-center justify-center text-gray-400 py-8">
                                    <div className="flex flex-col items-center justify-center mb-3">
                                        <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                            <path
                                                className="fill-gray-300"
                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                            <path
                                                className="fill-gray-500"
                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                        </svg>
                                    </div>
                                    <p className="text-xs">Loading ideas...</p>
                                </div>
                            )
                            : (
                                <div className="w-full flex flex-col items-center justify-center text-gray-400 py-8">
                                    <CgBatteryEmpty className='h-8 w-8 mt-[2px] mr-2 text-gray-600' />
                                    {/* <p className="text-xs">No ideas saved</p> */}
                                    <h3 className="mt-2 text-sm font-semibold text-gray-600">No ideas saved yet</h3>
                                    <p className="mt-1 text-sm text-gray-500">Get started by typing a in video idea.</p>

                                </div>
                            )
                    )
            }
        </div>
    );
}

export default StoryboardsList;