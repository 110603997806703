import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getFunctions, httpsCallable } from "firebase/functions";
import ratingGirl from '../../assets/yt-rating-girl.png';
import { HiOutlineBell, HiSparkles } from 'react-icons/hi';
import { toast } from 'react-toastify';
import DotDotDot from './DotDotDot';
import Hint from './Hint';
import { PremiumCredentials } from '../../context/PremiumContext';
import { db, storage } from '../../firebase-config';
import useDynamicRefs from 'use-dynamic-refs';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit, onSnapshot, increment } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "@firebase/storage";
import Lottie from "lottie-react";
import image_upload from "../../assets/animations/image_uploader.json";
import Tooltip from './Tooltip';
import { Tab } from '@headlessui/react';

const AddThumbnailsDialog = ({ isVisible, onClose, numberOfExistingThumbs, testId, refresh }) => {

    const mainUserId = localStorage.getItem("mainUserId");
    const [getRef, setRef] = useDynamicRefs();
    const navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked } = PremiumCredentials();
    const [processing, setProcessing] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [titles, setTitles] = useState([]);
    const [thumbsLoaded, setThumbsLoaded] = useState(false)
    const [thumbnailsData, setThumbnailsData] = useState(null)
    const [popupBlocked, setPopupBlocked] = useState(false)
    const [blockedURL, setBlockedURL] = useState('')
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [imagesActive, setImagesActive] = useState(false)
    const [allInputsHaveText, setAllInputsHaveText] = useState(false);
    const [percent, setPercent] = useState(0);
    const [splitTestId, setSplitTestId] = useState(null);

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png, image/gif',
        maxFiles: 12,
        maxSize: 2 * 1024 * 1024, // 2 MB in bytes
        onDrop: acceptedFiles => {
            // if (accessLevel === 1 || accessLevel === 2) {
            if (fileRejections.length === 0) {
                setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setImagesActive(true);
            } else {
                // Handle file rejection error if needed
                console.log('File rejected:', fileRejections);
            }
            // } else {
            //     setBlockPopup(true);
            // }
        }
    });

    const handleInputFilled = (e) => {
        const numberOfInputs = selectedImages.length;
        if (e.target.value.trim() === '') {
            setAllInputsHaveText(false);
        } else {
            const numberOfFilledInputs = titles.filter((item) => item.trim() !== "").length;
            if (numberOfInputs === numberOfFilledInputs) {
                setAllInputsHaveText(true);
                console.log('filled');
            } else {
                setAllInputsHaveText(false);
                console.log('not filled');
            }
        }
    }

    const thumbs = selectedImages.map((file, i) => (
        <div key={i} className={`relative text-gray-800 w-60 mt-5 pb-3 text-[0.9rem] mb-5 group`}>
            <div className="mb-2 cursor-pointer">
                <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                    <img className="aspect-video @apply inline-block mx-0 w-full rounded-lg" src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="" />
                    <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                    </div>
                </div>
            </div>

            <div className='flex'>
                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-8 w-8 object-cover">
                    <img className='rounded-full md:h-8 md:w-8 xl:h-7 xl:w-7' src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                </div>
                <div className='flex-col w-10/12'>
                    <div className="cursor-pointer flex flex-col mb-[0.1rem] pr-1 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                        <input
                            id={`title-${i}`}
                            onChange={(e) => {
                                const newTitles = [...titles];
                                newTitles[i] = e.target.value;
                                setTitles(newTitles);
                                handleInputFilled(e);
                            }}
                            rows="2"
                            placeholder='Type a compelling title here'
                            className='text-[12px] w-full p-1 pb-2 flex-1 px-0 font-semibold outline-none border-b border-gray-200 h-full line bg-transparent' />
                    </div>
                </div>
            </div>
        </div>

    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div className="absolute z-50 right-5 top-20 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
            <div className="flex items-center justify-center w-16 bg-red-500">
                <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
            </div>

            <div className="px-4 py-2 -mx-3 text-white">
                <div className="mx-3 text-white">
                    <p className="text-sm text-white dark:text-gray-600">Wrong image format</p>
                    <li className="text-white" key={file.path}>
                        {file.path}
                    </li>
                    <ul>
                        {errors.map(e => <li key={e.code}>{e.message}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    ));

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const scaleFactor = 720 / img.width;
                    const canvas = document.createElement("canvas");
                    canvas.width = 720;
                    canvas.height = img.height * scaleFactor;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        blob.lastModifiedDate = new Date();
                        blob.name = file.name;
                        const newFile = new File([blob], file.name, { type: "image/png" });
                        const initialWidth = img.width;
                        const initialHeight = img.height;
                        resolve({ file: newFile, initialWidth, initialHeight });
                    }, "image/png", 0.9);
                };
            };
        });
    };

    const uploadClicked = () => {
        setUploading(true);
        uploadImages();
    }

    const uploadImages = async () => {

        await Promise.all(
            selectedImages.map(async (image, i) => {
                const title = titles[i];
                console.log(title);

                const { file: resizedImage, initialWidth, initialHeight } = await resizeImage(
                    image
                );

                const docRef = doc(db, "Users", mainUserId);
                const colRef = collection(docRef, "UserThumbnails");
                const newImgResp = await addDoc(colRef, {
                    user_id: mainUserId,
                    test_type: "Main",
                    date_created: serverTimestamp(),
                });
                const extension = resizedImage.type.split("/").pop();
                const imageRef = ref(
                    storage,
                    `/userThumbnails/${mainUserId}/${newImgResp.id}.${extension}`
                );
                const uploadTask = uploadBytesResumable(imageRef, resizedImage, {
                    contentType: "data_url",
                });
                uploadTask.on("state_changed", (snapshot) => {
                    const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const newPercent = Math.round((i + percent) / selectedImages.length);
                    setPercent(newPercent);
                });
                await uploadTask;
                const downloadURL = await getDownloadURL(imageRef);
                const userDoc = doc(
                    db,
                    "Users",
                    mainUserId,
                    "UserThumbnails",
                    newImgResp.id
                );
                const data = {
                    thumbnail: downloadURL,
                    title: title,
                    resolution_width: initialWidth,
                    resolution_height: initialHeight,
                };
                setDoc(userDoc, data, { merge: true });
                updateDoc(
                    doc(db, "Users", mainUserId, "SplitTests", testId),
                    {
                        items: arrayUnion({
                            thumbnail_id: newImgResp.id,
                            thumbnail: downloadURL,
                            title: title,
                        }),
                    }
                )
            })
        )
            .then(async () => {
                updateDocument(mainUserId, testId);
                setSplitTestId(testId);
                await waitForItemsToUpload(testId);
            })
            .catch((e) => {
                console.log(e);
                setUploading(false);
                toast("An error occured. Unable to upload images!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            });

    };

    async function waitForItemsToUpload(id) {
        setPercent(100);
        setProcessing(true);
        const splitTestRef = doc(db, 'Users', mainUserId, 'SplitTests', id);
        return new Promise((resolve) => {
            const unsubscribe = onSnapshot(splitTestRef, (doc) => {
                const items = doc.data()?.items || [];
                console.log('almost done uploading');

                if (items.length === (selectedImages.length + numberOfExistingThumbs)) {
                    console.log('Done!!!!');
                    unsubscribe();
                    resolve();
                    modalCloseRefresh();
                    // // Redirect to the next page
                    // let path = `/testing-suite/compare/${id}`;
                    // navigate(path);
                    // setUploading(false);
                }
            });
        });
    }

    async function addThumbnailsToSplitTest() {
        await Promise.all(
            selectedItems.map(async (image) => {
                console.log('Doing...');
                await updateDoc(
                    doc(db, "Users", mainUserId, "SplitTests", testId),
                    {
                        items: arrayUnion({
                            thumbnail_id: image.id,
                            thumbnail: image.thumbnail,
                            title: image.title,
                        }),
                    }
                );
            })
        ).then(async () => {
            console.log('Done.');
            modalCloseRefresh();
        })
    }

    const updateDocument = async (user_id, doc_id) => {
        await updateDoc(doc(db, "Users", user_id), {
            currentTestId: doc_id,
        })
    }

    const openPopup = (url) => {
        const newWindow = window.open(url, "_blank");
        if (newWindow && newWindow.focus) {
            newWindow.focus();
        } else {
            setPopupBlocked(true);
            setBlockedURL(url);
            alert("Popup blocked! Please allow popups for this website.");
        }
    };

    const handleModalClose = (e) => {
        if (!uploading && !processing) {
            if (e.target.id === "thumbnail-upload-modal") {
                setSelectedImages([]);
                setProcessing(false);
                setUploading(false);
                onClose();
            }
        }
    }

    const modalClose = (e) => {
        setSelectedImages([]);
        setProcessing(false);
        setUploading(false);
        onClose();
    }

    const modalCloseRefresh = (e) => {
        setSelectedImages([]);
        setProcessing(false);
        setUploading(false);
        refresh();
    }

    const handleImageCheckbox = (id, title, thumbnail) => {
        // Check if the item is already selected
        const isSelected = selectedItems.some(item => item.id === id);

        if (isSelected) {
            // If already selected, remove it from the selectedItems array
            setSelectedItems(prevItems => prevItems.filter(item => item.id !== id));
            console.log('Selected already. Removing');
        } else {
            // If not selected, add it to the selectedItems array
            setSelectedItems(prevItems => [...prevItems, { id, title, thumbnail }]);
            console.log('Not Selected. Adding');
        }
    };

    useEffect(() => {
        setSelectedImages([]);
    }, [selectedIndex]);

    useEffect(() => {
        loadThumbnails();
    }, []);

    const loadThumbnails = async (e) => {
        const colRef = collection(db, 'Users', mainUserId, 'UserThumbnails');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            endBefore(null),
            limit(6)
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                setThumbnailsData(newData);
                setThumbsLoaded(true);
            })
    }

    if (!isVisible) return null;

    return (
        <>
            <div onClick={handleModalClose} id="thumbnail-upload-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full 
            bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-3xl md:h-auto mx-0">

                    <div className="relative w-full h-full min-h-[350px] max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className='relative border-gray-200 text-gray-800 border-b text-sm p-1 pb-0'>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className={`inline-flex p-3`}>
                                        <p className='hidden lg:block'>Upload More Thumbnails</p>
                                    </span>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className="inline-flex p-3">
                                        <p className='hidden lg:block'>Select From Existing Thumbnails</p>
                                    </span>
                                </Tab>
                                <button onClick={modalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </Tab.List>
                            <Tab.Panels className='w-full'>

                                <Tab.Panel className='w-full md:h-auto min-h-[245px]'>
                                    {
                                        uploading === false
                                            ?
                                            <>
                                                <div className="">
                                                    <div className="w-full h-full justify-left lg:flex-none rounded-lg">
                                                        <div className="w-full relative rounded-md mb-2">

                                                            {
                                                                selectedImages.length !== 0 ? (
                                                                    <div className="p-4">
                                                                        <div className='flex flex-wrap w-full max-h-[400px] overflow-scroll justify-center space-x-2'>
                                                                            {thumbs}
                                                                            {fileRejectionItems}
                                                                        </div>

                                                                        <div className="w-full flex flex-row justify-between border-t">
                                                                            <div className="pt-4 pb-1 w-[300px]">
                                                                                <Hint hintText={'Hit TAB to move to the next text field when filling out your titles'} fontSizePx={'12px'} />
                                                                            </div>
                                                                            <>
                                                                                {
                                                                                    allInputsHaveText === true
                                                                                        ?
                                                                                        <div className='flex justify-center items-center'>
                                                                                            <button onClick={uploadClicked} className='bg-slate-700 w-64 bg-gradient-to-r 
                                                                    from-blue-600 to-[#FC2959] opacity-90 hover:opacity-100 transition-colors 
                                                                    hover:duration-1200 text-white p-3 rounded-lg'>
                                                                                                Upload & Compare
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        <div className='flex justify-center items-center'>
                                                                                            <button disabled className='cursor-wait bg-gray-200 text-gray-400 w-64 p-3 rounded-lg'>
                                                                                                Upload & Compare
                                                                                            </button>
                                                                                        </div>
                                                                                }
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='flex flex-col justify-center items-center w-full text-sm p-4 pb-6'>
                                                                        <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-[245px] mb-4 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer hover:bg-gray-100 " {...getRootProps()}>
                                                                            <div className="flex flex-col justify-center items-center pt-5 pb-5 px-5 text-sm ">
                                                                                <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                                                </svg>
                                                                                <>
                                                                                    <span>Drag and  Drop thumbnails here</span>
                                                                                    <span className="mt-1 text-gray-400">or</span>
                                                                                    <button className="bg-blue-500 rounded-lg p-2 px-3 mt-2 text-white">Browse Files</button>
                                                                                </>

                                                                            </div>
                                                                            <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                                                                        </label>
                                                                        <span>JPG, GIF, or PNG (1280x720)</span>
                                                                        <span className="text-red-500 font-bold">• 2MB Limit for each •</span>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="flex flex-col items-center justify-center pb-16">
                                                {uploading && !processing &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <p className='font-bold text-2xl text-gray-600'>{percent}% done</p>
                                                    </>

                                                }
                                                {uploading && processing &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <p className='font-semibold text-xl text-gray-600'>Finishing...</p>
                                                    </>
                                                }
                                            </div>
                                    }
                                </Tab.Panel>

                                <Tab.Panel className='w-full h-full'>
                                    <div className="">

                                        <div className="w-full h-full justify-left lg:flex-none rounded-l-lg px-4 border-r mt-2">
                                            <div className="w-full h-full rounded-md mb-2 overflow-y-hidden duration-200">
                                                {
                                                    thumbsLoaded === false
                                                        ?
                                                        <div className="w-full h-full text-sm grid place-items-center">
                                                            Loading thumbnails...
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="grid grid-cols-3 gap-1 pb-3">
                                                                {
                                                                    thumbnailsData.length === 0
                                                                        ?
                                                                        <div className="w-full h-full text-sm grid place-items-center">
                                                                            No thumbnails uploaded!
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {thumbnailsData?.map((item, i) => (
                                                                                <div key={i} className='inline-block col-span-1'>
                                                                                    <div className="w-full">
                                                                                        <input id={i} type="checkbox" onChange={() => handleImageCheckbox(item.id, item.title, item.thumbnail)} value="" name="image-checkbox" className="peer hidden" />
                                                                                        <label htmlFor={i} className="flex cursor-pointer justify-center items-center h-full w-full p-1 peer-checked:border peer-checked:rounded-md peer-checked:border-blue-500">
                                                                                            {/* <img className="inline-block w-full rounded-md" src={item.thumbnail} alt="image-thumbnail" /> */}
                                                                                            <div className='hover:cursor-pointer text-gray-800 w-60 2xl:w-72 text-[0.8rem] m-2'>
                                                                                                <div className="mb-2 relative group">
                                                                                                    <div className="@apply inline-block overflow-hidden w-full h-[80%] duration-200 ">
                                                                                                        <img className="@apply inline-block mx-0 w-full rounded-lg" src={item.thumbnail} alt="" />
                                                                                                    </div>
                                                                                                    <div className="rounded-lg absolute top-0 left-0 w-full h-[98%]">
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='flex'>
                                                                                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-8 w-8 object-cover">
                                                                                                        <img className='rounded-full md:h-8 md:w-8 xl:h-7 xl:w-7' src={channelPhotoUrl} alt="" />
                                                                                                    </div>
                                                                                                    <div className='flex-col w-9/12 h-12'>
                                                                                                        <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                                            <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[13px] mb:text-mb'>{item.title}</p>
                                                                                                        </div>
                                                                                                        <div className=''>
                                                                                                            <div className='inline-flex text-[11px] font-roboto lg:text-[13px]'>
                                                                                                                <p className='opacity-50'>{channelDisplayName}
                                                                                                                    {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="border-t py-3 w-full">
                                                                {
                                                                    selectedItems.length > 0
                                                                        ?
                                                                        <div className='flex justify-center items-center'>
                                                                            <button onClick={addThumbnailsToSplitTest} className='bg-slate-700 w-64 bg-gradient-to-r 
                                                                                from-blue-600 to-[#FC2959] opacity-90 hover:opacity-100 transition-colors 
                                                                                hover:duration-1200 text-white p-3 rounded-lg'>
                                                                                Add & Compare
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <div className='flex justify-center items-center'>
                                                                            <button disabled className='cursor-wait bg-gray-200 text-gray-400 w-64 p-3 rounded-lg'>
                                                                                Add & Compare
                                                                            </button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </Tab.Panel>

                            </Tab.Panels>
                        </Tab.Group>
                    </div>

                </div>
            </div>
        </>
    );
};

export default AddThumbnailsDialog;

