import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

//Accordion section component
const AccordionSection = ({ id, title, desc, isOpen, onToggle, children }) => {
    return (
        <div className="border-b">
            <button
                className="flex justify-between items-center rounded-md py-3 px-5 hover:bg-gray-100 active:bg-gray-100 w-full focus:outline-none"
                onClick={() => onToggle(id)}
            >
                <div className="flex flex-col items-start">
                    <h2 className="font-medium">{title}</h2>
                    <p className="font-normal text-[13px] text-gray-600">{desc}</p>
                </div>

                <span className="font-semibold">
                    {isOpen ? <SlArrowUp className='w-3 h-3' /> : <SlArrowDown className='w-3 h-3' />}
                </span>
            </button>
            {isOpen && <div className="p-5 border-gray-200">{children}</div>}
        </div>
    );
};

//Modal component
const TutorialItems = ({ isOpen, onClose, initialOpenItemId }) => {

    const [openSectionId, setOpenSectionId] = useState(initialOpenItemId || null);
    //const [openSectionId, setOpenSectionId] = useState(null);

    const handleToggle = (id) => {
        if (openSectionId === id) {
            setOpenSectionId(null); //Close the section if it's already open
        } else {
            setOpenSectionId(id); //Open the clicked section and close others
        }
    };

    if (!isOpen) return null;

    const sectionData = [
        { id: 1, title: "Getting Started/Installing the Extension", desc: "Learn how to get started with ThumbnailGeek", videoPath: "/videos/InstallExtensionVideohb.mp4" },
        { id: 2, title: "Visualize your Thumbnail on Youtube", desc: "Learn how to get started with ThumbnailGeek", videoPath: "/videos/TestThumbnailVideohb.mp4" },
        { id: 3, title: "Improve Your Titles with AI", desc: "Learn how to improve your titles", videoPath: "/videos/GetTitleshb.mp4" },
        { id: 4, title: "Generate Thumbnail Ideas with AI", desc: "Learn how to generate ideas for your thumbnails", videoPath: "/videos/Thgk_storyboards_preview1.mp4" },
        { id: 5, title: "Describe Thumbnail Ideas with AI", desc: "Learn how to get thumbnail ideas", videoPath: "/videos/GetIdeasVideohb.mp4" },
        { id: 6, title: "Analyze your thumbnail with AI", desc: "Learn how to analyze your thumbnail", videoPath: "/videos/AnalysisVideohb.mp4" },
        { id: 7, title: "Viral Library", desc: "Learn how viral library works", videoPath: "/videos/ViralLibVideohb.mp4" },
        { id: 8, title: "Collect Thumbnaiils From YouTube", desc: "Learn how to collect thumbnails from youtube", videoPath: "/videos/BucketVideohb.mp4" },
    ];

    const handleModalClose = (e) => {
        if (e.target.id === "tutorials-modal") {
            onClose();
        };
    }

    return (
        <div id="tutorials-modal" onClick={handleModalClose} className="fixed inset-0 bg-gray-700 bg-opacity-75 z-[34895374] flex justify-center items-center">
            <div className="relative bg-white rounded-lg max-w-4xl w-full">
                <h2 className='my-4 px-6 font-medium text-lg'>Video Tutorials</h2>
                <button className="absolute top-0 right-0 m-2 text-2xl font-bold hover:bg-gray-200 active:bg-gray-300 rounded-lg p-1" onClick={onClose}>
                    <IoCloseOutline />
                </button>

                <div className="overflow-auto max-h-[700px] px-8 pb-8">
                    {sectionData.map(({ id, title, desc, videoPath }) => (
                        <AccordionSection
                            key={id}
                            id={id}
                            title={title}
                            desc={desc}
                            isOpen={openSectionId === id}
                            onToggle={handleToggle}
                        >
                            <video width="100%" controls autoPlay loop className='rounded-lg ring-1 ring-gray-500'>
                                <source src={videoPath} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionSection>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TutorialItems;
