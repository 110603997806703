import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { getFunctions, httpsCallable } from "firebase/functions";
import fireEmbers from '../../assets/yt-fire-embers.jpg';
import { AiFillAccountBook, AiFillTool } from 'react-icons/ai';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { HiSparkles } from 'react-icons/hi';
import { IoSparklesOutline, IoSparklesSharp } from 'react-icons/io5';
import { MdCenterFocusStrong } from 'react-icons/md';
import { SlClose, SlMagicWand } from 'react-icons/sl';
import DotDotDot from './DotDotDot';
import { BiRadar, BiTestTube } from 'react-icons/bi';
import { TbBucket, TbChartArea, TbChartBar, TbChartDonut, TbChartRadar } from 'react-icons/tb';
import { GiftIcon } from '@heroicons/react/24/outline';
import { GiTeePipe } from 'react-icons/gi';

const CreditsPopupStanProTrial = ({ isVisible, onClose, pageUrl }) => {
    let navigate = useNavigate()
    const mainUserId = localStorage.getItem("mainUserId");
    const [redirecting, setRedirecting] = useState(false);

    // const redirectToStripe = async () => {
    //     setRedirecting(true);
    //     const functions = getFunctions();
    //     const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    //     addMessage({
    //         returnUrl: window.location.origin + pageUrl,
    //         locale: 'auto',
    //         // configuration: 'bpc_1JSEAKHYgolSBA358VNoc2Hs',
    //     })
    //         .then((result) => {
    //             const data = result.data;
    //             setRedirecting(false);

    //             if (data.url) {
    //                 window.open(data.url, '_blank');
    //             }
    //         })
    //         .catch((error) => {
    //             // Getting the Error details.
    //             const code = error.code;
    //             const message = error.message;
    //             const details = error.details;
    //             // ...
    //             console.error(code);
    //             console.error(message);
    //             console.error(details);

    //             setRedirecting(false);
    //             window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ', '_blank');
    //         });

    // }

    const redirectToPayment = async () => {
        navigate('/pricing');
    }

    const modalClose = (e) => {
        if (e.target.id === "this-modal") {
            onClose();
        } else {
            onClose();
        }
    }

    if (!isVisible) return null;

    return (
        <>
            <div id="this-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[86875] w-full bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-4xl md:h-auto mx-0">
                    <div className="grid grid-cols-8 relative w-full h-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">

                        <button onClick={modalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Left */}
                        <div className="col-span-4 pt-4 h-full justify-left lg:flex-none rounded-l-lg bg-white">
                            <div className="pb-6 pt-3 px-6">
                                <h2 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900">
                                    You've run out of trial credits.
                                </h2>
                                <p className="text-[15px] mt-1 text-left font-medium  text-gray-600">
                                    Recharge your account with credits to continue.
                                </p>
                                <h4 className="text-sm font-bold tracking-tight mt-5 text-gray-900 sm:text-sm">
                                    Experience success on Youtube...
                                </h4>
                                <ul className="mt-4 space-y-2 font-normal text-[12px] text-gray-800">

                                    <li className="inline-flex">
                                        <BiRadar className="text-xl mx-2" />
                                        <span>Analyze your thumbnails using advanced AI and viral psychology to help your videos go viral.</span>
                                    </li>
                                    <li className="inline-flex">
                                        <IoSparklesSharp className="text-lg mx-2" />
                                        <span>Explore the Viral Library with hundreds of thumbnails for inspiration and ideas.</span>
                                    </li>
                                    <li className="inline-flex">
                                        <GiTeePipe className="text-xl mx-2" />
                                        <span>Generate titles and thumbnail ideas that actually get people to click.</span>
                                    </li>
                                    <li className="inline-flex">
                                        <BiTestTube className="text-lg mx-2" />
                                        <span>Test your thumbnail on the Youtube Homepage & Search page before you post.</span>
                                    </li>
                                    <li className="inline-flex">
                                        <GiftIcon className="w-3 mx-2" />
                                        <span>Lots more useful tools & premium downloads.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="px-6 mt-2 w-full flex flex-col items-center justify-center pb-8 space-x-2 border-gray-200 rounded-b dark:border-gray-600">

                                <button onClick={redirectToPayment} className="w-full h-11 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    Recharge Your Account
                                </button>
                                {/* <p className='text-xs mt-2'>No subscriptions. Pay once, not monthly.</p> */}
                            </div>
                        </div>

                        {/* Right */}
                        <div className="col-span-4 lg:block flex items-center justify-center h-full">
                            <img src={fireEmbers} alt='image' className='w-full h-full object-cover rounded-tr-lg rounded-br-lg  transition-opacity duration-1000' />
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default CreditsPopupStanProTrial;
