import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player/youtube';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { UserAuth } from '../../../context/AuthContext'
import { db, storage } from '../../../firebase-config';
import { setDoc, doc, } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import logo from '../../../assets/logo_full_32.png';
import SideBar from '../../components/SideBar'
import testing from '../../../assets/svgs/testing.svg';
import FocusBoardCollageImages from '../../components/FocusBoardCollageImages';
import Avatar from '../../components/Avatar';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';

const YoutubeTest = () => {
    let navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const mainUserId = localStorage.getItem("mainUserId");
    const [extensionModal, setExtensionModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [boards, setBoards] = useState([]);
    const [helpModal, setHelpModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { pathname } = useLocation();
    const savedUser = JSON.parse(localStorage.getItem("currentUser"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleShowHelpModal = () => {
        setHelpModal(true);
    }

    const handleHelpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setSelectedIndex(0);
            setHelpModal(false);
        }
    }

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const handleSearchModalClose = (e) => {
        if (e.target.id === "search-page-modal") {
            setSearchModal(false);
        };
    }

    const searchModalClose = (e) => {
        setSearchModal(false);
    }

    const goToYoutubeHomepage = () => {
        // Attempt to set a test cookie
        document.cookie = 'test_cookie=1';
        // Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            const url = "https://www.youtube.com";
            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                //Set Page Intent
                const intent = 'OPENTESTER';
                const userDoc = doc(db, "Users", savedUser?.uid)
                const data = {
                    page_intent: intent,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
                    window.open(url, '_blank');
                    extensionModalClose();
                }).catch((e) => {
                    toast("Unable to verify connection.", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                });

            } else {
                setExtensionModal(true);
            }

        } else {
            // Cookies are disabled
            // alert('Cookies are disabled in your browser. Please enable cookies to use this feature.');
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const openSearchModal = () => {
        // Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        // Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                setSearchModal(true);
            } else {
                setExtensionModal(true);
            }
        } else {
            // Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const handleSearchQuerySubmit = async (e) => {
        e.preventDefault();
        const url = `https://www.youtube.com/results?search_query=${searchQuery}`;

        //Set Page Intent
        const intent = "OPENTESTER";
        const userDoc = doc(db, "Users", savedUser?.uid)
        const data = {
            page_intent: intent,
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
            window.open(url, '_blank');
            searchModalClose();
        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const goToHelp = (id) => {
        let path = `/tutorials/details/`;
        navigate(path);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Test Your Thumbnail On Youtube</title>
                <meta name="description" content="Settings of Thumbnail Geek" />
            </Helmet>
            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-light leading-relaxed text-gray-700 dark:text-gray-600">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or disabled the extension in your browser.
                            </p>
                            <div className="inline-flex text-[15px] font-light leading-relaxed text-gray-700 dark:text-gray-600">
                                <MdOutlineTipsAndUpdates className='text-[32px] text-blue-700 pt-1 mr-2 w-7' /> <span>ThumbnailGeek Chrome Extension helps you visualize your thumbnail on the Youtube homepage.</span>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Install Extension</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="search-page-modal" onClick={handleSearchModalClose} className={`${searchModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                What Niche Will You Like To Search?
                            </h3>
                            <button onClick={searchModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form onSubmit={handleSearchQuerySubmit}>
                            <div className="p-6 space-y-6">
                                <div>
                                    <label htmlFor="search_query" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Query</label>
                                    <input onChange={(e) => setSearchQuery(e.target.value)} type="text" id="search_query" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="eg. Food" required />
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Go</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg max-w-6xl border border-gray-400 shadow-md">
                    <div className="mx-auto mt-2">
                        <h3 className="text-base text-center font-semibold leading-6 text-gray-900">Here's how it works</h3>
                        {/* <p className="mt-1 text-center text-sm text-gray-500">Things to look out for, to choose your best thumbnail</p> */}
                    </div>
                    <div className="mt-2">
                        <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                className="rounded-lg overflow-hidden"
                                width="853px"
                                height="505px"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="youtube-test" darkMode={false} />

                <div className="ml-[55px] lg:ml-[280px] mb-6 w-[calc(100%)] lg:w-[calc(100%_-_55px)] lg:w-[calc(100%_-_280px)]">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-20 right-0 top-0 justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4 inline-flex">
                            <div className="">
                                <h5 className={`font-lora text-sm lg:text-xl text-gray-600 font-medium lg:flex`}>Test Your Thumbnail on Youtube before you upload</h5>
                            </div>

                            <div className="hidden absolute right-0 lg:flex items-center pr-2 sm:static sm:pr-0">
                                {/* <!-- Profile dropdown --> */}
                                <GoToYoutubeBtn />
                                <Avatar />
                            </div>
                        </div>

                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="w-full h-full mx-auto">

                                <div className="grid place-items-center h-screen">
                                    <div className="flex flex-col items-center justify-center ">
                                        <div className="w-52 p-6 bg-blue-100 rounded-full">
                                            <img src={testing} alt="" />
                                        </div>
                                        <div className=" mt-7">
                                            <div className="font-medium text-gray-600 text-lg text-center">
                                                Compare your thumbnail with others<br />on the Youtube Homepage!
                                            </div>
                                            <div className="font-normal text-gray-500 text-sm text-center mt-5 flex flex-col">
                                                <span>Clearly see how your thumbnail will look like, <br />how it compares to others on youtube, evaluate and make your decisions.</span>
                                                <span onClick={() => { handleShowHelpModal() }} className="text-blue-500 hover:cursor-pointer mt-3 text-[12px]"> How does it work?</span>
                                            </div>
                                        </div>

                                        <div className="mt-5 lg:space-x-3 lg:space-y-0 space-y-3 grid place-items-center lg:flex ">
                                            <button onClick={() => { goToYoutubeHomepage() }} className="bg-[#f12c5a] text-white p-2 px-8 rounded-lg text-base hover:bg-[#dd204c] active:bg-[#c81841] shadow">Test on Homepage</button>
                                            <button onClick={() => { openSearchModal() }} className="bg-blue-600 text-white p-2 px-8 rounded-lg text-base hover:bg-blue-700 active:bg-blue-800 shadow">Test on Search Page</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default YoutubeTest