import React, { useRef, useEffect } from 'react';
import thumbnail_onboarding_gif from '../../assets/gifs/Thumbnail_storyboards_preview.gif';

const VideoPopupModal = ({ isOpen, setIsOpen }) => {
  const videoRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  const closeModal = () => {
    setIsOpen(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="bg-white rounded-lg p-4 max-w-4xl w-full">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            Close
          </button>
        </div>
        <div className="aspect-w-16 aspect-h-9 mt-2">
          {/* <video
            ref={videoRef}
            src="/videos/THGKStBds.mp4"
            controls
            muted
            className="w-full h-full rounded-lg"
          > */}
          <img
            src={thumbnail_onboarding_gif}
            alt="image"
            className="w-full rounded-t-lg transition-opacity duration-1000"
          />

        </div>
      </div>
    </div>
  );
};

export default VideoPopupModal;