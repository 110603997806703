import React, { useContext, createContext, useEffect, useState } from 'react';
import { onSnapshot, doc, getDoc, getFirestore } from 'firebase/firestore';
import { db } from '../firebase-config';
import { UserAuth } from './AuthContext';
import { nullFormat } from 'numeral';

const PremiumContext = createContext()

export const PremiumContextProvider = ({ children }) => {
    // const { user } = UserAuth()
    const [bannedStatus, setBannedStatus] = useState(false);
    const [premiumStatus, setPremiumStatus] = useState('');
    const [premiumTier, setPremiumTier] = useState('');
    const [premiumExpiry, setPremiumExpiry] = useState('');
    const [accountStatus, setAccountStatus] = useState('');
    const [subscriptionUpdated, setSubscriptionUpdated] = useState('');
    const [testAccount, setTestAccount] = useState(false);
    const [pageTouchConnect, setPageTouchConnect] = useState(false);
    const [pageTouchExtension, setPageTouchExtension] = useState(false);
    const [pageTouchQA, setPageTouchQA] = useState(false);
    const [firstOpenPopup, setFirstOpenPopup] = useState(false);
    const [latestTriggerFulfilled, setLatestTriggerFulfilled] = useState(false);
    const [ytUsername, setYtUsername] = useState('');
    const [accessLevel, setAccessLevel] = useState('');
    const [checked, setChecked] = useState(false);
    const [channelLinked, setChannelLinked] = useState(false);
    const [aiQuota, setAiQuota] = useState(0);
    const [aiQuotaUsed, setAiQuotaUsed] = useState(0);
    const [aiCreditsLeft, setAiCreditsLeft] = useState(0);
    const mainUserId = localStorage.getItem("mainUserId");
    const [bucketCount, setBucketCount] = useState(0);
    const [testCount, setTestCount] = useState(0);
    const [splitTestCount, setSplitTestCount] = useState(0);
    const [titleGenCount, setTitleGenCount] = useState(0);
    const [thumbGenCount, setThumbGenCount] = useState(0);
    const [thumbAnalysisCount, setThumbAnalysisCount] = useState(0);

    const checkDataStatus = async (mainUserId) => {
        if (mainUserId) {
            console.log('Setting up subscription for:', mainUserId);
            const userDocRef = doc(db, 'Users', mainUserId);

            const unsubscribe = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    // localStorage.setItem("mainUserId", doc.id);

                    const banned = doc.data().banned === null ? false : doc.data().banned;
                    const access_level = doc.data().access_level === null ? '9' : doc.data().access_level;
                    const a_status = doc.data().account_status === null ? 'INACTIVE' : doc.data().account_status;
                    const p_status = doc.data().premium_status === null ? 'NONE' : doc.data().premium_status;
                    const tier = doc.data().premium_tier === null ? 'NONE' : doc.data().premium_tier;
                    const expiry = doc.data().premium_expiry === null ? Date.now : doc.data().premium_expiry;
                    const linked_channel = doc.data().premium_expiry === null ? Date.now : doc.data().channel_linked;
                    const aiCreditsQuota = doc.data().ai_credits_quota === null ? 0 : doc.data().ai_credits_quota;
                    const aiCreditsUsage = doc.data().ai_credits_usage === null ? 0 : doc.data().ai_credits_usage;
                    const subscription_updated = doc.data().subscription_updated === null ? 0 : doc.data().subscription_updated;
                    const test_account = doc.data().test_account === null ? false : doc.data().test_account;
                    const page_touch_connect = doc.data().page_touch_connect === null ? false : doc.data().page_touch_connect;
                    const page_touch_extension = doc.data().page_touch_extension === null ? false : doc.data().page_touch_extension;
                    const page_touch_qa = doc.data().page_touch_qa === null ? false : doc.data().page_touch_qa;
                    const first_open_popup = doc.data().first_open_popup ?? true;
                    const latest_trigger_fulfilled = doc.data().latest_trigger_fulfilled ?? false;
                    const yt_username = doc.data().yt_username ?? '';

                    setBannedStatus(banned);
                    setPremiumStatus(p_status);
                    setPremiumTier(tier);
                    setPremiumExpiry(expiry);
                    setAccountStatus(a_status);
                    setAccessLevel(access_level);
                    setChecked(true);
                    setChannelLinked(linked_channel);
                    setAiQuota(aiCreditsQuota);
                    setAiQuotaUsed(aiCreditsUsage);
                    setSubscriptionUpdated(subscription_updated);
                    setTestAccount(test_account);
                    setPageTouchConnect(page_touch_connect);
                    setPageTouchExtension(page_touch_extension);
                    setPageTouchQA(page_touch_qa);
                    setFirstOpenPopup(first_open_popup);
                    setLatestTriggerFulfilled(latest_trigger_fulfilled);
                    setYtUsername(yt_username);

                    let creditsLeft = aiCreditsQuota - aiCreditsUsage;
                    setAiCreditsLeft(creditsLeft)
                } else {
                    console.log('No document found!');
                    setBannedStatus(false);
                    setPremiumStatus('');
                    setPremiumTier('');
                    setPremiumExpiry('');
                    setAccountStatus('');
                    setAccessLevel(9);
                    setChecked(false);
                    setChannelLinked(false);
                    setAiQuota(0);
                    setAiQuotaUsed(0);
                    setAiCreditsLeft(0);
                    setSubscriptionUpdated('');
                    setTestAccount(false);
                    setPageTouchConnect(false);
                    setPageTouchExtension(false);
                    setPageTouchQA(false);
                    setFirstOpenPopup(true);
                    setLatestTriggerFulfilled(false);
                    setYtUsername('');
                }
            }, error => {
                console.error('Error on data fetching:', error);
                setBannedStatus(false);
                setPremiumStatus('');
                setPremiumTier('');
                setPremiumExpiry('');
                setAccountStatus('');
                setAccessLevel(9);
                setChecked(false);
                setChannelLinked(false);
                setAiQuota(0);
                setAiQuotaUsed(0);
                setAiCreditsLeft(0);
                setSubscriptionUpdated('');
                setTestAccount(false);
                setPageTouchConnect(false);
                setPageTouchExtension(false);
                setPageTouchQA(false);
                setFirstOpenPopup(true);
                setLatestTriggerFulfilled(false);
                setYtUsername('');
            });

            // Cleanup function only unsubscribes when component unmounts
            return () => {
                console.log('Cleaning up subscription');
                unsubscribe();
            };
        } else {
            console.log('mainUserId is not available');
            setBannedStatus(false);
            setPremiumStatus('');
            setPremiumTier('');
            setPremiumExpiry('');
            setAccountStatus('');
            setAccessLevel(9);
            setChecked(false);
            setChannelLinked(false);
            setAiQuota(0);
            setAiQuotaUsed(0);
            setAiCreditsLeft(0);
            setSubscriptionUpdated('');
            setTestAccount(false);
            setPageTouchConnect(false);
            setPageTouchExtension(false);
            setPageTouchQA(false);
            setFirstOpenPopup(true);
            setLatestTriggerFulfilled(false);
            setYtUsername('');
        }
    };

    const getDataCounts = async (mainUserId) => {
        if (mainUserId) {
            const docRef = doc(db, 'UserData', mainUserId);
            const testDocSnap = await getDoc(docRef);
            const unsubscribe = onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    const bucket_count = doc.data().bucket_count ?? 0;
                    const yt_test_count = doc.data().yt_test_count ?? 0;
                    const thumb_compare_count = doc.data().thumb_compare_count ?? 0;
                    const title_gen_count = doc.data().title_gen_count ?? 0;
                    const thumb_idea_gen_count = doc.data().thumb_idea_gen_count ?? 0;
                    const thumb_analysis_count = doc.data().thumb_analysis_count ?? 0;

                    setBucketCount(bucket_count)
                    setTestCount(yt_test_count)
                    setSplitTestCount(thumb_compare_count)
                    setTitleGenCount(title_gen_count)
                    setThumbGenCount(thumb_idea_gen_count)
                    setThumbAnalysisCount(thumb_analysis_count)
                } else {
                    setBucketCount(0)
                    setTestCount(0);
                    setSplitTestCount(0)
                    setTitleGenCount(0)
                    setThumbGenCount(0)
                    setThumbAnalysisCount(0)
                }

            }, error => {
                setBucketCount(0)
                setTestCount(0);
                setSplitTestCount(0)
                setTitleGenCount(0)
                setThumbGenCount(0)
                setThumbAnalysisCount(0)
            });
            // Cleanup function only unsubscribes when component unmounts
            return () => {
                console.log('Cleaning up subscription');
                unsubscribe();
            };

        } else {
            setBucketCount(0)
            setTestCount(0);
            setSplitTestCount(0)
            setTitleGenCount(0)
            setThumbGenCount(0)
            setThumbAnalysisCount(0)
        }
    }

    useEffect(() => {
        checkDataStatus(mainUserId);
        getDataCounts(mainUserId);
    }, [mainUserId]);

    // useEffect(() => {
    //     let userId = mainUserId;
    //     console.log(userId);

    //     if (userId !== null && userId !== '') {
    //         const userDocRef = doc(db, 'Users', userId);
    //         const unsubscribe = onSnapshot(userDocRef, (doc) => {
    //             if (doc.exists()) {

    //                 const banned = doc.data().banned === null ? false : doc.data().banned;
    //                 const access_level = doc.data().access_level === null ? '9' : doc.data().access_level;
    //                 const a_status = doc.data().account_status === null ? 'INACTIVE' : doc.data().account_status;
    //                 const p_status = doc.data().premium_status === null ? 'NONE' : doc.data().premium_status;
    //                 const tier = doc.data().premium_tier === null ? 'NONE' : doc.data().premium_tier;
    //                 const expiry = doc.data().premium_expiry === null ? Date.now : doc.data().premium_expiry;
    //                 const linked_channel = doc.data().premium_expiry === null ? Date.now : doc.data().channel_linked;
    //                 const aiCreditsQuota = doc.data().ai_credits_quota === null ? 0 : doc.data().ai_credits_quota;
    //                 const aiCreditsUsage = doc.data().ai_credits_usage === null ? 0 : doc.data().ai_credits_usage;
    //                 const subscription_updated = doc.data().subscription_updated === null ? 0 : doc.data().subscription_updated;
    //                 const test_account = doc.data().test_account === null ? false : doc.data().test_account;
    //                 const page_touch_connect = doc.data().page_touch_connect === null ? false : doc.data().page_touch_connect;
    //                 const page_touch_extension = doc.data().page_touch_extension === null ? false : doc.data().page_touch_extension;
    //                 const page_touch_qa = doc.data().page_touch_qa === null ? false : doc.data().page_touch_qa;

    //                 setBannedStatus(banned);
    //                 setPremiumStatus(p_status);
    //                 setPremiumTier(tier);
    //                 setPremiumExpiry(expiry);
    //                 setAccountStatus(a_status);
    //                 setAccessLevel(access_level);
    //                 setChecked(true);
    //                 setChannelLinked(linked_channel);
    //                 setAiQuota(aiCreditsQuota);
    //                 setAiQuotaUsed(aiCreditsUsage);
    //                 setSubscriptionUpdated(subscription_updated);
    //                 setTestAccount(test_account);
    //                 setPageTouchConnect(page_touch_connect);
    //                 setPageTouchExtension(page_touch_extension);
    //                 setPageTouchQA(page_touch_qa);

    //                 let creditsLeft = aiCreditsQuota - aiCreditsUsage;
    //                 setAiCreditsLeft(creditsLeft)
    //             }
    //         });

    //         return () => {
    //             unsubscribe();
    //         };
    //     }
    //     else {
    //         setBannedStatus(false);
    //         setPremiumStatus('');
    //         setPremiumTier('');
    //         setPremiumExpiry('');
    //         setAccountStatus('');
    //         setAccessLevel(9);
    //         setChecked(false);
    //         setChannelLinked(false);
    //         setAiQuota(0);
    //         setAiQuotaUsed(0);
    //         setAiCreditsLeft(0);
    //         setSubscriptionUpdated('');
    //         setTestAccount(false);
    //         setPageTouchConnect(false);
    //         setPageTouchExtension(false);
    //         setPageTouchQA(false);
    //     }

    // }, []);

    return (
        <PremiumContext.Provider value={{
            bannedStatus,
            premiumStatus,
            premiumTier,
            premiumExpiry,
            accountStatus,
            accessLevel,
            checked,
            channelLinked,
            aiQuota,
            aiQuotaUsed,
            aiCreditsLeft,
            subscriptionUpdated,
            testAccount,
            pageTouchConnect,
            pageTouchExtension,
            pageTouchQA,
            firstOpenPopup,
            latestTriggerFulfilled,
            ytUsername,
            checkDataStatus,
            bucketCount,
            testCount,
            splitTestCount,
            titleGenCount,
            thumbGenCount,
            thumbAnalysisCount,
        }}>
            {children}
        </PremiumContext.Provider>
    )
}

export const PremiumCredentials = () => {
    return useContext(PremiumContext)
}
