import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import CountUp from 'react-countup';
import axios from 'axios';
import { PremiumCredentials } from '../../../context/PremiumContext'
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import { db, storage } from '../../../firebase-config';
import { collection, getDocs, getDoc, query, orderBy, doc, updateDoc, arrayUnion, addDoc, serverTimestamp, onSnapshot } from "firebase/firestore";
import { Tab } from '@headlessui/react';
import { AiOutlineCheckCircle, } from 'react-icons/ai'
import { TfiArrowLeft, TfiDownload, TfiPlus } from 'react-icons/tfi'
import { ToastContainer, toast } from 'react-toastify';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import { MdOutlineArrowBack } from 'react-icons/md';
import Hint from '../../components/Hint';
import { FaHandScissors } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { BiDoorOpen, BiRefresh } from 'react-icons/bi';
import TitleTemplateIdeaGenerator from '../../components/TitleTemplateIdeaGenerator';
import FeedbackBanner from '../../components/FeedbackBanner';

const ViralLibraryDetails = () => {
    let { item_id } = useParams();
    let navigate = useNavigate();
    const { accessLevel, premiumStatus } = PremiumCredentials()
    const [selectedIndex, setSelectedIndex] = useState(0);
    const mainUserId = localStorage.getItem("mainUserId");
    const [boards, setBoards] = useState([]);
    const [fbModal, setFbModal] = useState(false);
    const [titleTemplateIdeaGenerator, setTitleTemplateIdeaGenerator] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [fbLoaded, setFbLoaded] = useState(false);
    const [fbProcessing, setFbProcessing] = useState(false);
    const [data, setData] = useState(null);
    const [fbName, setfbName] = useState("");
    const { pathname } = useLocation();
    const PAGE_SIZE = 6;
    const [blockPopup, setBlockPopup] = useState(false)

    const colors = ['text-blue-500', 'text-red-500', 'text-green-500', 'text-pink-500', 'text-purple-500'];
    let colorIndex = 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const goBack = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            // Alternative action, e.g., navigate to home page
            navigate('/viral-library');
        }
    };

    const refreshTemplate = (title) => {
        const token = getCookie('TG_AT');
        const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/refresh-title-template/`;

        const data = {
            doc_id: item_id,
            title: title,
        };

        const headers = {
            headers: {
                'Authorization': `${token}`,
            }
        };

        axios.post(url, data, headers).then((response) => {
            if (response.status === 200) {
                // //console.log('It worked');
            }
        }).catch((error) => {
            console.error(error);
            // //console.log('It did not work');
        });
    }

    const goToSdDownload = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            window.open(link, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const goToHdDownload = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            const videoId = extractSectionFromURL(link);
            const newLink = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const goToVideo = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            const videoId = extractSectionFromURL(link);
            const newLink = `https://www.youtube.com/watch?v=${videoId}`
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    useEffect(() => {
        // const getDetails = async () => {
        //     // //console.log(item_id);
        //     const docRef = doc(db, 'Library', item_id);
        //     const vDocSnap = await getDoc(docRef);
        //     if (vDocSnap.exists()) {
        //         // //console.log("Document data:", vDocSnap.data());
        //         let cat_id = vDocSnap.data().category_id

        //         const catDocRef = doc(db, 'LibraryCategories', cat_id);
        //         const catDocSnap = await getDoc(catDocRef);

        //         if (catDocSnap.exists()) {
        //             const newData = { ...vDocSnap.data(), category_name: catDocSnap.data().category_name };
        //             setData(newData);
        //         } else {
        //             setData(vDocSnap.data());
        //         }

        //         setLoaded(true);

        //     } else {
        //         navigate('/404');
        //         // //console.log("No such document!"); 
        //     }
        // }

        const getDetails = async () => {
            const token = getCookie('TG_AT');
            const docRef = doc(db, 'Library', item_id);
            const unsubscribe = onSnapshot(docRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    // let cat_id = docSnapshot.data().category_id;
                    const rawData = docSnapshot.data();
                    const newData = { ...docSnapshot.data() };

                    //console.log('inside')
                    if (!rawData.item_data || !rawData.item_data.title_template) {
                        try {
                            const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/process-title-template/`;

                            const data = {
                                doc_id: item_id,
                                title: rawData.item_data.title,
                            };

                            const headers = {
                                headers: {
                                    'Authorization': `${token}`,
                                }
                            };

                            axios.post(url, data, headers).then((response) => {
                                if (response.status === 200) {
                                    //console.log('It worked');
                                }
                            }).catch((error) => {
                                console.error(error);
                                //console.log('It did not work');
                            });
                        } catch (error) {
                            console.error('Error generating title template:', error);
                        }
                    }

                    // Fetch category name only once
                    if (newData.category_id) {
                        const catDocRef = doc(db, 'LibraryCategories', newData.category_id);
                        const catDocSnapshot = await getDoc(catDocRef);
                        if (catDocSnapshot.exists()) {
                            newData.category_name = catDocSnapshot.data().category_name;
                        }
                    }

                    setData(newData);
                    setLoaded(true);
                } else {
                    navigate('/404');
                }
            });
            return unsubscribe;
        };

        const getFocusBoards = async () => {
            const colRef = collection(db, 'Users', mainUserId, 'FocusBoards');
            const q = query(
                colRef,
                orderBy("date_created", "desc"),
            );
            await getDocs(q)
                .then((querySnapshot) => {
                    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setBoards(data);
                    setFbLoaded(true);
                    // //console.log(boards, newData);
                })
        }

        getDetails();
        getFocusBoards();
    }, []);

    // Function to extract the section from the URL
    const extractSectionFromURL = (url) => {
        const sections = url.split('/');
        return sections[4]; // Change the index as needed, assuming dlteGDtxsqs is always at the 4th position.
    };

    const handleFbModalClose = (e) => {
        if (accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 4 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7) {
            if (e.target.id === "focus-board-modal") {
                setFbModal(false);
                setFbProcessing(false);
                setfbName("");
            };
        } else {
            toast.warn('You need active credits to access this feature.', {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const addToFocusBoard = async (focus_board_id, focus_board_name) => {

        if (accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 4 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7) {
            setFbProcessing(true);
            const addRef = doc(db, 'Users', mainUserId, 'FocusBoards', focus_board_id);
            const addedData = {
                items: arrayUnion({
                    avatar: data.item_data.avatar,
                    channelName: data.item_data.name,
                    thumbnail: data.item_data.thumbnail,
                    title: data.item_data.title
                })
            }
            updateDoc(addRef, addedData).then(res => {
                fbModalClose();
                toast(`Thumbnail added to ${focus_board_name}`, {
                    icon: ({ theme, type }) => <AiOutlineCheckCircle className='text-green-500 h-7 w-7' />
                });
            });
        } else {
            toast.warn(<>You need active credits to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }

    }

    const saveNewFocusBoard = async (e) => {
        e.preventDefault();
        if (accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 4 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7) {
            if (fbName.trimStart().trimEnd() !== '') {
                setFbProcessing(true);
                const colRef = collection(db, 'Users', mainUserId, 'FocusBoards',)
                await addDoc(colRef, {
                    name: fbName,
                    is_active: true,
                    items: [{
                        avatar: data.item_data.avatar,
                        channelName: data.item_data.name,
                        thumbnail: data.item_data.thumbnail,
                        title: data.item_data.title
                    }],
                    date_created: serverTimestamp(),
                }).then((response) => {
                    fbModalClose();
                    toast(`${fbName} focus board created`, {
                        icon: ({ theme, type }) => <AiOutlineCheckCircle className='text-green-500 h-7 w-7' />
                    });
                })
            }
        } else {
            toast.warn(<>You need active credits to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const showCopiedToast = async (value) => {
        if (accessLevel !== 5 && premiumStatus !== 'TRIALING') {
            navigator.clipboard.writeText(value);
            toast.success('Color copied to clipboard', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }



    }

    const fbModalClose = () => {
        setFbModal(false);
        setFbProcessing(false);
        setfbName("");
    }

    const fbModalOpen = () => {
        // if (accessLevel === 1 || accessLevel === 2) {
        setFbModal(true);
        // } else {
        //     setBlockPopup(true);
        // }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Why It Works - Viral Library</title>
                <meta name="description" content="Dashboard of Thumbnail Geek" />
            </Helmet>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/viral-library/details/' + item_id} />

            <TitleTemplateIdeaGenerator show={titleTemplateIdeaGenerator} isVisible={titleTemplateIdeaGenerator} template={data?.item_data?.title_template ? data?.item_data?.title_template : ''} onClose={() => setTitleTemplateIdeaGenerator(false)} />

            <div id="focus-board-modal" onClick={handleFbModalClose} className={`${fbModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-700 dark:text-white">
                                Add to your Focus Board
                            </h3>
                            <button onClick={fbModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className='border-gray-200 text-gray-800 border-b text-sm'>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className={`inline-flex p-3`}>
                                        <p className='hidden lg:block'>Create New</p>
                                    </span>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className="inline-flex p-3">
                                        <p className='hidden lg:block'>Add to Existing</p>
                                    </span>
                                </Tab>

                            </Tab.List>
                            <Tab.Panels className='w-full'>
                                <Tab.Panel className='w-full h-72 max-w-lg md:h-auto'>
                                    <form onSubmit={saveNewFocusBoard}>
                                        <div className="p-6 pb-12 space-y-6">
                                            <div>
                                                <label htmlFor="fb_name" className="block mb-2 ml-1 text-sm font-medium text-gray-900 dark:text-white">Give It A Name</label>
                                                <input onChange={(e) => setfbName(e.target.value)} type="text" id="fb_name" name="fb_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="eg. Creative Ideas" required />
                                            </div>
                                        </div>

                                        {
                                            fbName.trimStart().trimEnd() !== ''
                                                ?
                                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                                    <button type="submit" disabled={fbProcessing} className={`${fbProcessing === true ? 'bg-neutral-200 text-neutral-400 cursor-wait' : 'cursor-pointer text-white bg-blue-600 hover:bg-blue-700 active:bg-blue-800'} font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>{fbProcessing === true ? 'Doing...' : 'Create & Add'}</button>
                                                </div>
                                                :
                                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                                    <button type="submit" disabled='disabled' className={`bg-neutral-200 text-neutral-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>Create & Add</button>
                                                </div>
                                        }

                                    </form>
                                </Tab.Panel>
                                <Tab.Panel className='w-full h-full max-h-80 max-w-lg md:h-auto'>
                                    <div className="p-4 pb-8 pt-4 w-full h-80 overflow-hidden overflow-y-scroll">
                                        {boards.length === 0
                                            ?
                                            <>
                                                {
                                                    fbLoaded === true
                                                        ?
                                                        <div className='pb-2 pt-5 text-neutral-500 flex items-center justify-center'>
                                                            <div className="text-sm">No Focus Board created. Go to Create New tab.</div>
                                                        </div>
                                                        :
                                                        <div className="flex flex-col mt-3 items-center justify-center h-full">
                                                            <svg className="animate-spin h-5 w-5 text-gray-500 mb-3" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.206 0 4.232-.896 5.657-2.343l2.343 2.343zM18 6.709V4.043A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z"></path>
                                                            </svg>
                                                            <p className="text-gray-500">Loading focus boards...</p>
                                                        </div>
                                                }

                                            </>

                                            :
                                            <div className="flex flex-wrap gap-x-4 gap-y-1 w-full">
                                                {boards?.map((doc, i) => (
                                                    doc.items.length < 11 ?

                                                        <div key={doc.id} className="pl-4 mb-2 group h-16 flex justify-between text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 hover:bg-gray-200 hover:border-gray-200 w-full">
                                                            <div className="flex flex-col mt-3">
                                                                <span className='font-medium'>{doc.name}</span>
                                                                <span className='text-[11px]'>{doc.items.length}{'/11 thumbnails'}</span>
                                                            </div>
                                                            <div className="hidden group-hover:block m-4">
                                                                <button onClick={() => addToFocusBoard(doc.id, doc.name)} type="submit" disabled={fbProcessing} className={` ${fbProcessing === true ? 'bg-neutral-200 text-neutral-400 cursor-wait' : 'text-white cursor-pointer bg-blue-600 hover:bg-blue-700 active:bg-blue-800'} font-normal rounded-lg text-xs px-4 py-2 text-center`}>{fbProcessing === true ? 'Can\'t Add. Doing...' : 'Add to this'}</button>
                                                            </div>
                                                        </div>

                                                        :

                                                        <div className="" key={doc.id}></div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>

                        <div className="p-4 pt-0">
                            <Hint hintText={'You can compare your own thumbnails against the thumbnails in your Focus Board'} fontSizePx={'13px'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="viral-library" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24 w-[calc(100%)] lg:w-[calc(100%_-_55px)] lg:w-[calc(100%_-_240px)] 2xl:w-[calc(100%_-_280px)]">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-10 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">
                            <div className="flex flex-row justify-center items-center">
                                <button onClick={() => goBack()} className={`text-gray-800 bg-gray-100 hover:bg-gray-200
                             mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                    <MdOutlineArrowBack />
                                </button>
                                <div className="">
                                    <h5 hidden className={`text-xl text-gray-600 font-medium lg:block`}>Detailed Breakdown</h5>
                                </div>
                            </div>

                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">

                                <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">

                                    {/* <GoToYoutubeBtn /> */}
                                    <Avatar />
                                </div>
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:ml-8 mt-20">
                            {
                                loaded === true
                                    ?
                                    <div className="w-full grid grid-cols-10 max-w-6xl 2xl:p-7">

                                        <div className=' mx-auto col-span-full md:col-span-4 text-gray-800 w-80 2xl:w-[350px]  pb-5 text-[0.9rem] lg:mt-14 mb-5'>
                                            <div className="mb-2 group">
                                                <div className="@apply inline-block overflow-hidden w-full h-[80%] mr-2 cursor-pointer duration-200 ">
                                                    <img className="@apply inline-block mx-0 w-full rounded-lg" src={data?.item_data.thumbnail} alt="" />
                                                </div>
                                            </div>

                                            <div className='flex lg:mb-32'>
                                                <div className="shrink-0 mx-0 my-0 mr-4 md:mr-2 xl:mr-3 h-11 w-11 object-cover">
                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-11 xl:w-11' src={data?.item_data.avatar} alt="" />
                                                </div>
                                                <div className='flex-col w-9/12'>
                                                    <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                        <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[15px] mb:text-mb'>{data?.item_data.title}</p>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex text-[14px] font-roboto lg:text-[13px]'>
                                                            <p className='opacity-50'>{data?.item_data.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-span-full md:col-span-6 lg:border-l border-neutral-400 lg:px-4 2xl:px-8">
                                            <div className="">
                                                <h2 className="text-2xl text-gray-800 font-semibold text-left mt-2 mb-4 pb-4 border-b border-gray-300">
                                                    The specifics
                                                </h2>
                                                {/* <div className="">
                                                    {data?.analytical_description}
                                                </div> */}
                                            </div>
                                            <div className="mt-12">
                                                <div className="">
                                                    <dl className="mt-2 grid max-w-xl grid-cols-1 gap-8 sm:mt-5 sm:grid-cols-2 lg:grid-cols-12 xl:mt-7">
                                                        <div className="flex flex-col col-span-4 gap-y-3 pl-6">
                                                            <dt className="text-sm leading-6 text-gray-600">Brightness</dt>
                                                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                                                <CountUp duration={2.75} end={Math.round((data?.averageBrightness / 255) * 100)} />%
                                                                {/* {Math.round((data?.averageBrightness / 255) * 100)}% */}
                                                            </dd>
                                                        </div>
                                                        <div className="flex flex-col col-span-8 gap-y-3 border-l border-gray-900/10 pl-6">
                                                            <dt className="text-sm leading-6 text-gray-600">Color Palette</dt>
                                                            <dd className="order-first inline-flex text-gray-900">
                                                                <Tooltip content="Copy" position="top">
                                                                    <span onClick={() => showCopiedToast(`${data?.color_Vibrant}`)} className={`inline-block w-9 h-9 mr-[3px] rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_Vibrant}` }}></span>
                                                                    <span onClick={() => showCopiedToast(`${data?.color_LightVibrant}`)} className={`inline-block w-9 h-9 mr-[3px] rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_LightVibrant}` }}></span>
                                                                    <span onClick={() => showCopiedToast(`${data?.color_LightMuted}`)} className={`inline-block w-9 h-9 mr-[3px] rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_LightMuted}` }}></span>
                                                                    <span onClick={() => showCopiedToast(`${data?.color_Muted}`)} className={`inline-block w-9 h-9 mr-[3px] rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_Muted}` }}></span>
                                                                    <span onClick={() => showCopiedToast(`${data?.color_DarkMuted}`)} className={`inline-block w-9 h-9 mr-[3px] rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_DarkMuted}` }}></span>
                                                                    <span onClick={() => showCopiedToast(`${data?.color_DarkVibrant}`)} className={`inline-block w-9 h-9 rounded-full border hover:border-2 hover:border-gray-900 cursor-pointer active:border-gray-200`}
                                                                        style={{ backgroundColor: `${data?.color_DarkVibrant}` }}></span>
                                                                </Tooltip>
                                                            </dd>


                                                        </div>

                                                    </dl>

                                                    <dl className="mt-2 grid max-w-xl grid-cols-1 gap-8 sm:mt-5 sm:grid-cols-2 lg:grid-cols-12 xl:mt-7">
                                                        <div className="flex flex-col col-span-4 gap-y-3 pl-6">
                                                            <dt className="text-sm leading-6 text-gray-600">Title Length</dt>
                                                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                                                {/* <CountUp delay={2.85} duration={2.75} end={data?.item_data.title.length} /> */}
                                                                <CountUp delay={0} duration={2.75} end={data?.item_data.title.length} />
                                                            </dd>
                                                        </div>
                                                        <div className="flex flex-col col-span-4 gap-y-4 pl-6 mt-1 border-l border-gray-900/10">
                                                            <dt className="text-sm leading-6 text-gray-600">Category</dt>
                                                            <dd className="order-first text-xl font-semibold tracking-tight text-gray-900 w-96 hover:text-gray-500">
                                                                <NavLink to={`/viral-library/category/${data?.category_id}`}>
                                                                    {data?.category_name}
                                                                </NavLink>
                                                            </dd>
                                                        </div>
                                                    </dl>

                                                    <dl className="mt-2 grid grid-cols-1 gap-8 sm:mt-5 sm:grid-cols-2 lg:grid-cols-12 xl:mt-7 border-t pt-6 pr-7 mr-5">
                                                        <div className="flex flex-col col-span-12 gap-y-3 pl-6">
                                                            <dt className="order-first text-sm leading-6 text-gray-600">Title Template</dt>
                                                            <dd className="text-[15px] font-semibold tracking-tight text-gray-900 ">
                                                                {
                                                                    data?.item_data.title_template ?
                                                                        (
                                                                            data?.item_data.title_template.split(/(\[[^\]]+\])/).map((part, index) => {
                                                                                if (part.startsWith('[') && part.endsWith(']')) {
                                                                                    // const words = part.split(' '); // Split the words inside the brackets
                                                                                    const words = part.slice(1, -1).split(' '); // Split the words inside the brackets
                                                                                    return (
                                                                                        <span key={index} className='opacity-70'>
                                                                                            {words.map((word, wordIndex) => {
                                                                                                const color = colors[colorIndex % colors.length];
                                                                                                colorIndex += 1;
                                                                                                return <span key={wordIndex} className={color}>{word}</span>; // Add a space after each word
                                                                                            })}
                                                                                        </span>
                                                                                    );
                                                                                } else {
                                                                                    return <span key={index}>{part}</span>;
                                                                                }
                                                                            })
                                                                        ) : 'Generating...'
                                                                }

                                                            </dd>
                                                            <dd className="text-base tracking-tight text-gray-900 mt-3">
                                                                <button onClick={() => setTitleTemplateIdeaGenerator(true)}
                                                                    className='p-1 px-2 mr-2 text-gray-600 bg-gray-200 hover:text-gray-100 hover:bg-gray-500 rounded-md inline-flex items-center text-xl'>
                                                                    <GiReceiveMoney />
                                                                    <span className="ml-2 text-sm">Swipe this title template</span>
                                                                    {/* <p className={`ml-1 mr-3 text-sm`}>Worldwide</p> */}
                                                                </button>
                                                                {/* <button onClick={() => refreshTemplate(data?.item_data.title)}
                                                                    className='p-1 px-2 mr-2 text-gray-400 bg-gray-200 hover:text-gray-100 hover:bg-gray-500 rounded-md inline-flex items-center text-xl'>
                                                                    <BiRefresh />
                                                                </button> */}
                                                            </dd>
                                                        </div>
                                                    </dl>

                                                    <div className="mt-12 w-full flex justify-start lg:items-left lg:justify-left ml-6">
                                                        <button onClick={() => goToSdDownload(data?.item_data.thumbnail)} className='p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl'>
                                                            <TfiDownload />
                                                            <span className="ml-2 text-sm">SD Download</span>
                                                            {/* <p className={`ml-1 mr-3 text-sm`}>Worldwide</p> */}
                                                        </button>
                                                        <button onClick={() => goToHdDownload(data?.item_data.thumbnail)} className='p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl'>
                                                            <TfiDownload />
                                                            <span className="ml-2 text-sm">HD Download</span>
                                                            {/* <p className={`ml-1 mr-3 text-sm`}>Worldwide</p> */}
                                                        </button>
                                                        <button onClick={() => goToVideo(data?.item_data.thumbnail)} className='p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl'>
                                                            <BiDoorOpen />
                                                            <span className="ml-2 text-sm">View Video</span>
                                                            {/* <p className={`ml-1 mr-3 text-sm`}>Worldwide</p> */}
                                                        </button>
                                                        {/* <button onClick={() => fbModalOpen()} className='p-3 mr-2 text-gray-400 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl'>
                                                            <TfiPlus />
                                                            <span className="ml-2 text-sm">Add to Focus Board</span>
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>


                                            {/* {data.reasons?.map((reason, i) => (
                                                <div className="flex justify-start mb-4">
                                                    <FcCheckmark className='mt-1' />
                                                    <span className='ml-3'>{reason}</span>
                                                </div>
                                            ))} */}


                                            {/* {data.reasons?.map((reason, i) => (
                                                <div className="flex justify-start mb-4">
                                                    <FcCheckmark className='mt-1' />
                                                    <span className='ml-3'>{reason}</span>
                                                </div>
                                            ))} */}
                                        </div>
                                    </div>
                                    :
                                    <div className="w-full min-h-full flex flex-col justify-center mt-48 items-center">
                                        <div className="flex flex-col items-center justify-center mb-3">
                                            <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                <path
                                                    className="fill-gray-300"
                                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                <path
                                                    className="fill-gray-500"
                                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                            </svg>
                                        </div>

                                        Gathering all the things...
                                    </div>
                                // <div className="w-full flex flex-col justify-center mt-48 items-center">Gathering all the things...</div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <FeedbackBanner currentPageUrl={'viral-library-details'} />
        </>
    )
}

export default ViralLibraryDetails