import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import html2canvas from "html2canvas";
import LazyLoad from 'react-lazyload';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, storage, storageStoryboards } from '../../firebase-config';
import { getFirestore, collection, addDoc, arrayUnion, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { PremiumCredentials } from '../../context/PremiumContext';
import { CgClose } from 'react-icons/cg';
import { BiDownArrow, BiGitCommit } from 'react-icons/bi';
import { HiHand, HiRefresh } from 'react-icons/hi';
import { RxMove, RxText } from 'react-icons/rx';
import { MdFontDownload, MdOutlineDataSaverOff, MdOutlinePublishedWithChanges, MdTextDecrease, MdTextIncrease, MdTrackChanges } from 'react-icons/md';
import DotDotDot from './DotDotDot'; // Assuming this component exists
import Lottie from 'lottie-react'; // Assuming you're using lottie for animations
import loading_tg from "../../assets/animations/loading_tg.json"; // Assuming this is your loading animation
import drawing from "../../assets/animations/ani5.json";
import { TbAlertTriangle, TbFrame, TbLayersDifference, TbPick, TbShare } from 'react-icons/tb';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { RiBold, RiZoomInLine, RiZoomOutLine } from 'react-icons/ri';
import { IoRefreshSharp, IoText } from 'react-icons/io5';
import Tooltip from './Tooltip';
import { FaBold, FaCheck } from 'react-icons/fa';
import { FiSave, FiSlash, FiZap } from 'react-icons/fi';
import { BsSave } from 'react-icons/bs';
import CachedImage from './CachedImage';

const InteractiveImageViewer = ({ imageUrl, onClose, onSave, storyboardId, index }) => {
    const mainUserId = localStorage.getItem("mainUserId");
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [minScale, setMinScale] = useState(1);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [activeTool, setActiveTool] = useState('move');
    const [onImageText, setOnImageText] = useState('');
    const [textOverlays, setTextOverlays] = useState([]);
    const [selectedOverlay, setSelectedOverlay] = useState(null);
    const [isDraggingOverlay, setIsDraggingOverlay] = useState(false);
    const [overlayDragStart, setOverlayDragStart] = useState({ x: 0, y: 0 });
    const [fontSize, setFontSize] = useState(70);
    const [fontWeight, setFontWeight] = useState(300);
    const [showTextBackground, setShowTextBackground] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showTextControls, setShowTextControls] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(null);
    const fontWeights = [400, 500, 600, 700, 800, 900];
    const [editingOverlay, setEditingOverlay] = useState(null);
    const [editText, setEditText] = useState('');
    const [editingPosition, setEditingPosition] = useState({ x: 0, y: 0 });

    const editInputRef = useRef(null);
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const animationRef = useRef(null);

    const [pageFont, setPageFont] = useState('');

    // Function to get the page font
    const getPageFont = useCallback(() => {
        const body = document.body;
        const computedStyle = window.getComputedStyle(body);
        return computedStyle.getPropertyValue('font-family');
    }, []);

    // Effect to set the page font when the component mounts
    useEffect(() => {
        setPageFont(getPageFont());
    }, [getPageFont]);

    const resizeCanvas = useCallback(() => {
        const container = containerRef.current;
        const image = imageRef.current;
        if (!container || !image) return;

        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const containerAspectRatio = containerWidth / containerHeight;
        const imageAspectRatio = image.width / image.height;

        let scale;
        if (imageAspectRatio > containerAspectRatio) {
            scale = containerWidth / image.width;
        } else {
            scale = containerHeight / image.height;
        }

        const canvas = canvasRef.current;
        canvas.width = containerWidth;
        canvas.height = containerHeight;
        setScale(scale);
        setMinScale(scale);
        setPosition({ x: 0, y: 0 });

        drawImage();
    }, []);

    const drawImage = useCallback(() => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const scaledWidth = image.width * scale;
        const scaledHeight = image.height * scale;
        const x = (canvas.width - scaledWidth) / 2 + position.x;
        const y = (canvas.height - scaledHeight) / 2 + position.y;

        ctx.drawImage(image, x, y, scaledWidth, scaledHeight);
        drawTextOverlays();
    }, [scale, position, imageSize, textOverlays]);

    const drawTextOverlays = useCallback(() => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const ctx = canvas.getContext('2d');
        const scaledWidth = image.width * scale;
        const scaledHeight = image.height * scale;
        const x = (canvas.width - scaledWidth) / 2 + position.x;
        const y = (canvas.height - scaledHeight) / 2 + position.y;

        ctx.save();
        ctx.translate(x, y);
        ctx.scale(scale, scale);

        textOverlays.forEach((overlay) => {
            if (overlay.id === editingOverlay) return;

            ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px ${pageFont}, Arial`;
            const metrics = ctx.measureText(overlay.text);
            const textHeight = overlay.fontSize;

            // Calculate extended bottom padding for descenders
            const descenderPadding = textHeight * 0.25; // Adjust this value as needed

            if (selectedOverlay === overlay.id && activeTool === 'text') {
                // Draw selection box
                ctx.fillStyle = 'rgba(0, 123, 255, 0.2)';
                ctx.fillRect(
                    overlay.x - 4 / scale,
                    overlay.y - textHeight - 4 / scale,
                    metrics.width + 8 / scale,
                    textHeight + 8 / scale + descenderPadding
                );
                ctx.strokeStyle = 'rgba(0, 123, 255, 0.8)';
                ctx.lineWidth = 2 / scale;
                ctx.strokeRect(
                    overlay.x - 4 / scale,
                    overlay.y - textHeight - 4 / scale,
                    metrics.width + 8 / scale,
                    textHeight + 8 / scale + descenderPadding
                );
            }

            if (overlay.showBackground) {
                // Draw background box with extended bottom
                const paddingX = 4 / scale;
                const paddingY = 4 / scale;
                ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                ctx.fillRect(
                    overlay.x - paddingX,
                    overlay.y - textHeight - paddingY,
                    metrics.width + paddingX * 2,
                    textHeight + paddingY * 2 + descenderPadding
                );
            }

            // Draw text
            ctx.fillStyle = overlay.color;
            ctx.fillText(overlay.text, overlay.x, overlay.y);
        });

        ctx.restore();
    }, [textOverlays, scale, position, selectedOverlay, activeTool, editingOverlay, pageFont]);

    const handleCanvasClick = useCallback((e) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const rect = canvas.getBoundingClientRect();
        const scaledWidth = image.width * scale;
        const scaledHeight = image.height * scale;
        const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
        const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

        const clickX = (e.clientX - rect.left - offsetX) / scale;
        const clickY = (e.clientY - rect.top - offsetY) / scale;

        const clickedOverlay = textOverlays.find(overlay => {
            const ctx = canvas.getContext('2d');
            ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
            const metrics = ctx.measureText(overlay.text);
            const textHeight = overlay.fontSize;

            return (
                clickX >= overlay.x &&
                clickX <= overlay.x + metrics.width &&
                clickY >= overlay.y - textHeight &&
                clickY <= overlay.y
            );
        });

        if (activeTool === 'move') {
            if (clickedOverlay) {
                setActiveTool('text');
                setSelectedOverlay(clickedOverlay.id);
            } else {
                setSelectedOverlay(null); // Deselect text when clicking empty space in Move mode
            }
        } else if (activeTool === 'text') {
            if (clickedOverlay) {
                setSelectedOverlay(clickedOverlay.id);
            } else {
                setSelectedOverlay(null); // Deselect text when clicking empty space in Text mode
            }
        }

        // Ensure state updates are applied
        setTextOverlays(prevOverlays => [...prevOverlays]);

        drawImage();
    }, [activeTool, textOverlays, position, scale, drawImage]);

    const handleMouseDown = useCallback((e) => {
        if (activeTool === 'text') {
            const canvas = canvasRef.current;
            const image = imageRef.current;
            if (!canvas || !image) return;

            const rect = canvas.getBoundingClientRect();
            const scaledWidth = image.width * scale;
            const scaledHeight = image.height * scale;
            const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
            const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

            const clickX = (e.clientX - rect.left - offsetX) / scale;
            const clickY = (e.clientY - rect.top - offsetY) / scale;

            const clickedOverlay = textOverlays.find(overlay => {
                const ctx = canvas.getContext('2d');
                ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
                const metrics = ctx.measureText(overlay.text);
                const textHeight = overlay.fontSize;

                return (
                    clickX >= overlay.x &&
                    clickX <= overlay.x + metrics.width &&
                    clickY >= overlay.y - textHeight &&
                    clickY <= overlay.y
                );
            });

            if (clickedOverlay) {
                setSelectedOverlay(clickedOverlay.id);
                setIsDraggingOverlay(true);
                setOverlayDragStart({ x: clickX - clickedOverlay.x, y: clickY - clickedOverlay.y });
            } else {
                setSelectedOverlay(null);
                setIsDraggingOverlay(false);
            }

            drawImage();
        } else if (activeTool === 'move') {
            setIsDragging(true);
            setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
        }
    }, [activeTool, textOverlays, position, scale, drawImage]);

    const handleMouseMove = useCallback((e) => {
        if (isDraggingOverlay && activeTool === 'text') {
            const canvas = canvasRef.current;
            const image = imageRef.current;
            if (!canvas || !image) return;

            const rect = canvas.getBoundingClientRect();
            const scaledWidth = image.width * scale;
            const scaledHeight = image.height * scale;
            const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
            const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

            const newX = (e.clientX - rect.left - offsetX) / scale - overlayDragStart.x;
            const newY = (e.clientY - rect.top - offsetY) / scale - overlayDragStart.y;

            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay =>
                    overlay.id === selectedOverlay
                        ? { ...overlay, x: newX, y: newY }
                        : overlay
                )
            );

            drawImage();
        } else if (isDragging && activeTool === 'move') {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }

            animationRef.current = requestAnimationFrame(() => {
                const newX = e.clientX - dragStart.x;
                const newY = e.clientY - dragStart.y;
                setPosition({ x: newX, y: newY });
            });
        }
    }, [isDragging, isDraggingOverlay, activeTool, dragStart, selectedOverlay, overlayDragStart, scale, position, drawImage]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
        setIsDraggingOverlay(false);
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        drawImage();
    }, [drawImage]);

    const handleToolChange = useCallback((tool) => {
        setActiveTool(tool);
        setSelectedOverlay(null);
        setIsDragging(false);
        setIsDraggingOverlay(false);

        setTextOverlays(prevOverlays => [...prevOverlays]);

        drawImage();
    }, [drawImage]);

    const handleAddText = useCallback(() => {
        if (onImageText && canvasRef.current && imageRef.current) {
            const image = imageRef.current;
            const newOverlay = {
                id: Date.now(),
                text: onImageText,
                x: image.width / 2,
                y: image.height / 2,
                fontSize,
                fontWeight,
                color: 'black',
                showBackground: showTextBackground,
                fontFamily: pageFont // Add this line
            };
            setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
            setOnImageText('');
            drawImage();
        }
    }, [onImageText, fontSize, fontWeight, showTextBackground, drawImage]);

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter' && activeTool === 'text') {
            handleAddText();
        }
    }, [activeTool, handleAddText]);

    const handleDoubleClick = useCallback((e) => {
        if (activeTool === 'text') {
            const canvas = canvasRef.current;
            const image = imageRef.current;
            if (!canvas || !image) return;

            const rect = canvas.getBoundingClientRect();
            const scaledWidth = image.width * scale;
            const scaledHeight = image.height * scale;
            const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
            const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

            const clickX = (e.clientX - rect.left - offsetX) / scale;
            const clickY = (e.clientY - rect.top - offsetY) / scale;

            const clickedOverlay = textOverlays.find(overlay => {
                const ctx = canvas.getContext('2d');
                ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px ${overlay.fontFamily}`;
                const metrics = ctx.measureText(overlay.text);
                const textHeight = overlay.fontSize;

                return (
                    clickX >= overlay.x &&
                    clickX <= overlay.x + metrics.width &&
                    clickY >= overlay.y - textHeight &&
                    clickY <= overlay.y
                );
            });

            if (clickedOverlay) {
                setEditingOverlay(clickedOverlay.id);
                setEditText(clickedOverlay.text);
                setSelectedOverlay(clickedOverlay.id);
                setEditingPosition({
                    x: clickedOverlay.x * scale + offsetX,
                    y: (clickedOverlay.y + clickedOverlay.fontSize / 2) * scale + offsetY
                });
            } else {
                const newOverlay = {
                    id: Date.now(),
                    text: 'Text overlay',
                    x: clickX,
                    y: clickY,
                    fontSize,
                    fontWeight,
                    color: 'black',
                    showBackground: showTextBackground,
                    fontFamily: pageFont
                };
                setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
                setEditingOverlay(newOverlay.id);
                setEditText('Text overlay');
                setSelectedOverlay(newOverlay.id);
                setEditingPosition({
                    x: clickX * scale + offsetX,
                    y: (clickY + fontSize / 2) * scale + offsetY
                });
            }
            drawImage();
        }
    }, [activeTool, scale, position, textOverlays, fontSize, fontWeight, showTextBackground, pageFont, drawImage]);

    const handleTextUpdate = useCallback(() => {
        if (editingOverlay !== null) {
            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay =>
                    overlay.id === editingOverlay
                        ? { ...overlay, text: editText }
                        : overlay
                )
            );
            setEditingOverlay(null);
            setEditText('');
            drawImage();
        }
    }, [editingOverlay, editText, drawImage]);

    const handleDeleteText = useCallback(() => {
        if (selectedOverlay) {
            setTextOverlays(prevOverlays => prevOverlays.filter(overlay => overlay.id !== selectedOverlay));
            setSelectedOverlay(null);
            drawImage();
        }
    }, [selectedOverlay, drawImage]);

    const handleZoom = useCallback((delta) => {
        if (activeTool === 'move') {
            setScale(prevScale => {
                const newScale = Math.max(minScale, prevScale + delta);
                return newScale;
            });
        }
    }, [activeTool, minScale]);

    const handleReset = useCallback(() => {
        if (activeTool === 'move') {
            setScale(minScale);
            setPosition({ x: 0, y: 0 });
        }
    }, [activeTool, minScale]);

    const handleFontWeightChange = useCallback((action) => {
        if (selectedOverlay) {
            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay => {
                    if (overlay.id === selectedOverlay) {
                        const currentIndex = fontWeights.indexOf(overlay.fontWeight);
                        let newIndex;
                        if (action === 'increase') {
                            newIndex = Math.min(fontWeights.length - 1, currentIndex + 1);
                        } else {
                            newIndex = Math.max(0, currentIndex - 1);
                        }
                        return { ...overlay, fontWeight: fontWeights[newIndex] };
                    }
                    return overlay;
                })
            );
            drawImage();
        }
    }, [selectedOverlay, drawImage, fontWeights]);

    const handleFontSizeChange = useCallback((delta) => {
        if (selectedOverlay) {
            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay =>
                    overlay.id === selectedOverlay
                        ? { ...overlay, fontSize: Math.max(10, overlay.fontSize + delta) }
                        : overlay
                )
            );
            drawImage();
        }
    }, [selectedOverlay, drawImage]);

    const toggleTextBackground = useCallback(() => {
        if (selectedOverlay) {
            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay =>
                    overlay.id === selectedOverlay
                        ? { ...overlay, showBackground: !overlay.showBackground }
                        : overlay
                )
            );
            drawImage();
        }
    }, [selectedOverlay, drawImage]);

    const handleEditChange = (e) => {
        setEditText(e.target.value);
    };

    const finishEditing = useCallback(() => {
        if (editingOverlay !== null) {
            setTextOverlays(prevOverlays =>
                prevOverlays.map(overlay =>
                    overlay.id === editingOverlay
                        ? { ...overlay, text: editText }
                        : overlay
                )
            );
            setEditingOverlay(null);
            setEditText('');
            drawImage();  // Redraw the image to show the updated text
        }
    }, [editingOverlay, editText, drawImage]);

    const [isSaving, setIsSaving] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showSaved, setShowSaved] = useState(false);
    const progressIntervalRef = useRef(null);

    useEffect(() => {
        return () => {
            if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
            }
        };
    }, []);

    const saveImageToBucket = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setProgress(0);

        progressIntervalRef.current = setInterval(() => {
            setProgress(prev => (prev < 95 ? prev + 1 : prev));
        }, 50);

        try {
            // Get the image data from the canvas
            const imageData = canvasRef.current.toDataURL('image/jpeg');

            // Generate a unique filename
            const filename = `storyboardThumbnails/${mainUserId}/thumbnailVariations/${storyboardId}-${index}-resized-${Date.now()}.jpg`;
            const storageRef = ref(storageStoryboards, filename);

            // Upload the image
            await uploadString(storageRef, imageData, 'data_url');
            const publicUrl = await getDownloadURL(storageRef);

            // Update Firestore
            const storyboardRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);
            await updateDoc(storyboardRef, {
                [`storyboards.${index}.image_url_local_resized`]: publicUrl,
                [`storyboards.${index}.variations`]: arrayUnion(publicUrl)
            });

            setProgress(100);
            clearInterval(progressIntervalRef.current);

            onSave(publicUrl);
            setShowSaved(true);
            setTimeout(() => {
                setShowSaved(false);
                onClose();
            }, 1500);

            //console.log('Image uploaded and Firestore updated successfully');
        } catch (error) {
            toast.error(<>Failed to upload image.<br />Please try again.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        } finally {
            clearInterval(progressIntervalRef.current);
            setIsSaving(false);
        }
    };

    const saveButtonText = () => {
        if (showSaved) return 'Saved';
        if (isSaving) return `${progress}%`;
        return 'Save';
    };

    // const saveImageToBucket = async (e) => {
    //     e.preventDefault();
    //     setIsSaving(true);

    //     try {
    //         // Get the image data from the canvas
    //         const imageData = canvasRef.current.toDataURL('image/jpeg');

    //         // Generate a unique filename
    //         const filename = `storyboardThumbnails/${mainUserId}/thumbnailVariations/${storyboardId}-${index}-resized-${Date.now()}.jpg`;
    //         const storageRef = ref(storageStoryboards, filename);

    //         // Upload the image
    //         await uploadString(storageRef, imageData, 'data_url');
    //         const publicUrl = await getDownloadURL(storageRef);

    //         // Update Firestore
    //         const storyboardRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);
    //         await updateDoc(storyboardRef, {
    //             [`storyboards.${index}.image_url_local_resized`]: publicUrl,
    //             [`storyboards.${index}.variations`]: arrayUnion(publicUrl)
    //         });

    //         onSave(publicUrl);
    //         onClose();
    //         //console.log('Image uploaded and Firestore updated successfully');
    //     } catch (error) {
    //         toast.error(<>Failed to upload image.<br />Please try again.</>, {
    //             position: "top-right",
    //             autoClose: 7000,
    //         })
    //     } finally {
    //         setIsSaving(false);
    //     }
    // };

    const downloadImage = (e) => {
        let link = e.currentTarget;
        link.setAttribute('download', 'thumbnail-idea-mockup.png');
        let image = canvasRef.current.toDataURL('image/png');
        link.setAttribute('href', image);
    };

    useEffect(() => {
        if (editingOverlay !== null && editInputRef.current) {
            editInputRef.current.focus();
        }
    }, [editingOverlay]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (editInputRef.current && !editInputRef.current.contains(e.target)) {
                finishEditing();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [finishEditing]);

    useEffect(() => {
        const image = new Image();
        image.onload = function () {
            imageRef.current = image;
            image.crossOrigin = "anonymous";
            setImageSize({ width: image.width, height: image.height });
            setImageLoaded(true);
            resizeCanvas();
        };
        image.onerror = function (err) {
            console.error("Error loading image:", err);
            setImageLoadError(err);
        };
        image.src = imageUrl;

        window.addEventListener('resize', resizeCanvas);
        return () => {
            window.removeEventListener('resize', resizeCanvas);
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [imageUrl, resizeCanvas]);

    useEffect(() => {
        drawImage();
    }, [drawImage, scale, position]);

    useEffect(() => {
        if (activeTool === 'text') {
            drawImage();
        }
    }, [activeTool, drawImage]);

    const handleKeyDown = useCallback((e) => {
        // Only allow delete when not editing text and a text overlay is selected
        if ((e.key === 'Delete' || e.key === 'Backspace') &&
            activeTool === 'text' &&
            selectedOverlay &&
            editingOverlay === null) {
            e.preventDefault(); // Prevent the default action
            handleDeleteText();
        }
    }, [activeTool, selectedOverlay, editingOverlay, handleDeleteText]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const toggleTextControls = () => setShowTextControls(prev => !prev);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <div className="relative bg-white rounded-lg max-w-4xl w-full flex flex-col">
                <button
                    className="absolute z-50 top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-gray-300 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
                    onClick={onClose}
                >
                    <CgClose size={24} />
                </button>
                <div className="w-full bg-white rounded-lg">

                    <div className="p-3 mb-0 border-b border-gray-300">
                        Reframe, Resize & Add Basic Text
                    </div>
                    <div
                        ref={containerRef}
                        className="w-full overflow-hidden rounded-lg relative"
                        style={{ aspectRatio: '16/9' }}
                    >
                        <canvas
                            ref={canvasRef}
                            className="max-w-none rounded-t-lg"
                            onClick={handleCanvasClick}
                            onDoubleClick={handleDoubleClick}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            style={{ cursor: activeTool === 'move' ? 'move' : 'default' }}
                        ></canvas>
                        {editingOverlay !== null && (
                            <input
                                ref={editInputRef}
                                type="text"
                                value={editText}
                                onChange={handleEditChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleTextUpdate();
                                    }
                                }}
                                onBlur={handleTextUpdate}
                                className="absolute bg-white border border-gray-300 rounded px-3 py-3 text-2xl"
                                style={{
                                    left: `${editingPosition.x}px`,
                                    top: `${editingPosition.y}px`,
                                    transform: 'translateY(-10%)',
                                    minWidth: '100px'
                                }}
                                autoFocus
                            />
                        )}
                    </div>
                    <div className="p-4 space-x-2 flex justify-between border-t border-gray-300">

                        <div className="inline-flex">
                            <div className='ring-1 ring-gray-300 rounded-lg p-2 mr-2 inline-flex items-center'>
                                <Tooltip content="Move mode">
                                    <button
                                        className={`px-2 py-2 ${activeTool === 'move' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg mr-2`}
                                        onClick={() => handleToolChange('move')}
                                    >
                                        <RxMove className='w-5 h-5' />
                                    </button>
                                </Tooltip>
                                <Tooltip content="Text Mode">
                                    <button
                                        className={`px-2 py-2 ${activeTool === 'text' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg`}
                                        onClick={() => handleToolChange('text')}
                                    >
                                        <RxText className='w-5 h-5' />
                                    </button>
                                </Tooltip>
                            </div>

                            {activeTool === 'text' && (
                                <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
                                    <input
                                        type="text"
                                        placeholder='Enter overlay text'
                                        value={onImageText}
                                        onChange={(e) => setOnImageText(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        className="mr-2 -mt-0 p-2 bg-gray-100 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-md"
                                    />
                                    <Tooltip content="Add text to image">
                                        <button
                                            className={`px-2 py-2 text-gray-500 hover:text-gray-600 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
                                            onClick={handleAddText}
                                        >
                                            <FaCheck className='w-5 h-5' />
                                        </button>
                                    </Tooltip>
                                    <Tooltip content="Delete selected text">
                                        <button
                                            className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
                                            onClick={handleDeleteText}
                                        >
                                            <TrashIcon className='w-5 h-5' />
                                        </button>
                                    </Tooltip>
                                    <div className="relative">
                                        {showTextControls && (
                                            <div className="p-1 absolute -top-12 right-1/2 translate-x-1/2 inline-flex items-center bg-white rounded-lg ring-1 ring-gray-300">

                                                <Tooltip content="Decrease text size">
                                                    <button
                                                        className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
                                                        onClick={() => handleFontSizeChange(-2)}
                                                    >
                                                        <MdTextDecrease className='w-5 h-5' />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="Increase text size">
                                                    <button
                                                        className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
                                                        onClick={() => handleFontSizeChange(2)}
                                                    >
                                                        <MdTextIncrease className='w-5 h-5' />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="Decrease boldness">
                                                    <button
                                                        className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
                                                        onClick={() => handleFontWeightChange('decrease')}
                                                    >
                                                        <RiBold className='w-5 h-5' />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="Increase boldness">
                                                    <button
                                                        className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
                                                        onClick={() => handleFontWeightChange('increase')}
                                                    >
                                                        <FaBold className='w-5 h-5' />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="Add/Remove text background">
                                                    <button
                                                        className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
                                                        onClick={toggleTextBackground}
                                                    >
                                                        <MdFontDownload className='w-5 h-5' />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        )}
                                        <Tooltip content="Show/Hide text controls">
                                            <button
                                                className={`px-2 py-2 text-gray-600 hover:text-gray-700 ${showTextControls ? 'bg-gray-200' : ''} hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
                                                onClick={toggleTextControls}
                                            >
                                                <IoText className='w-5 h-5' />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                            {activeTool === 'move' && (
                                <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
                                    <Tooltip content="Reset move and zoom state">
                                        <button
                                            onClick={handleReset}
                                            className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
                                        >
                                            <IoRefreshSharp className='w-5 h-5' />
                                        </button>
                                    </Tooltip>
                                    <Tooltip content="Zoom Out">
                                        <button
                                            onClick={() => handleZoom(-0.1)}
                                            className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
                                            disabled={activeTool === 'text'}
                                        >
                                            <RiZoomOutLine className='w-5 h-5' />
                                        </button>
                                    </Tooltip>
                                    <Tooltip content="Zoom In">
                                        <button
                                            onClick={() => handleZoom(0.1)}
                                            className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg"
                                            disabled={activeTool === 'text'}
                                        >
                                            <RiZoomInLine className='w-5 h-5' />
                                        </button>
                                    </Tooltip>
                                </div>
                            )}
                        </div>

                        <div className='inline-flex  '>

                            <a id='download_image_link' href='download-link'
                                onClick={saveImageToBucket}
                                disabled={isSaving}
                                className={`px-4 h-full w-[100px] mr-2 rounded-lg border-2 border-gray-500 hover:border-gray-800 text-gray-600 hover:text-gray-800 transition-colors duration-200 flex items-center justify-center ${isExporting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FiZap className='w-4 h-4 mr-2' />
                                {/* {isSaving ? 'Saving...' : 'Save'} */}
                                {saveButtonText()}
                            </a>

                            <a id='download_image_link' href='download-link'
                                onClick={downloadImage}
                                disabled={isExporting}
                                className={`px-4 h-full w-[150px] rounded-lg border-2 border-gray-500 hover:border-gray-800 text-gray-600 hover:text-gray-800 transition-colors duration-200 flex items-center justify-center ${isExporting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isExporting ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Exporting...
                                    </>
                                ) : (
                                    <>
                                        <BsSave className='w-4 h-4 mr-2' />
                                        Download
                                    </>
                                )}
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

const VariationPopup = ({ storyboardId, index, initialImageSrc, onCloseVPopup, onVariationSelect }) => {
    const mainUserId = localStorage.getItem("mainUserId");
    const [variations, setVariations] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const functions = getFunctions();

    useEffect(() => {
        const fetchVariations = async () => {
            //console.log(mainUserId)
            //console.log(storyboardId)
            //console.log(index)

            const docRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);
            const docSnap = await getDoc(docRef);

            //console.log(docSnap)

            if (docSnap.exists()) {
                const data = docSnap.data();
                const storyboardData = data.storyboards?.[index];
                if (storyboardData && Array.isArray(storyboardData.variations)) {
                    setVariations(storyboardData.variations);
                } else {
                    console.error('Variations not found or not in expected format');
                }
            } else {
                console.error('No such document!');
            }
        };

        fetchVariations();
    }, [storyboardId, index, mainUserId]);

    const selectVariation = () => {
        if (selectedVariation) {
            onVariationSelect(selectedVariation);
            onCloseVPopup();
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white max-w-5xl w-full flex flex-col rounded-lg overflow-hidden">
                <button
                    className="absolute z-50 top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
                    onClick={onCloseVPopup}
                >
                    <CgClose size={24} />
                </button>

                <div className="p-4 bg-white">
                    Select the Image variation to Display
                </div>

                <div className="bg-gray-100 p-4">
                    <div className="flex gap-4">
                        <div className="flex-grow">
                            <h3 className='mb-2 text-xs'>PREVIEW</h3>
                            <div className="aspect-[16/9] w-[750px] overflow-hidden ring-1 ring-gray-300 rounded-lg">
                                <img src={selectedVariation || initialImageSrc} className="w-full h-full object-cover rounded-lg" alt="Selected variation" />
                            </div>
                        </div>

                        <div className="w-[300px]">
                            <h3 className='mb-2 text-xs'>VARIATIONS</h3>
                            <div className="bg-white rounded-lg ring-1 ring-gray-300 h-[415px] overflow-y-auto">
                                <div className="p-3 space-y-4">
                                    {variations.length === 0 ? (
                                        <div className="w-full h-full text-xs flex items-center justify-center">
                                            No Variations Found
                                        </div>
                                    ) : (
                                        variations.map((variationSrc, idx) => (
                                            <div key={idx}
                                                className={`relative w-full aspect-[16/9] overflow-hidden ring-1 ${selectedVariation === variationSrc ? 'ring-blue-500 ring-2' : 'ring-gray-300'} rounded-md cursor-pointer`}
                                                onClick={() => setSelectedVariation(variationSrc)}
                                            >
                                                {initialImageSrc === variationSrc && (
                                                    <div className="absolute top-1 right-1 p-1 bg-black text-white text-[8px] bg-opacity-80 rounded-md">
                                                        <span>CURRENT</span>
                                                    </div>
                                                )}
                                                <img src={variationSrc} className="w-full h-full object-cover rounded-lg" alt={`Variation ${idx + 1}`} />
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-4 w-full flex justify-end bg-white">
                    <button
                        onClick={selectVariation}
                        className={`px-8 py-2 text-white rounded-lg bg-gray-700 hover:bg-gray-800 transition-colors duration-200 flex items-center ${!selectedVariation && 'opacity-50 cursor-not-allowed'}`}
                        disabled={!selectedVariation}
                    >
                        <HiHand className="mr-2" />
                        Select this
                    </button>
                </div>
            </div>
        </div>
    );
};

const EditableTitle = ({ initialTitle, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(initialTitle);

    const removeQuotes = (originalText) => {
        return originalText?.replace(/^"|"$/g, '');
    };

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
            onSave(title);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        onSave(title);
    };

    useEffect(() => {
        let newTitle = removeQuotes(initialTitle);
        setTitle(newTitle);
    }, [initialTitle]);

    return (
        <div className="w-full h-14 flex items-start justify-start">
            {isEditing ? (
                <input
                    type="text"
                    value={title}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    className="mt-2 text-[15px] leading-snug font-medium text-gray-700 px-2 w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
                    autoFocus
                />
            ) : (
                <Tooltip content="Click to Edit Title">
                    <div
                        onClick={handleClick}
                        className="mt-2 text-[15px] leading-snug font-medium text-gray-700 px-2 cursor-pointer hover:bg-gray-100 rounded"
                    >
                        {title}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const StoryboardItem = React.memo(({
    storyboardId,
    storyboardData,
    storyboard,
    itemKey,
    overallProcessing,
    onProcessingStatusChange,
}) => {
    const functions = getFunctions();
    const refreshThumbnailImage = httpsCallable(functions, 'refreshThumbnailImage');
    const generateTitleForStoryboardImage = httpsCallable(functions, 'generateTitleForStoryboardImage');
    const changeStoryBoardItemConcept = httpsCallable(functions, 'changeStoryBoardItemConcept');
    const { accessLevel, aiCreditsLeft, premiumStatus } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId");
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [isVPopupOpen, setIsVPopupOpen] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(storyboard.thumbnail_type);
    const [currentTitle, setCurrentTitle] = useState(storyboard.title);
    const [currentImageUrl, setCurrentImageUrl] = useState(storyboard.image_url_local_resized);
    const [loadingState, setLoadingState] = useState({ image: false, title: false, category: false });
    const [imageLoaded, setImageLoaded] = useState(false);
    const [itemStatus, setItemStatus] = useState(storyboard.status || 'incomplete');
    const [isListening, setIsListening] = useState(true);
    const unsubscribeRef = useRef(null);
    const modalRef = useRef(null);

    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const checkProcessingStatus = () => {
            const isStillProcessing = (
                (itemStatus !== 'complete'
                    && itemStatus !== 'saving_image'
                    && itemStatus !== 'error'
                    && storyboard.error === null
                )
                || !currentImageUrl
            );

            const hasSpecificError = storyboard.error &&
                (storyboard.error.includes('400') ||
                    storyboard.error.includes('safety') ||
                    storyboard.error.includes('content policy'));

            const hasGeneralError = storyboard.error && storyboard.error !== '';

            if (!currentImageUrl && hasSpecificError) {
                onProcessingStatusChange(itemKey, false);
                setErrorMessage('Image failed. Please change Concept or Refresh Image.');
            } else if (!currentImageUrl && hasGeneralError) {
                onProcessingStatusChange(itemKey, false);
                setErrorMessage('Image error. Please change Concept or Refresh Image.');
            } else {
                setIsProcessing(isStillProcessing);
                onProcessingStatusChange(itemKey, isStillProcessing);
                setErrorMessage('');
            }
        };

        checkProcessingStatus();
    }, [loadingState, itemStatus, itemKey, onProcessingStatusChange, storyboard.error, currentImageUrl]);

    const setupListener = useCallback(() => {
        if (!mainUserId || !storyboardId || !itemKey) return;
        setLoadingState(prev => ({ ...prev, title: true }));

        const storyboardsDocRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);

        unsubscribeRef.current = onSnapshot(storyboardsDocRef, (snapshot) => {
            if (snapshot.exists()) {
                const incomingData = snapshot.data();
                if (incomingData.storyboards && incomingData.storyboards[itemKey]) {
                    const updatedItem = incomingData.storyboards[itemKey];

                    setCurrentImageUrl(updatedItem.image_url_local_resized ?? '');
                    setCurrentCategory(updatedItem.thumbnail_type ?? '');
                    setCurrentTitle(updatedItem.title ?? '');
                    setItemStatus(updatedItem.status || 'incomplete');
                    if (updatedItem.error && updatedItem.error.trim() !== '') {
                        setItemStatus('error');
                    }

                    if (updatedItem.title) {
                        setLoadingState(prev => ({ ...prev, title: false }));
                    }

                    if (updatedItem.status === 'complete') {
                        unsubscribeListener();
                    }
                } else {
                    // Handle item removal
                    setCurrentImageUrl('');
                    setCurrentCategory('');
                    setCurrentTitle('');
                    setItemStatus('removed');
                    unsubscribeListener();
                }
            } else {
                setLoadingState(prev => ({ ...prev, title: false }));
                setCurrentImageUrl('');
                setCurrentCategory('');
                setCurrentTitle('');
                setItemStatus('removed');
                unsubscribeListener();
            }
        });

        setIsListening(true);
    }, [mainUserId, storyboardId, itemKey]);

    const unsubscribeListener = useCallback(() => {
        if (unsubscribeRef.current) {
            unsubscribeRef.current();
            unsubscribeRef.current = null;
            setIsListening(false);
        }
    }, []);

    const reactivateListener = useCallback(() => {
        if (!isListening) {
            setupListener();
        }
    }, [isListening, setupListener]);

    useEffect(() => {
        setupListener();

        return () => {
            unsubscribeListener();
        };
    }, [setupListener, unsubscribeListener]);

    const openViewModal = () => {
        if (currentImageUrl !== null && currentImageUrl !== undefined && currentImageUrl !== "") {
            setViewModal(true);
        } else {
            toast.error('Sorry. There is no image to view!', {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const handleViewModalClose = (e) => {
        if (e.target.id === "st-item-view-modal") {
            setViewModal(false);
        };
    }

    const viewModalClose = (e) => {
        setViewModal(false);
    }

    const openVariationModal = () => {

        if (storyboard.error) {
            toast.error(<>Cannot view image due to an error. <br />Refresh image to create a new one.</>, {
                position: "top-right",
                autoClose: 7000,
            });
            return;
        }

        if (currentImageUrl !== null && currentImageUrl !== undefined && currentImageUrl !== "") {
            setIsVPopupOpen(true)
        } else {
            toast.error('Sorry. There is no image to view!', {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const variationSelect = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setIsVPopupOpen(false);
    };

    const replaceWithImageFromSave = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setIsVPopupOpen(false);
    };

    //TODO: tested
    const handleRefreshImage = useCallback(async () => {
        if (accessLevel === 5) {
            toast.error(
                <>Recharge to unlock. Unavailable to use in trial</>,
                { position: "top-right", autoClose: 7000 }
            );
            return;
        }

        if (isProcessing || aiCreditsLeft <= 50) {
            toast.error(
                isProcessing
                    ? <>A task is already in progress.<br />Please wait for it to finish.</>
                    : <>Not enough processing credits.<br />Recharge to access this feature.</>,
                { position: "top-right", autoClose: 7000 }
            );
            return;
        }

        if (!storyboardId || !itemKey) {
            toast.error('Something went wrong. Please try again later.', {
                position: "top-right",
                autoClose: 7000,
            });
            return;
        }

        setLoadingState(prev => ({ ...prev, image: true }));
        try {
            const response = await refreshThumbnailImage({ storyboardId: storyboardId, key: itemKey });

            if (response?.data?.newImageUrl && response?.data?.keyToChange) {
                setCurrentImageUrl(response.data.newImageUrl);
                reactivateListener();
            }
            toast.success('Image refreshed successfully');
        } catch (error) {
            console.error('Error refreshing image:', error);
            toast.error('Failed to refresh image');
        } finally {
            setLoadingState(prev => ({ ...prev, image: false }));
        }
    }, [itemKey]);

    //TODO: tested
    const handleGenerateTitle = useCallback(async () => {

        // if (accessLevel === 5) {
        //     toast.error(
        //         <>Recharge to unlock. Unavailable to use in trial</>,
        //         { position: "top-right", autoClose: 7000 }
        //     );
        //     return;
        // }

        if (isProcessing || aiCreditsLeft <= 5) {
            toast.error(
                isProcessing
                    ? <>A task is already in progress.<br />Please wait for it to finish.</>
                    : <>Not enough processing credits.<br />Recharge to access this feature.</>,
                { position: "top-right", autoClose: 7000 }
            );
            return;
        }

        if (!storyboardId || !itemKey) {
            toast.error('Something went wrong. Please try again later.', {
                position: "top-right",
                autoClose: 7000,
            });
            return;
        }

        setLoadingState(prev => ({ ...prev, title: true }));
        try {
            const response = await generateTitleForStoryboardImage({ storyboardId: storyboardId, key: itemKey });
            if (response.data.title !== '' && response.data.title !== null) {
                setCurrentTitle(response.data.title);
            } else {
                toast.error('Cannot process title at this time');
            }
        } catch (error) {
            console.error('Error generating title:', error);
            toast.error('Failed to generate new title');
        } finally {
            setLoadingState(prev => ({ ...prev, title: false }));
        }
    }, [itemKey]);

    //TODO: test concept
    const handleChangeConcept = useCallback(async () => {
        if (accessLevel === 5) {
            toast.error(
                <>Recharge to unlock. Unavailable to use in trial</>,
                { position: "top-right", autoClose: 7000 }
            );
            return;
        }

        if (isProcessing || aiCreditsLeft <= 60) {
            toast.error(
                isProcessing
                    ? <>A task is already in progress.<br />Please wait for it to finish.</>
                    : <>Not enough processing credits.<br />Recharge to access this feature.</>,
                { position: "top-right", autoClose: 7000 }
            );
            return;
        }

        if (!storyboardId || !itemKey) {
            toast.error('Something went wrong. Please try again later.', {
                position: "top-right",
                autoClose: 7000,
            });
            return;
        }

        setLoadingState(prev => ({ ...prev, image: true, title: true, category: true }));
        try {
            const response = await changeStoryBoardItemConcept({ storyboardId: storyboardId, key: itemKey });
            if (response?.data?.newImageUrl && response?.data?.newTitle && response?.data?.newCategory && response?.data?.keyToChange) {

                setCurrentCategory(response.data.newCategory);
                setCurrentImageUrl(response.data.newImageUrl);
                setCurrentTitle(response.data.newTitle);

                toast.success('Concept updated successfully');
            }
        } catch (error) {
            console.error('Error changing concept:', error);
            toast.error('Failed to change concept. Try later.');
        } finally {
            setLoadingState(prev => ({ ...prev, image: false, title: false, category: false }));
        }
    }, [itemKey]);

    const handleEditTitle = useCallback(async (newTitle) => {
        const docRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);
        try {
            await updateDoc(docRef, {
                ['storyboards.' + itemKey + '.title']: newTitle
            })
        } catch (error) {
            toast.error('Failed to save.', {
                position: "top-right",
                autoClose: 2000,
            });
        }
    }, [itemKey]);

    const handleShare = useCallback(() => {
        toast.info('Coming soon.', {
            position: "top-right",
            autoClose: 2000,
        });
    }, []);

    const handleReframe = () => {
        if (storyboard.error) {
            toast.error(<>Cannot reframe due to an image error. <br />Create a new image.</>, {
                position: "top-right",
                autoClose: 7000,
            });
            return false;
        } else if (!currentImageUrl) {
            toast.error(<>Cannot reframe when there's no image. <br />Hit Refresh to generate a new image</>, {
                position: "top-right",
                autoClose: 7000,
            });
            return false;
        } else if (!currentImageUrl?.includes('storage.googleapis.com')
            && !currentImageUrl?.includes('firebasestorage.googleapis.com')) {
            toast.error(<>Cannot reframe yet. Please wait... <br />Image not yet saved to your account.</>, {
                position: "top-right",
                autoClose: 7000,
            });
            return false;
        } else {
            loadReframer();
        }
    }

    const loadReframer = useCallback(() => {
        if (currentImageUrl === null || currentImageUrl === undefined) {
            toast.error('Cannot reframe when there\'s  no image. Hit Refresh to generate a new image', {
                position: "top-right",
                autoClose: 7000,
            })
            return;
        }
        //console.log(currentImageUrl)

        viewModalClose();
        setIsViewerOpen(true);
        //console.log(`Preparing to reframe item ${itemKey}`);
    }, [itemKey]);

    const onRefreshImage = () => handleRefreshImage(itemKey);
    const onGenerateTitle = () => handleGenerateTitle(itemKey);
    const onChangeConcept = () => handleChangeConcept(itemKey);
    const onEditTitle = (newTitle) => handleEditTitle(newTitle);

    const renderImageContent = () => {
        if (loadingState.image) {
            return (
                <div className="relative w-full h-full">
                    <div className="absolute inset-0 bg-white/70 flex justify-center items-center w-full h-full">
                        <div className='w-40'>
                            <Lottie animationData={loading_tg} loop={true} />
                        </div>
                    </div>
                    <img src={currentImageUrl} alt={currentTitle || 'Loading image'} className="object-cover w-full h-full" />
                </div>
            );
        }

        if (currentImageUrl) {
            const imgSrc = currentImageUrl.includes('storage.googleapis.com') || currentImageUrl?.includes('firebasestorage.googleapis.com')
                ? `${currentImageUrl}?${Date.now()}`
                : currentImageUrl;
            // return <img src={imgSrc} className="object-cover w-full h-full" alt={currentTitle || 'Generated image'} />;
            return <CachedImage src={imgSrc} containerClassName="object-cover w-full h-full" alt={currentTitle || 'Generated image'} />;
        }

        if (isProcessing) {
            return (
                <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
                    {
                        storyboard.status === 'generated_image_prompt' ?
                            <Lottie animationData={drawing} loop={true} className='w-24 h-24' />
                            :
                            <div className="flex flex-col items-center justify-center mb-3">
                                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                    <path
                                        className="fill-gray-300"
                                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                    ></path>
                                    <path
                                        className="fill-gray-500"
                                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                    ></path>
                                </svg>
                            </div>
                    }
                    <p className="text-center">
                        {
                            storyboard.status === 'generated_image_prompt'
                                ?
                                <>
                                    <p className='-mt-7'>Generating Image. <br />Please wait...</p>
                                </>
                                : storyboard.status === 'retrying'
                                    ?
                                    <>
                                        <p>Content Moderation Rejection. Retrying. <br />Please wait...</p>
                                    </>
                                    :
                                    <>
                                        <p>This image will take some time to process <br />Please wait...</p>
                                    </>
                        }
                    </p>
                </div>
            );
        }

        if (errorMessage) {
            return (
                <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
                    <TbAlertTriangle className="h-8 w-8 text-gray-500 mb-2" />
                    <p className="text-center w-2/3">{errorMessage}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div id="st-item-view-modal" onClick={handleViewModalClose} className={`${viewModal === true ? '' : 'hidden'} fixed inset-0 z-50 flex items-center justify-center p-4 rounded-lg bg-black bg-opacity-50`}>
                <div ref={modalRef} className="relative max-w-4xl w-full bg-white rounded-lg shadow-lg">
                    <button
                        className="absolute top-2 right-2 z-[54657] bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
                        onClick={() => viewModalClose()}
                    >
                        <CgClose size={24} />
                    </button>
                    {
                        !currentImageUrl?.includes('storage.googleapis.com/thumbnailgeek-storyboards') && !currentImageUrl?.includes('firebasestorage.googleapis.com/')
                            ?
                            <div className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50">
                                <CachedImage src={`${currentImageUrl}`} alt="-" containerClassName="object-cover w-full h-full" />
                            </div>
                            :
                            <div className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50">
                                <CachedImage src={`${currentImageUrl}?${Date.now()}`} alt="-" containerClassName="object-cover w-full h-full" />
                            </div>

                        // <img src={`${currentImageUrl}?${Date.now()}`}
                        //     className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50"
                        // />
                    }



                    <div className="flex p-4 justify-end">
                        <button onClick={() => handleReframe(itemKey)}
                            className="text-sm inline-flex items-center px-4 py-2 ring-1 ring-gray-400 text-gray-500 rounded hover:ring-gray-700 hover:text-gray-700"
                        >
                            <TbFrame size={18} className="mr-2" />
                            Reframe Image
                        </button>

                        <a href={`${currentImageUrl}?${Date.now()}` === '' ? '' : `${currentImageUrl}?${Date.now()}`} target='_blank'
                            className="text-sm inline-flex items-center ml-2 px-4 py-2 ring-1 ring-blue-500 bg-blue-500 text-white rounded hover:bg-blue-600 hover:ring-blue-600"
                        >
                            <BiDownArrow size={18} className="mr-2" />
                            Download
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 2xl:w-96 px-4 mb-8">

                <div className="flex flex-col">
                    {/* Category section */}
                    <div className="w-full h-7 flex items-center justify-between">
                        {loadingState.category ? (
                            <div className="mt-2 text-[17px] pl-3"><DotDotDot /></div>
                        ) : (
                            <p className='text-gray-500 text-sm mb-3'>{currentCategory || 'No Category'}</p>
                        )}

                        {/* Add variation selector button here */}
                        <Tooltip content='Select image variation to show'>
                            <div onClick={openVariationModal} className="w-6 h-6 text-gray-400 hover:text-gray-700 hover:bg-gray-200 active:bg-gray-300 rounded-md cursor-pointer flex items-center justify-center">
                                <TbLayersDifference className='' />
                            </div>
                        </Tooltip>
                    </div>

                    {/* Image section */}
                    {/* <div onClick={openViewModal} className="w-full aspect-[16/9] overflow-hidden ring-1 ring-gray-400 rounded-lg hover:ring-2 hover:cursor-pointer transition-all duration-400" >
                        {loadingState.image ? (
                            <div className="relative w-full h-full">
                                <div className="absolute inset-0 bg-white/70 flex justify-center items-center w-full h-full">
                                    <div className='w-40'>
                                        <Lottie animationData={loading_tg} loop={true} />
                                    </div>
                                </div>
                                <img src={currentImageUrl} alt={currentTitle || 'Loading image'} className="object-cover w-full h-full" />
                            </div>
                        ) : (
                            <div className="relative w-full h-full">
                                {currentImageUrl ? (
                                    currentImageUrl.includes('storage.googleapis.com')
                                        || currentImageUrl?.includes('firebasestorage.googleapis.com')
                                        ? (
                                            <img src={`${currentImageUrl}?${Date.now()}`} className="object-cover w-full h-full" />
                                        ) : (
                                            <img src={currentImageUrl} className="object-cover w-full h-full" />
                                        )
                                ) : (
                                    <>
                                        {!currentImageUrl && (storyboard.error === "" || !storyboard.error) ? (
                                            <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
                                                <div className="flex flex-col items-center justify-center mb-3">
                                                    <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                        <path
                                                            className="fill-gray-300"
                                                            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                        ></path>
                                                        <path
                                                            className="fill-gray-500"
                                                            d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <p className="text-center">This image will take some time to process. <br />Please wait...</p>
                                            </div>
                                        ) : itemStatus !== "complete" || !currentImageUrl || currentImageUrl === '' ? (
                                            <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
                                                {storyboard.error &&
                                                    (storyboard.error.includes('400') ||
                                                        storyboard.error.includes('safety') ||
                                                        storyboard.error.includes('content policy')) ? (
                                                    <>
                                                        <TbAlertTriangle className="h-8 w-8 text-gray-500 mb-2" />
                                                        <p className="text-center">Image generation failed. <br />Please change Concept or Refresh Image.</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="flex flex-col items-center justify-center mb-3">
                                                            <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                                <path
                                                                    className="fill-gray-300"
                                                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                                ></path>
                                                                <path
                                                                    className="fill-gray-500"
                                                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <p className="text-center">This image will take some time to process. <br />Please wait...</p>
                                                    </>
                                                )}
                                            </div>
                                        ) : itemStatus === "error" || storyboard.error !== "" ? (
                                            <div className="absolute inset-0 z-40 w-full h-full flex flex-col text-center items-center justify-center text-sm bg-gray-200">
                                                <TbAlertTriangle className="h-8 w-8 text-gray-500" />
                                                <p>Error creating image<br />Refresh to generate new</p>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                                {itemStatus !== "complete" && currentImageUrl && currentImageUrl === '' && (
                                    <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
                                        <div className="flex flex-col items-center justify-center mb-3">
                                            <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                <path
                                                    className="fill-gray-300"
                                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                ></path>
                                                <path
                                                    className="fill-gray-500"
                                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                ></path>
                                            </svg>
                                        </div>
                                        <p className="text-center">This image will take some time to process. <br />Please wait...</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div> */}

                    {/* Image section */}
                    <div onClick={openViewModal} className="w-full aspect-[16/9] overflow-hidden ring-1 ring-gray-400 rounded-lg hover:ring-2 hover:cursor-pointer transition-all duration-400">
                        <div className="relative w-full h-full">
                            {renderImageContent()}
                        </div>
                    </div>

                    {/* Title section */}
                    <div className="w-full h-14 flex items-start justify-start">
                        {loadingState.title ? (
                            <div className="mt-2 text-[17px] pt-3 pl-3"><DotDotDot /></div>
                        ) : (
                            <EditableTitle
                                initialTitle={currentTitle}
                                onSave={onEditTitle}
                            />
                        )}
                    </div>

                    {/* Action buttons */}
                    <div className="relative border-t border-gray-300 mt-8 grid grid-cols-2 gap-2 py-4 text-xs font-medium">
                        {
                            storyboard.error ? (
                                <div className="absolute -top-5 right-0">
                                    <Tooltip content="Error saving image">
                                        <TbAlertTriangle className="w-4 h-4 opacity-40 text-red-500" />
                                    </Tooltip>
                                </div>
                            ) : !currentImageUrl?.includes('storage.googleapis.com/thumbnailgeek-storyboards')
                                && !currentImageUrl?.includes('firebasestorage.googleapis.com/')
                                ? (
                                    <div className="absolute -top-5 right-0">
                                        <Tooltip content="Saving image to your account...">
                                            <MdOutlineDataSaverOff className="animate-spin opacity-40 w-4 h-4 text-orange-500" />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className="absolute -top-5 right-0">
                                        <Tooltip content="Image saved to your account.">
                                            <BiGitCommit className="w-4 h-4 text-gray-300" />
                                        </Tooltip>
                                    </div>
                                )
                        }

                        {!isProcessing &&
                            <>
                                <button
                                    onClick={onRefreshImage}
                                    className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    <HiRefresh className="-ml-0.5 h-5 w-5" />
                                    Refresh Image
                                </button>
                                <button
                                    onClick={onGenerateTitle}
                                    className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    <RxText className="-ml-0.5 h-5 w-5" />
                                    Refresh Title
                                </button>
                                <button onClick={onChangeConcept} className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out">
                                    <MdTrackChanges className="-ml-0.5 h-5 w-5" />
                                    New Concept
                                </button>
                                <a href={currentImageUrl}
                                    target='_blank'
                                    download={currentImageUrl}
                                    className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    <BiDownArrow size={18} />
                                    Download
                                </a>
                                <button
                                    onClick={() => handleReframe(itemKey)}
                                    className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    <TbFrame size={18} />
                                    Reframe
                                </button>
                                <button
                                    onClick={handleShare}
                                    className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    <TbShare size={18} />
                                    Share
                                </button>
                            </>
                        }

                    </div>
                    {/* <div className="w-full text-sm">
                        {storyboard.main_prompt}
                    </div> */}
                </div >

                {/* Add modals for viewer and variation popup here */}
                {
                    isViewerOpen && (
                        <InteractiveImageViewer
                            imageUrl={currentImageUrl}
                            onClose={() => setIsViewerOpen(false)}
                            onSave={(newUrl) => replaceWithImageFromSave(newUrl)}
                            storyboardId={storyboardId}
                            index={itemKey}
                        />
                    )
                }

                {
                    isVPopupOpen && (
                        <VariationPopup
                            storyboardId={storyboardId}
                            index={itemKey}
                            initialImageSrc={currentImageUrl}
                            onCloseVPopup={() => setIsVPopupOpen(false)}
                            onVariationSelect={(newUrl) => variationSelect(newUrl)}
                        />
                    )
                }
            </div >
        </>
    );
});

export default StoryboardItem;

























// const StoryboardItem = React.memo(({
//     storyboardData,
//     storyboardId,
//     storyboard,
//     itemKey,
//     isLoadingImage,
//     isLoadingTitle,
//     isLoadingCategory,
//     handleViewDetailsState,
//     handleEditTitle,
//     handleRefreshImage,
//     handleGenerateTitle,
//     handleChangeConcept,
//     ilType,
//     openModalKey,
//     handleOutsideClick,
// }) => {
//     const [isViewerOpen, setIsViewerOpen] = useState(false);
//     const [isVPopupOpen, setIsVPopupOpen] = useState(false);
//     const [viewModal, setViewModal] = useState(false);
//     const [currentImageUrl, setCurrentImageUrl] = useState(storyboard.image_url_local_resized);

//     const modalRef = useRef(null);

//     const onRefreshImage = useCallback(() => {
//         handleRefreshImage(itemKey);
//     }, [handleRefreshImage, storyboardId, itemKey]);

//     const onGenerateTitle = useCallback(() => {
//         handleGenerateTitle(itemKey);
//     }, [handleGenerateTitle, itemKey]);

//     const handleShare = async (key) => {
//         toast.info(<>Coming soon.</>, {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     };

//     const handleReframe = () => {
//         viewModalClose();
//         setIsViewerOpen(true);
//     };

//     const handleExport = (exportedImageUrl) => {
//         //console.log('Exported image URL:', exportedImageUrl);
//         toast.success('Image exported successfully!', {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     };

//     const openViewModal = () => {
//         setViewModal(true);
//     }

//     const handleViewModalClose = (e) => {
//         if (e.target.id === "st-item-view-modal") {
//             setViewModal(false);
//         };
//     }

//     const viewModalClose = (e) => {
//         setViewModal(false);
//     }

//     const variationSelect = useCallback((imageUrl) => {
//         setCurrentImageUrl(imageUrl);
//         setIsVPopupOpen(false);
//     }, []);

//     const imageUrl = useMemo(() => {
//         const url = currentImageUrl || storyboard.image_url_local_resized;
//         return url?.includes('storage.googleapis.com/thumbnailgeek-storyboards')
//             ? `${url}?${new Date().getTime()}`
//             : url;
//     }, [currentImageUrl, storyboard.image_url_local_resized]);

//     // Effect to update currentImageUrl when storyboard.image_url_local_resized changes
//     useEffect(() => {
//         setCurrentImageUrl(storyboard.image_url_local_resized);
//     }, [storyboard.image_url_local_resized]);

//     return (
//         <>
//             <div id="st-item-view-modal" onClick={handleViewModalClose} className={`${viewModal === true ? '' : 'hidden'} fixed inset-0 z-50 flex items-center justify-center p-4 rounded-lg bg-black bg-opacity-50`}>
//                 <div ref={modalRef} className="relative max-w-4xl w-full bg-white rounded-lg shadow-lg">
//                     <button
//                         className="absolute top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
//                         onClick={() => viewModalClose()}
//                     >
//                         <CgClose size={24} />
//                     </button>
//                     <img src={`${storyboard.image_url_local_resized}?${Date.now()}`}
//                         className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50"
//                     />

//                     <div className="flex p-4 justify-end">
//                         <button onClick={() => handleReframe(itemKey)}
//                             className="text-sm inline-flex items-center px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
//                         >
//                             <TbFrame size={18} className="mr-2" />
//                             Reframe Image
//                         </button>

//                         <a href={`${storyboard.image_url_local_resized}?${Date.now()}` === '' ? '' : `${storyboard.image_url_local_resized}?${Date.now()}`} target='_blank'
//                             className="text-sm inline-flex items-center ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//                         >
//                             <BiDownArrow size={18} className="mr-2" />
//                             Download
//                         </a>
//                     </div>
//                 </div>
//             </div>

//             <div key={itemKey} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 2xl:w-96">
//                 <div className="px-4 mb-8">
//                     <div className="flex flex-col">

//                         {/* Category section */}
//                         <div className="w-full h-7 flex items-center justify-between">
//                             {isLoadingCategory ? (
//                                 <div className="mt-2 text-[17px] pl-3"><DotDotDot /></div>
//                             ) : (
//                                 <p className='text-gray-500 text-sm mb-3'>{storyboard.thumbnail_type}</p>
//                             )}
//                             <Tooltip content='Select image variation to show'>
//                                 <div onClick={() => setIsVPopupOpen(true)} className="w-6 h-6 text-gray-400 hover:text-gray-700 hover:bg-gray-200 active:bg-gray-300 rounded-md cursor-pointer flex items-center justify-center">
//                                     <TbLayersDifference className='' />
//                                 </div>
//                             </Tooltip>
//                         </div>

//                         {
//                             isVPopupOpen &&
//                             <VariationPopup
//                                 storyboardId={storyboardId}
//                                 index={itemKey}
//                                 initialImageSrc={currentImageUrl}
//                                 onCloseVPopup={() => setIsVPopupOpen(false)}
//                                 onVariationSelect={variationSelect}
//                             />
//                         }

//                         {/* Image section */}
//                         <div onClick={openViewModal} className="w-full aspect-[16/9] overflow-hidden ring-1 ring-gray-400 rounded-lg hover:ring-2 hover:cursor-pointer transition-all duration-400" >
//                             {isLoadingImage ? (
//                                 <div className="relative w-full h-full">
//                                     <div className="absolute inset-0 bg-white/70 flex justify-center items-center w-full h-full">
//                                         <div className='w-40'>
//                                             <Lottie animationData={loading_tg} loop={true} />
//                                         </div>
//                                     </div>
//                                     <img src={imageUrl} alt={storyboard.title} className="object-cover w-full h-full" />
//                                 </div>
//                             ) : (
//                                 <div className="relative w-full h-full">
//                                     {imageUrl?.includes('storage.googleapis.com/thumbnailgeek-storyboards') ? (
//                                         <img src={`${imageUrl}?${Date.now()}`} className="object-cover w-full h-full" />
//                                     ) : (
//                                         <img src={imageUrl} className="object-cover w-full h-full" />
//                                     )}
//                                     {!imageUrl && storyboard.status !== "complete" && (
//                                         <div className="absolute inset-0 z-40 text-xs flex flex-col items-center justify-center text-gray-600">
//                                             <div className="flex flex-col items-center justify-center mb-3">
//                                                 <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
//                                                     <path
//                                                         className="fill-gray-300"
//                                                         d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
//                                                     ></path>
//                                                     <path
//                                                         className="fill-gray-500"
//                                                         d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
//                                                     ></path>
//                                                 </svg>
//                                             </div>
//                                             <p className="text-center">This image will take some time to process. <br />Please wait...</p>
//                                         </div>
//                                     )}
//                                 </div>
//                             )}
//                         </div>


//                         {/* Title section */}
//                         <div className="w-full h-14 flex items-start justify-start">
//                             {isLoadingTitle ? (
//                                 <div className="mt-2 text-[17px] pt-3 pl-3"><DotDotDot /></div>
//                             ) : (
//                                 <EditableTitle
//                                     key={`${itemKey}-${storyboard.title}`}
//                                     initialTitle={storyboard.title}
//                                     onSave={(newTitle) => handleEditTitle(itemKey, newTitle)}
//                                 />
//                             )}
//                         </div>

//                         {isViewerOpen && (
//                             <InteractiveImageViewer
//                                 imageUrl={imageUrl}
//                                 onClose={() => setIsViewerOpen(false)}
//                                 onExport={handleExport}
//                                 storyboardId={storyboardId}
//                                 index={itemKey}
//                             />
//                         )}

//                         {/* Action buttons */}
//                         <div className="border-t border-gray-300 mt-8 grid grid-cols-2 gap-2 py-4 text-xs font-medium">
//                             <button
//                                 onClick={onRefreshImage}
//                                 type="button"
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <HiRefresh aria-hidden="true" className="-ml-0.5 h-5 w-5" />
//                                 New Image
//                             </button>
//                             <button
//                                 onClick={onGenerateTitle}
//                                 type="button"
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <RxText aria-hidden="true" className="-ml-0.5 h-5 w-5" />
//                                 New Title
//                             </button>
//                             <button
//                                 onClick={() => handleChangeConcept(itemKey)}
//                                 type="button"
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <MdTrackChanges aria-hidden="true" className="-ml-0.5 h-5 w-5" />
//                                 New Concept
//                             </button>
//                             <a
//                                 href={storyboard.image_url_local_resized}
//                                 target='_blank'
//                                 download={storyboard.image_url_local_resized || storyboard.image_url}
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <BiDownArrow size={18} className="" />
//                                 Download
//                             </a>
//                             <button
//                                 onClick={() => handleReframe(itemKey)}
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <TbFrame size={18} className="" />
//                                 Reframe
//                             </button>
//                             <button
//                                 onClick={() => handleShare(itemKey)}
//                                 className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600  transition duration-300 ease-in-out"
//                             >
//                                 <TbShare size={18} className="" />
//                                 Share
//                             </button>
//                         </div>

//                         {/* <div className="w-full text-sm">
//                         {storyboard.main_prompt}
//                     </div> */}
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// });

// export default StoryboardItem;










// const StoryboardItem = React.memo(({
//     storyboardId,
//     storyboard,
//     itemKey,
//     isLoadingImage,
//     isLoadingTitle,
//     isLoadingCategory,
//     handleRefreshImage,
//     handleGenerateTitle,
//     handleChangeConcept,
//     handleEditTitle,
// }) => {
//     const [isViewerOpen, setIsViewerOpen] = useState(false);
//     const [isVPopupOpen, setIsVPopupOpen] = useState(false);
//     const [viewModal, setViewModal] = useState(false);
//     const [currentImageUrl, setCurrentImageUrl] = useState(storyboard.image_url_local_resized);

//     const modalRef = useRef(null);

//     const handleShare = useCallback(() => {
//         toast.info('Coming soon.', {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     }, []);

//     const handleReframe = useCallback(() => {
//         setViewModal(false);
//         setIsViewerOpen(true);
//     }, []);

//     const handleExport = useCallback((exportedImageUrl) => {
//         //console.log('Exported image URL:', exportedImageUrl);
//         toast.success('Image exported successfully!', {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     }, []);

//     const openViewModal = useCallback(() => setViewModal(true), []);
//     const closeViewModal = useCallback(() => setViewModal(false), []);

//     const variationSelect = useCallback((imageUrl) => {
//         setCurrentImageUrl(imageUrl);
//         setIsVPopupOpen(false);
//     }, []);

//     const imageUrl = useMemo(() => {
//         const url = currentImageUrl || storyboard.image_url_local_resized;
//         return url?.includes('storage.googleapis.com/thumbnailgeek-storyboards')
//             ? `${url}?${new Date().getTime()}`
//             : url;
//     }, [currentImageUrl, storyboard.image_url_local_resized]);

//     const onRefreshImage = useCallback(() => {
//         if (storyboardId && itemKey) {
//             handleRefreshImage(storyboardId, itemKey);
//         } else {
//             console.error('storyboardId or itemKey is missing', { storyboardId, itemKey });
//         }
//     }, [handleRefreshImage, storyboardId, itemKey]);

//     return (
//         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 2xl:w-96 px-4 mb-8">
//             <div className="flex flex-col">
//                 {/* Category section */}
//                 <div className="w-full h-7 flex items-center justify-between">
//                     {isLoadingCategory ? (
//                         <div className="mt-2 text-[17px] pl-3"><DotDotDot /></div>
//                     ) : (
//                         <p className='text-gray-500 text-sm mb-3'>{storyboard.thumbnail_type}</p>
//                     )}
//                     <Tooltip content='Select image to display'>
//                         <div onClick={() => setIsVPopupOpen(true)} className="w-6 h-6 text-gray-400 hover:text-gray-700 hover:bg-gray-200 active:bg-gray-300 rounded-md cursor-pointer flex items-center justify-center">
//                             <TbLayersDifference />
//                         </div>
//                     </Tooltip>
//                 </div>

//                 {isVPopupOpen && (
//                     <VariationPopup
//                         storyboardId={storyboardId}
//                         index={itemKey}
//                         initialImageSrc={currentImageUrl}
//                         onCloseVPopup={() => setIsVPopupOpen(false)}
//                         onVariationSelect={variationSelect}
//                     />
//                 )}

//                 {/* Image section */}
//                 <div onClick={openViewModal} className="w-full aspect-[16/9] overflow-hidden ring-1 ring-gray-400 rounded-lg hover:ring-2 hover:cursor-pointer transition-all duration-400">
//                     {isLoadingImage ? (
//                         <div className="relative w-full h-full">
//                             <div className="absolute inset-0 bg-white/70 flex justify-center items-center w-full h-full">
//                                 <div className='w-40'>
//                                     <DotDotDot />
//                                 </div>
//                             </div>
//                             <img src={imageUrl} alt={storyboard.title} className="object-cover w-full h-full" />
//                         </div>
//                     ) : (
//                         <img src={imageUrl} alt={storyboard.title} className="object-cover w-full h-full" />
//                     )}
//                 </div>

//                 {/* Title section */}
//                 <div className="w-full h-14 flex items-start justify-start">
//                     {isLoadingTitle ? (
//                         <div className="mt-2 text-[17px] pt-3 pl-3"><DotDotDot /></div>
//                     ) : (
//                         <EditableTitle
//                             key={`${itemKey}-${storyboard.title}`}
//                             initialTitle={storyboard.title}
//                             onSave={(newTitle) => handleEditTitle(itemKey, newTitle)}
//                         />
//                     )}
//                 </div>

//                 {/* Action buttons */}
//                 <div className="relative border-t border-gray-300 mt-8 grid grid-cols-2 gap-2 py-4 text-xs font-medium">

//                     {
//                         !currentImageUrl?.includes('storage.googleapis.com/thumbnailgeek-storyboards') && (
//                             <div className="absolute -top-5 right-0">
//                                 <Tooltip content="Image not yet saved your account. Saving.">
//                                     <MdOutlineDataSaverOff className="animate-spin w-4 h-4 text-gray-300" />
//                                 </Tooltip>
//                             </div>
//                         )
//                     }


//                     <button
//                         // onClick={() => handleRefreshImage(itemKey)}
//                         onClick={onRefreshImage}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <HiRefresh className="-ml-0.5 h-5 w-5" />
//                         New Image
//                     </button>
//                     <button
//                         onClick={() => handleGenerateTitle(itemKey)}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <RxText className="-ml-0.5 h-5 w-5" />
//                         New Title
//                     </button>
//                     <button
//                         onClick={() => handleChangeConcept(itemKey)}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <MdTrackChanges className="-ml-0.5 h-5 w-5" />
//                         New Concept
//                     </button>
//                     <a
//                         href={storyboard.image_url_local_resized}
//                         target='_blank'
//                         download={storyboard.image_url_local_resized || storyboard.image_url}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <BiDownArrow size={18} />
//                         Download
//                     </a>
//                     <button
//                         onClick={handleReframe}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <TbFrame size={18} />
//                         Reframe
//                     </button>
//                     <button
//                         onClick={handleShare}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <TbShare size={18} />
//                         Share
//                     </button>
//                 </div>

//                 <div className="text-sm">{storyboard.main_prompt}</div>
//             </div>

//             {viewModal && (
//                 <div id="st-item-view-modal" onClick={closeViewModal} className="fixed inset-0 z-50 flex items-center justify-center p-4 rounded-lg bg-black bg-opacity-50">
//                     <div ref={modalRef} className="relative max-w-4xl w-full bg-white rounded-lg shadow-lg">
//                         <button
//                             className="absolute top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
//                             onClick={closeViewModal}
//                         >
//                             <CgClose size={24} />
//                         </button>
//                         <img src={imageUrl} className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50" />
//                         <div className="flex p-4 justify-end">
//                             <button onClick={() => setIsVPopupOpen(true)} className="text-sm inline-flex items-center px-4 py-2 ring-1 ring-gray-600 text-gray-700 rounded hover:ring-gray-700 mr-2">
//                                 <MdOutlinePublishedWithChanges size={18} className="mr-2" />
//                                 Switch to Another Image
//                             </button>
//                             <button onClick={handleReframe} className="text-sm inline-flex items-center px-4 py-2 ring-1 ring-gray-600 text-gray-700 hover:ring-gray-700 rounded mr-2">
//                                 <TbFrame size={18} className="mr-2" />
//                                 Reframe Image
//                             </button>
//                             <a href={imageUrl} target='_blank' download className="text-sm inline-flex items-center px-4 py-2 ring-1 ring-blue-600 bg-blue-500 text-white rounded hover:bg-blue-600">
//                                 <BiDownArrow size={18} className="mr-2" />
//                                 Download
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {isViewerOpen && (
//                 <InteractiveImageViewer
//                     imageUrl={imageUrl}
//                     onClose={() => setIsViewerOpen(false)}
//                     onExport={handleExport}
//                     storyboardId={storyboardId}
//                     index={itemKey}
//                 />
//             )}
//         </div>
//     );
// });

// export default StoryboardItem;



// import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// import html2canvas from "html2canvas";
// import LazyLoad from 'react-lazyload';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import { db, storage } from '../../firebase-config';
// import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
// import { CgClose } from 'react-icons/cg';
// import { BiDownArrow } from 'react-icons/bi';
// import { HiHand, HiRefresh } from 'react-icons/hi';
// import { RxMove, RxText } from 'react-icons/rx';
// import { MdFontDownload, MdTextDecrease, MdTextIncrease, MdTrackChanges } from 'react-icons/md';
// import DotDotDot from './DotDotDot'; // Assuming this component exists
// import Lottie from 'lottie-react'; // Assuming you're using lottie for animations
// import loading_tg from "../../assets/animations/loading_tg.json"; // Assuming this is your loading animation
// import { TbFrame, TbLayersDifference, TbPick, TbShare } from 'react-icons/tb';
// import { TrashIcon } from '@heroicons/react/24/outline';
// import { toast } from 'react-toastify';
// import { RiBold, RiZoomInLine, RiZoomOutLine } from 'react-icons/ri';
// import { IoRefreshSharp, IoText } from 'react-icons/io5';
// import Tooltip from './Tooltip';
// import { FaBold, FaCheck } from 'react-icons/fa';
// import { BsCheck2 } from 'react-icons/bs';

// // const InteractiveImageViewer = ({ imageUrl, onClose, onExport, storyboardId, index }) => {
// //     const functions = getFunctions();
// //     const exportImageMockup = httpsCallable(functions, 'exportImageMockup');

// //     const [scale, setScale] = useState(1);
// //     const [position, setPosition] = useState({ x: 0, y: 0 });
// //     const [isDragging, setIsDragging] = useState(false);
// //     const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
// //     const [minScale, setMinScale] = useState(1);
// //     const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
// //     const [activeTool, setActiveTool] = useState('move');
// //     const [onImageText, setOnImageText] = useState('');
// //     const [textOverlays, setTextOverlays] = useState([]);
// //     const [selectedOverlay, setSelectedOverlay] = useState(null);
// //     const [isDraggingOverlay, setIsDraggingOverlay] = useState(false);
// //     const [overlayDragStart, setOverlayDragStart] = useState({ x: 0, y: 0 });
// //     const [fontSize, setFontSize] = useState(70);
// //     const [fontWeight, setFontWeight] = useState(300);
// //     const [showTextBackground, setShowTextBackground] = useState(false);
// //     const [isExporting, setIsExporting] = useState(false);
// //     const [isSuccess, setIsSuccess] = useState(false);
// //     const [showTextControls, setShowTextControls] = useState(false);
// //     const [imageLoaded, setImageLoaded] = useState(false);
// //     const [imageLoadError, setImageLoadError] = useState(null);
// //     const fontWeights = [400, 500, 600, 700, 800, 900];

// //     const imageRef = useRef(null);
// //     const canvasRef = useRef(null);
// //     const containerRef = useRef(null);
// //     const animationRef = useRef(null);

// //     const [pageFont, setPageFont] = useState('');

// //     // Function to get the page font
// //     const getPageFont = useCallback(() => {
// //         const body = document.body;
// //         const computedStyle = window.getComputedStyle(body);
// //         return computedStyle.getPropertyValue('font-family');
// //     }, []);

// //     // Effect to set the page font when the component mounts
// //     useEffect(() => {
// //         setPageFont(getPageFont());
// //     }, [getPageFont]);


// //     const resizeCanvas = useCallback(() => {
// //         const container = containerRef.current;
// //         const image = imageRef.current;
// //         if (!container || !image) return;

// //         const containerWidth = container.clientWidth;
// //         const containerHeight = container.clientHeight;
// //         const containerAspectRatio = containerWidth / containerHeight;
// //         const imageAspectRatio = image.width / image.height;

// //         let scale;
// //         if (imageAspectRatio > containerAspectRatio) {
// //             scale = containerWidth / image.width;
// //         } else {
// //             scale = containerHeight / image.height;
// //         }

// //         const canvas = canvasRef.current;
// //         canvas.width = containerWidth;
// //         canvas.height = containerHeight;
// //         setScale(scale);
// //         setMinScale(scale);
// //         setPosition({ x: 0, y: 0 });

// //         drawImage();
// //     }, []);

// //     const drawImage = useCallback(() => {
// //         const canvas = canvasRef.current;
// //         const image = imageRef.current;
// //         if (!canvas || !image) return;

// //         const ctx = canvas.getContext('2d');
// //         ctx.clearRect(0, 0, canvas.width, canvas.height);

// //         const scaledWidth = image.width * scale;
// //         const scaledHeight = image.height * scale;
// //         const x = (canvas.width - scaledWidth) / 2 + position.x;
// //         const y = (canvas.height - scaledHeight) / 2 + position.y;

// //         ctx.drawImage(image, x, y, scaledWidth, scaledHeight);
// //         drawTextOverlays();
// //     }, [scale, position, imageSize, textOverlays]);

// //     const drawTextOverlays = useCallback(() => {
// //         const canvas = canvasRef.current;
// //         const image = imageRef.current;
// //         if (!canvas || !image) return;

// //         const ctx = canvas.getContext('2d');
// //         const scaledWidth = image.width * scale;
// //         const scaledHeight = image.height * scale;
// //         const x = (canvas.width - scaledWidth) / 2 + position.x;
// //         const y = (canvas.height - scaledHeight) / 2 + position.y;

// //         ctx.save();
// //         ctx.translate(x, y);
// //         ctx.scale(scale, scale);

// //         textOverlays.forEach((overlay) => {
// //             ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px ${pageFont}, Arial`;
// //             const metrics = ctx.measureText(overlay.text);
// //             const textHeight = overlay.fontSize;

// //             if (selectedOverlay === overlay.id && activeTool === 'text') {
// //                 ctx.fillStyle = 'rgba(0, 123, 255, 0.2)';
// //                 ctx.fillRect(
// //                     overlay.x - 4 / scale,
// //                     overlay.y - textHeight - 4 / scale,
// //                     metrics.width + 8 / scale,
// //                     textHeight + 8 / scale
// //                 );
// //                 ctx.strokeStyle = 'rgba(0, 123, 255, 0.8)';
// //                 ctx.lineWidth = 2 / scale;
// //                 ctx.strokeRect(
// //                     overlay.x - 4 / scale,
// //                     overlay.y - textHeight - 4 / scale,
// //                     metrics.width + 8 / scale,
// //                     textHeight + 8 / scale
// //                 );
// //             }

// //             if (overlay.showBackground) {
// //                 ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
// //                 ctx.fillRect(
// //                     overlay.x - 2 / scale,
// //                     overlay.y - textHeight - 2 / scale,
// //                     metrics.width + 4 / scale,
// //                     textHeight + 4 / scale
// //                 );
// //             }

// //             ctx.fillStyle = overlay.color;
// //             ctx.fillText(overlay.text, overlay.x, overlay.y);
// //         });

// //         ctx.restore();
// //     }, [textOverlays, scale, position, selectedOverlay, activeTool]);

// //     const handleCanvasClick = useCallback((e) => {
// //         const canvas = canvasRef.current;
// //         const image = imageRef.current;
// //         if (!canvas || !image) return;

// //         const rect = canvas.getBoundingClientRect();
// //         const scaledWidth = image.width * scale;
// //         const scaledHeight = image.height * scale;
// //         const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
// //         const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

// //         const clickX = (e.clientX - rect.left - offsetX) / scale;
// //         const clickY = (e.clientY - rect.top - offsetY) / scale;

// //         const clickedOverlay = textOverlays.find(overlay => {
// //             const ctx = canvas.getContext('2d');
// //             ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
// //             const metrics = ctx.measureText(overlay.text);
// //             const textHeight = overlay.fontSize;

// //             return (
// //                 clickX >= overlay.x &&
// //                 clickX <= overlay.x + metrics.width &&
// //                 clickY >= overlay.y - textHeight &&
// //                 clickY <= overlay.y
// //             );
// //         });

// //         if (activeTool === 'move') {
// //             if (clickedOverlay) {
// //                 setActiveTool('text');
// //                 setSelectedOverlay(clickedOverlay.id);
// //             } else {
// //                 setSelectedOverlay(null); // Deselect text when clicking empty space in Move mode
// //             }
// //         } else if (activeTool === 'text') {
// //             if (clickedOverlay) {
// //                 setSelectedOverlay(clickedOverlay.id);
// //             } else {
// //                 setSelectedOverlay(null); // Deselect text when clicking empty space in Text mode
// //             }
// //         }

// //         // Ensure state updates are applied
// //         setTextOverlays(prevOverlays => [...prevOverlays]);

// //         drawImage();
// //     }, [activeTool, textOverlays, position, scale, drawImage]);

// //     const handleMouseDown = useCallback((e) => {
// //         if (activeTool === 'text') {
// //             const canvas = canvasRef.current;
// //             const image = imageRef.current;
// //             if (!canvas || !image) return;

// //             const rect = canvas.getBoundingClientRect();
// //             const scaledWidth = image.width * scale;
// //             const scaledHeight = image.height * scale;
// //             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
// //             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

// //             const clickX = (e.clientX - rect.left - offsetX) / scale;
// //             const clickY = (e.clientY - rect.top - offsetY) / scale;

// //             const clickedOverlay = textOverlays.find(overlay => {
// //                 const ctx = canvas.getContext('2d');
// //                 ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
// //                 const metrics = ctx.measureText(overlay.text);
// //                 const textHeight = overlay.fontSize;

// //                 return (
// //                     clickX >= overlay.x &&
// //                     clickX <= overlay.x + metrics.width &&
// //                     clickY >= overlay.y - textHeight &&
// //                     clickY <= overlay.y
// //                 );
// //             });

// //             if (clickedOverlay) {
// //                 setSelectedOverlay(clickedOverlay.id);
// //                 setIsDraggingOverlay(true);
// //                 setOverlayDragStart({ x: clickX - clickedOverlay.x, y: clickY - clickedOverlay.y });
// //             } else {
// //                 setSelectedOverlay(null);
// //                 setIsDraggingOverlay(false);
// //             }

// //             drawImage();
// //         } else if (activeTool === 'move') {
// //             setIsDragging(true);
// //             setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
// //         }
// //     }, [activeTool, textOverlays, position, scale, drawImage]);

// //     const handleMouseMove = useCallback((e) => {
// //         if (isDraggingOverlay && activeTool === 'text') {
// //             const canvas = canvasRef.current;
// //             const image = imageRef.current;
// //             if (!canvas || !image) return;

// //             const rect = canvas.getBoundingClientRect();
// //             const scaledWidth = image.width * scale;
// //             const scaledHeight = image.height * scale;
// //             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
// //             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

// //             const newX = (e.clientX - rect.left - offsetX) / scale - overlayDragStart.x;
// //             const newY = (e.clientY - rect.top - offsetY) / scale - overlayDragStart.y;

// //             setTextOverlays(prevOverlays =>
// //                 prevOverlays.map(overlay =>
// //                     overlay.id === selectedOverlay
// //                         ? { ...overlay, x: newX, y: newY }
// //                         : overlay
// //                 )
// //             );

// //             drawImage();
// //         } else if (isDragging && activeTool === 'move') {
// //             if (animationRef.current) {
// //                 cancelAnimationFrame(animationRef.current);
// //             }

// //             animationRef.current = requestAnimationFrame(() => {
// //                 const newX = e.clientX - dragStart.x;
// //                 const newY = e.clientY - dragStart.y;
// //                 setPosition({ x: newX, y: newY });
// //             });
// //         }
// //     }, [isDragging, isDraggingOverlay, activeTool, dragStart, selectedOverlay, overlayDragStart, scale, position, drawImage]);

// //     const handleMouseUp = useCallback(() => {
// //         setIsDragging(false);
// //         setIsDraggingOverlay(false);
// //         if (animationRef.current) {
// //             cancelAnimationFrame(animationRef.current);
// //         }
// //         drawImage();
// //     }, [drawImage]);

// //     const handleToolChange = useCallback((tool) => {
// //         setActiveTool(tool);
// //         setSelectedOverlay(null);
// //         setIsDragging(false);
// //         setIsDraggingOverlay(false);

// //         setTextOverlays(prevOverlays => [...prevOverlays]);

// //         drawImage();
// //     }, [drawImage]);

// //     const handleAddText = useCallback(() => {
// //         if (onImageText && canvasRef.current && imageRef.current) {
// //             const image = imageRef.current;
// //             const newOverlay = {
// //                 id: Date.now(),
// //                 text: onImageText,
// //                 x: image.width / 2,
// //                 y: image.height / 2,
// //                 fontSize,
// //                 fontWeight,
// //                 color: 'black',
// //                 showBackground: showTextBackground,
// //                 fontFamily: pageFont // Add this line
// //             };
// //             setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
// //             setOnImageText('');
// //             drawImage();
// //         }
// //     }, [onImageText, fontSize, fontWeight, showTextBackground, drawImage]);

// //     const handleKeyPress = useCallback((e) => {
// //         if (e.key === 'Enter' && activeTool === 'text') {
// //             handleAddText();
// //         }
// //     }, [activeTool, handleAddText]);

// //     const handleDoubleClick = useCallback((e) => {
// //         if (activeTool === 'text') {
// //             const canvas = canvasRef.current;
// //             const image = imageRef.current;
// //             if (!canvas || !image) return;

// //             const rect = canvas.getBoundingClientRect();
// //             const scaledWidth = image.width * scale;
// //             const scaledHeight = image.height * scale;
// //             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
// //             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

// //             const clickX = (e.clientX - rect.left - offsetX) / scale;
// //             const clickY = (e.clientY - rect.top - offsetY) / scale;

// //             const newOverlay = {
// //                 id: Date.now(),
// //                 text: 'Text overlay',
// //                 x: clickX,
// //                 y: clickY,
// //                 fontSize,
// //                 fontWeight,
// //                 color: 'black',
// //                 showBackground: showTextBackground,
// //                 fontFamily: pageFont
// //             };
// //             setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
// //             drawImage();
// //         }
// //     }, [activeTool, scale, position, fontSize, fontWeight, showTextBackground, pageFont, drawImage]);

// //     const handleDeleteText = useCallback(() => {
// //         if (selectedOverlay) {
// //             setTextOverlays(prevOverlays => prevOverlays.filter(overlay => overlay.id !== selectedOverlay));
// //             setSelectedOverlay(null);
// //             drawImage();
// //         }
// //     }, [selectedOverlay, drawImage]);

// //     const handleZoom = useCallback((delta) => {
// //         if (activeTool === 'move') {
// //             setScale(prevScale => {
// //                 const newScale = Math.max(minScale, prevScale + delta);
// //                 return newScale;
// //             });
// //         }
// //     }, [activeTool, minScale]);

// //     const handleReset = useCallback(() => {
// //         if (activeTool === 'move') {
// //             setScale(minScale);
// //             setPosition({ x: 0, y: 0 });
// //         }
// //     }, [activeTool, minScale]);

// //     const handleFontWeightChange = useCallback((action) => {
// //         if (selectedOverlay) {
// //             setTextOverlays(prevOverlays =>
// //                 prevOverlays.map(overlay => {
// //                     if (overlay.id === selectedOverlay) {
// //                         const currentIndex = fontWeights.indexOf(overlay.fontWeight);
// //                         let newIndex;
// //                         if (action === 'increase') {
// //                             newIndex = Math.min(fontWeights.length - 1, currentIndex + 1);
// //                         } else {
// //                             newIndex = Math.max(0, currentIndex - 1);
// //                         }
// //                         return { ...overlay, fontWeight: fontWeights[newIndex] };
// //                     }
// //                     return overlay;
// //                 })
// //             );
// //             drawImage();
// //         }
// //     }, [selectedOverlay, drawImage, fontWeights]);

// //     const handleFontSizeChange = useCallback((delta) => {
// //         if (selectedOverlay) {
// //             setTextOverlays(prevOverlays =>
// //                 prevOverlays.map(overlay =>
// //                     overlay.id === selectedOverlay
// //                         ? { ...overlay, fontSize: Math.max(10, overlay.fontSize + delta) }
// //                         : overlay
// //                 )
// //             );
// //             drawImage();
// //         }
// //     }, [selectedOverlay, drawImage]);

// //     const toggleTextBackground = useCallback(() => {
// //         if (selectedOverlay) {
// //             setTextOverlays(prevOverlays =>
// //                 prevOverlays.map(overlay =>
// //                     overlay.id === selectedOverlay
// //                         ? { ...overlay, showBackground: !overlay.showBackground }
// //                         : overlay
// //                 )
// //             );
// //             drawImage();
// //         }
// //     }, [selectedOverlay, drawImage]);

// //     const handleExport = async () => {
// //         setIsExporting(true);
// //         try {
// //             const canvas = canvasRef.current;
// //             if (canvas) {
// //                 const dataUrl = canvas.toDataURL('image/png');
// //                 await exportImageMockup({ imageData: dataUrl, storyboardId, index });
// //                 setIsSuccess(true);
// //             }
// //         } catch (error) {
// //             console.error('Export failed:', error);
// //         } finally {
// //             setIsExporting(false);
// //         }
// //     };

// //     useEffect(() => {
// //         const image = new Image();
// //         image.onload = function () {
// //             imageRef.current = image;
// //             setImageSize({ width: image.width, height: image.height });
// //             setImageLoaded(true);
// //             resizeCanvas();
// //         };
// //         image.onerror = function (err) {
// //             console.error("Error loading image:", err);
// //             setImageLoadError(err);
// //         };
// //         image.src = imageUrl;

// //         window.addEventListener('resize', resizeCanvas);
// //         return () => {
// //             window.removeEventListener('resize', resizeCanvas);
// //             if (animationRef.current) {
// //                 cancelAnimationFrame(animationRef.current);
// //             }
// //         };
// //     }, [imageUrl, resizeCanvas]);

// //     useEffect(() => {
// //         drawImage();
// //     }, [drawImage, scale, position]);

// //     useEffect(() => {
// //         if (activeTool === 'text') {
// //             drawImage();
// //         }
// //     }, [activeTool, drawImage]);

// //     const toggleTextControls = () => setShowTextControls(prev => !prev);

// //     return (
// //         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
// //             <div className="relative bg-white rounded-lg max-w-4xl w-full flex flex-col">
// //                 <button
// //                     className="absolute z-50 top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
// //                     onClick={onClose}
// //                 >
// //                     <CgClose size={24} />
// //                 </button>
// //                 <div
// //                     ref={containerRef}
// //                     className="w-full overflow-hidden rounded-t-lg"
// //                     style={{ aspectRatio: '16/9' }}
// //                 >
// //                     <canvas
// //                         ref={canvasRef}
// //                         className="max-w-none rounded-t-lg"
// //                         onClick={handleCanvasClick}
// //                         onDoubleClick={handleDoubleClick}
// //                         onMouseDown={handleMouseDown}
// //                         onMouseMove={handleMouseMove}
// //                         onMouseUp={handleMouseUp}
// //                         onMouseLeave={handleMouseUp}
// //                         style={{ cursor: activeTool === 'move' ? 'move' : 'default' }}
// //                     />
// //                 </div>
// //                 <div className="p-4 space-x-2 flex justify-between">
// //                     <div className="inline-flex">
// //                         <div className='ring-1 ring-gray-300 rounded-lg p-2 mr-2 inline-flex items-center'>
// //                             <Tooltip content="Move mode">
// //                                 <button
// //                                     className={`px-2 py-2 ${activeTool === 'move' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg mr-2`}
// //                                     onClick={() => handleToolChange('move')}
// //                                 >
// //                                     <RxMove className='w-5 h-5' />
// //                                 </button>
// //                             </Tooltip>
// //                             <Tooltip content="Text Mode">
// //                                 <button
// //                                     className={`px-2 py-2 ${activeTool === 'text' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg`}
// //                                     onClick={() => handleToolChange('text')}
// //                                 >
// //                                     <RxText className='w-5 h-5' />
// //                                 </button>
// //                             </Tooltip>
// //                         </div>

// //                         {activeTool === 'text' && (
// //                             <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
// //                                 <input
// //                                     type="text"
// //                                     placeholder='Enter overlay text'
// //                                     value={onImageText}
// //                                     onChange={(e) => setOnImageText(e.target.value)}
// //                                     onKeyPress={handleKeyPress}
// //                                     className="mr-2 -mt-0 p-2 bg-gray-100 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg"
// //                                 />
// //                                 <Tooltip content="Add text to image">
// //                                     <button
// //                                         className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
// //                                         onClick={handleAddText}
// //                                     >
// //                                         <IoAddSharp className='w-5 h-5' />
// //                                     </button>
// //                                 </Tooltip>
// //                                 <Tooltip content="Delete selected text">
// //                                     <button
// //                                         className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
// //                                         onClick={handleDeleteText}
// //                                     >
// //                                         <TrashIcon className='w-5 h-5' />
// //                                     </button>
// //                                 </Tooltip>
// //                                 <div className="relative">
// //                                     {showTextControls && (
// //                                         <div className="p-1 absolute -top-12 right-1/2 translate-x-1/2 inline-flex items-center bg-white rounded-lg ring-1 ring-gray-300">
// //                                             <Tooltip content="Decrease boldness">
// //                                                 <button
// //                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
// //                                                     onClick={() => handleFontWeightChange('decrease')}
// //                                                 >
// //                                                     <RiBold className='w-5 h-5' />
// //                                                 </button>
// //                                             </Tooltip>
// //                                             <Tooltip content="Increase boldness">
// //                                                 <button
// //                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
// //                                                     onClick={() => handleFontWeightChange('increase')}
// //                                                 >
// //                                                     <FaBold className='w-5 h-5' />
// //                                                 </button>
// //                                             </Tooltip>
// //                                             <Tooltip content="Decrease text size">
// //                                                 <button
// //                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
// //                                                     onClick={() => handleFontSizeChange(-2)}
// //                                                 >
// //                                                     <MdTextDecrease className='w-5 h-5' />
// //                                                 </button>
// //                                             </Tooltip>
// //                                             <Tooltip content="Increase text size">
// //                                                 <button
// //                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
// //                                                     onClick={() => handleFontSizeChange(2)}
// //                                                 >
// //                                                     <MdTextIncrease className='w-5 h-5' />
// //                                                 </button>
// //                                             </Tooltip>
// //                                             <Tooltip content="Add/Remove text background">
// //                                                 <button
// //                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
// //                                                     onClick={toggleTextBackground}
// //                                                 >
// //                                                     <MdFontDownload className='w-5 h-5' />
// //                                                 </button>
// //                                             </Tooltip>
// //                                         </div>
// //                                     )}
// //                                     <Tooltip content="Show/Hide text controls">
// //                                         <button
// //                                             className={`px-2 py-2 text-gray-600 hover:text-gray-700 ${showTextControls ? 'bg-gray-200' : ''} hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
// //                                             onClick={toggleTextControls}
// //                                         >
// //                                             <IoText className='w-5 h-5' />
// //                                         </button>
// //                                     </Tooltip>
// //                                 </div>
// //                             </div>
// //                         )}
// //                         {activeTool === 'move' && (
// //                             <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
// //                                 <Tooltip content="Reset move and zoom state">
// //                                     <button
// //                                         onClick={handleReset}
// //                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
// //                                     >
// //                                         <IoRefreshSharp className='w-5 h-5' />
// //                                     </button>
// //                                 </Tooltip>
// //                                 <Tooltip content="Zoom Out">
// //                                     <button
// //                                         onClick={() => handleZoom(-0.1)}
// //                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
// //                                         disabled={activeTool === 'text'}
// //                                     >
// //                                         <RiZoomOutLine className='w-5 h-5' />
// //                                     </button>
// //                                 </Tooltip>
// //                                 <Tooltip content="Zoom In">
// //                                     <button
// //                                         onClick={() => handleZoom(0.1)}
// //                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg"
// //                                         disabled={activeTool === 'text'}
// //                                     >
// //                                         <RiZoomInLine className='w-5 h-5' />
// //                                     </button>
// //                                 </Tooltip>
// //                             </div>
// //                         )}
// //                     </div>
// //                     <div>
// //                         <div className="h-full w-full rounded-lg bg-[#223] grid place-items-center">
// //                             <button
// //                                 onClick={handleExport}
// //                                 disabled={isExporting || isSuccess}
// //                                 className={`
// //                                 relative w-60 h-50 grid place-content-center
// //                                 text-white text-shadow
// //                                 border-5 border-transparent rounded-2xl
// //                                 bg-gradient-border
// //                                 transition-all duration-300
// //                                 ${isExporting ? 'animate-bg-spin' : 'hover:animate-none'}
// //                                 ${isSuccess ? 'bg-gradient-success' : ''}
// //                             `}
// //                             >
// //                                 <span className="relative z-10">
// //                                     {isSuccess ? 'Save Successful!' : isExporting ? 'Saving...' : 'Save Mockup Idea'}
// //                                 </span>
// //                             </button>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// const InteractiveImageViewer = ({ imageUrl, onClose, onExport, storyboardId, index }) => {
//     const functions = getFunctions();
//     const exportImageMockup = httpsCallable(functions, 'exportImageMockup');

//     const [scale, setScale] = useState(1);
//     const [position, setPosition] = useState({ x: 0, y: 0 });
//     const [isDragging, setIsDragging] = useState(false);
//     const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
//     const [minScale, setMinScale] = useState(1);
//     const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
//     const [activeTool, setActiveTool] = useState('move');
//     const [onImageText, setOnImageText] = useState('');
//     const [textOverlays, setTextOverlays] = useState([]);
//     const [selectedOverlay, setSelectedOverlay] = useState(null);
//     const [isDraggingOverlay, setIsDraggingOverlay] = useState(false);
//     const [overlayDragStart, setOverlayDragStart] = useState({ x: 0, y: 0 });
//     const [fontSize, setFontSize] = useState(70);
//     const [fontWeight, setFontWeight] = useState(300);
//     const [showTextBackground, setShowTextBackground] = useState(false);
//     const [isExporting, setIsExporting] = useState(false);
//     const [isSuccess, setIsSuccess] = useState(false);
//     const [showTextControls, setShowTextControls] = useState(false);
//     const [imageLoaded, setImageLoaded] = useState(false);
//     const [imageLoadError, setImageLoadError] = useState(null);
//     const fontWeights = [400, 500, 600, 700, 800, 900];
//     const [editingOverlay, setEditingOverlay] = useState(null);
//     const [editText, setEditText] = useState('');

//     const editInputRef = useRef(null);
//     const imageRef = useRef(null);
//     const canvasRef = useRef(null);
//     const containerRef = useRef(null);
//     const animationRef = useRef(null);

//     const [pageFont, setPageFont] = useState('');

//     // Function to get the page font
//     const getPageFont = useCallback(() => {
//         const body = document.body;
//         const computedStyle = window.getComputedStyle(body);
//         return computedStyle.getPropertyValue('font-family');
//     }, []);

//     // Effect to set the page font when the component mounts
//     useEffect(() => {
//         setPageFont(getPageFont());
//     }, [getPageFont]);


//     const resizeCanvas = useCallback(() => {
//         const container = containerRef.current;
//         const image = imageRef.current;
//         if (!container || !image) return;

//         const containerWidth = container.clientWidth;
//         const containerHeight = container.clientHeight;
//         const containerAspectRatio = containerWidth / containerHeight;
//         const imageAspectRatio = image.width / image.height;

//         let scale;
//         if (imageAspectRatio > containerAspectRatio) {
//             scale = containerWidth / image.width;
//         } else {
//             scale = containerHeight / image.height;
//         }

//         const canvas = canvasRef.current;
//         canvas.width = containerWidth;
//         canvas.height = containerHeight;
//         setScale(scale);
//         setMinScale(scale);
//         setPosition({ x: 0, y: 0 });

//         drawImage();
//     }, []);

//     const drawImage = useCallback(() => {
//         const canvas = canvasRef.current;
//         const image = imageRef.current;
//         if (!canvas || !image) return;

//         const ctx = canvas.getContext('2d');
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const scaledWidth = image.width * scale;
//         const scaledHeight = image.height * scale;
//         const x = (canvas.width - scaledWidth) / 2 + position.x;
//         const y = (canvas.height - scaledHeight) / 2 + position.y;

//         ctx.drawImage(image, x, y, scaledWidth, scaledHeight);
//         drawTextOverlays();
//     }, [scale, position, imageSize, textOverlays]);

//     const drawTextOverlays = useCallback(() => {
//         const canvas = canvasRef.current;
//         const image = imageRef.current;
//         if (!canvas || !image) return;

//         const ctx = canvas.getContext('2d');
//         const scaledWidth = image.width * scale;
//         const scaledHeight = image.height * scale;
//         const x = (canvas.width - scaledWidth) / 2 + position.x;
//         const y = (canvas.height - scaledHeight) / 2 + position.y;

//         ctx.save();
//         ctx.translate(x, y);
//         ctx.scale(scale, scale);

//         textOverlays.forEach((overlay) => {
//             // Skip drawing the overlay that's currently being edited
//             if (overlay.id === editingOverlay) return;

//             ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px ${pageFont}, Arial`;
//             const metrics = ctx.measureText(overlay.text);
//             const textHeight = overlay.fontSize;

//             if (selectedOverlay === overlay.id && activeTool === 'text') {
//                 ctx.fillStyle = 'rgba(0, 123, 255, 0.2)';
//                 ctx.fillRect(
//                     overlay.x - 4 / scale,
//                     overlay.y - textHeight - 4 / scale,
//                     metrics.width + 8 / scale,
//                     textHeight + 8 / scale
//                 );
//                 ctx.strokeStyle = 'rgba(0, 123, 255, 0.8)';
//                 ctx.lineWidth = 2 / scale;
//                 ctx.strokeRect(
//                     overlay.x - 4 / scale,
//                     overlay.y - textHeight - 4 / scale,
//                     metrics.width + 8 / scale,
//                     textHeight + 8 / scale
//                 );
//             }

//             if (overlay.showBackground) {
//                 ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
//                 ctx.fillRect(
//                     overlay.x - 2 / scale,
//                     overlay.y - textHeight - 2 / scale,
//                     metrics.width + 4 / scale,
//                     textHeight + 4 / scale
//                 );
//             }

//             ctx.fillStyle = overlay.color;
//             ctx.fillText(overlay.text, overlay.x, overlay.y);
//         });

//         ctx.restore();
//     }, [textOverlays, scale, position, selectedOverlay, activeTool, editingOverlay, pageFont]);

//     const handleCanvasClick = useCallback((e) => {
//         const canvas = canvasRef.current;
//         const image = imageRef.current;
//         if (!canvas || !image) return;

//         const rect = canvas.getBoundingClientRect();
//         const scaledWidth = image.width * scale;
//         const scaledHeight = image.height * scale;
//         const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
//         const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

//         const clickX = (e.clientX - rect.left - offsetX) / scale;
//         const clickY = (e.clientY - rect.top - offsetY) / scale;

//         const clickedOverlay = textOverlays.find(overlay => {
//             const ctx = canvas.getContext('2d');
//             ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
//             const metrics = ctx.measureText(overlay.text);
//             const textHeight = overlay.fontSize;

//             return (
//                 clickX >= overlay.x &&
//                 clickX <= overlay.x + metrics.width &&
//                 clickY >= overlay.y - textHeight &&
//                 clickY <= overlay.y
//             );
//         });

//         if (activeTool === 'move') {
//             if (clickedOverlay) {
//                 setActiveTool('text');
//                 setSelectedOverlay(clickedOverlay.id);
//             } else {
//                 setSelectedOverlay(null); // Deselect text when clicking empty space in Move mode
//             }
//         } else if (activeTool === 'text') {
//             if (clickedOverlay) {
//                 setSelectedOverlay(clickedOverlay.id);
//             } else {
//                 setSelectedOverlay(null); // Deselect text when clicking empty space in Text mode
//             }
//         }

//         // Ensure state updates are applied
//         setTextOverlays(prevOverlays => [...prevOverlays]);

//         drawImage();
//     }, [activeTool, textOverlays, position, scale, drawImage]);

//     const handleMouseDown = useCallback((e) => {
//         if (activeTool === 'text') {
//             const canvas = canvasRef.current;
//             const image = imageRef.current;
//             if (!canvas || !image) return;

//             const rect = canvas.getBoundingClientRect();
//             const scaledWidth = image.width * scale;
//             const scaledHeight = image.height * scale;
//             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
//             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

//             const clickX = (e.clientX - rect.left - offsetX) / scale;
//             const clickY = (e.clientY - rect.top - offsetY) / scale;

//             const clickedOverlay = textOverlays.find(overlay => {
//                 const ctx = canvas.getContext('2d');
//                 ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px Arial`;
//                 const metrics = ctx.measureText(overlay.text);
//                 const textHeight = overlay.fontSize;

//                 return (
//                     clickX >= overlay.x &&
//                     clickX <= overlay.x + metrics.width &&
//                     clickY >= overlay.y - textHeight &&
//                     clickY <= overlay.y
//                 );
//             });

//             if (clickedOverlay) {
//                 setSelectedOverlay(clickedOverlay.id);
//                 setIsDraggingOverlay(true);
//                 setOverlayDragStart({ x: clickX - clickedOverlay.x, y: clickY - clickedOverlay.y });
//             } else {
//                 setSelectedOverlay(null);
//                 setIsDraggingOverlay(false);
//             }

//             drawImage();
//         } else if (activeTool === 'move') {
//             setIsDragging(true);
//             setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
//         }
//     }, [activeTool, textOverlays, position, scale, drawImage]);

//     const handleMouseMove = useCallback((e) => {
//         if (isDraggingOverlay && activeTool === 'text') {
//             const canvas = canvasRef.current;
//             const image = imageRef.current;
//             if (!canvas || !image) return;

//             const rect = canvas.getBoundingClientRect();
//             const scaledWidth = image.width * scale;
//             const scaledHeight = image.height * scale;
//             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
//             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

//             const newX = (e.clientX - rect.left - offsetX) / scale - overlayDragStart.x;
//             const newY = (e.clientY - rect.top - offsetY) / scale - overlayDragStart.y;

//             setTextOverlays(prevOverlays =>
//                 prevOverlays.map(overlay =>
//                     overlay.id === selectedOverlay
//                         ? { ...overlay, x: newX, y: newY }
//                         : overlay
//                 )
//             );

//             drawImage();
//         } else if (isDragging && activeTool === 'move') {
//             if (animationRef.current) {
//                 cancelAnimationFrame(animationRef.current);
//             }

//             animationRef.current = requestAnimationFrame(() => {
//                 const newX = e.clientX - dragStart.x;
//                 const newY = e.clientY - dragStart.y;
//                 setPosition({ x: newX, y: newY });
//             });
//         }
//     }, [isDragging, isDraggingOverlay, activeTool, dragStart, selectedOverlay, overlayDragStart, scale, position, drawImage]);

//     const handleMouseUp = useCallback(() => {
//         setIsDragging(false);
//         setIsDraggingOverlay(false);
//         if (animationRef.current) {
//             cancelAnimationFrame(animationRef.current);
//         }
//         drawImage();
//     }, [drawImage]);

//     const handleToolChange = useCallback((tool) => {
//         setActiveTool(tool);
//         setSelectedOverlay(null);
//         setIsDragging(false);
//         setIsDraggingOverlay(false);

//         setTextOverlays(prevOverlays => [...prevOverlays]);

//         drawImage();
//     }, [drawImage]);

//     const handleAddText = useCallback(() => {
//         if (onImageText && canvasRef.current && imageRef.current) {
//             const image = imageRef.current;
//             const newOverlay = {
//                 id: Date.now(),
//                 text: onImageText,
//                 x: image.width / 2,
//                 y: image.height / 2,
//                 fontSize,
//                 fontWeight,
//                 color: 'black',
//                 showBackground: showTextBackground,
//                 fontFamily: pageFont // Add this line
//             };
//             setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
//             setOnImageText('');
//             drawImage();
//         }
//     }, [onImageText, fontSize, fontWeight, showTextBackground, drawImage]);

//     const handleKeyPress = useCallback((e) => {
//         if (e.key === 'Enter' && activeTool === 'text') {
//             handleAddText();
//         }
//     }, [activeTool, handleAddText]);

//     const handleDoubleClick = useCallback((e) => {
//         if (activeTool === 'text') {
//             const canvas = canvasRef.current;
//             const image = imageRef.current;
//             if (!canvas || !image) return;

//             const rect = canvas.getBoundingClientRect();
//             const scaledWidth = image.width * scale;
//             const scaledHeight = image.height * scale;
//             const offsetX = (canvas.width - scaledWidth) / 2 + position.x;
//             const offsetY = (canvas.height - scaledHeight) / 2 + position.y;

//             const clickX = (e.clientX - rect.left - offsetX) / scale;
//             const clickY = (e.clientY - rect.top - offsetY) / scale;

//             const clickedOverlay = textOverlays.find(overlay => {
//                 const ctx = canvas.getContext('2d');
//                 ctx.font = `${overlay.fontWeight} ${overlay.fontSize}px ${overlay.fontFamily}`;
//                 const metrics = ctx.measureText(overlay.text);
//                 const textHeight = overlay.fontSize;

//                 return (
//                     clickX >= overlay.x &&
//                     clickX <= overlay.x + metrics.width &&
//                     clickY >= overlay.y - textHeight &&
//                     clickY <= overlay.y
//                 );
//             });

//             if (clickedOverlay) {
//                 setEditingOverlay(clickedOverlay.id);
//                 setEditText(clickedOverlay.text);
//                 setSelectedOverlay(clickedOverlay.id);
//             } else {
//                 const newOverlay = {
//                     id: Date.now(),
//                     text: 'Text overlay',
//                     x: clickX,
//                     y: clickY,
//                     fontSize,
//                     fontWeight,
//                     color: 'black',
//                     showBackground: showTextBackground,
//                     fontFamily: pageFont
//                 };
//                 setTextOverlays(prevOverlays => [...prevOverlays, newOverlay]);
//                 setEditingOverlay(newOverlay.id);
//                 setEditText('Text overlay');
//                 setSelectedOverlay(newOverlay.id);
//             }
//             drawImage();
//         }
//     }, [activeTool, scale, position, textOverlays, fontSize, fontWeight, showTextBackground, pageFont, drawImage]);


//     const handleDeleteText = useCallback(() => {
//         if (selectedOverlay) {
//             setTextOverlays(prevOverlays => prevOverlays.filter(overlay => overlay.id !== selectedOverlay));
//             setSelectedOverlay(null);
//             drawImage();
//         }
//     }, [selectedOverlay, drawImage]);

//     const handleZoom = useCallback((delta) => {
//         if (activeTool === 'move') {
//             setScale(prevScale => {
//                 const newScale = Math.max(minScale, prevScale + delta);
//                 return newScale;
//             });
//         }
//     }, [activeTool, minScale]);

//     const handleReset = useCallback(() => {
//         if (activeTool === 'move') {
//             setScale(minScale);
//             setPosition({ x: 0, y: 0 });
//         }
//     }, [activeTool, minScale]);

//     const handleFontWeightChange = useCallback((action) => {
//         if (selectedOverlay) {
//             setTextOverlays(prevOverlays =>
//                 prevOverlays.map(overlay => {
//                     if (overlay.id === selectedOverlay) {
//                         const currentIndex = fontWeights.indexOf(overlay.fontWeight);
//                         let newIndex;
//                         if (action === 'increase') {
//                             newIndex = Math.min(fontWeights.length - 1, currentIndex + 1);
//                         } else {
//                             newIndex = Math.max(0, currentIndex - 1);
//                         }
//                         return { ...overlay, fontWeight: fontWeights[newIndex] };
//                     }
//                     return overlay;
//                 })
//             );
//             drawImage();
//         }
//     }, [selectedOverlay, drawImage, fontWeights]);

//     const handleFontSizeChange = useCallback((delta) => {
//         if (selectedOverlay) {
//             setTextOverlays(prevOverlays =>
//                 prevOverlays.map(overlay =>
//                     overlay.id === selectedOverlay
//                         ? { ...overlay, fontSize: Math.max(10, overlay.fontSize + delta) }
//                         : overlay
//                 )
//             );
//             drawImage();
//         }
//     }, [selectedOverlay, drawImage]);

//     const toggleTextBackground = useCallback(() => {
//         if (selectedOverlay) {
//             setTextOverlays(prevOverlays =>
//                 prevOverlays.map(overlay =>
//                     overlay.id === selectedOverlay
//                         ? { ...overlay, showBackground: !overlay.showBackground }
//                         : overlay
//                 )
//             );
//             drawImage();
//         }
//     }, [selectedOverlay, drawImage]);

//     const handleExport = async () => {
//         setIsExporting(true);
//         try {
//             const canvas = canvasRef.current;
//             if (canvas) {
//                 const dataUrl = canvas.toDataURL('image/png');
//                 await exportImageMockup({ imageData: dataUrl, storyboardId, index });
//                 setIsSuccess(true);
//             }
//         } catch (error) {
//             console.error('Export failed:', error);
//         } finally {
//             setIsExporting(false);
//         }
//     };

//     const handleEditChange = (e) => {
//         setEditText(e.target.value);
//     };

//     const handleEditKeyDown = (e) => {
//         if (e.key === 'Enter') {
//             finishEditing();
//         }
//     };

//     const finishEditing = useCallback(() => {
//         if (editingOverlay !== null) {
//             setTextOverlays(prevOverlays =>
//                 prevOverlays.map(overlay =>
//                     overlay.id === editingOverlay
//                         ? { ...overlay, text: editText }
//                         : overlay
//                 )
//             );
//             setEditingOverlay(null);
//             setEditText('');
//             drawImage();  // Redraw the image to show the updated text
//         }
//     }, [editingOverlay, editText, drawImage]);


//     const consolidateAndDownload = (e) => {
//         let link = e.currentTarget;
//         link.setAttribute('download', 'thumbnail-idea-mockup.png');
//         let image = canvasRef.current.toDataURL('image/png');
//         link.setAttribute('href', image);
//     };


//     useEffect(() => {
//         if (editingOverlay !== null && editInputRef.current) {
//             editInputRef.current.focus();
//         }
//     }, [editingOverlay]);

//     useEffect(() => {
//         const handleClickOutside = (e) => {
//             if (editInputRef.current && !editInputRef.current.contains(e.target)) {
//                 finishEditing();
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [finishEditing]);

//     useEffect(() => {
//         const image = new Image();
//         image.onload = function () {
//             imageRef.current = image;
//             setImageSize({ width: image.width, height: image.height });
//             setImageLoaded(true);
//             resizeCanvas();
//         };
//         image.onerror = function (err) {
//             console.error("Error loading image:", err);
//             setImageLoadError(err);
//         };
//         image.src = imageUrl;

//         window.addEventListener('resize', resizeCanvas);
//         return () => {
//             window.removeEventListener('resize', resizeCanvas);
//             if (animationRef.current) {
//                 cancelAnimationFrame(animationRef.current);
//             }
//         };
//     }, [imageUrl, resizeCanvas]);

//     useEffect(() => {
//         drawImage();
//     }, [drawImage, scale, position]);

//     useEffect(() => {
//         if (activeTool === 'text') {
//             drawImage();
//         }
//     }, [activeTool, drawImage]);

//     const toggleTextControls = () => setShowTextControls(prev => !prev);

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//             <div className="relative bg-white rounded-lg max-w-4xl w-full flex flex-col">
//                 <button
//                     className="absolute z-50 top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
//                     onClick={onClose}
//                 >
//                     <CgClose size={24} />
//                 </button>
//                 <div
//                     ref={containerRef}
//                     className="w-full overflow-hidden rounded-t-lg relative"
//                     style={{ aspectRatio: '16/9' }}
//                 >
//                     <canvas
//                         ref={canvasRef}
//                         className="max-w-none rounded-t-lg"
//                         onClick={handleCanvasClick}
//                         onDoubleClick={handleDoubleClick}
//                         onMouseDown={handleMouseDown}
//                         onMouseMove={handleMouseMove}
//                         onMouseUp={handleMouseUp}
//                         onMouseLeave={handleMouseUp}
//                         style={{ cursor: activeTool === 'move' ? 'move' : 'default' }}
//                     ></canvas>
//                     {editingOverlay !== null && (
//                         <input
//                             ref={editInputRef}
//                             type="text"
//                             value={editText}
//                             onChange={handleEditChange}
//                             onKeyDown={handleEditKeyDown}
//                             className="absolute bg-transparent border-none outline-none text-black"
//                             style={{
//                                 left: `${(textOverlays.find(o => o.id === editingOverlay)?.x || 0) * scale + (canvasRef.current?.width || 0) / 2 - (imageRef.current?.width || 0) * scale / 2 + position.x}px`,
//                                 top: `${(textOverlays.find(o => o.id === editingOverlay)?.y || 0) * scale + (canvasRef.current?.height || 0) / 2 - (imageRef.current?.height || 0) * scale / 2 + position.y - (textOverlays.find(o => o.id === editingOverlay)?.fontSize || 0)}px`,
//                                 fontSize: `${(textOverlays.find(o => o.id === editingOverlay)?.fontSize || 16) * scale}px`,
//                                 fontWeight: textOverlays.find(o => o.id === editingOverlay)?.fontWeight || 400,
//                                 fontFamily: `${pageFont}, Arial`,
//                             }}
//                         />
//                     )}
//                 </div>
//                 <div className="p-4 space-x-2 flex justify-between">
//                     <div className="inline-flex">
//                         <div className='ring-1 ring-gray-300 rounded-lg p-2 mr-2 inline-flex items-center'>
//                             <Tooltip content="Move mode">
//                                 <button
//                                     className={`px-2 py-2 ${activeTool === 'move' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg mr-2`}
//                                     onClick={() => handleToolChange('move')}
//                                 >
//                                     <RxMove className='w-5 h-5' />
//                                 </button>
//                             </Tooltip>
//                             <Tooltip content="Text Mode">
//                                 <button
//                                     className={`px-2 py-2 ${activeTool === 'text' ? 'text-gray-600 bg-gray-200' : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100'} active:bg-gray-200 rounded-lg`}
//                                     onClick={() => handleToolChange('text')}
//                                 >
//                                     <RxText className='w-5 h-5' />
//                                 </button>
//                             </Tooltip>
//                         </div>

//                         {activeTool === 'text' && (
//                             <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
//                                 <input
//                                     type="text"
//                                     placeholder='Enter overlay text'
//                                     value={onImageText}
//                                     onChange={(e) => setOnImageText(e.target.value)}
//                                     onKeyPress={handleKeyPress}
//                                     className="mr-2 -mt-0 p-2 bg-gray-100 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-md"
//                                 />
//                                 <Tooltip content="Add text to image">
//                                     <button
//                                         className={`px-2 py-2 text-gray-500 hover:text-gray-600 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
//                                         onClick={handleAddText}
//                                     >
//                                         <FaCheck className='w-5 h-5' />
//                                     </button>
//                                 </Tooltip>
//                                 <Tooltip content="Delete selected text">
//                                     <button
//                                         className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
//                                         onClick={handleDeleteText}
//                                     >
//                                         <TrashIcon className='w-5 h-5' />
//                                     </button>
//                                 </Tooltip>
//                                 <div className="relative">
//                                     {showTextControls && (
//                                         <div className="p-1 absolute -top-12 right-1/2 translate-x-1/2 inline-flex items-center bg-white rounded-lg ring-1 ring-gray-300">

//                                             <Tooltip content="Decrease text size">
//                                                 <button
//                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
//                                                     onClick={() => handleFontSizeChange(-2)}
//                                                 >
//                                                     <MdTextDecrease className='w-5 h-5' />
//                                                 </button>
//                                             </Tooltip>
//                                             <Tooltip content="Increase text size">
//                                                 <button
//                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
//                                                     onClick={() => handleFontSizeChange(2)}
//                                                 >
//                                                     <MdTextIncrease className='w-5 h-5' />
//                                                 </button>
//                                             </Tooltip>
//                                             <Tooltip content="Decrease boldness">
//                                                 <button
//                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
//                                                     onClick={() => handleFontWeightChange('decrease')}
//                                                 >
//                                                     <RiBold className='w-5 h-5' />
//                                                 </button>
//                                             </Tooltip>
//                                             <Tooltip content="Increase boldness">
//                                                 <button
//                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2`}
//                                                     onClick={() => handleFontWeightChange('increase')}
//                                                 >
//                                                     <FaBold className='w-5 h-5' />
//                                                 </button>
//                                             </Tooltip>
//                                             <Tooltip content="Add/Remove text background">
//                                                 <button
//                                                     className={`px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
//                                                     onClick={toggleTextBackground}
//                                                 >
//                                                     <MdFontDownload className='w-5 h-5' />
//                                                 </button>
//                                             </Tooltip>
//                                         </div>
//                                     )}
//                                     <Tooltip content="Show/Hide text controls">
//                                         <button
//                                             className={`px-2 py-2 text-gray-600 hover:text-gray-700 ${showTextControls ? 'bg-gray-200' : ''} hover:bg-gray-100 active:bg-gray-200 rounded-lg`}
//                                             onClick={toggleTextControls}
//                                         >
//                                             <IoText className='w-5 h-5' />
//                                         </button>
//                                     </Tooltip>
//                                 </div>
//                             </div>
//                         )}
//                         {activeTool === 'move' && (
//                             <div className='ring-1 ring-gray-300 rounded-lg px-2 inline-flex items-center'>
//                                 <Tooltip content="Reset move and zoom state">
//                                     <button
//                                         onClick={handleReset}
//                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
//                                     >
//                                         <IoRefreshSharp className='w-5 h-5' />
//                                     </button>
//                                 </Tooltip>
//                                 <Tooltip content="Zoom Out">
//                                     <button
//                                         onClick={() => handleZoom(-0.1)}
//                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg mr-2"
//                                         disabled={activeTool === 'text'}
//                                     >
//                                         <RiZoomOutLine className='w-5 h-5' />
//                                     </button>
//                                 </Tooltip>
//                                 <Tooltip content="Zoom In">
//                                     <button
//                                         onClick={() => handleZoom(0.1)}
//                                         className="px-2 py-2 text-gray-600 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200 rounded-lg"
//                                         disabled={activeTool === 'text'}
//                                     >
//                                         <RiZoomInLine className='w-5 h-5' />
//                                     </button>
//                                 </Tooltip>
//                             </div>
//                         )}
//                     </div>
//                     <div>
//                         <a id='download_image_link' href='download-link'
//                             onClick={consolidateAndDownload}
//                             disabled={isExporting}
//                             className={`px-8 text-white h-full w-full rounded-lg bg-gray-700 hover:bg-gray-80 transition-colors duration-200 flex items-center ${isExporting ? 'opacity-50 cursor-not-allowed' : ''}`}
//                         >
//                             {isExporting ? (
//                                 <>
//                                     <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                                         <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                                     </svg>
//                                     Exporting...
//                                 </>
//                             ) : (
//                                 <>
//                                     <BiDownArrow className="mr-2" />
//                                     Export & Download
//                                 </>
//                             )}
//                         </a>
//                         {/* </div> */}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const VariationPopup = ({ storyboardId, index, initialImageSrc, onCloseVPopup, onVariationSelect }) => {
//     const mainUserId = localStorage.getItem("mainUserId");
//     const [variations, setVariations] = useState([]);
//     const [selectedVariation, setSelectedVariation] = useState(null);
//     const functions = getFunctions();
//     const exportImageMockup = httpsCallable(functions, 'exportImageMockup');

//     useEffect(() => {
//         const fetchVariations = async () => {
//             //console.log(mainUserId)
//             //console.log(storyboardId)
//             //console.log(index)

//             const docRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', storyboardId);
//             const docSnap = await getDoc(docRef);

//             //console.log(docSnap)

//             if (docSnap.exists()) {
//                 const data = docSnap.data();
//                 const storyboardData = data.storyboards?.[index];
//                 if (storyboardData && Array.isArray(storyboardData.variations)) {
//                     setVariations(storyboardData.variations);
//                 } else {
//                     console.error('Variations not found or not in expected format');
//                 }
//             } else {
//                 console.error('No such document!');
//             }
//         };

//         fetchVariations();
//     }, [storyboardId, index, mainUserId]);

//     const selectVariation = () => {
//         if (selectedVariation) {
//             onVariationSelect(selectedVariation);
//             onCloseVPopup();
//         }
//     };

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//             <div className="relative bg-white max-w-4xl w-full flex flex-col rounded-lg overflow-hidden">
//                 <button
//                     className="absolute z-50 top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
//                     onClick={onCloseVPopup}
//                 >
//                     <CgClose size={24} />
//                 </button>

//                 <div className="bg-gray-100">
//                     <div className="p-4 mb-2 bg-white">
//                         Select the Variation to Display
//                     </div>

//                     <div className="p-4 pt-0 w-full min-h-[300px]">
//                         <div className="grid grid-cols-12 gap-5">
//                             <div className="col-span-9 w-full">
//                                 <h3 className='mb-2 text-xs mt-2'>PREVIEW</h3>
//                                 <div className="aspect-[16/9] overflow-hidden ring-1 ring-gray-300 rounded-lg">
//                                     <img src={selectedVariation || initialImageSrc} className="w-full h-full object-cover rounded-lg" alt="Selected variation" />
//                                 </div>
//                             </div>

//                             <div className="w-full h-full flex flex-col col-span-3 ">
//                                 <h3 className='mb-2 text-xs mt-2'>VARIATIONS</h3>
//                                 <div className="bg-white w-full h-full space-y-4 overflow-y-auto p-3 rounded-lg ring-1 ring-gray-300">
//                                     {variations.length === 0 ? (
//                                         <div className="w-full h-full text-xs flex items-center justify-center">
//                                             No Variations Found
//                                         </div>
//                                     ) : (
//                                         variations.map((variationSrc, idx) => (
//                                             <div
//                                                 key={idx}
//                                                 className={`w-full aspect-[16/9] overflow-hidden ring-1 ${selectedVariation === variationSrc ? 'ring-blue-500 ring-2' : 'ring-gray-300'} rounded-md cursor-pointer`}
//                                                 onClick={() => setSelectedVariation(variationSrc)}
//                                             >
//                                                 <img src={variationSrc} className="w-full h-full object-cover rounded-lg" alt={`Variation ${idx + 1}`} />
//                                             </div>
//                                         ))
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="p-4 w-full flex justify-end">
//                     <button
//                         onClick={selectVariation}
//                         className={`px-8 py-2 text-white rounded-lg bg-gray-700 hover:bg-gray-800 transition-colors duration-200 flex items-center ${!selectedVariation && 'opacity-50 cursor-not-allowed'}`}
//                         disabled={!selectedVariation}
//                     >
//                         <HiHand className="mr-2" />
//                         Select
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const EditableTitle = ({ initialTitle, onSave }) => {
//     const [isEditing, setIsEditing] = useState(false);
//     const [title, setTitle] = useState(initialTitle);

//     const handleClick = () => {
//         setIsEditing(true);
//     };

//     const handleChange = (e) => {
//         setTitle(e.target.value);
//     };

//     const handleKeyDown = (e) => {
//         if (e.key === 'Enter') {
//             setIsEditing(false);
//             onSave(title);
//         }
//     };

//     const handleBlur = () => {
//         setIsEditing(false);
//         onSave(title);
//     };

//     useEffect(() => {
//         setTitle(initialTitle);
//     }, [initialTitle]);

//     return (
//         <div className="w-full h-14 flex items-start justify-start">
//             {isEditing ? (
//                 <input
//                     type="text"
//                     value={title}
//                     onChange={handleChange}
//                     onKeyDown={handleKeyDown}
//                     onBlur={handleBlur}
//                     className="mt-2 text-[15px] leading-snug font-medium text-gray-700 px-2 w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
//                     autoFocus
//                 />
//             ) : (
//                 <div
//                     onClick={handleClick}
//                     className="mt-2 text-[15px] leading-snug font-medium text-gray-700 px-2 cursor-pointer hover:bg-gray-100 rounded"
//                 >
//                     {title}
//                 </div>
//             )}
//         </div>
//     );
// };

// const StoryboardItem = React.memo(({
//     storyboardId,
//     storyboard,
//     itemKey,
//     onRefreshImage,
//     onGenerateTitle,
//     onChangeConcept,
//     onEditTitle,
// }) => {
//     const [isViewerOpen, setIsViewerOpen] = useState(false);
//     const [isVPopupOpen, setIsVPopupOpen] = useState(false);
//     const [viewModal, setViewModal] = useState(false);
//     const [currentImageUrl, setCurrentImageUrl] = useState(storyboard.image_url_local_resized);
//     const [isLoadingImage, setIsLoadingImage] = useState(false);
//     const [isLoadingTitle, setIsLoadingTitle] = useState(false);
//     const [isLoadingCategory, setIsLoadingCategory] = useState(false);

//     const modalRef = useRef(null);

//     const handleShare = useCallback(() => {
//         toast.info('Coming soon.', {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     }, []);

//     const handleReframe = useCallback(() => {
//         setViewModal(false);
//         setIsViewerOpen(true);
//     }, []);

//     const handleExport = useCallback((exportedImageUrl) => {
//         //console.log('Exported image URL:', exportedImageUrl);
//         toast.success('Image exported successfully!', {
//             position: "top-right",
//             autoClose: 2000,
//         });
//     }, []);

//     const openViewModal = useCallback(() => setViewModal(true), []);
//     const closeViewModal = useCallback(() => setViewModal(false), []);

//     const variationSelect = useCallback((imageUrl) => {
//         setCurrentImageUrl(imageUrl);
//         setIsVPopupOpen(false);
//     }, []);

//     const handleRefreshImage = useCallback(() => {
//         setIsLoadingImage(true);
//         onRefreshImage(itemKey).finally(() => setIsLoadingImage(false));
//     }, [itemKey, onRefreshImage]);

//     const handleGenerateTitle = useCallback(() => {
//         setIsLoadingTitle(true);
//         onGenerateTitle(itemKey).finally(() => setIsLoadingTitle(false));
//     }, [itemKey, onGenerateTitle]);

//     const handleChangeConcept = useCallback(() => {
//         setIsLoadingImage(true);
//         setIsLoadingTitle(true);
//         setIsLoadingCategory(true);
//         onChangeConcept(itemKey).finally(() => {
//             setIsLoadingImage(false);
//             setIsLoadingTitle(false);
//             setIsLoadingCategory(false);
//         });
//     }, [itemKey, onChangeConcept]);

//     const imageUrl = useMemo(() => {
//         const url = currentImageUrl || storyboard.image_url_local_resized;
//         return url?.includes('storage.googleapis.com/thumbnailgeek-storyboards')
//             ? `${url}?${new Date().getTime()}`
//             : url;
//     }, [currentImageUrl, storyboard.image_url_local_resized]);

//     return (
//         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 2xl:w-96 px-4 mb-8">
//             <div className="flex flex-col">
//                 {/* Category section */}
//                 <div className="w-full h-7 flex items-center justify-between">
//                     {isLoadingCategory ? (
//                         <div className="mt-2 text-[17px] pl-3"><DotDotDot /></div>
//                     ) : (
//                         <p className='text-gray-500 text-sm mb-3'>{storyboard.thumbnail_type}</p>
//                     )}
//                     <Tooltip content='Select image variation to show'>
//                         <div onClick={() => setIsVPopupOpen(true)} className="w-6 h-6 text-gray-400 hover:text-gray-700 hover:bg-gray-200 active:bg-gray-300 rounded-md cursor-pointer flex items-center justify-center">
//                             <TbLayersDifference />
//                         </div>
//                     </Tooltip>
//                 </div>

//                 {isVPopupOpen && (
//                     <VariationPopup
//                         storyboardId={storyboardId}
//                         index={itemKey}
//                         initialImageSrc={currentImageUrl}
//                         onCloseVPopup={() => setIsVPopupOpen(false)}
//                         onVariationSelect={variationSelect}
//                     />
//                 )}

//                 {/* Image section */}
//                 <div onClick={openViewModal} className="w-full aspect-[16/9] overflow-hidden ring-1 ring-gray-400 rounded-lg hover:ring-2 hover:cursor-pointer transition-all duration-400">
//                     {isLoadingImage ? (
//                         <div className="relative w-full h-full">
//                             <div className="absolute inset-0 bg-white/70 flex justify-center items-center w-full h-full">
//                                 <div className='w-40'>
//                                     <Lottie animationData={loading_tg} loop={true} />
//                                 </div>
//                             </div>
//                             <img src={imageUrl} alt={storyboard.title} className="object-cover w-full h-full" />
//                         </div>
//                     ) : (
//                         <img src={imageUrl} alt={storyboard.title} className="object-cover w-full h-full" />
//                     )}
//                 </div>

//                 {/* Title section */}
//                 <div className="w-full h-14 flex items-start justify-start">
//                     {isLoadingTitle ? (
//                         <div className="mt-2 text-[17px] pt-3 pl-3"><DotDotDot /></div>
//                     ) : (
//                         <EditableTitle
//                             key={`${itemKey}-${storyboard.title}`}
//                             initialTitle={storyboard.title}
//                             onSave={(newTitle) => onEditTitle(itemKey, newTitle)}
//                         />
//                     )}
//                 </div>

//                 {/* Action buttons */}
//                 <div className="border-t border-gray-300 mt-8 grid grid-cols-2 gap-2 py-4 text-xs font-medium">
//                     <button
//                         onClick={handleRefreshImage}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <HiRefresh className="-ml-0.5 h-5 w-5" />
//                         New Image
//                     </button>
//                     <button
//                         onClick={handleGenerateTitle}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <RxText className="-ml-0.5 h-5 w-5" />
//                         New Title
//                     </button>
//                     <button
//                         onClick={handleChangeConcept}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <MdTrackChanges className="-ml-0.5 h-5 w-5" />
//                         New Concept
//                     </button>
//                     <a
//                         href={storyboard.image_url_local_resized}
//                         target='_blank'
//                         download={storyboard.image_url_local_resized || storyboard.image_url}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <BiDownArrow size={18} />
//                         Download
//                     </a>
//                     <button
//                         onClick={handleReframe}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <TbFrame size={18} />
//                         Reframe
//                     </button>
//                     <button
//                         onClick={handleShare}
//                         className="inline-flex items-center gap-x-2 h-8 rounded-md ring-1 ring-gray-300 px-3 text-gray-500 hover:ring-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
//                     >
//                         <TbShare size={18} />
//                         Share
//                     </button>
//                 </div>
//             </div>

//             {viewModal && (
//                 <div id="st-item-view-modal" onClick={closeViewModal} className="fixed inset-0 z-50 flex items-center justify-center p-4 rounded-lg bg-black bg-opacity-50">
//                     <div ref={modalRef} className="relative max-w-4xl w-full bg-white rounded-lg shadow-lg">
//                         <button
//                             className="absolute top-2 right-2 bg-white bg-opacity-30 text-gray-500 hover:text-gray-700 hover:bg-opacity-70 p-1 rounded-lg transition-all duration-200"
//                             onClick={closeViewModal}
//                         >
//                             <CgClose size={24} />
//                         </button>
//                         <img src={imageUrl} className="w-full h-auto min-w-[460px] max-h-[900px] aspect-[16/9] rounded-t-lg overflow-hidden object-cover z-50" />
//                         <div className="flex p-4 justify-end">
//                             <button onClick={handleReframe} className="text-sm inline-flex items-center px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700">
//                                 <TbFrame size={18} className="mr-2" />
//                                 Reframe Image
//                             </button>
//                             <a href={imageUrl} target='_blank' download className="text-sm inline-flex items-center ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
//                                 <BiDownArrow size={18} className="mr-2" />
//                                 Download
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {isViewerOpen && (
//                 <InteractiveImageViewer
//                     imageUrl={imageUrl}
//                     onClose={() => setIsViewerOpen(false)}
//                     onExport={handleExport}
//                     storyboardId={storyboardId}
//                     index={itemKey}
//                 />
//             )}
//         </div>
//     );
// });

// export default StoryboardItem;

