import React, { useEffect, useState } from 'react'
import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import axios from 'axios';
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import ReactPlayer from 'react-player/youtube';
import Cookies from 'js-cookie'
import { FcBinoculars, FcStackOfPhotos, FcTodoList, FcTreeStructure, FcMms, FcStatistics } from "react-icons/fc";
import { AiOutlineExclamation } from "react-icons/ai";
import { UserAuth } from '../../../context/AuthContext'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import Avatar from '../../components/Avatar';
import { CheckIcon } from '@heroicons/react/20/solid'
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import Hint from '../../components/Hint';
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  GiftIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline'
import Tooltip from '../../components/Tooltip';


const FeaturesFixes = () => {
  let navigate = useNavigate();
  const { user } = UserAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loginParam = params.get('login');
  const firstEntry = params.get('first-entry');
  const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
  const channelDisplayName = localStorage.getItem("channelDisplayName");
  const { premiumStatus, premiumTier, accountStatus, channelLinked } = PremiumCredentials();
  const mainUserId = localStorage.getItem("mainUserId");
  const [darkMode, setDarkMode] = useState(false);
  const [boardCount, setBoardCount] = useState(0);
  const [splitTestCount, setSplitTestCount] = useState(0);
  const [titleGenCount, setTitleGenCount] = useState(0);
  const [thumbGenCount, setThumbGenCount] = useState(0);
  const [thumbAnalysisCount, setThumbAnalysisCount] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [extensionModal, setExtensionModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | ThumbnailGeek</title>
        <meta name="description" content="Dashboard | ThumbnailGeek" />
      </Helmet>


      <div className='bg-gray-50' >
        <SideBar selectedSideBarItem="dashboard" darkMode={false} />

        <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
          <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

            <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-20 right-0 top-0 inline-flex items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7">
              <div className="">
                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold lg:block`}>Bug fixes & Upcoming Features</h5>
              </div>

              <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                <Avatar />
              </div>
            </div>

            <div className="mt-20 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8">
              <div className="w-full h-full mx-auto">




              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export default FeaturesFixes