import React from 'react'

const SplitCollage = ({ itemsData }) => {
    // const 
    var lengthOfItems = itemsData.length;
    // var items = itemsData.items;

    return (
        <>

            {lengthOfItems > 2 ?
                <>
                    {
                        lengthOfItems === 3
                            ?
                            <div className="w-full h-full overflow-hidden grid grid-cols-9 grid-rows-9 gap-[2px] space-y-0 bg-gray-300 aspect-video">

                                <div className="w-full h-full col-span-3 row-span-9 bg-gray-200">
                                    {
                                        itemsData[1].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[1].thumbnail}?${Date.now()} || ${itemsData[1].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-3 row-span-9  bg-gray-200">
                                    {
                                        itemsData[2].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[2].thumbnail}?${Date.now()} || ${itemsData[2].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-3 row-span-9 bg-gray-200">
                                    {
                                        itemsData[0].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[0].thumbnail}?${Date.now()} || ${itemsData[0].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>

                            </div>
                            :
                            <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-gray-300 aspect-video">

                                <div className="w-full h-full col-span-2 row-span-2 bg-gray-200">
                                    {
                                        itemsData[3].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[3].thumbnail}?${Date.now()} || ${itemsData[3].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-2 row-span-2 bg-gray-200">
                                    {
                                        itemsData[1].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[1].thumbnail}?${Date.now()} || ${itemsData[1].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-2 row-span-2  bg-gray-200">
                                    {
                                        itemsData[2].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[2].thumbnail}?${Date.now()} || ${itemsData[2].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-2 row-span-2 bg-gray-200">
                                    {
                                        itemsData[0].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[0].thumbnail}?${Date.now()} || ${itemsData[0].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>

                            </div>

                    }
                </>
                :
                <>
                    {
                        lengthOfItems === 1
                            ?
                            <div className="w-full h-full aspect-video">
                                {
                                    itemsData[0].thumbnail
                                        ?
                                        <img className='w-full h-full object-cover' src={`${itemsData[0].thumbnail}?${Date.now()} || ${itemsData[0].thumbnail}`} alt="image" />
                                        :
                                        <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                }
                            </div>
                            :
                            <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-gray-300 aspect-video">
                                <div className="w-full h-full col-span-2 row-span-4 bg-gray-400">
                                    {
                                        itemsData[0].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[0].thumbnail}?${Date.now()} || ${itemsData[0].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                                <div className="w-full h-full col-span-2 row-span-4 bg-gray-200">
                                    {
                                        itemsData[1].thumbnail
                                            ?
                                            <img className='w-full h-full object-cover' src={`${itemsData[1].thumbnail}?${Date.now()} || ${itemsData[1].thumbnail}`} alt="image" />
                                            :
                                            <img className='w-full h-full object-cover' src={`https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/siteAssets%2Fplaceholder-image-2.jpg?alt=media&token=28c078ad-c396-47c1-8acc-54cb1168319f`} alt="image" />
                                    }
                                </div>
                            </div>
                    }
                </>
            }
        </>
    )
}

export default SplitCollage
