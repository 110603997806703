import React, { useState, useRef, useEffect } from 'react';
import { db } from '../../firebase-config';
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { toast } from 'react-toastify';
import { CgClose } from 'react-icons/cg';

const Spinner = () => (
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
);

const PreferenceModal = ({ isOpen, onClose, onSave }) => {
    const mainUserId = localStorage.getItem("mainUserId");
    const [gender, setGender] = useState('');
    const [race, setRace] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isClearing, setIsClearing] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [hasPreferences, setHasPreferences] = useState(false);
    const modalRef = useRef();

    const races = ['Asian', 'Black/African American', 'Hispanic', 'Indian', 'White/Caucasian', 'Middle-Eastern', 'Native American', 'Non-Specified'];

    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        await saveToDB();
        onSave({ gender, race });
        setIsSaving(false);
        onClose();
    };

    const saveToDB = async () => {
        const data = { gender, race };
        const docRef = doc(db, 'Users', mainUserId);
        try {
            await updateDoc(docRef, {
                storyboard_preferences: data,
            });
            setHasPreferences(true);
            toast.success('Character preferences saved');
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('Failed to save preferences');
        }
    };

    const clearPreferencesDB = async () => {
        setIsClearing(true);
        const docRef = doc(db, 'Users', mainUserId);
        try {
            await updateDoc(docRef, {
                storyboard_preferences: deleteField()
            });
            setGender('');
            setRace('');
            setHasPreferences(false);
            toast.success('Preferences cleared');
            onClose();
        } catch (error) {
            console.error('Error clearing data:', error);
            toast.error('Failed to clear preferences');
        } finally {
            setIsClearing(false);
        }
    };

    const fetchData = async () => {
        setIsFetching(true);
        const docRef = doc(db, 'Users', mainUserId);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                const preferences = data?.storyboard_preferences || {};
                setGender(preferences.gender || '');
                setRace(preferences.race || '');
                setHasPreferences(!!preferences.gender || !!preferences.race);
            } else {
                console.log('No such document!');
                setHasPreferences(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch preferences');
            setHasPreferences(false);
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

            <div ref={modalRef} className="relative bg-white rounded-lg p-5 w-full max-w-md">
                <button onClick={onClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 
                                rounded-md text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <CgClose />
                </button>
                <h2 className="text-lg font-bold mb-4">Set Character Visual Preferences</h2>
                {isFetching ? (
                    <div className="flex justify-center items-center h-40">
                        <Spinner />
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="gender" className="block text-sm font-bold text-gray-700 mb-2">
                                Gender
                            </label>
                            <select
                                id="gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="w-full p-2 ring-2 ring-blue-500 rounded-md"
                                disabled={isSaving || isClearing}
                            >
                                <option value="">Select...</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-bold text-gray-700 mb-2">Ethnicity</label>
                            <div className="flex flex-wrap space-x-2">
                                {races.map((r) => (
                                    <div
                                        key={r}
                                        className={`flex items-center p-2 ring-1 rounded-md mb-3 cursor-pointer ${race === r ? 'ring-2 ring-blue-500' : 'ring-gray-300'
                                            } ${(isSaving || isClearing) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        onClick={() => !isSaving && !isClearing && setRace(r)}
                                    >
                                        <input
                                            type="radio"
                                            id={r}
                                            name="race"
                                            value={r}
                                            checked={race === r}
                                            onChange={() => setRace(r)}
                                            disabled={isSaving || isClearing}
                                            className="sr-only"
                                        />
                                        <label htmlFor={r} className="flex-grow cursor-pointer">
                                            {r}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50"
                                disabled={isSaving || isClearing}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={clearPreferencesDB}
                                className={`px-4 py-2 text-sm font-medium text-white rounded-md flex items-center ${hasPreferences
                                    ? 'bg-red-500 hover:bg-red-600'
                                    : 'bg-gray-400 cursor-not-allowed'
                                    } disabled:opacity-50`}
                                disabled={isSaving || isClearing || !hasPreferences}
                            >
                                {isClearing ? <Spinner /> : 'Clear preferences'}
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 flex items-center"
                                disabled={isSaving || isClearing}
                            >
                                {isSaving ? <Spinner /> : 'Save preferences'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default PreferenceModal;