import React from 'react'

const FocusBoardCollageImages = ({ itemsData }) => {
    // const 

    var lengthOfItems = itemsData.length;
    // var items = itemsData.items;

    return (
        <>

            {lengthOfItems > 2 ?

                <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-white">

                    <div className="w-full h-full col-span-2 row-span-2 bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[1].thumbnail} alt="image" />
                    </div>
                    <div className="w-full h-full col-span-2 row-span-2  bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[2].thumbnail} alt="image" />
                    </div>
                    <div className="w-full h-full col-span-4 row-span-4 bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} alt="image" />
                    </div>

                </div>

                :
                <>
                    {
                        lengthOfItems === 1
                            ?
                            <div className="w-full h-full">
                                <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} />
                            </div>
                            :
                            <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-white">
                                <div className="w-full h-full col-span-4 row-span-2 bg-gray-400">
                                    <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} alt="image" />
                                </div>
                                <div className="w-full h-full col-span-4 row-span-2 bg-gray-200">
                                    <img className='w-full h-full object-cover' src={itemsData[1].thumbnail} alt="image" />
                                </div>
                            </div>
                    }
                </>

            }
        </>
    )
}

export default FocusBoardCollageImages



{/* <div className="overflow-hidden flex flex-row flex-wrap">
{lengthOfItems > 2 ?
    <div>
        {
            itemsData?.map((item, i) => (
                <div className=" m-0 p-0">
                    {i == 1
                        ?
                        <div key={i} className="h-full w-36">
                            <img className='w-full h-full' src={item.thumbnail} alt="image" />
                        </div>
                        :
                        <div key={i} className="col-span-1 row-span-1">
                            <img className='w-full h-full' src={item.thumbnail} alt="image" />
                        </div>
                    }
                </div>
            ))
        }
    </div>
    :
    <div className="w-full h-full space-y-1 col-span-3 row-span-3">
        <img src={itemsData[0].thumbnail} />
    </div>
}
</div> */}