import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useLocation, Link, NavLink, useNavigate } from 'react-router-dom'
import { auth, db, provider } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { UserAuth } from '../../../context/AuthContext';
import { fetchSignInMethodsForEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, addDoc, getFirestore, serverTimestamp, collection, query, where, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DotDotDot from '../../components/DotDotDot';
import gLogo from '../../../assets/g-logo.png';
import ytLogo from '../../../assets/yt_logo_mono_light.png';
import continueGoogleBtn from '../../../assets/web_light_sq_ctn@2x.png';

const SignUpPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const shouldRedirect = location.search.includes("extension=first-install");
    const upgrade = params.get('upgrade');
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { user } = UserAuth();
    const { premiumStatus, premiumTier, accountStatus, channelLinked, checkDataStatus, checked } = PremiumCredentials();
    const [verifying, setVerifying] = useState(false);
    const [toRedirect, setToRedirect] = useState(false);

    useEffect(() => {

        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
            document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;
        }
    }, []);

    useEffect(() => {
        if (checked !== true) {
            checkDataStatus();
        }
        //Check if the required data is available
        if (premiumStatus !== '' && premiumTier !== '' && accountStatus !== '') {
            localStorage.setItem('premiumNotEmpty', true);
        }

        if (channelLinked !== true && premiumStatus === 'NOT_INITIATED') {
            localStorage.setItem('premiumStatus', 'NOT_INITIATED');
            localStorage.setItem('channelLinked', false);
        }

        if (channelLinked === true && premiumStatus === 'NOT_INITIATED') {
            localStorage.setItem('premiumStatus', 'NOT_INITIATED');
            localStorage.setItem('channelLinked', true);
        }

    }, [premiumStatus, premiumTier, accountStatus, channelLinked, checkDataStatus, checked]);

    useEffect(() => {
        if (mainUserId) {
            window.location.href = `/mid/${user.uid}`;
        }
        // else {
        //     document.cookie = 'TG_AT=; max-age=0;';
        //     document.cookie = 'TG_Avatar=; max-age=0;';
        //     document.cookie = 'TG_ChannelName=; max-age=0;';
        // }
    }, []);

    const checkEmail = async (email) => {
        try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            //If the array of sign-in methods returned is not empty, the email is registered and enabled
            if (methods.length > 0) {
                console.log("Email exists and is enabled.");
                return true;
            } else {
                console.log("Email does not exist or is not enabled.");
                return false;
            }
        } catch (error) {
            console.error(error);
            //Handle any errors that occur during the check
        }
    };

    const findDocument = async (email) => {
        const q = query(collection(db, 'Users'), where('email', '==', email));
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                return true;
            });
            return false;
        } catch (error) {
            console.error('Error getting documents: ', error);
        }
    };

    const handleSignIn = () => {

        signInWithPopup(auth, provider).then(async (data) => {

            let idToken = await data.user.getIdToken();
            const oauthIdToken = localStorage.getItem('oauthIdToken');
            if (oauthIdToken) {
                localStorage.removeItem('oauthIdToken');
            }
            localStorage.setItem('oauthIdToken', idToken);
            validateAndSaveUserData(data?.user);

        }).catch((error) => {
            setVerifying(false);
            //Handle Errors here.
            //const errorMessage = error.message;

        });
    }

    const validateAndSaveUserData = async (user) => {
        //localStorage.setItem('mainUserId', user.uid);
        setVerifying(true);
        try {
            const firestore = getFirestore();
            const userDocRef = doc(firestore, "Users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            const existingUserId = localStorage.getItem('mainUserId');
            const existingEmail = localStorage.getItem('mainEmail');
            const authenticationCheck = checkEmail(user?.email);

            if (existingUserId) {
                localStorage.removeItem('mainUserId');
            }
            localStorage.setItem('mainUserId', user.uid);

            if (existingEmail) {
                localStorage.removeItem('mainEmail');
            }
            localStorage.setItem('mainEmail', user.email);

            if (!userDocSnap.exists()) {
                if (authenticationCheck) {
                    await createNewUser(userDocRef, user);
                } else {
                    localStorage.setItem('signUpTriggered', false);
                    navigate('/signup')
                }
            } else {
                updateUserCounts(userDocSnap, user);
            }

            localStorage.setItem('signUpTriggered', false);
        } catch (e) {
            console.error(e);
            localStorage.setItem('signUpTriggered', false);
        }
    };

    const createNewUser = async (userDoc, user) => {

        const expiresIn = 1209600;
        const createdAt = Timestamp.now().toDate();
        const expirationDate = new Date(createdAt);
        expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);
        const expirationTimestamp = Timestamp.fromDate(expirationDate);

        const data = {
            banned: false,
            access_level: 5,
            user_name: user?.displayName ?? "",
            user_email: user?.email,
            user_type: 'pay_as_you_go',
            photo_url: user?.providerData[0].photoURL ?? "",
            login_method: user?.providerData[0].providerId,
            ai_credits_quota: 1000,
            ai_credits_usage: 700,
            account_status: "ACTIVE",
            premium_status: "TRIALING",
            premium_tier: "FREE_CREDITS",
            premium_expiry: serverTimestamp(),
            pack_expiration_date: expirationTimestamp,
            channel_linked: false,
            first_open_popup: true,
            channel_id: "",
            channel_name: "",
            channel_email: "",
            date_created: serverTimestamp(),
            last_active_date: serverTimestamp(),
        }
        setDoc(userDoc, data, { merge: true })
            .then(async res => {
                if (user.uid !== '') {
                    const countsRef = doc(db, "UserData", user.uid);
                    const data = {
                        bucket_count: 0,
                        thumb_analysis_count: 0,
                        thumb_compare_count: 0,
                        thumb_idea_gen_count: 0,
                        title_gen_count: 0,
                        vl_images_saved: 0,
                        yt_test_count: 0,
                        currentWeekStreak: 1,
                        longestWeekStreak: 1,
                        date_created: serverTimestamp(),
                    };
                    await setDoc(countsRef, data);
                    navigate(`/first-steps/connect/${user.uid} `)
                    //navigate(`/mid/${ user.uid } `)

                    localStorage.setItem('signUpTriggered', false);
                }
            }).catch((e) => {
                localStorage.setItem('signUpTriggered', false);
                console.log(e);
            });
    };

    const updateUserCounts = async (docSnap, user) => {
        localStorage.setItem('signUpTriggered', false);

        const countsRef = doc(db, "UserData", user.uid);
        const countsSnap = await getDoc(countsRef)
        if (!countsSnap.exists()) {

            const countsRef = doc(db, "UserData", user.uid);
            const data = {
                bucket_count: 0,
                thumb_analysis_count: 0,
                thumb_compare_count: 0,
                thumb_idea_gen_count: 0,
                title_gen_count: 0,
                vl_images_saved: 0,
                yt_test_count: 0,
                date_created: serverTimestamp(),
            };

            await setDoc(countsRef, data);
        }

        const channelId = docSnap.data().channel_id;
        const channelLinked = docSnap.data().channel_linked;
        const channelName = docSnap.data().channel_name;
        const channelPhoto = docSnap.data().channel_photo_url;
        const channel_token = docSnap.data().channel_token;
        const refresh_token = docSnap.data().refresh_token;
        const premium_status = docSnap.data().premium_status;
        const premium_tier = docSnap.data().premium_tier;

        console.log(channelId)
        console.log(channelName)
        console.log(channelPhoto)
        console.log(channel_token)
        console.log(refresh_token)
        console.log(refresh_token)
        console.log(premium_status)
        console.log(premium_tier)

        if (channelId !== '' && channelId !== null && channelName !== null && channelName !== ''
            && channelPhoto !== null && channelPhoto !== '' && channelLinked === true) {

            updateToken(user, channelName, channelPhoto);

            localStorage.setItem('channelPhotoUrl', channelPhoto);
            localStorage.setItem('channelDisplayName', channelName);
            localStorage.setItem('channelId', channelId);
            localStorage.setItem('mainUserId', user.uid);
            localStorage.setItem('mainEmail', user.email);
            localStorage.setItem('ytToken', channel_token);
            localStorage.setItem('refreshToken', refresh_token);

            console.log(user.uid);
            console.log(premium_status);

            localStorage.setItem('channelConnectPassed', true);
            window.location.href = `/mid/${user.uid}`;
            localStorage.setItem('signUpTriggered', false);

            //if (premium_status === 'NOT_INITIATED' && channelLinked === false) {
            //    localStorage.setItem('premiumStatus', 'NOT_INITIATED');
            //    window.location.href = `/first-steps/connect/${ user.uid } `;
            //} else if (premium_status === 'NOT_INITIATED' && channelLinked === true) {
            //    localStorage.setItem('premiumStatus', 'NOT_INITIATED');
            //    window.location.href = `/first-steps/extension/${ user.uid } `;
            //} else if (premium_status === 'CANCELED') {
            //    localStorage.setItem('premiumStatus', 'CANCELED');
            //    window.location.href = '/restricted';
            //} else if (premium_status === 'TRIALING') {
            //    if (upgrade === 'true') {
            //        localStorage.setItem('premiumStatus', 'TRIALING');
            //        window.location.href = '/account-settings?upgrade=true';
            //    } else {
            //        localStorage.setItem('premiumStatus', 'TRIALING');
            //        window.location.href = '/first-steps/billing/plans';
            //    }
            //    localStorage.setItem('premiumStatus', 'TRIALING');
            //    window.location.href = '/dashboard?login=true';

            //} else if (premium_status === 'PREMIUM') {
            //    if (upgrade === 'true') {
            //        localStorage.setItem('premiumStatus', 'PREMIUM');
            //        window.location.href = '/account-settings?upgrade=true';
            //    } else {
            //        localStorage.setItem('premiumStatus', 'PREMIUM');
            //        window.location.href = '/dashboard?login=true';
            //    }

            //} else {
            //    window.location.href = `/first-steps/connect/${ user.uid } `;
            //}

        } else {
            if (channelLinked === true) {
                console.log(user.uid);
                localStorage.setItem('channelConnectPassed', true);
                //window.location.href = `/first-steps/extension/${ user.uid } `;
                window.location.href = `/mid/${user.uid}`;
                localStorage.setItem('signUpTriggered', false);
            } else {
                console.log(user.uid);
                localStorage.setItem('channelConnectPassed', false);
                //window.location.href = `/first-steps/connect/${ user.uid } `;
                window.location.href = `/mid/${user.uid}`;
                localStorage.setItem('signUpTriggered', false);
            }
        }

    };

    const updateToken = (user, channelName, channelPhoto) => {

        if (user.uid !== null && user.uid !== undefined && user.uid !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${user.uid}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    document.cookie = `TG_Avatar = ${channelPhoto}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });


        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sign Up | ThumbnailGeek</title>
                <meta name="description" content="First Steps - Add channel | ThumbnailGeek" />
            </Helmet>

            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-24">
                    <img className="mx-auto h-12" src={require('../../../assets/svgs/logo512.svg').default} alt="ThumbnailGeek Logo" />

                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 ring-1 ring-gray-300 shadow sm:rounded-lg sm:px-12">

                        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Explore thumbnailGeek for free
                        </h2>

                        <p className="mt-3 mb-8 text-center text-sm text-gray-500">
                            Sign up with Youtube.<br />
                            Get started - Its quick
                        </p>
                        <div className="mt-6 w-full flex flex-col justify-center items-center cursor-pointer hover:cursor-pointer" >

                            {/* <p className='mb-2 font-bold'>Sign up with</p>
                            <button onClick={() => handleSignIn()}
                                type="submit"
                                className="w-64 inline-flex h-[40px] cursor-pointer hover:ring-red-600 items-center justify-center rounded-md p-[8px] text-white shadow-md ring-1 ring-red-400"
                            >
                                <img src={ytLogo} alt="SIGN UP WITH YOUTUBE" className="w-32 p-4 mr-[24px] cursor-pointer hover:cursor-pointer" />
                            </button> */}

                            <button onClick={() => handleSignIn()}
                                type="submit" className="w-[200px] inline-flex h-[40px] ml-4 cursor-pointer justify-center items-center rounded-md text-white "
                            >
                                <img src={continueGoogleBtn} alt="SIGN IN WITH YOUTUBE" className="py-4 mr-[24px]" />
                            </button>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already have an account? {' '}
                        <NavLink to={'/login'}>
                            <span className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                                Log In
                            </span>
                        </NavLink>
                    </p>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        By signing up, you agree to our{' '}
                    </p>
                    <p className="text-center text-sm text-gray-500">
                        <a href='https://www.thumbnailgeek.com/privacy' target='_blank' className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                            Privacy Policy
                        </a>
                        {' '}&{' '}
                        <a href='https://www.thumbnailgeek.com/terms' target='_blank' className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                            Terms Of Use
                        </a>
                    </p>
                </div>
            </div>
        </>

    )
}

export default SignUpPage