import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from "react-dropzone";
import Cookies from 'js-cookie'
import axios from 'axios';
import { HiOutlineBell, HiSparkles } from 'react-icons/hi';
import { toast } from 'react-toastify';
import DotDotDot from './DotDotDot';
import Hint from './Hint';
import { PremiumCredentials } from '../../context/PremiumContext';
import { db, storage } from '../../firebase-config';
//import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, } from "firebase/firestore";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit, where } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "@firebase/storage";
import { HomeModernIcon, PhoneArrowUpRightIcon } from '@heroicons/react/24/outline';
import { BsYoutube } from 'react-icons/bs';
import { FiClipboard, FiSmartphone } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';
import Lottie from "lottie-react";
import image_upload from "../../assets/animations/image_uploader.json";
import Tooltip from './Tooltip';
import { Tab } from '@headlessui/react';
import { IoAnalytics } from 'react-icons/io5';
import { FaCrown } from 'react-icons/fa';
import BlockPopupStanPro from './BlockPopupStanPro';
import CreditsPopupStanPro from './CreditsPopupStanPro';
import CreditsPopupStanProTrial from './CreditsPopupStanProTrial';
import { FireIcon } from '@heroicons/react/20/solid';
import { AiFillFire } from 'react-icons/ai';
import { GiProcessor } from 'react-icons/gi';
import BlockPopupPro from './BlockPopupPro';

const TitleTemplateIdeaGenerator = ({ isVisible, template, title, onClose }) => {
    const navigate = useNavigate();
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, aiCreditsLeft } = PremiumCredentials();
    const [contentIdea, setContentIdea] = useState('')
    const [generatedTitles, setGeneratedTitles] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [blockPopup, setBlockPopup] = useState(false)
    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)
    const colors = ['text-blue-500', 'text-red-500', 'text-green-500', 'text-pink-500', 'text-purple-500'];
    let colorIndex = 0;

    useEffect(() => {

        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => { })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, []);


    const handleModalClose = (e) => {
        if (e.target.id === "title-template-idea-generator") {
            onClose();
        }
    }

    const modalClose = (e) => {
        setGeneratedTitles([]);
        setContentIdea('');
        setGenerating(false);
        onClose();
    }

    const getTemplateIdeas = (e) => {
        e.preventDefault();
        setGenerating(true);

        if (contentIdea !== '') {
            // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            if (aiCreditsLeft > 4) {
                const token = getCookie('TG_AT');
                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/get-title-template-suggestions/${mainUserId}`;

                const data = {
                    template: template,
                    title: title,
                    content_idea: contentIdea,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers).then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.payload);
                        const titles = response.data.payload.titles;
                        setGeneratedTitles(titles);
                        setGenerating(false);
                    } else {
                        setGenerating(false);
                        toast.error('Something went wrong. Please try again later.', {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    }
                }).catch((error) => {
                    console.error(error);
                    console.log('It did not work');
                });
            } else {
                if (premiumStatus === 'TRIALING') {
                    setGenerating(false);
                    setCreditBlockPopupTrial(true);
                } else {
                    setGenerating(false);
                    setCreditBlockPopup(true);
                }

                toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
            // } else {
            //     setGenerating(false);
            //     setCreditBlockPopup(true);
            // }
        }
    }

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const copyTitleToClipBoard = (title) => {
        navigator.clipboard.writeText(title);
        toast.success('Copied to clipboard', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    if (!isVisible) return null;

    return (
        <>

            {/* <BlockPopupPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/title-builder'} /> */}

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/testing-suite/upload'} />


            <div id="title-template-idea-generator" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full 
            bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-2xl md:h-auto mx-0">
                    <div className=" relative w-full h-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="px-6 pt-4 border-b pb-3">
                            <h2 className='font-bold text-xl text-gray-700'>Apply this template to your idea</h2>
                            <button onClick={modalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="">

                            <div className="px-6 pt-4">
                                <label htmlFor="fb_name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                                    Template
                                </label>

                                <div className="text-[19px] font-semibold tracking-tight text-gray-900">
                                    {
                                        template?.split(/(\[[^\]]+\])/).map((part, index) => {
                                            if (part.startsWith('[') && part.endsWith(']')) {
                                                const words = part.split(' '); // Split the words inside the brackets
                                                // const words = part.slice(1, -1).split(' '); // Split the words inside the brackets
                                                return (
                                                    <span key={index}>
                                                        {words.map((word, wordIndex) => {
                                                            const color = colors[colorIndex % colors.length];
                                                            colorIndex += 1;
                                                            return <span key={wordIndex} className={color}>____</span>; // Add a space after each word
                                                        })}
                                                    </span>
                                                );
                                            } else {
                                                return <span key={index}>{part}</span>;
                                            }
                                        })
                                    }
                                </div>
                            </div>

                            <form onSubmit={(e) => getTemplateIdeas(e)}>
                                <div className="relative p-6 pb-6">
                                    <label htmlFor="fb_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Your Content Idea/Details
                                    </label>
                                    <input onChange={(e) => setContentIdea(e.target.value)} type="text" id="fb_name" name="fb_name"
                                        className="bg-gray-50 border border-blue-500 text-gray-700 text-base rounded-lg block w-full p-2.5 "
                                        placeholder="A video about fishing in the snow" required />
                                    <button type="submit"
                                        className={`${contentIdea === '' ? 'hover:cursor-default bg-gray-300' : 'bg-blue-500 text-white hover:cursor-pointer hover:bg-blue-600 '}
                                         absolute right-[5px] top-[57px] mr-6 font-medium rounded-md text-sm px-5 py-2 text-center`}>
                                        Get Variations
                                    </button>
                                </div>
                            </form>

                            <div className=" border-gray-200">
                                <div className="w-full border-t">
                                    {
                                        generating === true
                                            ?
                                            <div className="flex justify-center items-center w-full h-16">
                                                <GiProcessor className="w-8 h-8 animate-pulse text-purple-600" />
                                            </div>
                                            :
                                            <div className='w-full px-6 pb-0 pt-2'>
                                                {
                                                    generatedTitles?.map((titles, i) => (
                                                        titles.title != ''
                                                            ?
                                                            <div onClick={() => copyTitleToClipBoard(titles.title)} key={i} className={`w-full hover:cursor-pointer inline-flex mb-2 p-2 rounded-lg hover:bg-gray-100 text-sm justify-between`}>
                                                                <p className=' text-gray-800'>
                                                                    {titles.title}
                                                                </p>
                                                                <div className='inline-flex  mr-1 h-[24px]'>
                                                                    <Tooltip content="Copy" position="top">
                                                                        <button onClick={() => copyTitleToClipBoard(titles.title)} className=' border text-xs border-gray-500 hover:border-gray-600 active:bg-gray-300 rounded-md'>
                                                                            <FiClipboard className='text-gray-500 hover:text-gray-600  p-1 w-[22px] h-[22px]' />
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    ))
                                                }
                                            </div>

                                    }
                                </div>
                                <div className="w-full inline-flex items-center justify-center text-xs text-purple-700 px-1 pb-1">15 <AiFillFire className='w-4 h-4' /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TitleTemplateIdeaGenerator;
