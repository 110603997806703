import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import { db, storage } from '../../../firebase-config';
import { MdOutlineArrowBack, MdOutlineFreeBreakfast } from "react-icons/md";
import { UserAuth } from '../../../context/AuthContext';
import { RadioGroup } from '@headlessui/react'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, getRedirectResult, GoogleAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { query, where, orderBy, addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, onSnapshot } from "firebase/firestore";
import AvatarNoPremium from '../../components/AvatarNoPremium';
import DotDotDot from '../../components/DotDotDot';
import { AiOutlineGift } from 'react-icons/ai';
import { RiRefund2Line } from 'react-icons/ri';
import ConfettiExplosion from 'react-confetti-explosion';
import Avatar from '../../components/Avatar';
import SideBar from '../../components/SideBar';
import { CgClose } from 'react-icons/cg';
import FreeCreditsModal from '../../components/FreeCreditsModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InsideBillingOneTime = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { user, token, logOut, providerId, photoUrl, connectYoutubeChannel, } = UserAuth()
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [plans, setPlans] = useState([]);
    let { user_id } = useParams();
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const [productInfo, setProductInfo] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [trialPast, setTrialPast] = useState(false);
    const [priceVerification, setPriceVerification] = useState(false);
    const [pack, setPack] = useState("1");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const auth = getAuth();

    const { premiumStatus, premiumTier, accountStatus, channelLinked, accessLevel, checked, firstOpenPopup } = PremiumCredentials();

    useEffect(() => {
        const getUserDetails = async () => {
            //Get user details
            const userRef = doc(db, 'Users', mainUserId);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                const today = new Date().toDateString();
                const packExpirationDateExists = data.hasOwnProperty('pack_expiration_date');
                const packExpiryDate = packExpirationDateExists ? data.pack_expiration_date.toDate().toDateString() : null;

                //Trial expired
                if (packExpiryDate !== null && (Date.parse(today) - Date.parse(packExpiryDate)) > 0) {
                    setTrialPast(true);
                }

                //Trial expired
                if (data.access_level !== 5) {
                    setTrialPast(true);
                }

                //Credits expired
                if (data.account_status !== "ACTIVE" && data.access_level === 5) {
                    setTrialPast(true);
                }

                setPriceVerification(true);
            }
        }
        getUserDetails();
    }, []);

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // //console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists

                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, mainUserId,]);

    const logout = async () => {
        try {
            //console.log("Logging out...");
            await logOut();
        } catch (error) {
            //console.log(error)
        }
    }

    const checkEmail = async (email) => {
        try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            // If the array of sign-in methods returned is not empty, the email is registered and enabled
            if (methods.length > 0) {
                //console.log("Account auth active.");
                return true;
            } else {
                //console.log("Account auth not active/found.");
                return false;
            }
        } catch (error) {
            console.error(error);
            // Handle any errors that occur during the check
        }
    };

    useEffect(() => {
        const getProductInfo = async () => {
            try {
                // Reference to the PricingTable collection in Firestore
                const pricingTableRef = collection(db, 'PricingTable');
                const q = query(
                    pricingTableRef,
                    where('visible', '==', true),
                    orderBy("order", "asc"),
                );

                // Execute the query and get the documents
                const pricingTableDocs = await getDocs(q);

                //console.log('Price Snapshot:', pricingTableDocs);

                // Process each document to fetch pricing details
                const productInfo = await Promise.all(
                    pricingTableDocs.docs.map(async (pricingTableDoc) => {
                        // Destructure relevant fields from the document
                        const { id, product_id, pricing_id, pricing_id_full, ...rest } = pricingTableDoc.data();

                        // //console.log(pricing_id, 'id')
                        // //console.log(pricing_id_full, 'full')
                        // // Fetch pricing details from the Products collection

                        //console.log(trialPast, 'Trial Past')
                        if (trialPast === false) {
                            const priceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', pricing_id,));
                            const price_data = priceSnapshot.exists() ? priceSnapshot.data() : null;

                            //// Log the price snapshot for debugging
                            // //console.log('Price Snapshot:', priceSnapshot);

                            // Return the aggregated data
                            return {
                                id,
                                product_id,
                                pricing_id,
                                pricing_id_full,
                                price_data,
                                ...rest
                            };
                        } else {
                            const priceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', pricing_id_full,));
                            const price_data = priceSnapshot.exists() ? priceSnapshot.data() : null;

                            // //console.log(price_data, 'Here');
                            //// Log the price snapshot for debugging
                            // //console.log('Price Snapshot:', priceSnapshot);

                            // Return the aggregated data
                            return {
                                id,
                                product_id,
                                pricing_id_full,
                                price_data,
                                ...rest
                            };
                        }

                    })
                );

                // Log the product info for debugging
                //console.log('Product Info:', productInfo);

                // Update state with the fetched product info
                setProductInfo(productInfo);
                setIsExploding(true);
            } catch (error) {
                console.error('Error fetching product info:', error);
            }
        };
        getProductInfo();
    }, [trialPast]);

    const handleOneTimePayment = async (id, pricing_id, quota) => {
        //console.log(pricing_id);
        //console.log(window.location.origin);

        try {
            setPaymentLoading(true);
            await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), {
                price: pricing_id,
                mode: "payment",
                success_url: window.location.origin + `/dashboard#purchase-success`,
                cancel_url: window.location.origin + location.pathname + '?success=false',
            }).then((docRef) => {
                //console.log(docRef.id);

                const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
                    (document) => {
                        //console.log("Current data: ", document.data());
                        const { error, url } = document.data();
                        if (error) {
                            //console.log(`An error occured: ${error.message}`);
                        }
                        if (url) {

                            const userDoc = doc(db, "Users", mainUserId)
                            const data = {
                                pricing_table_item_id: id.trim(),
                            }
                            setDoc(userDoc, data, { merge: true });

                            window.location.assign(url);
                            setPaymentLoading(false);
                        }
                    });
            });
        } catch (error) {
            setPaymentLoading(false);
            //console.log(`An error occured: ${error.message}`);
        }
    };

    // const claimFreeCredits = async (code) => {
    //     //console.log('Activating with code:', code);
    //     setIsModalOpen(false);
    // }

    // const goBack = () => {
    //     let path = `/dashboard`;
    //     navigate(path);
    //     // navigate(-1);
    // }

    return (
        <div className='bg-gray-50'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Billing</title>
                <meta name="description" content="Billing Page of Thumbnail Geek" />
            </Helmet>

            <FreeCreditsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="pricing" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-30 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">
                            <div className="">
                                <h5 className={`text-xl text-gray-600 font-semibold lg:block`}>Recharge your credits</h5>
                            </div>
                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="relative 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mr-5">
                            <div className="w-full h-full mx-auto">


                                {isExploding && trialPast === false &&
                                    <>
                                        <div className="absolute top-0 left-0 ">
                                            <ConfettiExplosion zIndex={52} />
                                        </div>
                                        <div className="absolute top-0 right-1/2">
                                            <ConfettiExplosion zIndex={52} />
                                        </div>
                                        <div className="absolute top-0 right-0">
                                            <ConfettiExplosion zIndex={52} />
                                        </div>
                                    </>
                                }

                                <div className="mx-auto max-w-6xl pb-32 pt-8">
                                    {
                                        productInfo && priceVerification === true
                                            ?
                                            <>
                                                <div className="flex flex-col justify-center antialiased text-gray-600 mt-12 ">
                                                    <div className="">
                                                        <div className="max-w-7xl mx-auto" x-data="{ annual: true }">

                                                            {/* Free Thank You Credits */}
                                                            <div className="pointer-events-none sm:flex sm:justify-center sm:px-12 sm:pb-5 lg:pb-8 -mt-5">
                                                                <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-purple-600 px-6 py-2.5 sm:rounded-xl sm:py-3">
                                                                    <p className="text-sm leading-6 text-white">
                                                                        <a href="#" className="inline-flex items-center text-white">
                                                                            <strong className="font-semibold">Claim Your Free Credits Here!</strong>
                                                                            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                                                                                <circle r={1} cx={1} cy={1} />
                                                                            </svg>
                                                                            <button onClick={() => setIsModalOpen(true)} className="hover:underline">Click here to redeem</button>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* <div className="pointer-events-none sm:flex sm:justify-center sm:px-12 sm:pb-5 lg:pb-8 -mt-5">
                                                                <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-600 px-6 py-2.5 sm:rounded-xl sm:py-3">
                                                                    <p className="text-sm leading-6 text-white">
                                                                        <button onClick={() => handleOneTimePayment('6OWiR4rqS2AWR4Dh3i1x', 'price_1PHatqD6B9LkfDQtKJYPCwhs', '')} className="hover:underline">Link</button>
                                                                    </p>
                                                                </div>
                                                            </div> */}

                                                            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                                                                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                                                    {/* Simple pricing.  No subscription. */}
                                                                    Pay once, not monthly.
                                                                </p>
                                                            </div>

                                                            <div className="mt-8 flex flex-col justify-center">
                                                                <div className="flex justify-center mt-2 mb-4">
                                                                    <RadioGroup
                                                                        value={pack}
                                                                        onChange={setPack}
                                                                        className="grid grid-cols-2 rounded-lg text-center text-base font-semibold leading-5"
                                                                    >
                                                                        <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>

                                                                        <RadioGroup.Option
                                                                            value='1'
                                                                            className={({ checked }) =>
                                                                                classNames(
                                                                                    checked ? 'border-b-2 border-gray-700 text-gray-700' : 'border-b-2 border-gray-400 text-gray-400',
                                                                                    'cursor-pointer px-5 py-1'
                                                                                )
                                                                            }
                                                                        >
                                                                            <span>Individuals</span>
                                                                        </RadioGroup.Option>

                                                                        <RadioGroup.Option
                                                                            value='2'
                                                                            className={({ checked }) =>
                                                                                classNames(
                                                                                    checked ? 'border-b-2 border-gray-700 text-gray-700' : 'border-b-2 border-gray-400 text-gray-400',
                                                                                    'cursor-pointer px-5 py-1'
                                                                                )
                                                                            }
                                                                        >
                                                                            <span>Businesses</span>
                                                                        </RadioGroup.Option>

                                                                    </RadioGroup>
                                                                </div>
                                                            </div>

                                                            <div className="">
                                                                <div className="w-full text-center text-lg mt-3">
                                                                    {
                                                                        pack === '1'
                                                                            ?
                                                                            <p>Analyze, test and improve more thumbnails & titles to grow your channel & increase views rapidly. <br />Available only for individual use.</p>
                                                                            :
                                                                            <p>Explode your views and customers. Analyze, test, improve hundreds of thumbnails & titles.<br />Suitable both for individual and business use.</p>
                                                                    }
                                                                </div>

                                                                <div className="grid grid-cols-12 gap-6 mt-16">
                                                                    {productInfo
                                                                        .filter(product => (pack === '1' && product.level === 1) || (pack === '2' && product.level === 2))
                                                                        .map((product, index) => (
                                                                            <div key={product.id} className={` relative flex-none col-span-full md:col-span-4 bg-white rounded-2xl border-4 
                                                                                ${product.highlight ? 'border-blue-500' : 'border-gray-200'} `}>
                                                                                {
                                                                                    product.highlight === true
                                                                                        ?
                                                                                        <div className="rounded-xl absolute -top-5 right-1/2 px-5 py-2 translate-x-1/2 shadow bg-blue-500 text-white text-sm font-bold">
                                                                                            Best Value
                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <div className="px-7 pt-10 pb-6 border-b border-gray-200">
                                                                                    <header className="flex items-center mb-2">
                                                                                        <h3 className="text-xl text-gray-800 font-bold">{product.name}</h3>
                                                                                    </header>
                                                                                    {
                                                                                        pack === '1'
                                                                                            ?
                                                                                            <>
                                                                                                <div className="text-gray-800 font-bold mb-4">
                                                                                                    <div className="">
                                                                                                        <span className="text-3xl">$</span><span className="text-4xl">
                                                                                                            {
                                                                                                                (product.id === 'NbyNzAS4fapLpoxFIJPW' || product.id === 'S1wEkpJUsCBsGK0u9zam') && trialPast === false
                                                                                                                    ?
                                                                                                                    (((product.price_data.unit_amount / 100) * 2).toFixed(0)) / 2
                                                                                                                    :
                                                                                                                    ((product.price_data.unit_amount / 100).toFixed(0))
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="inline-flex mt-2">

                                                                                                        {
                                                                                                            product.id === 'NbyNzAS4fapLpoxFIJPW' || product.id === 'S1wEkpJUsCBsGK0u9zam'
                                                                                                                ?

                                                                                                                trialPast === false
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <div className="text-gray-600 font-normal text-[18px] mr-1 line-through">
                                                                                                                            ${(product.price_data.unit_amount * 2) / 100}
                                                                                                                        </div>
                                                                                                                        <div className="w-auto flex items-center justify-center ring-1 px-1 mr-2 rounded-md text-gray-600 font-bold text-sm">
                                                                                                                            <span className="text-[12px]">-50%</span>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                                :
                                                                                                                <>
                                                                                                                </>
                                                                                                        }

                                                                                                        <div className="text-gray-600 font-bold text-lg">one-time payment</div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                {
                                                                                                    trialPast === false
                                                                                                        ?

                                                                                                        paymentLoading === false
                                                                                                            ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    product.highlight === true
                                                                                                                        ?
                                                                                                                        <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                                                                            justify-center px-3 py-3 rounded-lg leading-5 
                                                                                                                            shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                                                                            focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                                                            Buy Now
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                                                                            justify-center px-3 py-3  rounded-lg leading-5 
                                                                                                                            shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                                                                            focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                                                            Buy Now
                                                                                                                        </button>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                                                                shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                                                                focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                                                                <div className="inline-flex space-x-1 text-center">
                                                                                                                    <span>Loading</span>
                                                                                                                    <DotDotDot className="pl-2" />
                                                                                                                </div>
                                                                                                            </button>
                                                                                                        :
                                                                                                        paymentLoading === false
                                                                                                            ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    product.highlight === true
                                                                                                                        ?
                                                                                                                        <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id_full) }} className="font-medium text-sm inline-flex items-center 
                                                                                                                            justify-center px-3 py-3 rounded-lg leading-5 
                                                                                                                            shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                                                                            focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                                                            Buy Now
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id_full) }} className="font-medium text-sm inline-flex items-center 
                                                                                                                            justify-center px-3 py-3  rounded-lg leading-5 
                                                                                                                            shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                                                                            focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                                                            Buy Now
                                                                                                                        </button>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                                                                shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                                                                focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                                                                <div className="inline-flex space-x-1 text-center">
                                                                                                                    <span>Loading</span>
                                                                                                                    <DotDotDot className="pl-2" />
                                                                                                                </div>
                                                                                                            </button>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="text-gray-800 font-bold mb-4">
                                                                                                    <div className="">
                                                                                                        <span className="text-3xl">$</span><span className="text-4xl">
                                                                                                            {
                                                                                                                product.id === 'od94ax2eTfA1UZflixxM'
                                                                                                                    ?
                                                                                                                    (((product.price_data.unit_amount / 100).toFixed(0)) * 2) / 2
                                                                                                                    :
                                                                                                                    ((product.price_data.unit_amount / 100).toFixed(0))
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="inline-flex mt-2">
                                                                                                        {
                                                                                                            trialPast === false
                                                                                                                ?
                                                                                                                product.id === 'od94ax2eTfA1UZflixxM'
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <div className="text-gray-600 font-normal text-[18px] mr-1 line-through">
                                                                                                                            ${(product.price_data.unit_amount * 2) / 100}
                                                                                                                        </div>
                                                                                                                        <div className="w-auto flex items-center justify-center ring-1 px-1 mr-2 rounded-md text-gray-600 font-bold text-sm">
                                                                                                                            <span className="text-[12px]">-50%</span>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                                :
                                                                                                                <>
                                                                                                                </>
                                                                                                        }
                                                                                                        <div className="text-gray-600 font-bold text-lg">one-time payment</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    paymentLoading === false
                                                                                                        ?
                                                                                                        <>
                                                                                                            {
                                                                                                                product.highlight === true
                                                                                                                    ?
                                                                                                                    <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id, product.ai_credits_quota) }} className="font-bold text-sm inline-flex items-center 
                                                                                                            justify-center px-3 py-3 rounded-lg leading-5 shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                                                            focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                                                        Buy Now
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id, product.ai_credits_quota) }} className="font-bold text-sm inline-flex items-center 
                                                                                                            justify-center px-3 py-3  rounded-lg leading-5 shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                                                            focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                                                        Buy Now
                                                                                                                    </button>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                                                shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                                                focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                                                            <div className="inline-flex space-x-1 text-center">
                                                                                                                <span>Loading</span>
                                                                                                                <DotDotDot className="pl-2" />
                                                                                                            </div>
                                                                                                        </button>
                                                                                                }
                                                                                            </>
                                                                                    }

                                                                                </div>

                                                                                <div className="px-7 pt-4 pb-5">
                                                                                    <div className="text-xs text-gray-800 font-semibold uppercase mb-4">What's included</div>
                                                                                    <ul>
                                                                                        {product.features.map((feature, index) => (
                                                                                            <li key={index} className="flex gap-x-3 mb-3">
                                                                                                <svg className="w-3 h-3 mt-2 flex-shrink-0 fill-current text-blue-500 mr-2" viewBox="0 0 12 12">
                                                                                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                                                                                </svg>
                                                                                                <div className="text-sm">{feature}</div>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* {productInfo
                                                        .filter(product => (pack === '1' && product.level === 0))
                                                        .map((product, index) => (
                                                            <>
                                                                <div className="relative mt-12">
                                                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                        <div className="px-6 w-full border-t border-gray-300" />
                                                                    </div>
                                                                    <div className="relative flex justify-center">
                                                                        <span className="bg-gray-50 px-2 text-sm text-gray-500">Or</span>
                                                                    </div>
                                                                </div>
                                                                <div className="py-4">
                                                                    <div className="mx-auto max-w-6xl">
                                                                        <div className="mx-auto mt-8 max-w-2xl rounded-3xl border-4 border-gray-200 lg:mx-0 lg:flex lg:max-w-none">
                                                                            <div className="p-8 sm:p-10 lg:flex-auto">
                                                                                <h3 className="text-2xl font-bold tracking-tight text-gray-900">{product.name}</h3>
                                                                                <p className="mt-3 text-base leading-7 text-gray-600">
                                                                                    For quick easy access.
                                                                                </p>
                                                                                <ul
                                                                                    role="list"
                                                                                    className="mt-6 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-1"
                                                                                >
                                                                                    {product.features.map((feature, index) => (
                                                                                        <li key={index} className="flex gap-x-3 mb-3">
                                                                                            <svg className="w-3 h-3 mt-2 flex-shrink-0 fill-current text-blue-500 mr-2" viewBox="0 0 12 12">
                                                                                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                                                                            </svg>
                                                                                            <div className="text-sm">{feature}</div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                                                                                <div className="rounded-2xl bg-gray-50 py-10 text-center border-4 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                                                                    <div className="mx-auto max-w-2xl px-8">
                                                                                        <p className="text-base font-semibold text-gray-600">one-time payment</p>
                                                                                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                                                            <span className="text-5xl font-bold tracking-tight text-gray-900">${product.price_data.unit_amount / 100}</span>
                                                                                        </p>

                                                                                        {
                                                                                            paymentLoading === false
                                                                                                ?
                                                                                                <button onClick={() => { handleOneTimePayment(product.id, product.pricing_id) }}
                                                                                                    className="mt-10 block w-64 font-medium text-sm items-center justify-center px-3 py-3 rounded-lg leading-5 
                                                                                shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none hover:text-white focus-visible:ring-2 hover:bg-blue-500 text-white">
                                                                                                    Buy Now
                                                                                                </button>
                                                                                                :
                                                                                                <button disabled={true}
                                                                                                    className="mt-10 block w-64 font-medium text-sm items-center justify-center px-3 py-3 rounded-lg leading-5 
                                                                                shadow-sm transition duration-150 ease-in-out bg-slate-400 focus:outline-none text-white">
                                                                                                    <div className="inline-flex space-x-1 text-center">
                                                                                                        <span>Loading</span>
                                                                                                        <DotDotDot className="pl-2" />
                                                                                                    </div>
                                                                                                </button>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </>
                                                        ))} */}

                                                    <p className="mt-12 text-xs leading-5 text-gray-600 text-center">
                                                        {/* All packages adhere to our terms and conditions. */}
                                                        Basic & Pro packs have 6-month credit expiry. Credits for all other packs expire in 1 year. <br />By making a purchase you agree to our Terms and Conditions and Privacy Policy
                                                        {/* You will have access to all features of a selected pack so far as your account has active credits. */}
                                                    </p>
                                                </div>
                                            </>
                                            :
                                            <div className="w-full flex flex-col justify-center mt-32 items-center">
                                                <div className="flex flex-col items-center justify-center mb-3">
                                                    <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                        <path
                                                            className="fill-gray-300"
                                                            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                        <path
                                                            className="fill-gray-500"
                                                            d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                    </svg>
                                                </div>

                                                Arranging the things...
                                            </div>
                                    }

                                </div>

                            </div >
                        </div >
                    </div >
                </div >
            </div >
            {/*       
            <div className="ml-auto pb-12 lg:w-full">
                <div className="px-6 min-h-screen">
                    <div className='fixed top-0 left-0 z-50 bg-gray-50 px-8 py-3 pt-4 border-gray-200 w-full flex justify-between lg:border-b mb-4'>
                        <div className="flex">
                            <button onClick={() => goBack()} className={`text-gray-800 bg-gray-200 hover:bg-gray-300
                             mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                <MdOutlineArrowBack />
                            </button>
                            <Link to={`/dashboard`} className='cursor-pointer w-48 text-gray-800 relative hover:text-gray-700 mt-1'>
                                <img src={logo} />
                            </Link>
                        </div>
                        <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                            <AvatarNoPremium />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default InsideBillingOneTime