import React, { useEffect, useState, Fragment } from 'react'
import { NavLink, useNavigate, useParams, } from 'react-router-dom'
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player/youtube';
import Cookies from 'js-cookie'
import axios from 'axios';
import { MdOutlineArrowBack, MdOutlineClear, MdOutlineKeyboardArrowDown, MdOutlineTagFaces, MdCenterFocusWeak, MdOutlineTipsAndUpdates, MdOpenInBrowser, MdCheck, MdClose } from "react-icons/md";
import { FaRegLightbulb, FaLightbulb, FaTv } from "react-icons/fa";
import { GoPaintcan } from "react-icons/go";
import { AiFillFire, AiOutlineEyeInvisible, AiOutlinePieChart } from "react-icons/ai";
import { BiCheck, BiMedal } from "react-icons/bi";
import { RiCloseLine, RiQuestionMark } from "react-icons/ri";
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, getDocs, query, orderBy, doc, onSnapshot } from "firebase/firestore";
import { PremiumCredentials } from '../../../context/PremiumContext';
import { toast } from 'react-toastify';
import { BsPhone, BsSun, BsCloudSun, BsTextareaResize, BsStars, BsBarChartFill, BsLightning, BsQuestion } from "react-icons/bs";
import { IoTabletLandscapeOutline, IoPeopleOutline, IoTrailSignOutline, IoResize, IoAnalytics, IoImageOutline, IoMagnetOutline } from "react-icons/io5";
import { IoIosDesktop, IoIosMagnet, IoIosRefresh, IoIosThumbsUp, IoMdAdd } from "react-icons/io";
import { FiShuffle } from "react-icons/fi";
import { SlScreenDesktop, SlSocialYoutube } from "react-icons/sl";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import { HiOutlineBell, HiOutlineHeart, HiOutlineExternalLink } from "react-icons/hi";
import { Switch } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import FocusBoardCollageImages from '../../components/FocusBoardCollageImages';
import { Disclosure } from '@headlessui/react'
import { Tab } from '@headlessui/react';
import Hint from '../../components/Hint';
import Tooltip from '../../components/Tooltip';
import logo from '../../../assets/logo_full_32.png';
import Lottie from "lottie-react";
import shuffle from "../../../assets/animations/shuffle_gray.json";
import ImageAnalysisModalST from '../../components/ImageAnalysisModalST';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { FcPieChart, FcStatistics } from 'react-icons/fc';
import AIChooseBestImageST from '../../components/AIChooseBestImageST';
import AddThumbnailsDialog from '../../components/AddThumbnailsDialog';
import { TbQuestionMark } from 'react-icons/tb';
import { RxQuestionMark } from 'react-icons/rx';
import ConfettiExplosion from 'react-confetti-explosion';
import { GiProcessor } from 'react-icons/gi';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const perks = [
    {
        name: 'Bright & Clear',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-light.svg',
        description:
            "The thumbnail should be unusually bright, clear and focused image. Make sure the main subject is easily visible, and the image is not blurry or pixelated or with jaggered edges from its cutout.",
    },
    {
        name: 'Eye-catching Colors',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-returns-light.svg',
        description:
            'Ensure colors that contrast well with each other. Check colors in Dark and Light modes. Vibrant colors tend to work better than dull pastel colors. A high-contrast thumbnail can help your video stand out in a sea of other videos.',
    },
    {
        name: '3-second rule',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description:
            "Turn away from your thumbnail and then quickly glance at it. If you cannot clearly decipher the text, concept, or meaning of the image within the first few seconds, your thumbnail won't work. It needs to be easily understandable at a glance.",
    },
    {
        name: 'Readable text',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description:
            "Ensure your thumbnail has text that is easy to read and complements the image. Avoid using too many different fonts. Cursive can be difficult to read at a glance. Ensure that the font is bold and appropriately sized.",
    },
    {
        name: 'Strong Emotion',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description:
            "Make sure your thumbnail conveys a strong emotion by using faces or it has colors to evoke excitement or calmness in absence of faces. Emotion is a powerful tool to capture the viewer's attention.",
    },
    {
        name: 'Show & Tell',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description:
            "Ensure that your thumbnail visually shows the idea of your video, while your title tells the viewer what the idea is about. This alignment helps to reinforce your message and increase the likelihood that viewers will click on your video.",
    },
]

const ABComparisonSuite = () => {
    let { test_id } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    // const currentTestId = localStorage.getItem("currentTestId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [tItems, setTItems] = useState([]);
    const [selectedThumbnailId, setSelectedThumbnailId] = useState('');
    const [selectedThumb, setSelectedThumb] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [statsMode, setStatsMode] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [statsLoaded, setStatsLoaded] = useState(false);
    const [liteAnalytics, setLiteAnalytics] = useState(false);
    const [deviceOption, setDeviceOption] = useState(1);
    const [statisticsModal, setStatisticsModal] = useState(false);
    const [currentStats, setCurrentStats] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);
    const [statsItems, setStatsItems] = useState(null);

    const [comparisonEnabled, setComparisonEnabled] = useState(true)
    const [open, setOpen] = useState(true)

    const [tgScore, setTgScore] = useState('')
    const [clarityScore, setClarityScore] = useState('')
    const [emotionScore, setEmotionScore] = useState('')
    const [flairScore, setFlairScore] = useState(0)
    const [resolutionScore, setResolutionScore] = useState(0)
    const [Distinctiveness, setDistinctivenessScore] = useState('')
    const [Consistency, setConsistencyScore] = useState('')
    const [Resolution, setResolution] = useState('')
    const [Quality, setQualityScore] = useState('')
    const [faceAssessment, setFaceAssessment] = useState('')
    const [darkModeColors, setDarkModeColors] = useState([])
    const [lightModeColors, setLightModeColors] = useState([])
    const [boldColors, setBoldColors] = useState([])
    const [fbModal, setFbModal] = useState(false);
    const [ctModal, setCtModal] = useState(false);
    const [focusBoards, setFocusBoards] = useState([]);
    const [helpModal, setHelpModal] = useState(false);
    const [fBsLoaded, setfBsLoaded] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [refreshPage, setRefreshPage] = useState(false);
    const [showcaseThumbnail, setShowcaseThumbnail] = useState('');
    const [showcaseTitle, setShowcaseTitle] = useState('');
    const [shuffling, setShuffling] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [lastThumbnailId, setLastThumbnailId] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [extensionModal, setExtensionModal] = useState(false);
    const [showEvaluationModal, setShowEvaluationModal] = useState(false);
    const [showAddThumbnailsModal, setShowAddThumbnailsModal] = useState(false);
    const [redoAnalysis, setRedoAnalysis] = useState(false);
    const [thumbnailId, setThumbnailId] = useState('');
    const [hiddenThumbnailId, setHiddenThumbnailId] = useState('');
    const [isExploding, setIsExploding] = useState(false);
    const [requestStatus, setRequestStatus] = useState('idle');

    const hideControls = () => {
        setControlsVisible(false);
    };

    const showControls = () => {
        setControlsVisible(true);
    };

    const handleShowFBModal = () => {
        setFbModal(true);
        getFocusBoards();
    }

    const handleFBModalClose = (e) => {
        if (e.target.id === "fb-picker-modal") {
            setFbModal(false);
        }
    }

    const handleCTModalClose = (e) => {
        if (e.target.id === "chosen-thumb-modal") {
            setIsExploding(false);
            setCtModal(false);
        }
    }
    const CTModalClose = (e) => {
        setIsExploding(false);
        setCtModal(false);
    }

    const getFocusBoards = async () => {
        const colRef = collection(db, 'Users', mainUserId, 'FocusBoards');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setFocusBoards(newData);
                setfBsLoaded(true);
                // console.log(boards, newData);
            })
    }

    const handleShowHelpModal = () => {
        setHelpModal(true);
    }

    const handleHelpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setSelectedIndex(0);
            setHelpModal(false);
        }
    }

    const removeTestItem = async (thumbnail_id) => {
        setHiddenThumbnailId(thumbnail_id);
        // setTItems((state) => state.filter((item) => item.thumbnail_id !== thumbnail_id));
    }

    useEffect(() => {
        if (refreshPage === true) {
            setShowAddThumbnailsModal(false);
            getThumbnails();
            // window.location.reload();
        }
    }, [refreshPage]);

    useEffect(() => {
        let timerId;

        if (hiddenThumbnailId !== '') {
            timerId = setTimeout(() => {
                console.log('Timer expired!');

                setTItems((state) => state.filter((item) => item.thumbnail_id !== hiddenThumbnailId));
                setHiddenThumbnailId('');
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [hiddenThumbnailId]);

    const removeAllOtherItems = async (thumbnail_id) => {
        setTItems((state) => state.filter((item) => item.thumbnail_id === thumbnail_id));
    }

    useEffect(() => {
        getThumbnails();
    }, []);

    // useEffect(() => {
    //     if (evaModal === false) {
    //         setStatsLoaded(false);
    //         setStatsItems(null);
    //     }
    // }, [statisticsModal]);

    const getThumbnails = async () => {
        const docRef = doc(db, 'Users', mainUserId, 'SplitTests', test_id);
        const testDocSnap = await getDoc(docRef);
        if (testDocSnap.exists()) {
            console.log("Document data:", testDocSnap.data());
            setTItems(testDocSnap.data().items);
            setLoaded(true);
        } else {
            // doc.data() will be undefined in this case
            toast("Unable to load thumbnails", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
            console.log("No such document!");
        }
    }

    // const viewInsights = (thumbnail_id) => {
    //     // console.log('ThumbnailId - ' + thumbnail_id);
    //     setThumbnailId(thumbnail_id);
    //     setShowAnalysisModal(true);
    // }

    function shuffleArray(array) {
        let currentIndex = array.length,
            randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ];
        }
        return array;
    }

    const handleShuffleClick = () => {
        // //Setting to Comparison Mode
        // if (statsMode === true) {
        //     toast.info('Switched to Comparison Mode', {
        //         position: "top-right",
        //         autoClose: 2000,
        //     });
        //     setStatsMode(!statsMode);
        // }

        setShuffling(true);
        const shuffledTitles = shuffleArray(tItems.map(item => item.title));
        const shuffledThumbnails = shuffleArray(tItems.map(item => item.thumbnail));
        const shuffledThumbnailIds = shuffleArray(tItems.map(item => item.thumbnail_id));
        const newTItems = [];

        for (let i = 0; i < tItems.length; i++) {
            const title = shuffledTitles[i];
            let thumbnail = shuffledThumbnails[i];
            const thumbnail_id = shuffledThumbnailIds[i];

            // Check if title was previously assigned this thumbnail
            const prevTitleWithSameThumbnail = tItems.find(item => item.thumbnail === thumbnail && item.title !== title);
            if (prevTitleWithSameThumbnail) {
                // Swap thumbnails between the two titles
                thumbnail = prevTitleWithSameThumbnail.thumbnail;
                prevTitleWithSameThumbnail.thumbnail = shuffledThumbnails.find(thumbnail => thumbnail !== prevTitleWithSameThumbnail.thumbnail && !newTItems.some(item => item.thumbnail === thumbnail));
            }

            // Check if another title already has this thumbnail
            const prevItemWithSameTitle = newTItems.find(item => item.title === title);
            if (prevItemWithSameTitle) {
                // Swap thumbnails between the two titles
                thumbnail = prevItemWithSameTitle.thumbnail;
                prevItemWithSameTitle.thumbnail = shuffledThumbnails.find(thumbnail => thumbnail !== prevItemWithSameTitle.thumbnail && !newTItems.some(item => item.thumbnail === thumbnail));
            }

            newTItems.push({
                title,
                thumbnail,
                thumbnail_id
            });
        }

        if (shuffling === false) {
            setTItems(newTItems);
        }
    }

    useEffect(() => {
        let timerId;
        const min = 1.2; // Minimum time in seconds
        const max = 2.8; // Maximum time in seconds
        const random = Math.random();
        const time = random * (max - min) + min;
        const timeInMilliseconds = time * 1000;

        if (shuffling) {
            timerId = setTimeout(() => {
                console.log('Timer expired!');
                setShuffling(false);
            }, timeInMilliseconds);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [shuffling]);

    const handleFBTestClick = (boardId) => {
        console.log(tItems.length);
        console.log(tItems[0].thumbnail_id);
        console.log(boardId);
        if (tItems.length === 1) {
            let path = `/focus-board-testing/${boardId}/${tItems[0].thumbnail_id}`;
            navigate(path);
        }
    }

    const handleFBTestClickNewTab = (boardId) => {
        console.log(tItems.length);
        console.log(tItems[0].thumbnail_id);
        console.log(boardId);
        if (tItems.length === 1) {
            let path = `/focus-board-testing/${boardId}/${tItems[0].thumbnail_id}`;
            window.open(path, '_blank');
            // navigate(path);
        }
    }

    const handleYTTestClick = () => {
        setProcessing(true);

        // Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        // Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            document.cookie = "test_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            const url = "https://www.youtube.com";
            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                //Set Page Intent
                const intent = "OPENTESTER";
                const userDoc = doc(db, "Users", mainUserId)
                const data = {
                    page_intent: intent,
                    tg_tt_current_Image: tItems[0].thumbnail,
                    tg_tt_current_Title: tItems[0].title,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    // // insert current image and titles into cookies
                    document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
                    document.cookie = "tg_tt_current_Image=" + tItems[0].thumbnail + "; path=/;";
                    document.cookie = "tg_tt_current_Title=" + tItems[0].title + "; path=/;";
                    window.open(url, '_blank');

                    setProcessing(false);
                }).catch((e) => {

                    setProcessing(false);
                    toast("Unable to verify connection.", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                });

            } else {
                setProcessing(false);
                setExtensionModal(true);
            }

        } else {
            setProcessing(false);
            // Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const handleComparisonSwitch = (value) => {
        setComparisonEnabled(value);
        getThumbnails();
    }

    const closeStatisticsModal = (e) => {
        if (e.target.id === "statistics-modal") {
            setStatisticsModal(false);
        };
    }

    const getStatisticsForThumbnail = async (thumbnail_id) => {
        const docRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnail_id);
        const statDocSnap = await getDoc(docRef);
        if (statDocSnap.exists()) {
            const data = statDocSnap.data();
            console.log("Stats data:", data);
            setStatsItems(data);

            // Access properties using dot notation
            const brightness = data['average_brightness'];
            const edge_detect_background = data['background_edge_detect_background'];
            const edge_detect_foreground = data['background_edge_detect_foreground'];
            const background = data['background_separation_background'];
            const foreground = data['background_separation_foreground'];
            const sharpness_percentage = data['clarity_sharpness_percentage'];
            const faces_detected = data['faces_detected'];
            const faces_summary = data['face_data_summary'];

            let anger = typeof faces_summary['anger'] !== 'undefined' ? Number(faces_summary['anger']) : null;
            let joy = typeof faces_summary['joy'] !== 'undefined' ? Number(faces_summary['joy']) : null;
            let sorrow = typeof faces_summary['sorrow'] !== 'undefined' ? Number(faces_summary['sorrow']) : null;
            let surprise = typeof faces_summary['surprise'] !== 'undefined' ? Number(faces_summary['surprise']) : null;

            const has_bold_colors = data['has_bold_colors'];
            const vibrant_dark_mode_colors = data['vibrant_colors_darkMode'];
            const vibrant_light_mode_colors = data['vibrant_colors_lightMode'];
            let resolution_height = data['resolution_height'];
            let resolution_width = data['resolution_width'];

            setDarkModeColors(data['vibrant_colors_darkMode']);
            setLightModeColors(data['vibrant_colors_lightMode']);
            setBoldColors(data['colors_n_similarity']);

            //Face Detection
            if (faces_detected > 0) {
                setEmotionScore(50);
            } else {
                setEmotionScore(0);
            }

            //All sorts of calculations
            //resolution score
            const recommendedWidth = 1280;
            const recommendedHeight = 720;
            let compWidth = resolution_height;
            let compHeight = resolution_width;

            if (resolution_width >= recommendedWidth && resolution_height >= recommendedHeight) {
                compWidth = recommendedWidth
                compHeight = recommendedHeight
            }
            const currentResolution = compWidth * compHeight;
            const recommendedResolution = recommendedWidth * recommendedHeight;
            const resScore = Math.floor((currentResolution / recommendedResolution) * 100);

            setResolutionScore(resScore);

            console.log('resScore:', resScore);
            console.log('currentResolution:', currentResolution);
            console.log('recommendedResolution:', recommendedResolution);
            console.log('background:', background);

            //Clarity
            //Brightness + Resolution + Background 
            const brightnessWeight = 0.4; // weighted advantage for brightness
            const resScoreWeight = 0.4;
            const backgroundWeight = 0.2;

            const totalWeight = brightnessWeight + resScoreWeight + backgroundWeight;

            const brightness_percentage = Math.round((brightness / 255) * 100);
            const brightnessScore = brightness_percentage * brightnessWeight;
            const resScoreScore = resScore * resScoreWeight;
            const backgroundScore = background * backgroundWeight;

            const weightedSum = brightnessScore + resScoreScore + backgroundScore;
            const clarity = weightedSum / totalWeight;

            console.log('clarity', clarity);

            setClarityScore(clarity);
            console.log('Sharpness', sharpness_percentage);


            //Emotion
            //Faces - Face Blurriness + Primary colors in Image
            if (faces_detected > 0) {
                let emotSc = 0;
                if (anger > 1) {
                    emotSc = 25;
                }
                if (joy > 1) {
                    emotSc = emotSc + 25;
                }
                if (surprise > 1) {
                    emotSc = emotSc + 25;
                }
                if (sorrow > 1) {
                    emotSc = emotSc + 25;
                }
                setEmotionScore(emotSc);
            } else {
                setEmotionScore(0);
            }

            //Resolution
            if (resScore === 100 && resolution_width > 1280) {
                setResolution('High');
            } else if (resScore === 100 && resolution_width === 1280) {
                setResolution('Ok');
            } else {
                setResolution('Low');
            }

            //Flair
            let flairSc = 0;
            if (has_bold_colors === true) {
                flairSc = 40;
            }
            if (vibrant_dark_mode_colors.length > 0) {
                flairSc = flairSc + 30;
            }
            if (vibrant_light_mode_colors.length > 0) {
                flairSc = flairSc + 30;
            }

            setFlairScore(flairSc);
            setStatsLoaded(true);
        };
    }

    const handleDeviceOptions = (e) => {
        // if (statsMode === true) {
        //     statsModeToggle();
        // }

        if (e === 1) {
            setDeviceOption(1);
        } else if (e === 2) {
            setDeviceOption(2);
        } else if (e === 3) {
            setDeviceOption(3);
        } else if (e === 4) {
            setDeviceOption(4);
        }
    }

    const renderDeviceOptions = (e) => {
        if (deviceOption === 1) {
            return tItems && tItems?.map((item, i) => (
                <div key={i} className={`${hiddenThumbnailId === item.thumbnail_id ? 'transition-opacity duration-1000 opacity-0' : 'transition-opacity duration-400 opacity-100'} transition-opacity duration-1000 w-full lg:w-[308px] mx-2 pb-6`}>
                    <div className={` text-gray-800 w-72 pt-2 text-[0.9rem] mx-2 mb-5`}>
                        <div className='relative h-56'>
                            <div className='group'>
                                <div className="pb-2">
                                    <div className="relative inline-block overflow-hidden aspect-video w-full duration-200 ">
                                        <img className="@apply inline-block mx-0 w-full rounded-lg" src={item.thumbnail} alt="" />
                                        <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 
                                group-hover:opacity-10 group-active:opacity-5 duration-200">
                                        </div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-3 xl:mr-2 h-9 w-9 object-cover group-hover">
                                        <img className='rounded-full md:h-9 md:w-9 xl:h-9 xl:w-9' src={channelPhotoUrl} alt="" />
                                    </div>
                                    <div className='flex-col w-9/12 group-hover'>
                                        <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                            <p className={`text-[13px] ${darkMode === true ? 'text-gray-400' : 'text-gray-900'} font-roboto items-center leading-4 
                                    tracking-normal font-bold lg:text-[14px] mb:text-mb`}>{item.title}</p>
                                        </div>
                                        <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px] group-hover'>
                                            <p className={`${darkMode === true ? 'text-gray-400' : 'text-gray-500'} leading-4 line-clamp-1`}>
                                                {channelDisplayName}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {tItems.length > 1 &&
                                    <div>
                                        <div onClick={() => removeTestItem(item.thumbnail_id)}
                                            className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-2 
                                    inline-flex rounded-md bg-[#FC2959] hover:bg-[#fc2959] border border-white active:bg-gray-700 text-white px-1 py-1 text-center text-xs shadow-sm">
                                            <Tooltip content='Eliminate this thumbnail' position='bottom'>
                                                <MdOutlineClear className="text-lg" />
                                            </Tooltip>
                                        </div>


                                        <div onClick={() => removeAllOtherItems(item.thumbnail_id)}
                                            className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-10 
                                    inline-flex rounded-md bg-green-600 hover:bg-green-700 border border-white active:bg-gray-400 text-white px-1 py-1 text-center text-xs shadow-sm">
                                            <Tooltip content='Choose ONLY this thumbnail' position='bottom'>
                                                <MdCheck className="text-lg" />
                                            </Tooltip>
                                        </div>

                                        {/* <div onClick={() => viewInsights(item.thumbnail_id)}
                                            className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-[124px]
                                    inline-flex rounded-md bg-blue-500 hover:bg-blue-600 border border-white active:bg-gray-400 text-white px-1 py-1 text-center text-xs shadow-sm">
                                            <Tooltip content='View Intelligent Insights' position='bottom'>
                                                <IoAnalytics className="text-lg" />
                                            </Tooltip>
                                        </div> */}
                                    </div>
                                }
                            </div>
                        </div>
                        {/* {
                            statsMode === true &&
                            <ImageAnalysisSummary isVisible={statsMode} thumbnail_id={item.thumbnail_id} viewButtonClickCallback={viewInsights} redo={redoAnalysis} newTitle={item.title} />
                        } */}
                    </div>
                </div>
            ));
        } else if (deviceOption === 3) {
            return <div className="w-full flex flex-cols justify-center ">
                <div className="inline-block">
                    <div className={`mt-[-30px] pb-[-30px] p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[346px] h-[660px] 2xl:h-[860px] overflow-y-auto transition duration-700`}>
                        <div className={`pt-5 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[320px]`}>
                            <div className=" grid grid-cols-1  transition duration-700">
                                {
                                    tItems?.map((item, i) => (
                                        <div key={i} className={`${hiddenThumbnailId === item.thumbnail_id ? 'transition-opacity duration-1000 opacity-0' : 'transition-opacity duration-400 opacity-100'} transition-opacity duration-1000 w-full pb-6`}>
                                            <div className={` text-gray-800 w-full pt-2 text-[0.9rem] mb-5`}>
                                                <div className='relative h-56'>
                                                    <div className='group'>
                                                        <div className="pb-2">
                                                            <div className="relative group">
                                                                <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                                                    <img className="@apply inline-block mx-0 w-full " src={item.thumbnail} alt="" />
                                                                </div>
                                                                <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex ml-2'>
                                                            <div className="shrink-0 mx-0 my-0 mr-1 md:mr-1 xl:mr-1 h-8 w-8 object-cover">
                                                                <img className='rounded-full md:h-8 md:w-8 xl:h-7 xl:w-7' src={channelPhotoUrl} alt="" />
                                                            </div>
                                                            <div className='flex-col w-9/12'>
                                                                <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                    <p className={`${darkMode === true ? 'text-gray-400' : ''} font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>
                                                                        {item.title}
                                                                    </p>
                                                                </div>
                                                                <div className=''>
                                                                    <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                                        <p className={`${darkMode === true ? 'text-gray-400' : ''}`}>{channelDisplayName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {tItems.length > 1 &&
                                                            <div>
                                                                <div onClick={() => removeTestItem(item.thumbnail_id)}
                                                                    className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-2 
                                                        inline-flex rounded-md bg-[#FC2959] hover:bg-[#fc2959] border border-white active:bg-gray-700 text-white px-1 py-1 text-center text-xs shadow-sm">
                                                                    <Tooltip content='Eliminate this thumbnail' position='bottom'>
                                                                        <MdOutlineClear className="text-lg" />
                                                                    </Tooltip>
                                                                </div>


                                                                <div onClick={() => removeAllOtherItems(item.thumbnail_id)}
                                                                    className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-10 
                                                        inline-flex rounded-md bg-green-600 hover:bg-green-700 border border-white active:bg-gray-400 text-white px-1 py-1 text-center text-xs shadow-sm">
                                                                    <Tooltip content='Choose ONLY this thumbnail' position='bottom'>
                                                                        <MdCheck className="text-lg" />
                                                                    </Tooltip>
                                                                </div>

                                                                {/* <div onClick={() => viewInsights(item.thumbnail_id)}
                                                                    className="cursor-pointer absolute z-0 opacity-0 group-hover:opacity-100 right-2 top-[124px]
                                                        inline-flex rounded-md bg-blue-500 hover:bg-blue-600 border border-white active:bg-gray-400 text-white px-1 py-1 text-center text-xs shadow-sm">
                                                                    <Tooltip content='View Intelligent Insights' position='bottom'>
                                                                        <IoAnalytics className="text-lg" />
                                                                    </Tooltip>
                                                                </div> */}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {/* {
                                                    statsMode === true &&
                                                    <ImageAnalysisSummary isVisible={statsMode} thumbnail_id={item.thumbnail_id} viewButtonClickCallback={viewInsights} redo={redoAnalysis} newTitle={item.title} />
                                                } */}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    const openStatisticsModal = (statsItem) => {
        setCurrentStats(statsItem);
        if (currentStats) {
            setStatisticsModal(true);
        }
    }

    const handleClearClick = () => {
        if (comparisonEnabled === false) {
            setSelectedItem(null);
            const selectedRadio = document.querySelector('input[name="tt-radio-group"]:checked');
            if (selectedRadio) {
                selectedRadio.checked = false;
            }
        } else {
            setTItems([]);
            getThumbnails();
        }

    };

    const statsModeToggle = () => {
        // setStatsMode(!statsMode);

        toast("Stats Mode coming really soon", {
            icon: ({ theme, type }) =>
                <MdOutlineTipsAndUpdates className='text-blue-300 h-7 w-7' />
        });

        // setTItems([]);
        // getThumbnails();
        // setStatsMode(!statsMode)
    };

    const addNewThumbnails = () => {
        setShowAddThumbnailsModal(true);
    }

    const askAFriend = () => {
        toast("Coming really soon", {
            icon: ({ theme, type }) =>
                <MdOutlineTipsAndUpdates className='text-blue-300 h-7 w-7' />
        });
    }

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const aiEvaluationOfImages = () => {
        initiateEval();
    }

    const initiateEval = async () => {
        setRequestStatus('loading');
        const token = getCookie('TG_AT');
        console.log(token);

        let url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/ai-evaluate-split-test/${mainUserId}`;

        const data = {
            split_test_id: test_id,
        };

        const headers = {
            headers: {
                'Authorization': `${token}`,
            }
        };

        axios.post(url, data, headers)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data.status);
                    setShowEvaluationModal(true);
                    setRequestStatus('success');
                } else {
                    console.log('Error');
                    toast("Error. Unable to analyse with AI. Try later.", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                    setRequestStatus('error');
                }
            })
            .catch((error) => {
                console.error(error);
                setRequestStatus('error');
            });
    }

    const goBack = () => {
        let path = `/testing-suite/upload`;
        navigate(path);
        // navigate(-1);
    }

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const redoSelection = (e) => {
        setCtModal(false);
        setIsExploding(false);
        handleClearClick();
    }

    useEffect(() => {
        //if items is length 1 then show a modal of that item
        if (tItems.length === 1) {
            setShowcaseThumbnail(tItems[0].thumbnail);
            setShowcaseTitle(tItems[0].title);
            setCtModal(true);
            setIsExploding(true);
        }
    }, [tItems]);

    const installExtension = (url) => {
        const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
        const popupWindow = window.open(url, '_blank', windowFeatures);
        // Check if the popup was blocked
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            console.log('Popup was blocked or unable to open.');
            window.open(url, '_blank');
        } else {
            console.log('Popup opened successfully.');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Comparison | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Comparison | ThumbnailGeek" />
            </Helmet>

            <div id="fb-picker-modal" onClick={handleFBModalClose} className={`${fbModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg max-w-6xl border border-gray-400 shadow-md">
                    {
                        tItems.length === 1
                            ?
                            <>
                                <h1 className="font-semibold text-center text-sm text-md mb-2 mt-2 text-gray-700">
                                    Select a Focus Board to compare your thumbnail against.
                                </h1>
                                <div className="flex flex-col md:flex-row justify-center items-center">
                                    <div className="pt-5">
                                        {focusBoards.length === 0
                                            ?
                                            <>
                                                {
                                                    fBsLoaded === true
                                                        ?
                                                        <div className='pb-2 pt-5 text-neutral-500 flex flex-col items-center justify-center max-w-5xl'>
                                                            <div className="text-sm mb-5">No Focus Board created. </div>
                                                            <NavLink to="/focus-boards" target='_blank'>
                                                                <button className={`hover:cursor-pointer hover:bg-blue-700 bg-blue-600 inline-flex rounded-lg  p-3 px-5 text-white`}>
                                                                    <span className='text-sm mr-2'>Go to Focus Boards Page</span>
                                                                    <HiOutlineExternalLink className='h-4 w-4 mt-[2px]' />
                                                                </button>
                                                            </NavLink>
                                                        </div>
                                                        :
                                                        <div className="flex flex-wrap gap-x-4 max-w-5xl p-7">
                                                            Loading focus boards...
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className="flex flex-wrap gap-x-4 gap-y-1 max-w-5xl">
                                                {focusBoards?.map((doc, i) => (
                                                    <div key={doc.id} className="">
                                                        <div className="w-40 h-auto relative mb-5">
                                                            <div className="border border-gray-300 rounded-md w-40 h-24 bg-gray-200 cursor-pointer overflow-hidden group">
                                                                {/* <div className="m-3 w-7 h-7 text-center rounded-full bg-white border-gray-300 border-2 absolute">{doc.items.length}</div> */}
                                                                <FocusBoardCollageImages itemsData={doc.items} />
                                                                <div className="rounded-md absolute top-0 left-0 w-40 h-24 bg-black opacity-0 group-hover:opacity-50 group-active:opacity-80 duration-200">
                                                                    <div className="w-full h-full inline-flex">

                                                                        <div onClick={() => { handleFBTestClick(doc.id) }} className="w-1/2 flex flex-col justify-center items-center">
                                                                            <Tooltip content='Open Here' position='top' className='z-50'>
                                                                                <MdOpenInBrowser className="text-white text-3xl" />
                                                                            </Tooltip>
                                                                        </div>

                                                                        <div onClick={() => { handleFBTestClickNewTab(doc.id) }} className="w-1/2 flex flex-col justify-center items-center">
                                                                            <Tooltip content='Open in New Tab' position='top' className='z-50'>
                                                                                <HiOutlineExternalLink className="text-white text-3xl" />
                                                                            </Tooltip>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-full inline-flex">
                                                                <div onClick={() => { handleFBTestClickNewTab(doc.id) }} className="w-10/12 group">
                                                                    <div className="m-0 p-0 mt-2 pl-1 w-full cursor-pointer line-clamp-1 leading-tight lg:text-[14px] md:text-md font-medium">
                                                                        {doc.name}
                                                                    </div>
                                                                    <div className="m-0 p-0 pl-1 mt-0 inline-block text-gray-600 text-center text-[11px] text-grey-300">
                                                                        {`${doc.items.length} thumbnails`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="mx-auto">
                                    <div className="rounded-md bg-yellow-50 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-yellow-800">Focus Board Error</h3>
                                                <div className="mt-2 text-sm text-yellow-700">
                                                    <p>
                                                        Only one thumbnail can be tested on a focus board at a time.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                    }
                </div>
            </div>

            <div id="chosen-thumb-modal" onClick={handleCTModalClose} className={`opacity ${ctModal === true ? '' : 'hidden'} 
            fixed top-0 left-0 right-0 z-40 bg-black bg-opacity-25 backdrop-blur-sm p-6 overflow-x-hidden 
            overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>

                <div id='inner-container' className="bg-white p-5 mb-10 rounded-lg w-[420px] border border-gray-300 shadow-lg">

                    <div className="relative inline-flex mb-4 w-full">
                        <div className="inline-flex">
                            <div className="text-xl">👑</div>
                            <div className="text-gray-500 font-medium ml-2 pt-1">Your Choice</div>
                        </div>
                        <div onClick={CTModalClose} className="absolute -right-2 -top-2 p-2 rounded-lg hover:bg-gray-100">
                            <MdClose />
                        </div>
                    </div>

                    <div className={`w-full text-gray-800 pt-2 text-[0.9rem] mb-5`}>
                        <div className='relative'>
                            <div className='group'>
                                <div className="pb-2">
                                    <div className="relative inline-block overflow-hidden aspect-video w-full duration-200 ">
                                        <img className="@apply inline-block mx-0 w-full rounded-lg" src={showcaseThumbnail} alt="" />
                                        <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 
                                group-hover:opacity-10 group-active:opacity-5 duration-200">
                                        </div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-3 xl:mr-2 h-11 w-11 object-cover group-hover">
                                        <img className='rounded-full md:h-11 md:w-11 xl:h-11 xl:w-11' src={channelPhotoUrl} alt="" />
                                    </div>
                                    <div className='flex-col w-9/12 group-hover'>
                                        <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                            <p className={`text-[14px] ${darkMode === true ? 'text-gray-400' : 'text-gray-900'} 
                                            font-roboto items-center leading-4 tracking-normal font-bold lg:text-[15px] mb:text-mb`}>{showcaseTitle}</p>
                                        </div>
                                        <div className='text-[13px] font-roboto lg:text-[14px] mt-[7px] group-hover'>
                                            <p className={`${darkMode === true ? 'text-gray-400' : 'text-gray-500'} leading-4 line-clamp-1`}>
                                                {channelDisplayName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10">
                        <div className='flex flex-col w-full pt-2 text-[0.9rem] space-y-2 text-gray-500'>

                            <div className="inline-flex space-x-6">
                                <button onClick={handleYTTestClick} className={`ring-1 ring-gray-500 text-gray-500 hover:ring-red-600 hover:text-red-600
                                            w-full border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                    <SlSocialYoutube className='text-base mr-1' /><p className={`text-sm ml-1`}>Test on Youtube</p>
                                </button>
                            </div>
                            <div className="inline-flex space-x-6">
                                <button onClick={redoSelection} className={`ring-1 ring-gray-500 text-gray-500 hover:ring-purple-600 hover:text-purple-600
                                            w-full border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                    <IoIosRefresh className='text-base mr-1' /><p className={`text-sm ml-1`}>Redo Selection</p>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                {isExploding &&
                    <>
                        <div className="absolute top-0 left-0 ">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                        <div className="absolute top-0 right-1/2">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                        <div className="absolute top-0 right-0">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                    </>
                }

            </div>

            <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="relative bg-white p-5 items-center justify-center rounded-lg max-w-6xl border border-gray-400 shadow-md">

                    <button onClick={() => setHelpModal(false)} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="mx-auto">
                        <h3 className="text-base text-center font-semibold leading-6 text-gray-900">How to compare your thumbnails against each other</h3>
                    </div>
                    <div className="mt-2">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className='border-gray-200 text-gray-800 border-b text-sm'>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className={`inline-flex p-3`}>
                                        <p className='hidden lg:block'>Explanation</p>
                                    </span>
                                </Tab>
                                {/* <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className="inline-flex p-3">
                                        <p className='hidden lg:block'>Video</p>
                                    </span>
                                </Tab> */}
                            </Tab.List>
                            <Tab.Panels className='w-full'>
                                <Tab.Panel className='w-full max-w-6xl'>

                                    <div className="bg-gray-50 h-96 p-6 overflow-scroll">
                                        <div class="container flex flex-row">
                                            <ul class="w-1/2">
                                                <li class="mb-2 ml-4"><span class="font-bold text-center">Keep thumbnail if:</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The image is of high quality and clear.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The colors are bold and contrasting.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The text is compelling and easy to read.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The main subject is prominently displayed.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>Faces show clear emotions or expressions.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>Arrows or icons help direct attention.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The design is clean and uncluttered.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The thumbnail is easy to understand when the picture is smaller.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The concept makes sense at a glance.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The image is brighter than the rest of the thumbnail.</span></li>
                                                <li className="ml-4 inline-flex"><BiCheck className="text-green-600 text-xl" /><span>The title accurately describes what is in the thumbnail.</span></li>
                                            </ul>
                                            <ul class="w-1/2">
                                                <li class="mb-2 ml-4"><span class="font-bold text-center">Eliminate thumbnail if:</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>There is excessive text that overwhelms the thumbnail.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>Text is not bold and clear. Seems hard to read.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>The thumbnail image does not represent what will be in the video.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>Low-resolution images are used, resulting in a blurry or pixelated appearance.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>The thumbnail is overcrowded with too many visuals, making it confusing.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>The thumbnail is not easy to understand when the picture is smaller.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>It takes a sec or two to understand the concept at a glance.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>The image is darker than other thumbnails.</span></li>
                                                <li className="ml-4 inline-flex"><RiCloseLine className="text-red-600 text-xl pt-1" /><span>The title does not perfectly describe what is in the thumbnail.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab.Panel>

                                {/* <Tab.Panel className='w-full max-w-6xl md:h-auto'>
                                    <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                            className="rounded-lg overflow-hidden"
                                            width="853px"
                                            height="505px"
                                        />
                                    </div>
                                </Tab.Panel> */}
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>

            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or disabled the extension in your browser.
                            </p>
                            <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />

                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={() => installExtension('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Install Extension
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${processing === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg w-96 border border-gray-400 shadow-md">
                    <div className="w-full h-[105px] text-center animate-pulse flex items-center justify-center bg-gray-200 rounded-md mb-2">
                        <h1 className="font-semibold text-center text-md mb-4 mt-2 text-gray-700 animate-pulse">
                            Processing...
                        </h1>
                    </div>
                </div>
            </div>

            <div className={`${shuffling === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-40 backdrop-blur-sm p-4 transition overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="p-5 mb-16 rounded-lg w-96">
                    <Lottie animationData={shuffle} loop={true} />
                </div>
            </div>

            <div className={` ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818]'} transition duration-300 ml-auto pb-40 lg:w-[100%]`}>
                <button onClick={showControls} className={`${darkMode === false ? 'text-gray-800 hover:text-gray-600' : ' text-gray-400 hover:text-gray-300'} fixed top-5 right-5 text-xs inline-flex cursor-pointer`}>
                    <BsBoxArrowInDownLeft className="mt-[1px] mr-1" /><span>Restore</span>
                </button>
                <div className="px-6 min-h-screen">
                    <div className={`
                    transform 
                    ${controlsVisible ? 'translate-y-0' : '-translate-y-[70px]'} 
                    transition-transform duration-500 
                    ${controlsVisible ? 'ease-in-out' : 'ease-in-out'} 
                    fixed ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818] border-gray-700'} border-b right-0 left-0 top-0 z-10 w-full px-6 pt-3 pb-3 flex items-center justify-between`}>
                        <div className="inline-flex">
                            <button onClick={() => goBack()} className={`text-gray-800 ${darkMode === false ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-700 hover:bg-gray-800 text-gray-300'}
                             mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                <MdOutlineArrowBack />
                            </button>
                            <NavLink to="/dashboard" className=''>
                                <div className='relative w-2/3 mt-2 '>
                                    <img className='w-full h-full' src={logo} />
                                </div>
                            </NavLink>
                            {/* <button className='text-gray-800 border border-blue-700 bg-gray-100 hover:text-black hover:bg-gray-200 active:bg-gray-300 mb-0 pb-1 pt-1 pr-4 pl-3 rounded-md text-xl'>
                                <p className='text-sm ml-1 '>Add Another Thumbnail</p>
                            </button> */}
                        </div>

                        <div className='absolute right-1/2 translate-x-1/2 inline-flex' >
                            <div className={`text-base ${darkMode === false ? 'text-gray-700' : 'text-gray-300'} text-center`}>
                                <span className="font-normal text-gray-600">A/B Comparison |</span>
                                {
                                    statsMode !== true
                                        ?
                                        <span onClick={statsModeToggle} className="font-bold cursor-pointer"> Comparison Mode </span>
                                        :
                                        <span onClick={statsModeToggle} className="font-bold cursor-pointer"> Analysis Mode </span>
                                }
                            </div>
                        </div>

                        <div className="inline-flex space-x-2 justify-self-end mr-3">
                            <div className={`
                            ${darkMode === false ? 'border-gray-400' : 'border-gray-700'}
                            border-r flex justify-center items-center px-1`}>
                                <div className="inline-flex">
                                    <button onClick={() => handleDeviceOptions(1)}
                                        className={`
                                        hidden px-2 lg:inline-flex items-center justify-center cursor-pointer
                                        ${darkMode === false && deviceOption === 1 ? 'border-gray-300' : 'border-gray-700'} 
                                    `}>
                                        <SlScreenDesktop className={`hover:text-[#FC2959]
                                        ${darkMode === false ? 'text-gray-700' : 'text-gray-300'}
                                        ${deviceOption === 1 ? 'text-red-600' : ''}`} />
                                    </button>
                                </div>

                                <div className="inline-flex ">
                                    <button onClick={() => handleDeviceOptions(3)}
                                        className={`
                                        px-2 inline-flex items-center justify-center ${darkMode === false && deviceOption === 3 ? 'border-gray-300' : 'border-gray-700'}
                                   `}>
                                        <BsPhone className={`hover:text-[#FC2959] text-[14px] cursor-pointer
                                        ${darkMode === false ? 'text-gray-700' : 'text-gray-300'}
                                        ${deviceOption === 3 ? 'text-red-600' : ''}`} />
                                    </button>
                                </div>
                            </div>

                            <div className="">
                                <button
                                    onClick={statsModeToggle}
                                    type="button"
                                    className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                    ${darkMode === false ? 'text-gray-800 hover:ring-gray-600' : 'text-gray-400 hover:ring-gray-300'}  shadow-sm`}
                                >
                                    <BsBarChartFill className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                    <span className="hidden lg:block">Stats</span>
                                </button>

                            </div>

                            <div className="">
                                <button
                                    onClick={handleClearClick}
                                    type="button"
                                    className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                    ${darkMode === false ? 'text-gray-800 hover:ring-gray-600' : 'text-gray-400 hover:ring-gray-300'}  shadow-sm`}
                                >
                                    <IoIosRefresh className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                    <span className="hidden lg:block">Reset</span>
                                </button>

                            </div>

                            <div className="transition-all duration-500 ease-in-out rounded-full">
                                {darkMode === false ? (
                                    <>
                                        <button
                                            onClick={() => setDarkMode(!darkMode)}
                                            type="button"
                                            className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                    ${darkMode === false ? 'text-gray-800 hover:ring-gray-600' : 'text-gray-400 hover:ring-gray-300'}  shadow-sm`}
                                        >
                                            <FaLightbulb className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                            <span className="hidden lg:block">Dark</span>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => setDarkMode(!darkMode)}
                                            type="button"
                                            className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                            ${darkMode === false ? 'text-gray-800 hover:ring-gray-600' : 'text-gray-400 hover:ring-gray-300'} shadow-sm`}
                                        >
                                            <FaRegLightbulb className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                            <span className="hidden lg:block">Light</span>
                                        </button>
                                    </>
                                )}
                            </div>

                            <div className="">
                                <button
                                    onClick={hideControls}
                                    type="button"
                                    className={`inline-flex items-center gap-x-1.5 rounded-md ring-1 ring-gray-400 px-2.5 py-1.5 text-xs font-normal 
                                    ${darkMode === false ? 'text-gray-800 hover:ring-gray-600' : 'text-gray-400 hover:ring-gray-300'}  shadow-sm`}
                                >
                                    <AiOutlineEyeInvisible className="-mr-0.5 h-3 w-3" aria-hidden="true" />
                                    <span className="hidden lg:block">Controls</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pt-10">
                        <div className='2xl:w-[1346px] mx-auto transition ease-in-out delay-150 duration-300'>
                            <div>
                                <div className='flex flex-wrap justify-center mb-12 mt-12'>
                                    {
                                        loaded === true && tItems?.length > 0
                                            ?
                                            <>
                                                {renderDeviceOptions()}
                                            </>
                                            :
                                            <div className="flex flex-col mt-24 items-center justify-center h-full">
                                                <svg className="animate-spin h-5 w-5 text-gray-500 mb-3" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.206 0 4.232-.896 5.657-2.343l2.343 2.343zM18 6.709V4.043A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z"></path>
                                                </svg>
                                                <p className="text-gray-500">Doing the business...</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="fixed bottom-0 right-0 left-0 flex flex-col justify-center items-center ">
                            <div className={`transform 
                                ${controlsVisible ? 'translate-y-0' : 'translate-y-[70px]'} 
                                transition-transform duration-500 
                                ${controlsVisible ? 'ease-in-out' : 'ease-in-out'}  
                                ${darkMode === false ? 'bg-gray-50 border-gray-200' : 'bg-[#202020] border-gray-600'} 
                                inline-flex p-2 border mb-3 shadow-md rounded-md`}>

                                {tItems.length === 1 &&
                                    <>
                                        {/* <div className="inline-flex space-x-6">
                                            <button onClick={handleShowFBModal} className={`${darkMode === false ? 'text-white bg-blue-600  hover:bg-gray-100 active:bg-gray-200 hover:text-blue-600 active:text-blue-600' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <MdCenterFocusWeak className='text-base mr-1' /><p className={`text-sm ml-1`}>Compare on Focus Board</p>
                                            </button>
                                        </div> */}

                                        <div className="inline-flex space-x-6">
                                            <button onClick={handleYTTestClick} className={`${darkMode === false ? 'text-white bg-[#f12c5a] hover:bg-gray-100 active:bg-gray-200 hover:text-[#f12c5a] active:text-[#f12c5a]' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <SlSocialYoutube className='text-base mr-1' /><p className={`text-sm ml-1`}>Test on Youtube</p>
                                            </button>
                                        </div>
                                    </>
                                }

                                {tItems.length > 1 &&
                                    <>
                                        <div className="inline-flex space-x-6 mr-2">
                                            <button onClick={handleShuffleClick} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 active:bg-gray-400 hover:text-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <FiShuffle className='text-base mr-1' /><p className={`text-sm ml-1`}>Shuffle Thumbnails & Titles</p>
                                            </button>
                                        </div>

                                        <div className="inline-flex space-x-6 mr-2">
                                            <button onClick={addNewThumbnails} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c]'} border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <IoMdAdd className='mr-[1px]' /><p className={`text-sm ml-1`}>Add More Thumbnails</p>
                                            </button>
                                        </div>

                                        <div className="inline-flex space-x-6 mr-2">
                                            <button
                                                disabled={requestStatus === 'loading'}
                                                onClick={aiEvaluationOfImages} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c]'} border border-purple-500 px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <GiProcessor className='mr-[1px]' />
                                                <p className={`text-sm ml-1`}>
                                                    {requestStatus === 'loading' ? 'Connecting...' : "Show AI's Choice"}
                                                </p>
                                            </button>
                                        </div>

                                        <div className="inline-flex space-x-6">
                                            <button onClick={askAFriend} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c]'} border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <IoPeopleOutline className='mr-[1px]' /><p className={`text-sm ml-1`}>Ask A Friend's Opinion</p>
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="fixed bottom-5 left-5">
                            <Tooltip content='Tutorials & Tips' position={'top'}>
                                <button onClick={handleShowHelpModal} className={`${darkMode === false ? 'text-gray-800 bg-[#FC2959] hover:bg-blue-600 active:bg-blue-700 hover:text-white' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                border p-3 rounded-full inline-flex justify-center items-center text-xl duration-200`}>
                                    <RiQuestionMark className='text-base text-white' />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <AIChooseBestImageST isVisible={showEvaluationModal} onClose={() => setShowEvaluationModal(false)} test_id={test_id} extensionCallback={() => setExtensionModal(true)} />

            <AddThumbnailsDialog isVisible={showAddThumbnailsModal} onClose={() => setShowAddThumbnailsModal(false)} numberOfExistingThumbs={tItems.length} testId={test_id} refresh={() => setRefreshPage(true)} />
        </>
    )
}

export default ABComparisonSuite

