import {
  Router,
  Routes,
  Route,
  Navigate,
  Link,
  useNavigate,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import { useEffect, useState } from "react";
import LogIn from "./app/screens/Old/LogIn";
import SignUp from "./app/screens/Old/SignUp";
import { useSelector } from "react-redux";
import MainRoutesWithNavbar from "./components/MainRoutesWithNavbar";
import ProtectedRoute from "./app/components/Protected";
import ABComparisonUpload from "./app/screens/Pages/ABComparisonUpload";
import ABComparisonSuite from "./app/screens/Pages/ABComparisonSuite";
import YoutubeTest from "./app/screens/Pages/YoutubeTest";
import SmartTestMain from "./app/screens/Pages/SmartTestMain";
import StandaloneUpload from "./app/screens/Pages/StandaloneUpload";
import Dashboard from "./app/screens/Pages/Dashboard";
import { AuthContextProvider } from "./context/AuthContext";
import { PremiumContextProvider } from "./context/PremiumContext";
import ErrorPage from "./app/screens/Pages/ErrorPage";
import StandaloneTYoutubeTest from "./app/screens/Pages/StandaloneTYoutubeTest";
import ReferralSuite from "./app/screens/Pages/ReferralSuite";
import Settings from "./app/screens/Pages/Settings";
// import Billing from "./app/screens/Pages/bkp Billing";
import FocusBoardDetails from "./app/screens/Pages/FocusBoardDetails";
import FocusBoardTesting from "./app/screens/Pages/FocusBoardTesting";
import FocusBoards from "./app/screens/Pages/FocusBoards";
import SubCheckOut from "./app/screens/Pages/SubCheckOut";
import Tutorials from "./app/screens/Pages/Tutorials";
import ViralLibrary from "./app/screens/Pages/ViralLibrary";
import ViralLibraryDetails from "./app/screens/Pages/ViralLibraryDetails";
import Test from "./app/screens/Pages/Test";
import ViralLibraryCategory from "./app/screens/Pages/ViralLibraryCategory";
// import TitleComposer from "./app/screens/Pages/bkpTitleCompser--";
import Clear from "./app/screens/Pages/Clear";
import SignUpDirect from "./app/screens/Old/SignUpDirect";
import LoginDirect from "./app/screens/Old/LogInDirect";
import FirstStepsConnect from "./app/screens/Pages/FirstStepsConnect";
import FirstStepsBillingPlans from "./app/screens/Pages/FirstStepsBillingPlans";
import ThumbnailPlanner from "./app/screens/Pages/bkp/bkp ThumbnailPlanner";
// import BillingPlans from "./app/screens/Pages/bkp BillingPlans";
import FirstStepsSuccess from "./app/screens/Pages/FirstStepsSuccess";
import FirstStepsExtension from "./app/screens/Pages/FirstStepsExtension";
import TransitionPage from "./app/screens/Pages/TransitionPage";
import AnalysisUpload from "./app/screens/Pages/AnalysisUpload";
// import AnalysisSuite from "./app/screens/Pages/AnalysisSuite";
import FirstStepsQA from "./app/screens/Pages/FirstStepsQA";
import { Crisp } from "crisp-sdk-web";
import InsideBilling from "./app/screens/Pages/InsideBilling";
import GeekPass from "./app/screens/Pages/GeekPass";
import Validator from "./app/screens/Auth/Validator";
import ThumbnailStrategist from "./app/screens/Pages/ThumbnailStrategist";
import TitleBuilder from "./app/screens/Pages/TitleBuilder";
import NewLogin from "./app/screens/Auth/LoginPage";
import NewSignUp from "./app/screens/Auth/SignUpPage";
import LoginPage from "./app/screens/Auth/LoginPage";
import SignUpPage from "./app/screens/Auth/SignUpPage";
import ConnectChannel from "./app/screens/Pages/ConnectChannel";
import BasicUpload from "./app/screens/Pages/BasicUpload";
import AnalysisDetails from "./app/screens/Pages/AnalysisDetails";
import DeviceMockups from "./app/screens/Pages/DeviceMockups";
import RestrictedAccount from "./app/screens/Pages/RestrictedAccount";
import InBetweenPlace from "./app/screens/Pages/InBetweenPlace";
import FirstTimeValidator from "./app/screens/Auth/FirstTimeValidator";
import Buckets from "./app/screens/Pages/Buckets";
import BucketDetails from "./app/screens/Pages/BucketDetails";
import ExtensionUninstall from "./app/screens/Pages/ExtensionUninstall";
import ExtensionInstall from "./app/screens/Pages/ExtensionInstall";
import ExtensionTips from "./app/screens/Pages/ExtensionTips";
import ChannelAudit from "./app/screens/Pages/ChannelAudit";
import InsideBillingOneTime from "./app/screens/Pages/InsideBillingOneTime";
import FeaturesFixes from "./app/screens/Pages/FeaturesFixes";
import ThumbnailStoryboards from "./app/screens/Pages/ThumbnailStoryboards";

function App() {
  useEffect(() => {
    Crisp.configure("7087fb38-ee0e-4598-bae5-725fcf11d670");
  }, []);

  return (
    <AuthContextProvider>
      <PremiumContextProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {/* <Route path="/login" element={<LoginDirect />} />
          <Route path="/signup" element={<SignUpDirect />} /> */}
          <Route path="/activate" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/extension/uninstall" element={<ExtensionUninstall />} />

          <Route
            path="/mid/:user_id"
            element={
              <ProtectedRoute>
                <InBetweenPlace />
              </ProtectedRoute>
            }
          />

          <Route path="/connect-channel" element={<ConnectChannel />} />

          <Route
            path="/first-steps/connect/:user_id"
            element={
              <ProtectedRoute>
                <FirstStepsConnect />
              </ProtectedRoute>
            }
          />

          <Route
            path="/upload-thumbnail"
            element={
              <ProtectedRoute>
                <BasicUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path="/first-steps/billing/plans/:user_id"
            element={
              <ProtectedRoute>
                <FirstStepsBillingPlans />
              </ProtectedRoute>
            }
          />

          <Route
            path="/first-steps/extension/:user_id"
            element={
              <ProtectedRoute>
                <FirstStepsExtension />
              </ProtectedRoute>
            }
          />

          <Route
            path="/first-steps/qa/:user_id"
            element={
              <ProtectedRoute>
                <FirstStepsQA />
              </ProtectedRoute>
            }
          />

          <Route
            path="/first-steps/billing/:user_id/success"
            element={
              <ProtectedRoute>
                <FirstStepsSuccess />
              </ProtectedRoute>
            }
          />

          <Route
            path="/billing/plans/"
            element={
              <ProtectedRoute>
                <InsideBilling />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <InsideBillingOneTime />
              </ProtectedRoute>
            }
          />

          <Route
            path="/restricted"
            element={
              <ProtectedRoute>
                <RestrictedAccount />
              </ProtectedRoute>
            }
          />

          <Route
            path="/geekpass"
            element={
              <ProtectedRoute>
                <GeekPass />
              </ProtectedRoute>
            }
          />

          <Route
            path="/validation"
            element={
              <ProtectedRoute>
                <Validator />
              </ProtectedRoute>
            }
          />

          <Route
            path="/first-time-validation"
            element={
              <ProtectedRoute>
                <FirstTimeValidator />
              </ProtectedRoute>
            }
          />

          {/* <Route
            path="/billing/plans/:user_id"
            element={
              <ProtectedRoute>
                <BillingPlans />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/focus-boards"
            element={
              <ProtectedRoute>
                <FocusBoards />
              </ProtectedRoute>
            }
          />

          <Route
            path="/buckets"
            element={
              <ProtectedRoute>
                <Buckets />
              </ProtectedRoute>
            }
          />

          <Route
            path="/bucket-details/:bucket_id"
            element={
              <ProtectedRoute>
                <BucketDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/thumbnail-analysis/upload"
            element={
              <ProtectedRoute>
                <AnalysisUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path="/thumbnail-analysis/:thumbnail_id"
            element={
              <ProtectedRoute>
                <AnalysisDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/device-preview/:thumbnail_id"
            element={
              <ProtectedRoute>
                <DeviceMockups />
              </ProtectedRoute>
            }
          />

          <Route
            path="/testing-suite/upload"
            element={
              <ProtectedRoute>
                <ABComparisonUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/testing-suite/compare/:test_id"
            element={
              <ProtectedRoute>
                <ABComparisonSuite />
              </ProtectedRoute>
            }
          />
          <Route
            path="/testing-suite/main-test"
            element={
              <ProtectedRoute>
                <SmartTestMain />
              </ProtectedRoute>
            }
          />

          <Route
            path="/youtube-tt-testing/upload"
            element={
              <ProtectedRoute>
                <StandaloneUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path="/youtube-tt-testing/test"
            element={
              <ProtectedRoute>
                <StandaloneTYoutubeTest />
              </ProtectedRoute>
            }
          />

          <Route
            path="/focus-board-details/:board_id"
            element={
              <ProtectedRoute>
                <FocusBoardDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/focus-board-testing/:board_id/:upload_id"
            element={
              <ProtectedRoute>
                <FocusBoardTesting />
              </ProtectedRoute>
            }
          />

          {/* <Route
            path="/title-composer"
            element={
              <ProtectedRoute>
                <TitleComposer />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/title-builder"
            element={
              <ProtectedRoute>
                <TitleBuilder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thumbnail-visual-ideas"
            element={
              <ProtectedRoute>
                <ThumbnailStoryboards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/referrals"
            element={
              <ProtectedRoute>
                <ReferralSuite />
              </ProtectedRoute>
            }
          />
          <Route
            path="/viral-library"
            element={
              <ProtectedRoute>
                <ViralLibrary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/viral-library/details/:item_id"
            element={
              <ProtectedRoute>
                <ViralLibraryDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/viral-library/category/:category_id"
            element={
              <ProtectedRoute>
                <ViralLibraryCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account-settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path="subscriptions/new/:plan_id"
            element={
              <ProtectedRoute>
                <SubCheckOut />
              </ProtectedRoute>
            }
          />

          <Route
            path="tests/youtube-test"
            element={
              <ProtectedRoute>
                <YoutubeTest />
              </ProtectedRoute>
            }
          />

          <Route
            path="tutorials"
            element={
              <ProtectedRoute>
                <Tutorials />
              </ProtectedRoute>
            }
          />

          <Route
            path="test"
            element={
              <ProtectedRoute>
                <Test />
              </ProtectedRoute>
            }
          />

          {/* <Route
            path="/thumbnail-planner"
            element={
              <ProtectedRoute>
                <ThumbnailPlanner />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/thumbnail-ideas"
            element={
              <ProtectedRoute>
                <ThumbnailStrategist />
              </ProtectedRoute>
            }
          />

          <Route
            path="/extension/install"
            element={
              <ProtectedRoute>
                <ExtensionInstall />
              </ProtectedRoute>
            }
          />

          <Route
            path="/extension/tips"
            element={
              <ProtectedRoute>
                <ExtensionTips />
              </ProtectedRoute>
            }
          />

          <Route
            path="/channel-audit/"
            element={
              <ProtectedRoute>
                <ChannelAudit />
              </ProtectedRoute>
            }
          />

          <Route
            path="/features-fixes/"
            element={
              <ProtectedRoute>
                <FeaturesFixes />
              </ProtectedRoute>
            }
          />

          {/* <Route
          path="first-entry"
          element={
            <ProtectedRoute>
              <FirstEntry />
            </ProtectedRoute>
          }
        /> */}

          <Route path="*" element={<ErrorPage />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route path="/404" element={<ErrorPage />} />

          {/* <Route path="*" element={<MainRoutesWithNavbar />} /> */}

          <Route path="/clear" element={<Clear />} />
        </Routes>
        <ToastContainer
          theme="dark"
          position="top-right"
          closeButton={false}
          transition={Slide}
          autoClose={5000}
          style={{ zIndex: 32894572 }}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName={() =>
            "rounded-lg bg-gray-800 shadow relative mb-2 ring-1 ring-gray-500 flex p-1 min-h-10 text-sm justify-between overflow-hidden cursor-pointer"
          }
        />
      </PremiumContextProvider>
    </AuthContextProvider>
  );
}

export default App;

{
  /* <UserAuthContextProvider>
<Routes>
  <Route path="/login" element={<Login />} />
  <Route path="/signup" element={<SignUp />} />

  <Route path='/initial-steps' element={
    <ProtectedRoute>
      <InitialScreen />
    </ProtectedRoute>
  } />
  <Route path='/dashboard' element={
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  } />
  <Route path='/testing-suite' element={
    <ProtectedRoute>
      <TestingSuite />
    </ProtectedRoute>
  } />

  <Route path='niche-inquiry' element={
    <ProtectedRoute>
      <NicheInquiry />
    </ProtectedRoute>
  } />

  <Route path='/youtube-testing'>
    <Route path=":videoNiche" element={
      <ProtectedRoute>
        <YoutubeTest />
      </ProtectedRoute>
    } />
    <Route path='' element={
      <ProtectedRoute>
        <TestingSuite />
      </ProtectedRoute>
    } />
  </Route>

  <Route path='title-builder' element={
    <ProtectedRoute>
      <TitleBuilder />
    </ProtectedRoute>
  } />

  {/* <Route path='/youtube-testing' element={
    <ProtectedRoute>
      <YoutubeTest />
    </ProtectedRoute>
  } /> 

  <Route path="*" element={<MainRoutesWithNavbar />} />
</Routes>

</UserAuthContextProvider> */
}
