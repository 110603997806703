import React from 'react';
import { IoMdTrendingUp, IoMdTrendingDown } from 'react-icons/io';
import { MdTrendingFlat } from 'react-icons/md';

const ViewsTrendIcon = ({ predictedViews, expectedViews }) => {
    const getTrendInfo = () => {
        const ratio = predictedViews / expectedViews;

        if (ratio > 1.2) {
            return {
                Icon: IoMdTrendingUp,
                iconColor: 'green-600',
                bgColor: 'bg-green-100',
                text: 'Increase in Views Expected'
            };
        } else if (ratio >= 0.9 && ratio <= 1.2) {
            return {
                Icon: MdTrendingFlat,
                iconColor: 'orange-500',
                bgColor: 'bg-orange-100',
                text: 'Average Views Expected'
            };
        } else {
            return {
                Icon: IoMdTrendingDown,
                iconColor: 'red-500',
                bgColor: 'bg-red-100',
                text: 'Decrease in Views Expected'
            };
        }
    };

    const { Icon, iconColor, bgColor, text } = getTrendInfo();

    return (
        <div className={`flex flex-col items-center p-4 ${bgColor} rounded-lg`}>
            <Icon className={`w-12 h-12 text-${iconColor} mb-1`} />
            <span className={`text-sm font-medium text-${iconColor}`}>{text}</span>
        </div>
    );
};

export default ViewsTrendIcon;