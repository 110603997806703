import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { BsBarChart, BsBarChartFill, BsCreditCard, BsGrid1X2 } from 'react-icons/bs'
import { IoPersonCircleOutline, } from 'react-icons/io5'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { GiHorizonRoad } from "react-icons/gi";
import SideBar from '../../components/SideBar';
import { Tab } from '@headlessui/react';
import Avatar from '../../components/Avatar';
import { db, storage } from '../../../firebase-config';
import { getFunctions, httpsCallable } from "firebase/functions";
import { where, addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy } from "firebase/firestore";
import DotDotDot from '../../components/DotDotDot';
import Tooltip from '../../components/Tooltip';

const sides = [
    { id: 0, name: '25 Credits' },
    { id: 1, name: '50 Credits' },
    { id: 2, name: '100 Credits' },
    { id: 3, name: '200 Credits' },
]

const Settings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const upgrade = params.get('upgrade');
    const refresh = params.get('refresh');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, aiQuota, aiQuotaUsed, aiCreditsLeft, accountStatus, accessLevel } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId")
    const mainEmail = localStorage.getItem("mainEmail")
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [working, setWorking] = useState(false);
    const [userDocument, setUserDocument] = useState(null);
    const [userDocumentLoaded, setUserDocumentLoaded] = useState(false);
    const [pricingData, setPricingData] = useState([]);
    const [pricingDataLoaded, setPricingDataLoaded] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [aiCreditsModal, setAICreditsModal] = useState(false);
    const [creditsAmount, setCreditsAmount] = useState("");
    const [accountCheckModal, setAccountCheckModal] = useState(false);
    const [billingOverlayModal, setBillingOverlayModal] = useState(false);
    const [creditHistory, setCreditHistory] = useState([]);

    useEffect(() => {
        if (upgrade === 'true') {
            redirectToStripe();
        }
    }, []);

    useEffect(() => {
        updateToken();
        const fetchDocument = async () => {
            try {
                const docRef = doc(db, 'Users', mainUserId);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    setUserDocument(docSnapshot.data());
                    setUserDocumentLoaded(true);

                    // let pricingPlan = (docSnapshot.data().pricing_table_item_id);
                    // console.log(pricingPlan)

                    try {

                        // const docRef1 = doc(db, 'PricingTableData', pricingPlan);
                        // const querySnapshot1 = await getDoc(docRef1);

                        if (docSnapshot.exists()) {
                            // setPricingData(querySnapshot1.data());
                            setPricingDataLoaded(true);

                            updateToken();

                            // console.log(querySnapshot1.data());
                        }
                        ////const q = query(collection(db, 'PricingTableData'),
                        ////    where('name', '==', pricingPlan));
                        ////const querySnapshot = await getDocs(q);
                        ////const data = querySnapshot1.docs.map((doc) => doc.data());
                    } catch (error) {
                        console.error('Error fetching pricing data:', error);
                    }
                } else {
                    console.log('Document not found.');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        fetchDocument();
    }, []);

    useEffect(() => {
        async function getCreditHistory() {
            const docRef = collection(db, 'Users', mainUserId, 'CreditHistory');
            const q = query(docRef, orderBy('date_created', 'desc'));
            await getDocs(q).then((querySnapshot) => {
                setCreditHistory(querySnapshot.docs.map((doc) => doc.data()));
            })
        }

        getCreditHistory();
    }, [selectedIndex]);

    function deleteCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    const updateToken = (e) => {

        console.log('Doing First load sequence');

        if (mainUserId !== undefined && mainUserId !== null && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);

                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';

                    document.cookie = `TG_AT=${response.data.token}; max-age=2505600; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2505600; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2505600; path='/'; SameSite=Lax;`;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    const redirectToStripe = async () => {
        if (premiumStatus === 'NOT_INITIATED' || accountStatus === 'INACTIVE') {
            goToBilling();
        } else {
            if (!redirecting) {
                setRedirecting(true);
                setBillingOverlayModal(true);
                const functions = getFunctions();
                const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
                addMessage({
                    returnUrl: window.location.origin + '/account-settings',
                    locale: 'auto',
                    //configuration: 'bpc_1JSEAKHYgolSBA358VNoc2Hs',
                })
                    .then((result) => {
                        const data = result.data;
                        setBillingOverlayModal(false);
                        setRedirecting(false);

                        if (data.url) {
                            window.open(data.url, '_blank');
                        }
                    })
                    .catch((error) => {
                        //Getting the Error details.
                        const code = error.code;
                        const message = error.message;
                        const details = error.details;
                        //...
                        console.error(code);
                        console.error(message);
                        console.error(details);

                        setBillingOverlayModal(false);
                        setRedirecting(false);
                        window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ', '_blank');
                    });

            }
        }
    }

    const goToBilling = async () => {
        navigate(`/billing/plans`);
    }

    const aiCreditsClose = () => {
        setAICreditsModal(false);
    }

    const handleAICreditsClose = (e) => {
        if (e.target.id === "ai-credits-modal") {
            setAICreditsModal(false);
        };
    }

    const initiateCreditsPurchase = (e) => {
        console.log(creditsAmount);
        //check if the account can purchase credits
        if (accessLevel !== 0 || accessLevel !== 1 || accessLevel !== 2) {
            aiCreditsClose();
            openAccountCheckModal();
        }
    }

    const openAccountCheckModal = () => {
        setAccountCheckModal(true);
    }

    const handleAccountCheckModalClose = (e) => {
        if (e.target.id === "account-check-modal") {
            setAccountCheckModal(false);
        };
    }

    const accountCheckModalClose = (e) => {
        setAccountCheckModal(false);
    }

    const redirectToPayment = async () => {
        navigate('/pricing');
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Settings</title>
                <meta name="description" content="Settings of Thumbnail Geek" />
            </Helmet>

            <div id="ai-credits-modal" onClick={handleAICreditsClose} className={`${aiCreditsModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Select amount of AI Credits
                            </h3>
                            <button onClick={aiCreditsClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="pt-6 px-6 space-y-6">
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                                You will be asked to confirm your purchase at the next step.<br />
                                <span className="font-semibold"> Note: AI Credits <span className="underline">require an active subscription</span> to use.</span>
                            </p>
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">

                            </p>
                            <div>
                                <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                                    {sides.map((side, id) => (
                                        <div key={id} className="relative flex items-start py-4">
                                            <div className="min-w-0 flex-1 text-sm leading-6">
                                                <label htmlFor={`side-${side.id}`} className="select-none font-medium text-gray-900">
                                                    {side.name}
                                                </label>
                                            </div>
                                            <div className="ml-3 flex h-6 items-center">
                                                <input
                                                    id={`credits-${id}`}
                                                    name="plan"
                                                    type="radio"
                                                    onChange={() => setCreditsAmount(side.id)}
                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {
                                    creditsAmount === ""
                                        ?
                                        <div className="flex items-center justify-end py-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                            <button disabled={true} className="text-gray-700 bg-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                                Select A Package
                                            </button>
                                        </div>
                                        :
                                        <div className="flex items-center justify-end py-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                            <button onClick={initiateCreditsPurchase} className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                                Continue
                                            </button>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="account-check-modal" onClick={handleAccountCheckModalClose} className={`${accountCheckModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Sorry!
                            </h3>
                            <button onClick={accountCheckModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                <div className="rounded-md bg-red-100 p-4">
                                    <div className="">
                                        <div className="">
                                            <div className=" text-sm font-medium text-red-500">
                                                <ul role="list" className="space-y-1">
                                                    <li>Only Basic, Pro or Max Accounts can purchase AI credits</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={accountCheckModalClose} className="text-white bg-purple-600 hover:bg-purple-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Upgrade</button>
                            <button onClick={accountCheckModalClose} className="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="extension-modal" className={`${billingOverlayModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full flex flex-col items-center justify-center max-w-lg md:h-auto">
                    <span>Loading billing info...</span>
                </div>
            </div>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="settings" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold`}>Settings</h5>
                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mt-[27px] lg:mt-[31px] bg-gray-50">
                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                <Tab.List className='w-full border-gray-200 bg-gray-50 text-gray-800 border-b text-sm fixed z-10 r-0 mx-auto max-w-4xl sm:pr-6 lg:pr-8 pt-2'>

                                    <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className={`inline-flex p-3`}>
                                            <IoPersonCircleOutline className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Account Settings</p>
                                        </span>
                                    </Tab>

                                    <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className="inline-flex p-3">
                                            <BsCreditCard className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Billing</p>
                                        </span>
                                    </Tab>

                                    <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className="hidden lg:inline-flex p-3">
                                            <BsBarChart className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Credit History</p>
                                        </span>
                                    </Tab>

                                </Tab.List>
                                <Tab.Panels className='w-full'>
                                    {/* //Account */}
                                    <Tab.Panel className='w-full pl-7'>

                                        <div className="px-7 py-20">
                                            <>
                                                <div className="px-4 sm:px-0">
                                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h3>
                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Your main account info.</p>
                                                </div>
                                                <div className="mt-6 border-t border-gray-100">
                                                    <dl className="divide-y divide-gray-100">
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Youtube channel name</dt>
                                                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <span className="flex-grow">{channelDisplayName}</span>
                                                            </dd>
                                                        </div>
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Email Address</dt>
                                                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <span className="flex-grow">{mainEmail}</span>
                                                            </dd>
                                                        </div>
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Joined since</dt>
                                                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    userDocumentLoaded === true
                                                                        ?
                                                                        <span className="flex-grow">{userDocument.date_created.toDate().toLocaleString('en-US', { month: 'long', year: 'numeric' })}</span>
                                                                        :
                                                                        <DotDotDot />
                                                                }
                                                            </dd>
                                                        </div>


                                                    </dl>
                                                </div>
                                            </>



                                            {/* <>
                                                <div className="px-4 mt-16 sm:px-0">
                                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Danger Zone</h3>
                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">These actions cannot be reversed. Your data, insights and recommendations will be lost</p>
                                                </div>
                                                <div className="mt-6 border-t border-gray-100">
                                                    <dl className="divide-y divide-gray-100">
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Delete Account</dt>
                                                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <span className="flex-grow">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md py-1 text-xs font-normaal text-red-300 "
                                                                    >
                                                                        Delete my account
                                                                    </button>
                                                                </span>
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </> */}
                                        </div>

                                    </Tab.Panel>

                                    {/* //Billing */}
                                    <Tab.Panel className='w-full pl-7'>
                                        <div className="px-7 pt-24 w-full">
                                            <>
                                                <div className="px-4 sm:px-0">
                                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Billing Settings</h3>
                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details of your billing information.</p>
                                                </div>
                                                <div className="mt-6 pb-9 border-t border-gray-100">
                                                    <dl className="divide-y divide-gray-100">
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Current Billing Package</dt>
                                                            <dd className="mt-1 flex-grow text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <span className="flex-grow">
                                                                    {
                                                                        userDocumentLoaded === true
                                                                            ?
                                                                            <span className={`flex-grow`}>{userDocument?.premium_tier}</span>
                                                                            :
                                                                            <DotDotDot />
                                                                    }
                                                                </span>
                                                                <span className="ml-12 flex-shrink-0">
                                                                    <button onClick={redirectToPayment} type="button" className=" font-medium rounded-md bg-green-600 px-3 py-1.5 text-xs text-white hover:bg-green-700">
                                                                        Upgrade to higher pack
                                                                    </button>
                                                                </span>
                                                            </dd>
                                                        </div>
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Type of Billing</dt>
                                                            <dd className="mt-1 flex text-sm leading-6  font-semibold sm:col-span-2 sm:mt-0">
                                                                <div className={`text-gray-700 flex-grow`}>
                                                                    One-Time
                                                                </div>
                                                            </dd>
                                                        </div>
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                                            <dd className="mt-1 flex text-sm leading-6  font-semibold sm:col-span-2 sm:mt-0">
                                                                <div className={` ${userDocument?.premium_status == 'PREMIUM' ? 'text-green-600' : 'text-orange-500'} flex-grow font-semibold`}>
                                                                    <span className="flex-grow">{userDocument?.premium_status === 'TRIALING' ? 'TRIAL' : userDocument?.premium_status}</span>

                                                                    {/* {
                                                                        premiumStatus !== 'PREMIUM' && premiumStatus !== 'TRIALING'
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    premiumStatus === 'PAST_DUE' || premiumStatus === 'INCOMPLETE'
                                                                                        ?
                                                                                        <button onClick={redirectToStripe} type="button" className="ml-7 font-medium rounded-md bg-gray-600 px-3 py-1 text-xs text-white hover:bg-gray-700">
                                                                                            {redirecting ? <DotDotDot /> : 'Make Payment'}
                                                                                        </button>
                                                                                        :
                                                                                        //incomplete_expired canceled unpaid
                                                                                        <button onClick={goToBilling} type="button" className="ml-7 font-medium rounded-md bg-gray-600 px-3 py-1 text-xs text-white hover:bg-gray-700">
                                                                                            Subscribe
                                                                                        </button>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    } */}
                                                                </div>
                                                            </dd>
                                                        </div>
                                                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">Processing Credits </dt>
                                                            <dd className="mt-1 flex-grow text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                <span className="flex-grow">
                                                                    {aiQuota - aiQuotaUsed} {!userDocument?.premium_status === 'TRIALING' && `${aiQuota}`} {userDocument?.premium_status === 'TRIALING' ? 'trial credits left' : 'credits left'}
                                                                </span>
                                                                <span className="ml-12 flex-shrink-0">
                                                                    <button onClick={redirectToPayment} type="button" className=" font-medium rounded-md bg-blue-500 px-3 py-1.5 text-xs text-white hover:bg-blue-600">
                                                                        Recharge Your Account
                                                                    </button>
                                                                </span>
                                                            </dd>
                                                        </div>

                                                    </dl>
                                                </div>
                                            </>
                                        </div>
                                    </Tab.Panel>


                                    <Tab.Panel className='w-full pl-7 pt-20'>
                                        {/* Credit History Table */}
                                        <div className="p-4 pt-0 text-gray-900">

                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-base font-semibold leading-7 text-gray-900">Topup History</h3>
                                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Showing credit top ups within the past 12 months</p>
                                            </div>
                                            <div className="mt-6 pb-9 border-t border-gray-100">
                                                <table className="w-full border-collapse">
                                                    <thead>
                                                        <tr className="text-left text-gray-600 text-sm">
                                                            <th className="p-3 border-b border-gray-200">STATUS</th>
                                                            <th className="p-3 border-b border-gray-200">AMOUNT</th>
                                                            <th className="p-3 border-b border-gray-200">CREATED</th>
                                                            <th className="p-3 border-b border-gray-200"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {creditHistory && creditHistory.map((credit, index) => (
                                                            <tr key={index} className="border-b border-gray-100 hover:bg-gray-50 text-sm">
                                                                <td className="p-3">
                                                                    <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full font-medium">
                                                                        Added
                                                                    </span>
                                                                </td>
                                                                <td className="p-3">
                                                                    <span className="px-2 py-1  text-gray-800 rounded-full text-sm font-medium">
                                                                        {credit.new_credits} credits
                                                                    </span>
                                                                </td>
                                                                <td className="p-3">
                                                                    {new Date(credit.date_created.seconds * 1000).toLocaleString('en-US', {
                                                                        year: 'numeric',
                                                                        month: 'short',
                                                                        day: 'numeric',
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        hour12: true
                                                                    })}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Settings