import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import SideBar from '../../components/SideBar'
import Cookies from 'js-cookie'
import ReactPlayer from 'react-player/youtube';
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { TfiTrash } from "react-icons/tfi";
import { IoAddSharp } from "react-icons/io5";
import { PremiumCredentials } from '../../../context/PremiumContext';
import { UserAuth } from '../../../context/AuthContext'
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import logo from '../../../assets/logo_full_32.png';
import BucketCollageImages from '../../components/BucketCollageImages';
import Avatar from '../../components/Avatar';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import Tooltip from '../../components/Tooltip';
import Hint from '../../components/Hint';
import DotDotDot from '../../components/DotDotDot';
import { FaCrown } from 'react-icons/fa';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import TutorialItems from '../../components/TutorialItems';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import BucketLimit from '../../components/BucketLimit';

const Buckets = () => {
    let navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const mainUserId = localStorage.getItem("mainUserId");
    const { accessLevel, aiCreditsLeft, premiumStatus, bucketCount } = PremiumCredentials();
    const [buckets, setBuckets] = useState([]);
    const [demoBoards, setDemoBoards] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [demoLoaded, setDemoLoaded] = useState(false);
    const [cloningDemo, setCloningDemo] = useState(false);
    const [extensionModal, setExtensionModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [channelModal, setChannelModal] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [pageSelectModal, setPageSelectModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemToDelete, setItemToDelete] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const [cookiesEnabled, setCookiesEnabled] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [helpModal, setHelpModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const { pathname } = useLocation();
    const [blockPopup, setBlockPopup] = useState(false)
    const [updatedToken, setUpdatedToken] = useState(false)
    // const [bucketLimit, setBucketLimit] = useState(true)
    const [bucketLimitPopup, setBucketLimitPopup] = useState(false)
    const [bucketLimitNumber, setBucketLimitNumber] = useState(0)
    const [bucketNumber, setBucketNumber] = useState(0)

    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)

    useEffect(() => {
        const updateToken = (e) => {
            if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
                const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
                const requestBody = {
                    loginStatus: true,
                };
                axios.post(apiUrl, requestBody)
                    .then(response => {
                        // //console.log('Token ' + response.data.token);
                        //Remove previous cookie if it exists
                        document.cookie = 'TG_AT=; max-age=0; path=/';
                        document.cookie = 'TG_Avatar=; max-age=0; path=/';
                        document.cookie = 'TG_ChannelName=; max-age=0; path=/';
                        document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                        const dateSet = new Date();
                        localStorage.setItem('login_cookie_setDate', dateSet);

                        setUpdatedToken(true);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

                document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;
            }
        }
        updateToken();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // useEffect(() => {
    //     //console.log('bucket running');
    //     //console.log('Access Level:', accessLevel);
    //     //console.log('Bucket Count:', bucketCount);

    //     if (accessLevel === 0 && bucketCount === 5) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(5);
    //     } else if (accessLevel === 1 && bucketCount === 30) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(30);
    //     } else if (accessLevel === 2 && bucketCount === 30) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(30);
    //     } else if (accessLevel === 3 && bucketCount === 30) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(30);
    //     } else if (accessLevel === 5 && bucketCount === 5) {
    //         //console.log("Condition met for access level 5 and bucket count 5");
    //         setBucketLimit(true);
    //         setBucketLimitNumber(5);
    //     } else if (accessLevel === 6 && bucketCount === 70) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(70);
    //     } else if (accessLevel === 7 && bucketCount === 70) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(70);
    //     } else if (accessLevel === 8 && bucketCount === 70) {
    //         setBucketLimit(true);
    //         setBucketLimitNumber(70);
    //     } else {
    //         setBucketLimit(false);
    //     }
    // }, [accessLevel, bucketCount]); // Ensure both dependencies are included

    const getBuckets = async () => {
        const colRef = collection(db, 'Users', mainUserId, 'Buckets');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setBuckets(newData);
                setLoaded(true);
                ////console.log(boards, newData);
            })
    }

    const deleteBucket = async (board_id) => {
        setDeleting(true);
        const docRef = doc(db, 'Users', mainUserId, 'Buckets', board_id);
        await deleteDoc(docRef).then(() => {
            deleteModalClose();
            setBuckets((state) => state.filter((item) => item.id !== board_id))
            setDeleting(false);
            toast("Bucket Deleted", {
                icon: ({ theme, type }) => <TfiTrash className='text-white h-7 w-7' />
            });
        });
    }

    useEffect(() => {
        getBuckets();
    }, []);

    const goToBucketDetails = (id) => {
        let path = `/bucket-details/${id}`;
        navigate(path);
    }

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const pageSelectModalClose = (e) => {
        setPageSelectModal(false);
    }

    const handleSearchModalClose = (e) => {
        if (e.target.id === "search-page-modal") {
            setSearchModal(false);
        };
    }

    const handleChannelModalClose = (e) => {
        if (e.target.id === "channel-page-modal") {
            setChannelModal(false);
        };
    }

    const handlePageSelectModalClose = (e) => {
        if (e.target.id === "page-select-modal") {
            setPageSelectModal(false);
        };
    }

    const channelModalClose = (e) => {
        setChannelModal(false);
    }

    const searchModalClose = (e) => {
        setSearchModal(false);
    }

    const openSelectModal = async () => {
        const userRef = doc(db, 'Users', mainUserId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            const isAccessLevelRecord = data.hasOwnProperty('access_level');

            if (isAccessLevelRecord) {

                let accessLevel = data.access_level;
                let bucketCount = buckets.length;

                //console.log(accessLevel) // this is 7
                //console.log(bucketCount) // this is 6

                if (
                    (bucketCount <= 5 && (accessLevel === 0 || accessLevel === 5)) ||
                    (bucketCount <= 30 &&
                        (accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 4)) ||
                    (bucketCount <= 70 &&
                        (accessLevel === 6 || accessLevel === 7 || accessLevel === 8))
                ) {

                    if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 4 ||
                        accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
                        if (aiCreditsLeft > 4) {
                            if (document.cookie.indexOf('TG_CEF') !== -1) {
                                setPageSelectModal(true);
                            } else {
                                setExtensionModal(true);
                            }
                        } else {
                            if (premiumStatus === 'TRIALING') {
                                setCreditBlockPopupTrial(true);
                            } else {
                                setCreditBlockPopup(true);
                            }

                            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                                position: "top-right",
                                autoClose: 7000,
                            });

                        }
                    } else {
                        setCreditBlockPopup(true);
                    }

                } else {
                    setBucketLimitPopup(true);
                }
            }
        }



    }

    const goToYoutubeHomepage = () => {
        const url = "https://www.youtube.com";
        var checkChromeExtension = Cookies.get('TG_CEF');

        if (checkChromeExtension === 'TRUE') {

            //Set Page Intent
            const userDoc = doc(db, "Users", mainUserId)
            const data = {
                page_intent: "OPENFB"
            }
            setDoc(userDoc, data, { merge: true }).then(res => {
                document.cookie = "tg_tt_current_page=OPENFB; path=/;";
                pageSelectModalClose();
                window.location.href = url;
                //window.open(url);
            }).catch((e) => {
                toast("Unable to verify connection.", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });
            });


        } else {
            setExtensionModal(true);
        }
    }

    const goToChannelPage = () => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            pageSelectModalClose();

            if (document.cookie.indexOf('TG_CEF') !== -1) {
                setCookiesEnabled(true);
                var checkChromeExtension = Cookies.get('TG_CEF');

                if (checkChromeExtension === 'TRUE') {
                    setChannelModal(true);
                } else {
                    setExtensionModal(true);
                }
            } else {
                toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });
            }
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const openSearchModal = () => {
        pageSelectModalClose();

        if (document.cookie.indexOf('TG_CEF') !== -1) {
            //Cookies are enabled
            setCookiesEnabled(true);

            var checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                setSearchModal(true);
            } else {
                setExtensionModal(true);
            }

        } else {
            //Cookies are disabled
            //alert('Cookies are disabled in your browser. Please enable cookies to use this feature.');
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const showHelpModal = (id) => {
        setHelpModal(true);
    }

    const handleHelpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setHelpModal(false);
        }
    }

    const handleSearchQuerySubmit = async (e) => {
        e.preventDefault();
        const url = `https://www.youtube.com/results?search_query=${searchQuery}`;

        //Set Page Intent
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
            page_intent: "OPENFB"
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            document.cookie = "tg_tt_current_page=OPENFB; path=/;";
            window.location.href = url;
            //window.open(url);
            searchModalClose();
        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const handleChannelSubmit = async (e) => {
        e.preventDefault();
        const url = `https://www.youtube.com/@${channelName}/videos`;

        //Set Page Intent
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
            page_intent: "OPENFB"
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            document.cookie = "tg_tt_current_page=OPENFB; path=/;";
            window.location.href = url;
            //window.open(url);
            searchModalClose();
        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const openDeleteModal = (name, id) => {
        setItemToDelete(name);
        setIdToDelete(id)
        setDeleteModal(true);
    }

    const handleDeleteModalClose = (e) => {
        if (e.target.id === "fb-item-delete-modal") {
            setDeleteModal(false);
        };
    }

    const deleteModalClose = (e) => {
        setDeleteModal(false);
    }

    const installExtension = (url) => {
        const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
        const popupWindow = window.open(url, '_blank', windowFeatures);
        //Check if the popup was blocked
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            //console.log('Popup was blocked or unable to open.');
            window.open(url, '_blank');
        } else {
            //console.log('Popup opened successfully.');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Buckets | ThumbnailGeek</title>
                <meta name="description" content="Buckets | ThumbnailGeek" />
            </Helmet>

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/'} />

            <TutorialItems isOpen={isModalOpen} onClose={() => setModalOpen(false)} initialOpenItemId={8} />

            <BucketLimit isOpen={bucketLimitPopup} onClose={() => setBucketLimitPopup(false)} />

            <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-[100] 
            bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg max-w-6xl border border-gray-400 shadow-md">
                    <div className="mx-auto mt-2">
                        <h3 className="text-base text-center font-semibold leading-6 text-gray-900">Here's how it works</h3>
                    </div>
                    <div className="mt-2">
                        <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                className="rounded-lg overflow-hidden"
                                width="853px"
                                height="505px"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or the extension has been disabled in your browser.
                            </p>
                            <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />
                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={() => installExtension('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Install Extension
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="page-select-modal" onClick={handlePageSelectModalClose} className={`${pageSelectModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Import thumbnails from...
                            </h3>
                            <button onClick={pageSelectModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>


                        <div className="p-6 space-y-4 flex flex-col justify-center items-center">
                            <button onClick={() => { openSearchModal() }} className="bg-blue-600 text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-blue-700 active:bg-blue-800 ">
                                {/* {accessLevel === 0 && accessLevel === 1 && accessLevel === 2 && accessLevel === 5 && <FaCrown className='h-4 w-4 text-yellow-400 mr-2 pt-0.5' />} */}
                                Youtube Search Page</button>
                            <button onClick={() => { goToYoutubeHomepage() }} className="bg-[#f12c5a] text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-[#dd204c] active:bg-[#c81841] ">
                                {/* {accessLevel === 0 && accessLevel === 1 && accessLevel === 2 && accessLevel === 5 && <FaCrown className='h-4 w-4 text-yellow-400 mr-2 pt-0.5' />} */}
                                Youtube Homepage</button>
                            <button onClick={() => { goToChannelPage() }} className="inline-flex bg-purple-500 text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-purple-600 active:bg-purple-800 ">
                                {/* {accessLevel === 0 && accessLevel === 1 && accessLevel === 2 && accessLevel === 5 && <FaCrown className='h-4 w-4 text-yellow-400 mr-2 pt-0.5' />} */}
                                A Youtuber's Channel</button>
                        </div>
                        <div className="mt-1 pb-3 flex-col justify-center items-center text-center">
                            <span onClick={() => setModalOpen(true)} className="text-blue-500 hover:cursor-pointer text-center text-[12px]"> How does it work?</span>
                        </div>

                    </div>
                </div>
            </div>

            <div id="channel-page-modal" onClick={handleChannelModalClose} className={`${channelModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 pt-3 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base font-semibold text-gray-900 dark:text-white">
                                Youtube Channel Username
                            </h3>
                            <button onClick={channelModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className='px-4 mt-5'>
                            <form onSubmit={handleChannelSubmit}>

                                <div className="mt-1 flex rounded-md shadow-sm mx-auto">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500 sm:text-sm">
                                        https://youtube.com/@
                                    </span>
                                    <input
                                        type="text"
                                        name="channelName"
                                        id="channelName"
                                        required={true}
                                        onChange={(e) => setChannelName(e.target.value)}
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        placeholder="ChannelName"
                                    />
                                </div>

                                <div className="flex items-center justify-end space-x-2 py-4 border-gray-200 rounded-b dark:border-gray-600">
                                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Start collecting thumbnails</button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div id="search-page-modal" onClick={handleSearchModalClose} className={`${searchModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base font-semibold text-gray-900 dark:text-white">
                                Youtube niche or search term
                            </h3>
                            <button onClick={searchModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form onSubmit={handleSearchQuerySubmit}>
                            <div className="p-4 pb-0 space-y-5">
                                <div>
                                    <input onChange={(e) => setSearchQuery(e.target.value)} type="text" id="search_query" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="eg. Food" required />
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-4 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Start collecting thumbnails</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="fb-item-delete-modal" onClick={handleDeleteModalClose} className={`${deleteModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Are you sure you want to delete?
                            </h3>
                            <button onClick={deleteModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                {itemToDelete}
                            </div>
                        </div>
                        {/* <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                            <button onClick={() => deleteBucket(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                        </div> */}
                        {
                            deleting === false
                                ?
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                    <button onClick={() => deleteBucket(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                                </div>
                                :
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="text-white bg-gray-800 inline-flex space-x-1 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        <span>Deleting</span>
                                        <DotDotDot className="pl-2" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/buckets'} />

            <div className='bg-gray-50' >

                <SideBar selectedSideBarItem="buckets" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-20 right-0 top-0 inline-flex items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold lg:block`}>Buckets - Collect interesting thumbnails</h5>
                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="mt-20 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8">
                            <div className="w-full h-full mx-auto">
                                <div className="flex flex-col items-center">
                                    <h2 className='font-bold text-3xl mb-7 text-gray-600'>" Its like Pinterest, but for collecting thumbnails that inspire you "</h2>
                                    <Tooltip content='Click to add thumbnails from Youtube' position='bottom'>

                                        <div className="w-72 h-32 mr-2">
                                            <div className="text-center mb-2 p-0 text-sm text-gray-700">Create Bucket</div>
                                            <div onClick={() => { openSelectModal() }}
                                                className="flex flex-wrap items-center justify-center p-3 border border-blue-300 rounded-full 
                                                w-72 h-16 bg-white hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer">
                                                <IoAddSharp className='w-8 h-8 m-0 p-0' />
                                            </div>
                                        </div>
                                    </Tooltip>

                                </div>
                                <div className="">
                                    <div className=" lg:border-b pb-2 text-neutral-500">
                                        Your Thumbnail Buckets
                                    </div>
                                    <div className="pt-5">
                                        {buckets.length === 0
                                            ?
                                            <>
                                                {
                                                    loaded === true
                                                        ?
                                                        <div className='pb-2 pt-12 text-neutral-500 flex items-center justify-center'>
                                                            <div className="text-sm">No Bucket created. Hit + to Create New.</div>
                                                        </div>
                                                        :
                                                        <div className="w-full flex flex-col items-center justify-center text-gray-400 py-8 mt-7">
                                                            <div className="flex flex-col items-center justify-center mb-3">
                                                                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                                    <path
                                                                        className="fill-gray-300"
                                                                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                                    <path
                                                                        className="fill-gray-500"
                                                                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                                </svg>
                                                            </div>
                                                            <p className="text-xs">Loading buckets...</p>
                                                        </div>
                                                    // <div className="flex flex-wrap gap-x-4">
                                                    //     <div className="w-52 h-52">
                                                    //         <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                    //         <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                    //     </div>
                                                    //     <div className="w-52 h-52">
                                                    //         <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                    //         <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                    //     </div>
                                                    //     <div className="w-52 h-52">
                                                    //         <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                    //         <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                    //     </div>
                                                    //     <div className="w-52 h-52">
                                                    //         <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                    //         <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                    //     </div>
                                                    //     <div className="w-52 h-52">
                                                    //         <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                    //         <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                    //     </div>
                                                    // </div>
                                                }
                                            </>
                                            :
                                            <div className="flex flex-wrap gap-x-4 gap-y-1">
                                                {buckets?.map((doc, i) => (
                                                    typeof doc.items !== 'undefined' && doc.items.length > 0 ? (
                                                        <div key={doc.id} className="">
                                                            <div className="w-52 h-auto relative mb-5">
                                                                <div onClick={() => { goToBucketDetails(doc.id) }} className="border border-gray-300 rounded-md w-52 h-32 bg-gray-200 cursor-pointer overflow-hidden group">
                                                                    {/* <div className="m-3 w-7 h-7 text-center rounded-full bg-white border-gray-300 border-2 absolute">{doc.items.length}</div> */}
                                                                    <BucketCollageImages itemsData={doc.items} />
                                                                    <div className="rounded-md absolute top-0 left-0 w-52 h-32 bg-black opacity-0 group-hover:opacity-50 group-active:opacity-80 duration-200">
                                                                    </div>
                                                                </div>
                                                                <div className="w-full inline-flex">
                                                                    <div onClick={() => { goToBucketDetails(doc.id) }} className="w-10/12 group">
                                                                        <div className="m-0 p-0 mt-2 pl-1 w-full cursor-pointer line-clamp-1 leading-tight lg:text-[14px] md:text-md font-medium">
                                                                            {doc.name}
                                                                        </div>
                                                                        <div className="m-0 p-0 pl-1 mt-0 inline-block text-gray-600 text-center text-[11px] text-grey-300">
                                                                            {`${doc.items.length} thumbnails`}
                                                                        </div>
                                                                    </div>
                                                                    <div onClick={() => { openDeleteModal(doc.name, doc.id) }} className="w-2/12 m-1 mr-0 p-2 hover:bg-neutral-300 rounded-lg h-8 hover:cursor-pointer">
                                                                        <TfiTrash className='ml-[1px]' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Buckets