import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition, Disclosure, Listbox } from '@headlessui/react'
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { FaChrome } from 'react-icons/fa';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db } from '../../../firebase-config';
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import logoImage from '../../../assets/logo192.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import DotDotDot from '../../components/DotDotDot';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import AvatarNoPremium from '../../components/AvatarNoPremium';
import tester from '../../../assets/thumbnailgeek_tester_1.png';

const mailingLists = [
    { id: 1, title: 'Newsletter', description: 'Last message sent an hour ago', users: '621 users' },
    { id: 2, title: 'Existing Customers', description: 'Last message sent 2 weeks ago', users: '1200 users' },
    { id: 3, title: 'Trial Users', description: 'Last message sent 4 days ago', users: '2740 users' },
]

const goals = [
    { id: 1, name: 'Make better thumbnails' },
    { id: 2, name: 'Compare, test my thumbnails before I upload' },
    { id: 3, name: 'Analyse my thumbnails before I upload' },
    { id: 4, name: 'Some other method' },
]

const qualities = [
    { id: 1, name: "I just don't get enough views. I don't know why" },
    { id: 2, name: "I have run out of catchy video ideas" },
    { id: 3, name: "I don't know how to improve my thumbnails" },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const RestrictedAccount = () => {
    let { user_id } = useParams();
    const { user, token, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    const { premiumStatus, premiumTier, premiumExpiry, subscriptionUpdated, accountStatus, channelLinked, accessLevel } = PremiumCredentials();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const navigate = useNavigate()



    const goToPricing = async (event) => {
        navigate(`/billing/plans/`);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Account Restricted | ThumbnailGeek</title>
                <meta name="description" content="First Steps - QA | ThumbnailGeek" />
            </Helmet>

            <div className="flex flex-col h-screen">
                <div className="px-6 pt-4">
                    {/* Start Nav */}
                    <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4'>
                        <div className="flex">
                            <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                                <img src={require('../../../assets/svgs/tg_logo_full.svg').default} />
                            </a>
                        </div>
                        <AvatarNoPremium />
                    </div>
                    {/* End Nav */}
                </div>

                <div className="flex flex-1 px-10 h-full">
                    <div className="w-full flex flex-1 flex-col justify-left lg:flex-none h-full">
                        <div className="w-full lg:w-full h-full">

                            <div className="flex flex-col h-full items-center justify-center">
                                <div className="sm:mx-auto sm:w-full sm:max-w-[480px] -mt-32">

                                    <div className="pr-4">
                                        <h2 className=" text-center mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                            Account Restricted
                                        </h2>
                                    </div>

                                    <div className="mt-9 pr-9">

                                        <p className='text-center mt-4 text-gray-700'>
                                            Your account is currently <span className='text-orange-600 font-bold'>RESTRICTED</span>. You will not be able to access ThumbnailGeek's features.
                                        </p>
                                        <p className='text-center mt-4 text-gray-700'>
                                            Contact support for more information.
                                        </p>

                                        <div className="block mt-10 max-w-lg">
                                            <a href="mailto:hello@thumbnailgeek.com"
                                                className="flex w-full justify-center rounded-md underline px-3 py-2.5 text-sm font-semibold 
                                                leading-6 text-blue-500"
                                            >
                                                Find out why
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="relative hidden w-0 flex-1 lg:block h-full">
                                            <div className="w-full lg:w-full h-full bg-red-100">
                                                <div className="mx-auto max-w-2xl flex flex-col h-full items-center justify-center">
                                                    <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">More views equals More revenue.</h2>
                                                    <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
                                                        No more guessing. Test your thumbnail on Youtube Homepage<br />before you upload.
                                                    </p>
                                                    <img
                                                        className=" w-2/3 object-contain mb-4 mt-5 rounded-md"
                                                        src={tester}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                </div>
            </div>

            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                        <Link
                            href="/privacy"
                            className="text-gray-400 text-xs hover:text-gray-500"
                        >
                            <span className="sr-only">Privacy Policy</span>
                            <span>Privacy Policy</span>
                        </Link>
                    </div>
                    <div className="mt-8 md:order-1 md:mt-0">
                        <p className="text-center text-xs leading-5 text-gray-500">
                            &copy; {new Date().getFullYear()} ThumbnailGeek. All rights
                            reserved.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RestrictedAccount