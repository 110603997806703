import React, { useEffect, useState, Fragment } from 'react'
import { useLocation, NavLink, useNavigate, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import Avatar from '../../components/Avatar'
import axios from 'axios';
import { Tab, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { TbBell, TbCrown } from 'react-icons/tb'
import { FaAngleDown, FaChalkboardTeacher, FaTextHeight } from 'react-icons/fa'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { ArrowDownIcon, BellIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { db, storage } from '../../../firebase-config';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject, getStorage } from "@firebase/storage";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy, onSnapshot, where, limit } from "firebase/firestore";
import OnboardingPopup from '../../components/OnboardingPopup';
import PremiumAssetPopup from '../../components/PremiumAssetPopup';
import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { IoSettingsOutline, IoSettingsSharp, IoText, IoTextSharp } from 'react-icons/io5';
import { FcCopyright, FcDown, FcPicture, FcSettings } from 'react-icons/fc';
import { IoIosArrowDown } from 'react-icons/io';
import { GoBell } from 'react-icons/go';
import { BiBell, BiBroadcast } from 'react-icons/bi';
import Tooltip from '../../components/Tooltip';
import { HiRefresh } from 'react-icons/hi';
import DotDotDot from '../../components/DotDotDot';
import { RxDot } from 'react-icons/rx';
import { GiProcessor, GiRadioTower, GiTvTower } from 'react-icons/gi';
import { SlPicture } from 'react-icons/sl';
import { RiText } from 'react-icons/ri';
import { MdOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';
import { AiFillFire } from 'react-icons/ai';
import BlockPopupRestricted from '../../components/BlockPopupRestricted';
import BlockPopupNotEnough from '../../components/BlockPopupNotEnough';
import { FiAlertTriangle } from 'react-icons/fi';
import YouTubeThumbnail from '../../components/YoutubeThumbnail';
import FeedbackBanner from '../../components/FeedbackBanner';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ChannelAudit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, accountStatus, aiQuota, aiCreditsLeft, accessLevel, channelLinked, checked, latestTriggerFulfilled } = PremiumCredentials();
    const chId = localStorage.getItem("channelId");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [videos, setVideos] = useState([]);
    const [thumbnailData, setThumbnailData] = useState([]);
    const [allThumbnailData, setAllThumbnailData] = useState(null);
    const [viewTotal, setViewTotal] = useState(0);
    const [viewAverage, setViewAverage] = useState(0);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [loadingViewAverage, setLoadingViewAverage] = useState(false);
    const [limit, setLimit] = useState(16);
    const [currentItemDetails, setCurrentItemDetails] = useState(null);
    const [loadingState, setLoadingState] = useState('Viewing your thumbnails...');
    const [openDetails, setOpenDetails] = useState(false);
    const [fullDatasetStats, setFullDatasetStats] = useState(null);
    const [currentStats, setCurrentStats] = useState({
        totalViews: 0,
        averageViews: 0,
        medianViews: 0,
        maxViews: 0,
        minViews: 0,
        standardDeviation: 0,
        weightedRatio: 0,
    });
    const [explainerOpen, setExplainerOpen] = useState(false)
    const [currentExplainerTopic, setCurrentExplainerTopic] = useState('')
    const [currentExplainerDescription, setCurrentExplainerDescription] = useState('')
    const [currentExplainerActionableDescription, setCurrentExplainerActionableDescription] = useState('')
    const [percentageChange, setPercentageChange] = useState({
        medianChange: 0,
        standardDeviationChange: 0,
        viewRatioChange: 0,
    });
    const [triggerCheck, setTriggerCheck] = useState(false)
    const [blockPopup, setBlockPopup] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [triggerError, setTriggerError] = useState(false)
    const [numberOfCreditsNeeded, setNumberOfCreditsNeeded] = useState(120)
    const [lastUpdated, setLastUpdated] = useState('')
    const [lastUpdatedRaw, setLastUpdatedRaw] = useState(0)
    const [currentThumbnail, setCurrentThumbnail] = useState('')

    useEffect(() => {
        fetchFullDatasetStats();
    }, []);

    useEffect(() => {
        if (checked) {
            const checkLatestTrigger = async () => {
                setLoading(true);
                try {
                    const docRef = doc(db, 'Users', mainUserId);
                    const docSnapshot = await getDoc(docRef);

                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data();
                        const today = new Date().toDateString();
                        const latestTriggerExists = data.hasOwnProperty('latest_trigger');
                        const latestTriggerDateExists = data.hasOwnProperty('latest_trigger_date');
                        const latestTriggerDateError = data.hasOwnProperty('latest_trigger_error');
                        const ytUsername = data.hasOwnProperty('yt_username');
                        const latestTriggerDate = latestTriggerDateExists ? data.latest_trigger_date.toDate().toDateString() : null;
                        const latestTriggerError = data.latest_trigger_error ?? false;

                        // console.log(latestTriggerDate);

                        if (latestTriggerDateExists) {
                            setLastUpdated(timeAgo(data.latest_trigger_date));
                        }

                        if (latestTriggerExists && latestTriggerDateExists) {
                            if ((data.latest_trigger === 'get_thumbnails' ||
                                data.latest_trigger === 'get_thumbnails_30' ||
                                data.latest_trigger === 'get_thumbnails_first_use' ||
                                data.latest_trigger === 'get_thumbnails_free') &&
                                latestTriggerDate === today) {
                                fetchThumbnails(limit);
                            } else if (latestTriggerDate !== null && Date.parse(today) - Date.parse(latestTriggerDate) >= 1 * 24 * 60 * 60 * 1000) {
                                triggerThumbnails("get_thumbnails");
                            } else {
                                fetchThumbnails(limit);
                            }
                        } else {
                            triggerThumbnails("get_thumbnails");
                        }
                    } else {
                        console.log('Document does not exist');
                        // logout
                    }
                } catch (error) {
                    console.error('Error checking latest_trigger: ', error);
                }
            };

            checkLatestTrigger();
        }
    }, [mainUserId, checked]);

    // const getChannelUsername = async () => {
    //     try {
    //         const apiKey = 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s';
    //         const response = await axios.get(
    //             `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${chId}&key=${apiKey}`
    //         );

    //         const channelData = response.data.items[0];
    //         if (channelData) {
    //             const customUrl = channelData.snippet.customUrl;
    //             if (customUrl) {
    //                 const userDoc = doc(db, "Users", mainUserId)
    //                 const data = {
    //                     yt_username: customUrl,
    //                 }
    //                 setDoc(userDoc, data, { merge: true }).then(res => {

    //                 });
    //             } else {
    //                 const userDoc = doc(db, "Users", mainUserId)
    //                 const data = {
    //                     ytUsername: null
    //                 }
    //                 setDoc(userDoc, data, { merge: true });
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error', error);
    //     }
    // };

    // Utility function to calculate the time difference

    function timeAgo(firebaseTimestamp) {
        const { seconds, nanoseconds } = firebaseTimestamp;

        // Convert seconds and nanoseconds to milliseconds
        const timestampDate = new Date(seconds * 1000 + nanoseconds / 1000000);
        const now = new Date();

        const secondsDiff = Math.floor((now - timestampDate) / 1000);

        let interval = Math.floor(secondsDiff / 31536000);



        if (interval >= 1) {
            setLastUpdatedRaw(interval);
            console.log(interval)
            return interval === 1 ? 'a year ago' : `${interval} years ago`;
        }
        interval = Math.floor(secondsDiff / 2592000);
        if (interval >= 1) {
            setLastUpdatedRaw(interval * 2592000);
            return interval === 1 ? 'a month ago' : `${interval} months ago`;
        }
        interval = Math.floor(secondsDiff / 86400);
        if (interval >= 1) {
            setLastUpdatedRaw(interval * 86400);
            return interval === 1 ? 'a day ago' : `${interval} days ago`;
        }
        interval = Math.floor(secondsDiff / 3600);
        if (interval >= 1) {
            setLastUpdatedRaw(interval * 3600);
            return interval === 1 ? 'an hour ago' : `${interval} hours ago`;
        }
        interval = Math.floor(secondsDiff / 60);
        if (interval >= 1) {
            setLastUpdatedRaw(interval * 60);
            return interval === 1 ? 'a minute ago' : `${interval} minutes ago`;
        }
        return 'just now';
    }

    const confirmTrigger = async () => {
        console.log(numberOfCreditsNeeded, 'numberOfCreditsNeeded');
        setTriggerCheck(true);
    }

    const triggerThumbnails = async (type) => {
        console.log(aiCreditsLeft, 'aiCreditsLeft');
        console.log(numberOfCreditsNeeded, 'numberOfCreditsNeeded');

        if (aiCreditsLeft > numberOfCreditsNeeded) {
            setTriggerCheck(false);
            setLoading(true);
            setLoadingState('Fetching your newest thumbnails...');

            const triggersRef = collection(db, "Users", mainUserId, "Triggers");
            const tDocRef = await addDoc(triggersRef, {
                trigger: type,
                trigger_count: 51,
                trigger_date: serverTimestamp(),
            });

            const docRef = doc(db, "Users", mainUserId);
            const ref = await updateDoc(docRef, {
                latest_trigger: type,
                latest_trigger_fulfilled: false,
                latest_trigger_date: serverTimestamp(),
            });
            console.log(`Have set new trigger for ${type}`);
        } else {
            if (checked === true) {
                if (triggerCheck === true) {
                    setLoading(false);
                    setMessage('Insufficient credits! Please Recharge your credits to continue');
                    setTriggerCheck(false);
                    setBlockPopup(true);
                } else {
                    toast.warn(`Unable to fetch new data. Insufficient credits!`, {
                        position: "top-right",
                        autoClose: 7000,
                    });
                    fetchThumbnails(limit);
                }
            }
        }
    }

    const updateLastUpdated = async () => {
        const docRef = doc(db, 'Users', mainUserId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const latestTriggerDateExists = data.hasOwnProperty('latest_trigger_date');

            if (latestTriggerDateExists) {
                setLastUpdated(timeAgo(data.latest_trigger_date));
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        if (latestTriggerFulfilled === true) {
            updateLastUpdated();
            fetchFullDatasetStats();
            fetchThumbnails(limit);
        } else {
            setLoadingState('Loading...');
        }
    }, [latestTriggerFulfilled, mainUserId, limit]);

    useEffect(() => {
        if (loading === true) {
            const interval = setInterval(() => {
                setLoadingProgress(prevProgress => {
                    if (prevProgress < 97) {
                        const newProgress = prevProgress + 0.2;
                        if (newProgress >= 10 && newProgress < 10.1) {
                            setLoadingState('Please wait...');
                        } else if (newProgress >= 10 && newProgress < 10.1) {
                            setLoadingState('Crunching the numbers...');
                        } else if (newProgress >= 20 && newProgress < 20.1) {
                            setLoadingState('Checking your data...');
                        } else if (newProgress >= 40 && newProgress < 40.1) {
                            setLoadingState('Fetching your thumbnails...');
                        } else if (newProgress >= 70 && newProgress < 70.1) {
                            setLoadingState('Arranging into neat little boxes...');
                        } else if (newProgress >= 80 && newProgress < 70.1) {
                            setLoadingState('Checking your videos...');
                        }
                        return newProgress;
                    } else {
                        clearInterval(interval);
                        return prevProgress;
                    }
                });
            }, 100);

            return () => clearInterval(interval);
        }
    }, [loading]);

    const fetchFullDatasetStats = async () => {
        const recordsRef = collection(db, "Users", mainUserId, "ChannelThumbnailsData");
        const q = query(
            recordsRef,
            where('position', '>=', 0),
            where('position', '<', 30),
            orderBy('position')
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return;
        }

        const data = querySnapshot.docs.map(doc => doc.data());
        setNumberOfCreditsNeeded((data.length * 3) + 30);

        // const viewsArray = data.map(item => convertViews(item.views));
        // const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
        // const averageViews = totalViews / viewsArray.length;
        // const sortedViews = [...viewsArray].sort((a, b) => a - b);
        // const medianViews = sortedViews.length % 2 === 0
        //     ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
        //     : sortedViews[Math.floor(sortedViews.length / 2)];



        // -------------------------------



        const viewsArray = data.map(item => convertViews(item.views));
        const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
        const averageViews = totalViews / viewsArray.length;
        // Calculate Median
        const sortedViews = [...viewsArray].sort((a, b) => a - b);
        const medianViews = sortedViews.length % 2 === 0
            ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
            : sortedViews[Math.floor(sortedViews.length / 2)];

        // Calculate 10% Trimmed Mean
        const trimPercent = 0.1;
        const trimCount = Math.floor(trimPercent * sortedViews.length);
        const trimmedViews = sortedViews.slice(trimCount, sortedViews.length - trimCount);
        const totalTrimmedViews = trimmedViews.reduce((acc, curr) => acc + curr, 0);
        const trimmedMean = totalTrimmedViews / trimmedViews.length;

        // Calculate the Average of the Median and the Trimmed Mean
        const combinedAverage = Math.round((medianViews + trimmedMean) / 2);



        // --------------------------------




        const maxViews = Math.max(...viewsArray);
        const minViews = Math.min(...viewsArray);
        const standardDeviation = Math.sqrt(viewsArray.reduce((acc, curr) => acc + Math.pow(curr - averageViews, 2), 0) / viewsArray.length);
        const aboveMedianViews = viewsArray.filter(view => view > combinedAverage).reduce((acc, curr) => acc + curr, 0);
        const belowOrEqualMedianViews = viewsArray.filter(view => view <= combinedAverage).reduce((acc, curr) => acc + curr, 0);
        const totalViewsConsidered = aboveMedianViews + belowOrEqualMedianViews;
        const weightedRatio = (aboveMedianViews / totalViewsConsidered) * 100;

        const fullStats = {
            totalViews,
            averageViews,
            combinedAverage,
            maxViews,
            minViews,
            standardDeviation,
            weightedRatio,
        };

        setFullDatasetStats(fullStats);


        // const maxViews = Math.max(...viewsArray);
        //         const minViews = Math.min(...viewsArray);
        //         const standardDeviation = Math.sqrt(viewsArray.reduce((acc, curr) => acc + Math.pow(curr - averageViews, 2), 0) / viewsArray.length);

        //         const aboveMedianViews = viewsArray.filter(view => view > combinedAverage).reduce((acc, curr) => acc + curr, 0);
        //         const belowOrEqualMedianViews = viewsArray.filter(view => view <= combinedAverage).reduce((acc, curr) => acc + curr, 0);
        //         const totalViewsConsidered = aboveMedianViews + belowOrEqualMedianViews;
        //         const weightedRatio = (aboveMedianViews / totalViewsConsidered) * 100;

        //         const newStats = {
        //             totalViews,
        //             averageViews,
        //             combinedAverage,
        //             maxViews,
        //             minViews,
        //             standardDeviation,
        //             weightedRatio,
        //         };

        //         setCurrentStats(newStats);
    };

    const fetchThumbnails = async (limit) => {
        // setLoadingProgress(70);
        setLoading(true);
        setLoadingState("Retrieving & calculating...");

        const recordsRef = collection(db, "Users", mainUserId, "ChannelThumbnailsData");
        const q = query(
            recordsRef,
            where('position', '>=', 0),
            where('position', '<', limit),
            orderBy('position')
        );

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            setLoading(false);
            setLoadingState('No thumbnails found');
            setMessage("No videos found on your channel.\nShorts and playlists are not supported.");
        } else {

            const data = querySnapshot.docs.map(doc => doc.data());

            if (data.length !== 0) {
                setLoadingProgress(0);
                // const viewsArray = data.map(item => convertViews(item.views));
                // const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
                // const averageViews = totalViews / viewsArray.length;
                // const sortedViews = [...viewsArray].sort((a, b) => a - b);
                // const medianViews = sortedViews.length % 2 === 0
                //     ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
                //     : sortedViews[Math.floor(sortedViews.length / 2)];




                // --------------------------------





                const viewsArray = data.map(item => convertViews(item.views));
                const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
                const averageViews = totalViews / viewsArray.length;
                // Calculate Median
                const sortedViews = [...viewsArray].sort((a, b) => a - b);
                const medianViews = sortedViews.length % 2 === 0
                    ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
                    : sortedViews[Math.floor(sortedViews.length / 2)];

                // Calculate 10% Trimmed Mean
                const trimPercent = 0.1;
                const trimCount = Math.floor(trimPercent * sortedViews.length);
                const trimmedViews = sortedViews.slice(trimCount, sortedViews.length - trimCount);
                const totalTrimmedViews = trimmedViews.reduce((acc, curr) => acc + curr, 0);
                const trimmedMean = totalTrimmedViews / trimmedViews.length;

                // Calculate the Average of the Median and the Trimmed Mean
                const combinedAverage = Math.round((medianViews + trimmedMean) / 2);

                // console.log("Median Views:", medianViews);
                // console.log("Trimmed Mean Views:", trimmedMean);
                // console.log("Combined Average Views:", combinedAverage);





                // --------------------------------------





                const maxViews = Math.max(...viewsArray);
                const minViews = Math.min(...viewsArray);
                const standardDeviation = Math.sqrt(viewsArray.reduce((acc, curr) => acc + Math.pow(curr - averageViews, 2), 0) / viewsArray.length);

                const aboveMedianViews = viewsArray.filter(view => view > combinedAverage).reduce((acc, curr) => acc + curr, 0);
                const belowOrEqualMedianViews = viewsArray.filter(view => view <= combinedAverage).reduce((acc, curr) => acc + curr, 0);
                const totalViewsConsidered = aboveMedianViews + belowOrEqualMedianViews;
                const weightedRatio = (aboveMedianViews / totalViewsConsidered) * 100;

                const newStats = {
                    totalViews,
                    averageViews,
                    combinedAverage,
                    maxViews,
                    minViews,
                    standardDeviation,
                    weightedRatio,
                };

                setCurrentStats(newStats);

                if (fullDatasetStats) {
                    const percentageChange = {
                        medianChange: ((newStats.combinedAverage - fullDatasetStats.combinedAverage) / fullDatasetStats.combinedAverage) * 100,
                        standardDeviationChange: ((newStats.standardDeviation - fullDatasetStats.standardDeviation) / fullDatasetStats.standardDeviation) * 100,
                        viewRatioChange: ((newStats.weightedRatio - fullDatasetStats.weightedRatio) / fullDatasetStats.weightedRatio) * 100,
                    };
                    setPercentageChange(percentageChange);
                }

                const transformedData = data.map(item => {
                    const viewsModified = convertViews(item.views);
                    return {
                        ...item,
                        viewsModified,
                        outlierScore: computeOutlierScore(viewsModified, combinedAverage),
                    };
                });

                const sortedTransformedData = transformedData.sort((a, b) => a.position - b.position);
                setViewTotal(totalViews);
                setViewAverage(combinedAverage);
                setAllThumbnailData(sortedTransformedData);
                setThumbnailData(sortedTransformedData);
                setLoading(false);

            } else {
                setEmpty(true);
                setLoading(false);
                setLoadingState('No thumbnails found');
                setMessage("No thumbnails found on your channel.\nShorts and playlists are not supported.");
            }
            // dCredits(transformedData.length)
        }
    }

    useEffect(() => {
        fetchThumbnails(limit);
    }, [limit]);

    function convertViews(viewsString) {
        const regex = /^(\d+(\.\d+)?)([KkMm])? views$/;
        const match = viewsString.match(regex);

        if (!match) {
            throw new Error('Invalid input format');
        }

        const number = parseFloat(match[1]);
        const suffix = match[3];

        let multiplier = 1;
        if (suffix === 'K' || suffix === 'k') {
            multiplier = 1000;
        } else if (suffix === 'M' || suffix === 'm') {
            multiplier = 1000000;
        }

        return number * multiplier;
    }

    const computeOutlierScore = (recordViews, averageViews) => {
        const percentageDifference = ((recordViews - averageViews) / averageViews) * 100;
        return Math.round(percentageDifference);
    }

    const formatNumber = number => {
        return new Intl.NumberFormat().format(number);
    };

    const openDetailsPopup = (item, url) => {
        setCurrentItemDetails(item)
        setOpenDetails(true);
        setCurrentThumbnail(url);

        console.log(url)
    }

    const closeDetailsPopup = () => {
        setCurrentItemDetails(null)
        setSelectedIndex(false);
        setOpenDetails(false);
    }

    const openExplanationPopup = (item, polarity) => {
        if (item === 'weighted_ratio') {
            setCurrentExplainerTopic('Thumbnail & Title Strength [Higher is better]');
            setCurrentExplainerDescription('This shows how attractive your videos are to new viewers, for the videos in this set.');
            if (polarity === 'positive') {
                setCurrentExplainerActionableDescription('If it is increasing, it means you are getting better at attracting viewers. Keep testing and improving your thumbnails and titles to attract more viewers.');
            } else {
                setCurrentExplainerActionableDescription('If it is decreasing, it means you need to pay more attention to your thumbnails and titles. Analyze, test and improve your thumbnails and titles to attract more viewers.');
            }
        }

        if (item === 'standard_deviation') {
            setCurrentExplainerTopic('View Count Variability [Lower is better]');
            setCurrentExplainerDescription('This shows how much the your views spike or dip from your expected views baseline. It helps you know if your views are steadily growing for all videos or if you sometimes get a lot of views and other times not many at all.');
            if (polarity === 'negative') {
                setCurrentExplainerActionableDescription("If your videos are getting more consistent view counts, with lower variations above or below the average for this set. Keep up the consistency and keep improving because your viewers seem to like what you're doing");
            } else {
                setCurrentExplainerActionableDescription('If it is increasing, it means your views change a lot from video to video. Try to figure out what makes your most popular videos attract more views. Like, the idea, thumbnail or title and do more of that.');
            }
        }

        if (item === 'channel_average') {
            setCurrentExplainerTopic('Expected Views Per Video [Higher is better]');
            setCurrentExplainerDescription('This is a baseline of the average number of views that your channel expects to have per video. It is based on how many people watched your last 30 videos.');
            if (polarity === 'positive') {
                setCurrentExplainerActionableDescription('If your baseline is going up, it means more people are watching your videos. You are making progress. It means more people are finding your channel. You need to keep improving your thumbnails and titles for newer videos.');
            } else {
                setCurrentExplainerActionableDescription('If your baseline is going down, it means you are losing viewers. Fewer people are watching your videos for this set. Consider changing the thumbnails & titles of the low performing videos to get more people to watch them.');
            }
        }
        setExplainerOpen(true);
    }

    const closeExplanationPopup = () => {
        setExplainerOpen(false);
    }

    const comingSoon = () => {
        toast.info(`Coming soon!`, {
            position: "top-right",
            autoClose: 7000,
        });
    }

    const handleSetCurrentThumbnail = (url) => {
        setCurrentThumbnail(url);
    };

    // const deductCredits = () => {
    //     const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/brainstorm-ai-titles-step-one/${mainUserId}`;
    //     const data = {
    //         prompt: mainText
    //     };
    //     const headers = {
    //         headers: {
    //             'Authorization': `${token}`,
    //         }
    //     };

    //     axios.post(url, data, headers).then((response) => {
    //         if (response.status === 200) {
    //             // console.log('It worked');
    //         }
    //     }).catch((error) => {
    //         console.error(error);
    //         // console.log('It did not work');
    //     });
    // }

    // const dCredits = (numberOfItems) => {
    //     updateDoc(doc(db, "Users", mainUserId),
    //         {

    //         }
    //     )
    // }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Channel Audit | ThumbnailGeek</title>
                <meta name="description" contee2nt="Learning Center | ThumbnailGeek" />
            </Helmet>

            <BlockPopupNotEnough show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} />

            <Transition.Root show={openDetails} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => closeDetailsPopup()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 backdrop-blur-sm transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white mx-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl ">
                                    <button onClick={() => closeDetailsPopup()} type="button"
                                        className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        data-modal-toggle="defaultModal">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </button>

                                    <div className="px-4 pt-4">

                                        <h3 className="text-lg font-bold text-gray-900 mb-3">
                                            Analysis & Insights
                                        </h3>

                                        <div className="sm:py-6 sm:pt-0 w-full overflow-y-scroll flex flex-row mt-3 sm:mt-0 sm:text-left">

                                            <div className="mt-2 w-2/5 pr-7">
                                                <div className="mb-2 relative group cursor-pointer">
                                                    <div className="rounded-lg inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200">
                                                        <YouTubeThumbnail
                                                            videoId={currentItemDetails?.video_id}
                                                            title={currentItemDetails?.title}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex'>
                                                    <div className="flex pb-5">
                                                        <div className="shrink-0 mr-6 md:mr-2 xl:mr-1 h-10 w-10 rounded-full  duration-200">
                                                            <img src={channelPhotoUrl} alt='image' className='w-full h-full rounded-full object-cover' />
                                                        </div>
                                                        <div className="w-[80%] ml-1">
                                                            <div className="group-hover:cursor-pointer p-0 text-[15px] font-semibold font-roboto w-full rounded-[5px] duration-200 leading-tight line-clamp-2">
                                                                <span> {currentItemDetails?.title}</span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer mt-1 p-0 text-[13px] rounded-[5px] duration-200 font-medium font-roboto ">
                                                                <span> {channelDisplayName} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-2 w-3/5 border-l pl-7">
                                                {
                                                    currentItemDetails?.outlierScore === 0
                                                        ?
                                                        <>

                                                            <div className={`w-full cursor-pointer bg-gray-200 text-gray-800 flex flex-col rounded-lg px-2.5 py-1.5 text-base font-medium md:mt-2 lg:mt-0`}>
                                                                <div className="inline-flex justify-between items-center">
                                                                    {currentItemDetails?.views}
                                                                    <div className="inline-flex">
                                                                        <RxDot
                                                                            className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-gray-700"
                                                                            aria-hidden="true"
                                                                        />
                                                                        {Math.abs(currentItemDetails?.outlierScore)}%
                                                                    </div>
                                                                </div>
                                                                <div className={`w-full cursor-pointer  text-gray-800 inline-flex justify-between items-center rounded-lg text-sm font-light lg:mt-0`}>
                                                                    <p className="">😴 Average video</p>
                                                                    <p className="">Same views as channel average</p>
                                                                </div>
                                                            </div>
                                                        </>

                                                        :
                                                        currentItemDetails?.outlierScore < 0
                                                            ?
                                                            <>
                                                                <div className={`w-full cursor-pointer bg-red-100 text-red-800 flex flex-col rounded-lg px-2.5 py-1.5 text-base font-medium md:mt-2 lg:mt-0`}>
                                                                    <div className="inline-flex justify-between items-center">
                                                                        {currentItemDetails?.views}
                                                                        <div className="inline-flex">
                                                                            <ArrowDownIcon
                                                                                className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-red-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            {(currentItemDetails?.outlierScore)}%
                                                                        </div>
                                                                    </div>
                                                                    <div className={`w-full cursor-pointer  text-gray-800 inline-flex justify-between items-center rounded-lg text-sm font-light lg:mt-0`}>
                                                                        <p className="">😵‍💫 Low performance</p>
                                                                        <p className="">Below average</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={`w-full cursor-pointer bg-green-100 text-green-800 flex flex-col rounded-lg px-2.5 py-1.5 text-base font-medium md:mt-2 lg:mt-0`}>
                                                                    <div className="inline-flex justify-between items-center">
                                                                        {currentItemDetails?.views}
                                                                        <div className="inline-flex">
                                                                            <ArrowUpIcon
                                                                                className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-green-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            {(currentItemDetails?.outlierScore)}%
                                                                        </div>
                                                                    </div>
                                                                    <div className={`w-full cursor-pointer  text-gray-800 inline-flex justify-between items-center rounded-lg text-sm font-light lg:mt-0`}>
                                                                        <p className="">🥳 Outlier video</p>
                                                                        <p className="">Above average</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                }

                                                {
                                                    currentItemDetails?.outlierScore < 0 &&
                                                    <div className="mt-2">
                                                        <h3 className="mt-6 ml-1 text-base font-semibold leading-6 text-gray-800">Possible Issues & Reasons</h3>
                                                        <div className="mt-1 w-full">
                                                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                                                <Tab.List className=' w-full mt-2 ml-1 lg:mt-2 text-gray-800 text-sm fixed z-20 r-0 mx-auto lg:mr-8 space-x-2'>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className={`inline-flex items-center p-2`}>
                                                                            <SlPicture className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Thumbnail</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <RiText className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Title</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <TbBell className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Idea</p>
                                                                        </span>
                                                                    </Tab>
                                                                </Tab.List>

                                                                <Tab.Panels className='w-full pt-4 flex flex-wrap justify-start'>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className="text-sm text-gray-500">
                                                                            Your thumbnail may lack strong curiosity.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-4">
                                                                            It may be too explanatory & not leave enough of a gap to create interest in a viewer.
                                                                        </p>
                                                                        <div className="inline-flex">
                                                                            <NavLink to={`/thumbnail-analysis/upload`}
                                                                                className="rounded-md mr-2 inline-flex items-center mt-12 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-blue-300 hover:bg-gray-50">
                                                                                Analyze thumbnail
                                                                                {/* <MdOpenInNew className='ml-1' /> */}
                                                                            </NavLink>
                                                                            <a href={`https://studio.youtube.com/video/${currentItemDetails?.video_id}/edit`} target='_blank' className="rounded-md inline-flex items-center mt-12 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-blue-300 hover:bg-gray-50">
                                                                                Change thumbnail in Youtube Studio
                                                                                <MdOpenInNew className='ml-1' />
                                                                            </a>
                                                                        </div>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className="text-sm text-gray-500 mr-2">
                                                                            Title may not be as catchy as expected. It may be bland or very low in curiosity.
                                                                        </p>

                                                                        <p className="mt-3 text-sm text-gray-500 mr-3">
                                                                            It may also be confusing when seen along with the thumbnail. Confused viewers will scroll past.
                                                                        </p>

                                                                        <a href={`https://studio.youtube.com/video/${currentItemDetails?.video_id}/edit`} target='_blank' className="rounded-md inline-flex items-center mt-12 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-blue-300 hover:bg-gray-50">
                                                                            Change title in Youtube Studio <MdOpenInNew className='ml-1' />
                                                                        </a>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" max-w-4xl text-sm text-gray-500">
                                                                            Your video idea of your may be confusing to viewers, lack novelty or uniqueness.
                                                                        </p>
                                                                    </Tab.Panel>

                                                                </Tab.Panels>
                                                            </Tab.Group>

                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    currentItemDetails?.outlierScore > 0 &&
                                                    <div className="">
                                                        <h3 className="mt-4 ml-1 text-base text-left font-semibold text-gray-800">Possible Insights</h3>
                                                        <div className="mt-1">
                                                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                                                <Tab.List className=' w-full mt-2 ml-1 lg:mt-2 text-gray-800 text-sm fixed z-20 r-0 mx-auto lg:mr-8 space-x-2'>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className={`inline-flex items-center p-2`}>
                                                                            <SlPicture className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Thumbnail</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <RiText className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Title</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <TbBell className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Idea</p>
                                                                        </span>
                                                                    </Tab>
                                                                </Tab.List>

                                                                <Tab.Panels className='w-full pt-4 flex flex-wrap justify-start overflow-scroll'>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" max-w-4xl text-sm text-gray-500">
                                                                            It is very likely that this thumbnail builds enough curiosity to cause new viewers to click.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            You can copy the thumbnail concept, colors or style for other videos.
                                                                        </p>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" max-w-4xl text-sm text-gray-500">
                                                                            Title may be inducing a lot of curiosity in a viewer.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            Your title may tie really well with your thumbnail to give a clear, unconfusing & catchy message.
                                                                        </p>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" max-w-4xl text-sm text-gray-500">
                                                                            The video idea may be interesting to more new viewers in your niche, not just your subscribers.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            You can take notes from this for other videos.
                                                                        </p>
                                                                    </Tab.Panel>

                                                                </Tab.Panels>
                                                            </Tab.Group>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    currentItemDetails?.outlierScore === 0 &&
                                                    <div className="">
                                                        <h3 className="mt-4 ml-1 text-base font-semibold leading-6 text-gray-800">Possible Insights</h3>
                                                        <div className="mt-1">
                                                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                                                <Tab.List className=' w-full mt-2 ml-1 lg:mt-2 text-gray-800 text-sm fixed z-20 r-0 mx-auto lg:mr-8 space-x-2'>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className={`inline-flex items-center p-2`}>
                                                                            <SlPicture className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Thumbnail</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <RiText className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Title</p>
                                                                        </span>
                                                                    </Tab>
                                                                    <Tab className={({ selected }) =>
                                                                        selected
                                                                            ? 'lg:w-32 outline-0 text-white rounded-lg bg-gray-700 active group duration-500'
                                                                            : 'lg:w-32 outline-0 bg-gray-200 rounded-lg hover:bg-gray-400 hover:text-gray-600 group'}
                                                                    >
                                                                        <span className="inline-flex items-center p-2">
                                                                            <TbBell className='' />
                                                                            <p className='ml-1.5 hidden lg:block'>Idea</p>
                                                                        </span>
                                                                    </Tab>
                                                                </Tab.List>

                                                                <Tab.Panels className='w-full pt-4 flex flex-wrap justify-start overflow-scroll'>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" text-sm text-gray-500 mr-2">
                                                                            Your thumbnail may be ok enough to get your core audience and subscribers to watch.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            It may not be strong enough to reach a wider audience hence the average number of views.
                                                                        </p>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className="l text-sm text-gray-500 mr-2">
                                                                            Your title appeals to your core audience and subscribers to watch.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            However it may not be strong enough to create curiosity a wider audience hence the average number of views.
                                                                        </p>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel className='w-full pt-12 flex flex-wrap justify-start'>
                                                                        <p className=" text-sm text-gray-500 mr-2">
                                                                            This idea is interesting to your core audience and subscribers. However, it may not be strong enough to
                                                                            reach a wider audience to gain lots of views.
                                                                        </p>
                                                                        <p className="mt-3 text-sm text-gray-500 mr-2">
                                                                            Copying the idea in newer videos may give similar views or less views. Not enough views to grow your channel fast.
                                                                        </p>
                                                                    </Tab.Panel>

                                                                </Tab.Panels>
                                                            </Tab.Group>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        currentItemDetails?.outlierScore < 0 || currentItemDetails?.outlierScore === 0 &&
                                        <div className="inline-flex p-3">
                                            {/* <GiProcessor className='text-gray-500' /> */}
                                            <p className="text-xs ml-1 text-gray-500">* Changes to your thumbnail or title may take time to see the effects. It may take a week or more to start results. Positive results are not guaranteed for each modification you make, so keep experimenting</p>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={explainerOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => closeExplanationPopup()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 backdrop-blur-sm transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg ring-1 bg-white mx-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:h-auto">
                                    <button onClick={() => closeExplanationPopup()} type="button"
                                        className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        data-modal-toggle="defaultModal">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </button>

                                    <div className="px-4 pt-4">
                                        <h3 className="text-lg font-bold text-gray-700 mb-3">
                                            {currentExplainerTopic}
                                        </h3>
                                        <div className="sm:py-6 sm:pt-0 w-full overflow-y-scroll flex flex-col mt-3 sm:mt-0 sm:text-left">
                                            <p className="text-sm font-normal text-gray-600 mr-2">
                                                {currentExplainerDescription}
                                            </p>
                                            <h3 className="mt-7 text-base font-semibold text-gray-700">
                                                What this means
                                            </h3>
                                            <p className=" text-sm font-normal text-gray-600 mr-2">
                                                {currentExplainerActionableDescription}
                                            </p>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {
                triggerCheck && (
                    <div id="trigger-check-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                        <div className="relative w-full h-full max-w-lg md:h-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                        Fetch latest video data?
                                    </h3>
                                    <button onClick={() => setTriggerCheck(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div className="p-6 space-y-6">
                                    <div className='text-center'>
                                        Do you want to fetch latest video data from your youtube channel and re-calculate your metrics?
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="flex items-baseline ">
                                        <div className="w-full inline-flex items-center justify-center text-xs text-purple-700 px-1 pb-1"><AiFillFire className='w-4 h-4' /> {numberOfCreditsNeeded ?? 150} credits needed</div>
                                    </div>
                                    <div className="space-x-2">
                                        <button onClick={() => setTriggerCheck(false)} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                        <button onClick={() => triggerThumbnails("get_thumbnails")} className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Fetch</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className='bg-gray-50'>
                <SideBar selectedSideBarItem="channel-audit" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-10 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="inline-flex items-center">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold lg:flex `}> Channel Audit + Outlier Insights</h5>
                                <div className="">
                                    <span className="inline-flex items-center rounded-md bg-purple-100 ml-2 px-2 py-1 
                                text-xs font-medium text-purple-700">
                                        BETA
                                    </span>
                                </div>
                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] lg:ml-8 xl:ml-2 2xl:ml-8 mt-9">
                            <div className="w-full h-full mx-auto">

                                {!loading && thumbnailData && thumbnailData.length < 8 && (
                                    <div className="border-l-4 border-red-400 bg-red-50 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm text-red-700">
                                                    Your channel has {thumbnailData?.length > 0 ? 'only' : ''} {thumbnailData?.length} video{thumbnailData?.length > 1 || thumbnailData?.length === 0 ? 's' : ''}. You need more videos to see meaningful trends and insights.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="w-full border-b py-2  inline-flex items-center justify-between ">
                                    <div className="inline-flex items-center">
                                        <h3 className='ml-2 text-base font-semibold text-gray-700'>Your channel stats</h3>
                                        <p className='ml-2 text-xs font-normal text-gray-500'>( Up to last 30 videos )</p>
                                    </div>

                                    <div className="inline-flex items-center">
                                        <Tooltip content='Email alerts to notify you, when a new video is performing poorly' position='bottom'>
                                            <button onClick={comingSoon} type="button" className="inline-flex rounded-md px-3 py-2 text-sm font-medium text-gray-700 
                                            hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                <BiBroadcast className='w-5 h-5 mr-2' />
                                                Set up monitoring
                                            </button>
                                        </Tooltip>
                                        <Tooltip content='Re-fetch & re-calculate data from your channel' position='bottom'>
                                            <button onClick={confirmTrigger} type="button" className="inline-flex rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                <HiRefresh className='w-5 h-5 mr-2' />
                                                Fetch fresh data
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                                <dl className="mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-3">
                                    <div className="relative px-4 py-5 sm:p-6 border-r border-gray-200">
                                        <button onClick={() => openExplanationPopup('weighted_ratio')} className={`absolute right-1 top-1 cursor-pointer bg-gray-100 duration-200 hover:bg-gray-200 text-gray-500 flex justify-center items-center rounded-full w-5 h-5 text-sm font-medium`}>
                                            ?
                                        </button>
                                        <dt className="text-base font-normal text-gray-900">
                                            Thumbnail & Title Strength
                                        </dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                {Math.round(currentStats?.weightedRatio)}
                                                <span className="text-xl font-semibold text-blue-600">%</span>
                                                <span className="ml-2 text-sm font-normal text-gray-500">weighted ratio</span>
                                            </div>

                                            {
                                                percentageChange.viewRatioChange === 0
                                                    ?
                                                    <Tooltip content="No change" position={'bottom'}>
                                                        <div className={`bg-gray-200 text-gray-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                            <RxDot
                                                                className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            {percentageChange.viewRatioChange ?? '-'}%
                                                        </div>
                                                    </Tooltip>
                                                    :
                                                    percentageChange.viewRatioChange > 0
                                                        ?
                                                        <Tooltip content="Increase" position={'bottom'}>
                                                            <div className={`bg-green-100 text-green-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                <ArrowUpIcon
                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                                {Math.round(percentageChange.viewRatioChange) ?? '-'}%
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip content="Decrease" position={'bottom'}>
                                                            <div className={`bg-red-100 text-red-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                <ArrowDownIcon
                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                                {Math.round(percentageChange.viewRatioChange) ?? '-'}%
                                                            </div>
                                                        </Tooltip>
                                            }
                                        </dd>
                                    </div>

                                    <div className="relative px-4 py-5 sm:p-6 border-r border-gray-200">
                                        <button onClick={() => openExplanationPopup('standard_deviation')} className={`absolute right-1 top-1 cursor-pointer bg-gray-100 hover:bg-gray-200 duration-200 text-gray-500 flex justify-center items-center rounded-full w-5 h-5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                            ?
                                        </button>
                                        <dt className="text-base font-normal text-gray-900">View Count Variability</dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                            <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                                ±<span className='mr-1'></span>{formatNumber(Math.round(currentStats?.standardDeviation))}
                                                <span className="ml-2 text-sm font-normal text-gray-500">views</span>
                                            </div>

                                            {
                                                percentageChange.standardDeviationChange === 0
                                                    ?
                                                    <Tooltip content="No change" position={'bottom'}>
                                                        <div className={`bg-gray-200 text-gray-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                            <RxDot
                                                                className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            {percentageChange.standardDeviationChange ?? '-'}%
                                                        </div>
                                                    </Tooltip>
                                                    :
                                                    percentageChange.standardDeviationChange < 0
                                                        ?
                                                        <Tooltip content="Decrease" position={'bottom'}>
                                                            <div className={`bg-green-100 text-green-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                <ArrowDownIcon
                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                                {Math.round(Math.abs(percentageChange.standardDeviationChange)) ?? '-'}%
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip content="Increase" position={'bottom'}>
                                                            <div className={`bg-red-100 text-red-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                <ArrowUpIcon
                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                                {Math.round(Math.abs(percentageChange.standardDeviationChange)) ?? '-'}%
                                                            </div>
                                                        </Tooltip>
                                            }
                                        </dd>
                                    </div>

                                    <div className="relative px-4 py-5 sm:p-6">
                                        <button onClick={() => openExplanationPopup('channel_average')} className={`absolute right-1 top-1 cursor-pointer bg-gray-100 hover:bg-gray-200 duration-200 text-gray-500 flex justify-center items-center rounded-full w-5 h-5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                            ?
                                        </button>
                                        <dt className="text-base font-normal text-gray-900">Expected Views Per Video</dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex ">

                                            <div className="flex md:block lg:flex justify-between h-7 w-full">
                                                {
                                                    loadingViewAverage === true
                                                        ?
                                                        <DotDotDot />
                                                        :
                                                        <>
                                                            <div className="min-w-fit h-12 flex items-baseline text-2xl font-semibold text-blue-600">
                                                                {formatNumber(viewAverage)}
                                                                <span className="ml-2 text-sm font-normal text-gray-500">views</span>
                                                            </div>

                                                            {
                                                                percentageChange.medianChange === 0
                                                                    ?
                                                                    <Tooltip content="No change" position={'bottom'}>
                                                                        <div className={`bg-gray-200 text-gray-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                            <RxDot
                                                                                className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            {percentageChange.medianChange ?? '-'}%
                                                                        </div>
                                                                    </Tooltip>
                                                                    :
                                                                    percentageChange.medianChange > 0
                                                                        ?
                                                                        <Tooltip content="Increase" position={'bottom'}>
                                                                            <div className={`bg-green-100 text-green-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                <ArrowUpIcon
                                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                {Math.round(percentageChange.medianChange) ?? '-'}%
                                                                            </div>
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip content="Decrease" position={'bottom'}>
                                                                            <div className={`bg-red-100 text-red-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                <ArrowDownIcon
                                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                {Math.round(percentageChange.medianChange) ?? '-'}%
                                                                            </div>
                                                                        </Tooltip>
                                                            }

                                                            {/* <div className={`cursor-pointer bg-purple-200 text-purple-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                ?
                                                            </div> */}
                                                        </>
                                                }
                                            </div>
                                        </dd>
                                    </div>
                                </dl>



                                <div className="inline-flex justify-between items-baseline w-full mt-6 mb-3 pr-6">
                                    <h1 className='mb-3 ml-2 font-bold'>Your Latest Videos</h1>
                                    <span className={`text-sm ${lastUpdatedRaw > 100000 ? 'text-orange-500' : 'text-gray-500'}`}> {lastUpdated ? `Last updated ${lastUpdated}` : ''}</span>
                                    <span className=" inline-flex rounded-md shadow-sm">
                                        {/* If limit is 10, highlight it */}
                                        <button
                                            type="button"
                                            onClick={() => setLimit(8)}
                                            className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 ${limit === 8 ? 'bg-gray-200' : ''}`}
                                        >
                                            Last 8
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setLimit(16)}
                                            className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 ${limit === 16 ? 'bg-gray-200' : ''}`}
                                        >
                                            Last 16
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setLimit(30)}
                                            className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 ${limit === 30 ? 'bg-gray-200' : ''}`}
                                        >
                                            Last 30
                                        </button>
                                    </span>
                                </div>

                                <div className=" flex flex-wrap">

                                    {
                                        triggerError === true &&
                                        (
                                            <div className="flex flex-col items-center justify-center my-7">
                                                <FiAlertTriangle className='w-12 h-12 animate-pulse text-gray-500' />
                                                <div className="flex h-2 w-32 mb-4 mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                    Click here
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        empty !== true
                                            ?
                                            loading === true
                                                ?
                                                (
                                                    <div className="w-full flex flex-col justify-center mt-32 items-center">
                                                        <div className="flex h-2 w-32 mb-4 mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                            <div
                                                                style={{ width: `${loadingProgress}%` }}
                                                                className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                            />
                                                        </div>
                                                        {loadingState}
                                                    </div>
                                                )
                                                :
                                                <>
                                                    {
                                                        thumbnailData?.map((item, index) => (
                                                            <div key={index} className='text-gray-800 w-64 2xl:w-72 pb-5 text-[0.9rem] mx-2 mb-5'>
                                                                <Tooltip content={`Click for details`} position={'bottom'}>
                                                                    <div onClick={() => openDetailsPopup(item, currentThumbnail)} className="mb-2 relative group cursor-pointer">
                                                                        <div className="hover:ring-2 rounded-lg hover:ring-blue-500 inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200">
                                                                            <YouTubeThumbnail
                                                                                videoId={item?.video_id}
                                                                                title={item?.title}
                                                                                onImageLoad={setCurrentThumbnail}
                                                                            />
                                                                            {/* <img
                                                                                src={item?.thumbnail}
                                                                                className="inline-block mx-0 w-full rounded-lg cursor-pointer"
                                                                                alt={item?.title}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </Tooltip>

                                                                <div className='flex'>
                                                                    <div className='flex-col w-full'>
                                                                        <div className="flex flex-col mb-[0.1rem] h-8 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal cursor-pointer">
                                                                            <p className={`text-gray-700 font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{item.title}</p>
                                                                        </div>
                                                                        <div className='inline-flex justify-between items-center w-full  mt-2'>
                                                                            <div className='text-[13px] font-roboto lg:text-[13px]'>
                                                                                <p className={`text-gray-700 w-full cursor-pointer`}>{item?.views}</p>
                                                                            </div>
                                                                            {
                                                                                item?.outlierScore === 0
                                                                                    ?
                                                                                    <Tooltip content="No change in views" position={'bottom'}>
                                                                                        <div className={`cursor-pointer bg-gray-200 text-gray-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                            <RxDot
                                                                                                className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-gray-700"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                            {Math.abs(item.outlierScore)}%
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    item?.outlierScore < 0
                                                                                        ?
                                                                                        <Tooltip content={`${Math.abs(item?.outlierScore)}% decrease in views`} position={'bottom'}>
                                                                                            <div className={`cursor-pointer bg-red-100 text-red-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                                <ArrowDownIcon
                                                                                                    className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-red-500"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                - {' '}{Math.abs(item.outlierScore)}%
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip content="This video is an outlier. You can copy the idea, thumbnail concept or title concept for other videos" position={'bottom'}>
                                                                                            <div
                                                                                                className={`cursor-pointer bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                                <ArrowUpIcon
                                                                                                    className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                {Math.abs(item.outlierScore)}%
                                                                                            </div>
                                                                                        </Tooltip>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            :
                                            <div className="w-full flex flex-col items-center justify-center my-24">
                                                <FiAlertTriangle className='w-12 h-12 animate-pulse text-gray-500' />
                                                <div className="flex mb-4 mt-3 w-64 text-center rounded-sm overflow-hidden text-sm ">
                                                    {message}
                                                </div>
                                            </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FeedbackBanner currentPageUrl={'/channel-audit'} />
        </>
    )
}

export default ChannelAudit


{/* {
        computePercentageChange(stats.medianViews, lowestMedian) === 0
            ?
            <Tooltip content="Increased average views" position={'bottom'}>
                <div className={`bg-gray-200 text-gray-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                    <RxDot
                        className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-gray-500"
                        aria-hidden="true"
                    />
                    {computePercentageChange(stats.medianViews, lowestMedian)}%
                </div>
            </Tooltip>
            :
            computePercentageChange(stats.medianViews, lowestMedian) < 0
                ?
                <Tooltip content="Decreased average views" position={'bottom'}>
                    <div className={`bg-red-100 text-red-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                        <ArrowDownIcon
                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
                            aria-hidden="true"
                        />
                        {computePercentageChange(stats.medianViews, lowestMedian)}%
                    </div>
                </Tooltip>
                :
                <Tooltip content="No change in average views" position={'bottom'}>
                    <div className={`bg-green-100 text-green-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                        <ArrowUpIcon
                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                            aria-hidden="true"
                        />
                        {computePercentageChange(stats.medianViews, lowestMedian)}%
                    </div>
                </Tooltip>
    } */}