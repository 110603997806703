import React from 'react'
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { NavLink } from 'react-router-dom';

const Hint = ({ hintText, fontSizePx, link }) => {

    return (
        <>
            {
                hintText !== ''
                    ?
                    <div className='w-full inline-flex ring-1 bg-blue-50 p-3 rounded-md'>
                        <div className="border-r border-blue-300 p-2 pt-1 mr-3 w-12">
                            <MdOutlineTipsAndUpdates className='text-lg text-blue-500' />
                        </div>
                        <div className={`text-[${fontSizePx}]`}>
                            {link ? (
                                <>
                                    {hintText}{' '}
                                    <NavLink to={link} target="_blank" className="text-blue-500 font-semibold">
                                        Learn more
                                    </NavLink>
                                </>
                            ) : (
                                hintText
                            )}
                        </div>
                    </div>
                    :
                    <></>
            }
        </>

    )
}

export default Hint