import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import Cookies from 'js-cookie'
import ReactPlayer from 'react-player/youtube';
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { TfiTrash } from "react-icons/tfi";
import { IoAddSharp } from "react-icons/io5";
import { PremiumCredentials } from '../../../context/PremiumContext';
import { UserAuth } from '../../../context/AuthContext'
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import logo from '../../../assets/logo_full_32.png';
import FocusBoardCollageImages from '../../components/FocusBoardCollageImages';
import Avatar from '../../components/Avatar';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import Tooltip from '../../components/Tooltip';
import Hint from '../../components/Hint';
import DotDotDot from '../../components/DotDotDot';
import { FaCrown } from 'react-icons/fa';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';

const FocusBoards = () => {
    let navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const mainUserId = localStorage.getItem("mainUserId");
    const { accessLevel, aiCreditsLeft } = PremiumCredentials();
    const [boards, setBoards] = useState([]);
    const [demoBoards, setDemoBoards] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [demoLoaded, setDemoLoaded] = useState(false);
    const [cloningDemo, setCloningDemo] = useState(false);
    const [extensionModal, setExtensionModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [channelModal, setChannelModal] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [pageSelectModal, setPageSelectModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemToDelete, setItemToDelete] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const [cookiesEnabled, setCookiesEnabled] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [helpModal, setHelpModal] = useState(false);
    const { pathname } = useLocation();

    const [blockPopup, setBlockPopup] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const getFocusBoards = async () => {
        const colRef = collection(db, 'Users', mainUserId, 'FocusBoards');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setBoards(newData);
                setLoaded(true);
                // console.log(boards, newData);
            })
    }

    const getDemoFocusBoards = async () => {

        const colRef = collection(db, 'FocusBoardDemos');
        const q = query(
            colRef,
            orderBy("date_modified", "desc"),
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                console.log(newData);
                setDemoBoards(newData);
                setDemoLoaded(true);
            })
    }

    const cloneDemoFocusBoard = async () => {

        // const boardItemsRespArray = await Promise.all(
        //     boardItems.map(async (boardItem) => {
        //         const boardItemResp = await db
        //             .collection("Users")
        //             .doc(req.params.id)
        //             .collection("FocusBoardItems")
        //             .add({
        //                 thumbnail: boardItem.thumbnail,
        //                 avatar: boardItem.avatar,
        //                 saved_thumbnail: "",
        //                 title: boardItem.title,
        //                 channelName: boardItem.channelName,
        //             });
        //         console.log(`Focus Board Item ID: ${boardItemResp.id}`);
        //         return boardItemResp;
        //     })
        // );

        // const getImagePromises = boardItemsRespArray.map(
        //     (boardItemResp, index) => {
        //         return getImageToFBStorage(
        //             bucket,
        //             axios,
        //             boardItems[index].thumbnail,
        //             req.params.id,
        //             boardItemResp.id
        //         );
        //     }
        // );

        // Promise.all(getImagePromises);

        // const boardDataArray = boardItems.map((boardItem, index) => {
        //     return {
        //         fb_item_id: boardItemsRespArray[index].id,
        //         thumbnail: boardItem.thumbnail,
        //         avatar: boardItem.avatar,
        //         saved_thumbnail: "",
        //         title: boardItem.title,
        //         channelName: boardItem.channelName,
        //     };
        // });

        // const finalResponse = await db
        //     .collection("Users")
        //     .doc(req.params.id)
        //     .collection("FocusBoards")
        //     .add({
        //         name: req.body.name,
        //         items: boardDataArray,
        //         is_active: true,
        //         date_created: admin.firestore.FieldValue.serverTimestamp(),
        //         date_modified: admin.firestore.FieldValue.serverTimestamp(),
        //     });

    }

    const deleteFocusBoard = async (board_id) => {
        setDeleting(true);
        const docRef = doc(db, 'Users', mainUserId, 'FocusBoards', board_id);
        await deleteDoc(docRef).then(() => {
            deleteModalClose();
            setBoards((state) => state.filter((item) => item.id !== board_id))
            setDeleting(false);
            toast("Board Deleted", {
                icon: ({ theme, type }) => <TfiTrash className='text-white h-7 w-7' />
            });
        });
    }

    useEffect(() => {
        getFocusBoards();
        getDemoFocusBoards();
    }, []);

    const goToFocusBoardDetails = (id) => {
        let path = `/focus-board-details/${id}`;
        navigate(path);
    }

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const pageSelectModalClose = (e) => {
        setPageSelectModal(false);
    }

    const handleSearchModalClose = (e) => {
        if (e.target.id === "search-page-modal") {
            setSearchModal(false);
        };
    }

    const handleChannelModalClose = (e) => {
        if (e.target.id === "channel-page-modal") {
            setChannelModal(false);
        };
    }

    const handlePageSelectModalClose = (e) => {
        if (e.target.id === "page-select-modal") {
            setPageSelectModal(false);
        };
    }

    const channelModalClose = (e) => {
        setChannelModal(false);
    }

    const searchModalClose = (e) => {
        setSearchModal(false);
    }

    const openSelectModal = () => {
        // Check if the test cookie was set

        if (accessLevel === 1 || accessLevel === 2) {
            if (document.cookie.indexOf('TG_CEF') !== -1) {
                setPageSelectModal(true);
            } else {
                setExtensionModal(true);
            }
        } else {
            setBlockPopup(true);
        }
    }

    const goToYoutubeHomepage = () => {
        const url = "https://www.youtube.com";
        var checkChromeExtension = Cookies.get('TG_CEF');

        if (checkChromeExtension === 'TRUE') {

            //Set Page Intent
            const userDoc = doc(db, "Users", mainUserId)
            const data = {
                page_intent: "OPENFB"
            }
            setDoc(userDoc, data, { merge: true }).then(res => {
                pageSelectModalClose();
                window.open(url, '_blank');
            }).catch((e) => {
                toast("Unable to verify connection.", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });
            });


        } else {
            setExtensionModal(true);
        }
    }

    const goToChannelPage = () => {
        pageSelectModalClose();

        if (document.cookie.indexOf('TG_CEF') !== -1) {
            setCookiesEnabled(true);
            var checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                setChannelModal(true);
            } else {
                setExtensionModal(true);
            }
        } else {
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const openSearchModal = () => {
        pageSelectModalClose();

        // Check if the test cookie was set
        if (document.cookie.indexOf('TG_CEF') !== -1) {
            // Cookies are enabled
            setCookiesEnabled(true);

            var checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                setSearchModal(true);
            } else {
                setExtensionModal(true);
            }

        } else {
            // Cookies are disabled
            // alert('Cookies are disabled in your browser. Please enable cookies to use this feature.');
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }

    }

    const showHelpModal = (id) => {
        setHelpModal(true);
    }

    const handleHelpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setHelpModal(false);
        }
    }

    const handleSearchQuerySubmit = async (e) => {
        e.preventDefault();
        const url = `https://www.youtube.com/results?search_query=${searchQuery}`;

        //Set Page Intent
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
            page_intent: "OPENFB"
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            window.open(url, '_blank');
            searchModalClose();
        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const handleChannelSubmit = async (e) => {
        e.preventDefault();
        const url = `https://www.youtube.com/@${channelName}/videos`;

        //Set Page Intent
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
            page_intent: "OPENFB"
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            window.open(url, '_blank');
            searchModalClose();
        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const openDeleteModal = (name, id) => {
        setItemToDelete(name);
        setIdToDelete(id)
        setDeleteModal(true);
    }

    const handleDeleteModalClose = (e) => {
        if (e.target.id === "fb-item-delete-modal") {
            setDeleteModal(false);
        };
    }

    const deleteModalClose = (e) => {
        setDeleteModal(false);
    }

    const installExtension = (url) => {
        const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
        const popupWindow = window.open(url, '_blank', windowFeatures);
        // Check if the popup was blocked
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            console.log('Popup was blocked or unable to open.');
            window.open(url, '_blank');
        } else {
            console.log('Popup opened successfully.');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Focus Boards | ThumbnailGeek</title>
                <meta name="description" content="Focus Boards | ThumbnailGeek" />
            </Helmet>

            <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-[100] 
            bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg max-w-6xl border border-gray-400 shadow-md">
                    <div className="mx-auto mt-2">
                        <h3 className="text-base text-center font-semibold leading-6 text-gray-900">Here's how it works</h3>
                    </div>
                    <div className="mt-2">
                        <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                className="rounded-lg overflow-hidden"
                                width="853px"
                                height="505px"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or the extension has been disabled in your browser.
                            </p>
                            <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />

                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={() => installExtension('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Install Extension
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="page-select-modal" onClick={handlePageSelectModalClose} className={`${pageSelectModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Import thumbnails from...
                            </h3>
                            <button onClick={pageSelectModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>


                        <div className="p-6 space-y-4 flex flex-col justify-center items-center">
                            <button onClick={() => { openSearchModal() }} className="bg-blue-600 text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-blue-700 active:bg-blue-800 ">Youtube Search Page</button>
                            <button onClick={() => { goToYoutubeHomepage() }} className="bg-[#f12c5a] text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-[#dd204c] active:bg-[#c81841] ">Youtube Homepage</button>
                            <button onClick={() => { goToChannelPage() }} className="bg-purple-500 text-white p-2.5 px-8 rounded-lg text-[14px] hover:bg-purple-600 active:bg-purple-800 ">A Youtuber's Channel</button>
                        </div>
                        <div className="mt-1 pb-3 flex-col justify-center items-center text-center">
                            <span onClick={() => { showHelpModal() }} className="text-blue-500 hover:cursor-pointer text-center text-[12px]"> How does it work?</span>
                        </div>

                    </div>
                </div>
            </div>

            <div id="channel-page-modal" onClick={handleChannelModalClose} className={`${channelModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 pt-3 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base font-semibold text-gray-900 dark:text-white">
                                Youtube Channel Username
                            </h3>
                            <button onClick={channelModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className='px-4 mt-5'>
                            <form onSubmit={handleChannelSubmit}>

                                <div className="mt-1 flex rounded-md shadow-sm mx-auto">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500 sm:text-sm">
                                        https://youtube.com/@
                                    </span>
                                    <input
                                        type="text"
                                        name="channelName"
                                        id="channelName"
                                        required={true}
                                        onChange={(e) => setChannelName(e.target.value)}
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        placeholder="ChannelName"
                                    />
                                </div>

                                <div className="flex items-center justify-end space-x-2 py-4 border-gray-200 rounded-b dark:border-gray-600">
                                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Start collecting thumbnails</button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div id="search-page-modal" onClick={handleSearchModalClose} className={`${searchModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base font-semibold text-gray-900 dark:text-white">
                                Youtube niche or search term
                            </h3>
                            <button onClick={searchModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form onSubmit={handleSearchQuerySubmit}>
                            <div className="p-4 pb-0 space-y-5">
                                <div>
                                    <input onChange={(e) => setSearchQuery(e.target.value)} type="text" id="search_query" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="eg. Food" required />
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-4 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Start collecting thumbnails</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="fb-item-delete-modal" onClick={handleDeleteModalClose} className={`${deleteModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Are you sure you want to delete?
                            </h3>
                            <button onClick={deleteModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                {itemToDelete}
                            </div>
                        </div>
                        {/* <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                            <button onClick={() => deleteFocusBoard(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                        </div> */}
                        {
                            deleting === false
                                ?
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                    <button onClick={() => deleteFocusBoard(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                                </div>
                                :
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="text-white bg-gray-800 inline-flex space-x-1 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        <span>Deleting</span>
                                        <DotDotDot className="pl-2" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/focus-boards'} />

            <div className='bg-gray-50' >

                <SideBar selectedSideBarItem="focus-boards" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-20 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold lg:block`}>Focus Boards - Compare Against Competitors or Niche</h5>
                            </div>

                            <div className="hidden absolute right-0 lg:flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <GoToYoutubeBtn /> */}
                                <Avatar />
                            </div>
                        </div>

                        <div className="mt-20 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8">
                            <div className="w-full h-full mx-auto">
                                <div className="flex flex-col items-center">

                                    <Tooltip content='Click to add thumbnails from Youtube' position='bottom'>
                                        <div className="w-72 h-52 mr-2">
                                            {
                                                accessLevel === 1 || accessLevel === 2
                                                    ?
                                                    <div className="text-center mb-2 p-0 text-sm text-gray-500">Create New Focus Board</div>
                                                    :
                                                    <div className="flex flex-col items-center justify-center text-center mb-2 p-0 text-sm text-gray-500">
                                                        <FaCrown className='h-4 w-4 text-yellow-400' />
                                                        <span>Create New Focus Board</span>
                                                    </div>
                                            }

                                            <div onClick={() => { openSelectModal() }} className="flex flex-wrap items-center justify-center p-3 border border-blue-300 rounded-md w-72 h-32 bg-white hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer">
                                                <IoAddSharp className='w-8 h-8 m-0 p-0' />
                                            </div>
                                        </div>
                                    </Tooltip>
                                    {/* <div className="mr-6 ml-4 border-l"></div> */}
                                    {/* 
                                    <Tooltip content='Click to Try this' position='bottom'>
                                        <div className="w-52 h-52 mr-5">
                                            <div onClick={() => { openSelectModal() }} className="flex flex-wrap mt-7 items-center justify-center p-3 border border-blue-300 rounded-md w-52 h-32 bg-white hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer">
                                                <IoAddSharp className='w-8 h-8 m-0 p-0' />
                                            </div>
                                            <div className="text-center mb-2 p-0 text-sm text-gray-500">Demo</div>
                                        </div>
                                    </Tooltip>

                                    <Tooltip content='Click to Try this' position='bottom'>
                                        <div className="w-52 h-52 mr-2">
                                            <div onClick={() => { openSelectModal() }} className="flex flex-wrap mt-7 items-center justify-center p-3 border border-blue-300 rounded-md w-52 h-32 bg-white hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer">
                                                <IoAddSharp className='w-8 h-8 m-0 p-0' />
                                            </div>
                                            <div className="text-center mb-2 p-0 text-sm text-gray-500">Demo 2</div>
                                        </div>
                                    </Tooltip> */}


                                    {/* <div className="flex flex-col items-center justify-center gap-x-4">
                                        <div className="text-center mb-2 p-0 text-sm font-medium text-gray-500">Demo</div>
                                        {demoBoards?.map((doc, i) => (
                                            <div key={doc.id} className="">
                                                <div className="w-52 h-auto relative mb-5">
                                                    <div onClick={() => cloneDemoFocusBoard()} className="border border-gray-300 rounded-md w-52 h-32 bg-gray-200 cursor-pointer overflow-hidden group">
                                                        <FocusBoardCollageImages itemsData={doc.items} />
                                                        <div className="rounded-md absolute top-0 left-0 flex flex-wrap items-center justify-center w-52 h-32 bg-black opacity-0 group-hover:opacity-50 group-active:opacity-80 duration-200">
                                                        </div>
                                                        <div className="rounded-md absolute top-0 left-0 flex flex-wrap items-center justify-center w-52 h-32 opacity-0 group-hover:opacity-100 group-active:opacity-80 duration-200">
                                                            <IoAddSharp className='w-8 h-8 m-0 p-0 text-white' />
                                                        </div>
                                                    </div>
                                                    <div className="w-full inline-flex">
                                                        <div className="w-full group">
                                                            <div className="m-0 p-0 mt-2 pl-1 w-full cursor-pointer line-clamp-1 leading-tight lg:text-[14px] md:text-md font-medium">
                                                                {doc.name}
                                                            </div>
                                                            <div className="m-0 p-0 pl-1 mt-0 inline-block text-gray-600 text-center text-[11px] text-grey-300">
                                                                {`${doc.items.length} thumbnails`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div> */}
                                </div>
                                <div className="">
                                    <div className=" lg:border-b pb-2 text-neutral-500">
                                        Your Focus Boards
                                    </div>
                                    <div className="pt-5">
                                        {boards.length === 0
                                            ?
                                            <>
                                                {
                                                    loaded === true
                                                        ?
                                                        <div className='pb-2 pt-5 text-neutral-500 flex items-center justify-center'>
                                                            <div className="text-sm">No Focus Board created. Hit + to Create New.</div>
                                                        </div>
                                                        :
                                                        <div className="flex flex-wrap gap-x-4">
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className="flex flex-wrap gap-x-4 gap-y-1">
                                                {boards?.map((doc, i) => (
                                                    typeof doc.items !== 'undefined' && doc.items.length > 0 ? (
                                                        <div key={doc.id} className="">
                                                            <div className="w-52 h-auto relative mb-5">
                                                                <div onClick={() => { goToFocusBoardDetails(doc.id) }} className="border border-gray-300 rounded-md w-52 h-32 bg-gray-200 cursor-pointer overflow-hidden group">
                                                                    {/* <div className="m-3 w-7 h-7 text-center rounded-full bg-white border-gray-300 border-2 absolute">{doc.items.length}</div> */}
                                                                    <FocusBoardCollageImages itemsData={doc.items} />
                                                                    <div className="rounded-md absolute top-0 left-0 w-52 h-32 bg-black opacity-0 group-hover:opacity-50 group-active:opacity-80 duration-200">
                                                                    </div>
                                                                </div>
                                                                <div className="w-full inline-flex">
                                                                    <div onClick={() => { goToFocusBoardDetails(doc.id) }} className="w-10/12 group">
                                                                        <div className="m-0 p-0 mt-2 pl-1 w-full cursor-pointer line-clamp-1 leading-tight lg:text-[14px] md:text-md font-medium">
                                                                            {doc.name}
                                                                        </div>
                                                                        <div className="m-0 p-0 pl-1 mt-0 inline-block text-gray-600 text-center text-[11px] text-grey-300">
                                                                            {`${doc.items.length} thumbnails`}
                                                                        </div>
                                                                    </div>
                                                                    <div onClick={() => { openDeleteModal(doc.name, doc.id) }} className="w-2/12 m-1 mr-0 p-2 hover:bg-neutral-300 rounded-lg h-8 hover:cursor-pointer">
                                                                        <TfiTrash className='ml-[1px]' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FocusBoards