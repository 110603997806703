import React, { useState, useEffect, useRef } from 'react';

const ShimmerPlaceholder = ({ className }) => (
    <div className={`animate-pulse bg-gradient-to-r from-gray-300 via-gray-100 to-gray-300 bg-[length:400%_100%] absolute inset-0 ${className}`}></div>
);

const CachedImage = ({ src, alt, containerClassName = '', imageClassName = '' }) => {
    const [imageSrc, setImageSrc] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        let observer;
        let didCancel = false;

        const loadImage = (imageUrl) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageUrl;
                img.onload = () => resolve(imageUrl);
                img.onerror = (err) => reject(err);
            });
        };

        const fetchImage = async () => {
            if (didCancel) return;

            const cachedSrc = localStorage.getItem(src);
            if (cachedSrc) {
                setImageSrc(cachedSrc);
                setIsLoaded(true);
                return;
            }

            try {
                const imageUrl = await loadImage(src);
                localStorage.setItem(src, imageUrl);
                if (!didCancel) {
                    setImageSrc(imageUrl);
                    setIsLoaded(true);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };

        if (imgRef.current) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            fetchImage();
                            observer.unobserve(entry.target);
                        }
                    });
                },
                {
                    threshold: 0.1,
                    rootMargin: '0px 0px 200px 0px'
                }
            );
            observer.observe(imgRef.current);
        }

        return () => {
            didCancel = true;
            if (observer && imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [src]);

    return (
        <div className={`relative overflow-hidden ${containerClassName}`}>
            {!isLoaded && <ShimmerPlaceholder className={imageClassName} />}
            <img
                ref={imgRef}
                src={imageSrc}
                alt={alt}
                className={`w-full h-full object-cover transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'
                    } ${imageClassName}`}
            />
        </div>
    );
};

export default CachedImage;