import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import CountUp from 'react-countup';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from "./ProgressProvider";
import 'react-circular-progressbar/dist/styles.css';
import { MdLightMode, MdOutlineFaceRetouchingNatural } from 'react-icons/md'
import { BiLaugh } from 'react-icons/bi'
import { HiOutlineMenuAlt2, HiOutlineBell } from 'react-icons/hi'
import { BsFillPuzzleFill, BsLightningChargeFill, BsLightningFill } from 'react-icons/bs'
import { RiFocus2Line } from 'react-icons/ri'
import { AiFillHeart } from 'react-icons/ai'
import { CgMergeVertical } from 'react-icons/cg'
import { RxEyeOpen } from 'react-icons/rx'
import { Tab } from '@headlessui/react';
import { MdOutlineArrowBack } from "react-icons/md";
import { db, storage } from '../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, getFirestore, updateDoc, onSnapshot, setDoc, getDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'
import Lottie from "lottie-react";
import image_analyze from "../../assets/animations/image_analyze.json";
import Hint from './Hint';

const ImageAnalysisModalST = ({ isVisible, onClose, thumbnail_id }) => {
    // console.log(isVisible)
    // console.log(onClose)s

    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [thumbnailData, setThumbnailData] = useState(null);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const savedUser = JSON.parse(localStorage.getItem("currentUser"));
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [subjectBrightnessTitle, setImageBrightnessTitle] = useState('');
    const [imageBrightnessAdvisory, setImageBrightnessAdvisory] = useState('');
    const [subjectBrightnessAdvisory, setSubjectBrightnessAdvisory] = useState('');
    const [overallBrightnessAdvisory, setOverallBrightnessAdvisory] = useState('');
    const [overallBrightnessHint, setOverallBrightnessHint] = useState('');

    const [personCount, setPersonCount] = useState(0);
    const [brightnessScore, setBrightnessScore] = useState(0);
    const [subjectBrightnessScore, setSubjectBrightnessScore] = useState(0);
    const [subjectBrightnessRatio, setSubjectBrightnessRatio] = useState(0);
    const [imageBrightness, setImagebrightness] = useState(0);
    const [brightnessAccent, setBrightnessAccent] = useState('');
    const [brightnessBg, setBrightnessBg] = useState('');


    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [curiosity, setCuriosity] = useState('');
    const [curiosityText, setCuriosityText] = useState('');
    const [curiosityAccent, setCuriosityAccent] = useState('');
    const [curiosityBg, setCuriosityBg] = useState('');

    const [overallCuriosityHint, setOverallCuriosityHint] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [cohesion, setCohesion] = useState('');
    const [cohesionText, setCohesionText] = useState('');
    const [cohesionAccent, setCohesionAccent] = useState('');
    const [cohesionBg, setCohesionBg] = useState('');

    const [overallCohesionHint, setOverallCohesionHint] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [titleLengthScore, setTitleLengthScore] = useState(0);
    const [titleChars, setTitleChars] = useState(0);
    const [titleMarker, setTitleMarker] = useState(0);

    const [titleAdvisory, setTitleAdvisory] = useState('');
    const [overallTitleHint, setOverallTitleHint] = useState('');

    const [titleLengthAccent, setTitleLengthAccent] = useState('');
    const [titleLengthBg, setTitleLengthBg] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [emotionScore, setEmotionScore] = useState(0);
    const [facesNumber, setFacesNumber] = useState(0);

    const [emotionTitle, setEmotionTitle] = useState('');
    const [emotionAdvisory, setEmotionAdvisory] = useState('');
    const [overallEmotionHint, setOverallEmotionHint] = useState('');

    const [emotionAccent, setEmotionAccent] = useState('');
    const [emotionBg, setEmotionBg] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    const [overallScore, setOverallScore] = useState(0);
    const [mainScoreAccent, setMainScoreAccent] = useState('');
    const [mainScoreBg, setMainScoreBg] = useState('');
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    let overallBrightness = '';
    let colors_n_similarity = [];
    let has_bold_colors = false;
    let face_data_summary = [];
    let faces_detected = 0;
    let labels_data = [];
    let objects_data = [];
    let text_on_thumbnail = [];
    let resolution_height = 0;
    let resolution_width = 0;
    let titleT = '';
    let thumbnailT = '';
    let personBrightnessRatio = 0;
    let objectBrightnessRatio = 0;

    const [title, setTitle] = useState('');
    const [thumbnail, setThumbnail] = useState('');

    const progressValue = 70;
    const percentage = 40;

    const lowAccent = '#dc2626';
    const lowBg = '#fecaca'; //'#FFAFA2';
    const midAccent = '#EDBB00'; //'#D3B54B';
    const midBg = '#FFF3A1';
    // const midBg = '#F2CA14';
    const highAccent = '#00AD02';
    const highBg = '#bbf7d0';  //'#B7FFB9'; //'#BBFFB0'; 

    // const lowAccent = '#B82022';
    // const lowBg = '#FEBCB7'; //'#FFAFA2';
    // const midAccent = '#9D6F2B'; //'#D3B54B';
    // const midBg = '#FFD869';
    // const highAccent = '#005803';
    // const highBg = '#74D665';  //'#B7FFB9'; //'#BBFFB0'; 


    const computeData = (data) => {
        setThumbnail(data.thumbnail);
        setTitle(data.title);
        // setImagebrightness(Math.round((data.average_brightness / 255) * 100));

        setCuriosity(data.stats_curiosity);
        setCuriosityText(data.stats_curiosity_text);
        setCohesion(data.stats_cohesion);
        setCohesionText(data.stats_cohesion_text);

        overallBrightness = data.average_brightness;
        colors_n_similarity = data.colors_n_similarity;
        has_bold_colors = data.has_bold_colors;
        face_data_summary = data.face_data_summary;
        faces_detected = data.faces_detected;
        labels_data = data.labels_data;
        objects_data = data.objects_data;
        text_on_thumbnail = data.text_on_thumbnail;
        resolution_height = data.resolution_height;
        resolution_width = data.resolution_width;
        titleT = data.title;
        thumbnailT = data.thumbnail;

        let subjectBrightnessPercentage = 0;
        // let overallBrightnessScore = 0;
        let overallVibranceScore = 0;
        let overallClarityScore = 0;
        let overallTitleLengthScore = 0;
        let overallEmotionScore = 0;
        let overallMainScore = 0;

        // ===========================Brightness============================
        // =================================================================

        // Do subject brightness ratio and advisory
        // const personObjects = objects_data.filter(obj => obj.name === 'Person');
        // const otherObjects = objects_data.filter(obj => obj.name !== 'Person');
        // let imageBrightnessPercentage = Math.round((overallBrightness / 255) * 100)


        // setPersonCount(personObjects.length);
        // if (personObjects.length > 0) {
        //     console.log(personObjects.length)

        //     const personWithHighestBrightness = personObjects.reduce((maxObj, obj) => {
        //         return obj.averageBrightness > maxObj.averageBrightness ? obj : maxObj;
        //     });

        //     let upperLimit = 1.2;
        //     if (imageBrightnessPercentage < 30) {
        //         upperLimit = 1.4;
        //     }

        //     personBrightnessRatio = personWithHighestBrightness.averageBrightness / overallBrightness;
        //     setSubjectBrightnessRatio(Number(personBrightnessRatio.toFixed(2)));

        //     subjectBrightnessPercentage = Math.round((personWithHighestBrightness.averageBrightness / 255) * 100)

        //     if (imageBrightnessPercentage < 30) {
        //         setImageBrightnessAdvisory('Your thumbnail image is too dark. Brighten up your subjects, objects, background & overall image to stand out.')
        //     } else if (imageBrightnessPercentage > 29 && imageBrightnessPercentage < 50) {
        //         setImageBrightnessAdvisory('Average thumbnail brightness. If possible, further increase brightness of your subjects or background to stand out more.')
        //     } else {
        //         setImageBrightnessAdvisory('Great! Your thumbnail is generally bright enough. Well done!')
        //     }

        //     if (imageBrightnessPercentage < 50) {
        //         if (subjectBrightnessPercentage > imageBrightnessPercentage) {
        //             setSubjectBrightnessAdvisory(`There is at least one subject(person) in the image that stands out. Well done!`);
        //         } else if (subjectBrightnessPercentage === imageBrightnessPercentage) {
        //             setSubjectBrightnessAdvisory('A large part of the subject blends into the background. Consider using brightly colored clothes to stand out from the background.')
        //         } else {
        //             setSubjectBrightnessAdvisory(`One or more subjects(person) in the image are dark, compared to the background. Increase subject brightness.`);
        //             if (personBrightnessRatio < 1.0) {
        //                 setOverallBrightnessHint('Dark clothes can lower brightness of your subjects. Use brighter or brightly colored clothes to make your subject(s) stand out.')
        //             } else {
        //                 setOverallBrightnessHint('Slightly brightening a dark background or subject or changing to bright clothes can increase image brightness and visibility')
        //             }
        //         }
        //     } else if (imageBrightnessPercentage > 60) {
        //         if (subjectBrightnessPercentage > 44) {
        //             setSubjectBrightnessAdvisory(`There is at least one subject(person) in the image that stands out. Keep it up! `);
        //         } else {
        //             setSubjectBrightnessAdvisory(`One or more subjects(person) in the image is too dark, when compared to the background. Increase subject brightness.`);
        //             setOverallBrightnessHint('Dark clothes can lower brightness of your subjects. Use brighter or brightly colored clothes to make your subject(s) stand out.')
        //         }
        //     } else {
        //         if (personBrightnessRatio > 0.89) {
        //             setSubjectBrightnessAdvisory(`Subject(person) brightness is acceptable or optimial. Feel free to increase it as you see fit.`);
        //         } else {
        //             setSubjectBrightnessAdvisory(`One or more subjects(person) in the image is too dark, when compared to the background. Increase subject brightness.`);
        //             setOverallBrightnessHint('Increasing the brightness of the whole image can make it stand out further.')
        //         }

        //     }


        //     overallBrightnessScore = (4 / 11) * subjectBrightnessPercentage + (7 / 11) * imageBrightnessPercentage

        //     //Reward for distinct subjects
        //     if (personBrightnessRatio > 0.99 && personBrightnessRatio < 1.2) {
        //         overallBrightnessScore = (7 / 11) * subjectBrightnessPercentage + (4 / 11) * imageBrightnessPercentage
        //         overallBrightnessScore = overallBrightnessScore + 10;
        //     }

        //     //Reward for very distinct subjects
        //     if (personBrightnessRatio > 1.19) {
        //         overallBrightnessScore = (7 / 11) * subjectBrightnessPercentage + (4 / 11) * imageBrightnessPercentage
        //         overallBrightnessScore = overallBrightnessScore + 15;
        //     }

        //     //Penalty for too dark subjects
        //     if (personBrightnessRatio < 0.76) {
        //         overallBrightnessScore = overallBrightnessScore - 25;
        //     }

        //     // Normalize to range between 5 and 95
        //     overallBrightnessScore = Math.max(5, Math.min(95, overallBrightnessScore));

        //     if (overallBrightnessScore < 30) {
        //         setBrightnessAccent(lowAccent);
        //         setBrightnessBg(lowBg);
        //     } else if (overallBrightnessScore > 29 && overallBrightnessScore < 60) {
        //         setOverallBrightnessHint('Aim for an overall brightness of 60% and above for the best results. Bright and colorful thumbnails tend to catch more attention on Youtube.')
        //         setBrightnessAccent(midAccent);
        //         setBrightnessBg(midBg);
        //     } else {
        //         setBrightnessAccent(highAccent);
        //         setBrightnessBg(highBg);
        //     }
        //     console.log(subjectBrightnessPercentage)
        //     console.log(overallBrightnessScore)
        //     console.log(personBrightnessRatio)


        //     //set colors
        //     setBrightnessScore(overallBrightnessScore);

        // } else if (otherObjects.length > 0) {
        //     const objectWithHighestBrightness = otherObjects.reduce((maxObj, obj) => {
        //         return obj.averageBrightness > maxObj.averageBrightness ? obj : maxObj;
        //     });

        //     let upperLimit = 1.2;
        //     let lowerLimit = 0.9;
        //     if (imageBrightnessPercentage < 30) {
        //         upperLimit = 1.4;
        //     }

        //     if (imageBrightnessPercentage > 65) {
        //         lowerLimit = 0.1;
        //         upperLimit = 1;
        //     }

        //     let objectName = objectWithHighestBrightness.name;
        //     objectBrightnessRatio = objectWithHighestBrightness.averageBrightness / overallBrightness;

        //     setSubjectBrightnessRatio(Number(objectBrightnessRatio.toFixed(2)));
        //     subjectBrightnessPercentage = (((objectBrightnessRatio - lowerLimit) / (upperLimit - lowerLimit)) * 100).toFixed(2);

        //     if (objectWithHighestBrightness.averageBrightness > overallBrightness) {
        //         setImageBrightnessTitle('Distinct Subject')
        //         setSubjectBrightnessAdvisory(`There is at least one object[${objectName}] in the image that stands out. Well done!`);
        //     } else {
        //         if (imageBrightnessPercentage > 65) {
        //             setImageBrightnessTitle('Good Contrast')
        //             setSubjectBrightnessAdvisory(`Good contrast between the elements in your thumbnail. Good job!`);
        //             setOverallBrightnessHint('');
        //         } else {
        //             setImageBrightnessTitle('Dark')
        //             setSubjectBrightnessAdvisory(`One or more objects in the image is too dark, compared to the background. Increase subject brightness.`);
        //             setOverallBrightnessHint('White or Brightly colored objects stand out well in dark backgrounds. Brighten the main object in your image to draw attention to it.')
        //         }
        //     }

        //     if (imageBrightnessPercentage < 30) {
        //         setImageBrightnessTitle('Dark')
        //         setImageBrightnessAdvisory('Your thumbnail image is too dark. Brighten up your subjects, objects, background & overall image to stand out.')
        //     } else if (imageBrightnessPercentage > 29 && imageBrightnessPercentage < 50) {
        //         setImageBrightnessTitle('Average')
        //         setImageBrightnessAdvisory('Average thumbnail brightness. You can further increase brightness of your subjects or background to stand out more.')
        //     } else {
        //         setImageBrightnessTitle('Bright')
        //         setImageBrightnessAdvisory('Great! Your thumbnail is generally bright enough. Well done!')
        //     }

        //     let overallBrightnessScore = (2 / 7) * subjectBrightnessPercentage + (5 / 7) * imageBrightnessPercentage

        //     console.log(objectBrightnessRatio)
        //     console.log(subjectBrightnessPercentage)
        //     console.log(imageBrightnessPercentage)
        //     console.log(overallBrightnessScore)

        //     // Normalize to range between 5 and 95
        //     overallBrightnessScore = Math.max(5, Math.min(95, overallBrightnessScore));

        //     if (overallBrightnessScore < 30) {
        //         setBrightnessAccent(lowAccent);
        //         setBrightnessBg(lowBg);
        //     } else if (overallBrightnessScore > 29 && overallBrightnessScore < 50) {
        //         setBrightnessAccent(midAccent);
        //         setBrightnessBg(midBg);
        //     } else {
        //         setBrightnessAccent(highAccent);
        //         setBrightnessBg(highBg);
        //     }

        //     //set colors
        //     setBrightnessScore(overallBrightnessScore);
        // } else {
        //     // Normalize to range between 5 and 95
        //     let overallBrightnessScore = Math.max(5, Math.min(95, overallBrightness));

        //     if (overallBrightnessScore < 30) {
        //         setImageBrightnessTitle('Dark')
        //         setImageBrightnessAdvisory('Your thumbnail is too dark! It may not stand out on Youtube. Increase brightness.')
        //         setBrightnessAccent(lowAccent);
        //         setBrightnessBg(lowBg);
        //     } else if (overallBrightnessScore > 29 && overallBrightnessScore < 50) {
        //         setImageBrightnessTitle('Average')
        //         setImageBrightnessAdvisory('Average brighness! Your thumbnail may not stand out on Youtube. Increase brightness.')
        //         setBrightnessAccent(midAccent);
        //         setBrightnessBg(midBg);
        //     } else {
        //         setImageBrightnessTitle('Bright')
        //         setImageBrightnessAdvisory('Great! Your thumbnail is generally bright enough. Well done!')
        //         setBrightnessAccent(highAccent);
        //         setBrightnessBg(highBg);
        //     }

        //     setBrightnessScore(overallBrightnessScore);
        // }


        overallBrightness = (Math.round((data.average_brightness / 255) * 100));
        let overallBrightnessScore = Math.max(5, Math.min(95, overallBrightness + 10));
        setImagebrightness(overallBrightnessScore);

        console.log('overallBrightnessScore', overallBrightnessScore)

        if (overallBrightnessScore < 40) {
            setImageBrightnessTitle('Dark')
            setImageBrightnessAdvisory('Your thumbnail is too dark! It may not stand out on Youtube. Increase brightness.')
            setBrightnessAccent(lowAccent);
            setBrightnessBg(lowBg);
        } else if (overallBrightnessScore > 39 && overallBrightnessScore < 70) {
            setImageBrightnessTitle('Average')
            setImageBrightnessAdvisory('Average brighness! Your thumbnail may not stand out on Youtube. Increase brightness.')
            setBrightnessAccent(midAccent);
            setBrightnessBg(midBg);
        } else {
            setImageBrightnessTitle('Bright')
            setImageBrightnessAdvisory('Great! Your thumbnail is generally bright enough. Well done!')
            setBrightnessAccent(highAccent);
            setBrightnessBg(highBg);
        }


        // ==========================================================================        
        // ==================================Curiosity===============================


        let curiosityVar = data.stats_curiosity;
        let curiosityTextVar = data.stats_curiosity_text;
        let curiosityValue = leveltoPercent(curiosityVar);

        if (curiosityValue === 20) {
            setCuriosityAccent(lowAccent)
            setCuriosityBg(lowBg)
            setOverallCuriosityHint('Partly hidden or illegible text may cause the AI to provide inaccurate results. Fancy and obscured fonts may be hard to see even by viewers, making your thumbnail less catchy  ')
        }
        else if (curiosityValue === 50) {
            setCuriosityAccent(midAccent)
            setCuriosityBg(midBg)
            setOverallCuriosityHint('Partly hidden or illegible text may cause the AI to provide inaccurate results. Fancy and obscured fonts may be hard to see even by viewers, making your thumbnail less catchy  ')
        }
        else if (curiosityValue === 80) {
            setCuriosityAccent(highAccent)
            setCuriosityBg(highBg)
            setOverallCuriosityHint('')
        } else {
            setCuriosityAccent(lowAccent)
            setCuriosityBg(lowBg)
        }

        setCuriosity(data.stats_curiosity)
        setCuriosityText(data.stats_curiosity_text)

        console.log(curiosityVar, curiosityValue)


        // =========================================================================        
        // ==================================Cohesion===============================

        let cohesionVar = data.stats_cohesion;
        let cohesionTextVar = data.stats_cohesion_text;
        let cohesionValue = leveltoPercent(cohesionVar);

        console.log('cohesionVar', cohesionVar,);
        console.log('cohesionValue', cohesionValue,);

        if (cohesionValue === 20) {
            setCohesionAccent(lowAccent)
            setCohesionBg(lowBg)
            setOverallCohesionHint('Try to get your thumbnail and title to have the same idea. Conflicting ideas may confuse a viewer and lower its clickability.')
        }
        else if (cohesionValue === 50) {
            setCohesionAccent(midAccent)
            setCohesionBg(midBg)
            setOverallCohesionHint('Try to get your thumbnail and title to have the same idea. Conflicting ideas may confuse a viewer and lower its clickability.')
        }
        else if (cohesionValue === 80) {
            setCohesionAccent(highAccent)
            setCohesionBg(highBg)
        } else {
            setCohesionAccent(lowAccent)
            setCohesionBg(lowBg)
            setOverallCohesionHint('')
        }

        setCohesion(data.stats_cohesion)
        setCohesionText(data.stats_cohesion_text)

        console.log(cohesionVar, cohesionValue)

        // ============================================================================        
        // ===============================Text Length==================================

        let noTitleCharacters = titleT.length;
        setTitleChars(noTitleCharacters);

        if (noTitleCharacters < 56) {
            setTitleAdvisory("Perfect! Your title is under 55 characters. It won't get truncated.");
            setOverallTitleHint('');

            setTitleMarker(15);
            overallTitleLengthScore = 93;
            setTitleLengthScore(93)

            setTitleLengthAccent(highAccent);
            setTitleLengthBg(highBg);
        } else if (noTitleCharacters > 55 && noTitleCharacters < 70) {
            setTitleAdvisory('Not Ideal! Part of your title is likely to get cut off on some pages on Youtube.');
            setOverallTitleHint('Keep the length of your title to under 55 charcters to get your the title shown on all youtube pages.');

            setTitleMarker(noTitleCharacters);
            overallTitleLengthScore = 50;
            setTitleLengthScore(50);

            setTitleLengthAccent(midAccent);
            setTitleLengthBg(midBg);
        } else {
            setTitleAdvisory('Too Long! Your tile is too long and wordy and will get cut off on some pages on Youtube.');
            setOverallTitleHint('55 charcters is the sweet spot to get your full title shown on all youtube pages. If not, aim for 70.');

            setTitleMarker(93);
            overallTitleLengthScore = 15;
            setTitleLengthScore(15)

            setTitleLengthAccent(lowAccent);
            setTitleLengthBg(lowBg)
        }


        // ==========================================================================   
        // ==========================================================================        
        // ==================================Score===================================        
        // ==========================================================================        
        // ==========================================================================

        // //Darkess penalty
        // if (overallBrightnessScore < 50) {
        //     overallBrightnessScore = overallBrightnessScore - 45;
        // }

        overallMainScore =
            (overallBrightness
                + curiosityValue
                + cohesionValue
                + overallTitleLengthScore) / 4;
        setOverallScore(overallMainScore);

        console.log(Math.round(overallMainScore));

        if (overallMainScore < 40) {
            setMainScoreAccent(lowAccent);
            setMainScoreBg(lowBg);
        } else if (overallMainScore > 39 && overallMainScore < 70) {
            setMainScoreAccent(midAccent);
            setMainScoreBg(midBg);
        } else {
            setMainScoreAccent(highAccent);
            setMainScoreBg(highBg);
        }

        // =================================================================        
        // =================================================================

        setLoaded(true);
    }

    const leveltoPercent = (value) => {
        console.log(value)
        switch (value) {
            case 'low':
                return 20;
            case 'Low':
                return 20;
            case 'medium':
                return 50;
            case 'Medium':
                return 50;
            case 'mid':
                return 50;
            case 'Mid':
                return 50;
            case 'high':
                return 80;
            case 'High':
                return 80;
            default:
                return -1; // Handle invalid values as needed
        }
    };

    useEffect(() => {
        console.log(thumbnail_id);

        const getThumbnailData = async () => {
            console.log(thumbnail_id);

            const userThumbnailRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnail_id);

            try {
                const unsubscribe = onSnapshot(userThumbnailRef, (snapshot) => {
                    console.log(snapshot.data());

                    if (snapshot.exists()) {
                        const data = snapshot.data();

                        // Check if the specific fields you are waiting for exist in the document
                        if (data.objects_data && data.labels_data && data.text_on_thumbnail && data.average_brightness) {
                            setLoadingMessage('Analysing...');

                            if (data.stats_curiosity && data.stats_cohesion) {
                                setThumbnailData(data);
                                console.log(data);
                                computeData(data);
                                setLoaded(true);
                            } else {
                                if (data.stats_loading === false) {
                                    getAITnTtextEval(data.text_on_thumbnail[0], data.title, thumbnail_id)
                                }
                            }

                        } else {
                            setLoadingMessage('Finalizing...');
                        }
                    } else {
                        // Document does not exist
                        setErrorState(true);
                        navigate('/404');
                        toast("Document doesn't exist.", {
                            icon: ({ theme, type }) => (
                                <HiOutlineBell className="text-red-500 h-7 w-7" />
                            ),
                        });
                    }
                });
                // Unsubscribe from the snapshot listener if needed
                // unsubscribe();

            } catch (error) {
                setErrorState(true);
                toast("Error fetching document!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            }
        }

        getThumbnailData();
    }, [thumbnail_id]);


    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const getAITnTtextEval = async (textOnThumbnail, titleText, thumbnailId) => {
        const token = getCookie('TG_AT');

        console.log(token);
        if (textOnThumbnail !== '' && titleText !== '' && token !== null) {

            let url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/analyse-tt-texts/${mainUserId}`;

            const data = {
                thumbnail_text: textOnThumbnail,
                title_text: titleText,
                thumbnail_id: thumbnailId,
                user_id: mainUserId,
            };

            const headers = {
                headers: {
                    'Authorization': `${token}`,
                }
            };

            axios.post(url, data, headers)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.payload);
                        console.log('Saved in');
                    } else {
                        console.log('Error');
                    }
                })
                .catch((error) => {
                    console.error(error);

                });
        }
    }

    const handleModalClose = (e) => {
        if (e.target.id === "this-modal") {
            onClose();
        }
    }

    const modalClose = (e) => {
        onClose();
    }

    if (!isVisible) return null;

    return (
        <div id="this-modal" onClick={handleModalClose} className={`fixed z-20 inset-0  bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center`}>

            <div className="w-[1050px] h-[570px] flex flex-col bg-white border border-gray-400 rounded-lg p-3 px-6">

                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    <Tab.List className='relative w-full border-gray-200 text-gray-800 border-b text-sm mx-auto'>
                        <Tab className={({ selected }) =>
                            selected
                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                        >
                            <span className={`inline-flex p-3`}>
                                <p className='mr-3'>Thumbnail & Title Score</p>
                                {/* <span className={` text-gray-400 font-bold rounded-md px-2`} style={{ backgroundColor: `${mainScoreBg}`, color: `${mainScoreAccent}` }}>{Math.round(overallScore)}</span> */}
                            </span>
                        </Tab>
                        <button onClick={modalClose} type="button" className="absolute top-0 -right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </Tab.List>
                    <Tab.Panels className='w-full'>
                        <Tab.Panel className='w-full'>
                            {
                                loaded === true
                                    ?
                                    <div className="">
                                        <div className="fixed w-72 h-[500px] border-r">
                                            <div className="mr-3 mt-4">
                                                <div className="aspect-w-16 aspect-h-9">
                                                    <div className="aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg">
                                                        <img
                                                            src={thumbnail} alt=""
                                                            className="object-cover"
                                                        />
                                                    </div>
                                                    <div className='flex mt-3'>
                                                        <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-8 w-8 object-cover">
                                                            <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                                        </div>
                                                        <div className='flex-col w-9/12'>
                                                            <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[13px] mb:text-mb text-[#0f0f0f]'>{title}</p>
                                                            </div>
                                                            <div className=''>
                                                                <div className='inline-flex text-[12px] font-roboto lg:text-[12px] text-[#606060]'>
                                                                    <p className='font-[400]'>{channelDisplayName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fixed ml-72 w-52 h-[510px] ">
                                            <div className="absolute w-full flex flex-col justify-center items-center mt-7">
                                                <div className={`text-[52px] m-0 font-medium p-0 tracking-tighter`}
                                                    style={{ color: `${mainScoreAccent}` }}>
                                                    <CountUp duration={2.75} end={overallScore} />
                                                </div>
                                                <div className={`flex flex-col text-[11px] -mt-2 font-medium justify-center items-center`}
                                                    style={{ color: `${mainScoreAccent}` }}>
                                                    <p className="-m-1 p-0">
                                                        OVERALL
                                                    </p>
                                                    <p className="m-0 p-0">
                                                        SCORE
                                                    </p>
                                                </div>
                                            </div>

                                            <ProgressProvider valueStart={5} valueEnd={overallScore}>
                                                {overallScore => <CircularProgressbar className='w-32 h-32 mt-4'
                                                    value={overallScore}
                                                    background={true}
                                                    strokeWidth={5}
                                                    styles={buildStyles({
                                                        strokeLinecap: 'butt',
                                                        pathTransitionDuration: 3.55,
                                                        pathColor: mainScoreAccent,
                                                        trailColor: mainScoreBg,
                                                        backgroundColor: mainScoreBg,
                                                    })}
                                                />}
                                            </ProgressProvider>

                                            <div className="ml-5 mt-6">
                                                <div className="inline-flex w-full py-1" style={{}}>
                                                    <div className={` rounded-lg p-1 mr-[8px]`} style={{ backgroundColor: `${brightnessBg}` }}>
                                                        <MdLightMode className={` `} style={{ color: `${brightnessAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-xs text-[13px] 2xl:text-[14px] font-normal mt-[5px]'>Brightness</span>
                                                </div>
                                                <div className="inline-flex w-full py-1 ">
                                                    <div className={` rounded-lg p-1 mr-[8px]`} style={{ backgroundColor: `${curiosityBg}` }}>
                                                        <BsLightningFill className={` `} style={{ color: `${curiosityAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-xs text-[13px] 2xl:text-[14px] font-normal mt-[5px]'>Curiosity</span>
                                                </div>
                                                <div className="inline-flex w-full py-1 ">
                                                    <div className={`rounded-lg p-1 mr-[8px]`} style={{ backgroundColor: `${cohesionBg}` }}>
                                                        <BsFillPuzzleFill className={` `} style={{ color: `${cohesionAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-xs text-[13px] 2xl:text-[14px] font-normal mt-[5px]'>Clarity</span>
                                                </div>
                                                <div className="inline-flex w-full py-1 ">
                                                    <div className={`rounded-lg p-1 mr-[8px]`} style={{ backgroundColor: `${titleLengthBg}` }}>
                                                        <HiOutlineMenuAlt2 className={` `} style={{ color: `${titleLengthAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-xs text-[13px] 2xl:text-[14px] font-normal mt-[5px]'>Title Length</span>
                                                </div>
                                                {/* <div className="inline-flex w-full py-1 ">
                                                    <div className={`rounded-lg p-1 mr-[8px]`} style={{ backgroundColor: `${emotionBg}` }}>
                                                        <BiLaugh className={` `} style={{ color: `${emotionAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-xs text-[13px] 2xl:text-[14px] font-normal mt-[5px]'>Glance-ability</span>
                                                </div> */}
                                            </div>

                                        </div>
                                        <div className="fixed ml-[496px] h-[500px] w-[508px] overflow-scroll pt-4 space-y-3 pb-5">
                                            {/* Image Brightness */}
                                            <div className="overflow-hidden rounded-md border border-slate-300 mr-2 bg-white">
                                                <div className="bg-slate-50 inline-flex w-full px-4 py-[7px] border-b">
                                                    <div className={`rounded-md p-1 mr-2`} style={{ backgroundColor: `${brightnessBg}` }}>
                                                        <MdLightMode className={`text-sm`} style={{ color: `${brightnessAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-[12px] font-bold mt-[2px]'>Brightness</span>
                                                </div>
                                                <div className="text-[#4A4A4A] flex flex-col text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white">

                                                    <div className="text-[#4A4A4A] text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white p-4">
                                                        <div className="mt-2 inline-flex">
                                                            <div className="mr-2">
                                                                <h2 className='mb-3 text-5xl font-medium' style={{ color: `${brightnessAccent}` }}>
                                                                    {imageBrightness}%
                                                                </h2>
                                                            </div>
                                                            <div className="gap-y-0 col-span-8 flex flex-col">
                                                                <p className='text-base font-bold' style={{ color: `${brightnessAccent}` }}>image brightness</p>
                                                                {imageBrightnessAdvisory}
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <Hint hintText={overallBrightnessHint} />
                                                            {/* Hint:  */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Curiosity */}
                                            <div className="overflow-hidden rounded-md border bg-white border-slate-300 mr-2">
                                                <div className="bg-slate-50 inline-flex w-full px-4 py-[7px]  border-b">
                                                    <div className={`rounded-md p-1 mr-2`} style={{ backgroundColor: `${curiosityBg}` }}>
                                                        <BsLightningFill className={`text-sm`} style={{ color: `${curiosityAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-[12px] font-bold mt-[2px]'>Curiosity</span>
                                                </div>

                                                <div className="text-[#4A4A4A] flex flex-col text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white">

                                                    <div className="text-[#4A4A4A] text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white p-4">
                                                        <div className="inline-flex">
                                                            {/* <div className="mr-2">
                                                                <h2 className='mb-3 text-5xl font-medium' style={{ color: `${brightnessAccent}` }}>
                                                                    {curiosity}%
                                                                </h2>
                                                            </div> */}
                                                            <div className="gap-y-0 col-span-8 flex flex-col">
                                                                <h2 className='text-xl font-bold' style={{ color: `${curiosityAccent}` }}>{curiosity} curiosity</h2>
                                                                <p className='mt-2'>{curiosityText}</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <Hint hintText={overallCuriosityHint} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Clarity */}
                                            <div className="overflow-hidden rounded-md border border-slate-300 mr-2 bg-white">
                                                <div className="bg-slate-50 inline-flex w-full px-4 py-[7px]  border-b">
                                                    <div className={`rounded-md p-1 mr-2`} style={{ backgroundColor: `${cohesionBg}` }}>
                                                        <RiFocus2Line className={`text-sm`} style={{ color: `${cohesionAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-[12px] font-bold mt-[2px]'>Clarity</span>
                                                </div>
                                                <div className="text-[#4A4A4A] flex flex-col text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white">

                                                    <div className="text-[#4A4A4A] text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white p-4">
                                                        <div className="inline-flex">
                                                            {/* <div className="mr-2">
                                                                <h2 className='mb-3 text-5xl font-medium' style={{ color: `${brightnessAccent}` }}>
                                                                    {curiosity}%
                                                                </h2>
                                                            </div> */}
                                                            <div className="gap-y-0 col-span-8 flex flex-col">
                                                                <h2 className='text-xl font-bold' style={{ color: `${cohesionAccent}` }}>{cohesion} clarity</h2>
                                                                <p className='mt-2'>{cohesionText}</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <Hint hintText={overallCohesionHint} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            {/* Title Length */}
                                            <div className="overflow-hidden rounded-md border border-slate-300 mr-2 bg-white">
                                                <div className="bg-slate-50 inline-flex w-full px-4 py-[7px] border-b">
                                                    <div className={`rounded-md p-1 mr-2`} style={{ backgroundColor: `${titleLengthBg}` }}>
                                                        <HiOutlineMenuAlt2 className={`text-sm`} style={{ color: `${titleLengthAccent}` }} />
                                                    </div>
                                                    <span className='text-gray-800 text-[12px] font-bold mt-[2px]'>Title Length</span>
                                                </div>

                                                <div className="text-[#4A4A4A] text-[13px] 2xl:text-[14px] leading-[21px] tracking-[-0.015em] bg-white p-4">
                                                    <div className="mt-2 inline-flex">
                                                        <div className="mr-2">
                                                            <h2 className='mb-3 text-5xl font-medium' style={{ color: `${titleLengthAccent}` }}>
                                                                {titleChars}
                                                            </h2>
                                                        </div>
                                                        <div className="gap-y-0 col-span-8 flex flex-col">
                                                            <p className='text-base font-bold' style={{ color: `${titleLengthAccent}` }}>characters</p>
                                                            {titleAdvisory}
                                                        </div>
                                                    </div>

                                                    <Hint hintText={overallTitleHint} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    :
                                    <div className='w-full h-full flex flex-col items-center justify-center'>
                                        <div className="flex flex-col items-center justify-center mt-16">
                                            <Lottie className='w-32 h-32' animationData={image_analyze} loop={true} />
                                            <p>{loadingMessage}</p>
                                        </div>
                                    </div>
                            }

                        </Tab.Panel>

                        {/* <Tab.Panel className='w-full'>
                            <div className="w-full h-[510px] overflow-y-scroll">
                                <div className="w-full">
                                    <div className="mr-3 mt-4 w-56">
                                        <div className="aspect-w-16 aspect-h-9">
                                            <div className="aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg">
                                                <img
                                                    src="https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/viralThumbnails%2F0NXlpwKM4IieZ4bfOzE4-thumbnail.jpg?alt=media&token=698e22d0-52de-409b-b8fa-4aad46d9a53f" alt=""
                                                    className="object-cover"
                                                />
                                            </div>
                                            <div className='flex mt-3'>
                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-8 w-8 object-cover">
                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src="https://yt3.ggpht.com/ytc/AL5GRJVaP8qqhnaBvlgkQDRWDONrWTbpYSMOv7hwHI235w=s68-c-k-c0x00ffffff-no-rj" alt="" />
                                                </div>
                                                <div className='flex-col w-9/12'>
                                                    <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                        <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[12px] mb:text-mb text-[#0f0f0f]'>The new AirPods Pro | Quiet the noise | Apple</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="mr-3 mt-4 w-56">
                                        <div className="aspect-w-16 aspect-h-9">
                                            <div className="aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg">
                                                <img
                                                    src="https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/viralThumbnails%2F0NXlpwKM4IieZ4bfOzE4-thumbnail.jpg?alt=media&token=698e22d0-52de-409b-b8fa-4aad46d9a53f" alt=""
                                                    className="object-cover"
                                                />
                                            </div>
                                            <div className='flex mt-3'>
                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-8 w-8 object-cover">
                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src="https://yt3.ggpht.com/ytc/AL5GRJVaP8qqhnaBvlgkQDRWDONrWTbpYSMOv7hwHI235w=s68-c-k-c0x00ffffff-no-rj" alt="" />
                                                </div>
                                                <div className='flex-col w-9/12'>
                                                    <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                        <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[12px] mb:text-mb text-[#0f0f0f]'>The new AirPods Pro | Quiet the noise | Apple</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="mr-3 mt-4 w-56 pb-5">
                                        <div className="aspect-w-16 aspect-h-9">
                                            <div className="aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg">
                                                <img
                                                    src="https://firebasestorage.googleapis.com/v0/b/thumbnailgeek.appspot.com/o/viralThumbnails%2F0NXlpwKM4IieZ4bfOzE4-thumbnail.jpg?alt=media&token=698e22d0-52de-409b-b8fa-4aad46d9a53f" alt=""
                                                    className="object-cover"
                                                />
                                            </div>
                                            <div className='flex mt-3'>
                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-8 w-8 object-cover">
                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src="https://yt3.ggpht.com/ytc/AL5GRJVaP8qqhnaBvlgkQDRWDONrWTbpYSMOv7hwHI235w=s68-c-k-c0x00ffffff-no-rj" alt="" />
                                                </div>
                                                <div className='flex-col w-9/12'>
                                                    <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                        <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[12px] mb:text-mb text-[#0f0f0f]'>The new AirPods Pro | Quiet the noise | Apple</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel> */}
                    </Tab.Panels>
                </Tab.Group>


            </div>
        </div>
    )
}

export default ImageAnalysisModalST