import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, FieldPath } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s",
  // authDomain: "thumbnailgeek.firebaseapp.com",
  authDomain: "app.thumbnailgeek.com",
  projectId: "thumbnailgeek",
  storageBucket: "thumbnailgeek.appspot.com",
  messagingSenderId: "571556548969",
  appId: "1:571556548969:web:0298db1ef9935a9274edf8",
  measurementId: "G-RHT8S683K6",
};

// const app = initializeApp(firebaseConfig);
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage();
export const storageStoryboards = getStorage(app, "gs://thumbnailgeek-storyboards"); // Second bucket
export const authentication = getAuth(app);
export const firestoreFieldPath = FieldPath;

// import firebase from 'firebase/app';
// import 'firebase/auth';

// const firebaseConfig = {
//     apiKey: "AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s",
//     authDomain: "thumbnailgeek.firebaseapp.com",
//     projectId: "thumbnailgeek",
//     storageBucket: "thumbnailgeek.appspot.com",
//     messagingSenderId: "571556548969",
//     appId: "1:571556548969:web:0298db1ef9935a9274edf8",
//     measurementId: "G-RHT8S683K6"
// };

// firebase.initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// export default firebase.auth();
