import React from 'react';

const RotatingDial = ({ fromValue, value, color }) => {
  let finalValue = +value + 1; // Adjust final value as before
  let initialFinalValue = +fromValue + 1; // Convert fromValue to a usable number for initial angle calculation
  let fromAngle = (initialFinalValue / 100) * 240 - 120; // Calculate starting angle based on fromValue
  let toAngle = (finalValue / 100) * 240 - 120; // Calculate ending angle based on value

  // Unique animation name based on value and fromValue to ensure the animation is correctly reapplied
  const animationName = `rotate-dial-${fromValue}-to-${finalValue}`;

  const dialStyle = {
    animation: `${animationName} 2.75s ease-in-out forwards`,
    // animationDelay: '400ms',
    transformOrigin: 'center bottom',
    width: '16px',
    height: '78px',
    background: `linear-gradient(to top, rgba(0,0,0,0) 0%, ${color} 100%)`,
    clipPath: 'polygon(25% 25%, 75% 25%, 100% 100%, 0% 100%)',
    transform: `rotate(${fromAngle}deg)`, // Initial rotation set to fromAngle
  };

  // Dynamic keyframes with unique name based on fromValue and value
  const dynamicAnimation = `
    @keyframes ${animationName} {
      from {
        transform: rotate(${fromAngle}deg);
      }
      to {
        transform: rotate(${toAngle}deg);
      }
    }
  `;

  return (
    <>
      <style>{dynamicAnimation}</style>
      <div className={`dial bg-gray-400`} style={dialStyle}></div>
    </>
  );
};

export default RotatingDial;
