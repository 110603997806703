import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import axios from 'axios';
import { toast } from 'react-toastify';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { HiOutlineMenuAlt2, HiOutlineCheckCircle, HiOutlineExclamation, HiOutlineEmojiHappy, HiRefresh } from "react-icons/hi";
import { FiClipboard, FiArchive, FiBold } from "react-icons/fi";
import { FaBold, FaPencilAlt, FaCrown } from 'react-icons/fa'
import { SlMagicWand, SlRefresh, SlEqualizer, SlPuzzle } from 'react-icons/sl'
import Lottie from "lottie-react";
import { Tab } from '@headlessui/react';
import Avatar from '../../components/Avatar';
import computing from "../../../assets/animations/computing.json";
import Tooltip from '../../components/Tooltip';
import { getFunctions, httpsCallable } from "firebase/functions";
import { TfiAngleDown, TfiAngleLeft, TfiAngleRight, TfiAngleUp, TfiControlRecord, TfiExchangeVertical, TfiText } from 'react-icons/tfi';
import { RxLetterCaseLowercase, RxLetterCaseUppercase } from 'react-icons/rx';
import { IoTextOutline } from 'react-icons/io5';
import BlockPopupPro from '../../components/BlockPopupPro';
import { MdOutlineArrowUpward } from 'react-icons/md';
import { BiPencil } from 'react-icons/bi';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import { GiIdea, GiProcessor } from 'react-icons/gi';
import FeedbackBanner from '../../components/FeedbackBanner';
import { BsStar, BsStars } from 'react-icons/bs';

const EditableTitle = ({ initialTitle, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(initialTitle);

    const removeQuotes = (originalText) => {
        return originalText?.replace(/^"|"$/g, '');
    };

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
            onSave(title);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        onSave(title);
    };

    useEffect(() => {
        let newTitle = removeQuotes(initialTitle);
        setTitle(newTitle);
    }, [initialTitle]);

    return (
        <div className="w-full flex items-start justify-start">
            {isEditing ? (
                <input
                    type="text"
                    value={title}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    className=" text-[15px] text-gray-700 px-2 w-full border-b border-gray-300 focus:outline-none focus:border-blue-500 cursor-pointer hover:bg-gray-100 font-semibold font-roboto  duration-200 leading-0 line-clamp-2"
                    autoFocus
                />
            ) : (
                <Tooltip content="Click to Edit Title">
                    <div
                        onClick={handleClick}
                        className=" text-[15px] text-gray-700 cursor-pointer hover:bg-gray-100 font-semibold font-roboto  duration-200 leading-0 line-clamp-2"
                    >
                        {title}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const TitleBuilder = () => {
    const navigate = useNavigate()
    const functions = getFunctions();
    const generateCuriosityTitles = httpsCallable(functions, 'generateCuriosityTitles');
    const generateEmojiTitles = httpsCallable(functions, 'generateEmojiTitles');
    const generateRewordTitles = httpsCallable(functions, 'generateRewordTitles');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const { accessLevel, aiCreditsLeft, premiumStatus } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [refineText, setRefineText] = useState('');
    const [refining, setRefining] = useState(false);
    const [refined, setRefined] = useState(false);
    const [refinedTitles, setRefinedTitles] = useState([]);

    const [mainText, setMainText] = useState('');
    const [specificA, setSpecificA] = useState('');
    const [specificB, setSpecificB] = useState('');
    const [tone, setTone] = useState("Happy");
    const optionsT = ["Happy", "Sad", "Angry", "Surprised"];
    const [activeBtn, setActiveBtn] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [generatedTitles, setGeneratedTitles] = useState([]);
    const [generatedStep2, setGeneratedStep2] = useState([]);
    const [titlesArray, setTitlesArray] = useState([]);
    const [refineType, setRefineType] = useState('refine');

    const [isActiveT, setIsActiveT] = useState(false);
    const [radioValue, setRadioValue] = useState(false)

    const [blockPopup, setBlockPopup] = useState(false)
    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)

    const [genTextControls, setGenTextControls] = useState(false);
    const [refTextControls, setRefTextControls] = useState(false);
    const [generatedIndex, setGeneratedIndex] = useState(0);
    const [refinedIndex, setRefinedIndex] = useState(0);

    const [refinedRandomIndex, setRefinedRandomIndex] = useState(0);
    const [refinedMockupActivated, setRefinedMockupActivated] = useState(false)
    const [refinedThumbText, setRefinedThumbText] = useState('')
    const [refinedTitleText, setRefinedTitleText] = useState('')

    const [generatedRandomIndex, setGeneratedRandomIndex] = useState(0);
    const [generatedMockupActivated, setGeneratedMockupActivated] = useState(false)
    const [generatedThumbText, setGeneratedThumbText] = useState('')
    const [generatedTitleText, setGeneratedTitleText] = useState('')

    const [refinedRandomTextTransform, setRefinedTextTransform] = useState('');
    const [generatedRandomTextTransform, setGeneratedTextTransform] = useState('');

    const [selectedIdeas, setSelectedIdeas] = useState('');
    const [step2, setStep2] = useState(false);
    const [step2Generating, setStep2Generating] = useState(false);
    const [originalIdeas, setOriginalIdeas] = useState([]);

    const titleRandomize = () => {
        const newIndex = Math.floor(Math.random() * 2) + 0;
        setGeneratedRandomIndex(newIndex);
    };

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const visualizeOnMockupRefined = (text, i) => {
        setRefTextControls(false);
        titleRandomize();

        text = text.replace('"', '')
            .replace('"', '').replace('1. ', '').replace('2. ', '').replace('3. ', '').replace('4. ', '').replace('5. ', '')
            .replace('1.', '').replace('2.', '').replace('3.', '').replace('4.', '').replace('5.', '')
            .replace('1) ', '').replace('2) ', '').replace('3) ', '').replace('4) ', '').replace('5) ', '')

        const splitText = text.match(/(.*?[:;!?])\s*(.*)/);
        if (splitText) {

            const firstPart = splitText[1].replace(/[:;]/, '').trim();
            const secondPart = splitText[2].replace(/[:;]/, '').trim();
            if (firstPart.length <= secondPart.length) {
                setRefinedThumbText(firstPart);
                setRefinedTitleText(secondPart);
            } else {
                setRefinedThumbText(secondPart);
                setRefinedTitleText(firstPart);
            }
            setRefTextControls(true)
        } else {
            setRefTextControls(false)
            setRefinedThumbText('');
            setRefinedTitleText(text.trim());
        }
        setRefinedIndex(i);
        setRefinedMockupActivated(true);
    }

    const visualizeOnMockupGenerated = (text, i) => {
        setGenTextControls(false);
        titleRandomize();

        text = text.replace('"', '')
            .replace('"', '').replace('1. ', '').replace('2. ', '').replace('3. ', '').replace('4. ', '').replace('5. ', '')
            .replace('1.', '').replace('2.', '').replace('3.', '').replace('4.', '').replace('5.', '')
            .replace('1) ', '').replace('2) ', '').replace('3) ', '').replace('4) ', '').replace('5) ', '')

        const splitText = text.match(/(.*?[:;!?])\s*(.*)/);
        if (splitText) {
            const firstPart = splitText[1].replace(/[:;]/, '').trim();
            const secondPart = splitText[2].replace(/[:;]/, '').trim();
            if (firstPart.length <= secondPart.length) {
                setGeneratedThumbText(firstPart);
                setGeneratedTitleText(secondPart);
            } else {
                setGeneratedThumbText(secondPart);
                setGeneratedTitleText(firstPart);
            }
            setGenTextControls(true)
        } else {
            setGenTextControls(false)
            setGeneratedThumbText('');
            setGeneratedTitleText(text.trim());
        }
        setGeneratedIndex(i);
        setGeneratedMockupActivated(true);
    }

    const moveRefinedThumbText = (position) => {
        if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            setRefinedRandomIndex(position);
        } else {
            setBlockPopup(true);
        }
    }

    const moveGeneratedThumbText = (position) => {
        if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            setGeneratedRandomIndex(position);
        } else {
            setBlockPopup(true);
        }
    }

    // const swapRefinedThumbNTitleText = () => {
    //     // setGeneratedRandomIndex(position);
    // }

    // const swapGeneratedThumbNTitleText = () => {
    //     // setGeneratedRandomIndex(position);
    // }

    const transformRefinedThumbText = (position) => {
        //Bold
        if (position === 0) {
            setRefinedTextTransform('font-bold');
        }
        if (position === 1) {
            setRefinedTextTransform('font-light');
        }
        if (position === 2) {
            setRefinedTextTransform('uppercase');
        }
        if (position === 3) {
            setRefinedTextTransform('lowercase');
        }
        if (position === 4) {
            setRefinedTextTransform('font-normal');
        }
    }

    const transformGeneratedThumbText = (position) => {
        //Bold
        if (position === 0) {
            setGeneratedTextTransform('font-bold');
        }
        if (position === 1) {
            setGeneratedTextTransform('font-light');
        }
        if (position === 2) {
            setGeneratedTextTransform('uppercase');
        }
        if (position === 3) {
            setGeneratedTextTransform('lowercase');
        }
        if (position === 4) {
            setGeneratedTextTransform('font-normal');
        }
    }

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Enter') {
    //             if (refineType === 'improve') {
    //                 // Call the function for 'Refine & Improve'
    //                 handleRefineAndImprove();
    //             } else if (refineType === 'rewrite') {
    //                 // Call the function for 'Rewrite in Other Ways'
    //                 handleRewrite();
    //             }
    //         }
    //     };

    //     // Attach the event listener when the component mounts
    //     document.addEventListener('keypress', handleKeyPress);

    //     // Detach the event listener when the component unmounts
    //     return () => {
    //         document.removeEventListener('keypress', handleKeyPress);
    //     };
    // }, [refineType]);

    const improveTitle = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // // //console.log(refineText);

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {

            if (refineText.trimStart().trimEnd() !== '' && refineText !== null && token !== null) {
                setRefining(true);

                setRefineType('REFINED');

                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/optimize-ai-title/${mainUserId}`;

                const data = {
                    prompt: refineText,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        if (response.status === 200) {
                            setRefining(false);
                            setRefined(true);
                            // //console.log(response.data.payload);
                            // var resultText = response.data.payload;

                            const titles = response.data.payload.titles;
                            setRefinedTitles(titles);
                            visualizeOnMockupRefined(titles[0].title, 0);
                            // //console.log(titles);
                        } else {
                            setRefining(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setRefining(false);

                        if (error.response) {
                            // //console.log('Data:', error.response.data);
                            // //console.log('Status:', error.response.status);
                            // //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            }
            else {
                toast("Fields empty. Enter the necessary text.", {
                    icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                });
            }

        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }
    }

    const rewordTitle = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // // //console.log(token);

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (refineText.trimStart().trimEnd() !== '' && refineText !== null && token !== null) {
                setRefining(true);

                setRefineType('REWRITTEN');

                // //console.log(refineText)
                // //console.log(refineType)

                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/reword-ai-title/${mainUserId}`;

                const data = {
                    prompt: refineText,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        if (response.status === 200) {
                            setRefining(false);
                            setRefined(true);
                            // //console.log(response.data.payload);
                            // var resultText = response.data.payload;

                            const titles = response.data.payload.titles;
                            setRefinedTitles(titles);
                            visualizeOnMockupRefined(titles[0].title, 0);
                            // //console.log(titles);
                        } else {
                            setRefining(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setRefining(false);

                        if (error.response) {
                            // //console.log('Data:', error.response.data);
                            // //console.log('Status:', error.response.status);
                            // //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            }
            else {
                toast("Fields empty. Enter the necessary text.", {
                    icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                });
            }

        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }
    }

    //Groq Llama
    // const rewordTitle = async (e) => {
    //     e.preventDefault();

    //     if (aiCreditsLeft > 4) {
    //         if (refineText.trimStart().trimEnd() !== '' && refineText !== null) {
    //             setRefining(true);

    //             setRefineType('REWRITTEN');
    //             try {
    //                 const response = await generateRewordTitles({ prompt: refineText });
    //                 // //console.log("Success:", response);
    //                 if (response && response.data.payload.titles) {
    //                     setRefining(false);
    //                     setRefined(true);
    //                     // // //console.log(response.data.payload);
    //                     const titles = response.data.payload.titles;
    //                     setRefinedTitles(titles);
    //                     visualizeOnMockupRefined(titles[0].title, 0);
    //                     // //console.log(titles);
    //                 } else {
    //                     toast.error('Something went wrong. Please try again later.', {
    //                         position: "bottom-center",
    //                         autoClose: 7000,
    //                     });
    //                 }
    //             } catch (error) {
    //                 setRefining(false);
    //                 toast.error('Something went wrong. Please try again later.', {
    //                     position: "bottom-center",
    //                     autoClose: 2000,
    //                 });
    //             }
    //         }
    //         else {
    //             toast("Fields empty. Enter the necessary text.", {
    //                 icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
    //             });
    //         }
    //     } else {
    //         toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
    //             position: "top-right",
    //             autoClose: 7000,
    //         });
    //     }
    // }

    const curiosityTitle = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // // //console.log(token);

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (refineText.trimStart().trimEnd() !== '' && refineText !== null && token !== null) {
                setRefining(true);

                setRefineType('CURIOSITY DRIVEN');

                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/curiosity-ai-title/${mainUserId}`;

                const data = {
                    prompt: refineText,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        if (response.status === 200) {
                            setRefining(false);
                            setRefined(true);
                            // //console.log(response.data.payload);
                            // var resultText = response.data.payload;

                            const titles = response.data.payload.titles;
                            setRefinedTitles(titles);
                            visualizeOnMockupRefined(titles[0].title, 0);
                            // //console.log(titles);
                        } else {
                            setRefining(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setRefining(false);

                        if (error.response) {
                            // //console.log('Data:', error.response.data);
                            // //console.log('Status:', error.response.status);
                            // //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            }
            else {
                toast("Fields empty. Enter the necessary text.", {
                    icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                });
            }
        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }
    }

    // const curiosityTitle = async (e) => {
    //     e.preventDefault();

    //     if (aiCreditsLeft > 4) {
    //         if (refineText.trimStart().trimEnd() !== '' && refineText !== null) {
    //             setRefining(true);

    //             setRefineType('CURIOSITY DRIVEN');
    //             try {
    //                 const response = await generateCuriosityTitles({ prompt: refineText });
    //                 // //console.log("Success:", response);
    //                 if (response && response.data.payload.titles) {
    //                     setRefining(false);
    //                     setRefined(true);
    //                     // // //console.log(response.data.payload);
    //                     const titles = response.data.payload.titles;
    //                     setRefinedTitles(titles);
    //                     visualizeOnMockupRefined(titles[0].title, 0);
    //                     // //console.log(titles);
    //                 } else {
    //                     toast.error('Something went wrong. Please try again later.', {
    //                         position: "bottom-center",
    //                         autoClose: 7000,
    //                     });
    //                 }
    //             } catch (error) {
    //                 setRefining(false);
    //                 toast.error('Something went wrong. Please try again later.', {
    //                     position: "bottom-center",
    //                     autoClose: 2000,
    //                 });
    //             }
    //             // axios.post(url, data, headers)
    //             //     .then((response) => {
    //             //         if (response.status === 200) {
    //             //             setRefining(false);
    //             //             setRefined(true);
    //             //             // //console.log(response.data.payload);
    //             //             // var resultText = response.data.payload;

    //             //             const titles = response.data.payload.titles;
    //             //             setRefinedTitles(titles);
    //             //             visualizeOnMockupRefined(titles[0].title, 0);
    //             //             // //console.log(titles);
    //             //         } else {
    //             //             setRefining(false);
    //             //             toast.error('Something went wrong. Please try again later.', {
    //             //                 position: "bottom-center",
    //             //                 autoClose: 2000,
    //             //             });
    //             //         }
    //             //     })
    //             //     .catch((error) => {
    //             //         console.error(error);
    //             //         setRefining(false);

    //             //         if (error.response) {
    //             //             // //console.log('Data:', error.response.data);
    //             //             // //console.log('Status:', error.response.status);
    //             //             // //console.log('Headers:', error.response.headers);

    //             //             if (error.response.data.payload === "1494") {
    //             //                 toast.error(`${error.response.data.status}`, {
    //             //                     position: "bottom-center",
    //             //                     autoClose: 7000,
    //             //                 });
    //             //             } else {
    //             //                 toast.error('Something went wrong. Please try again later.', {
    //             //                     position: "bottom-center",
    //             //                     autoClose: 7000,
    //             //                 });
    //             //             }

    //             //         } else {
    //             //             toast.error('Something went wrong. Please try again later.', {
    //             //                 position: "bottom-center",
    //             //                 autoClose: 7000,
    //             //             });
    //             //         }
    //             //     });
    //         }
    //         else {
    //             toast("Fields empty. Enter the necessary text.", {
    //                 icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
    //             });
    //         }
    //     } else {
    //         // if (premiumStatus === 'TRIALING') {
    //         //     setCreditBlockPopupTrial(true);
    //         // } else {
    //         //     setCreditBlockPopup(true);
    //         // }

    //         toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
    //             position: "top-right",
    //             autoClose: 7000,
    //         });
    //     }
    // }

    //Groq Llama

    const emojiTitle = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // //console.log(token);


        if (aiCreditsLeft > 9) {
            if (accessLevel === 5 || premiumStatus === 'TRIALING') {
                toast.error(<>Feature not available in Trial<br />Upgrade to access this feature.</>, {
                    position: "top-right",
                    autoClose: 7000,
                });
            } else {
                if (refineText.trimStart().trimEnd() !== '' && refineText !== null && token !== null) {
                    setRefining(true);

                    setRefineType('EMOJI');

                    const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/emoji-ai-title/${mainUserId}`;

                    const data = {
                        prompt: refineText,
                    };

                    const headers = {
                        headers: {
                            'Authorization': `${token}`,
                        }
                    };

                    axios.post(url, data, headers)
                        .then((response) => {
                            if (response.status === 200) {
                                setRefining(false);
                                setRefined(true);
                                // //console.log(response.data.payload);
                                // var resultText = response.data.payload;

                                const titles = response.data.payload.titles;
                                setRefinedTitles(titles);
                                visualizeOnMockupRefined(titles[0].title, 0);
                                // //console.log(titles);
                            } else {
                                setRefining(false);
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 2000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setRefining(false);

                            if (error.response) {
                                // //console.log('Data:', error.response.data);
                                // //console.log('Status:', error.response.status);
                                // //console.log('Headers:', error.response.headers);

                                if (error.response.data.payload === "1494") {
                                    toast.error(`${error.response.data.status}`, {
                                        position: "bottom-center",
                                        autoClose: 7000,
                                    });
                                } else {
                                    toast.error('Something went wrong. Please try again later.', {
                                        position: "bottom-center",
                                        autoClose: 7000,
                                    });
                                }

                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }
                        });
                }
                else {
                    toast("Fields empty. Enter the necessary text.", {
                        icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                    });
                }
            }
        } else {
            // if (premiumStatus === 'TRIALING') {
            //     setCreditBlockPopupTrial(true);
            // } else {
            //     setCreditBlockPopup(true);
            // }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }

    }

    // const emojiTitle = async (e) => {
    //     e.preventDefault();

    //     if (aiCreditsLeft > 4) {
    //         if (refineText.trimStart().trimEnd() !== '' && refineText !== null) {
    //             setRefining(true);

    //             setRefineType('EMOJI');
    //             try {
    //                 const response = await generateEmojiTitles({ prompt: refineText });
    //                 // //console.log("Success:", response);
    //                 if (response && response.data.payload.titles) {
    //                     setRefining(false);
    //                     setRefined(true);
    //                     // // //console.log(response.data.payload);
    //                     const titles = response.data.payload.titles;
    //                     setRefinedTitles(titles);
    //                     visualizeOnMockupRefined(titles[0].title, 0);
    //                     // //console.log(titles);
    //                 } else {
    //                     toast.error('Something went wrong. Please try again later.', {
    //                         position: "bottom-center",
    //                         autoClose: 7000,
    //                     });
    //                 }
    //             } catch (error) {
    //                 setRefining(false);
    //                 toast.error('Something went wrong. Please try again later.', {
    //                     position: "bottom-center",
    //                     autoClose: 2000,
    //                 });
    //             }
    //         }
    //         else {
    //             toast("Fields empty. Enter the necessary text.", {
    //                 icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
    //             });
    //         }
    //     } else {
    //         toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
    //             position: "top-right",
    //             autoClose: 7000,
    //         });
    //     }
    // }

    const buttonActive = () => {
        if (mainText !== '' && specificA !== '' && specificB !== '') {
            setActiveBtn(true);
        }
    }

    const generateAITitles = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (mainText.trimStart().trimEnd() !== '' && mainText !== null && token !== null) {

                setGenerating(true);
                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/generate-ai-titles/${mainUserId}`;
                // const promptText = `Generate a Youtube title that sounds persuasive and clickable using the following details. "${mainText.trimStart().trimEnd()}" is the what the video is about. "${specificA.trimStart().trimEnd()}" is one of the specific things that the video talks about. "${specificB.trimStart().trimEnd()}" is another of the specific thing that the video talks about. Use different tones. Refine the titles and make them as compelling as possible. Give 5 options. Do not repeat the same text in the options.`
                // const promptText = mainText;
                const data = {
                    prompt: mainText
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        if (response.status === 200) {
                            setGenerating(false);
                            setGenerated(true);
                            // //console.log(response.data.payload);
                            // var resultText = response.data.payload;

                            const titles = response.data.payload.titles;
                            setGeneratedTitles(titles);
                            visualizeOnMockupGenerated(titles[0].title, 0);
                            // //console.log(titles);

                            // // //console.log(titles);
                        } else {
                            setGenerating(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setGenerating(false);
                        if (error.response) {
                            // //console.log('Data:', error.response.data);
                            // //console.log('Status:', error.response.status);
                            // //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            } else {
                toast("Fields empty. Enter the necessary text.", {
                    icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                });
            }
        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }

    }

    const brainstorm1 = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');
        setGeneratedTitles('');
        setSelectedIdeas('');
        setStep2(false);
        setGeneratedStep2('');
        setStep2Generating(false);

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (mainText.trimStart().trimEnd() !== '' && mainText !== null && token !== null) {

                setGenerating(true);
                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/brainstorm-ai-titles-step-one/${mainUserId}`;
                // const promptText = `Generate a Youtube title that sounds persuasive and clickable using the following details. "${mainText.trimStart().trimEnd()}" is the what the video is about. "${specificA.trimStart().trimEnd()}" is one of the specific things that the video talks about. "${specificB.trimStart().trimEnd()}" is another of the specific thing that the video talks about. Use different tones. Refine the titles and make them as compelling as possible. Give 5 options. Do not repeat the same text in the options.`
                // const promptText = mainText;
                const data = {
                    prompt: mainText
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        if (response.status === 200) {
                            setGenerating(false);
                            setGenerated(true);
                            // //console.log(response.data.payload);
                            // var resultText = response.data.payload;

                            const ideas = response.data.payload.ideas;
                            setGeneratedTitles(ideas);
                            // visualizeOnMockupGenerated(titles[0].title, 0);
                            // //console.log(ideas);

                            // // //console.log(titles);
                        } else {
                            setGenerating(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setGenerating(false);
                        if (error.response) {
                            // //console.log('Data:', error.response.data);
                            // //console.log('Status:', error.response.status);
                            // //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            } else {
                toast("Fields empty. Enter the necessary text.", {
                    icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                });
            }
        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }

    }

    const brainstorm2 = async (e) => {
        e.preventDefault();

        if (selectedIdeas.length === 0) {
            toast.warn('Select up to 5 ideas.', {
                position: "top-right",
                autoClose: 7000,
            });
        } else {
            const token = getCookie('TG_AT');
            setStep2(true);
            setStep2Generating(true);
            // //console.log(selectedIdeas);

            // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            if (aiCreditsLeft > 4) {
                if (mainText.trimStart().trimEnd() !== '' && mainText !== null && token !== null) {

                    const ideas = selectedIdeas.join(', ');
                    const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/brainstorm-ai-titles-step-two/${mainUserId}`;
                    const data = {
                        prompt: mainText,
                        ideas: ideas
                    };

                    const headers = {
                        headers: {
                            'Authorization': `${token}`,
                        }
                    };

                    axios.post(url, data, headers)
                        .then((response) => {
                            if (response.status === 200) {
                                // setGenerating(false);
                                // setGenerated(true);
                                // //console.log(response.data.payload);
                                // var resultText = response.data.payload;

                                const ideas = response.data.payload.ideas;
                                visualizeOnMockupGenerated(ideas[0].idea, 0);
                                setGeneratedStep2(ideas);
                                setStep2Generating(false);
                                // //console.log(ideas);

                                // // //console.log(titles);
                            } else {
                                setGenerating(false);
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 2000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setGenerating(false);
                            if (error.response) {
                                // //console.log('Data:', error.response.data);
                                // //console.log('Status:', error.response.status);
                                // //console.log('Headers:', error.response.headers);

                                if (error.response.data.payload === "1494") {
                                    toast.error(`${error.response.data.status}`, {
                                        position: "bottom-center",
                                        autoClose: 7000,
                                    });
                                } else {
                                    toast.error('Something went wrong. Please try again later.', {
                                        position: "bottom-center",
                                        autoClose: 7000,
                                    });
                                }

                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }
                        });
                } else {
                    toast("Fields empty. Enter the necessary text.", {
                        icon: ({ theme, type }) => <HiOutlineExclamation className='text-red-500 h-7 w-7' />
                    });
                }
            } else {
                if (premiumStatus === 'TRIALING') {
                    setCreditBlockPopupTrial(true);
                } else {
                    setCreditBlockPopup(true);
                }

                toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
            // } else {
            //     setCreditBlockPopup(true);
            // }
        }
    }

    // useEffect(() => {
    //     // Check if selectedIdeas is different from originalIdeas and originalIdeas is not empty
    //     // if (originalIdeas.length > 0) {
    //     const sortedSelectedIdeas = selectedIdeas.slice().sort();
    //     const sortedOriginalIdeas = originalIdeas.slice().sort();
    //     if (originalIdeas.length > 0 && JSON.stringify(sortedSelectedIdeas) !== JSON.stringify(sortedOriginalIdeas)) {
    //         setStep2(true);
    //     } else {
    //         setStep2(false);
    //     }
    //     // }
    // }, [selectedIdeas, originalIdeas]);

    useEffect(() => {
        // Check if selectedIdeas and originalIdeas are arrays
        if (Array.isArray(selectedIdeas) && Array.isArray(originalIdeas)) {
            // Sort copies of the arrays for comparison
            const sortedSelectedIdeas = selectedIdeas.slice().sort();
            const sortedOriginalIdeas = originalIdeas.slice().sort();

            // Check if originalIdeas is not empty and if the sorted arrays are different
            if (originalIdeas.length > 0 && JSON.stringify(sortedSelectedIdeas) !== JSON.stringify(sortedOriginalIdeas)) {
                setStep2(true);
            } else {
                setStep2(false);
            }
        } else {
            // If selectedIdeas or originalIdeas are not arrays, setStep2 to false
            setStep2(false);
        }
    }, [selectedIdeas, originalIdeas]);

    const handleItemsClick = (idea, i) => {
        const selectedIndex = selectedIdeas.indexOf(idea);
        if (selectedIndex === -1 && selectedIdeas.length < 5) {
            setSelectedIdeas([...selectedIdeas, idea]);
        } else if (selectedIndex !== -1) {
            setSelectedIdeas(selectedIdeas.filter((_, index) => index !== selectedIndex));
        }
    };

    const copyTitleToClipBoard = (title) => {
        navigator.clipboard.writeText(title);
        toast.success('Copied to clipboard', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    useEffect(() => {
        document.title = 'Title Composer';
        if (mainUserId === null) {
            navigate('/login')
        }
    }, [])

    const handleSave = (newTitle) => handleEditTitle(newTitle);

    const handleEditTitle = useCallback(async (newTitle) => {
        if (selectedIndex === 0) {
            setRefinedTitleText(newTitle);
        } else {
            setGeneratedTitleText(newTitle);
        }
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Title Composer | ThumbnailGeek</title>
                <meta name="description" content="Title Composer | ThumbnailGeek" />
            </Helmet>

            {/* https://static.canva.com/web/images/5718ed57618dbb1bd6e2fab9ef023722.png */}

            <BlockPopupPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/title-builder'} />

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/testing-suite/upload'} />

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="title-composer" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24 ">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={` text-sm lg:text-xl text-gray-600 font-semibold inline-flex`}>Craft The Perfect Video Title.</h5>
                            </div>
                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 lg:mt-[28px] bg-gray-50">
                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                <Tab.List className='max-w-7xl w-full xl:max-w-6xl 2xl:max-w-7xl border-gray-200 bg-gray-50 mt-7 lg:mt-0 text-gray-800 border-b text-sm fixed z-20 r-0 mx-auto sm:pr-6 lg:pr-8 pt-5'>
                                    <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className={`inline-flex p-3`}>
                                            <SlRefresh className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Refine Title Idea</p>
                                        </span>
                                    </Tab>
                                    {/* <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className="inline-flex p-3">
                                            <SlMagicWand className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Generate A New Title</p>
                                        </span>
                                    </Tab> */}
                                    <Tab className={({ selected }) =>
                                        selected
                                            ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                            : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                    >
                                        <span className="inline-flex p-3">
                                            <SlMagicWand className='mt-1 mr-1.5' />
                                            <p className='hidden lg:block'>Brainstorm A Great Title</p>
                                        </span>
                                    </Tab>
                                </Tab.List>

                                <Tab.Panels className='max-w-7xl w-full pt-4 flex flex-wrap justify-start overflow-scroll min-h-screen'>

                                    {/* Refine A Title Idea */}
                                    <Tab.Panel className='w-full max-w-7xl 2xl:max-w-7xl pt-12 flex flex-wrap justify-start'>

                                        <div className="mt-5 w-full mx-1">
                                            <form>
                                                <div className="relative">
                                                    {
                                                        refined === true || refining === true
                                                            ?
                                                            <></>
                                                            :
                                                            <div className={`flex flex-col justify-center items-center text-center text-gray-800 w-full`}>
                                                                <div className="w-36 h-36 mt-7">
                                                                    <img src={require('../../../assets/svgs/ai_thinking.svg').default} alt='title idea' />
                                                                </div>
                                                                <span>
                                                                    Refine Your Title ideas into Persuasive Attention Grabbing titles
                                                                </span>
                                                            </div>
                                                    }

                                                    <input autoFocus onChange={(e) => setRefineText(e.target.value)} type="text"
                                                        className="p-4 bg-white text-gray-900 rounded-lg block 
                                                        w-full pl-4 pr-10 text-lg ring-1 mt-10"
                                                        placeholder="Enter a title you would like to refine." />

                                                    <div className="flex justify-center items-center pr-2 mt-8 inset-y-0 ">
                                                        {
                                                            refining === true
                                                                ?
                                                                <span className='ml-1 mt-6 font-normal text-gray-500'>Refining...</span>
                                                                :
                                                                <div className='space-x-2 flex flex-wrap items-center justify-center -mt-3'>

                                                                    {
                                                                        <button onClick={improveTitle} className={`${refineText === '' ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} mt-3 inline-flex rounded-lg  p-3 px-5 text-white`}>
                                                                            <SlRefresh className='h-4 w-4 -ml-1 mt-[2px]' />
                                                                            <span className='ml-1 text-sm inline-flex '>Refine <span className='hidden lg:flex'>&nbsp;& Improve</span></span>
                                                                        </button>
                                                                    }
                                                                    {
                                                                        <div className="relative">
                                                                            <p className="absolute text-[9px] ml-1 -top-1  text-green-600">IMPROVED</p>
                                                                            <button onClick={rewordTitle} className={`${refineText === '' ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} mt-3 inline-flex rounded-lg  p-3 px-5 text-white`}>

                                                                                <BiPencil className='h-4 w-4 -ml-1 mt-[2px]' />
                                                                                <span className='ml-1 text-sm inline-flex '>Rewrite <span className='hidden lg:flex'>&nbsp;in Other Ways</span></span>
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="relative">
                                                                            <p className="absolute text-[9px] ml-1 -top-1  text-green-600">IMPROVED</p>
                                                                            <button onClick={curiosityTitle} className={`${refineText === '' ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} mt-3 inline-flex rounded-lg  p-3 px-5 text-white`}>

                                                                                <MdOutlineArrowUpward className='h-4 w-4 -ml-1 mt-[2px]' />
                                                                                <span className='ml-1 text-sm inline-flex '><span className='hidden lg:flex'>Increase&nbsp;</span>Curiosity</span>
                                                                            </button>

                                                                        </div>

                                                                    }
                                                                    {
                                                                        <button onClick={emojiTitle} className={`${refineText === '' ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} mt-3 inline-flex rounded-lg  p-3 px-5 text-white`}>

                                                                            <HiOutlineEmojiHappy className='h-4 w-4 -ml-1 mt-[2px]' />
                                                                            <span className='ml-1 text-sm inline-flex '><span className='hidden lg:flex'>Modify with&nbsp;</span>Emoji</span>
                                                                        </button>
                                                                    }
                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                            </form>

                                            {selectedIndex === 0 &&
                                                <>
                                                    {
                                                        refining === true
                                                            ?
                                                            <div className="mt-2">
                                                                <div className='w-full flex flex-cols items-center justify-center'>
                                                                    <div className=' w-60 h-60'>
                                                                        <Lottie animationData={computing} loop={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    (refinedTitles.length > 0 && refining === false) &&
                                                                    <div className='inline-flex bg-gray-50 mt-12 mb-12 w-full'>
                                                                        <div className='flex flex-col w-2/3 pr-5'>

                                                                            <h3 className='mb-8 pb-2 border-b ml-2 text-sm uppercase font-semibold text-gray-700'>🚀{` `} Top {refineType} Title Suggestions</h3>

                                                                            {
                                                                                refinedTitles?.map((titles, i) => (
                                                                                    titles.title != ''
                                                                                        ?
                                                                                        <div onClick={() => visualizeOnMockupRefined(titles.title, i)} key={i} className={`hover:cursor-pointer inline-flex mb-2 p-2 rounded-lg 
                                                                                        ${refinedIndex === i ? 'bg-gray-200' : 'hover:bg-gray-100'} text-sm justify-between`}>
                                                                                            <p className=' text-gray-800'>
                                                                                                {titles.title}
                                                                                                {/* {titles.title = titles.title.replace('"', '')
                                                                                                    .replace('"', '').replace('1. ', '').replace('2. ', '').replace('3. ', '').replace('4. ', '').replace('5. ', '')
                                                                                                    .replace('1.', '').replace('2.', '').replace('3.', '').replace('4.', '').replace('5.', '')
                                                                                                    .replace('1) ', '').replace('2) ', '').replace('3) ', '').replace('4) ', '').replace('5) ', '')
                                                                                                } */}
                                                                                            </p>
                                                                                            <div className='inline-flex  mr-1 h-[24px]'>
                                                                                                <Tooltip content="Copy" position="top">
                                                                                                    <button onClick={() => copyTitleToClipBoard(titles.title)} className=' border text-xs border-gray-500 hover:border-gray-600 active:bg-gray-300 rounded-md'>
                                                                                                        <FiClipboard className='text-gray-500 hover:text-gray-600  p-1 w-[22px] h-[22px]' />
                                                                                                    </button>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''
                                                                                ))
                                                                            }

                                                                            <div className='text-sm text-gray-400 font-normal text-center mt-12'>
                                                                                Not satisfied with the results? Hit button again for better results.
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            refinedMockupActivated === true && refined === true
                                                                                ?
                                                                                <div className='pl-3 w-1/3'>

                                                                                    <h3 className='mb-8 pb-2 border-b ml-2 text-sm uppercase font-semibold text-gray-700'>🎨 Preview</h3>

                                                                                    <div className="relative w-64 2xl:w-72 mx-2 group mb-5">
                                                                                        <div className=" text-center flex flex-wrap items-center justify-center
                                                                                            hover:bg-gray-200 active:bg-gray-300 rounded-lg w-full h-[145px] 
                                                                                            2xl:h-[162px] bg-gray-200 duration-200 text-sm font-medium">
                                                                                            {refinedRandomIndex === 0 && <><p className={`absolute top-5 ${refinedRandomTextTransform}`}> {refinedThumbText}</p></>}
                                                                                            {refinedRandomIndex === 1 && <><p className={`absolute bottom-4 ${refinedRandomTextTransform}`}> {refinedThumbText}</p></>}
                                                                                            {refinedRandomIndex === 2 && <><p className={`absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ${refinedRandomTextTransform}`}> {refinedThumbText}</p></>}
                                                                                            {refinedRandomIndex === 3 && <><p className={`absolute left-6 text-left ${refinedRandomTextTransform}`}> {refinedThumbText}</p></>}
                                                                                            {refinedRandomIndex === 4 && <><p className={`absolute right-6 text-right ${refinedRandomTextTransform}`}> {refinedThumbText}</p></>}
                                                                                        </div>

                                                                                        <div className="flex pb-5">
                                                                                            <div className="shrink-0 mt-3 mr-6 md:mr-2 xl:mr-1 h-9 w-9 rounded-full  duration-200">
                                                                                                <img src={channelPhotoUrl} alt='image' className='w-full h-full rounded-full object-cover' />
                                                                                            </div>
                                                                                            <div className="w-[80%] mt-3 ml-1 ">
                                                                                                {/* <div className="group-hover:cursor-pointer 
                                                                                                p-0 text-[14px] font-semibold font-roboto 
                                                                                                w-full rounded-[5px] duration-200 leading-0 line-clamp-2">
                                                                                                    <span> {refinedTitleText}</span>
                                                                                                </div> */}
                                                                                                <EditableTitle
                                                                                                    initialTitle={refinedTitleText}
                                                                                                    onSave={handleSave}
                                                                                                />

                                                                                                <div className="group-hover:cursor-pointer mt-1 p-0 text-[12px]
                                                                                                    rounded-[5px] duration-200 font-medium font-roboto text-gray-600">
                                                                                                    <span> {channelDisplayName} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <button onClick={() => copyTitleToClipBoard(refinedTitleText)} className='absolute right-0 bottom-1 inline-flex items-center justify-center text-gray-500 hover:text-gray-600 border text-xs border-gray-500 hover:border-gray-600 active:bg-gray-300 rounded-md'>
                                                                                            <FiClipboard className='text-gray-500 hover:text-gray-600 p-1 w-[22px] h-[22px]' />
                                                                                        </button>

                                                                                    </div>


                                                                                    {
                                                                                        refTextControls === true &&
                                                                                        <div className='p-2'>
                                                                                            {/* <div className="inline-flex mb-3 ">
                                                                                                <p className='inline-flex text-xs uppercase font-semibold'>
                                                                                                    🎛{' '}Thumbnail Text Controls
                                                                                                </p>
                                                                                            </div> */}


                                                                                            <div className='space-x-1 flex flex-row'>
                                                                                                <Tooltip content="Up" position="top">
                                                                                                    <button onClick={() => moveRefinedThumbText(0)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleUp /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Down" position="top">
                                                                                                    <button onClick={() => moveRefinedThumbText(1)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleDown /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Center" position="top">
                                                                                                    <button onClick={() => moveRefinedThumbText(2)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiControlRecord /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Left" position="top">
                                                                                                    <button onClick={() => moveRefinedThumbText(3)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleLeft /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Right" position="top">
                                                                                                    <button onClick={() => moveRefinedThumbText(4)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleRight /></button>
                                                                                                </Tooltip>
                                                                                                {/* <Tooltip content="Swap Titles" position="top">
                                                                                                <button onClick={() => swapGeneratedThumbNTitleText()} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiExchangeVertical /></button>
                                                                                            </Tooltip> */}
                                                                                            </div>
                                                                                            <div className='space-x-1 flex flex-row mt-2'>
                                                                                                <Tooltip content="Bold" position="top">
                                                                                                    <button onClick={() => transformRefinedThumbText(0)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><FaBold /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Thin" position="top">
                                                                                                    <button onClick={() => transformRefinedThumbText(1)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiText /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Uppercase" position="top">
                                                                                                    <button onClick={() => transformRefinedThumbText(2)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><RxLetterCaseUppercase /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Lowercase" position="top">
                                                                                                    <button onClick={() => transformRefinedThumbText(3)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><RxLetterCaseLowercase /></button>
                                                                                                </Tooltip>
                                                                                                <Tooltip content="Normal" position="top">
                                                                                                    <button onClick={() => transformRefinedThumbText(4)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><IoTextOutline /></button>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                }
                                                            </>
                                                    }
                                                </>
                                            }

                                        </div>

                                    </Tab.Panel>

                                    {/* Generate Fresh Title */}
                                    <Tab.Panel className='w-full max-w-7xl 2xl:max-w-7xl pt-12 flex flex-wrap justify-start px-1 py-3'>

                                        <div className="mt-5 w-full">
                                            <form onSubmit={brainstorm1}>
                                                <div className="relative">
                                                    {
                                                        generated === true || generating === true
                                                            ?
                                                            <></>
                                                            :
                                                            <div className={`flex flex-col justify-center items-center text-center text-gray-800 w-full`}>
                                                                <div className="w-36 h-36 mt-7">
                                                                    <img src={require('../../../assets/svgs/ai_brainstorming.svg').default} alt='tiyle idea' />
                                                                </div>
                                                                <span>
                                                                    Generate attention grabbing Titles for your videos
                                                                </span>
                                                            </div>
                                                        // <div className={`text-center mt-16 mb-8 text-gray-500`}>Generate Attention Grabbing Titles for your videos</div>
                                                    }

                                                    <div className="relative">
                                                        <input autoFocus onChange={(e) => setMainText(e.target.value)} type="text"
                                                            className="p-4 text-gray-900 rounded-lg block w-full pl-4 pr-64 text-lg ring-1 ring-blue-400 mt-8"
                                                            placeholder="Describe what happens in your video. Make it detailed." />


                                                        <div className={`col-span-1 absolute justify-center items-center pr-2 top-2 right-0 `}>
                                                            {
                                                                generating === true
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <div className='space-x-2'>
                                                                        <button type='submit' className={`${mainText === '' ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} 
                                                                            inline-flex rounded-lg p-3 px-5 text-white`}>
                                                                            <BsStars className='h-4 w-4 mt-[2px]' />
                                                                            <span className='ml-1 text-sm'>Start Brainstorming</span>
                                                                        </button>
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                            </form>

                                            <div className="mt-2 w-full">
                                                {
                                                    generating === true
                                                        ?
                                                        <div className="mt-16 w-full">
                                                            <div className='w-full flex flex-cols items-center justify-center'>
                                                                <div className=' w-60 h-60'>
                                                                    <Lottie animationData={computing} loop={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="relative w-full grid grid-cols-1 gap-4">

                                                            {selectedIndex === 1 &&
                                                                <>
                                                                    {
                                                                        (generatedTitles.length > 0 && generating === false) &&
                                                                        <div className='w-full bg-gray-50 mt-7 inline-flex'>
                                                                            <div className='w-full flex flex-col '>

                                                                                <div className='inline-flex items-center py-1.5 ml-0.5 justify-between text-sm font-semibold text-gray-700'>
                                                                                    <span className='font-semibold text-base  text-gray-700'>Choose up to 5 areas to focus on (fewer is better)</span>
                                                                                    <button onClick={brainstorm1} className='p-2 rounded-md hover:bg-blue-100'>
                                                                                        <HiRefresh className=' text-blue-600 w-5 h-5' />
                                                                                    </button>

                                                                                </div>

                                                                                <div className="flex flex-wrap p-3 pb-3 items-center justify-center ring-1 rounded-lg">
                                                                                    {
                                                                                        generatedTitles?.map((titles, i) => (
                                                                                            titles.idea != ''
                                                                                                ?
                                                                                                <div key={i} className='mr-2 my-1'>
                                                                                                    <input
                                                                                                        id={i}
                                                                                                        type="checkbox"
                                                                                                        checked={selectedIdeas.includes(titles.idea)}
                                                                                                        onChange={() => handleItemsClick(titles.idea, i)}
                                                                                                        disabled={selectedIdeas.length === 5 && !selectedIdeas.includes(titles.idea)}
                                                                                                        className="peer hidden"
                                                                                                    />
                                                                                                    <label htmlFor={i} className="border-gray-200 flex px-2 cursor-pointer justify-center items-center h-full w-full p-1 
                                                            peer-hover:bg-gray-200 peer-hover:rounded-md peer-checked:border peer-checked:rounded-md text-sm peer-checked:border-blue-500 
                                                            peer-checked:bg-gray-200">
                                                                                                        {titles.idea}
                                                                                                    </label>
                                                                                                </div>
                                                                                                : ''
                                                                                        ))
                                                                                    }
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    }
                                                                </>
                                                            }

                                                            {selectedIndex === 1 &&
                                                                <>
                                                                    {
                                                                        (generatedTitles.length > 0 && generating === false) &&
                                                                        <div className='w-full bg-gray-50 mt-7 inline-flex'>

                                                                            {
                                                                                step2 !== true
                                                                                    ?
                                                                                    <div className="relative w-full flex flex-col items-center justify-center">
                                                                                        {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                                            <div className="w-44 border-t border-blue-400" />
                                                                                        </div> */}
                                                                                        {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                                            <BsArrowRightShort className="absolute inset-0 flex items-center" />
                                                                                        </div> */}

                                                                                        <button onClick={brainstorm2} className='z-50 px-4 py-3 inline-flex bg-blue-500 hover:bg-blue-600 active:bg-blue-700 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm'>
                                                                                            <GiProcessor className='h-4 w-4 mt-[2px] mr-2 text-white' />
                                                                                            Expand these ideas
                                                                                        </button>
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            step2 !== true
                                                                                            &&
                                                                                            <div className="relative w-full flex flex-col items-center justify-center">
                                                                                                {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                                                <div className="w-44 border-t border-blue-400" />
                                                                                            </div> */}
                                                                                                {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                                                                <BsArrowRightShort className="absolute inset-0 flex items-center" />
                                                                                            </div> */}

                                                                                                <button onClick={brainstorm2} className='z-50 px-3 py-2 inline-flex ring-1 ring-blue-500 bg-blue-50 hover:bg-blue-100 active:bg-blue-200 hover:cursor-pointer text-gray-800 text-sm rounded-lg shadow-sm'>
                                                                                                    <GiProcessor className='h-4 w-4 mt-[2px] mr-2 text-gray-800' />
                                                                                                    Expand these ideas
                                                                                                </button>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            step2Generating === true && step2 === true
                                                                                                ?
                                                                                                <div className='w-full flex flex-cols items-center justify-center'>
                                                                                                    <GiProcessor className='w-16 h-16 animate-pulse text-blue-400' />
                                                                                                </div>
                                                                                                :
                                                                                                <div className="w-full grid grid-cols-2 gap-6">
                                                                                                    <div className='w-full flex flex-col'>
                                                                                                        {/* <div className='inline-flex items-center mb-3 p-2 border-b justify-between border-blue-400 text-sm font-semibold text-gray-700'>
                                                                                                            <span className='font-bold text-base text-gray-700 ml-2'>Title Ideas</span>\
                                                                                                    </div> */}
                                                                                                        <h3 className='mb-6 pb-2 border-b ml-2 text-sm uppercase font-semibold text-gray-700'>
                                                                                                            💡
                                                                                                            TITLE IDEAS BASED ON YOUR FOCUS AREAS</h3>
                                                                                                        <div className="flex flex-col px-3 pb-3">
                                                                                                            {
                                                                                                                generatedStep2?.map((titles, i) => (
                                                                                                                    titles.idea != ''
                                                                                                                        ?
                                                                                                                        <div onClick={() => visualizeOnMockupGenerated(titles.idea, i)} key={i} className={`hover:cursor-pointer inline-flex mb-2 p-2 rounded-lg ${generatedIndex === i ? 'bg-gray-200' : 'hover:bg-gray-100'} text-sm justify-between`}>
                                                                                                                            <p className=' text-gray-800'>
                                                                                                                                {titles.idea}
                                                                                                                            </p>
                                                                                                                            <div className='inline-flex  mr-1 h-[24px]'>
                                                                                                                                <Tooltip content="Copy" position="top">
                                                                                                                                    <button onClick={() => copyTitleToClipBoard(titles.idea)} className=' border text-xs border-gray-500 hover:border-gray-600 active:bg-gray-300 rounded-md'>
                                                                                                                                        <FiClipboard className='text-gray-500 hover:text-gray-600  p-1 w-[22px] h-[22px]' />
                                                                                                                                    </button>
                                                                                                                                </Tooltip>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        : ''
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {
                                                                                                        generatedMockupActivated === true
                                                                                                            ?
                                                                                                            <div className='pl-3 w-full'>

                                                                                                                <h3 className='mb-8 pb-2 border-b ml-2 text-sm uppercase font-semibold text-gray-700'>🖼 Preview</h3>

                                                                                                                <div className="relative w-72 2xl:w-80 mx-2 aspect-video group mb-5">
                                                                                                                    <div className={`aspect-video relative text-center flex flex-wrap items-center justify-center hover:bg-gray-200 active:bg-gray-300 
                                                                                                            rounded-lg  bg-gray-200 duration-200 text-sm font-normal text-gray-700`}>
                                                                                                                        {generatedRandomIndex === 0 && <><p className={`absolute top-5 ${generatedRandomTextTransform}`}> {generatedThumbText}</p></>}
                                                                                                                        {generatedRandomIndex === 1 && <><p className={`absolute bottom-4 ${generatedRandomTextTransform}`}> {generatedThumbText}</p></>}
                                                                                                                        {generatedRandomIndex === 2 && <><p className={`absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ${generatedRandomTextTransform}`}> {generatedThumbText}</p></>}
                                                                                                                        {generatedRandomIndex === 3 && <><p className={`absolute left-6 text-left ${generatedRandomTextTransform}`}> {generatedThumbText}</p></>}
                                                                                                                        {generatedRandomIndex === 4 && <><p className={`absolute right-6 text-right ${generatedRandomTextTransform}`}> {generatedThumbText}</p></>}
                                                                                                                    </div>

                                                                                                                    <div className="flex pb-5">
                                                                                                                        <div className="shrink-0 mt-3 mr-6 md:mr-2 xl:mr-1 h-10 w-10 rounded-full  duration-200">
                                                                                                                            <img src={channelPhotoUrl} alt='image' className='w-full h-full rounded-full object-cover' />
                                                                                                                        </div>
                                                                                                                        <div className="w-[80%] mt-3 ml-1">
                                                                                                                            {/* <div className="group-hover:cursor-pointer p-0 text-[15px] font-semibold font-roboto w-full rounded-[5px] duration-200 leading-0 line-clamp-2">
                                                                                                                                <span> {generatedTitleText}</span>
                                                                                                                            </div> */}
                                                                                                                            <EditableTitle
                                                                                                                                initialTitle={generatedTitleText}
                                                                                                                                onSave={handleSave}
                                                                                                                            />
                                                                                                                            <div className="group-hover:cursor-pointer mt-1 p-0 text-[12px] rounded-[5px] duration-200 font-medium font-roboto ">
                                                                                                                                <span> {channelDisplayName} </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>

                                                                                                                    <button onClick={() => copyTitleToClipBoard(generatedTitleText)} className='absolute right-0 bottom-1 inline-flex items-center justify-center text-gray-500 hover:text-gray-600 border text-xs border-gray-500 hover:border-gray-600 active:bg-gray-300 rounded-md'>
                                                                                                                        <FiClipboard className='text-gray-500 hover:text-gray-600 p-1 w-[22px] h-[22px]' />
                                                                                                                    </button>
                                                                                                                </div>

                                                                                                                {
                                                                                                                    genTextControls === true &&
                                                                                                                    <div className='p-2 w-full'>
                                                                                                                        {/* <p className='mb-3 text-xs uppercase font-medium'>🎛{' '}Thumbnail Text Controls</p> */}
                                                                                                                        <div className='space-x-1 flex flex-row w-full'>
                                                                                                                            <Tooltip content="Up" position="top">
                                                                                                                                <button onClick={() => moveGeneratedThumbText(0)} className='w-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleUp /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Down" position="top">
                                                                                                                                <button onClick={() => moveGeneratedThumbText(1)} className='w-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleDown /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Center" position="top">
                                                                                                                                <button onClick={() => moveGeneratedThumbText(2)} className='w-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiControlRecord /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Left" position="top">
                                                                                                                                <button onClick={() => moveGeneratedThumbText(3)} className='w-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleLeft /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Right" position="top">
                                                                                                                                <button onClick={() => moveGeneratedThumbText(4)} className='w-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiAngleRight /></button>
                                                                                                                            </Tooltip>

                                                                                                                        </div>
                                                                                                                        <div className='space-x-1 flex flex-row mt-2'>
                                                                                                                            <Tooltip content="Bold" position="top">
                                                                                                                                <button onClick={() => transformGeneratedThumbText(0)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><FaBold /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Thin" position="top">
                                                                                                                                <button onClick={() => transformGeneratedThumbText(1)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><TfiText /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Uppercase" position="top">
                                                                                                                                <button onClick={() => transformGeneratedThumbText(2)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><RxLetterCaseUppercase /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Lowercase" position="top">
                                                                                                                                <button onClick={() => transformGeneratedThumbText(3)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><RxLetterCaseLowercase /></button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip content="Normal" position="top">
                                                                                                                                <button onClick={() => transformGeneratedThumbText(4)} className='bg-gray-200 hover:bg-gray-300 active:bg-gray-400 px-4 py-1 rounded-md text-sm'><IoTextOutline /></button>
                                                                                                                            </Tooltip>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </div>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </div>

                                                                                        }
                                                                                    </>
                                                                            }

                                                                        </div>
                                                                    }
                                                                </>
                                                            }


                                                        </div>
                                                }
                                            </div>
                                        </div>

                                    </Tab.Panel>

                                </Tab.Panels>
                            </Tab.Group>
                        </div>

                    </div>
                </div>
            </div>

            {
                refinedTitles.length > 0 || generatedTitles.length > 0
                    ?
                    <FeedbackBanner currentPageUrl={'title-builder'} />
                    :
                    <></>
            }
        </>
    )
}

export default TitleBuilder