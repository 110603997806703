import React, { useState, useEffect } from 'react';

const PageOnboardingGifPopup = ({ heading, content, imageSrc, buttonText }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(true);
    const currentPage = window.location.pathname;

    useEffect(() => {
        const shownPages = JSON.parse(localStorage.getItem('onboardingPopupShown')) || [];

        if (!shownPages.includes(currentPage)) {
            setShowPopup(true);
        }
    }, [currentPage]);

    const handleClosePopup = () => {
        const shownPages = JSON.parse(localStorage.getItem('onboardingPopupShown')) || [];
        localStorage.setItem('onboardingPopupShown', JSON.stringify([...shownPages, currentPage]));
        setShowPopup(false);
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <>
            {showPopup && (
                <div id="onboarding-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <img
                                src={imageSrc}
                                alt="image"
                                className="w-full rounded-t-lg transition-opacity duration-1000"
                                onLoad={handleImageLoad}
                                style={{ display: imageLoaded ? 'block' : 'none' }}
                            />
                            {imageLoaded && (
                                <>
                                    <div className="pb-6 pt-3 px-6 border-t">
                                        <h2 className="text-left font-bold text-xl">{heading}</h2>
                                        <p className="text-[15px] text-left font-normal tracking-[-0.015em] leading-relaxed text-gray-800">
                                            {content}
                                        </p>
                                    </div>
                                    <div className="flex items-end justify-end pb-6 px-6 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                                        <button onClick={handleClosePopup} className="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                            {buttonText}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PageOnboardingGifPopup;