import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import ratingGirl from '../../assets/yt-rating-girl.png';
import { AiFillAccountBook, AiFillTool } from 'react-icons/ai';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { HiSparkles } from 'react-icons/hi';
import { IoSparklesOutline, IoSparklesSharp } from 'react-icons/io5';
import { MdCenterFocusStrong } from 'react-icons/md';
import { SlClose, SlMagicWand } from 'react-icons/sl';
import DotDotDot from './DotDotDot';

const BlockPopupPro = ({ isVisible, onClose, pageUrl }) => {
    const mainUserId = localStorage.getItem("mainUserId");
    const [showPopup, setShowPopup] = useState(true);
    const [redirecting, setRedirecting] = useState(false);

    const redirectToStripe = async () => {
        setRedirecting(true);
        const functions = getFunctions();
        const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        addMessage({
            returnUrl: window.location.origin + pageUrl,
            locale: 'auto',
            // configuration: 'bpc_1JSEAKHYgolSBA358VNoc2Hs',
        })
            .then((result) => {
                const data = result.data;
                setRedirecting(false);

                if (data.url) {
                    window.open(data.url, '_blank');
                }
            })
            .catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                // ...
                console.error(code);
                console.error(message);
                console.error(details);

                setRedirecting(false);
                window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ', '_blank');
            });

    }


    const modalClose = (e) => {
        if (e.target.id === "this-modal") {
            onClose();
        } else {
            onClose();
        }
    }

    if (!isVisible) return null;

    return (
        <>
            <div onClick={modalClose} id="this-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-4xl md:h-auto mx-0">
                    <div className="grid grid-cols-8 relative w-full h-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">

                        {/* <SlClose onClick={modalClose} className='absolute right-2 top-2 text-lg cursor-pointer' /> */}
                        <button onClick={modalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Left */}
                        <div className="col-span-3 pt-4 h-full justify-left lg:flex-none rounded-l-lg bg-blue-100">
                            <div className="pb-6 pt-3 px-6">
                                <h2 className="text-lg font-bold tracking-tight text-gray-900 sm:text-2xl">
                                    Upgrade to thumbnailGeek Pro
                                </h2>
                                <p className="text-[15px] mt-4 text-left font-light  text-gray-800">
                                    ThumbnailGeek Pro has all the tools you need to get clicks on your thumbnails
                                </p>
                                <h4 className="text-sm font-bold tracking-tight mt-5 text-gray-900 sm:text-sm">
                                    Here's what you get with thumbnailGeek Pro
                                </h4>
                                <ul className="mt-4 space-y-2 font-normal text-[12px] text-gray-800">
                                    <li className="inline-flex">
                                        <IoSparklesSharp className="text-lg mx-2" />
                                        <span>Advanced A.I. powered Thumbnail Analysis and Insights (Beta)</span>
                                    </li>
                                    <li className="inline-flex">
                                        <AiFillTool className="text-lg mx-2" />
                                        <span>Advanced tools for visualization, comparison and choosing your best thumbnail </span>
                                    </li>
                                    <li className="inline-flex">
                                        <SlMagicWand className="text-lg mx-2" />
                                        <span>Full AI access for title creation and thumbnail idea generation</span>
                                    </li>
                                    <li className="inline-flex">
                                        <MdCenterFocusStrong className="text-lg mx-2" />
                                        <span>Unlimited Focus Boards for In-depth competitor/niche thumbnail comparisons</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="px-6 w-full flex items-center justify-center pb-8 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                                {
                                    redirecting === true
                                        ?
                                        <button className="w-full h-11 text-white bg-gray-500 hover:bg-gray-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                            <DotDotDot />
                                        </button>
                                        :
                                        <button onClick={redirectToStripe} className="w-full h-11 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                            Upgrade
                                        </button>
                                }

                            </div>
                        </div>



                        {/* Right */}
                        <div className="col-span-5 lg:block flex items-center justify-center h-full">
                            <img src={ratingGirl} alt='image' className='w-[512px] mt-12 rounded-lg transition-opacity duration-1000' />
                        </div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default BlockPopupPro;
