import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import CountUp from 'react-countup';
import Cookies from 'js-cookie'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from "./ProgressProvider";
import 'react-circular-progressbar/dist/styles.css';
import { MdCenterFocusWeak, MdClose, MdLightMode, MdOutlineFaceRetouchingNatural } from 'react-icons/md'
import { BiBrain, BiLaugh } from 'react-icons/bi'
import { HiOutlineMenuAlt2, HiOutlineBell } from 'react-icons/hi'
import { BsFillPuzzleFill, BsLightningChargeFill, BsLightningFill } from 'react-icons/bs'
import { RiFocus2Line } from 'react-icons/ri'
import { AiFillApi, AiFillHeart } from 'react-icons/ai'
import { CgMergeVertical } from 'react-icons/cg'
import { RxEyeOpen } from 'react-icons/rx'
import { Tab } from '@headlessui/react';
import { MdOutlineArrowBack } from "react-icons/md";
import { db, storage } from '../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, getFirestore, updateDoc, onSnapshot, setDoc, getDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'
import Lottie from "lottie-react";
import image_analyze from "../../assets/animations/image_analyze.json";
import Hint from './Hint';
import { GiBrainStem, GiBrainstorm, GiProcessor } from 'react-icons/gi';
import { IoIosRefresh } from 'react-icons/io';
import { SlSocialYoutube } from 'react-icons/sl';
import error from "../../assets/animations/error.json";
import ConfettiExplosion from 'react-confetti-explosion';

const AIChooseBestImageST = ({ isVisible, onClose, test_id, extensionCallback }) => {
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [thumbnailData, setThumbnailData] = useState(null);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const savedUser = JSON.parse(localStorage.getItem("currentUser"));
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isExploding, setIsExploding] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [thumbnail, setThumbnail] = useState("");
    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState([]);

    const [aiMessage, setAIMessage] = useState('');

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 95) {
                setProgress(progress + 0.1);
            } else {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [progress]);

    useEffect(() => {
        setProgress(0);
        console.log(test_id);

        const getThumbnailData = async () => {
            console.log(test_id);

            const userThumbnailRef = doc(db, 'Users', mainUserId, 'SplitTests', test_id);

            try {
                const unsubscribe = onSnapshot(userThumbnailRef, (snapshot) => {
                    console.log(snapshot.data());

                    if (snapshot.exists()) {
                        const data = snapshot.data();

                        if (data.ai_analysis_progress === "to_analyse") {
                            if (data.ai_analysis_verdict === "error") {
                                setErrorState(true);
                                unsubscribe();
                                return;
                            } else if (data.ai_analysis_verdict === "sorry") {
                                setErrorState(true);
                                setAIMessage(data.ai_visual_analysis);
                                unsubscribe();
                                return;
                            } else {
                                setLoadingMessage('Analysing properties...');
                            }
                        } else if (data.ai_analysis_progress === "to_structure") {
                            if (data.ai_analysis_verdict === "error") {
                                setErrorState(true);
                                unsubscribe();
                                return;
                            } else if (data.ai_analysis_verdict === "sorry") {
                                setErrorState(true);
                                setAIMessage(data.ai_visual_analysis);
                                unsubscribe();
                                return;
                            } else {
                                setLoadingMessage('Processing...');
                            }
                        } else if (data.ai_analysis_progress === "complete") {
                            if (data.ai_analysis_verdict === "success") {
                                setThumbnail(data.ai_visual_analysis.ai_thumbnail_choice);
                                setTitle(data.ai_visual_analysis.ai_title_choice);
                                setNotes(data.ai_visual_analysis.ai_notes);

                                console.log(data.ai_visual_analysis.ai_thumbnail_choice);
                                console.log(data.ai_visual_analysis.ai_title_choice);
                                console.log(data.ai_visual_analysis.ai_notes);
                                setLoaded(true);
                                setIsExploding(true);

                            } else if (data.ai_analysis_verdict === "error") {
                                setErrorState(true);
                                unsubscribe();
                                return;
                            } else if (data.ai_analysis_verdict === "sorry") {
                                setErrorState(true);
                                setAIMessage(data.ai_visual_analysis);
                                unsubscribe();
                                return;
                            } else {
                                setLoadingMessage('Finalizing...');
                            }
                        }

                        // if (data.ai_visual_analysis) {
                        //     setLoadingMessage('Analysing...');

                        //     if (data.ai_analysis_progress === "complete") {
                        //         setThumbnail(data.ai_visual_analysis.ai_thumbnail_choice);
                        //         setTitle(data.ai_visual_analysis.ai_title_choice);
                        //         setNotes(data.ai_visual_analysis.ai_notes);

                        //         console.log(data.ai_visual_analysis.ai_thumbnail_choice);
                        //         console.log(data.ai_visual_analysis.ai_title_choice);
                        //         console.log(data.ai_visual_analysis.ai_notes);

                        //         setLoaded(true);
                        //     } else if (data.ai_analysis_progress === "error") {
                        //         setThumbnail(data.ai_visual_analysis.ai_thumbnail_choice);
                        //         setTitle(data.ai_visual_analysis.ai_title_choice);
                        //         setNotes(data.ai_visual_analysis.ai_notes);

                        //         console.log(data.ai_visual_analysis.ai_thumbnail_choice);
                        //         console.log(data.ai_visual_analysis.ai_title_choice);
                        //         console.log(data.ai_visual_analysis.ai_notes);

                        //         setLoaded(true);
                        //     }

                        // } else {
                        //     setLoadingMessage('Finalizing...');
                        // }

                    } else {
                        setErrorState(true);
                        toast("Unable to perform analysis!", {
                            icon: ({ theme, type }) => (
                                <HiOutlineBell className="text-red-500 h-7 w-7" />
                            ),
                        });
                    }
                });

            } catch (error) {
                setErrorState(true);
                toast("Error fetching document!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            }
        }

        getThumbnailData();
    }, [test_id]);

    const handleModalClose = (e) => {
        if (e.target.id === "this-modal") {
            setProgress(0);
            onClose();
        }
    }

    const modalClose = (e) => {
        setProgress(0);
        onClose();
    }

    const handleYTTestClick = () => {
        setProcessing(true);
        console.log('Processing');

        // Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        // Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            document.cookie = "test_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            const url = "https://www.youtube.com";
            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                //Set Page Intent
                const intent = "OPENTESTER";
                const userDoc = doc(db, "Users", mainUserId)
                const data = {
                    page_intent: intent,
                    tg_tt_current_Image: thumbnail,
                    tg_tt_current_Title: title,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    // // insert current image and titles into cookies
                    document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
                    document.cookie = "tg_tt_current_Image=" + thumbnail + "; path=/;";
                    document.cookie = "tg_tt_current_Title=" + title + "; path=/;";
                    window.open(url, '_blank');

                    setProcessing(false);
                }).catch((e) => {
                    setProcessing(false);
                    toast("Unable to verify connection.", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                });

            } else {
                setProcessing(false);
                extensionCallback();
            }

        } else {
            setProcessing(false);
            // Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    if (!isVisible) return null;

    return (

        <div id="this-modal" onClick={handleModalClose} className={`fixed z-20 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center`}>

            <div className="flex flex-col bg-white border border-gray-400 rounded-lg p-3">

                {
                    loaded === true
                        ?
                        <div className="">
                            <div className="p-4 pt-2">
                                <div className="relative w-full inline-flex mb-6">
                                    <div className="inline-flex">
                                        <div className="text-xl text-purple-500"><GiProcessor /></div>
                                        <div className="text-gray-500 font-medium mb-1 ml-2 ">AI's Choice</div>
                                    </div>
                                    <div onClick={modalClose} className="absolute -right-4 -top-2 p-2 rounded-lg hover:bg-gray-100"><MdClose /></div>
                                </div>
                                <div className=" ">
                                    <div className="w-[360px] aspect-w-16 aspect-h-9">
                                        <div className="aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg">
                                            <img
                                                src={thumbnail} alt=""
                                                className="object-cover"
                                            />
                                        </div>
                                        <div className='flex mt-4'>
                                            <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-9 w-9 object-cover">
                                                <img className='rounded-full md:h-10 md:w-10 xl:h-9 xl:w-9' src={channelPhotoUrl} alt="" />
                                            </div>
                                            <div className='flex-col w-9/12'>
                                                <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                    <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[15px] mb:text-mb text-[#0f0f0f]'>{title}</p>
                                                </div>
                                                <div className=''>
                                                    <div className='inline-flex text-[13px] font-roboto lg:text-[13px] text-[#606060]'>
                                                        <p className='font-[400]'>{channelDisplayName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[360px] border-t border-gray-900/20 mt-10 pt-3">
                                    <h2 className='text-sm mt-3 font-bold text-purple-500'>Notes</h2>
                                    <ul className='text-[13px] text-gray-700 font-normal 2xl:text-[14px] leading-[25px] tracking-[-0.020em] py-4 pt-2'>
                                        {/* {notes} */}
                                        {notes?.map((tip, index) => (
                                            <li key={index} className="mb-0 inline-flex">
                                                <span className="text-2xl text-purple-400 mr-2 -mt-1">•</span>
                                                {tip}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-0">
                                    <div className='flex flex-col w-full pt-2 text-[0.9rem] space-y-2 text-gray-500'>

                                        <div className="inline-flex space-x-6">
                                            <button onClick={() => handleYTTestClick()} className={`ring-1 ring-gray-500 text-gray-500 hover:ring-red-600 hover:text-red-600
                                            w-full border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                                <SlSocialYoutube className='text-base mr-1' /><p className={`text-sm ml-1`}>
                                                    {processing === true ? 'Processing...' : 'Test on Youtube'}
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <>
                            {
                                errorState === false
                                    ?
                                    <div className='w-80 flex flex-col items-center justify-center'>
                                        <div className="flex flex-col items-center justify-center mt-4">
                                            {/* <Lottie className='w-32 h-32' animationData={image_analyze} loop={true} /> */}
                                            <GiProcessor className='w-24 h-24 animate-pulse text-gray-500' />
                                            <p>{loadingMessage}</p>
                                            <div className="relative pt-1">
                                                <div className="flex h-2 w-32 mb-4 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                    <div
                                                        style={{ width: `${progress}%` }}
                                                        className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='flex flex-col items-center justify-center mt-4'>
                                        <div className='w-72 h-72'>
                                            <Lottie animationData={error} loop={true} className='w-72 h-72' />
                                        </div>
                                        {aiMessage !== '' ? (
                                            <div className='max-w-2xl p-2 ring-1 ring-blue-400 rounded-md flex flex-col items-center justify-center'>
                                                <div className="text-[13px]">{aiMessage}</div>
                                            </div>
                                        ) : (
                                            <h2 className=' font-normal text-[14px] mb-5 text-gray-500 flex flex-col items-center justify-center'>
                                                Something went wrong... Try again later.
                                            </h2>
                                        )}
                                        <div className='max-w-2xl mt-4'>
                                            <Hint hintText={'The AI may at times not respond correctly to uploaded images. This may sometimes be due to the content of the image being restricted or some other miscellanious error. Kindly retry later or choose a different image. Your AI credits were not deducted for this request.'} fontSizePx={'13px'} />
                                        </div>

                                    </div>
                            }
                        </>
                }

                {isExploding &&
                    <>
                        <div className="absolute top-0 left-0 ">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                        <div className="absolute top-0 right-1/2">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                        <div className="absolute top-0 right-0">
                            <ConfettiExplosion zIndex={52} />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default AIChooseBestImageST