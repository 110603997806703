import React, { useEffect } from 'react';

const TrustPilotReviewButton = () => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;

        // Append the script to the document body
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="6495a65286e27a7563051839"
            data-style-height="52px"
            data-style-width="100%"
        >
            <a href="https://www.trustpilot.com/review/thumbnailgeek.com" target="_blank" rel="noopener noreferrer">
                Trustpilot
            </a>
        </div>
    );
};

export default TrustPilotReviewButton;