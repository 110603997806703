import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { PremiumCredentials } from '../../context/PremiumContext';
import { UserAuth } from '../../context/AuthContext';
import { AiFillFire } from "react-icons/ai";
import Cookies from 'js-cookie';
import Tooltip from './Tooltip';
import { BiBell, BiTestTube } from 'react-icons/bi';
import TutorialItems from './TutorialItems';
import { FaBell } from 'react-icons/fa';
import { RiBellLine } from 'react-icons/ri';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Avatar = () => {
    const navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { accessLevel, aiCreditsLeft, premiumTier, premiumStatus, testAccount } = PremiumCredentials();
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [extensionExists, setExtensionExists] = useState(false);
    const [aiCredits, setAiCredits] = useState(0);
    const mainUserId = localStorage.getItem("mainUserId");
    const { user, logOut } = UserAuth();
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const desiredCookie = 'TG_CEF';
        const cookies = document.cookie;

        if (cookies.includes(desiredCookie)) {
            console.log('extensionExists');
            setExtensionExists(true);
        } else {
            setExtensionExists(false);
        }
    }, []);

    useEffect(() => {
        // Function to inject the Headway script
        const injectHeadwayScript = () => {
            const script1 = document.createElement('script');
            script1.innerHTML = `
                var HW_config = {
                    selector: ".headway-badge", // CSS selector where to inject the badge
                    account: "JPdeZJ"
                };
            `;
            document.body.appendChild(script1);

            const script2 = document.createElement('script');
            script2.src = "https://cdn.headwayapp.co/widget.js";
            script2.async = true;
            document.body.appendChild(script2);
        };

        injectHeadwayScript();
    }, []);

    const handleLogOut = async () => {
        try {
            console.log("Logout triggered");
            document.cookie = 'TG_AT=; max-age=0;';
            document.cookie = 'TG_Avatar=; max-age=0;';
            document.cookie = 'TG_ChannelName=; max-age=0;';
            await logOut();
            window.window.location.href = '/login';
        } catch (error) {
            console.log(error);
        }
    };

    const handleOnClose = (e) => {
        setProfileDropdown(false);
    };

    const userNavigation = [
        { name: 'Settings', onClick: handleProfileClick },
        { name: 'Support', onClick: handleSupportClick },
        { name: 'Log out', onClick: handleSignOutClick },
    ];

    const installExtension = (url) => {
        const desiredCookie = 'TG_CEF';
        const cookies = document.cookie;

        if (cookies.includes(desiredCookie)) {
            setExtensionExists(true);
            console.log('The specific cookie has been set.');
        } else {
            const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
            const popupWindow = window.open(url, '_blank', windowFeatures);
            if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
                console.log('Popup was blocked or unable to open.');
                window.open(url, '_blank');
            } else {
                console.log('Popup opened successfully.');
            }
        }
    };

    function handleProfileClick(event) {
        event.preventDefault();
        navigate('/account-settings');
    }

    function handleSupportClick(event) {
        event.preventDefault();
        window.location = 'mailto:hello@thumbnailgeek.com';
    }

    async function handleSignOutClick(event) {
        event.preventDefault();
        try {
            console.log("Logout clicked");
            await logOut();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='inline-flex'>
            <div className=" h-8 relative mr-3">
                <div className="absolute -right-3 -top-3 group">
                    <div className="headway-badge h-0 ">
                        <BiBell className="absolute right-4 top-4 w-6 h-6 text-purple-500 group-hover:text-purple-700 group-hover:cursor-pointer" />
                    </div>
                </div>

            </div>
            <Menu as="div" className="relative ml-1">
                <div className=" inline-flex items-center justify-center">



                    <div className="hidden lg:flex">
                        {
                            extensionExists === false
                                ?
                                <NavLink to={'/extension/install'}>
                                    <button className="text-white text-[12px] bg-blue-600 hover:bg-blue-700 font-normal rounded-[4px] text-sm px-4 py-1.5 mr-2 text-center">
                                        Install Extension
                                    </button>
                                </NavLink>
                                :
                                <button className="text-white text-[12px] bg-gray-300 font-normal rounded-[4px] text-sm px-4 py-1.5 mr-2 text-center">
                                    Extension Installed
                                </button>
                        }

                    </div>

                    <Menu.Button className="flex ml-2 max-w-xs items-center rounded-full ring-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                            className="h-8 w-8 rounded-full"
                            src={channelPhotoUrl == null ? '' : channelPhotoUrl}
                            alt=""
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 lg:py-3 border-b">
                            <p className="truncate text-sm font-medium text-gray-900">{channelDisplayName}</p>
                            {
                                testAccount &&
                                (
                                    <div className="mt-2 w-full inline-flex space-x-1 text-xs font-normal text-[12px] bg-orange-500 rounded-[4px] px-2 py-2 text-white">
                                        <BiTestTube className='mt-[1px]' />
                                        <p>Test Account</p>
                                    </div>
                                )
                            }
                        </div>
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name} className='cursor-pointer'>
                                {({ active }) => (
                                    <a
                                        onClick={item.onClick}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                        )}
                                    >
                                        {item.name}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default Avatar;