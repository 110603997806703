import React from 'react';
import './DotDotDot.css';

function DotDotDot() {
    return (
        <div className="flex items-center justify-center">
            <div className="w-1 h-1 bg-gray-400 rounded-full mr-1 animate-dot-1"></div>
            <div className="w-1 h-1 bg-gray-400 rounded-full mr-1 animate-dot-2"></div>
            <div className="w-1 h-1 bg-gray-400 rounded-full animate-dot-3"></div>
        </div>
    );
}

export default DotDotDot;