import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import { useUserAuth } from '../../../context/na-UserAuthContext'
import { UserAuth } from '../../../context/AuthContext'
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { logout } from '../../../redux/actions/auth.action';
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar'

const ErrorPage = () => {
  const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
  const channelDisplayName = localStorage.getItem("channelDisplayName");
  const channelId = localStorage.getItem("channelId");
  const [showSidebar, setShowSidebar] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error Page</title>
        <meta name="description" content="Error Page of Thumbnail Geek" />
      </Helmet>
      <div className='bg-gray-50 h-full w-full min-h-screen min-w-screen' >

        <div className=" px-6 py-4">
          {/* Start Nav */}
          <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4'>
            <div className="flex">
              <a href="/dashboard" className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700'>
                <img src={logo} />
              </a>
            </div>

          </div>
          {/* End Nav */}
        </div>


        <div class="text-center h-[80vh] flex flex-col items-center justify-center">
          <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
          <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
          <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, the page you're looking for does not exist.. </p>
          <div className='grid grid-cols-3 gap-3'>
            <a href="/thumbnail-analysis/upload" class="w-48 h-32 flex flex-col justify-center items-center text-white bg-blue-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Analyze your thumbnails</a>
            <a href="/thumbnail-ideas" class="w-48 h-32 flex flex-col justify-center items-center text-white bg-pink-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Get viral thumbnail ideas</a>
            <a href="/title-builder" class="w-48 h-32 flex flex-col justify-center items-center text-white bg-slate-900 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Get compelling video titles</a>
          </div>

        </div>
        {/* <div className="ml-auto mb-6 lg:w-[80%] xl:w-[80%] 2xl:w-[85%] 3xl:w-[95%]">
          <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50">


            <div className="mx-auto mt-6">
              <div className="w-full h-full mx-auto">
                Content

              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>

  )
}

export default ErrorPage