import React from 'react'
import { useContext, createContext, useEffect, useState } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect
} from 'firebase/auth';
import { authentication } from '../firebase-config';
import { db } from '../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getDoc } from 'firebase/firestore';

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const auth = getAuth();
    const [user, setUser] = useState({});
    const [token, setToken] = useState();
    const [photoUrl, setPhotoUrl] = useState();
    const [providerId, setProviderId] = useState();
    const usersCollection = collection(db, "Users")
    // const [idToken, setIdToken] = useState({});

    // async function signUp(email, password) {
    //     return createUserWithEmailAndPassword(authentication, email, password).then(response => {
    //         console.log(response);
    //     })
    // }

    // async function logIn(email, password) {
    //     console.log("Logging in");
    //     return signInWithEmailAndPassword(authentication, email, password).then(response => {

    //     });
    // }

    async function logOut() {
        return signOut(auth).then(response => {
            document.cookie = `TG_HSH=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
            localStorage.removeItem("mainUserId");
            localStorage.clear();
            // console.log("localStorage user data removed")
        })
    }

    // async function googleSignIn() {
    //     const provider = new GoogleAuthProvider();
    //     provider.setCustomParameters({
    //         prompt: 'select_account'
    //     });
    //     signInWithRedirect(authentication, provider);
    // };

    async function googleSignInPopup() {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        signInWithPopup(authentication, provider);
    };

    async function connectYoutubeChannel() {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/youtube.readonly')
        provider.setCustomParameters({
            prompt: 'select_account',
            // login_hint: email
        });
        // provider.setCustomParameters({
        //     login_hint: email
        // });
        return signInWithRedirect(authentication, provider);
    };

    async function connectYoutubeChannelPopup() {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/youtube.readonly')
        provider.setCustomParameters({
            prompt: 'select_account',
            // login_hint: email
        });
        signInWithPopup(authentication, provider);
    };


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authentication, (currentUser) => {
            setUser(currentUser);
            setPhotoUrl(currentUser?.providerData[0].photoURL);
            setProviderId(currentUser?.providerData[0].providerId);

            currentUser?.getIdToken(true).then(
                idToken => setToken(idToken)
            )

            if (!currentUser) {
                logOut();
                // localStorage.removeItem('currentUser');
                localStorage.removeItem('mainUserId');
                localStorage.removeItem('mainEmail');
                localStorage.removeItem('oauthIdToken');

                // console.log("localStorage user removed");
            }
            setUser(currentUser);
        })

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <AuthContext.Provider value={{ auth, googleSignInPopup, logOut, user, photoUrl, providerId, token, connectYoutubeChannel, connectYoutubeChannelPopup }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}
