import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom'
import { getFunctions, httpsCallable } from "firebase/functions";
import fireEmbers1 from '../../assets/yt-fire-embers-1.jpg';
import { AiFillAccountBook, AiFillTool } from 'react-icons/ai';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { HiSparkles } from 'react-icons/hi';
import { IoAlbums, IoSparklesOutline, IoSparklesSharp } from 'react-icons/io5';
import { MdAlbum, MdCenterFocusStrong, MdWrapText } from 'react-icons/md';
import { SlClose, SlMagicWand } from 'react-icons/sl';
import { db, storage } from '../../firebase-config';
import { PremiumCredentials } from '../../context/PremiumContext';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
import DotDotDot from './DotDotDot';
import { BiRadar, BiTestTube } from 'react-icons/bi';
import { GiftIcon } from '@heroicons/react/24/outline';
import { GiGiftTrap, GiTeePipe } from 'react-icons/gi';
import { IoMdAlbums } from 'react-icons/io';
import { FaHammer } from 'react-icons/fa';
import { CgGift } from 'react-icons/cg';
import { FaTimes, FaBolt, FaGift, FaRocket } from 'react-icons/fa';

const WelcomePopup = ({ isVisible, onClose }) => {
    const navigate = useNavigate()
    const mainUserId = localStorage.getItem("mainUserId");
    const { aiCreditsQuota, aiCreditsUsage, } = PremiumCredentials();
    const [redirecting, setRedirecting] = useState(false);

    const modalClose = (e) => {
        setPopupOff();
        if (e.target.id === "this-modal") {
            onClose();
        } else {
            onClose();
        }
    }

    const setPopupOff = async () => {
        const userRef = doc(db, 'Users', mainUserId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            //console.log("Here");
            const data = docSnap.data();

            const isAiCreditsQuotaRecord = data.hasOwnProperty('ai_credits_quota') && data.ai_credits_quota !== null;
            const isAiCreditsUsageRecord = data.hasOwnProperty('ai_credits_usage') && data.ai_credits_usage !== null;

            if (isAiCreditsQuotaRecord && isAiCreditsUsageRecord) {
                //console.log("Here Here");
                await updateDoc(userRef, { first_open_popup: false });
            } else {
                //console.log("Here Heres");
                const userRef = doc(db, 'Users', mainUserId);
                await updateDoc(userRef, {
                    first_open_popup: false,
                    ai_credits_quota: 1000,
                    ai_credits_usage: 700,
                });
            }
        } else {
            //console.log("No such document!");
        }

        // if (aiCreditsQuota && aiCreditsUsage) {
        //     await updateDoc(userRef, { first_open_popup: false });
        // } else {
        //     const userRef = doc(db, 'Users', mainUserId);
        //     await updateDoc(userRef, {
        //         ai_credits_quota: 1000,
        //         // ai_credits_usage: 700,
        //         // first_open_popup: false,
        //     });
        // }

    }

    const redirectToPayment = async () => {
        navigate('/pricing');
    }

    if (!isVisible) return null;

    return (
        <>
            <div id="this-modal" className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden">
                    <div className="relative bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
                        <button
                            onClick={modalClose}
                            className="absolute top-2 right-2 text-white hover:text-gray-200"
                        >
                            <FaTimes size={24} />
                        </button>
                        <h2 className="text-3xl font-bold mb-2">Unlock Your YouTube Success!</h2>
                        <p className="text-lg">Welcome to ThumbnailGeek</p>
                        {/* <p className="text-xs">Your Secret Weapon for thumbnails that get clicks</p> */}
                    </div>

                    <div className="p-6">
                        <div className="flex items-center justify-center mb-6">
                            <div className="bg-yellow-400 text-yellow-800 text-xl font-bold py-2 px-4 rounded-full animate-pulse">
                                Limited Time Offer!
                            </div>
                        </div>

                        <div className="space-y-4 mb-6">
                            <div className="flex items-center">
                                <FaBolt className="text-yellow-500 mr-2" size={20} />
                                <p><span className="font-bold">300 FREE credits</span> added to your account</p>
                            </div>
                            <div className="flex items-center">
                                <FaGift className="text-green-500 mr-2" size={20} />
                                <p><span className="font-bold">Exclusive 50% discount</span> for new users</p>
                            </div>
                            <div className="flex items-center">
                                <FaRocket className="text-blue-500 mr-2" size={20} />
                                <p><span className="font-bold">10x your channel growth</span> with our tools</p>
                            </div>
                        </div>

                        <div className="text-center mb-6">
                            <p className="text-sm text-gray-600">🕒 This offer expires in <span className="font-bold text-red-500">14 days</span>. Don't miss out!</p>
                        </div>

                        <div className="space-y-4">
                            <Link
                                to="/pricing"
                                className="block w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white font-bold py-3 px-4 rounded-lg text-center transition duration-300 transform hover:scale-105"
                                onClick={modalClose}
                            >
                                Claim Your 50% Discount Now!
                            </Link>
                            <button
                                onClick={modalClose}
                                className="block w-full bg-gray-100 text-gray-800 font-semibold py-3 px-4 rounded-lg text-center hover:bg-gray-200 transition duration-300"
                            >
                                Start with Free Credits
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WelcomePopup