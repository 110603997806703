import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { createPortal } from 'react-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { MdCelebration, MdOutlineCelebration } from 'react-icons/md';
import { toast } from 'react-toastify';

const FreeCreditsModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const functions = getFunctions();
  const freeCreditDrop = httpsCallable(functions, 'freeCreditDrop');
  const [activationCode, setActivationCode] = useState('');
  const [modalRoot, setModalRoot] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setIsError] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const root = document.getElementById('modal-root') || document.createElement('div');
    if (!document.getElementById('modal-root')) {
      root.id = 'modal-root';
      document.body.appendChild(root);
    }
    setModalRoot(root);

    return () => {
      if (root.parentNode === document.body) {
        document.body.removeChild(root);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await freeCreditDrop({ code: activationCode });
      console.log("Success:", response);
      if (response && response.data.success) {
        setSubmitMessage(response.data.message);
        setSuccess(true);
        setTimeout(() => {
          onClose();
        }, 4000);
      }
    } catch (error) {
      setIsError(true);
      if (error.code === 'already-exists') {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 7000,
        });
      } else if (error.code === 'invalid-argument') {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 7000,
        });
      } else if (error.code === 'unauthenticated') {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 7000,
        });
      } else {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 7000,
        });
      }
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }

  };

  const closeModal = () => {
    onClose();
  };


  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  if (!isOpen || !modalRoot) return null;

  const modalContent = (
    <div onClick={handleOutsideClick} className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div ref={modalRef} className="relative bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-3 border-b">
          <h2 className="text-lg font-bold ml-1 text-gray-800">Activate Your Free Credits</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="">
          {
            success === false
              ?
              <form onSubmit={handleSubmit} className="p-4">
                <div className="mb-4">
                  <label htmlFor="activationCode" className="block text-sm font-medium text-gray-700 mb-2">
                    Enter your activation code
                  </label>
                  <input
                    type="text"
                    id="activationCode"
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}
                    className={`w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
                    placeholder="Enter code here. Ex: VALID123"
                    required
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="mr-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isSubmitting ? 'Activating...' : 'Activate Credits'}
                  </button>
                </div>
              </form>
              : (
                <div className="w-full mb-16 flex flex-col items-center justify-center">
                  <div className="mb-2 pt-8"><MdOutlineCelebration className='w-10 h-10 text-green-500' /></div>
                  <div className={` text-sm ${submitMessage.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                    {submitMessage}
                  </div>
                </div>
              )
          }
        </div>


      </div>
    </div>
  );

  return createPortal(modalContent, modalRoot);
};

export default FreeCreditsModal;