import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { db } from '../../../firebase-config';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, getRedirectResult, GoogleAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, addDoc, getFirestore, serverTimestamp, collection, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import logoImage from '../../../assets/logo192.png';

const FirstTimeValidator = () => {
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const navigate = useNavigate();
    const authG = getAuth();
    const [validating, setValidating] = useState(true)
    const [banned, setBanned] = useState(false)

    useEffect(() => {
        console.log('Validating');

        //Check account info
        const checkUserId = async () => {
            const firestore = getFirestore()
            const docRef = doc(firestore, "Users", mainUserId)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                const banned = docSnap.data().banned;
                const account_status = docSnap.data().account_status;
                const premium_status = docSnap.data().premium_status;
                const premium_tier = docSnap.data().premium_tier;
                const premium_expiry = docSnap.data().premium_expiry;

                if (banned === true) {
                    //Banned
                    setBanned(true)
                    setValidating(false)
                }

                if ((account_status === "ACTIVE"
                    || account_status === "TRIALING") &&
                    (premium_status === "PREMIUM"
                        || premium_status === "TRIALING")) {

                    //If token expired create new token
                    // console.log('token expired. creating new token');
                    if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
                        const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
                        const requestBody = {
                            loginStatus: true,
                        };
                        axios.post(apiUrl, requestBody)
                            .then(response => {
                                // console.log('Token ' + response.data.token);
                                document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                                const dateSet = new Date();
                                localStorage.setItem('login_cookie_setDate', dateSet);
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            });

                        document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
                        document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
                    }
                    setValidating(false)
                    const delay = 3000; // Delay in milliseconds (3 seconds)

                    const redirectionTimeout = setTimeout(() => {
                        window.location.href = 'https://www.youtube.com'
                    }, delay);

                    // Clean up the timeout when the component unmounts
                    return () => clearTimeout(redirectionTimeout);

                }
                else if (premium_status !== "PREMIUM"
                    || premium_status !== "TRIALING") {
                    console.log('billing cancelled. go to');
                    navigate(`/billing/plans/`);
                    setValidating(false);
                } else {
                    console.log('Something else is wrong');
                    navigate(`/login`);
                }
            } else {
                navigate(`/signup`);
            }
        }

        checkUserId();
    }, []);

    if (validating === true) {
        return <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
            <div className="flex flex-col items-center justify-center">
                <div className='relative w-2/3'>
                    <img className='w-16' src={logoImage} />
                </div>
                <p>Validating...</p>
                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                    <path
                        className="fill-gray-300"
                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path
                        className="fill-gray-500"
                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>
        </div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Validator | ThumbnailGeek</title>
                <meta name="description" content="Login | ThumbnailGeek" />
            </Helmet>

            <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
                <div className="flex flex-col items-center justify-center">
                    <div className='relative'>
                        <img className='w-16' src={logoImage} />
                    </div>
                    {
                        banned === false
                            ?
                            <div className="flex flex-col items-center justify-center text-center">
                                <p className='text-center'>Validation Complete!</p>
                                <a className='text-center' href='https://www.youtube.com'>Redirecting to your account</a>
                            </div>
                            :
                            <>
                                <h2>Account Disabled!</h2>
                                <a href='mailto://hello@thumbnailgeek.com'>Find out why?</a>
                            </>
                    }


                </div>
            </div>
        </>
    )
}
export default FirstTimeValidator 