import React, { useState, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { FaCheckCircle, FaHeart } from 'react-icons/fa';
import axios from 'axios';
import TrustPilotReviewButton from './TrustPilotReviewButton';
import { Heart } from 'lucide-react';
import { BsHeart } from 'react-icons/bs';

const PaymentSuccess = ({ isOpen, onClose }) => {
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const [isExploding, setIsExploding] = useState(true);
    const [showReviewButton, setShowReviewButton] = useState(false);

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
                    // console.log('Token set : ' + response.data.token);

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, []);

    const handleContinue = () => {
        setShowReviewButton(true);
    };

    const handleCancel = () => {
        if (showReviewButton) {
            onClose();
        } else {
            setShowReviewButton(true);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-2xl flex flex-col items-center">
                {isExploding && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <ConfettiExplosion zIndex={52} />
                    </div>
                )}
                <button
                    onClick={handleCancel}
                    type="button"
                    className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                <div className="text-center flex flex-col items-center justify-center">
                    {/* <FaCheckCircle className="w-24 h-24 mb-2 text-green-500" />
                    <h2 className="text-2xl font-bold mb-2">Payment succeeded!</h2>
                    <p className="mt-2">Thank you for your most recent payment.</p>
                    <p className="mb-4">Processing credits have been added to your account</p>
                    <button className="bg-blue-500 hover:bg-blue-700 cursor-pointer z-[57] text-white px-4 py-2 rounded-lg" onClick={onClose}>
                        Continue
                    </button> */}
                    {!showReviewButton ? (
                        <>
                            <FaCheckCircle className="w-24 h-24 mb-2 text-green-500" />
                            <h2 className="text-2xl font-bold mb-2">Payment succeeded!</h2>
                            <p className="mt-2">Thank you for your most recent payment.</p>
                            <p className="mb-4">Processing credits have been added to your account</p>
                            <button className="bg-blue-500 hover:bg-blue-700 cursor-pointer z-[57] text-white px-4 py-2 rounded-lg"
                                onClick={handleContinue}>
                                Continue
                            </button>
                        </>
                    ) : (
                        <>
                            <FaHeart className="w-24 h-24 mb-2 text-red-500" />
                            <h2 className="text-2xl font-bold mb-2">Before you dive in!</h2>
                            <p className="mt-2 mb-4">We'd really appreciate a review.</p>
                            <TrustPilotReviewButton />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
