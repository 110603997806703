import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext'
import { ArrowUpIcon } from '@heroicons/react/24/outline';

const PremiumBlocker = ({ active }) => {
    const navigate = useNavigate();
    const mainUserId = localStorage.getItem("mainUserId")
    const { user, logOut } = UserAuth();
    const [hovered, setHovered] = useState(false);

    const handleBilling = async () => {
        navigate(`/first-steps/billing/plans/${mainUserId}`);
    }
    const handleLogOut = async () => {
        try {
            console.log("Logout triggered");
            document.cookie = 'TG_AT=; max-age=0;';
            document.cookie = 'TG_Avatar=; max-age=0;';
            document.cookie = 'TG_ChannelName=; max-age=0;';
            await logOut();
            window.window.location.href = '/login';
        } catch (error) {
            console.log(error)
        }
    }

    if (!active) return null;

    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 h-full w-full z-[12321431]'>
            <div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full bg-gray-900/60 opacity-100">
            </div>
            <div className="bg-opacity-30 z-[342423] w-full h-full flex flex-col items-center justify-center">
                <div className={`w-[600px] p-6 transition ease-in duration-700`}>
                    <div className="relative max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">
                        <h2 className="text-lg sm:text-2xl font-bold tracking-tight text-gray-900 pb-6 pt-7 px-6">
                            Start Using ThumbnailGeek
                        </h2>
                        <p className="px-6 tracking-normal text-gray-700">
                            Welcome to ThumbnailGeek. To get started, please start your free trial. You have 7 days to play around with the software and your card will not be charged.
                        </p>
                        <div className="px-6 w-full group flex flex-col items-center justify-center mb-2 mt-8 space-x-2 border-gray-200 rounded-b dark:border-gray-600">

                            <button
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                onClick={handleBilling}
                                className="group inline-flex items-center justify-center w-full h-11 text-gray-800 tracking-wide hover:text-white ring-1 ring-green-500 bg-green-100 hover:bg-green-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                <ArrowUpIcon className='group-hover:text-white w-5 h-5 mr-2' />
                                <span> View Plans (You will not be charged) </span>
                            </button>
                        </div>

                        <div className="px-6 w-full flex items-center justify-center pb-4 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                style={{ opacity: hovered ? 0 : 1 }}
                                onClick={handleLogOut}
                                className=" w-full h-11 text-gray-800 tracking-wide font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Sign Out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PremiumBlocker