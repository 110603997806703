import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import journey from '../../../assets/journey.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import ConfettiExplosion from 'react-confetti-explosion';
import AvatarNoPremium from '../../components/AvatarNoPremium';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import logoImage from '../../../assets/logo192.png';

const InBetweenPlace = () => {
    let { user_id } = useParams();
    localStorage.setItem('real_user_id', user_id);
    const navigate = useNavigate()
    const { user, token, providerId, photoUrl, connectYoutubeChannel, logOut } = UserAuth()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserId = localStorage.getItem("mainUserId");
    const auth = getAuth();
    const {
        bannedStatus,
        premiumStatus,
        premiumTier,
        accountStatus,
        accessLevel,
        channelLinked,
        checked,
        pageTouchConnect,
        pageTouchExtension,
        pageTouchQA,
        checkDataStatus
    } = PremiumCredentials();
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        console.log('Setting login cookie');

        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists

                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (bannedStatus === true) {
            navigate('/restricted');
        }

        if (mainUserId !== null && mainUserId !== undefined) {

            if (checked === true) {
                console.log('mainUserId: --- ' + mainUserId);

                if (pageTouchConnect === false || pageTouchConnect === undefined || pageTouchConnect === null) {
                    navigate(`/first-steps/connect/${mainUserId}`);
                    return;
                }
                else if (pageTouchExtension === false || pageTouchExtension === undefined || pageTouchExtension === null) {
                    navigate(`/first-steps/extension/${mainUserId}`);
                    return;
                }
                // else if (pageTouchQA === false || pageTouchQA === undefined || pageTouchQA === null) {
                //     navigate(`/first-steps/qa/${mainUserId}`);
                //     return;
                // }
                else {
                    console.log(accountStatus, 'accountStatus')
                    console.log(premiumStatus, 'premiumStatus')
                    console.log(channelLinked, 'channelLinked')

                    if (channelLinked === false || channelLinked === undefined || channelLinked === null) {
                        navigate(`/first-steps/connect/${mainUserId}`);
                        return;
                    } else {
                        //accountStatus === 'INACTIVE'
                        goToDashboard();
                        setLoaded(true);
                    }

                    // if (accountStatus !== 'INACTIVE') {
                    //     // if (channelLinked === true) {
                    //     goToDashboard();
                    //     setLoaded(true);
                    //     return;
                    //     // }
                    // } else {

                    // }
                }

            } else {
                // console.log('Initializing Premium Credentials');
                checkDataStatus(mainUserId);
            }
        } else {
            logoutUser();
        }
    }, [
        bannedStatus,
        premiumStatus,
        premiumTier,
        accountStatus,
        accessLevel,
        channelLinked,
        checked,
        pageTouchConnect,
        pageTouchExtension,
        pageTouchQA,
        mainUserId,
        checkDataStatus
    ]);


    // useEffect(() => {

    //     if (mainUserId !== null && mainUserId !== undefined) {

    //         if (checked === true) {

    //             console.log('mainUserId: ---  ' + mainUserId);

    //             if (pageTouchConnect === false || pageTouchConnect === undefined || pageTouchConnect === null) {
    //                 navigate(`/first-steps/connect/${mainUserId}`);
    //                 return;
    //             }
    //             else if (pageTouchExtension === false || pageTouchExtension === undefined || pageTouchExtension === null) {
    //                 navigate(`/first-steps/extension/${mainUserId}`);
    //                 return;
    //             } else if (pageTouchQA === false || pageTouchQA === undefined || pageTouchQA === null) {
    //                 navigate(`/first-steps/qa/${mainUserId}`);
    //                 return;
    //             }
    //             else {
    //                 console.log(accountStatus, 'accountStatus')
    //                 console.log(premiumStatus, 'premiumStatus')
    //                 console.log(channelLinked, 'channelLinked')

    //                 // if (accountStatus === 'INACTIVE' && premiumStatus === 'NOT_INITIATED') {
    //                 //     localStorage.setItem('premiumStatus', 'NOT_INITIATED');
    //                 //     // navigate(`/first-steps/billing/plans/${mainUserId}`);
    //                 //     navigate(`/dashboard?p=f`);
    //                 //     return;
    //                 // }

    //                 if (channelLinked === false || channelLinked === undefined || channelLinked === null) {
    //                     // localStorage.setItem('premiumStatus', 'NOT_INITIATED');
    //                     navigate(`/first-steps/connect/${mainUserId}`);
    //                     return;
    //                 }

    //                 // if (channelLinked === true && premiumStatus === 'NOT_INITIATED') {
    //                 //     // localStorage.setItem('premiumStatus', 'NOT_INITIATED');
    //                 //     navigate(`/first-steps/billing/plans/${mainUserId}`);
    //                 //     return;
    //                 // }

    //                 // if (premiumStatus === 'INCOMPLETE_EXPIRED' || premiumStatus === 'CANCELED') {
    //                 //     // localStorage.setItem('premiumStatus', 'CANCELED');
    //                 //     navigate(`/restricted`);
    //                 //     return;
    //                 // }

    //                 // if (premiumStatus === 'PAST_DUE' || premiumStatus === 'INCOMPLETE' || premiumStatus === 'UNPAID') {
    //                 //     const functions = getFunctions();
    //                 //     const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    //                 //     addMessage({
    //                 //         returnUrl: window.location.origin + '/account-settings',
    //                 //         locale: 'auto',
    //                 //     })
    //                 //         .then((result) => {
    //                 //             const data = result.data;
    //                 //             if (data.url) {
    //                 //                 window.open(data.url);
    //                 //             }
    //                 //         })
    //                 //         .catch((error) => {
    //                 //             //Getting the Error details.
    //                 //             const code = error.code;
    //                 //             const message = error.message;
    //                 //             const details = error.details;
    //                 //             //...
    //                 //             console.error(code);
    //                 //             console.error(message);
    //                 //             console.error(details);
    //                 //             window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ');
    //                 //         });
    //                 //     return;
    //                 // }

    //                 // if (channelLinked === true && premiumStatus === 'CANCELED' && premiumStatus !== 'PREMIUM' && premiumStatus !== 'TRIAL') {
    //                 //     navigate(`/restricted`);
    //                 //     return;
    //                 // }

    //                 if (accountStatus !== 'INACTIVE') {
    //                     if (channelLinked === true && premiumStatus === 'PREMIUM') {
    //                         goToDashboard();
    //                         setLoaded(true);
    //                         return;
    //                     }

    //                     if (channelLinked === true && premiumStatus === 'TRIALING') {
    //                         goToDashboard();
    //                         setLoaded(true);
    //                         return;
    //                     }
    //                 }

    //             }

    //         } else {
    //             // console.log('Initializing Premium Credentials');
    //             checkDataStatus(mainUserId);
    //         }
    //     } else {
    //         logoutUser();
    //     }

    // }, [
    //     premiumStatus,
    //     premiumTier,
    //     accountStatus,
    //     accessLevel,
    //     channelLinked,
    //     checked,
    //     pageTouchConnect,
    //     pageTouchExtension,
    //     pageTouchQA,
    //     mainUserId,
    //     checkDataStatus
    // ]);

    // useEffect(() => {
    //     // if (mainUserId !== null && mainUserId !== undefined) {
    //     //     if (checked === true) {

    //     //         if (pageTouchConnect === false || pageTouchConnect === undefined || pageTouchConnect === null) {
    //     //             navigate(`/first-steps/connect/${mainUserId}`);
    //     //             return;
    //     //         } else if (pageTouchExtension === false || pageTouchExtension === undefined || pageTouchExtension === null) {
    //     //             navigate(`/first-steps/extension/${mainUserId}`);
    //     //             return;
    //     //         } else if (pageTouchQA === false || pageTouchQA === undefined || pageTouchQA === null) {
    //     //             navigate(`/first-steps/qa/${mainUserId}`);
    //     //             return;
    //     //         } else {

    //     //             console.log('jhkljkjlkjlkj;ljkljk;lj;kljkl;j;ljl;')

    //     //         }

    //     //     } else {
    //     //         console.log('Initializing Premium Credentials');
    //     //         // checkDataStatus(mainUserId);
    //     //     }
    //     // } else {
    //     //     // logoutUser();
    //     // }

    // }, [
    //     premiumStatus,
    //     premiumTier,
    //     accountStatus,
    //     accessLevel,
    //     channelLinked,
    //     checked,
    //     pageTouchConnect,
    //     pageTouchExtension,
    //     pageTouchQA,
    //     mainUserId,
    //     checkDataStatus
    // ]);

    const logoutUser = async () => {
        await logOut();
    }

    function deleteCookie(name) {
        document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    const updateToken = (e) => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
            document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;
        }
    }

    const goToDashboard = async (event) => {
        // navigate(`/dashboard`);
        window.location.href = '/dashboard';
        // navigate(`/dashboard?first-entry=1`);
    }

    if (loaded === false) {
        return <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
            <div className="flex flex-col items-center justify-center">
                <img className='w-12' src={logoImage} />
                {/* </div> */}
                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                    <path
                        className="fill-gray-300"
                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path
                        className="fill-gray-500"
                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>
        </div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mid</title>
                <meta name="description" content="Mid" />
            </Helmet>
        </>
    )
}

export default InBetweenPlace