import React, { useRef, useState } from 'react';

const GeekPass = () => {
    const cardWrapperRef = useRef(null);
    const cardRef = useRef(null);
    const highlightRef = useRef(null);
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        // remove transition
        cardRef.current.style.transition = "none";
        highlightRef.current.style.transition = "none";

        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY;
        const { width, height } = cardWrapperRef.current.getBoundingClientRect();
        const halfWidth = width / 2;
        const halfHeight = height / 2;

        // highest values for angle
        const mostX = 10; // 10 or -10
        const mostY = 10; // 10 or -10

        // calculate angle
        const rotationY = ((x - halfWidth) / halfWidth) * mostX;
        const rotationX = ((y - halfHeight) / halfHeight) * mostY;

        // set rotation
        setRotation({ x: rotationX, y: rotationY });
        cardRef.current.style.transform = `rotateY(${rotationY}deg) rotateX(${rotationX}deg)`;
        highlightRef.current.style.left = `${(rotationY / mostX) * 30 * -1}%`;
        highlightRef.current.style.top = `${(rotationX / mostY) * 30 * -1}%`;
    };

    const handleMouseLeave = () => {
        // add transition back
        cardRef.current.style.transition = "transform 0.5s ease-in-out";
        cardRef.current.style.transform = "rotateY(0) rotateX(0)";
        highlightRef.current.style.transition = "left 0.5s ease-in-out, top 0.5s ease-in-out";

        // add default position back to highlight
        highlightRef.current.style.left = "-20%";
        highlightRef.current.style.top = "-13%";
    };

    return (
        <div>
            <section className="h-screen flex items-center justify-center">
                <div className="container">
                    <div className="cardWrapper" ref={cardWrapperRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                        <div className="card bg-blue-500 text-white relative" ref={cardRef} style={{ borderRadius: "18px", boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)", padding: "30px", fontSize: "1.6rem", fontFamily: "Poppins" }}>
                            <div className="card__name mb-10" style={{ alignSelf: "flex-start", fontSize: "4rem" }}>
                                <p>OBAID</p>
                            </div>
                            <div className="card__number flex items-center justify-center gap-1" style={{ fontSize: "2rem", marginBottom: "10px" }}>
                                <div className="h-6 w-14"></div>
                                <div className="h-6 w-14"></div>
                                <div className="h-6 w-14"></div>
                                <div>1234</div>
                            </div>
                            <div className="highlight absolute" ref={highlightRef} style={{ width: "400px", height: "400px", borderRadius: "50%", backgroundColor: "rgba(255, 255, 255, 0.13)", filter: "brightness(70%) blur(20px)", color: "#333", fontWeight: "bold", left: "-20%", top: "-13%" }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default GeekPass;
