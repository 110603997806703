import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { auth, db } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { fetchSignInMethodsForEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, addDoc, getFirestore, serverTimestamp, collection, query, where } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import logoImage from '../../../assets/logo192.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import DotDotDot from '../../components/DotDotDot';
import gLogo from '../../../assets/g-logo.png';

const ConnectChannel = () => {
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const { premiumStatus, premiumTier, premiumExpiry, accountStatus, checked, channelLinked } = PremiumCredentials()
    const navigate = useNavigate()
    const [connect, setConnect] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [channelName, setChannelName] = useState("")
    const [channelImage, setChannelImage] = useState("")
    const [userDetails, setUserDetails] = useState(null);
    const [verifying, setVerifying] = useState(false);

    useEffect(() => {
        const checkUserId = async () => {
            const firestore = getFirestore()
            const docRef = doc(firestore, "Users", mainUserId)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                navigate('/login')
            } else {
                setUserDetails(true)
                const channel_id = docSnap.data().channel_id;
                const channel_name = docSnap.data().channel_name;
                const channel_photo = docSnap.data().channel_photo_url;
                const channel_linked = docSnap.data().channel_linked;

                if (channel_id !== '' && channel_id !== null) {
                    setConnect(true)

                    if (channel_linked === false) {
                        const userDoc = doc(db, "Users", mainUserId)
                        const data = {
                            channel_linked: true,
                        }
                        setDoc(userDoc, data, { merge: true });
                    }
                }

                if (channel_photo !== '' && channel_photo !== null) {
                    setChannelImage(channel_photo)
                }

                if (channel_name !== '' && channel_name !== null) {
                    setChannelName(channel_name)
                }
            }
        }

        checkUserId();
    }, []);

    const connectChannel = async () => {
        setConnecting(true);

        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/youtube.readonly')
        provider.setCustomParameters({
            prompt: 'select_account',
            // login_hint: email
        });

        try {
            signInWithPopup(auth, provider).then(async (data) => {

                // This gives you a Google Access Token. You can use it to access Google APIs.
                const credential = GoogleAuthProvider.credentialFromResult(data);

                const accessToken = credential.accessToken;
                const refreshToken = data.user.refreshToken;

                localStorage.setItem('ytToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                console.log(`------------Access Token--------------- ${accessToken}`);
                console.log(`------------Refresh Token-------------- ${refreshToken}`);

                if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
                    getChannelIdThenSave(accessToken, refreshToken, data);
                }

            }).catch((error) => {
                setVerifying(false);
            });
        } catch (error) {
            setVerifying(false);
        }

    }

    const getChannelIdThenSave = async (accessToken, refreshToken, result) => {
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
        };

        try {
            await axios.get("https://youtube.googleapis.com/youtube/v3/channels?part=statistics&mine=true&key=AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s",
                config
            ).then(res => {
                console.log(res.data);

                if (Array.isArray(res.data.items)) {
                    setConnecting(false);
                    console.log(res.data.items[0].id);

                    const channelid = res.data.items[0].id ?? "";
                    const channelname = result.user?.displayName ?? "";
                    const channelemail = result.user?.email ?? "";
                    const accesstoken = accessToken ?? "";
                    const refreshtoken = refreshToken ?? "";
                    const channelphotourl = result.user?.providerData[0].photoURL ?? "";
                    const subscribercount = res.data.items[0].statistics.subscriberCount ?? "";

                    localStorage.setItem('channelPhotoUrl', channelphotourl);
                    localStorage.setItem('channelDisplayName', channelname);
                    localStorage.setItem('channelId', channelid);

                    const userDoc = doc(db, "Users", mainUserId)
                    const data = {
                        channel_id: channelid,
                        channel_name: channelname,
                        channel_email: channelemail,
                        channel_token: accesstoken,
                        refresh_token: refreshtoken,
                        channel_photo_url: channelphotourl,
                        sub_count: subscribercount,
                        channel_linked: true,
                    }
                    setDoc(userDoc, data, { merge: true }).then(res => {
                        setChannelName(channelname);
                        setChannelImage(channelphotourl);
                        setConnect(true);
                    });
                } else {
                    console.log('Invalid channel');
                    setConnecting(false);
                    toast("Not a valid Youtube channel", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                }
            })
        } catch (error) {
            setConnecting(false);
            setConnect(false);
        }

    }

    const goToDashboard = async (event) => {
        if (connect === true) {
            // navigate(`/dashboard`);
        } else {
            toast("Connect your youtube channel first", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sign Up | ThumbnailGeek</title>
                <meta name="description" content="First Steps - Add channel | ThumbnailGeek" />
            </Helmet>

            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-24">
                    <img className="mx-auto h-12" src={require('../../../assets/svgs/logo512.svg').default} alt="ThumbnailGeek Logo" />

                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 pt-12 pb-7 ring-1 ring-gray-300 shadow sm:rounded-lg sm:px-12">

                        <div className="mt-6 w-full flex flex-col justify-center items-center" >

                            <div className="flex flex-col h-full items-center justify-center">
                                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                                    <div className="">
                                        {
                                            userDetails === true && connect === true
                                                ?
                                                <div className="pr-4">
                                                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                        Awesome!
                                                    </h2>
                                                    <p className="mt-3 my-12 text-center text-sm text-gray-500">
                                                        You can now use the browser extension to test your thumbnail on Youtube homepage.
                                                    </p>
                                                </div>
                                                :
                                                <div className="pr-4">
                                                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                        Add your Youtube channel
                                                    </h2>
                                                    <p className="mt-3 my-12 text-center text-sm text-gray-500">
                                                        Which Youtube channel will you like to grow using thumbnailGeek?
                                                    </p>
                                                </div>
                                        }
                                    </div>

                                    <div className="mt-2">
                                        <div className="">
                                            {
                                                userDetails === true
                                                    ?
                                                    <div className="text-gray-600 body-font container mx-auto flex flex-col mt-12 justify-center items-center">
                                                        <div className="w-full max-w-lg flex flex-col mb-6 text-center">
                                                            {
                                                                connect === true
                                                                    ?
                                                                    <>
                                                                        <div className="mt-1">
                                                                            <div className="flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 p-2 text-left shadow-sm"
                                                                            >
                                                                                <span className="flex min-w-0 flex-1 items-center space-x-3">
                                                                                    <span className="block flex-shrink-0">
                                                                                        <img className="h-10 w-10 rounded-full" src={channelImage} alt="" />
                                                                                    </span>
                                                                                    <span className="block min-w-0 flex-1">
                                                                                        <span className="block truncate text-sm font-medium text-gray-900">{channelName}</span>
                                                                                        <span className="block truncate text-xs font-medium text-green-600 mr-4">youtube channel connected</span>
                                                                                    </span>
                                                                                </span>
                                                                                <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                                                                    <IoCheckmarkCircleSharp className="h-5 w-5 text-green-600" aria-hidden="true" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <button onClick={connectChannel} className='mt-2 text-sm font-normal text-gray-900'>Change channel</button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            connecting === false
                                                                                ?
                                                                                <>
                                                                                    <div className="block mt-1">
                                                                                        <button onClick={connectChannel}
                                                                                            type="button"
                                                                                            className="group flex w-full items-center justify-between space-x-3 rounded-lg border 
                                                                                        border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 bg-white active:bg-gray-100"
                                                                                        >
                                                                                            <span className="flex min-w-0 flex-1 items-center space-x-3 ml-4">
                                                                                                <span className="block min-w-0 flex-1">
                                                                                                    <span className="block truncate font-semibold text-sm text-gray-8 00">Add Youtube Channel</span>
                                                                                                </span>
                                                                                            </span>
                                                                                            <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                                                                                <PlusIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <div className="block mt-1">
                                                                                    <button onClick={connectChannel}
                                                                                        type="button"
                                                                                        className="group flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 p-2 text-left shadow-sm bg-gray-200"
                                                                                    >
                                                                                        <span className="flex min-w-0 flex-1 items-center space-x-3 ml-4">
                                                                                            <span className="block min-w-0 flex-1">
                                                                                                <span className="block truncate font-semibold text-sm text-gray-8 00">Loading...</span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                                                                            <svg role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                        }
                                                                    </>
                                                            }
                                                            {
                                                                connect === true
                                                                    ?
                                                                    <div className="block mt-10 max-w-lg">
                                                                        <button
                                                                            onClick={goToDashboard}
                                                                            className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="block mt-10 max-w-lg">
                                                                        <button
                                                                            disable="true"
                                                                            className="flex w-full justify-center rounded-md bg-gray-300 px-3 py-2.5 text-sm font-semibold leading-6 text-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                    </div>
                                                            }


                                                            {error &&
                                                                <div className="absolute right-5 top-5 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
                                                                    <div className="flex items-center justify-center w-12 bg-red-500">
                                                                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                                                                        </svg>
                                                                    </div>

                                                                    <div className="px-4 py-2 -mx-3">
                                                                        <div className="mx-3">
                                                                            <p className="text-sm text-white dark:text-gray-600">{error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {success &&
                                                                <div className="absolute right-5 top-5 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
                                                                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                                                                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                                                                        </svg>
                                                                    </div>

                                                                    <div className="px-4 py-2 -mx-3">
                                                                        <div className="mx-3">
                                                                            <p className="text-sm text-white dark:text-gray-600">{success}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="w-full flex mt-16 justify-center items-center xl:px-8">
                                                        <div className="flex flex-col items-center justify-center">
                                                            <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                                <path
                                                                    className="fill-gray-300"
                                                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                                <path
                                                                    className="fill-gray-500"
                                                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div >
            </div >
        </>

    )
}

export default ConnectChannel