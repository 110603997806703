import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from "react-dropzone";
import Cookies from 'js-cookie'
import axios from 'axios';
import { HiOutlineBell, HiSparkles } from 'react-icons/hi';
import { toast } from 'react-toastify';
import DotDotDot from './DotDotDot';
import Hint from './Hint';
import { PremiumCredentials } from '../../context/PremiumContext';
import { db, storage } from '../../firebase-config';
//import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, } from "firebase/firestore";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit, where } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "@firebase/storage";
import { HomeModernIcon, PhoneArrowUpRightIcon } from '@heroicons/react/24/outline';
import { BsYoutube } from 'react-icons/bs';
import { FiSmartphone } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';
import Lottie from "lottie-react";
import image_upload from "../../assets/animations/image_uploader.json";
import Tooltip from './Tooltip';
import { Tab } from '@headlessui/react';
import { IoAnalytics } from 'react-icons/io5';
import { FaCrown } from 'react-icons/fa';
import BlockPopupStanPro from './BlockPopupStanPro';
import CreditsPopupStanPro from './CreditsPopupStanPro';
import CreditsPopupStanProTrial from './CreditsPopupStanProTrial';

const ThumbnailUploadDialog = ({ isVisible, onClose }) => {
    const navigate = useNavigate();
    const mainUserId = localStorage.getItem("mainUserId");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectType, setSelectType] = useState(0);
    const [selectedID, setSelectedID] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [selectedTImage, setSelectedTImage] = useState();
    const [uploadPopup, setUploadPopup] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [extensionModal, setExtensionModal] = useState(false)
    const [searchModal, setSearchModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, aiCreditsLeft } = PremiumCredentials();
    const [uploadBtnsActive, setUploadBtnsActive] = useState(false)
    const [searchType, setSearchType] = useState(0)
    const [thumbsLoaded, setThumbsLoaded] = useState(false)
    const [thumbnailsData, setThumbnailsData] = useState(null)
    const [currentTitle, setCurrentTitle] = useState('')
    const [uploadPercent, setUploadPercent] = useState(0)
    const [redirectUrl, setRedirectUrl] = useState(0)
    const [popupBlocked, setPopupBlocked] = useState(false)
    const [blockPopup, setBlockPopup] = useState(false)
    const [blockedURL, setBlockedURL] = useState('')
    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)

    useEffect(() => {

        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
            document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;
        }
    }, []);

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png, image/gif',
        maxFiles: 1,
        maxSize: 2 * 1024 * 1024, //2 MB in bytes
        onDrop: acceptedFiles => {

            if (fileRejections.length === 0) {
                if (acceptedFiles.length > 1) {
                    toast("Only one image can be uploaded in this section", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                    return;
                }
                setSelectedImage(acceptedFiles[0]);
                setUploadBtnsActive(true);
                uploadThumbnail();
            } else {
                //Handle file rejection error if needed
                console.log('File rejected:', fileRejections);
            }
        }
    });

    const testExtension = () => {
        document.cookie = 'test_cookie=1';
        if (document.cookie.indexOf('test_cookie') !== -1) {

            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                let url = "https://www.youtube.com";
                uploadThumbnail(url);
            } else {
                setExtensionModal(true);
            }

        } else {
            //Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    //const resizeImage = (file) => {
    //    return new Promise((resolve) => {
    //        const reader = new FileReader();
    //        reader.readAsDataURL(file);
    //        reader.onload = (event) => {
    //            const img = new Image();
    //            img.src = event.target.result;
    //            img.onload = () => {
    //                const scaleFactor = 720/img.width;
    //                const canvas = document.createElement("canvas");
    //                canvas.width = 720;
    //                canvas.height = img.height * scaleFactor;
    //                const ctx = canvas.getContext("2d");
    //                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //                canvas.toBlob((blob) => {
    //                    blob.lastModifiedDate = new Date();
    //                    blob.name = file.name;
    //                    const newFile = new File([blob], file.name, { type: "image/png" });
    //                    const initialWidth = img.width;
    //                    const initialHeight = img.height;
    //                    resolve({ file: newFile, initialWidth, initialHeight });
    //                }, "image/png", 0.9);
    //            };
    //        };
    //    });
    //};

    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            if (!(file instanceof Blob)) {
                setUploading(false);
                reject(new Error('The provided value is not a Blob or File.'));
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const scaleFactor = 720 / img.width;
                    const canvas = document.createElement("canvas");
                    canvas.width = 720;
                    canvas.height = img.height * scaleFactor;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        blob.lastModifiedDate = new Date();
                        blob.name = file.name;
                        const newFile = new File([blob], file.name, { type: "image/png" });
                        const initialWidth = img.width;
                        const initialHeight = img.height;
                        resolve({ file: newFile, initialWidth, initialHeight });
                    }, "image/png", 0.9);
                };
            };

            reader.onerror = (error) => {
                setUploading(false);
                reject(error);
            };
        });
    };

    const analyseThumbnail = async (type, id) => {
        if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 5) {
            if (aiCreditsLeft > 10) {
                if (type === 1) {
                    setUploading(true);
                    const docRef = doc(db, "Users", mainUserId);
                    const colRef = collection(docRef, "SoloAnalysis");
                    const addedColDocRef = await addDoc(colRef, {
                        user_id: mainUserId,
                        test_type: "Analysis",
                        date_created: serverTimestamp(),
                    });

                    const title = currentTitle.trimStart().trimEnd();
                    console.log(title);

                    const { file: resizedImage, initialWidth, initialHeight } = await resizeImage(
                        selectedImage
                    );

                    const docRef1 = doc(db, "Users", mainUserId);
                    const colRef1 = collection(docRef1, "UserThumbnails");
                    const newImgResp = await addDoc(colRef1, {
                        user_id: mainUserId,
                        test_type: "Analysis",
                        date_created: serverTimestamp(),
                    });
                    const extension = resizedImage.type.split("/").pop();
                    const imageRef = ref(
                        storage,
                        `/userThumbnails/${mainUserId} /${newImgResp.id}.${extension}`
                    );
                    const uploadTask = uploadBytesResumable(imageRef, resizedImage, {
                        contentType: "data_url",
                    });
                    await uploadTask;
                    const downloadURL = await getDownloadURL(imageRef);
                    const userDoc = doc(
                        db,
                        "Users",
                        mainUserId,
                        "UserThumbnails",
                        newImgResp.id
                    );
                    const data = {
                        thumbnail: downloadURL,
                        title: title,
                        should_analyse: true,
                        resolution_width: initialWidth,
                        resolution_height: initialHeight,
                    };
                    setDoc(userDoc, data, { merge: true });
                    updateDoc(
                        doc(db, "Users", mainUserId, "SoloAnalysis", addedColDocRef.id),
                        {
                            thumbnail_id: newImgResp.id,
                            thumbnail: downloadURL,
                            title: title,
                        }
                    ).then(async () => {
                        setTimeout(() => {
                            navigate(`/thumbnail-analysis/${newImgResp.id}`);
                            setUploading(false);
                            onClose();
                        }, 3000);

                    }).catch((e) => {
                        console.log(e);
                        setUploading(false);
                        toast("An error occured. Unable to upload images!", {
                            icon: ({ theme, type }) => (
                                <HiOutlineBell className="text-red-500 h-7 w-7" />
                            ),
                        });
                    });
                } else if (type === 2) {
                    setUploading(true);
                    const docRef = doc(db, "Users", mainUserId);
                    const colRef = collection(docRef, "SoloAnalysis");
                    const querySnapshot = await getDocs(query(colRef, where("thumbnail_id", "==", id)));
                    if (querySnapshot.empty) {
                        const addedColDocRef = await addDoc(colRef, {
                            user_id: mainUserId,
                            test_type: "Analysis",
                            thumbnail_id: id,
                            thumbnail: selectedTImage,
                            title: currentTitle,
                            date_created: serverTimestamp(),
                        }).then((docR) => {
                            const userDoc = doc(
                                db,
                                "Users",
                                mainUserId,
                                "UserThumbnails",
                                id
                            );
                            const data = {
                                should_analyse_alt: true,
                                resolution_width: 0,
                                resolution_height: 0,
                            };
                            setDoc(userDoc, data, { merge: true });

                            setTimeout(() => {
                                navigate(`/thumbnail-analysis/${id}`);
                                setUploading(false);
                                onClose();
                            }, 3000);
                        })
                            .catch((error) => {
                                setUploading(false);
                                console.error("Error adding document: ", error);
                            });
                    } else {
                        setTimeout(() => {
                            navigate(`/thumbnail-analysis/${id}`);
                            setUploading(false);
                            onClose();
                        }, 3000);
                        //window.location.href = `/thumbnail-analysis/${id}`;
                    }
                }
            } else {
                if (premiumStatus === 'TRIALING') {
                    setCreditBlockPopupTrial(true);
                } else {
                    setCreditBlockPopup(true);
                }

                toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                    position: "top-right",
                    autoClose: 7000,
                });

            }
        } else {
            setCreditBlockPopup(true);
        }
    };

    const viewOnDevices = async (type, id) => {
        if (type == 1) {
            try {
                if (selectedImage) {
                    console.log('selectedImage');
                    setUploading(true);
                    const docRef = doc(db, "Users", mainUserId);
                    const colRef = collection(docRef, "UserThumbnails")
                    const addedColDocRef = await addDoc(colRef, {
                        user_id: mainUserId,
                        date_created: serverTimestamp(),
                    })

                    const extension = selectedImage.type.split("/").pop();
                    const storageRef = ref(storage, `/userThumbnails/${mainUserId}/${addedColDocRef.id}.${extension}`);
                    const uploadTask = uploadBytesResumable(storageRef, selectedImage);

                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            //update progress
                            setUploadPercent(percent);
                        },
                        (err) => console.log(err),
                        () => {
                            //download url
                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                console.log(downloadURL);

                                const userDoc = doc(db, "Users", mainUserId, "UserThumbnails", addedColDocRef.id)
                                const data = {
                                    thumbnail: downloadURL,
                                    title: currentTitle
                                }
                                setDoc(userDoc, data, { merge: true }).then(async res => {

                                    //window.location.href = `/device-preview/${addedColDocRef.id}`;

                                    navigate(`/device-preview/${addedColDocRef.id}`);
                                    setUploading(false);
                                    onClose();

                                    //const devicePreviewUrl = `/device-preview/${addedColDocRef.id}`;
                                    //navigate(devicePreviewUrl);
                                });
                            });
                        }
                    );
                }
            } catch (error) {
                setUploading(false);
            }
        } else if (type == 2) {

            setTimeout(() => {
                navigate(`/device-preview/${id}`);
                setUploading(false);
                onClose();
            }, 1500);

            //window.location.href = `/device-preview/${id}`;
            //const devicePreviewUrl = `/device-preview/${id}`;
            //window.location =
            //navigate(devicePreviewUrl);
        }
    }

    const uploadThumbnail = async (url) => {
        console.log('processData');
        try {
            if (selectedImage) {
                console.log('selectedImage');
                setUploading(true);
                const docRef = doc(db, "Users", mainUserId);
                const colRef = collection(docRef, "UserThumbnails")
                const addedColDocRef = await addDoc(colRef, {
                    user_id: mainUserId,
                    date_created: serverTimestamp(),
                })

                const extension = selectedImage.type.split("/").pop();
                const storageRef = ref(storage, `/userThumbnails/${mainUserId}/${addedColDocRef.id}.${extension}`);
                const uploadTask = uploadBytesResumable(storageRef, selectedImage);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        //update progress
                        setUploadPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        //download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            console.log(downloadURL);

                            const userDoc = doc(db, "Users", mainUserId, "UserThumbnails", addedColDocRef.id)
                            const data = {
                                thumbnail: downloadURL,
                                title: currentTitle
                            }
                            setDoc(userDoc, data, { merge: true }).then(async res => {
                                //Redirect to Youtube
                                testYoutube(downloadURL, url);
                            });
                        });
                    }
                );
            }
        } catch (error) {
            setUploading(false);
        }
    }

    const openPopup = (url) => {
        const newWindow = window.open(url);
        if (newWindow && newWindow.focus) {
            newWindow.focus();
        } else {
            setPopupBlocked(true);
            setBlockedURL(url);
            alert("Popup blocked! Please allow popups for this website.");
        }
    };

    const openPopupAgain = (url) => {
        const newWindow = window.open(url, "_blank");
        if (newWindow && newWindow.focus) {
            newWindow.focus();
        } else {
            setPopupBlocked(true);
            setBlockedURL(url);
            alert("Popup blocked! Please allow popups for this website.");
        }

        setProcessing(false);
        setUploading(false);
        setSelectedImage(null);
        setCurrentTitle('');
        onClose();
    };

    const redirectReplace = (url) => {
        window.location.href = url;
    };

    const testYoutube = (thumbnailDBUrl, url) => {
        setProcessing(true);

        //Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        //Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            document.cookie = "test_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                //Set Page Intent
                const intent = "OPENTESTER";
                const userDoc = doc(db, "Users", mainUserId)
                const data = {
                    page_intent: intent,
                    tg_tt_current_Image: thumbnailDBUrl,
                    tg_tt_current_Title: currentTitle,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    ////insert current image and titles into cookies
                    document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
                    document.cookie = "tg_tt_current_Image=" + thumbnailDBUrl + "; path=/;";
                    document.cookie = "tg_tt_current_Title=" + currentTitle + "; path=/;";
                    //window.open(url, '_blank');

                    window.location.href = url;
                    setProcessing(false);
                    setUploading(false);

                    setSelectedImage(null);
                    setCurrentTitle('');
                    onClose();

                    //const newWindow = window.open(url);
                    //if (newWindow && newWindow.focus) {
                    //    newWindow.focus();
                    //    setProcessing(false);
                    //    setUploading(false);

                    //    setSelectedImage(null);
                    //    setCurrentTitle('');
                    //    onClose();
                    //} else {
                    //    window.location.href = url;
                    //    //openPopup(url)
                    //}
                }).catch((e) => {
                    setProcessing(false);
                    toast("Unable to verify connection.", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                });

            } else {
                setProcessing(false);
                setExtensionModal(true);
                //modalClose();
            }
        } else {
            setProcessing(false);
            //Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const handleModalClose = (e) => {
        if (!uploading && !processing) {
            if (e.target.id === "thumbnail-upload-modal") {
                setSelectedImage(null);
                onClose();
            }
        }
    }

    const modalClose = (e) => {
        setSelectedImage(null);
        onClose();
    }

    const handleOnTextChanged = (e) => {
        setCurrentTitle(e.target.value);
    }

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const handleSearchModalClose = (e) => {
        if (e.target.id === "search-page-modal") {
            setSearchModal(false);
        };
    }

    const searchModalClose = (e) => {
        setSearchModal(false);
    }

    const openSearch = (type) => {
        //Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        //Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                if (type === 2) {
                    setSearchType(2)
                }
                setSearchModal(true);
            } else {
                setExtensionModal(true);
            }
        } else {
            //Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const handleSearchQuerySubmit = async (e) => {
        e.preventDefault();

        //Set Page Intent
        const intent = "OPENTESTER";
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
            page_intent: intent
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
            document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
            let url = `https://www.youtube.com/results?search_query=${searchQuery}`;
            searchModalClose();
            if (searchType === 2) {
                testYoutube(selectedTImage, url);
            } else {
                uploadThumbnail(url);
            }

        }).catch((e) => {
            toast("Unable to verify connection.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        });
    }

    const handleImageRadio = async (id, title, thumbnail) => {
        setSelectType(2);
        setSelectedID(id);
        setCurrentTitle(title);
        setSelectedTImage(thumbnail);
    }

    useEffect(() => {
        if (isVisible) {
            loadThumbnails();
        }
    }, [isVisible]);

    useEffect(() => {
        setCurrentTitle('');
    }, [selectedIndex]);

    const loadThumbnails = async (e) => {
        const colRef = collection(db, 'Users', mainUserId, 'UserThumbnails');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            endBefore(null),
            limit(12)
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                setThumbnailsData(newData);
                setThumbsLoaded(true);
            })
    }

    const installExtension = (url) => {
        const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
        const popupWindow = window.open(url, '_blank', windowFeatures);
        //Check if the popup was blocked
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            console.log('Popup was blocked or unable to open.');
            window.open(url, '_blank');
        } else {
            console.log('Popup opened successfully.');
        }
    };

    if (!isVisible) return null;

    return (
        <>
            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'}  z-[86875] transition ease-in duration-700 fixed top-0 left-0 right-0 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-start justify-between p-4 border-b rounded-t">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-normal tracking-[-0.015em] leading-relaxed text-gray-800">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or disabled the extension in your browser.
                            </p>
                            <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />
                            {/* <div className="inline-flex text-[15px] font-light leading-relaxed text-gray-700 dark:text-gray-600">
                                <MdOutlineTipsAndUpdates className='text-[32px] text-blue-700 pt-1 mr-2 w-7' /> <span>ThumbnailGeek Chrome Extension is used to insert your thumbnail on Youtube homepage for comparison.</span>
                            </div> */}
                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={() => installExtension('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Install Extension
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="search-page-modal" onClick={handleSearchModalClose} className={`${searchModal === true ? '' : 'hidden'} z-[86875] transition ease-in duration-700 fixed top-0 left-0 right-0 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 ">
                                What Niche Will You Like To Compare to?
                            </h3>
                            <button onClick={searchModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form onSubmit={handleSearchQuerySubmit}>
                            <div className="p-6 space-y-6">
                                <div>
                                    {/* <label htmlFor="search_query" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Query</label> */}
                                    <input autoFocus onChange={(e) => setSearchQuery(e.target.value)} type="text" id="search_query" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter your search query eg. Food" required />
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Go</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/focus-boards'} />

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/'} />

            <div onClick={handleModalClose} id="thumbnail-upload-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full 
            bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-3xl md:h-auto mx-0">

                    <div className=" relative w-full h-full min-h-[350px] max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className='relative border-gray-200 text-gray-800 border-b text-sm p-1 pb-0'>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className={`inline-flex p-3`}>
                                        <p className='hidden lg:block'>Upload New Thumbnail</p>
                                    </span>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className="inline-flex p-3">
                                        <p className='hidden lg:block'>Use Existing Thumbnail</p>
                                    </span>
                                </Tab>
                                <button onClick={modalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </Tab.List>
                            <Tab.Panels className='w-full'>

                                <Tab.Panel className='w-full md:h-auto'>
                                    {
                                        uploading === false
                                            ?
                                            <>
                                                <div className="grid grid-cols-8 p-2">
                                                    {/* Left */}
                                                    <div className="col-span-4 h-full justify-left lg:flex-none rounded-l-lg p-4">
                                                        <div className="w-full h-[195px] relative bg-gray-200 rounded-md mb-2">
                                                            <div className="w-full h-[195px] relative bg-gray-200 rounded-md mb-2">
                                                                <div {...getRootProps()} className="w-full h-[195px] group first-letter:">
                                                                    <input {...getInputProps()} />
                                                                    {selectedImage ? (
                                                                        <img className="w-full cursor-pointer absolute top-0 h-[195px] border-0 object-cover rounded-md"
                                                                            src={URL.createObjectURL(selectedImage)}
                                                                        />
                                                                    ) : (
                                                                        <div className="w-full h-[195px] group-hover:cursor-pointer flex flex-col items-center justify-center text-sm text-center mb-2 border-2 border-dashed border-blue-300 rounded-md bg-gray-50 group-hover:bg-gray-100">
                                                                            <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                                            </svg>
                                                                            <span>Click to Select Thumbnail</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <input
                                                            autoFocus
                                                            onChange={handleOnTextChanged}
                                                            value={currentTitle}
                                                            type="text"
                                                            className="w-full text-sm border-b border-gray-500 p-0 pl-1 pt-2 pb-1 mb-5 focus:outline-none pr-12"
                                                            placeholder="Title of your video"
                                                        />
                                                    </div>

                                                    {/* Right */}
                                                    <div className="col-span-4 lg:block flex items-center justify-center h-full p-4">

                                                        {
                                                            selectedImage && currentTitle !== ''
                                                                ?
                                                                <div className="">
                                                                    <p className="text-sm mb-2 font-medium">View on Youtube</p>
                                                                    {/* <div className="flex flex-row space-x-2 mt-2"> */}
                                                                    <button onClick={() => testExtension(1)} className="w-full inline-flex justify-center items-center px-5 py-2 bg-[#FC2959] hover:bg-[#FC2959] active:bg-[#FC2959] hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                        <BsYoutube className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Home Page</span>
                                                                    </button>

                                                                    <button onClick={() => openSearch(1)} className="mt-2 w-full inline-flex justify-center items-center px-5 py-2 bg-blue-600 hover:bg-blue-700 active:bg-blue-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                        <BiSearch className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Search Page</span>
                                                                    </button>
                                                                    {/* </div> */}

                                                                    {/* <button onClick={() => analyseThumbnail(1)} className=" mt-5 w-full inline-flex justify-center items-center px-5 py-3 bg-purple-600 hover:bg-purple-700 active:bg-purple-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                        <IoAnalytics className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Analyze Thumbnail With AI</span>
                                                                        {(accessLevel !== 1 && accessLevel !== 2) && <FaCrown className='h-4 w-4 ml-2 text-yellow-400' />}
                                                                    </button>

                                                                    <button onClick={() => viewOnDevices(1, null)} className="w-full inline-flex justify-center items-center mt-2 px-5 py-3 bg-gray-700 hover:bg-gray-800 active:bg-gray-900 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                        <FiSmartphone className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>View on different devices</span>
                                                                    </button> */}
                                                                </div>
                                                                :
                                                                <div className="">
                                                                    <p className="text-sm mb-2 font-medium">View on Youtube</p>
                                                                    {/* <div className="flex flex-row space-x-2"> */}
                                                                    <button type='submit' className="w-full inline-flex justify-center items-center px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                        <BsYoutube className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Home Page</span>
                                                                    </button>

                                                                    <button type='submit' className="mt-2 w-full inline-flex justify-center items-center px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                        <BiSearch className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Search Page</span>
                                                                    </button>
                                                                    {/* </div> */}


                                                                    {/* <button type='submit' className=" mt-5 w-full inline-flex justify-center items-center px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                        <IoAnalytics className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>Analyze Thumbnail With AI</span>
                                                                        {(accessLevel !== 1 && accessLevel !== 2) && <FaCrown className='h-4 w-4 ml-2 text-yellow-400' />}
                                                                    </button>



                                                                    <button type='submit' className="w-full inline-flex justify-center items-center mt-2 px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                        <FiSmartphone className="w-5 h-5 mr-2 mt-[2px]" />
                                                                        <span>View on different devices</span>
                                                                    </button> */}

                                                                </div>
                                                        }

                                                        <div className="mt-4 pt-0">
                                                            <Hint hintText={'Popups need to be allowed in your browser. To allow ThumbnailGeek to redirect Youtube and insert your thumbnail.'} fontSizePx={'13px'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="flex flex-col items-center justify-center pb-16">
                                                {uploading && !processing &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-60">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        {/* <p className='font-bold text-2xl'>{uploadPercent}%</p> */}
                                                    </>
                                                }
                                                {uploading && processing && !popupBlocked &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <p className='font-semibold text-xl'>Preparing for Youtube</p>
                                                    </>
                                                }

                                                {uploading && processing && popupBlocked &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <h3 className='font-semibold text-2xl text-red-500'>Popup blocked!</h3>
                                                        <p className='font-normal text-base mb-4'> Please allow popups for this website <br />then hit the button below</p>

                                                        <button onClick={() => openPopupAgain(blockedURL)} className="w-64 inline-flex justify-center items-center px-5 py-3 
                                                        bg-blue-500 hover:bg-blue-500 active:bg-blue-600 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                            <span>Continue Test on Youtube</span>
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                    }
                                </Tab.Panel>

                                <Tab.Panel className='w-full h-full'>

                                    {
                                        uploading === false
                                            ?
                                            <>
                                                <div className="grid grid-cols-8 p-2">
                                                    <div className="col-span-5 h-full justify-left lg:flex-none rounded-l-lg px-4 border-r mt-2">
                                                        <div className="w-full h-full rounded-md mb-2 overflow-y-hidden duration-200">
                                                            {
                                                                thumbsLoaded === false
                                                                    ?
                                                                    <div className="w-full h-full text-sm grid place-items-center">
                                                                        Loading thumbnails...
                                                                    </div>
                                                                    :
                                                                    <div className="grid grid-cols-3 gap-1 ">
                                                                        {
                                                                            thumbnailsData.length === 0
                                                                                ?
                                                                                <div className="w-full h-full flex flex-col justify-center items-start text-sm ">
                                                                                    No thumbnails uploaded!
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    {thumbnailsData?.map((item, i) => (
                                                                                        <div key={i} className='inline-block col-span-1'>
                                                                                            <div className="w-full">
                                                                                                <input id={i} type="radio" onChange={() => handleImageRadio(item.id, item.title, item.thumbnail)} value="" name="image-radio" className="peer hidden" />
                                                                                                <label htmlFor={i} className="flex cursor-pointer justify-center items-center h-full w-full p-1 peer-checked:border peer-checked:rounded-md peer-checked:border-blue-500">
                                                                                                    <img className="inline-block w-full rounded-md" src={item.thumbnail} alt="image-thumbnail" />
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-span-3 lg:block flex items-center justify-center h-full p-4">

                                                        <>
                                                            {
                                                                selectedTImage && currentTitle !== ''
                                                                    ?
                                                                    <div className="">
                                                                        <p className="text-sm mb-2 font-medium">View on Youtube</p>
                                                                        {/* <div className="flex flex-row space-x-2 "> */}
                                                                        <button onClick={() => testYoutube(selectedTImage, 'https://www.youtube.com')} className="w-full inline-flex justify-center items-center px-5 py-3 bg-[#FC2959] hover:bg-[#FC2959] active:bg-[#FC2959] hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                            <BsYoutube className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Home Page</span>
                                                                        </button>

                                                                        <button onClick={() => openSearch(2)} className="mt-2 w-full inline-flex justify-center items-center px-5 py-3 bg-blue-600 hover:bg-blue-700 active:bg-blue-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                            <BiSearch className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Search Page</span>
                                                                        </button>
                                                                        {/* </div> */}

                                                                        {/* <button onClick={() => analyseThumbnail(2, selectedID)} className=" mt-5 w-full inline-flex justify-center items-center px-3 py-3  bg-purple-600 hover:bg-purple-700 active:bg-purple-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                            <IoAnalytics className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Analyze Thumbnail With AI</span>
                                                                            {(accessLevel !== 1 && accessLevel !== 2) && <FaCrown className='h-4 w-4 ml-2 text-yellow-400' />}
                                                                        </button>

                                                                        <button onClick={() => viewOnDevices(2, selectedID)} className="w-full inline-flex justify-center items-center mt-2 px-5 py-3 bg-gray-700 hover:bg-gray-800 active:bg-gray-900 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                                            <FiSmartphone className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>View on different devices</span>
                                                                        </button> */}

                                                                        <div className="mt-4 pt-0">
                                                                            <Hint hintText={'Popups need to be allowed in your browser. To allow ThumbnailGeek to redirect Youtube and insert your thumbnail.'} fontSizePx={'13px'} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="">
                                                                        <p className="text-sm mb-2 font-medium">View on Youtube</p>
                                                                        {/* <div className="flex flex-row space-x-2 mt-2"> */}
                                                                        <button type='submit' className="w-full inline-flex justify-center items-center px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                            <BsYoutube className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Home Page</span>
                                                                        </button>

                                                                        <button type='submit' className="mt-2 w-full inline-flex justify-center items-center px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                            <BiSearch className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Search Page</span>
                                                                        </button>
                                                                        {/* </div> */}

                                                                        {/* <button type='submit' className=" mt-5 w-full inline-flex justify-center items-center px-3 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                            <IoAnalytics className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>Analyze Thumbnail With AI</span>
                                                                            {(accessLevel !== 1 && accessLevel !== 2) && <FaCrown className='h-4 w-4 ml-2 text-yellow-400' />}
                                                                        </button>

                                                                        <button className="w-full inline-flex justify-center items-center mt-2 px-5 py-3 bg-gray-300  hover:cursor-default text-black text-sm rounded-lg shadow-sm">
                                                                            <FiSmartphone className="w-5 h-5 mr-2 mt-[2px]" />
                                                                            <span>View on different devices</span>
                                                                        </button> */}

                                                                        <div className="mt-4 pt-0">
                                                                            <Hint hintText={'Popups need to be allowed in your browser. To allow ThumbnailGeek to redirect Youtube and insert your thumbnail.'} fontSizePx={'13px'} />
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>



                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="flex flex-col items-center justify-center pb-16">
                                                {uploading && !processing &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-60">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        {/* <p className='font-bold text-2xl'>{uploadPercent}%</p> */}
                                                    </>
                                                }
                                                {uploading && processing && !popupBlocked &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <p className='font-semibold text-xl'>Preparing for Youtube</p>
                                                    </>
                                                }

                                                {uploading && processing && popupBlocked &&
                                                    <>
                                                        <div id='inner-container' className="rounded-lg w-44">
                                                            <Lottie animationData={image_upload} loop={true} />
                                                        </div>
                                                        <h3 className='font-semibold text-2xl text-red-500'>Popup blocked!</h3>
                                                        <p className='font-normal text-base mb-4'> Please allow popups for this website <br />then hit the button below</p>

                                                        <button onClick={() => openPopupAgain(blockedURL)} className="w-64 inline-flex justify-center items-center px-5 py-3 
                                                            bg-blue-500 hover:bg-blue-500 active:bg-blue-600 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                            <span>Continue Test on Youtube</span>
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                    }

                                </Tab.Panel>

                            </Tab.Panels>
                        </Tab.Group>
                    </div>

                </div>
            </div >
        </>
    );
};

export default ThumbnailUploadDialog;
