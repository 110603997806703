import React from 'react'

const BucketCollageImages = ({ itemsData }) => {
    // const 

    var lengthOfItems = itemsData.length;
    // var items = itemsData.items;

    return (
        <>

            {lengthOfItems > 2 ?

                <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-white">

                    <div className="w-full h-full col-span-2 row-span-2 bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[1].thumbnail} alt="image" />
                    </div>
                    <div className="w-full h-full col-span-2 row-span-2  bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[2].thumbnail} alt="image" />
                    </div>
                    <div className="w-full h-full col-span-4 row-span-4 bg-gray-200">
                        <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} alt="image" />
                    </div>

                </div>

                :
                <>
                    {
                        lengthOfItems === 1
                            ?
                            <div className="w-full h-full">
                                <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} />
                            </div>
                            :
                            <div className="w-full h-full overflow-hidden grid grid-cols-4 grid-rows-4 gap-[2px] space-y-0 bg-white">
                                <div className="w-full h-full col-span-4 row-span-2 bg-gray-400">
                                    <img className='w-full h-full object-cover' src={itemsData[0].thumbnail} alt="image" />
                                </div>
                                <div className="w-full h-full col-span-4 row-span-2 bg-gray-200">
                                    <img className='w-full h-full object-cover' src={itemsData[1].thumbnail} alt="image" />
                                </div>
                            </div>
                    }
                </>

            }
        </>
    )
}

export default BucketCollageImages