import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { useUserAuth } from '../../../context/na-UserAuthContext'
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import { AiOutlinePicture } from "react-icons/ai";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { BsStars, BsListTask } from 'react-icons/bs'
import { BiCategoryAlt, BiTrash } from 'react-icons/bi'
import SideBar from '../../components/SideBar'
import { db, storage } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { collection, getDocs, query, orderBy, limit, startAfter, endBefore, where, getDoc, deleteDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import logo from '../../../assets/logo_full_32.png';
import { Tab } from '@headlessui/react';
import { toast } from 'react-toastify';
import Lottie from "lottie-react";
import loading_animation from "../../../assets/animations/loading_tg.json";
import Avatar from '../../components/Avatar';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import Tooltip from '../../components/Tooltip';
import { MdOutlineArrowBack } from 'react-icons/md';
import { TfiDownload } from 'react-icons/tfi';

const ViralLibraryCategory = () => {
    let { category_id } = useParams();
    let navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { accessLevel } = PremiumCredentials();
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [vTTItems, setVTTItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [favLoaded, setFavLoaded] = useState(false);
    const [favLoading, setFavLoading] = useState(false);
    const [catItems, setCatItems] = useState([]);
    const [page, setPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [catInfo, setCatInfo] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [favPage, setFavPage] = useState(1);
    const [favLastDoc, setFavLastDoc] = useState(null);
    const [favoriteItems, setFavoriteItems] = useState([]);
    const mainUserId = localStorage.getItem("mainUserId");
    const [favItemsFeed, setFavItemsFeed] = useState([]);
    const [vCatItems, setVCatItems] = useState([]);
    const { pathname } = useLocation();
    const PAGE_SIZE = 6;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleFavorite = async (itemId, thumbnail, saved_thumbnail, title, avatar, name) => {
        if (favoriteItems.includes(itemId)) {
            // Item is already favorited, remove it from the list
            setFavoriteItems(favoriteItems.filter((id) => id !== itemId));

            try {
                const userDoc = doc(db, 'Users', mainUserId, 'ViralPicks', itemId);
                await deleteDoc(userDoc);
                toast("Removed thumbnail pick", {
                    position: 'top-center',
                    autoClose: 1000,
                    icon: ({ theme, type }) => <BiTrash className='text-red-500 h-7 w-7' />
                });
            } catch (error) {
                console.error('Error deleting document from subcollection:', error);
            }
        } else {
            // Item is not favorited, add it to the list
            setFavoriteItems([...favoriteItems, itemId]);

            try {
                const userDoc = doc(db, "Users", mainUserId, "ViralPicks", itemId);
                const data = {
                    id: itemId,
                    thumbnail: thumbnail,
                    saved_thumbnail: saved_thumbnail,
                    title: title,
                    avatar: avatar,
                    name: name,
                    date_created: serverTimestamp(),
                }
                setDoc(userDoc, data, { merge: true })
                    .then(async res => {
                        toast("Saved thumbnail pick", {
                            position: 'top-center',
                            autoClose: 1000,
                            icon: ({ theme, type }) => <AiOutlinePicture className='text-green-500 h-7 w-7' />
                        });
                    });
            } catch (error) {
                console.error('Error adding document to subcollection: ', error);
            }
        }
    };

    const getItems = async () => {
        const colRef = collection(db, 'Library');
        const q = query(
            colRef,
            where("category_id", "==", category_id),
            orderBy("date_created", "desc"),
            endBefore(null),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                // //console.log(lastVisible);
                setLastDoc(lastVisible);
                setCatItems(newData);
                setLoaded(true);

            })
    }

    const fetchMore = async () => {
        setLoading(true);

        const colRef = collection(db, 'Library');
        const q = query(
            colRef,
            where("category_id", "==", category_id),
            orderBy("date_created", "desc"),
            startAfter(lastDoc),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const isQuerySnapshotEmpty = documentSnapshots.size === 0;

                if (!isQuerySnapshotEmpty) {
                    const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    setLastDoc(lastVisible);
                    setCatItems((vTTItems) => [...vTTItems, ...newData]);

                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        fetchMore();
    }, [page])

    const onScroll = () => {
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight

        if (scrollTop + clientHeight >= scrollHeight) {
            setPage(page + 1)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [catItems])

    const getCategoryItem = async () => {
        const docRef = doc(db, 'LibraryCategories', category_id);
        const vDocSnap = await getDoc(docRef);
        if (vDocSnap.exists()) {
            //console.log("Document data:", vDocSnap.data());
            setCatInfo(vDocSnap.data());
        }
    }

    const getFavoriteItems = async () => {
        const colRef = collection(db, "Users", mainUserId, "ViralPicks",);
        const q = query(
            colRef,
            orderBy('date_created', 'desc'),
            endBefore(null),
            limit(12)
        );

        try {
            const documentSnapshots = await getDocs(q);
            const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

            setFavItemsFeed(newData);
            setFavLastDoc(lastVisible);
            setFavLoaded(true);
        } catch (error) {
            console.error('Error getting items:', error);
        }
    };

    const fetchMoreFavs = async () => {
        setFavLoading(true);

        const colRef = collection(db, "Users", mainUserId, "ViralPicks",);
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            startAfter(favLastDoc),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const isQuerySnapshotEmpty = documentSnapshots.size === 0;
                if (!isQuerySnapshotEmpty) {
                    const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    setFavLastDoc(lastVisible);
                    setFavItemsFeed((favItemsFeed) => [...favItemsFeed, ...newData]);
                    setFavLoading(false);
                } else {
                    setFavLoading(false);
                }
            })
    }

    const getViralUserFavorites = async () => {
        try {
            const viralPicksCollection = collection(db, 'Users', mainUserId, 'ViralPicks');
            const q = query(viralPicksCollection);
            const querySnapshot = await getDocs(q);

            const ids = [];
            querySnapshot.forEach((doc) => {
                ids.push(doc.id);
            });

            setFavoriteItems(ids);
        } catch (error) {
            console.error('Error getting favorite items:', error);
        }
    }

    useEffect(() => {
        getViralUserFavorites();
        getItems();
        getCategoryItem();
        getFavoriteItems();
    }, []);

    useEffect(() => {
        fetchMoreFavs();
    }, [favPage])

    const onScrollFavs = () => {
        // const scrollTop = document.documentElement.scrollTop
        // const scrollHeight = document.documentElement.scrollHeight
        // const clientHeight = document.documentElement.clientHeight

        // if (scrollTop + clientHeight >= scrollHeight) {
        //     setFavPage(favPage + 1)
        // }

        const scrollTop = window.scrollY;
        const scrollHeight = document.body.scrollHeight;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            setFavPage(favPage + 1);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollFavs)
        return () => window.removeEventListener('scroll', onScrollFavs)
    }, [favItemsFeed])

    const goToItemDetails = (id) => {
        let path = `/viral-library/details/${id}`;
        navigate(path);
    }

    const goBack = () => {
        let path = `/viral-library?tab=1`;
        navigate(path);
    }

    const extractSectionFromURL = (url) => {
        const sections = url.split('/');
        return sections[4];
    };

    const downloadThumbnail = async (url) => {
        if (accessLevel === 2) {
            const videoId = extractSectionFromURL(url);
            const newLink = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }

    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Viral Categories</title>
                <meta name="description" content="Dashboard of Thumbnail Geek" />
            </Helmet>

            {/* <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/viral-library/details/' + item_id} /> */}

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="viral-library" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24 w-[calc(100%)] lg:w-[calc(100%_-_55px)] lg:w-[calc(100%_-_240px)] 2xl:w-[calc(100%_-_280px)]">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-50 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">
                            {/* <div className="">
                                <h5 hidden className={`text-xl text-gray-600 font-semibold lg:block`}>{catInfo.category_name}</h5>
                            </div> */}
                            <div className="flex flex-row justify-center items-center">
                                <button onClick={() => goBack()} className={`text-gray-800 bg-gray-100 hover:bg-gray-200
                             mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                    <MdOutlineArrowBack />
                                </button>
                                <div className="">
                                    <h5 hidden className={`text-xl text-gray-600 font-semibold lg:block`}>Viral Library • {catInfo.category_name} </h5>
                                </div>
                            </div>

                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                    {/* <!-- Profile dropdown --> */}
                                    {/* <GoToYoutubeBtn /> */}
                                    <Avatar />
                                </div>
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:ml-8 lg:pt-[11px] pt-[11px]">
                            <div className="max-w-7xl w-full mt-[48px] pt-4 flex flex-wrap justify-start overflow-scroll min-h-screen ">
                                {
                                    loaded === false
                                        ?
                                        <>
                                            <div className="w-full flex flex-wrap ">
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {catItems?.map((item, i) => (
                                                item.is_active !== false
                                                    ?
                                                    <>

                                                        <div className={`relative text-gray-800 w-64 2xl:w-72 mt-5 pt-2 pb-3 text-[0.9rem] mx-2 mb-5 group`}>
                                                            {/* <div className="absolute opacity-0 group-hover:opacity-100 right-1/2 translate-x-1/2 -top-6 z-20 rounded-md bg-white px-3 py-1 text-xs shadow-md ">Click for details</div> */}

                                                            <div className="absolute opacity-0 group-hover:opacity-100 right-2 top-4 z-20 ">
                                                                <button onClick={() => downloadThumbnail(item.item_data.thumbnail)}
                                                                    className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">

                                                                    <TfiDownload className="text-gray-600 text-xl px-1 py-1" />
                                                                </button>
                                                                <button onClick={() => toggleFavorite(item.id, item.item_data.thumbnail, item.item_data.saved_thumbnail, item.item_data.title, item.item_data.avatar, item.item_data.name)} className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">
                                                                    {favoriteItems.includes(item.id) ? (
                                                                        <RiHeart3Fill className="text-gray-600 text-xl px-1 py-1" />
                                                                    ) : (
                                                                        <RiHeart3Line className="text-gray-400 hover:text-gray-600 text-xl px-1 py-1" />
                                                                    )}
                                                                </button>
                                                            </div>

                                                            <Tooltip content="Click for details" position="top">
                                                                <NavLink key={i} to={`/viral-library/details/${item.id}`}>
                                                                    <div className="mb-2 cursor-pointer">
                                                                        <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                                                                            <img className=" @apply inline-block mx-0 w-full rounded-lg" src={item.item_data.thumbnail} alt="" />
                                                                            <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            </Tooltip>

                                                            <NavLink key={i} to={`/viral-library/details/${item.id}`}>
                                                                <div className='flex'>
                                                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                        <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.item_data.avatar} alt="" />
                                                                    </div>
                                                                    <div className='flex-col w-9/12'>
                                                                        <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                            <p className='text-[13px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[14px] mb:text-mb'>{item.item_data.title}</p>
                                                                        </div>
                                                                        <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                            <p className='text-gray-500 leading-4 line-clamp-1'>{item.item_data.name}
                                                                                {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>

                                                    </>
                                                    :
                                                    <div key={item.id}></div>
                                            ))}
                                        </>
                                }
                                <div className="w-full mb-6 text-sm flex flex-col justify-center items-center">
                                    {
                                        loading &&
                                        <>
                                            <div className="w-full flex flex-wrap ">
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                    </div>

                                                    <div className="flex">
                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                            <span> &nbsp; </span>
                                                        </div>
                                                        <div className="w-[80%]">
                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                <span> &nbsp; </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <Lottie className='w-32 h-32' animationData={loading_animation} loop={true} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViralLibraryCategory