import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet";
import { useUserAuth } from '../../../context/na-UserAuthContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import CategoriesBar from '../../../components/categoriesBar/CategoriesBar'
import Video from '../../../components/video/Video'
import Header from '../../../components/header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { getPopularVideos } from '../../../redux/actions/videos.action'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { toast } from 'react-toastify';
import SideBar from '../../components/SideBar'
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar'
import friends from '../../../assets/svgs/friends.svg';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';


const ReferralSuite = () => {
    const scrollRef = useRef(null);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);


    const showCopiedToast = async (value) => {
        navigator.clipboard.writeText(value);
        toast.success('Copied to clipboard', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    const scrollLeft = () => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            container.scrollTo({
                left: container.scrollLeft - 400, // Adjust scroll amount as needed
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            container.scrollTo({
                left: container.scrollLeft + 400, // Adjust scroll amount as needed
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Earn | ThumbnailGeek</title>
                <meta name="description" content="Earn | ThumbnailGeek" />
            </Helmet>
            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="referrals" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-50 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">

                            <div className="">
                                <h5 hidden className={`text-xl text-gray-600 font-semibold lg:block`}>Earn with ThumbnailGeek</h5>
                            </div>

                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <GoToYoutubeBtn /> */}
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%_-_25px)] 2xl:ml-8 mt-20">

                            <div className=" mt-5 relative w-full overscroll-none">

                                <div className="flex overflow-x-auto font-normal text-gray-600 text-[15px] mt-3 p-3" ref={scrollRef}>

                                    <div class="flex-none mr-4">
                                        <div className="bg-white ring-1 sm:rounded-lg w-96">
                                            <div className="px-4 py-5 sm:p-6">
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">50% off for Chrome review</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        Review ThumbnailGeek's extension on the Chrome Web Store. Send us a screenshot once it has been posted to <a href='mailto:hello@thumbnailgeek.com' className="font-medium">hello@thumbnailgeek.com</a> and we'll give 50% off your next month.
                                                    </p>
                                                </div>
                                                <div className="mt-5">
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                                    >
                                                        Claim 50% Off
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-none mr-4">
                                        <div className="bg-white ring-1 sm:rounded-lg">
                                            <div className="px-4 py-5 sm:p-6 w-96">
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">50% off for TrustPilot review</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        Write a detailed review of ThumbnailGeek on TrustPilot. Send us a screenshot once it has been posted to <a href='mailto:hello@thumbnailgeek.com' className="font-medium">hello@thumbnailgeek.com</a> and we'll give 50% off your next month.
                                                    </p>
                                                </div>
                                                <div className="mt-5">
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                                    >
                                                        Claim 50% Off
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-none">
                                        <div className="bg-white ring-1 sm:rounded-lg w-96">
                                            <div className="px-4 py-5 sm:p-6">
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">10% off for link on Youtube</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        Paste the link below in your description of your video. Your video should have at least 1500 views.
                                                        Send us the link to your video to <a href='mailto:hello@thumbnailgeek.com' className="font-medium">hello@thumbnailgeek.com</a> and we'll give 10% off your next month.
                                                    </p>
                                                </div>
                                                <div className="mt-5">
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                                    >
                                                        Copy link for 10% Off
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <button
                                    className="absolute 2xl:hidden -left-2 top-28 transform -translate-y-1/2 ring-1 bg-gray-50 p-2 rounded-full"
                                    onClick={scrollLeft}
                                >
                                    <MdKeyboardArrowLeft />
                                </button>
                                <button
                                    className="absolute 2xl:hidden -right-2 top-28 transform -translate-y-1/2 ring-1 bg-gray-50 p-2 rounded-full"
                                    onClick={scrollRight}
                                >
                                    <MdKeyboardArrowRight />
                                </button>
                            </div>

                            {/* <div className=" mt-7">
                                    <div>
                                        <input onClick={() => showCopiedToast('http:sdkjfhsd.vcio.dfhs.com/redsjdfh')} type="text" readOnly='readOnly' id="search_query" className="bg-gray-50 border selection:bg-purple-300 select-text border-gray-300 text-gray-900 text-sm rounded-lg text-center block w-72 p-2.5 " value="http:sdkjfhsd.vcio.dfhs.com/redsjdfh" />
                                    </div>
                                </div>
                                <div onClick={() => showCopiedToast('http:sdkjfhsd.vcio.dfhs.com/redsjdfh')} className="hover:cursor-pointer text-[11px] mt-1 text-gray-900">
                                    Click to copy
                                </div> */}

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ReferralSuite