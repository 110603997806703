import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import axios from 'axios';
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import ReactPlayer from 'react-player/youtube';
import Cookies from 'js-cookie'
import { FcBinoculars, FcStackOfPhotos, FcTodoList, FcTreeStructure, FcMms, FcStatistics } from "react-icons/fc";
import { AiOutlineExclamation } from "react-icons/ai";
import { UserAuth } from '../../../context/AuthContext'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import Avatar from '../../components/Avatar';
import { CheckIcon } from '@heroicons/react/20/solid'
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import Hint from '../../components/Hint';
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  GiftIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline'
import Tooltip from '../../components/Tooltip';


const BasicUpload = () => {
  let navigate = useNavigate();
  const { user } = UserAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const popup = params.get('popup');
  const firstEntry = params.get('first-entry');
  const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
  const channelDisplayName = localStorage.getItem("channelDisplayName");
  const { premiumStatus, premiumTier, accountStatus, channelLinked } = PremiumCredentials();
  const mainUserId = localStorage.getItem("mainUserId");
  const [darkMode, setDarkMode] = useState(false);
  const [boardCount, setBoardCount] = useState(0);
  const [splitTestCount, setSplitTestCount] = useState(0);
  const [titleGenCount, setTitleGenCount] = useState(0);
  const [thumbGenCount, setThumbGenCount] = useState(0);
  const [thumbAnalysisCount, setThumbAnalysisCount] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [extensionModal, setExtensionModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [selectType, setSelectType] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const [uploadPopup, setUploadPopup] = useState(false)

  const {
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png, image/gif',
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 2 MB in bytes
    onDrop: acceptedFiles => {

      if (fileRejections.length === 0) {
        setSelectedImage(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
        // setUploadBtnActive(true);
        // openUploadModal();
      } else {
        // Handle file rejection error if needed
        console.log('File rejected:', fileRejections);
      }
    }
  });

  const modalClose = (e) => {
    if (e.target.id === "thumbnail-upload-modal") {
      setUploadPopup(false);
    }
  }
  const uploadModalClose = (e) => {
    setUploadPopup(false);
  }

  useEffect(() => {
    if (popup) {
      //show popup
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | ThumbnailGeek</title>
        <meta name="description" content="Dashboard | ThumbnailGeek" />
      </Helmet>

      <div onClick={modalClose} id="thumbnail-upload-modal" className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[12345] w-full 
      bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
        <div className="relative w-full h-full max-w-3xl md:h-auto mx-0">
          <div className="grid grid-cols-8 relative w-full h-full max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700">

            {/* <SlClose onClick={modalClose} className='absolute right-2 top-2 text-lg cursor-pointer' /> */}
            {/* Left */}
            <div className="col-span-4 pt-4 h-full justify-left lg:flex-none rounded-l-lg bg-blue-100 p-3">
              <div className="w-full h-[195px] relative bg-gray-200 rounded-md mb-2 mt-3">
                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-44 mb-8 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer  hover:bg-gray-100 " {...getRootProps()}>
                  <div className="flex flex-col justify-center items-center pt-5 pb-5 px-5">
                    <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                    </svg>
                    <>
                      <p className="mb-2 text-md text-gray-500 dark:text-gray-400">Drag your images here or click to <span className="font-semibold">browse</span></p>
                      <p className="text-xs text-gray-500 dark:text-gray-300">JPG, GIF, or PNG (1280x720)</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">• 2MB Limit •</p>
                    </>

                  </div>
                  <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                </label>

              </div>
            </div>

            {/* Right */}
            <div className="col-span-4 lg:block flex items-center justify-center h-full">
              {/* <img src={ratingGirl} alt='image' className='w-[512px] mt-12 rounded-lg transition-opacity duration-1000' /> */}
            </div>

          </div>

        </div>
      </div>

      <div className='bg-gray-50' >
        <SideBar selectedSideBarItem="dashboard" darkMode={false} />

        <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
          <div className="px-6 -my-6 min-h-screen bg-gray-50 ">

            <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-30 right-0 top-0 flex justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4">
              <div className="">
                <h5 className={`text-xl text-gray-600 font-semibold lg:block`}>Thumbnail Upload</h5>
              </div>
              <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                <GoToYoutubeBtn />
                <Avatar />
              </div>
            </div>

            <div className="mt-20 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mr-5">
              <div className="w-full h-full mx-auto">




              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export default BasicUpload