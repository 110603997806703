import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { NavLink, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Avatar = () => {
    const navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelEmail = localStorage.getItem("channelEmail");
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [email, setEmail] = useState(false);
    const mainUserId = localStorage.getItem("mainUserId");
    const { user, logOut } = UserAuth();

    const handleOnClose = (e) => {
        setProfileDropdown(false);
    }

    const userNavigation = [
        { name: 'Sign out', onClick: handleSignOutClick },
    ];

    async function handleSignOutClick(event) {
        event.preventDefault();
        try {
            console.log("Logout clicked");
            document.cookie = 'TG_AT=; max-age=0;';
            document.cookie = 'TG_Avatar=; max-age=0;';
            document.cookie = 'TG_ChannelName=; max-age=0;';
            await logOut();
            window.window.location.href = '/login';
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem("currentUser"));
        if (user == null && savedUser == null) {
            navigate("/login");
        }
    }, [user]);

    return (
        <Menu as="div" className="relative ml-3">
            <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full ring-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <img
                        className="h-8 w-8 rounded-full"
                        src={channelPhotoUrl == null ? '' : channelPhotoUrl}
                        alt=""
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >

                <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-3 border-b">
                        <p className="truncate text-sm font-medium text-gray-900">{channelDisplayName || 'No Channel Details'}</p>
                    </div>
                    {userNavigation.map((item) => (
                        <Menu.Item key={item.name} className='cursor-pointer'>
                            {({ active }) => (
                                <a
                                    onClick={item.onClick}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                    )}
                                >
                                    {item.name}
                                </a>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Avatar