import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDropzone } from "react-dropzone";
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { IoSparkles, IoSparklesOutline, IoRefresh, IoCloseCircle } from 'react-icons/io5'
import { MdOutlineArrowBack } from "react-icons/md";
import { SlRefresh } from 'react-icons/sl'
import axios from 'axios';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "@firebase/storage";
import { toast } from 'react-toastify';
import { HiOutlineBell } from "react-icons/hi";
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar';
import { Tab } from '@headlessui/react';

const FocusBoardDetails = () => {
    let { board_id } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [tItems, setTItems] = useState([]);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [originalTitle, setOriginalTitle] = useState('');
    const [refineText, setRefineText] = useState('');
    const [refining, setRefining] = useState(false);
    const [refinedTitles, setRefinedTitles] = useState([]);
    const [selectedID, setSelectedID] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [percent, setPercent] = useState(0);
    const [thumbnailsData, setThumbnailsData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [selectType, setSelectType] = useState(0);
    const [showImageAnalysisModal, setShowImageAnalysisModal] = useState(false);

    useEffect(() => {
        //   console.log('Doing Verification');

        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
        }
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            console.log("Only one image can be uploaded");
            return;
        }
        // do something with the uploaded file
        setSelectType(1);
        setSelectedImage(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: "image/*"
    });

    const handleOnClose = () => setShowModal(false);

    const getThumbnails = async () => {
        const docRef = doc(db, 'Users', mainUserId, 'FocusBoards', board_id);
        const testDocSnap = await getDoc(docRef);
        if (testDocSnap.exists()) {
            // console.log("Document data:", testDocSnap.data());
            setDocTitle(testDocSnap.data().name)
            setTItems(testDocSnap.data().items)

        } else {
            // doc.data() will be undefined in this case
            toast("Unable to load thumbnails", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
            console.log("No such document!");
        }
    }

    const handleDelete = (indexToDelete) => {
        setTItems((tItems) =>
            tItems.filter((_, index) => index !== indexToDelete)
        );
    };

    useEffect(() => {
        getThumbnails();
    }, []);

    const handleShowUploadModal = () => {
        setUploadModal(true);
        loadThumbnails();
    }

    const handleUploadModalClose = (e) => {
        if (e.target.id === "upload-modal") {
            setSelectedImage();
            setRefineText('');
            setRefinedTitles([]);
            setUploadModal(false);
            // onClose();
        }
    }

    const uploadModalClose = () => {
        setSelectedImage();
        setRefineText('');
        setRefinedTitles([]);
    }

    const handleOnTextChanged = (e) => {
        setRefineText(e.target.value);
    }

    const handleSelectOption = (title) => {
        setRefineText(title);
        setRefinedTitles([]);
    }

    const hideAISuggestions = (e) => {
        if (e.target.id === "inner-container") {
            setRefinedTitles([]);
        }
    }

    const refineTitle = async (e) => {
        e.preventDefault();

        if (refineText.trimStart().trimEnd() !== '' && refineText !== null) {
            setRefining(true);
            const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/optimize-ai-title/${mainUserId}`;
            const promptText = `Refine "${refineText}" into Youtube titles that sound persuasive and clickable. Use different tones. Do not repeat the same text. Make it as compelling as possible. Give 5 options.`
            const data = {
                prompt: promptText
            };

            axios.post(url, data)
                .then((response) => {
                    setRefining(false);
                    console.log(response.data.payload);
                    var resultText = response.data.payload;
                    var titles = resultText.split('\n');
                    setRefinedTitles(titles);
                    console.log(titles);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const processData = async (e) => {
        e.preventDefault();
        console.log('processData');

        if (selectedImage && selectType === 1) {
            console.log('selectedImage');
            setProcessing(true);
            const docRef = doc(db, "Users", mainUserId);
            const colRef = collection(docRef, "UserThumbnails")
            const addedColDocRef = await addDoc(colRef, {
                user_id: mainUserId,
                date_created: serverTimestamp(),
            })
            // ${selectedImage.name}

            console.log(addedColDocRef.id);
            // console.log(selectedImage.type);
            const extension = selectedImage.type.split("/").pop();

            const storageRef = ref(storage, `/userThumbnails/${mainUserId}/${addedColDocRef.id}.${extension}`);
            const uploadTask = uploadBytesResumable(storageRef, selectedImage);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log(downloadURL);

                        const userDoc = doc(db, "Users", mainUserId, "UserThumbnails", addedColDocRef.id)
                        const data = {
                            thumbnail: downloadURL,
                            title: refineText
                        }
                        setDoc(userDoc, data, { merge: true }).then(async res => {
                            const colRef = collection(docRef, "FBTests")
                            await addDoc(colRef, {
                                focus_board_id: board_id,
                                test_type: "fb_test",
                                user_id: mainUserId,
                                thumbnail: downloadURL,
                                title: refineText,
                                date_created: serverTimestamp(),
                            })

                            // Redirect
                            let path = `/focus-board-testing/${board_id}/${addedColDocRef.id}`;
                            navigate(path);
                            setProcessing(false);
                        });
                    });
                }
            );
        } else if (selectedImage && selectType === 2) {
            setProcessing(true);
            setPercent(25);

            console.log(originalTitle)
            console.log(refineText)

            if (originalTitle === refineText) {
                setPercent(75);
                // just go
                if (selectedID !== null) {
                    let path = `/focus-board-testing/${board_id}/${selectedID}`;
                    navigate(path);
                }
                setProcessing(false);
            } else {
                e.preventDefault();
                setPercent(92);
                if (selectedID !== null) {

                    const userDoc = doc(db, "Users", mainUserId, "UserThumbnails", selectedID)
                    const data = {
                        title: refineText,
                    }
                    setDoc(userDoc, data, { merge: true }).then(res => {
                        setPercent(99);
                        let path = `/focus-board-testing/${board_id}/${selectedID}`;
                        navigate(path);
                        setProcessing(false);
                    }).catch((e) => {
                        setProcessing(false);
                    });
                }
            }
        }
    }

    const loadThumbnails = async (e) => {
        const colRef = collection(db, 'Users', mainUserId, 'UserThumbnails');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            endBefore(null),
            limit(12)
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                setThumbnailsData(newData);
                setLoaded(true);
            })
    }

    const handleImageRadio = async (id, title, thumbnail) => {
        setSelectType(2);
        setSelectedID(id);
        setRefineText(title);
        setOriginalTitle(title);
        setSelectedImage(thumbnail);
    }

    const goBack = () => {
        let path = `/focus-boards/`;
        navigate(path);
    }

    useEffect(() => {
        setRefineText('');
        setSelectedID();
    }, [selectedIndex]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Focus Board Details</title>
                <meta name="description" content="Focus Board Details to Thumbnail Geek" />
            </Helmet>

            <div id="upload-modal" onClick={handleUploadModalClose} className={`${uploadModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                {
                    processing === false
                        ?
                        <div id='inner-container' onClick={hideAISuggestions} className="bg-white p-5 mb-16 rounded-lg w-96 border border-gray-400 shadow-md">
                            <h1 className="font-semibold text-center text-sm text-md mb-2 mt-2 text-gray-700">
                                Test your thumbnail. Compare against other great thumbnails.
                            </h1>
                            <form onSubmit={processData}>
                                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                    <Tab.List className='border-gray-200 text-gray-800 border-b text-sm'>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className={`inline-flex p-3`}>
                                                <p className=''>Upload New</p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="inline-flex p-3">
                                                <p className=''>Use Existing</p>
                                            </span>
                                        </Tab>
                                    </Tab.List>
                                    <Tab.Panels className='w-full'>
                                        <Tab.Panel className='w-full h-72 max-w-lg md:h-auto'>

                                            <div className="w-full h-[195px] relative bg-gray-200 rounded-md mb-2 mt-3">
                                                <div {...getRootProps()} className="w-full h-[195px] group first-letter:">
                                                    <input {...getInputProps()} />
                                                    {selectedImage && selectType === 1 ? (
                                                        <img className="w-full cursor-pointer absolute top-0 h-[195px] border-0 object-cover rounded-md"
                                                            src={URL.createObjectURL(selectedImage)}
                                                        />
                                                    ) : (
                                                        <div className="w-full h-[195px] group-hover:cursor-pointer flex flex-col items-center justify-center text-sm text-center mb-2 border border-dashed border-gray-800 rounded-md bg-slate-50 group-hover:bg-slate-200">
                                                            <span>Click to Select Thumbnail</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </Tab.Panel>

                                        <Tab.Panel className='w-full h-96 max-w-lg md:h-auto'>
                                            <div className="w-full h-[200px] rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                                                {
                                                    loaded === false
                                                        ?
                                                        <div className="w-full h-full text-sm grid place-items-center">
                                                            Loading thumbnails...
                                                        </div>
                                                        :
                                                        <div className="grid grid-cols-3 gap-1 ">
                                                            {thumbnailsData?.map((item, i) => (
                                                                <div key={i} className='inline-block col-span-1'>
                                                                    <div className="w-full">
                                                                        <input id={i} type="radio" onChange={() => handleImageRadio(item.id, item.title, item.thumbnail)} value="" name="image-radio" className="peer hidden" />
                                                                        <label htmlFor={i} className="flex cursor-pointer justify-center items-center h-full w-full p-1 peer-checked:border peer-checked:rounded-md peer-checked:border-blue-500">
                                                                            <img className="inline-block w-full rounded-md" src={item.thumbnail} alt="image-thumbnail" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                }
                                            </div>
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                                <div className="relative flex flex-col mt-3 px-1">
                                    <input
                                        onChange={handleOnTextChanged}
                                        value={refineText}
                                        type="text"
                                        className="text-sm border-b border-gray-500 p-0 pl-1 pt-2 pb-1 mb-5 focus:outline-none pr-12"
                                        placeholder="Title of your video"
                                    />

                                    <div className="absolute right-0 pr-0 inset-y-0 ">
                                        {
                                            refineText.trimStart().trimEnd().length > 0
                                                ?
                                                <>
                                                    {
                                                        refining === true
                                                            ?
                                                            <button disabled="disabled" type='submit' className={`hover:cursor-default text-gray-600 inline-flex p-[5px]`}>
                                                                <SlRefresh className='h-4 w-4 mt-[2px] animate-spin' />
                                                            </button>
                                                            :
                                                            <button onClick={refineTitle} type='submit' className={`hover:cursor-pointer hover:text-blue-900 text-blue-500 inline-flex p-[5px]`}>
                                                                <IoSparkles className='h-4 w-4 mt-[2px]' />
                                                            </button>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <button disabled="disabled" type='submit' className={`hover:cursor-default text-gray-800 inline-flex p-[5px]`}>
                                                        <IoSparklesOutline className='h-4 w-4 mt-[2px]' />
                                                    </button>
                                                </>
                                        }
                                    </div>

                                    {
                                        refinedTitles.length > 0
                                            ?
                                            <div className="absolute top-0 z-50 w-full mt-9 pr-1">
                                                <div id="dropdownDivider" class=" bg-white divide-y divide-gray-100 border border-gray-200 rounded shadow dark:bg-gray-700 dark:divide-gray-600">
                                                    <ul className="text-sm text-gray-700 dark:text-gray-200">
                                                        {
                                                            refinedTitles?.map((title, i) => (
                                                                title !== ''
                                                                    ?
                                                                    <li key={i} onClick={() => handleSelectOption(title)}>
                                                                        <p className="hover:cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                            {title = title.replace('"', '')
                                                                                .replace('"', '').replace('1. ', '').replace('2. ', '').replace('3. ', '').replace('4. ', '').replace('5. ', '')
                                                                                .replace('1.', '').replace('2.', '').replace('3.', '').replace('4.', '').replace('5.', '')
                                                                                .replace('1) ', '').replace('2) ', '').replace('3) ', '').replace('4) ', '').replace('5) ', '')
                                                                            }
                                                                        </p>
                                                                    </li>
                                                                    : ''
                                                            ))
                                                        }
                                                    </ul>

                                                </div>
                                            </div>
                                            :
                                            ''
                                    }

                                </div>
                                <div className="flex flex-col text-center">
                                    {
                                        refineText.trimStart().trimEnd().length > 0 && selectedImage != null
                                            ?
                                            <button type='submit' className="px-5 py-3 bg-blue-600 hover:bg-blue-700 active:bg-blue-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                Continue
                                            </button>
                                            :
                                            <button type='submit' disabled="disabled" className="px-5 py-3 bg-gray-200 text-gray-500 text-sm rounded-lg shadow-sm">
                                                Continue
                                            </button>
                                    }
                                </div>
                            </form>
                        </div>
                        :
                        <div id='inner-container' onClick={hideAISuggestions} className="bg-white p-5 mb-16 rounded-lg w-96 border border-gray-400 shadow-md">
                            <h1 className="font-semibold text-center text-md mb-4 mt-2 text-gray-700">
                                Processing...
                            </h1>
                            <div className="w-full h-[105px] text-center animate-pulse flex items-center justify-center bg-gray-200 rounded-md mb-2">
                                <p className='font-bold text-7xl '>{percent}%</p>
                            </div>
                        </div>
                }

            </div>

            <div className="bg-gray-50">
                <div className="ml-auto pb-40 lg:w-[100%]">
                    <div className="px-6 min-h-screen">

                        <div className={`fixed ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818]'} 
                                        border-b right-0 left-0 top-0 z-10 w-full px-6 pt-3 pb-3 flex items-center justify-between`}>
                            <div className="inline-flex">
                                <button onClick={() => goBack()} className={`text-gray-800 ${darkMode === false ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-700 hover:bg-gray-800 text-gray-300'}   mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                    <MdOutlineArrowBack />
                                </button>
                                <a className=" text-2xl font-bold text-gray-800 lg:text-2xl hover:text-gray-700" href="#">
                                    <div className='relative w-2/3 mt-2 '>
                                        <img className='w-full h-full' src={logo} />
                                    </div>
                                </a>
                            </div>

                            <div className='absolute right-1/2 translate-x-1/2 inline-flex' >
                                <div className="font-bold text-base text-center">
                                    <span className="font-normal text-gray-600">Focus Board |</span> {docTitle}
                                </div>
                            </div>

                            <div className="inline-flex space-x-2 justify-self-end">
                                <button onClick={handleShowUploadModal} className='text-gray-800 border border-blue-700 bg-gray-100 hover:text-black hover:bg-gray-200 active:bg-gray-300  mr-4 mb-0 pb-1 pt-1 pr-4 pl-3 rounded-md  text-xl'>
                                    <p className='text-sm ml-1 '>Upload Your Thumbnail</p>
                                </button>
                                <Avatar />
                            </div>
                        </div>

                        <div className="pt-20">
                            <div className="2xl:max-w-3xl mx-auto text-center my-6 text-gray-500/90 font-medium">
                                Compare your thumbnail against the thumbnails of this focus board. <br />
                                Perform AI assisted analysis or visually compare to see if your thumbnail will attract more clicks.
                            </div>
                            <div className='3xl:w-[1440px] mx-auto pt-6 mt-3 px-12 rounded-lg'>
                                <div className='flex flex-wrap justify-center'>
                                    {
                                        tItems.length === 0
                                            ?
                                            <div className="flex flex-col mt-24 items-center justify-center h-full">
                                                <svg className="animate-spin h-5 w-5 text-gray-500 mb-3" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.206 0 4.232-.896 5.657-2.343l2.343 2.343zM18 6.709V4.043A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z"></path>
                                                </svg>
                                                <p className="text-gray-500">Loading some things...</p>
                                            </div>
                                            :
                                            <>
                                                {tItems && tItems.map((item, index) => (
                                                    <React.Fragment key={index} >
                                                        {
                                                            Math.floor(tItems.length / 2) == index &&
                                                            <div onClick={handleShowUploadModal} className="w-64 2xl:w-72 mx-2 group mb-5">
                                                                <div className="group-hover:cursor-pointer text-center flex flex-wrap items-center justify-center border border-dashed border-gray-600
                                                                                    group-hover:bg-gray-200 group-active:bg-gray-300 rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-100 duration-200 text-sm font-medium">
                                                                    <span className='pb-4 pt-4'>Click to insert<br /> your thumbnail</span>
                                                                </div>

                                                                <div className="flex pb-5">
                                                                    <div className="group-hover:cursor-pointer border border-dashed border-gray-600 group-hover:bg-gray-200 group-active:bg-gray-300 shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-100 duration-200">
                                                                        <span> &nbsp; </span>
                                                                    </div>
                                                                    <div className="w-[80%]">
                                                                        <div className="group-hover:cursor-pointer border border-dashed border-gray-600 group-hover:bg-gray-200 group-active:bg-gray-300 mt-3 p-0 text-sm bg-gray-100 h-7 w-full rounded-[5px] duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="group-hover:cursor-pointer border border-dashed border-gray-600 group-hover:bg-gray-200 group-active:bg-gray-300 mt-2 p-0 text-sm bg-gray-100 h-4 w-[34%] rounded-[5px] duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='text-gray-800 w-64 2xl:w-72 pb-5 text-[0.9rem] mx-2 mb-5'>
                                                            <div className="mb-2 relative group">
                                                                <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                                                    <img className="@apply inline-block mx-0 w-full rounded-lg" src={item.thumbnail} alt="" />
                                                                </div>
                                                                <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                                                                </div>
                                                            </div>

                                                            <div className='flex'>
                                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.avatar} alt="" />
                                                                </div>
                                                                <div className='flex-col w-9/12'>
                                                                    <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                        <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb'>{item.title}</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                                            <p className='opacity-50'>{item.channelName}
                                                                                {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <UploadModal onClose={handleOnClose} visible={showModal} focusBoardId={board_id} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FocusBoardDetails