import React, { useEffect, useState, Fragment, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player/youtube';
import axios from 'axios';
import Cookies from 'js-cookie'
import { BsPhone, BsBoxArrowInDownLeft } from "react-icons/bs";
import { SlSocialYoutube, SlScreenDesktop } from "react-icons/sl";
import { BiCheck } from "react-icons/bi";
import { RiCloseLine, RiQuestionMark } from "react-icons/ri";
import { IoTrailSignOutline } from "react-icons/io5";
import { IoIosDesktop } from "react-icons/io";
import { MdOutlineArrowBack, MdOutlineTipsAndUpdates } from "react-icons/md";
import { FaRegLightbulb, FaLightbulb } from "react-icons/fa";
import { AiOutlineFundProjectionScreen, AiOutlineEyeInvisible, AiOutlineDesktop } from "react-icons/ai";
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'
import { HiOutlineBell } from "react-icons/hi";
import logo from '../../../assets/logo_full_32.png';
import { Tab } from '@headlessui/react';
import ImageAnalysisModalFB from '../../components/ImageAnalysisModalFB';
import Hint from '../../components/Hint';
import ImageAnalysisModalST from '../../components/ImageAnalysisModalST';
import Tooltip from '../../components/Tooltip';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const perks = [
    {
        name: 'Check the Colors',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg',
        description:
            "Order now and you'll get delivery absolutely free. Well, it's not actually free, we just price it into the products. Someone's paying for it, and it's not us.",
    },
    {
        name: 'Check the text',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-light.svg',
        description:
            "We have a 10 year warranty with every product that you purchase, whether thats a new pen or organizer, you can be sure we'll stand behind it.",
    },
    {
        name: 'Exchanges',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-returns-light.svg',
        description:
            'We understand that when your product arrives you might not particularly like it, or you ordered the wrong thing. Conditions apply here.',
    },
    {
        name: 'For the planet',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg',
        description:
            "Like you, we love the planet, and so we've pledged 1% of all sales to the preservation and restoration of the natural environment.",
    },
]

const FocusBoardTesting = () => {
    let { board_id, upload_id } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const currentTestId = localStorage.getItem("currentTestId");
    const [tItems, setTItems] = useState([]);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [thumbnailData, setThumbnailData] = useState(false);
    const [checklistEnabled, setChecklistEnabled] = useState(false);
    const [deviceOption, setDeviceOption] = useState(1);
    const [currentStep, setCurrentStep] = useState(1);
    const [thumbnailType, setThumbnailType] = useState(0);
    const [helpModal, setHelpModal] = useState(false);
    const [comparisonEnabled, setComparisonEnabled] = useState(false)
    const [pulse, setPulse] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [ttIndex, setTTIndex] = useState(0);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [extensionModal, setExtensionModal] = useState(false);
    const [showAnalysisModal, setShowAnalysisModal] = useState(false);

    const hideControls = () => {
        setControlsVisible(false);
    };

    const showControls = () => {
        setControlsVisible(true);
    };

    const handleShowHelpModal = () => {
        setHelpModal(true);
        // loadThumbnails();
    }

    const handleHelpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setHelpModal(false);
        }
    }

    const helpModalClose = (e) => {
        if (e.target.id === "help-modal") {
            setHelpModal(false);
        }
    }

    const handleDeviceOptions = (e) => {
        if (e === 1) {
            setDeviceOption(1);
        } else if (e === 2) {
            setDeviceOption(2);
        } else if (e === 3) {
            setDeviceOption(3);
        } else if (e === 4) {
            setDeviceOption(4);
        }
    }

    const renderDeviceOptions = (e) => {
        if (deviceOption === 1) {
            return tItems.map((item, index) => (
                <React.Fragment key={index} >
                    {
                        ttIndex === index && (
                            <div className={`text-gray-800 w-64 2xl:w-72 pb-5  ${pulse ? 'animate-pulse' : ''} text-[0.9rem] mx-2 mb-5`}>
                                <div className="mb-2 relative group">
                                    <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                        <img className="@apply inline-block mx-0 w-full rounded-lg" src={thumbnailData.thumbnail} alt="" />
                                    </div>
                                    <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                        <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                    </div>
                                    <div className='flex-col w-9/12'>
                                        <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                            <p className={`font-roboto ${darkMode === true ? 'text-gray-400' : ''} items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{thumbnailData.title}</p>
                                        </div>
                                        <div className=''>
                                            <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                <p className={` ${darkMode === true ? 'text-gray-400' : ''} `}>{channelDisplayName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className='text-gray-800 w-64 2xl:w-72 pb-5 text-[0.9rem] mx-2 mb-5'>
                        <div className="mb-2 relative group">
                            <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                <img className="@apply inline-block mx-0 w-full rounded-lg" src={item.thumbnail} alt="" />
                            </div>
                            <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                            </div>
                        </div>

                        <div className='flex'>
                            <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.avatar} alt="" />
                            </div>
                            <div className='flex-col w-9/12'>
                                <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                    <p className={`${darkMode === true ? 'text-gray-400' : ''} font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{item.title}</p>
                                </div>
                                <div className=''>
                                    <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                        <p className={`${darkMode === true ? 'text-gray-400' : ''}`}>{item.channelName}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            ));
        } else if (deviceOption === 3) {
            return <div className="w-full flex flex-cols justify-center ">
                <div className="inline-block">
                    <div className={`mt-[-30px] pb-[-30px] p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[346px] h-[660px] 2xl:h-[860px] overflow-y-auto transition duration-700`}>
                        <div className={`pt-5 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[320px]`}>
                            <div className=" grid grid-cols-1  transition duration-700">
                                {tItems && tItems.map((item, index) => (
                                    <React.Fragment key={index} >
                                        {
                                            ttIndex === index && (
                                                <div className={`text-gray-800 pb-5  ${pulse ? 'animate-pulse' : ''} text-[0.9rem]`}>
                                                    <div className="mb-2 relative group">
                                                        <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                                            <img className="@apply inline-block mx-0 w-full" src={thumbnailData.thumbnail} alt="" />
                                                        </div>
                                                        <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                                                        </div>
                                                    </div>

                                                    <div className='flex ml-2'>
                                                        <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                            <img className='rounded-full md:h-8 md:w-8 xl:h-7 xl:w-7' src={channelPhotoUrl} alt="" />
                                                        </div>
                                                        <div className='flex-col w-9/12'>
                                                            <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                <p className={`font-roboto ${darkMode === true ? 'text-gray-400' : ''} items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{thumbnailData.title}</p>
                                                            </div>
                                                            <div className=''>
                                                                <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                                    <p className={` ${darkMode === true ? 'text-gray-400' : ''} `}>{channelDisplayName}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className='text-gray-800 pb-5 text-[0.9rem]'>
                                            <div className="mb-2 relative group">
                                                <div className="@apply inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200 ">
                                                    <img className="@apply inline-block mx-0 w-full " src={item.thumbnail} alt="" />
                                                </div>
                                                <div className="rounded-lg absolute cursor-default top-0 left-0 w-full h-[98%]">
                                                </div>
                                            </div>

                                            <div className='flex ml-2'>
                                                <div className="shrink-0 mx-0 my-0 mr-1 md:mr-1 xl:mr-1 h-8 w-8 object-cover">
                                                    <img className='rounded-full md:h-8 md:w-8 xl:h-7 xl:w-7' src={item.avatar} alt="" />
                                                </div>
                                                <div className='flex-col w-9/12'>
                                                    <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                        <p className={`${darkMode === true ? 'text-gray-400' : ''} font-roboto items-center leading-4 tracking-normal font-medium lg:text-[14px] mb:text-mb`}>{item.title}</p>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex text-[12px] font-roboto lg:text-[13px]'>
                                                            <p className={`${darkMode === true ? 'text-gray-400' : ''}`}>{item.channelName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    const goBack = () => {
        // let path = `/focus-boards/`;
        // navigate(path);
        // navigate(-1);
        // window.history.back();
    }

    useEffect(() => {
        const getThumbnailForTesting = async () => {
            const docRef = doc(db, 'Users', mainUserId, 'UserThumbnails', upload_id);
            const testDocSnap = await getDoc(docRef);
            if (testDocSnap.exists()) {
                setThumbnailData(testDocSnap.data());
            } else {
                // doc.data() will be undefined in this case
                toast("Unable to load thumbnail for test.", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });

                console.log("No such document!");
            }
        }

        const getThumbnails = async () => {
            const docRef = doc(db, 'Users', mainUserId, 'FocusBoards', board_id);
            const testDocSnap = await getDoc(docRef);
            if (testDocSnap.exists()) {

                const calculatedIndex = Math.floor(testDocSnap.data().items.length / 2);
                setTTIndex(calculatedIndex);
                console.log(calculatedIndex + '----------------------------------------')


                console.log("Document data:", testDocSnap.data());
                setDocTitle(testDocSnap.data().name)
                setTItems(testDocSnap.data().items)


            } else {
                // doc.data() will be undefined in this case
                toast("Unable to load thumbnails", {
                    icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                });
                console.log("No such document!");
            }
        }

        getThumbnailForTesting();
        getThumbnails();

        const timer = setTimeout(() => {
            setPulse(false);
        }, 4500);

        return () => clearTimeout(timer);
    }, []);

    const handleExtensionModalClose = (e) => {
        if (e.target.id === "extension-modal") {
            setExtensionModal(false);
        };
    }

    const extensionModalClose = (e) => {
        setExtensionModal(false);
    }

    const handleYTTestClick = () => {
        setProcessing(true);

        // Attempt to set a test cookie
        document.cookie = 'test_cookie=1';

        // Check if the test cookie was set
        if (document.cookie.indexOf('test_cookie') !== -1) {

            document.cookie = "test_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            const url = "https://www.youtube.com";
            let checkChromeExtension = Cookies.get('TG_CEF');

            if (checkChromeExtension === 'TRUE') {
                //Set Page Intent
                const intent = 'OPENTESTER';
                const userDoc = doc(db, "Users", mainUserId)
                const data = {
                    page_intent: intent,
                    tg_tt_current_Image: tItems[ttIndex].thumbnail,
                    tg_tt_current_Title: tItems[ttIndex].title,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    // // insert current image and titles into cookies
                    document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
                    document.cookie = "tg_tt_current_Image=" + tItems[0].thumbnail + "; path=/;";
                    document.cookie = "tg_tt_current_Title=" + tItems[0].title + "; path=/;";
                    window.open(url, '_blank');

                    setProcessing(false);
                }).catch((e) => {

                    setProcessing(false);
                    toast("Unable to verify connection. to Youtube", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                });

            } else {
                setProcessing(false);
                setExtensionModal(true);
            }

        } else {
            setProcessing(false);
            // Cookies are disabled
            toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    const installExtension = (url) => {
        const windowFeatures = 'width=1300,height=800,resizable,scrollbars=yes,';
        const popupWindow = window.open(url, '_blank', windowFeatures);
        // Check if the popup was blocked
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            console.log('Popup was blocked or unable to open.');
            window.open(url, '_blank');
        } else {
            console.log('Popup opened successfully.');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Focus Board Testing</title>
                <meta name="description" content="Focus Board Testing - Thumbnail Geek" />
            </Helmet>

            <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Cannot Connect to Browser Extension
                            </h3>
                            <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or disabled the extension in your browser.
                            </p>
                            <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />

                        </div>
                        <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={() => installExtension('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Install Extension
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="relative bg-white p-5 rounded-lg max-w-6xl border border-gray-400 shadow-md">

                    <button onClick={() => setHelpModal(false)} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="mx-auto mt-2">
                        <h3 className="text-base text-center font-semibold leading-6 text-gray-900">How to compare your thumbnail against competitors</h3>
                    </div>
                    <div className="mt-2">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className='border-gray-200 text-gray-800 border-b text-sm'>
                                <Tab className={({ selected }) =>
                                    selected
                                        ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                        : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                >
                                    <span className="inline-flex p-3">
                                        <p className='hidden lg:block'>Video</p>
                                    </span>
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className='w-full'>

                                <Tab.Panel className='w-full max-w-6xl md:h-auto'>
                                    <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                            className="rounded-lg overflow-hidden"
                                            width="853px"
                                            height="505px"
                                        />
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>


                    </div>
                </div>
            </div>

            <div className={`${processing === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg w-96 border border-gray-400 shadow-md">
                    <div className="w-full h-[105px] text-center animate-pulse flex items-center justify-center bg-gray-200 rounded-md mb-2">
                        <h1 className="font-semibold text-center text-md mb-4 mt-2 text-gray-700 animate-pulse">
                            Preparing to send...
                        </h1>
                    </div>
                </div>
            </div>

            <div className={` ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818] '} transition duration-300 ml-auto pb-40 lg:w-[100%]`}>
                <button onClick={showControls} className={`${darkMode === false ? 'text-gray-800 hover:text-gray-600' : ' text-gray-400 hover:text-gray-300'} fixed top-5 right-5 text-xs inline-flex cursor-pointer`}>
                    <BsBoxArrowInDownLeft className="mt-[1px] mr-1" /><span>Restore</span>
                </button>
                <div className="px-6 min-h-screen">
                    <div className={`
                    transform 
                    ${controlsVisible ? 'translate-y-0' : '-translate-y-[70px]'} 
                    transition-transform duration-500 
                    ${controlsVisible ? 'ease-in-out' : 'ease-in-out'} 
                    fixed ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818] border-gray-700'} border-b right-0 left-0 top-0 z-10 w-full px-6 pt-3 pb-3 flex items-center justify-between`}>
                        <div className="inline-flex">
                            <button onClick={() => navigate(-1)} className={`text-gray-800 ${darkMode === false ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-700 hover:bg-gray-800 text-gray-300'}   mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                <MdOutlineArrowBack />
                            </button>
                            <NavLink to="/dashboard">
                                <div className="text-2xl font-bold text-gray-800 lg:text-2xl hover:text-gray-700" href="">
                                    <div className='relative w-2/3 mt-2 '>
                                        <img className='w-full h-full' src={logo} />
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className={`absolute right-1/2 translate-x-1/2 inline-flex`} >
                            <div className=" text-center">
                                <span className={`
                                ${darkMode === false ? 'text-gray-500 hover:text-gray-600' : 'text-gray-300'}
                                font-normal text-gray-600`}>Focus Board |</span>
                                <span className={`
                                ${darkMode === false ? 'text-gray-800 hover:text-gray-600' : 'text-gray-300'}
                                font-bold text-base ml-1`}>{docTitle}</span>
                            </div>
                        </div>

                        <div className="inline-flex space-x-2 justify-self-end">

                            <div className={`
                            ${darkMode === false ? 'border-gray-400' : 'border-gray-700'}
                            border-r flex justify-center items-center px-1`}>
                                <div className="inline-flex">
                                    <button onClick={() => handleDeviceOptions(1)}
                                        className={`
                                        hidden px-2 lg:inline-flex items-center justify-center cursor-pointer
                                        ${darkMode === false && deviceOption === 1 ? 'border-gray-300' : 'border-gray-700'} 
                                    `}>
                                        <SlScreenDesktop className={`hover:text-[#FC2959]
                                        ${darkMode === false ? 'text-gray-700' : 'text-gray-300'}
                                        ${deviceOption === 1 ? 'text-red-600' : ''}`} />
                                    </button>
                                </div>

                                <div className="inline-flex ">
                                    <button onClick={() => handleDeviceOptions(3)}
                                        className={`
                                        px-2 inline-flex items-center justify-center ${darkMode === false && deviceOption === 3 ? 'border-gray-300' : 'border-gray-700'}
                                   `}>
                                        <BsPhone className={`hover:text-[#FC2959] text-[14px] cursor-pointer
                                        ${darkMode === false ? 'text-gray-700' : 'text-gray-300'}
                                        ${deviceOption === 3 ? 'text-red-600' : ''}`} />
                                    </button>
                                </div>
                            </div>

                            <div className="rounded-full p-1 pt-2 ">
                                {darkMode === false ? (
                                    <>
                                        <button className={`inline-flex text-xs hover:cursor-pointer ${darkMode === false ? 'text-gray-500 hover:text-gray-600' : ''} `} onClick={() => setDarkMode(!darkMode)}>
                                            <FaLightbulb className="mt-1 mr-1 text-[16px]" />
                                            <span className="mt-[4px]">
                                                Dark Mode
                                            </span>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className={`inline-flex text-xs hover:cursor-pointer ${darkMode === true ? 'text-gray-400 hover:text-gray-300' : ''} `} onClick={() => setDarkMode(!darkMode)}>
                                            <FaRegLightbulb className="mt-1 mr-1 text-[16px]" />
                                            <span className="mt-[4px]">
                                                Light Mode
                                            </span>
                                        </button>
                                    </>
                                )}
                            </div>


                            <div className="">
                                <button className={`inline-flex mt-2 text-xs hover:cursor-pointer 
                                ${darkMode === true ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'}`}
                                    onClick={hideControls}>
                                    <AiOutlineEyeInvisible className="mt-1 mr-1 text-[16px]" />
                                    <span className=" mt-[4px]">
                                        Hide Controls
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div className="pt-10">
                        <div className='2xl:w-[1346px] mt-16 mx-auto transition ease-in-out delay-150 duration-300'>
                            <div className="">

                                <div className={`flex justify-center transition ease-in-out delay-150 duration-300`}>
                                    <div className='flex flex-wrap justify-center mb-12'>
                                        {
                                            tItems.length === 0
                                                ?
                                                <div className="flex flex-col mt-24 items-center justify-center h-full">
                                                    <svg className="animate-spin h-5 w-5 text-gray-500 mb-3" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.206 0 4.232-.896 5.657-2.343l2.343 2.343zM18 6.709V4.043A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z"></path>
                                                    </svg>
                                                    <p className="text-gray-500">Arranging some pixels...</p>
                                                </div>
                                                :
                                                <>
                                                    {renderDeviceOptions()}
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="fixed bottom-0 right-1/2 translate-x-1/2 mb-2">
                            <div className={`transform 
                                ${controlsVisible ? 'translate-y-0' : 'translate-y-[70px]'} 
                                transition-transform duration-500 
                                ${controlsVisible ? 'ease-in-out' : 'ease-in-out'}  
                                ${darkMode === false ? 'bg-gray-50 border-gray-200' : 'bg-[#202020] border-gray-600'} 
                                inline-flex p-2 border mb-3 shadow-md rounded-md`}>

                                <div className="inline-flex space-x-6">
                                    <button onClick={handleShowHelpModal} className={`${darkMode === false ? 'text-white bg-blue-600  hover:bg-gray-100 active:bg-gray-200 hover:text-blue-600 active:text-blue-600' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                        <IoTrailSignOutline className='text-base mr-1' /><p className={`text-sm ml-1`}>How to Compare</p>
                                    </button>
                                </div>

                                {/* <div className="inline-flex space-x-6 ml-3">
                                    <button onClick={() => setShowAnalysisModal(true)} className={`${darkMode === false ? 'text-white bg-purple-500 hover:bg-gray-100 active:bg-gray-200 hover:text-purple-500 active:text-purple-500' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                        <AiOutlineFundProjectionScreen className='text-base mr-1' /><p className={`text-sm ml-1`}>Evaluate For Me</p>
                                    </button>
                                </div> */}

                                {/* <div className="inline-flex space-x-6 ml-3">
                                    <button onClick={() => setShowAnalysisModal(true)} className={`${darkMode === false ? 'text-white bg-purple-500 hover:bg-gray-100 active:bg-gray-200 hover:text-purple-500 active:text-purple-500' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                        <AiOutlineFundProjectionScreen className='text-base mr-1' /><p className={`text-sm ml-1`}>Intelligent Insights</p>
                                    </button>
                                </div> */}

                                <div className="inline-flex space-x-6 ml-3">
                                    <button onClick={handleYTTestClick} className={`${darkMode === false ? 'text-white bg-[#f12c5a] hover:bg-gray-100 active:bg-gray-200 hover:text-[#f12c5a] active:text-[#f12c5a]' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                            border px-4 py-2 rounded-md inline-flex justify-center items-center text-xl duration-200`}>
                                        <SlSocialYoutube className='text-base mr-1' /><p className={`text-sm ml-1`}>Test on Youtube</p>
                                    </button>
                                </div>

                                {/* <div className="inline-flex space-x-6">
                                    <button onClick={handleShowHelpModal} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-[#202020] hover:bg-[#1c1c1c]'} border ${checklistEnabled ? 'border-green-600' : 'border-blue-600'} px-4 py-2 rounded-md bg-gray-100 inline-flex justify-center items-center text-xl duration-200`}>
                                        <IoTrailSignOutline /> <p className={`ml-2 text-sm`}>How to Compare</p>
                                    </button>
                                </div>

                                <div className="inline-flex space-x-6 ml-5">
                                    <button onClick={() => setShowAnalysisModal(true)} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-[#202020] hover:bg-[#1c1c1c]'} border ${checklistEnabled ? 'border-green-600' : 'border-blue-600'} border-green-600 px-4 py-2 rounded-md bg-gray-100 inline-flex justify-center items-center text-xl duration-200`}>
                                        <AiOutlineFundProjectionScreen /> <p className={`ml-2 text-sm`}>Intelligent Insights</p>
                                    </button>
                                </div> */}

                                {/* <div className="inline-flex space-x-6 ml-5">
                                    <button onClick={handleYTTestClick} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-800' : 'text-gray-400 bg-[#202020] hover:bg-[#1c1c1c]'} border border-red-600 px-4 py-2 rounded-md bg-gray-100 inline-flex justify-center items-center text-xl duration-200`}>
                                        <SlSocialYoutube /><p className={`text-sm ml-2`}>Test On Youtube</p>
                                    </button>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed bottom-5 left-5">
                <Tooltip content='Tutorials & Tips' position={'top'}>
                    <button onClick={handleShowHelpModal} className={`${darkMode === false ? 'text-gray-800 bg-[#FC2959] hover:bg-blue-600 active:bg-blue-700 hover:text-white' : 'text-gray-400 bg-gray-800 hover:bg-[#1c1c1c] active:bg-black'} 
                                border p-3 rounded-full inline-flex justify-center items-center text-xl duration-200`}>
                        <RiQuestionMark className='text-base text-white' />
                    </button>
                </Tooltip>
            </div>

            <ImageAnalysisModalST isVisible={showAnalysisModal} onClose={() => setShowAnalysisModal(false)} thumbnail_id={upload_id} />
            {/* <ImageAnalysisModalFB isVisible={showAnalysisModal} onClose={() => setShowAnalysisModal(false)} thumbnail_id={upload_id} focusBoardId={board_id} /> */}
        </>
    )
}

export default FocusBoardTesting