import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { FaChrome } from 'react-icons/fa';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db } from '../../../firebase-config';
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import tester from '../../../assets/thumbnailgeek_tester_1.png';
import testergif from '../../../assets/gifs/pinBtn.gif';
import 'react-toastify/dist/ReactToastify.css';
import DotDotDot from '../../components/DotDotDot';
import Tooltip from '../../components/Tooltip';
import AvatarNoPremium from '../../components/AvatarNoPremium';

const FirstStepsExtension = () => {
    let { user_id } = useParams();
    //localStorage.setItem('real_user_id', user_id);
    const { user, token, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    //const { premiumStatus, premiumTier, accountStatus, channelLinked, accessLevel } = PremiumCredentials();
    const { checked, checkDataStatus } = PremiumCredentials()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const navigate = useNavigate()
    const [connect, setConnect] = useState(false)
    const [extension, setExtension] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [channelName, setChannelName] = useState("")
    const [channelImage, setChannelImage] = useState("")
    const [userDetails, setUserDetails] = useState(null);
    const [checking, setChecking] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        }
    }, []);

    useEffect(() => {
        if (!checked) {
            console.log('Initializing Premium Credentials');
            checkDataStatus();
        }
    }, [checked]);

    //useEffect(() => {
    //    console.log(premiumStatus);
    //    console.log(accessLevel);

    //    if (channelLinked === false && premiumStatus === 'NOT_INITIATED') {
    //        //navigate(`/first-steps/connect/${ mainUserId } `);
    //        navigate(`/mid/${ mainUserId } `);
    //        return;
    //    }

    //}, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked]);

    function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Lax";
    }

    function getCookie(name) {
        //Encode the cookie name to escape special characters
        name = encodeURIComponent(name);

        //Split document.cookie on semicolons into an array of all the cookies
        const cookieArr = document.cookie.split(';');

        //Loop through the cookies array
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].trim().split('=');

            //Decode the cookie name and compare it to the given string
            if (cookiePair[0] === name) {
                //Decode the cookie value and return it
                return decodeURIComponent(cookiePair[1]);
            }
        }

        //Return null if the cookie was not found
        return null;
    }

    const openPopup = (url) => {
        const desiredCookie = getCookie('TG_CEF')

        if (desiredCookie !== null) {
            setExtension(true);
            console.log('The specific cookie has been set.');
        } else {
            //Specify the window features for the popup (size, position, etc.)
            const windowFeatures = 'width=1300,height=900,resizable,scrollbars=yes,';
            const popupWindow = window.open(url, '_blank', windowFeatures);
            //Check if the popup was blocked
            if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
                console.log('Popup was blocked or unable to open.');
                window.open(url, '_blank');
            } else {
                console.log('Popup opened successfully.');
                setChecking(true)
                let checkCount = 0; //Counter for the number of checks
                let timer; //Reference to the timer

                const checkCookie = setInterval(() => {
                    console.log('Checking for link to extension.');

                    const desiredCookie = getCookie('TG_CEF')

                    if (desiredCookie !== null) {
                        setExtension(true);
                        console.log('The specific cookie has been set.');
                        setChecking(false);
                        clearInterval(checkCookie); //Stop checking for the cookie
                        clearTimeout(timer); //Stop the timer
                    }

                    checkCount++; //Increment the check count

                    if (checkCount === 20) {
                        //Reached the desired number of checks
                        setChecking(false);
                        console.log('Reached the desired number of checks (20).');
                        clearInterval(checkCookie); //Stop checking for the cookie
                        clearTimeout(timer); //Stop the timer
                    }
                }, 9000); //Interval time in milliseconds (9000 = 3 minutes/20 checks)

                timer = setTimeout(() => {
                    clearInterval(checkCookie); //Stop checking for the cookie
                }, 180000); //3 minutes in milliseconds
            }
        }
    };

    useEffect(() => {
        const desiredCookie = getCookie('TG_CEF')
        if (desiredCookie !== null) {
            setExtension(true);
            console.log('The specific cookie has been set.');
        } else {
            console.log('The specific cookie has NOT been set.');
        }
    }, []);

    useEffect(() => {
        const checkUserId = async () => {
            const firestore = getFirestore()
            const docRef = doc(firestore, "Users", user_id)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                navigate('/login')
            }
        }

        checkUserId();
    }, []);

    const updateQnAPage = async () => {
        const userDoc = doc(db, "Users", user_id)
        const data = {
            page_touch_extension: true,
        }
        setDoc(userDoc, data, { merge: true }).then(() => {
            return true;
        });
    }

    const goToQnA = async (event) => {
        console.log('Tapped on Ext');
        const userDoc = doc(db, "Users", user_id)
        const data = {
            page_touch_extension: true,
        }
        setDoc(userDoc, data, { merge: true }).then(() => {
            navigate(`/mid/${user_id}`);
        });

        //window.location.href = `/mid/${ user_id } `;
        //navigate(`/mid/${ user_id } `);

        //navigate(`/first-steps/qa/${ user_id } `);
    }

    //const goToQnA = async (event) => {
    //    if (connect === true) {
    //        navigate(`/first-steps/billing/plans/${ user_id } `);
    //    } else {
    //        toast("Connect your youtube channel first", {
    //            icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
    //        });
    //    }
    //}

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>First Steps - Install Extension | ThumbnailGeek</title>
                <meta name="description" content="First Steps - Install Extension | ThumbnailGeek" />
            </Helmet>

            <div className="flex flex-col h-screen">
                <div className="px-6 pt-4">
                    {/* Start Nav */}
                    <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4'>
                        <div className="flex">
                            <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                                <img src={require('../../../assets/svgs/tg_logo_full.svg').default} />
                            </a>
                        </div>
                        <AvatarNoPremium />
                        {/* <img className="h-8 w-8 rounded-full ring-1 ring-gray-600"
                            src={channelPhotoUrl == null ? '' : channelPhotoUrl}
                            alt=""
                        /> */}
                    </div>
                    {/* End Nav */}
                </div>

                <div className="flex flex-1 px-10 h-full">
                    <div className="w-1/2 flex flex-1 flex-col justify-left lg:flex-none h-full">
                        <div className="w-full lg:w-full h-full">

                            <div className="flex flex-col h-full items-center justify-center">
                                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                                    <div className="max-w-2xl inline-flex space-x-3" aria-hidden="true">
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                        </div>
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                        </div>
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                        </div>
                                        {/* <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                        </div> */}
                                    </div>

                                    {
                                        extension !== true
                                            ?
                                            <div className="pr-4">
                                                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                    Install Browser Extension
                                                </h2>
                                                <p className="mt-2 text-base leading-6 text-gray-500">
                                                    Extension helps you insert & visualize your thumbnails directly on Youtube Home Page or Search Page.
                                                </p>
                                            </div>
                                            :
                                            <div className="pr-4">
                                                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                    Extension Installed!
                                                </h2>
                                                <p className="mt-2 text-base leading-6 text-gray-500">
                                                    Horray! You can now insert & visualize your thumbnails directly on Youtube Home Page or Search Page!
                                                </p>
                                            </div>
                                    }

                                    <div className="mt-2 pl-4 pr-9">
                                        <div className="block mt-10 max-w-lg">
                                            {
                                                extension !== true
                                                    ?
                                                    <button
                                                        onClick={() => openPopup('https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk')}
                                                        className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-blue-600 to-[#FC2959] px-3 py-4 text-sm font-semibold leading-6
                                                        text-white shadow-sm hover:from-blue-700 hover:to-[#d73c60] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
                                                        <FaChrome className='text-lg mt-[3px] mr-1' />
                                                        <p>Install Chrome Extension</p>
                                                    </button>
                                                    :
                                                    <button disabled="true"
                                                        className="inline-flex w-full justify-center rounded-md bg-gray-500 px-3 py-4 text-sm font-semibold leading-6 text-white shadow-sm 
                                                        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
                                                        <FaChrome className='text-lg mt-[3px] mr-1' />
                                                        <p>Extension Installed</p>
                                                    </button>
                                            }

                                            {
                                                checking === true ?
                                                    <div className="mt-6">
                                                        <DotDotDot className="pl-2" />
                                                        <p className='text-center text-sm mt-1 text-gray-400'>checking</p>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>

                                        {extension !== true && (<div className="flex flex-col items-center justify-center w-full max-w-lg mt-3">
                                            <Tooltip content="Testing your thumbnails on Youtube home page will be disabled!" position='top'>
                                                <button onClick={goToQnA} className="underline text-sm text-gray-600">
                                                    Skip
                                                </button>
                                            </Tooltip>
                                        </div>)}

                                        {
                                            extension === true
                                                ?
                                                <>
                                                    <div className="block mt-16 max-w-lg">
                                                        <button
                                                            onClick={goToQnA}
                                                            className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                        >
                                                            Next Step
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="block mt-16 max-w-lg">
                                                        <button
                                                            disable="true"
                                                            className="flex w-full justify-center rounded-md bg-gray-300 px-3 py-2.5 text-sm font-semibold leading-6 text-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                                        >
                                                            Next Step
                                                        </button>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative hidden w-0 flex-1 lg:block h-full">
                        <div className="w-full lg:w-full h-full bg-purple-100">
                            <div className="mx-auto max-w-2xl flex flex-col h-full items-center justify-center">
                                <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Find out what works. Fast.</h2>
                                <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
                                    Smart browser extension helps you test your thumbnail<br />on Youtube homepage before you upload.
                                </p>
                                {/* <video width="100%" controls autoPlay loop className='rounded-lg aspect-video'>
                                    <source src="videos/Tester1hb.mp4" type="video/mp4" />
                                </video> */}
                                <img
                                    className=" w-2/3 object-contain mb-4 mt-5 rounded-md"
                                    src={tester}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FirstStepsExtension