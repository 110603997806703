import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Tooltip = ({ content, position, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [hideTimeout, setHideTimeout] = useState(null);
    const [lastCursorPosition, setLastCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const gap = 10;

            if (Math.abs(clientX - lastCursorPosition.x) >= gap || Math.abs(clientY - lastCursorPosition.y) >= gap) {
                setLastCursorPosition({ x: clientX, y: clientY });
                setTooltipPosition({ top: clientY + gap + window.scrollY, left: clientX + gap + window.scrollX });
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [lastCursorPosition]);

    const handleMouseEnter = () => {
        setShowTooltip(true);
        clearTimeout(hideTimeout);
    };

    const handleMouseLeave = () => {
        setHideTimeout(setTimeout(() => setShowTooltip(false), 100));
    };

    const getTooltipPositionClass = () => {
        if (position === 'top') {
            return 'tooltip-top';
        }

        if (position === 'bottom') {
            return 'tooltip-bottom';
        }

        return '';
    };

    return (
        <>
            <div
                className="relative tooltip-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
            {showTooltip && (
                ReactDOM.createPortal(
                    <div
                        className={`absolute z-[8768256] max-w-[145px] bg-white text-xs rounded-md ring-1 text-gray-700 px-2 py-1 shadow-lg ${getTooltipPositionClass()}`}
                        style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                    >
                        {content}
                    </div>,
                    document.body
                )
            )}
        </>
    );
};

Tooltip.propTypes = {
    content: PropTypes.string.isRequired,
    position: PropTypes.oneOf(['top', 'bottom']),
    children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
    position: 'top',
};

export default Tooltip;
