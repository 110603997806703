import React, { useState } from 'react';

const Test = () => {
    const [formData, setFormData] = useState({
        visualAppeal: {
            resolution: false,
            composition: false,
            font: false,
            color: false,
            movement: false,
            face: false,
            wordCount: false,
            textReadability: false,
            clutter: false
        },
        relevanceEmotion: {
            relevance: false,
            consistency: false,
            titleRelevance: false,
            thumbnailEmotion: false,
            titleEmotion: false,
            tone: false
        },
        synergy: {
            compelling: false,
            curiosity: false,
            persuasive: false
        }
    });

    const handleChange = e => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    }

    return (
        <div className="bg-white p-6 rounded-lg">
            <h2 className="text-lg font-medium mb-4">Checklist for Evaluating YouTube Thumbnails and Titles</h2>
            <div className="mb-4">
                <h3 className="text-base font-medium mb-2">I. Visual Appeal</h3>
                <div className="mb-2">
                    <label className="block">
                        <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="resolution"
                            checked={formData.visualAppeal.resolution}
                            onChange={handleChange}
                        />
                        Clear and high-resolution image (800x450 pixels or higher recommended)
                    </label>
                </div>
                <div className="mb-2">
                    <label className="block">
                        <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="composition"
                            checked={formData.visualAppeal.composition}
                            onChange={handleChange}
                        />
                        Visually balanced composition
                    </label>
                </div>
                {/* Other form fields for visual appeal */}
            </div>
            <div className="mb-4">
                <h3 className="text-base font-medium mb-2">II. Relevance and Emotion</h3>
                {/* Form fields for relevance and emotion */}
            </div>
            <div className="mb-4">
                <h3 className="text-base font-medium mb-2">III. Synergy</h3>
                {/* Form fields for synergy */}
            </div>
        </div>
    );
};

export default Test;
