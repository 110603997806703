import React, { useEffect, useState, } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { BsDisplay, BsPhone } from "react-icons/bs";
import { AiOutlineTable, AiOutlineBars, AiOutlineImport, AiOutlineSolution } from "react-icons/ai";
import { IoTabletLandscapeOutline, IoSearch, IoSettingsOutline, IoRefresh } from "react-icons/io5";
import { IoIosDesktop, IoMdGlobe, IoIosArrowDown } from "react-icons/io";
import { MdOutlineArrowBack } from "react-icons/md";
import { FaRegLightbulb, FaLightbulb, FaTv } from "react-icons/fa";
import { FiTv } from "react-icons/fi";
import { db } from '../../../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import Toggle from '../../components/ThemeToggle';
import { Tab } from '@headlessui/react'
import Video from '../../components/Video'
import VideoPhone from '../../components/VideoPhone'
// import useFetch from '../../components/useFetch'
import axios from 'axios';

const SmartTestMain = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0)
    const navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const mainUserId = localStorage.getItem("mainUserId")
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [deviceOption, setDeviceOption] = useState(1);
    const [darkMode, setDarkMode] = useState(false);
    const [random, setRandom] = useState(0);
    const { user, logOut, } = UserAuth()
    const [videoData, setVideoData] = useState([]);
    const [country, setCountry] = useState(null);
    const [refresh, setRefresh] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [tempSearchQuery, setTempSearchQuery] = useState('');
    const baseUrl = 'https://youtube.googleapis.com/youtube/v3'

    const handleDeviceOptions = (e) => {
        if (e === 1) {
            setDeviceOption(1);
        } else if (e === 2) {
            setDeviceOption(2);
        } else if (e === 3) {
            setDeviceOption(3);
        } else if (e === 4) {
            setDeviceOption(4);
        }
    }

    const processSearch = (e) => {
        e.preventDefault()
        setSearchQuery(tempSearchQuery);
    }

    useEffect(() => {
        const min = 2;
        const max = 6;
        const rand = Math.round(min + Math.random() * (max - min));
        setRandom(rand)
        console.log(rand)

        const getPopularVideos = async (country, chart, maxResults, apiKey) => {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // console.log(ytToken);

            await axios.get(`${baseUrl}/videos?part=snippet%2CcontentDetails%2Cstatistics&chart=${chart}&maxResults=${maxResults}&regionCode=${country}&key=${apiKey}`,
                config
            ).then(res => {
                return res.data.items;
            }).then((data) => {
                console.log(data);
                setVideoData(data)
            })
        }

        const getSearcNicheVideos = async (country, order, maxResults, apiKey, query) => {
            var searchQuery;

            if (query === '') {
                searchQuery = '';
            } else {
                searchQuery = `${query}`
            }

            await axios.get(`${baseUrl}/search?&key=${apiKey}`, {
                params: {
                    type: "video",
                    part: "snippet",
                    maxResults: maxResults,
                    order: order,
                    regionCode: country,
                    q: searchQuery,
                }
            }
            ).then(res => {
                return res.data.items;
            }).then((data) => {
                console.log(data);
                setVideoData(data)
            })
        }

        getSearcNicheVideos('US', 'relevance', 12, 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s', searchQuery)

        // getPopularVideos('GH', 'mostPopular', 12, 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s');

        if (currentUser === null) {
            navigate('/login')
        }
    }, [searchQuery, refresh]);


    const renderUserThumbnailSlot = () => {
        return <div>
            <div>
                <div className="pb-5 text-[0.9rem]">
                    <div className="mb-2 z-0 relative ">
                        <div className="@apply inline-block overflow-hidden w-full h-[80%]">
                            <img className="@apply inline-block mx-0 w-full mb-[-0.6%]" src="https://i.ytimg.com/vi/-diHAmL4rOY/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAl8OKgnVMsnRsEOioSU8qHLD6qtA" alt="" />
                        </div>

                        {/* <img className="w-full" src={medium.url} alt="" /> */}
                        <span className='font-roboto px-[0.2rem] text-[0.7rem] absolute right-1 bottom-[9px] bg-black text-white rounded-sm'>
                            15:00
                        </span>
                    </div>

                    <div className='flex'>
                        <div className="shrink-0 mx-0 my-0 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                            <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                        </div>
                        <div className='flex-col'>
                            <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal line-">
                                <p className='font-roboto items-center leading-4 font-medium text-gray-800 lg:text-[14px] mb:text-mb'>THHIS ISSSS ITTT</p>
                            </div>
                            <div className='mt-1'>
                                <div className='flex'>
                                    <p className='font-roboto text-[13px] text-gray-500 '>{channelDisplayName} • 150K views</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderHomePageDeviceOptions = () => {
        if (deviceOption === 1) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto  px-2 py-1 justify-center`}>Desktop</span>
                    </div>
                    <div className={`mt-3 mb-9 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-full max-w-[1366px] h-[90%] max-h-[768px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className="inline-flex space-x-1 ml-1 justify-start">
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                        </div>
                        <div className={`px-3 pt-4 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border-t justify-center rounded-md w-full`}>
                            <div className="grid grid-cols-3 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 2) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto px-2 py-1 justify-center`}>Tablet</span>
                    </div>
                    <div className={`mt-3 mb-9 p-2 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[886px] h-[600px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className={`px-3 pt-6 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[868px]`}>
                            <div className=" grid grid-cols-3 gap-4 transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 3) {
            return <div className="w-full flex flex-cols justify-center ">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto px-2 py-1 justify-center`}>Phone</span>
                    </div>
                    <div className={`mt-5 mb-9 p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[346px] h-[660px] overflow-y-auto transition duration-1000`}>
                        <div className={`pt-5 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[320px]`}>
                            <div className=" grid grid-cols-1  transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <VideoPhone video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 4) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'}w-auto px-2 py-1 justify-center`}>TV</span>
                    </div>
                    <div className={`mt-5 mb-9 p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-full max-w-[1550px] h-[94%] max-h-[768px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className={`px-3 pt-4 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-full`}>
                            <div className=" lg:col-span-3  grid grid-cols-1 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className=" lg:col-span-3  grid grid-cols-1 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 transition duration-1000">
                {
                    videoData && videoData.map((video, index) => (

                        index === random ?
                            <div>
                                <div>
                                    <div className="pb-5 text-[0.9rem]">
                                        <div className="mb-2 z-0 relative ">
                                            <div className="@apply inline-block overflow-hidden w-full h-[80%]">
                                                <img className="@apply inline-block mx-0 w-full mb-[-0.6%]" src="https://i.ytimg.com/vi/-diHAmL4rOY/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAl8OKgnVMsnRsEOioSU8qHLD6qtA" alt="" />
                                            </div>

                                            {/* <img className="w-full" src={medium.url} alt="" /> */}
                                            <span className='px-[0.2rem] text-[0.7rem] absolute right-1 bottom-[9px] bg-black text-white rounded-sm'>
                                                15:00
                                            </span>
                                        </div>

                                        <div className='flex'>
                                            <div className="shrink-0 mx-0 my-0 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                            </div>
                                            <div className='flex-col'>
                                                <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal line-">
                                                    <p className='items-center leading-4 font-medium text-gray-800 lg:text-[14px] mb:text-mb'>THHIS ISSSS ITTT</p>
                                                </div>
                                                <div className='mt-1'>
                                                    <div className='flex'>
                                                        <p className='text-[13px] text-gray-500 '>{channelDisplayName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                <Video video={video} key={video.id} />
                            </div>
                    ))
                }
            </div>
        }
    }

    const renderSearchDeviceOptions = () => {
        if (deviceOption === 1) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto  px-2 py-1 justify-center`}>Desktop</span>
                    </div>
                    <div className={`mt-3 mb-9 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-full max-w-[1366px] h-[90%] max-h-[768px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className="inline-flex space-x-1 ml-1 justify-start">
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                            <div className={`rounded-full ${darkMode === false ? 'bg-gray-300' : 'bg-gray-700'} h-3 w-3 mb-1`}></div>
                        </div>
                        <div className={`px-3 pt-4 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border-t justify-center rounded-md w-full`}>
                            <div className="grid grid-cols-3 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 2) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto px-2 py-1 justify-center`}>Tablet</span>
                    </div>
                    <div className={`mt-3 mb-9 p-2 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[886px] h-[600px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className={`px-3 pt-6 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[868px]`}>
                            <div className=" grid grid-cols-3 gap-4 transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 3) {
            return <div className="w-full flex flex-cols justify-center ">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'} w-auto px-2 py-1 justify-center`}>Phone</span>
                    </div>
                    <div className={`mt-5 mb-9 p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-[346px] h-[660px] overflow-y-auto transition duration-1000`}>
                        <div className={`pt-5 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-[320px]`}>
                            <div className=" grid grid-cols-1  transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <VideoPhone video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (deviceOption === 4) {
            return <div className="w-full flex justify-center">
                <div className="inline-block">
                    <div className="flex justify-center ">
                        <span className={`${darkMode === false ? 'text-gray-700 border-gray-700' : 'text-gray-400 border-gray-400'}w-auto px-2 py-1 justify-center`}>TV</span>
                    </div>
                    <div className={`mt-5 mb-9 p-3 ${darkMode === false ? 'border-gray-200 bg-gray-100' : 'border-gray-800 bg-[#212121]'} shadow border rounded-md w-full max-w-[1550px] h-[94%] max-h-[768px] overflow-y-auto transition duration-1000`}> {/*h-[560px]*/}
                        <div className={`px-3 pt-4 ${darkMode === false ? 'border-gray-200 bg-gray-50' : 'border-gray-800 bg-[#181818]'} border justify-center rounded-md w-full`}>
                            <div className=" lg:col-span-3  grid grid-cols-1 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  transition duration-700">
                                {
                                    videoData && videoData.map((video, index) => (

                                        index === random ?
                                            renderUserThumbnailSlot()
                                            :
                                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                                <Video video={video} key={video.id} darkMode={darkMode} />
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className=" lg:col-span-3  grid grid-cols-1 gap-6 sm:gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 transition duration-1000">
                {
                    videoData && videoData.map((video, index) => (

                        index === random ?
                            <div>
                                <div>
                                    <div className="pb-5 text-[0.9rem]">
                                        <div className="mb-2 z-0 relative ">
                                            <div className="@apply inline-block overflow-hidden w-full h-[80%]">
                                                <img className="@apply inline-block mx-0 w-full mb-[-0.6%]" src="https://i.ytimg.com/vi/-diHAmL4rOY/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAl8OKgnVMsnRsEOioSU8qHLD6qtA" alt="" />
                                            </div>

                                            {/* <img className="w-full" src={medium.url} alt="" /> */}
                                            <span className='px-[0.2rem] text-[0.7rem] absolute right-1 bottom-[9px] bg-black text-white rounded-sm'>
                                                15:00
                                            </span>
                                        </div>

                                        <div className='flex'>
                                            <div className="shrink-0 mx-0 my-0 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                            </div>
                                            <div className='flex-col'>
                                                <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal line-">
                                                    <p className='items-center leading-4 font-medium text-gray-800 lg:text-[14px] mb:text-mb'>THHIS ISSSS ITTT</p>
                                                </div>
                                                <div className='mt-1'>
                                                    <div className='flex'>
                                                        <p className='text-[13px] text-gray-500 '>{channelDisplayName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'}`}>
                                <Video video={video} key={video.id} />
                            </div>
                    ))
                }
            </div>
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Smart Test Main</title>
                <meta name="description" content="Smart Test Main of Thumbnail Geek" />
            </Helmet>
            <div className={` ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818] '} transition duration-300`}>
                <button onClick={() => setShowSidebar(!showSidebar)} className="fixed z-20 top-[38px] left-4 bg-white mr-2 mt-6 border rounded-full lg:hidden">
                    <HiOutlineMenuAlt2 className='w-10 h-10 p-2' />
                </button>

                {/* <div className="ml-auto mb-6 lg:w-[80%] xl:w-[80%] 2xl:w-[85%] 3xl:w-[95%]"> */}
                <div className="ml-auto mb-6 lg:w-[100%]">
                    <div className="px-6 min-h-screen">

                        <div className={`${darkMode === false ? 'border-gray-200' : 'border-gray-800'} py-3 pt-6 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4`}>
                            <div className="">
                                <a className={`${darkMode === false ? 'text-gray-800' : 'text-gray-50'} relative text-2xl font-bold lg:text-2xl hover:text-gray-700`} href="#">
                                    thumbnailGeek.
                                </a>
                            </div>
                            <div className="inline-flex w-full ml-2 pt-1 space-x-2">
                                <h5 hidden className={`${darkMode === false ? 'text-gray-600' : 'text-gray-50'}  font-lora text-xl text-gray-600 font-medium lg:block`}>|</h5>
                                <h5 hidden className={`${darkMode === false ? 'text-gray-600' : 'text-gray-50'}  font-lora text-xl text-gray-600 font-medium lg:block ml-2`}>Comparison Testing</h5>
                            </div>
                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <!-- Profile dropdown --> */}
                                <div className="ml-3 relative">
                                    <div>
                                        <button onClick={() => setProfileDropdown(!profileDropdown)} type="button" className="h-8 w-8 bg-gray-800 flex text-sm rounded-full outline-none ring-2 ring-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                            <img className="h-8 w-8 rounded-full" src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                                        </button>
                                    </div>
                                    <div hidden className={`${profileDropdown ? 'block' : ''} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                        {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">Your Profile</a>
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-1">Settings</a>
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto mt-3">
                            <div className="w-full mx-auto">
                                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                    <Tab.List className={`${darkMode === false ? 'border-gray-200 text-gray-800' : 'border-gray-800 text-gray-500'} relative border-b text-sm`}>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className={`inline-flex p-3`}>
                                                <AiOutlineTable className='mt-1 mr-1.5' />
                                                <p className='inline-flex'>Home &nbsp;<span className='hidden lg:block'> Page</span></p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="inline-flex p-3">
                                                <AiOutlineBars className='mt-1 mr-1.5' />
                                                <p className='inline-flex'>Search &nbsp;<span className='hidden lg:block'> Page</span></p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="hidden lg:inline-flex p-3">
                                                <AiOutlineImport className='mt-1 mr-1.5' />
                                                <p className='inline-flex'>Side &nbsp;<span className='hidden lg:block'> Panel</span></p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="inline-flex p-3">
                                                <AiOutlineSolution className='mt-1 mr-1.5' />
                                                <p className='inline-flex'>Channel &nbsp;<span className='hidden lg:block'> Page</span></p>
                                            </span>
                                        </Tab>

                                        <div className='absolute z-0 right-0 top-2' >
                                            {/* <Toggle /> */}
                                            <div className="transition duration-500 ease-in-out rounded-full p-1">
                                                {darkMode === false ? (
                                                    <>
                                                        <div className="inline-flex text-sm cursor-pointer" onClick={() => setDarkMode(!darkMode)}>
                                                            <FaRegLightbulb className="mt-1 mr-1 text-gray-500 dark:text-gray-400 text-md" />
                                                            <span className="text-gray-500">
                                                                Light
                                                            </span>
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="inline-flex text-sm cursor-pointer" onClick={() => setDarkMode(!darkMode)}>
                                                            <FaLightbulb className="mt-1 mr-1 text-gray-500 dark:text-gray-400 text-md" />
                                                            <span className="text-gray-500">
                                                                Dark
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                    </Tab.List>
                                    <Tab.Panels className='w-full'>
                                        {/* //Home Page */}
                                        <Tab.Panel className='w-full'>
                                            <div className='flex justify-between w-full '>

                                                <div className="inline-flex justify-between w-full">

                                                    <div className="inline-flex space-x-6 mt-5">
                                                        <button onClick={() => setRefresh(refresh + 1)} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-600' : 'text-gray-500 bg-gray-800'} mr-4 h-9 pr-4 pl-3 rounded-md bg-gray-100 inline-flex justify-center items-center text-xl`}>
                                                            <MdOutlineArrowBack /><p className={`text-sm ml-1`}>Back Home</p>
                                                        </button>
                                                    </div>

                                                    <div className="inline-flex ">
                                                        {/* <div className="inline-flex space-x-6 mt-5">
                                                            <button className={`${darkMode === false ? 'text-gray-800' : 'text-gray-500'} text-xl`}>
                                                                <IoSettingsOutline />
                                                            </button>
                                                            <button className={`${darkMode === false ? 'text-gray-800 bg-gray-100' : 'text-gray-500 bg-gray-800'} px-2 rounded-md bg-gray-100 inline-flex items-center text-xl`}>
                                                                <IoMdGlobe /> <p className={`ml-1 mr-3 text-sm`}>Worldwide</p> <IoIosArrowDown className='text-sm' />
                                                            </button>

                                                        </div>

                                                        <div className="inline-flex space-x-6 mt-5 ml-5">
                                                            <button onClick={() => setRefresh(refresh + 1)} className={`${darkMode === false ? 'text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-600' : 'text-gray-500 bg-gray-800'} px-4 rounded-md bg-gray-100 inline-flex justify-center items-center text-xl`}>
                                                                <IoRefresh /><p className={`text-sm ml-1`}>Refresh</p>
                                                            </button>
                                                        </div>

                                                        <div className="relative w-92 ml-12 mt-5 mr-20 ">
                                                            <form onSubmit={processSearch}>
                                                                <input onChange={(e) => setTempSearchQuery(e.target.value)} type="search" id="search-dropdown" className="min-w-80 p-2 outline-none block w-80 pr-9 z-20 text-sm text-gray-900 border-b bg-transparent" placeholder="Search and compare with a niche" />
                                                                <button type="submit" className={`absolute top-1 right-0 p-2.5 text-sm font-medium ${darkMode === false ? 'text-gray-800' : 'text-gray-500'} outline-0 `}>
                                                                    <IoSearch classname="text-sm" />
                                                                    <span className="sr-only">Search</span>
                                                                </button>
                                                            </form>
                                                        </div> */}
                                                    </div>

                                                    <div className={`flex flex-wrap ${darkMode === false ? 'text-gray-400' : 'text-gray-600'} mt-5`}>

                                                        <div className="inline-flex items-end">
                                                            <button onClick={() => handleDeviceOptions(1)} className={`${deviceOption === 1 ? 'text-[#FC2959] border-[#FC2959] opacity-90' : ''} hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border-b-2 mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                                <IoIosDesktop className='mt-[1px]' />
                                                                {/* <span className='ml-2 text-[12px]'>Desktop</span> */}
                                                            </button>
                                                        </div>

                                                        <div className="inline-flex items-end">
                                                            <button onClick={() => handleDeviceOptions(2)} className={`${deviceOption === 2 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''}  hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border-b-2 mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                                <IoTabletLandscapeOutline className='' />
                                                                {/* <span className='ml-2 text-[12px]'>Tablet</span> */}
                                                            </button>
                                                        </div>

                                                        <div className="inline-flex items-end">
                                                            <button onClick={() => handleDeviceOptions(3)} className={`${deviceOption === 3 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''}  px-2 py-1 inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border-b-2 lg:mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                                <BsPhone className='text-[16px]' />
                                                                {/* <span className='ml-2 text-[12px]'>Phone</span> */}
                                                            </button>
                                                        </div>

                                                        <div className="inline-flex items-end">
                                                            <button onClick={() => handleDeviceOptions(4)} className={`${deviceOption === 4 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''} hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border-b-2 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                                <FiTv className='text-md' />
                                                                {/* <span className='ml-2 text-[12px]'>TV</span> */}
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='w-full mt-7 pb-10'>
                                                {renderHomePageDeviceOptions()}
                                            </div>
                                        </Tab.Panel>

                                        {/* //Search */}
                                        <Tab.Panel className='w-full'>
                                            {renderSearchDeviceOptions}
                                        </Tab.Panel>
                                        <Tab.Panel>

                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmartTestMain





// {`${darkMode === false ? '' : ''}


{/* <div className={`flex flex-wrap ${darkMode === false ? 'text-gray-400' : 'text-gray-600'} mt-5`}>

                                                    <div className="inline-flex items-end">
                                                        <button onClick={() => handleDeviceOptions(1)} className={`${deviceOption === 1 ? 'text-[#FC2959] border-[#FC2959] opacity-90' : ''} hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border rounded-md mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                            <IoIosDesktop className='mt-[1px]' />
                                                            <span className='ml-2 text-[12px]'>Desktop</span>
                                                        </button>
                                                    </div>

                                                    <div className="inline-flex items-end">
                                                        <button onClick={() => handleDeviceOptions(2)} className={`${deviceOption === 2 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''}  hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border rounded-md mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                            <IoTabletLandscapeOutline className='' />
                                                            <span className='ml-2 text-[12px]'>Tablet</span>
                                                        </button>
                                                    </div>

                                                    <div className="inline-flex items-end">
                                                        <button onClick={() => handleDeviceOptions(3)} className={`${deviceOption === 3 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''}  px-2 py-1 inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border rounded-md lg:mr-1 hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                            <BsPhone className='text-[16px]' />
                                                            <span className='ml-2 text-[12px]'>Phone</span>
                                                        </button>
                                                    </div>

                                                    <div className="inline-flex items-end">
                                                        <button onClick={() => handleDeviceOptions(4)} className={`${deviceOption === 4 ? 'text-[#FC2959] border-[#FC2959] opacity-80' : ''} hidden px-2 py-1 lg:inline-flex items-center justify-center ${darkMode === false ? 'border-gray-300' : 'border-gray-700'} border rounded-md hover:text-[#FC2959] hover:border-[#FC2959]`}>
                                                            <BsDisplay className='text-md' />
                                                            <span className='ml-2 text-[12px]'>TV</span>
                                                        </button>
                                                    </div>

                                                </div> */}


{/* <div className="">
                                    <button className="px-4 inline-flex items-center justify-center border rounded-md mr-2">
                                        <BiDesktop />
                                        <span className='ml-2'>Desktop</span>
                                    </button>
                                    <Toggle />
                                </div> */}

{/*

        // <div className='inline-flex mr-4' key={file.name}>
        //     <div className='flex flex-col overflow-hidden'>
        //         <div className='w-[304px] h-[170px]'>
        //             <img className='w-auto h-full'
        //                 src={file.preview}
        //                 // Revoke data uri after image is loaded
        //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
        //             />
        //         </div>
        //         <input className='text-sm p-1 px-2 font-light border mt-3 w-[304px] h-full' placeholder='Add a compelling title' />
        //     </div>
        // </div>


<div className=" justify-center">
    <h2 className='font-bold text-2xl mb-8 text-indigo-600'>Make your next upload irresistible! Start split testing </h2>
    </div>
    <div className="flex flex-col mt-0 pt-0">
    <div className="grid md:grid-cols-12 gap-0">
    <div className="col-span-4 pr-4">
        <h2 className='font-bold text-2xl text-slate-700'>Add Titles</h2>
        <p className='font-light text-sm'>Compose Titles That Will Grab Your Viewer's Attention</p>
        <div className="flex flex-col justify-center items-center ">
            <input type="text" onChange='' placeholder='A Catchy Title' className='mt-4 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <input type="text" onChange='' placeholder='Another Catchy Title' className='mt-3 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <input type="text" onChange='' placeholder='The Most Catchy Title' className='mt-3 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <button className='h-10 w-10 bg-indigo-800 items-center justify-center mt-4 rounded-full text-white'>+</button>
        </div>
    </div>
    <div className="h-full w-full items-center col-span-8 md:border-l pl-4 border-gray-200">
        <h2 className='font-bold text-2xl text-slate-700'>Add Thumbnails</h2>
        <p className='font-light text-sm'>Upload at least 3 Thumbnails clhtmlFor Split Testing Attractiveness and Clickability</p>
        <div className=" flex flex-col mt-4 py-10 items-center bg-slate-200 rounded-lg border border-slate-300 w-full">
            <p className="font-normal text-gray-800">Drag and drop thumbnail files to upload</p>
            <p className="font-light text-sm  text-gray-500 mb-0 pb-0">You can begin split testing right after this.</p>
            <button className='file: h-10 w-auto px-5 bg-indigo-800 mt-4 rounded-md text-white'>Select Thumbnails</button>
        </div>
    </div>
    </div>
    </div> */}






{/* <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">

                                        <li className="mr-2">
                                            <a href="#" className="inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group" aria-current="page">
                                                <svg aria-hidden="true" className="mr-2 w-5 h-5 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                                                Home Page
                                            </a>
                                        </li>
                                        <li className="mr-2">
                                            <a href="#" className="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                                <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                                                Search Page
                                            </a>
                                        </li>
                                        <li className="mr-2">
                                            <a href="#" className="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                                <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path></svg>
                                                Side Panel
                                            </a>
                                        </li>
                                        <li className="mr-2">
                                            <a href="#" className="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                                <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                                                Channel Page
                                            </a>
                                        </li>
                                    </ul> */}