import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDropzone } from 'react-dropzone';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { Helmet } from "react-helmet";
import { db, storage } from '../../../firebase-config';
import useDynamicRefs from 'use-dynamic-refs';
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { FaTrashAlt } from "react-icons/fa";
import { TfiTrash } from "react-icons/tfi";
import { Slide, toast } from 'react-toastify';
import { logout } from '../../../redux/actions/auth.action';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject, getStorage } from "@firebase/storage";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy, limit, onSnapshot, increment } from "firebase/firestore";
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar';
import SplitTestCollage from '../../components/SplitTestCollage';
import DotDotDot from '../../components/DotDotDot';
import { XCircleIcon } from '@heroicons/react/20/solid'
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import LiveChat from '../../components/LiveChat';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import { FiAlertCircle, FiAlertOctagon, FiAlertTriangle } from 'react-icons/fi';
import { TbFileAnalytics } from 'react-icons/tb';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import Gauge from '../../components/Guage';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ABComparisonUpload = () => {
    // console.log('db', db);
    // console.log('storage', storage);
    const navigate = useNavigate()
    const captionRef = useRef([])
    const [getRef, setRef] = useDynamicRefs();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const { accessLevel, aiCreditsLeft, premiumStatus } = PremiumCredentials();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const mainUserId = localStorage.getItem("mainUserId")
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [testsLoaded, setTestsLoaded] = useState(false);
    const [tests, setTests] = useState([]);
    const [uploadBtnActive, setUploadBtnActive] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const [applyToAllText, setApplyToAllText] = useState("");
    const [percent, setPercent] = useState(0);
    const { pathname } = useLocation();
    const [inputs, setInputs] = useState([{ value: '' }]);
    const [titles, setTitles] = useState([]);
    const [splitTestId, setSplitTestId] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [allInputsHaveText, setAllInputsHaveText] = useState(false);
    const [blockPopup, setBlockPopup] = useState(false)
    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)

    // Obtain a reference to the Firebase Storage instance
    const storage = getStorage();

    // let maxImages = 4; //- for standard
    // let maxImages = 12; //- for pro
    let maxImages = 8; //- for start

    useEffect(() => {
        // if (accessLevel == 1) {
        //     maxImages = 4;
        // }

        // if (accessLevel == 2) {
        //     maxImages = 12;
        // }

        const getSplitTests = async () => {
            const colRef = collection(db, 'Users', mainUserId, 'SplitTests');
            const q = query(
                colRef,
                orderBy("date_created", "desc"),
                limit(12)
            );
            await getDocs(q)
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setTests(newData);
                    setTestsLoaded(true);
                })
        }

        getSplitTests();
    }, []);

    function validator(file) {
        if (file.size > '20000') {
            return {
                code: "size-too-large",
                message: `Image larger than 2MB`
            };
        }
        return null
    }

    const deleteSplitTest = async (test_id) => {
        setDeleting(true);
        const docRef = doc(db, 'Users', mainUserId, 'SplitTests', test_id);
        const docSnap = await getDoc(docRef);
        const splitTest = docSnap.data();

        await deleteDoc(docRef);

        const items = splitTest.items || [];

        for (let i = 0; i < items.length; i++) {
            const thumbnailId = items[i]['thumbnail_id'];
            const thumbnailUrl = items[i]['thumbnail'];
            const thumbnailDocRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnailId);

            // Delete the thumbnail document from the UserThumbnails collection
            await deleteDoc(thumbnailDocRef);

            // Delete the thumbnail image from the Firebase Storage bucket
            const thumbnailRef = ref(storage, thumbnailUrl);
            await deleteObject(thumbnailRef);
        }

        deleteModalClose();
        setTests((state) => state.filter((item) => item.id !== test_id));
        toast("Comparison Test Deleted", {
            icon: ({ theme, type }) => <FaTrashAlt className='text-green-500 h-3 w-3' />
        });

        const test = tests.find(item => item.id === test_id);

        if (test) {
            // let lengthOfItems = test.items.length;
            for (const prop in test) {
                if (prop === "thumbnail") {
                    // Do an api call to delete the image
                    console.log(test[prop]);
                }
            }
        } else {
            // The test with the specified id was not found in the tests array
            console.log(`Test with id ${test_id} not found`);
        }

        setDeleting(false);
    }

    async function waitForItemsToUpload(id) {
        setPercent(100);
        const splitTestRef = doc(db, 'Users', mainUserId, 'SplitTests', id);
        return new Promise((resolve) => {
            const unsubscribe = onSnapshot(splitTestRef, (doc) => {
                const items = doc.data()?.items || [];

                if (items.length === selectedImages.length) {
                    unsubscribe();
                    resolve();
                    // Redirect to the next page
                    let path = `/testing-suite/compare/${id}`;
                    navigate(path);
                    setUploading(false);
                }
            });
        });
    }

    const goToSplitTestDetails = (id) => {
        let path = `/testing-suite/compare/${id}`;
        navigate(path);
    }

    const uploadClicked = () => {
        setUploading(true);
        uploadImages();
    }

    const updateDocument = async (user_id, doc_id) => {
        await updateDoc(doc(db, "Users", user_id), {
            currentTestId: doc_id,
        })
    }

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const scaleFactor = 720 / img.width;
                    const canvas = document.createElement("canvas");
                    canvas.width = 720;
                    canvas.height = img.height * scaleFactor;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        blob.lastModifiedDate = new Date();
                        blob.name = file.name;
                        const newFile = new File([blob], file.name, { type: "image/png" });
                        const initialWidth = img.width;
                        const initialHeight = img.height;
                        resolve({ file: newFile, initialWidth, initialHeight });
                    }, "image/png", 0.9);
                };
            };
        });
    };

    const uploadImages = async () => {
        const docRef = doc(db, "Users", mainUserId);
        const colRef = collection(docRef, "SplitTests");
        const addedColDocRef = await addDoc(colRef, {
            user_id: mainUserId,
            test_type: "Main",
            date_created: serverTimestamp(),
        });
        await Promise.all(
            selectedImages.map(async (image, i) => {
                const title = titles[i];
                console.log(title);

                const { file: resizedImage, initialWidth, initialHeight } = await resizeImage(
                    image
                );

                const docRef = doc(db, "Users", mainUserId);
                const colRef = collection(docRef, "UserThumbnails");
                const newImgResp = await addDoc(colRef, {
                    user_id: mainUserId,
                    test_type: "Main",
                    should_analyse: false,
                    date_created: serverTimestamp(),
                });
                const extension = resizedImage.type.split("/").pop();
                const imageRef = ref(
                    storage,
                    `/userThumbnails/${mainUserId}/${newImgResp.id}.${extension}`
                );
                const uploadTask = uploadBytesResumable(imageRef, resizedImage, {
                    contentType: "data_url",
                });
                uploadTask.on("state_changed", (snapshot) => {
                    const percent =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const newPercent = Math.round((i + percent) / selectedImages.length);
                    setPercent(newPercent);
                });
                await uploadTask;
                const downloadURL = await getDownloadURL(imageRef);
                const userDoc = doc(
                    db,
                    "Users",
                    mainUserId,
                    "UserThumbnails",
                    newImgResp.id
                );
                const data = {
                    thumbnail: downloadURL,
                    title: title,
                    resolution_width: initialWidth,
                    resolution_height: initialHeight,
                };
                setDoc(userDoc, data, { merge: true });
                updateDoc(
                    doc(db, "Users", mainUserId, "SplitTests", addedColDocRef.id),
                    {
                        ai_analysis_done: false,
                        items: arrayUnion({
                            thumbnail_id: newImgResp.id,
                            thumbnail: downloadURL,
                            title: title,
                        }),
                    }
                );


            })
        )
            .then(async () => {
                updateDocument(mainUserId, addedColDocRef.id);
                setSplitTestId(addedColDocRef.id);
                await waitForItemsToUpload(addedColDocRef.id);
            })
            .catch((e) => {
                console.log(e);
                setUploading(false);
                toast("An error occured. Unable to upload images!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            });

        updateDoc(
            doc(db, "UserData", mainUserId),
            {
                thumb_compare_count: increment(1),
                date_modified: serverTimestamp(),
            }
        );
    };

    const handleInputChange = (e, index) => {
        console.log(`Input changed for index ${index}: ${e.target.value}`);
        const newTitles = [...titles];
        newTitles[index] = e.target.value;
        setTitles(newTitles);
        handleInputFilled(e);
    };

    const handleInputFilled = (e) => {
        const numberOfInputs = selectedImages.length;
        if (e.target.value.trim() === '') {
            setAllInputsHaveText(false);
        } else {
            const numberOfFilledInputs = titles.filter((item) => item.trim() !== "").length;
            if (numberOfInputs === numberOfFilledInputs) {
                setAllInputsHaveText(true);
                console.log('filled');
            } else {
                setAllInputsHaveText(false);
                console.log('not filled');
            }
        }
    }

    const verifyAccount = () => {
        if (accessLevel !== 1 || accessLevel !== 2) {
            setBlockPopup(true);
        }
    }

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png, image/gif',
        maxFiles: maxImages,
        maxSize: 2 * 1024 * 1024, // 2 MB in bytes
        onDrop: acceptedFiles => {
            // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            if (aiCreditsLeft > 4) {

                if (fileRejections.length === 0) {
                    if (acceptedFiles.length === 1) {
                        console.error("Error: Please select more than one image.");
                        toast.error('Select 2 or more thumbnails to compare', {
                            position: "top-right",
                            autoClose: 10000,
                        });
                    } else {
                        // const maxWidth = 1920; // Maximum width requirement
                        // const maxHeight = 1080; // Maximum height requirement

                        // const invalidDimensionsFiles = acceptedFiles.filter(file => {
                        //     return new Promise((resolve) => {
                        //         const img = new Image();
                        //         img.src = URL.createObjectURL(file);
                        //         img.onload = () => {
                        //             resolve(img.width > maxWidth || img.height > maxHeight);
                        //         };
                        //     });
                        // });

                        // if (invalidDimensionsFiles.length > 0) {
                        //     invalidDimensionsFiles.forEach(file => {
                        //         toast.error(`Image '${file.name}' dimensions Recommended .`, {
                        //             position: "top-right",
                        //             autoClose: 10000,
                        //         });
                        //     });
                        // } else {
                        //     setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
                        //         preview: URL.createObjectURL(file)
                        //     })));
                        //     setUploadBtnActive(true);
                        //     openUploadModal();
                        // }

                        // const invalidDimensionsFiles = acceptedFiles.filter(file => {
                        //     return new Promise((resolve) => {
                        //         const img = new Image();
                        //         img.src = URL.createObjectURL(file);
                        //         img.onload = () => {
                        //             const aspectRatio = img.width / img.height;
                        //             // Check if the aspect ratio is approximately 16:9
                        //             if (Math.abs(aspectRatio - (16 / 9)) < 0.01) {
                        //                 toast.warning(`'${file.name}' -  Invalid dimensions. 1280x720 recommended. Image may be cropped on Youtube!`, {
                        //                     position: "top-right",
                        //                     autoClose: 10000,
                        //                 });
                        //                 resolve(false); // Valid aspect ratio
                        //             } else {
                        //                 resolve(true); // Invalid aspect ratio
                        //             }
                        //         };
                        //     });
                        // });

                        // if (invalidDimensionsFiles.length > 0) {
                        //     // Handle invalid dimensions
                        //     setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
                        //         preview: URL.createObjectURL(file)
                        //     })));
                        //     setUploadBtnActive(true);
                        //     openUploadModal();
                        // } else {
                        // Handle valid dimensions
                        setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })));
                        setUploadBtnActive(true);
                        openUploadModal();
                        // }
                    }
                } else {
                    // Handle file rejection and show toast for each rejected file
                    fileRejections.forEach(rejection => {
                        if (rejection.errors[0].code === "file-too-large") {
                            // Handle rejection due to file size
                            const sizeInMB = (rejection.file.size / (1024 * 1024)).toFixed(2);
                            toast.error(`Size Limit Exceeded (2MB Limit) - ${rejection.file.name} - Size: ${sizeInMB} MB`, {
                                position: "top-right",
                                autoClose: 10000,
                            });
                        } else if (rejection.errors[0].code === "file-invalid-type") {
                            // Handle rejection due to file type
                            toast.error(`Invalid File Type - ${rejection.file.name} - Only JPG, GIF, or PNG accepted`, {
                                position: "top-right",
                                autoClose: 10000,
                            });
                        } else {
                            // Handle other types of rejections
                            toast.error(`File rejected - ${rejection.file.name}`, {
                                position: "top-right",
                                autoClose: 10000,
                            });
                        }
                    });
                }
            } else {
                if (premiumStatus === 'TRIALING') {
                    setCreditBlockPopupTrial(true);
                } else {
                    setCreditBlockPopup(true);
                }

                toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                    position: "top-right",
                    autoClose: 10000,
                });

            }
            // } else {
            //     setCreditBlockPopup(true);
            // }
        }
    });

    // const {
    //     getRootProps,
    //     getInputProps,
    //     fileRejections,
    // } = useDropzone({
    //     accept: 'image/jpeg, image/jpg, image/png, image/gif',
    //     maxFiles: maxImages,
    //     maxSize: 2 * 1024 * 1024, // 2 MB in bytes
    //     onDrop: acceptedFiles => {
    //         if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
    //             if (fileRejections.length === 0) {
    //                 if (acceptedFiles.length === 1) {
    //                     console.error("Error: Please select more than one image.");
    //                     toast.error('For comparison, select 2 or more thumbnails', {
    //                         position: "top-right",
    //                         autoClose: 5000,
    //                     });
    //                 } else {
    //                     setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
    //                         preview: URL.createObjectURL(file)
    //                     })));
    //                     setUploadBtnActive(true);
    //                     openUploadModal();
    //                 }
    //             } else {
    //                 // Handle file rejection error if needed
    //                 console.log('File rejected:', fileRejections);
    //             }
    //         } else {
    //             setBlockPopup(true);
    //         }
    //     }
    // });

    const thumbs = selectedImages.map((file, i) => (
        <div key={i} className={`relative text-gray-800 w-64 mt-5 pb-3 text-[0.9rem] mx-2 mb-5 group`}>
            <div className="mb-2 cursor-pointer">
                <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                    <img className="aspect-video @apply inline-block mx-0 w-full rounded-lg" src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="" />
                    <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                    </div>
                </div>
            </div>

            <div className='flex'>
                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                </div>
                <div className='flex-col w-10/12'>
                    <div className="cursor-pointer flex flex-col mb-[0.1rem] pr-1 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                        <input
                            id={`title-${i}`}
                            // onInput={(e) => {
                            //     const newTitles = [...titles];
                            //     newTitles[i] = e.target.value;
                            //     setTitles(newTitles);
                            //     handleInputFilled(e);
                            // }}
                            onInput={(e) => handleInputChange(e, i)}
                            onBlur={(e) => handleInputChange(e, i)}
                            rows="2"
                            placeholder='Type a compelling title here'
                            className='text-[12px] w-full p-1 pb-2 flex-1 px-0 font-semibold outline-none border-b border-gray-200 h-full line bg-transparent' />
                    </div>
                </div>
            </div>
        </div>

    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div className="absolute z-50 right-5 top-20 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
            <div className="flex items-center justify-center w-16 bg-red-500">
                <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
            </div>

            <div className="px-4 py-2 -mx-3 text-white">
                <div className="mx-3 text-white">
                    <p className="text-sm text-white dark:text-gray-600">Wrong image format</p>
                    <li className="text-white" key={file.path}>
                        {file.path}
                    </li>
                    <ul>
                        {errors.map(e => <li key={e.code}>{e.message}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    ));

    const openDeleteModal = (name, id) => {
        setItemToDelete(name);
        setIdToDelete(id)
        setDeleteModal(true);
    }

    const handleDeleteModalClose = (e) => {
        if (e.target.id === "st-item-delete-modal") {
            setDeleteModal(false);
        };
    }

    const deleteModalClose = (e) => {
        setDeleteModal(false);
    }

    const openUploadModal = () => {
        setUploadModal(true);
    }

    const handleUploadModalClose = (e) => {
        if (e.target.id === "upload-items-modal" && uploading === false) {
            setUploadModal(false);
        };
    }

    const uploadModalClose = (e) => {
        if (uploading === false) {
            setUploadModal(false);
            setSelectedImages([]);
            setTitles([]);

        }
    }

    const applyTitleToAll = () => {
        const delay = 20;
        const text = applyToAllText;

        // applyToAllText
        selectedImages.map((image, i) => {
            const input = document.getElementById(`title-${i}`);
            input.value = '';
            let index = 0;

            const intervalId = setInterval(() => {
                input.value += text[index];
                index++;
                if (index >= text.length) {
                    clearInterval(intervalId);
                }
            }, delay);

            setTitles(prevTitles => {
                // create a new array with the updated element
                const updatedTitles = [...prevTitles];
                updatedTitles[i] = text;
                return updatedTitles;
            });
            // console.log(i);
            // console.log(titles[i]);
        });
        setAllInputsHaveText(true);
    }

    const generateAITitles = () => {
        toast("Coming really soon", {
            icon: ({ theme, type }) =>
                <MdOutlineTipsAndUpdates className='text-blue-300 h-7 w-7' />
        });
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Comparison Suite | ThumbnailGeek</title>
                <meta name="description" content="Comparison Suite | ThumbnailGeek" />
            </Helmet>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/'} />

            <div id="upload-items-modal" className={`${uploadModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                {
                    uploading === false
                        ?
                        <div className="bg-white max-h-full max-w-4xl overflow-scroll rounded-lg shadow dark:bg-gray-700 px-4">
                            <div className="w-full h-full md:h-auto overflow-hidden">
                                {/* Button */}
                                <div className="flex items-start justify-between pt-4 pb-3 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-base mt-2 font-semibold text-gray-900 dark:text-white">
                                        Your Thumbnails
                                    </h3>

                                    <div className="inline-flex ml-16">
                                        <button onClick={uploadModalClose} type="button" className="w-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='flex flex-wrap w-full max-h-[400px] overflow-scroll mt-3 justify-center'>
                                    {thumbs}
                                    {fileRejectionItems}
                                </div>

                                {/* Button */}
                                <div className="border-t flex w-full">
                                    <div className="w-full flex justify-center items-center">
                                        {/* <div className="inline-flex mr-4">
                                            <div className="mr-1 ">
                                                <input onChange={(e) => setApplyToAllText(e.target.value)} type="text" className="text-xs p-2 outline-none block w-60 pr-1 text-gray-900 border-b bg-transparent" placeholder="Title to Apply to All" />
                                            </div>
                                            <button onClick={applyTitleToAll}
                                                type="button"
                                                className="rounded bg-white py-1 px-2 h-8 text-xs font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                Apply to All
                                            </button>
                                            <button onClick={generateAITitles}
                                                type="button"
                                                className="rounded bg-white ml-2 py-1 px-2 h-8 text-xs font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                Generate AI Titles
                                            </button>
                                        </div> */}
                                        {
                                            uploading === false
                                                ?
                                                (
                                                    <>
                                                        {
                                                            allInputsHaveText === true
                                                                ?
                                                                <div className='flex justify-center items-center my-4'>
                                                                    <button onClick={uploadClicked} className='bg-slate-700 w-64 bg-gradient-to-r 
                                                                    from-blue-600 to-[#FC2959] opacity-90 hover:opacity-100 transition-colors 
                                                                    hover:duration-1200 text-white p-3 rounded-lg'>
                                                                        Start Comparing
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className='flex justify-center items-center my-4'>
                                                                    <button disabled className='cursor-not-allowed bg-gray-200 text-gray-400 w-64 p-3 rounded-lg'>
                                                                        Start Comparing
                                                                    </button>
                                                                </div>
                                                        }
                                                    </>
                                                )
                                                :
                                                <div className='flex justify-center items-center my-4'>
                                                    <button disabled className='cursor-wait bg-gray-200 text-gray-400 w-64 p-3 rounded-lg'>
                                                        Uploading...
                                                    </button>
                                                </div>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                        :
                        <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg w-96 border border-gray-400 shadow-md">
                            <h1 className="font-semibold text-center text-md mb-4 mt-2 text-gray-700">
                                Processing...
                            </h1>
                            <div className="w-full h-[105px] text-center animate-pulse flex items-center justify-center bg-gray-200 rounded-md mb-2">
                                <p className='font-bold text-7xl '>{percent}%</p>
                            </div>
                        </div>
                }
            </div>

            <div id="st-item-delete-modal" onClick={handleDeleteModalClose} className={`${deleteModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Are you sure you want to delete?
                            </h3>
                            <button onClick={deleteModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                {/* {itemToDelete} */}
                                <div className="rounded-md bg-red-100 p-4">
                                    <div className="">
                                        <div className=" text-sm font-medium text-red-500">
                                            <ul role="list" className="space-y-1">
                                                <li>All associated thumbnails will also be deleted</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            deleting === false
                                ?
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                    <button onClick={() => deleteSplitTest(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                                </div>
                                :
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="text-white bg-gray-800 inline-flex space-x-1 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        <span>Deleting</span>
                                        <DotDotDot className="pl-2" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="testing-suite" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-10 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={` text-sm lg:text-xl text-gray-600 font-semibold lg:inline-flex`}>Compare & Pick your best thumbnail.</h5>
                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="mt-16 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8">

                            <div className="w-full h-full mx-auto">

                                <h2 className='text-center font-bold text-3xl mb-7 text-gray-600'>You're 3 times more likely to discover a <br />high-performing thumbnail through comparison</h2>

                                <div className="mb-4 inline-flex justify-between items-center w-full">
                                    <div className="w-full text-lg text-center font-normal text-gray-500 leading-tight">Upload 2 or more thumbnails you want to compare</div>
                                    {/* <input type="text" className="min-w-80 p-2 outline-none block w-80 pr-9 text-sm text-gray-900 border-b bg-transparent" placeholder="Untitled Test Title" /> */}
                                </div>


                                {
                                    accessLevel < 9
                                        ?
                                        <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-52 mb-8 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer  hover:bg-gray-100 " {...getRootProps()}>
                                            <div className="flex flex-col justify-center items-center text-sm pt-5 pb-5 px-5">
                                                <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <>
                                                    <span>Drag and  Drop thumbnails here</span>
                                                    <span className="mt-1 text-gray-400">or</span>
                                                    <button className="bg-blue-500 rounded-lg p-2 px-3 mt-2 text-white">Browse Files</button>
                                                </>
                                            </div>
                                            <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                                        </label>
                                        :
                                        <>
                                            <label onClick={verifyAccount} htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-52 mb-8 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer  hover:bg-gray-100 ">
                                                <div className="flex flex-col justify-center items-center pt-5 pb-5 px-5">
                                                    <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                    </svg>
                                                    <>
                                                        <p className="mb-2 text-md text-gray-500 dark:text-gray-400">Upgrade to access this feature</p>
                                                    </>

                                                </div>
                                            </label>
                                        </>
                                }

                                <div className='flex justify-center items-center w-full mx-4 mt-3'>
                                    {/* <h2 className="mb-5 text-sm text-gray-500"><span className='text-gray-500 font-semibold'>Recommended:</span> Upload 2 or 3 different thumbnails of your video for testing.</h2> */}
                                    <div className='flex flex-col items-center justify-center text-bold'>
                                        {/* <p className="text-md font-bold text-gray-700 leading-tight mb-2">Upload 2 or more Thumbnails</p> */}
                                        <p className="text-xs font-semibold text-gray-500 dark:text-gray-300">JPG, GIF, or PNG (1280x720)</p>
                                        <p className="text-sm text-gray-500 dark:text-gray-400">• 2MB Limit •</p>
                                    </div>
                                </div>

                                <div className="mt-8">
                                    <div className=" lg:border-b pb-2 text-neutral-500">
                                        Your Previous Side-By-Side Comparisons
                                    </div>
                                    <div className="pt-5">
                                        {
                                            testsLoaded === true
                                                ?
                                                <>
                                                    {
                                                        <>
                                                            {
                                                                tests.length === 0
                                                                    ?
                                                                    <div className="flex flex-col items-center justify-center text-center mt-12 mb-12">

                                                                        <TbFileAnalytics className='w-7 h-7 text-gray-600' />
                                                                        <h3 className="mt-2 text-sm font-semibold text-gray-600">No comparisons yet</h3>
                                                                        <p className="mt-1 text-sm text-gray-500">Get started by by uploading some thumbnails to compare.</p>

                                                                    </div>
                                                                    :
                                                                    <div className="flex flex-wrap gap-x-4 gap-y-1">
                                                                        {tests?.map((doc, i) => {
                                                                            if (doc.items) {
                                                                                return (
                                                                                    <div key={i} className="">
                                                                                        <div className="w-52 h-auto relative mb-5">
                                                                                            <NavLink to={`/testing-suite/compare/${doc.id}`}>
                                                                                                <div onClick={() => { goToSplitTestDetails(doc.id) }} className="border border-gray-300 rounded-md w-52 h-32 bg-gray-200 cursor-pointer overflow-hidden group">
                                                                                                    <SplitTestCollage itemsData={doc.items} />
                                                                                                    <div className="rounded-md absolute top-0 left-0 w-52 h-32 bg-black opacity-0 group-hover:opacity-50 group-active:opacity-80 duration-200">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </NavLink>
                                                                                            <div className="w-full inline-flex">
                                                                                                <div onClick={() => { goToSplitTestDetails(doc.id) }} className="w-10/12 group">
                                                                                                    <div className="m-0 p-0 mt-3 pl-1 w-full line-clamp-1 cursor-pointer leading-tight lg:text-[14px] md:text-md font-medium">
                                                                                                        {doc.items[0].title}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div onClick={() => { openDeleteModal(doc.name, doc.id) }} className="w-2/12 m-1 mr-0 p-2 hover:bg-neutral-300 rounded-lg h-8 hover:cursor-pointer">
                                                                                                    <TfiTrash className='ml-[1px]' />
                                                                                                </div>
                                                                                                {/* <Menu as="div" className="relative inline-block text-left">
                                                                                                    <div>
                                                                                                        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 ">
                                                                                                            <span className="sr-only">Open options</span>
                                                                                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                        </Menu.Button>
                                                                                                    </div>

                                                                                                    <Transition
                                                                                                        as={Fragment}
                                                                                                        enter="transition ease-out duration-100"
                                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                                        leave="transition ease-in duration-75"
                                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                                    >
                                                                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                            <div className="py-1">
                                                                                                                <Menu.Item>
                                                                                                                    {({ active }) => (
                                                                                                                        <div onClick={() => { openDeleteModal(doc.name, doc.id) }}
                                                                                                                            className={classNames(
                                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                'w-full px-4 py-2 text-sm inline-flex'
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <TfiTrash className='ml-[1px]' /> Delete
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Menu.Item>
                                                                                                            </div>
                                                                                                        </Menu.Items>
                                                                                                    </Transition>
                                                                                                </Menu> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })}
                                                                    </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        <div className="flex flex-wrap gap-x-4">
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                            <div className="w-52 h-52">
                                                                <div className="flex flex-wrap items-center justify-center p-3 border border-gray-200 rounded-md w-52 h-32 bg-gray-200 animate-pulse"></div>
                                                                <div className="text-center mt-2 p-0 text-sm bg-gray-200 h-5 w-40 rounded-[5px] animate-pulse"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ABComparisonUpload



