import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDropzone } from "react-dropzone";
import { PremiumCredentials } from '../../../context/PremiumContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { IoSparkles, IoSparklesOutline, IoRefresh, IoCloseCircle } from 'react-icons/io5'
import { MdCheck, MdOutlineArrowBack, MdOutlineClear } from "react-icons/md";
import { SlMagnifier, SlRefresh } from 'react-icons/sl'
import axios from 'axios';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, deleteDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "@firebase/storage";
import { toast } from 'react-toastify';
import { HiOutlineBell } from "react-icons/hi";
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar';
import { Tab } from '@headlessui/react';
import Tooltip from '../../components/Tooltip';
import { BiDoorOpen, BiRefresh, BiTrash } from 'react-icons/bi';
import { TfiDownload } from 'react-icons/tfi';
import { GiMicroscope, GiReceiveMoney } from 'react-icons/gi';
import { FaMicroscope } from 'react-icons/fa';
import { RiMicroscopeLine } from 'react-icons/ri';
import CountUp from 'react-countup';
import TitleTemplateIdeaGenerator from '../../components/TitleTemplateIdeaGenerator';
import { AiOutlineFileAdd } from 'react-icons/ai';
import FeedbackBanner from '../../components/FeedbackBanner';

const BucketDetails = () => {
    let { bucket_id } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const { accessLevel, premiumStatus } = PremiumCredentials()
    const [tItems, setTItems] = useState([]);
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [originalTitle, setOriginalTitle] = useState('');
    const [refineText, setRefineText] = useState('');
    const [refining, setRefining] = useState(false);
    const [refinedTitles, setRefinedTitles] = useState([]);
    const [selectedID, setSelectedID] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [percent, setPercent] = useState(0);
    const [thumbnailsData, setThumbnailsData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [selectType, setSelectType] = useState(0);
    const [showImageAnalysisModal, setShowImageAnalysisModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [deletedIndex, setDeletedIndex] = useState();
    const [currentItem, setCurrentItem] = useState();
    const [currentDeleteItem, setCurrentDeleteItem] = useState();
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState();
    const [currentItemDetails, setCurrentItemDetails] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [titleTemplateIdeaGenerator, setTitleTemplateIdeaGenerator] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [currentDisplayImage, setCurrentDisplayImage] = useState('');
    const [highRes, setHighRes] = useState(false);

    const colors = ['text-blue-500', 'text-red-500', 'text-green-500', 'text-pink-500', 'text-purple-500'];
    let colorIndex = 0;

    useEffect(() => {
        //   //console.log('Doing Verification');
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
        }
    }, []);

    // const [isFeedbackBannerVisible, setIsFeedbackBannerVisible] = useState(true);

    // useEffect(() => {
    //     const storedPreference = localStorage.getItem(`feedbackBannerDismissed_bucket-details`);
    //     if (storedPreference === 'true') {
    //         setIsFeedbackBannerVisible(false);
    //     }
    // }, []);

    // const onDrop = useCallback((acceptedFiles) => {
    //     if (acceptedFiles.length > 1) {
    //         //console.log("Only one image can be uploaded");
    //         return;
    //     }
    //     // do something with the uploaded file
    //     setSelectType(1);
    //     setSelectedImage(acceptedFiles[0]);
    // }, []);

    // const handleOnClose = () => setShowModal(false);

    const getThumbnails = async () => {
        const docRef = doc(db, 'Users', mainUserId, 'Buckets', bucket_id);
        const testDocSnap = await getDoc(docRef);
        if (testDocSnap.exists()) {
            // //console.log("Document data:", testDocSnap.data());
            setDocTitle(testDocSnap.data().name)
            setTItems(testDocSnap.data().items)

        } else {
            // doc.data() will be undefined in this case
            toast("Unable to load thumbnails", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
            ////console.log("No such document!");
        }
    }

    useEffect(() => {
        getThumbnails();
    }, []);

    const goBack = () => {
        let path = `/buckets/`;
        navigate(path);
    }

    const handleShowDetailsModal = (item) => {
        setCurrentItemDetails(item);
        setOpenDetails(true);
    }

    const closeDetailsPopup = (item) => {
        setOpenDetails(false);
    }

    const handleViewModalClose = (e) => {
        if (e.target.id === "thumbnail-view-modal") {
            setViewImage(false);
        }
    }

    const handleItemDetailsModalClose = (e) => {
        if (e.target.id === "item-details-modal") {
            setViewImage(false);
        }
    }

    const closeViewImage = (e) => {
        setViewImage(false);
        e.stopPropagation();
    }

    const showDeleteModal = (item, index) => {
        if (tItems.length > 1) {
            setSelectedTitle(item.title);
            setOpenDeleteModal(true);
            setCurrentDeleteItem(item);
            setCurrentDeleteIndex(index);
        } else {

        }
    }

    const handleDeleteItem = async () => {
        const colRef = collection(db, 'Users', mainUserId, 'BucketItems');
        const docRef = doc(db, 'Users', mainUserId, 'Buckets', bucket_id);
        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let data = docSnap.data();
                let dataId = docSnap.data().fb_item_id;
                let arrayField = data.items;

                // Remove the element at the specified index
                arrayField.splice(currentDeleteIndex, 1);

                // Update the document with the new array
                await updateDoc(docRef, {
                    items: arrayField
                });

                setOpenDeleteModal(false);
                setSelectedTitle("");
                setDeletedIndex(currentDeleteIndex);



                //console.log('Document successfully updated!');

            } else {
                //console.log('No such document!');
            }
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    useEffect(() => {
        let timerId;

        if (deletedIndex !== null) {
            timerId = setTimeout(() => {
                setTItems((currentItems) => currentItems.filter((_, index) => index !== deletedIndex));
                setDeletedIndex();
            }, 200);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [deletedIndex]);


    useEffect(() => {
        setRefineText('');
        setSelectedID();
    }, [selectedIndex]);

    const goToSdDownload = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            window.open(link, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const goToHdDownload = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            const videoId = extractSectionFromURL(link);
            const newLink = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const setSdImage = (link) => {
        const newLink = link
        setCurrentDisplayImage(newLink);
        setViewImage(true);
        setHighRes(false);
    }

    const setHdImage = (link) => {
        if (accessLevel !== 5) {
            const videoId = extractSectionFromURL(link);
            const newLink = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            setCurrentDisplayImage(newLink);
            setViewImage(true);
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const goToVideo = (link) => {
        if (accessLevel !== 5 && premiumStatus === 'PREMIUM') {
            const videoId = extractSectionFromURL(link);
            const newLink = `https://www.youtube.com/watch?v=${videoId}`
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
    }

    const extractSectionFromURL = (url) => {
        const sections = url.split('/');
        return sections[4]; // Change the index as needed, assuming dlteGDtxsqs is always at the 4th position.
    };

    const saveUserFeedback = () => {
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bucket Details</title>
                <meta name="description" content="Bucket Details | ThumbnailGeek" />
            </Helmet>

            <TitleTemplateIdeaGenerator show={titleTemplateIdeaGenerator} isVisible={titleTemplateIdeaGenerator} template={currentItemDetails?.title_template ? currentItemDetails?.title_template : ''} onClose={() => setTitleTemplateIdeaGenerator(false)} />

            <Transition.Root show={openDeleteModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenDeleteModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setOpenDeleteModal(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Delete item
                                            </Dialog.Title>
                                            <div className="w-full mt-2 flex flex-col">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete
                                                </p>

                                                <span className="text-sm font-bold text-gray-600 py-3">{selectedTitle} </span>

                                                <p className="text-sm text-gray-500">
                                                    from bucket?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                            onClick={() => handleDeleteItem(currentDeleteItem)}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setOpenDeleteModal(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openDetails} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => setOpenDetails(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white mx-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl ">
                                    <button
                                        onClick={closeDetailsPopup}
                                        type="button"
                                        className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        data-modal-toggle="defaultModal"
                                    >
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                      11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>

                                    <div className="px-4 pt-4">
                                        <h3 className="text-lg font-bold text-gray-900 mb-3">Thumbnail & Title Details</h3>
                                        <div className="sm:py-6 sm:pt-0 w-full overflow-y-scroll flex flex-row mt-3 sm:mt-0 sm:text-left">
                                            <div className="mt-2 w-2/5 pr-7">
                                                <div className="mb-2 relative group cursor-pointer">
                                                    <div className="rounded-lg inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200">
                                                        <img
                                                            src={currentItemDetails?.thumbnail}
                                                            className="inline-block mx-0 w-full rounded-lg cursor-pointer"
                                                            alt={currentItemDetails?.title}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <div className="flex pb-5">
                                                        <div className="shrink-0 mr-6 md:mr-2 xl:mr-1 h-10 w-10 rounded-full  duration-200">
                                                            <img src={currentItemDetails?.avatar} alt="image" className="w-full h-full rounded-full object-cover" />
                                                        </div>
                                                        <div className="w-[80%] ml-1">
                                                            <div className="group-hover:cursor-pointer p-0 text-[15px] font-semibold font-roboto w-full rounded-[5px] duration-200 leading-tight line-clamp-2">
                                                                <span> {currentItemDetails?.title}</span>
                                                            </div>
                                                            <div className="group-hover:cursor-pointer mt-1 p-0 text-[13px] rounded-[5px] duration-200 font-medium font-roboto ">
                                                                <span> {currentItemDetails?.channelName} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 w-3/5 border-l pl-7">
                                                <div className="w-full flex justify-start lg:items-left lg:justify-left">
                                                    <button
                                                        onClick={() => goToSdDownload(currentItemDetails?.thumbnail)}
                                                        className="w-1/2 p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl"
                                                    >
                                                        <TfiDownload />
                                                        <span className="ml-2 text-sm">SD Download</span>
                                                    </button>
                                                    <button
                                                        onClick={() => goToHdDownload(currentItemDetails?.thumbnail)}
                                                        className="w-1/2 p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl"
                                                    >
                                                        <TfiDownload />
                                                        <span className="ml-2 text-sm">HD Download</span>
                                                    </button>

                                                </div>
                                                <div className="w-full flex justify-start lg:items-left lg:justify-left mt-3">
                                                    {/* <button
                                                        onClick={() => setSdImage(currentItemDetails?.thumbnail)}
                                                        className="w-1/2 p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl"
                                                    >
                                                        <SlMagnifier />
                                                        <span className="ml-2 text-sm">View thumbnail in full (Low res)</span>
                                                    </button> */}
                                                    <button
                                                        onClick={() => setHdImage(currentItemDetails?.thumbnail)}
                                                        className="w-1/2 p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl"
                                                    >
                                                        <RiMicroscopeLine />
                                                        <span className="ml-2 text-sm">View thumbnail in full </span>
                                                    </button>
                                                    <button
                                                        onClick={() => goToVideo(currentItemDetails?.thumbnail)}
                                                        className="w-1/2 p-3 mr-2 text-gray-500 bg-gray-100 hover:text-gray-100 hover:bg-gray-500 rounded-lg inline-flex items-center text-xl"
                                                    >
                                                        <BiDoorOpen />
                                                        <span className="ml-2 text-sm">View Video</span>
                                                    </button>
                                                </div>

                                                <div className="w-full mt-3">
                                                    <dl className="w-full mt-2 flex gap-8 sm:mt-5 xl:mt-7">
                                                        <div className="flex flex-col col-span-3 gap-y-3">
                                                            <dt className="text-sm leading-6 text-gray-600">Title Length</dt>
                                                            <dd
                                                                className={`order-first text-3xl font-semibold tracking-tight ${currentItemDetails?.title.length > 70 ? 'text-orange-500' : 'text-green-600'
                                                                    } `}
                                                            >
                                                                <CountUp delay={0} duration={2.75} end={currentItemDetails?.title.length} />
                                                            </dd>
                                                        </div>
                                                        {/* <div className="flex flex-col col-span-4 gap-y-4 mt-1 border-l border-gray-900/10">
                                                            <div className="w-full flex flex-col col-span-12 gap-y-3 pl-6">
                                                                <dt className="order-first text-sm leading-6 text-gray-600">Title Template</dt>
                                                                <dd className="text-[15px] font-semibold tracking-tight text-gray-900 ">
                                                                    {currentItemDetails?.title_template ? (
                                                                        currentItemDetails?.title_template.split(/(\[[^\]]+\])/).map((part, index) => {
                                                                            if (part.startsWith('[') && part.endsWith(']')) {
                                                                                const words = part.slice(1, -1).split(' '); // Split the words inside the brackets
                                                                                return (
                                                                                    <span key={index} className="opacity-70">
                                                                                        {words.map((word, wordIndex) => {
                                                                                            const color = colors[colorIndex % colors.length];
                                                                                            colorIndex += 1;
                                                                                            return (
                                                                                                <span key={wordIndex} className={color}>
                                                                                                    {word}
                                                                                                </span>
                                                                                            ); // Add a space after each word
                                                                                        })}
                                                                                    </span>
                                                                                );
                                                                            } else {
                                                                                return <span key={index}>{part}</span>;
                                                                            }
                                                                        })
                                                                    ) : (
                                                                        'Generating...'
                                                                    )}
                                                                </dd>
                                                                <dd className="text-base tracking-tight text-gray-900 mt-3">
                                                                    <button onClick={() => setTitleTemplateIdeaGenerator(true)}
                                                                        className='p-1 px-2 mr-2 text-gray-600 bg-gray-200 hover:text-gray-100 hover:bg-gray-500 rounded-md inline-flex items-center text-xl'>
                                                                        <GiReceiveMoney />
                                                                        <span className="ml-2 text-sm">Use this template</span>
                                                    </button>
                                                    <button onClick={() => refreshTemplate(currentItemDetails?.title)}
                                                        className='p-1 px-2 mr-2 text-gray-400 bg-gray-200 hover:text-gray-100 hover:bg-gray-500 rounded-md inline-flex items-center text-xl'>
                                                        <BiRefresh />
                                                    </button>
                                                </dd>
                                            </div>
                                        </div> */}
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child >
                        </div >
                    </div >
                </Dialog >
            </Transition.Root >

            {viewImage && (
                <div
                    propaga
                    onClick={handleViewModalClose}
                    id="thumbnail-view-modal"
                    className={`transition ease-in duration-700 fixed top-0 left-0 right-0 z-[1345] w-full h-full bg-black bg-opacity-10 backdrop-blur-sm p-4 flex justify-center items-center`}
                >
                    <div className='relative max-w-6xl w-full'>
                        <div className={`relative bg-white rounded-lg shadow `}>
                            <div className="flex items-start justify-between p-3 rounded-t">
                                <h3 className="text-base font-semibold text-gray-900 dark:text-white">{/* Optional title or leave empty */}</h3>
                                <button
                                    onClick={() => closeViewImage()}
                                    type="button"
                                    className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-6 pt-0">
                                <div className="w-full rounded-lg cursor-pointer duration-200">
                                    <img
                                        src={currentItemDetails?.thumbnail}
                                        className="mx-0 rounded-lg cursor-pointer max-w-7xl w-full h-full"
                                        alt={currentItemDetails?.title}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="bg-gray-50">
                <div className="ml-auto pb-40 lg:w-[100%]">
                    <div className="px-6 min-h-screen">

                        <div className={`fixed ${darkMode === false ? 'bg-gray-50' : 'bg-[#181818]'} border-b right-0 left-0 top-0 z-10 w-full px-6 pt-3 pb-3 flex items-center justify-between`}>
                            <div className="inline-flex">
                                <button onClick={() => goBack()} className={`text-gray-800 ${darkMode === false ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-700 hover:bg-gray-800 text-gray-300'}   mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                    <MdOutlineArrowBack />
                                </button>
                                <a className=" text-2xl font-bold text-gray-800 lg:text-2xl hover:text-gray-700" href="#">
                                    <div className='relative w-2/3 mt-2 '>
                                        <img className='w-full h-full' src={logo} />
                                    </div>
                                </a>
                            </div>

                            <div className='absolute right-1/2 translate-x-1/2 inline-flex' >
                                <div className="font-bold text-base text-center">
                                    {docTitle} <span className="font-normal text-gray-600"> | Bucket</span>
                                </div>
                            </div>

                            <div className="inline-flex space-x-2 justify-self-end">
                                <Avatar />
                            </div>
                        </div>

                        <div className="pt-20">
                            <div className="2xl:max-w-3xl mx-auto text-center my-6 text-gray-500/90 font-medium">
                                Your personalized collection of visually inspiring thumbnails and concepts<br />to help you create better thumbnails.
                            </div>
                            <div className='3xl:w-[1440px] mx-auto pt-6 mt-3 px-12 rounded-lg'>
                                <div className='flex flex-wrap justify-center'>
                                    {
                                        tItems.length === 0
                                            ?
                                            <div className="flex flex-col mt-24 items-center justify-center h-full">
                                                <svg className="animate-spin h-5 w-5 text-gray-500 mb-3" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.206 0 4.232-.896 5.657-2.343l2.343 2.343zM18 6.709V4.043A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z"></path>
                                                </svg>
                                                <p className="text-gray-500">Loading some things...</p>
                                            </div>
                                            :
                                            <>
                                                {tItems && tItems.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <div className='relative cursor-pointer text-gray-800 w-80 2xl:w-80 pb-5 text-[0.9rem] mx-2 mb-5 group'>
                                                            <div className="absolute opacity-0 group-hover:opacity-100 left-2 top-2 z-10 ">
                                                                <button onClick={() => showDeleteModal(item, index)} className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">
                                                                    <BiTrash className="text-gray-600 text-xl px-1 py-1" />
                                                                </button>
                                                            </div>

                                                            <div onClick={() => handleShowDetailsModal(item)} className="">
                                                                <div className="mb-2 relative group cursor-pointer">
                                                                    <div className="@apply hover:ring-2 rounded-lg hover:ring-blue-500 inline-block overflow-hidden w-full h-[80%] cursor-pointer duration-200">
                                                                        <img className="@apply inline-block mx-0 w-full rounded-lg" src={item.thumbnail} alt="" />
                                                                    </div>
                                                                </div>

                                                                <div className='flex'>
                                                                    <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-9 w-9 object-cover">
                                                                        <img className='rounded-full md:h-9 md:w-9 xl:h-9 xl:w-9' src={item.avatar} alt="" />
                                                                    </div>
                                                                    <div className='flex-col w-9/12'>
                                                                        <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                            <p className='font-roboto items-center leading-4 tracking-[-0.015em] font-medium lg:text-[15px] mb:text-mb'>{item.title}</p>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className='inline-flex text-[15px] font-roboto font-[500] lg:text-[13px]'>
                                                                                <p className='text-gray-500'>{item.channelName}
                                                                                    {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
                <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                    <p className="text-sm leading-6 text-white">
                        <a href="#">
                            <strong className="font-semibold">Is this tool helpful?</strong>
                            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                                <circle r={1} cx={1} cy={1} />
                            </svg>
                            <button onClick={helpfulToolTip(true)} className="hover:underline">Yes</button>
                            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                                <circle r={1} cx={1} cy={1} />
                            </svg>
                            <button onClick={() => helpfulToolTip(false)} className="hover:underline">No</button>
                        </a>
                    </p>
                    <button type="button" className="-m-1.5 flex-none p-1.5">
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
                    </button>
                </div>
            </div> */}

            <FeedbackBanner currentPageUrl={'bucket-details'} />
        </>
    )
}

export default BucketDetails