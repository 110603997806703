import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDropzone } from 'react-dropzone';
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { db, storage } from '../../../firebase-config';
import useDynamicRefs from 'use-dynamic-refs';
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { logout } from '../../../redux/actions/auth.action';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import logo from '../../../assets/logo_full_32.png';

const StandaloneUpload = () => {
    // console.log('db', db);
    // console.log('storage', storage);
    const navigate = useNavigate()
    const captionRef = useRef([])
    const [getRef, setRef] = useDynamicRefs();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const mainUserId = localStorage.getItem("mainUserId")
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadBtnActive, setUploadBtnActive] = useState(false);


    const uploadClicked = () => {
        if (selectedImages.length > 0) {
            setUploading(true);
            uploadImages();
        }
    }

    // const checkForUploadButton = () => {
    //     if (selectedImages.length > 0) {
    //         setUploadBtnActive(true);
    //     } else {
    //         setUploadBtnActive(false);
    //     }
    // }

    const updateDocument = async (user_id, doc_id) => {
        await updateDoc(doc(db, "Users", user_id), {
            currentTestId: doc_id,
        })
    }

    const uploadImages = async () => {
        var downloadURL = '';
        const docRef = doc(db, "Users", mainUserId);
        const colRef = collection(docRef, "ThumbnailTests")
        const addedColDocRef = await addDoc(colRef, {
            user_id: mainUserId,
            test_type: 'StandAlone',
            timestamp: serverTimestamp(),
        })
        await Promise.all(
            selectedImages.map((image, i) => {
                const titleRef = getRef(`input${i}`);
                const title = titleRef.current.value;
                console.log(titleRef.current.value);
                const imageRef = ref(storage, `userThumbnails/${docRef.id}/${image.path}`);
                uploadBytes(imageRef, image, "data_url").then(async () => {
                    downloadURL = await getDownloadURL(imageRef)
                    await updateDoc(doc(db, "Users", mainUserId, "ThumbnailTests", addedColDocRef.id), {
                        images: arrayUnion(downloadURL),
                        titles: arrayUnion(title)
                    })
                })
            })
        ).then((res) => {
            localStorage.setItem('currentTestId', addedColDocRef.id);
            updateDocument(mainUserId, addedColDocRef.id)
            setUploading(false)
            navigate(`/youtube-tt-testing/test?${downloadURL}`);
        }).catch(console.error())
    }

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setUploadBtnActive(true);
            setSelectedImages(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = selectedImages.map((file, i) => (
        <div className="pb-5 text-[0.9rem] mr-4">
            <div className="mb-2 z-0 relative ">
                <div className="inline-flex overflow-hidden w-72 h-auto">
                    <img className="" src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="" />
                </div>
            </div>

            <div className='flex'>
                <div className="shrink-0 mx-0 my-0 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                </div>
                <div className='flex flex-col'>
                    {/* {setCount(count + 1)} */}
                    <div className="flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal line-">
                        <input ref={setRef(`input${i}`)} className='text-[13px] w-[248px] flex-1 p-1 px-0 font-bold border-b border-gray-200 h-full line bg-gray-50' rows="2" placeholder='Type a compelling title here' />
                    </div>
                </div>
            </div>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ errors }) => (
        <div className="absolute z-50 right-5 top-20 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
            <div className="flex items-center justify-center w-16 bg-red-500">
                <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
            </div>

            <div className="px-4 py-2 -mx-3 text-white">
                <div className="mx-3 text-white">
                    <ul className='text-sm'>
                        {errors.map(e => <li key={e.code}>{e.message}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    ));

    const uploadPhotosNInfo = () => {

    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Standalone Upload</title>
                <meta name="description" content="Standalone Test - Thumbnail Geek" />
            </Helmet>
            <div className='bg-gray-50' >
                <div className=" px-6 py-4 mt-2">
                    <a className="fixed text-2xl font-bold text-gray-800 lg:text-2xl hover:text-gray-700" href="#">
                        {/* thumbnailGeek. */}
                        <img className='h-6 mt-1' src={logo} />
                    </a>
                </div>
                <button onClick={() => setShowSidebar(!showSidebar)} className="fixed z-20 top-[38px] left-4 bg-white mr-2 mt-6 border rounded-full lg:hidden">
                    <HiOutlineMenuAlt2 className='w-10 h-10 p-2' />
                </button>
                <div className='ml-2'>
                    <SideBar
                        showSidebarFlag={showSidebar} selectedSideBarItem="dashboard" darkMode={false} />
                </div>


                <div className="ml-auto mb-6 lg:w-[80%] xl:w-[80%] 2xl:w-[85%] 3xl:w-[95%]">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50">

                        <div className="w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4">
                            <div className="">
                                <h5 hidden className="text-lg text-gray-600 font-medium lg:block">Start testing with thumbnails</h5>
                            </div>
                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">

                                {/* <!-- Profile dropdown --> */}
                                <div className="ml-3 relative">
                                    <div>
                                        <button onClick={() => setProfileDropdown(!profileDropdown)} type="button" className="bg-gray-800 flex text-sm rounded-full outline-none ring-2 ring-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                            <img className="h-8 w-8 rounded-full" src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                                        </button>
                                    </div>
                                    <div hidden className={`${profileDropdown ? 'block' : ''} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                        {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">Your Profile</a>
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-1">Settings</a>
                                        <a href="#" className="hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto mt-6">
                            {/* Progress Bar Section */}
                            <div className="mb-4 inline-flex justify-between items-center w-full">
                                <div className="text-lg font-bold text-gray-700 leading-tight">Upload Thumbnails</div>
                                <input type="text" className="min-w-80 p-2 outline-none block w-80 pr-9 z-20 text-sm text-gray-900 border-b bg-transparent" placeholder="Untitled Test Title" />

                            </div>
                            <div className="w-full h-full mx-auto">
                                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-44 mb-8 bg-white rounded-lg border-2 border-gray-300 border-dashed cursor-pointer  hover:bg-gray-100 " {...getRootProps()}>
                                    <div className="flex flex-col justify-center items-center pt-5 pb-5 px-5">
                                        <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p className="mb-2 text-md text-gray-500 dark:text-gray-400 text-center">Drag your thumbnail here or click to <span className="font-semibold">browse</span></p>
                                        <p className="text-xs text-gray-500 dark:text-gray-300">JPG, GIF, or PNG (1280x720)</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">• 2MB Limit •</p>
                                    </div>
                                    <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                                </label>
                                <div className='flex flex-col justify-center items-center w-full mt-8'>
                                    <h2 className="mb-5 text-sm text-center text-gray-500"><span className='text-gray-500 font-semibold'>Note:</span> Only 1 thumbnail allowed for this test.</h2>
                                </div>


                                <div className='flex flex-wrap w-full mt-6 justify-center'>
                                    {thumbs}
                                    {fileRejectionItems}
                                </div>


                                {
                                    uploading === false
                                        ?
                                        (
                                            uploadBtnActive === false
                                                ?
                                                <div className='flex justify-center items-center w-full mb-16 mt-8'>
                                                    <button disabled className='bg-gray-200 w-64 text-gray-400 p-3 rounded-lg'>
                                                        Upload & Start Testing
                                                    </button>
                                                </div>

                                                :
                                                <div className='flex justify-center items-center w-full mb-16 mt-8'>
                                                    <button onClick={uploadClicked} className='bg-slate-700 w-64 bg-gradient-to-r from-blue-600 to-[#FC2959] opacity-100 hover:opacity-90 transition-colors hover:duration-1200 delay-700 text-white p-3 rounded-lg'>Upload & Start Testing</button>
                                                </div>

                                            //     <div className='flex justify-center items-center w-full mb-16 mt-8'>
                                            //     <button onClick={uploadClicked} className='bg-slate-700 w-64 hover:bg-gradient-to-r hover:from-blue-600 hover:to-[#FC2959] transition-colors hover:duration-1200 delay-700 text-white p-3 rounded-lg'>Upload & Start Testing</button>
                                            // </div>
                                        )
                                        :
                                        <div className='flex justify-center items-center w-full mb-16 mt-8'>
                                            <button disabled className='cursor-wait bg-gray-200 text-gray-400 w-64 p-3 rounded-lg'>
                                                Uploading...
                                            </button>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default StandaloneUpload















{/*

        // <div className='inline-flex mr-4' key={file.name}>
        //     <div className='flex flex-col overflow-hidden'>
        //         <div className='w-[304px] h-[170px]'>
        //             <img className='w-auto h-full'
        //                 src={file.preview}
        //                 // Revoke data uri after image is loaded
        //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
        //             />
        //         </div>
        //         <input className='text-sm p-1 px-2 font-light border mt-3 w-[304px] h-full' placeholder='Add a compelling title' />
        //     </div>
        // </div>





<div className=" justify-center">
    <h2 className='font-bold text-2xl mb-8 text-indigo-600'>Make your next upload irresistible! Start split testing </h2>
    </div>
    <div className="flex flex-col mt-0 pt-0">
    <div className="grid md:grid-cols-12 gap-0">
    <div className="col-span-4 pr-4">
        <h2 className='font-bold text-2xl text-slate-700'>Add Titles</h2>
        <p className='font-light text-sm'>Compose Titles That Will Grab Your Viewer's Attention</p>
        <div className="flex flex-col justify-center items-center ">
            <input type="text" onChange='' placeholder='A Catchy Title' className='mt-4 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <input type="text" onChange='' placeholder='Another Catchy Title' className='mt-3 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <input type="text" onChange='' placeholder='The Most Catchy Title' className='mt-3 border border-gray-300 rounded px-2 py-2 w-full font-light' />
            <button className='h-10 w-10 bg-indigo-800 items-center justify-center mt-4 rounded-full text-white'>+</button>
        </div>
    </div>
    <div className="h-full w-full items-center col-span-8 md:border-l pl-4 border-gray-200">
        <h2 className='font-bold text-2xl text-slate-700'>Add Thumbnails</h2>
        <p className='font-light text-sm'>Upload at least 3 Thumbnails clhtmlFor Split Testing Attractiveness and Clickability</p>
        <div className=" flex flex-col mt-4 py-10 items-center bg-slate-200 rounded-lg border border-slate-300 w-full">
            <p className="font-normal text-gray-800">Drag and drop thumbnail files to upload</p>
            <p className="font-light text-sm  text-gray-500 mb-0 pb-0">You can begin split testing right after this.</p>
            <button className='file: h-10 w-auto px-5 bg-indigo-800 mt-4 rounded-md text-white'>Select Thumbnails</button>
        </div>
    </div>
    </div>
    </div> */}