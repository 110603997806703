import React, { useEffect, useState } from 'react'
import { useLocation, NavLink, Link, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import axios from 'axios';
import { HiOutlineMenuAlt2, HiOutlineBell, HiArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from 'react-player/youtube';
import Cookies from 'js-cookie'
import { UserAuth } from '../../../context/AuthContext'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db, storage } from '../../../firebase-config';
import { getFunctions, httpsCallable } from "firebase/functions";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import Avatar from '../../components/Avatar';
import Hint from '../../components/Hint';
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  GiftIcon,
  ViewfinderCircleIcon,
  LinkIcon,
} from '@heroicons/react/24/outline'
import Tooltip from '../../components/Tooltip';
import ThumbnailUploadDialog from '../../components/ThumbnailUploadDialog';
import * as Icons from 'react-icons/pi';
import TutorialItems from '../../components/TutorialItems';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';  //Use ISO week plugin
import DotDotDot from '../../components/DotDotDot';
import PremiumBlocker from '../../components/PremiumBlocker';
import PaymentSuccess from '../../components/PaymentSuccess';
import WelcomePopup from '../../components/WelcomePopup';
import VideoPopupModal from '../../components/VideoPopupModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Card = ({ title, description, bgColor, link, linkText, IconComponent }) => (
  <Link to={link}>
    <div className={`relative h-52 rounded-xl p-6 bg-gradient-to-r group ${bgColor}`}>
      <div className="absolute top-3 right-3 w-16 opacity-50">
        <IconComponent
          className='w-full h-full text-white fill-current'
        />
      </div>
      <h2 className="text-xl font-medium mb-3 text-white">{title}</h2>
      <p className="mb-[57px] font-normal text-white text-[15px] w-2/3">{description}</p>
      <Link to={link} className="absolute inline-flex right-3 group-hover:right-1 bottom-3 mt-auto text-white font-medium py-2 px-4">
        {linkText}
        <HiArrowNarrowRight className='mt-1.5 ml-2' />
      </Link>
    </div>
  </Link>
);

dayjs.extend(isoWeek);

const Dashboard = () => {
  let navigate = useNavigate();
  const { user, logOut } = UserAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loginParam = params.get('login');
  const firstEntry = params.get('first-entry');
  const paidUser = params.get('p');
  const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
  const channelDisplayName = localStorage.getItem("channelDisplayName");
  const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, aiQuota, aiCreditsLeft, firstOpenPopup, latestTriggerFulfilled } = PremiumCredentials();
  const mainUserId = localStorage.getItem("mainUserId");
  // const [darkMode, setDarkMode] = useState(false);
  const [boardCount, setBoardCount] = useState(0);
  const [splitTestCount, setSplitTestCount] = useState(0);
  const [titleGenCount, setTitleGenCount] = useState(0);
  const [thumbGenCount, setThumbGenCount] = useState(0);
  const [thumbAnalysisCount, setThumbAnalysisCount] = useState(0);
  const [testCount, setTestCount] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  const [extensionModal, setExtensionModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  // const [firstUsePopup, setFirstUsePopup] = useState(false);
  // const [currentWeekStreak, setCurrentWeekStreak] = useState(1); 
  // const [longestWeekStreak, setLongestWeekStreak] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState(false);
  // const [notPremium, setNotPremium] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   if (paidUser === 'f') {
  //     setNotPremium(true);
  //   }
  // }, []);

  useEffect(() => {
    if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
      const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
      const requestBody = {
        loginStatus: true,
      };
      axios.post(apiUrl, requestBody)
        .then(response => {
          // //console.log('Token ' + response.data.token);
          //Remove previous cookie if it exists

          document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;
          document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
          document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

          const dateSet = new Date();
          localStorage.setItem('login_cookie_setDate', dateSet);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, mainUserId,]);

  useEffect(() => {
    if (mainUserId !== null && mainUserId !== undefined) {
      if (checked === true) {
        if (channelLinked === false && premiumStatus === 'NOT_INITIATED') {
          navigate(`/first-steps/connect/${mainUserId}`);
          return;
        }

        // if (channelLinked === true && premiumStatus === 'NOT_INITIATED') {
        //   // navigate(`/first-steps/billing/plans/${mainUserId} `);
        //   setNotPremium(true);
        //   return;
        // }

        // if (premiumStatus === 'PAST_DUE' || premiumStatus === 'INCOMPLETE' || premiumStatus === 'UNPAID') {
        //   const functions = getFunctions();
        //   const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        //   addMessage({
        //     returnUrl: window.location.origin + '/account-settings',
        //     locale: 'auto',
        //   })
        //     .then((result) => {
        //       const data = result.data;
        //       if (data.url) {
        //         window.open(data.url);
        //       }
        //     })
        //     .catch((error) => {
        //       //Getting the Error details.
        //       const code = error.code;
        //       const message = error.message;
        //       const details = error.details;
        //       //...
        //       console.error(code);
        //       console.error(message);
        //       console.error(details);
        //       window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ');
        //     });
        //   return;
        // }

        // if (premiumStatus === 'INCOMPLETE_EXPIRED' || premiumStatus === 'CANCELED') {
        //   navigate(`/restricted`);
        //   return;
        // }

        // if (channelLinked === true && premiumStatus === 'CANCELED' && premiumStatus !== 'PREMIUM' && premiumStatus !== 'TRIAL') {
        //   navigate(`/restricted`);
        //   return;
        // }
      }
    } else {
      logoutUser();
    }
  }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, mainUserId]);


  useEffect(() => {
    if (location.hash === '#purchase-success') {
      setPaymentSuccessModalOpen(true);
    }
  }, [location]);

  const logoutUser = async () => {
    await logOut();
  }

  // useEffect(() => {
  //   // //console.log(firstOpenPopup, 'firstOpenPopup')
  //   if (firstOpenPopup === true) {
  //     setFirstUsePopup(true);
  //   }
  // }, [firstOpenPopup]);

  const getCounts = async () => {
    const docRef = doc(db, 'UserData', mainUserId);
    const testDocSnap = await getDoc(docRef);
    if (testDocSnap.exists()) {
      ////console.log("Document data:", testDocSnap.data());
      setTestCount(testDocSnap.data().yt_test_count)
      setSplitTestCount(testDocSnap.data().thumb_compare_count)
      setTitleGenCount(testDocSnap.data().title_gen_count)
      setThumbGenCount(testDocSnap.data().thumb_idea_gen_count)
      setThumbAnalysisCount(testDocSnap.data().thumb_analysis_count)

    } else {
      //doc.data() will be undefined in this case
      setTestCount(0);
      setSplitTestCount(0)
      setTitleGenCount(0)
      setThumbGenCount(0)
      setThumbAnalysisCount(0)
    }
  }

  useEffect(() => {
    document.title = 'Dashboard'
    getCounts();
    // getFocusBoardCount();
    // fetchStreak();
    //fetchWeekStreak();
    //getSplitComparisonCount();
  }, []);

  //Check for latest trigger
  useEffect(() => {
    if (checked) {
      // //console.log(aiCreditsLeft)
      // //console.log(aiQuota)
      const checkLatestTrigger = async () => {
        try {
          const docRef = doc(db, 'Users', mainUserId);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const today = new Date().toDateString();
            const latestTriggerExists = data.hasOwnProperty('latest_trigger');
            const latestTriggerDateExists = data.hasOwnProperty('latest_trigger_date');
            const latestTriggerDate = latestTriggerDateExists ? data.latest_trigger_date.toDate().toDateString() : null;
            const latestTriggerError = data.latest_trigger_error ?? false;

            if ((latestTriggerExists && latestTriggerDateExists) || latestTriggerError) {
              if (latestTriggerDate !== null && Date.parse(today) - Date.parse(latestTriggerDate) >= 3 * 24 * 60 * 60 * 1000) {
                triggerThumbnails();
              }
            }
          }
        } catch (error) {
          // console.error('Error checking latest_trigger: ', error);
        }
      };

      checkLatestTrigger();
    }
  }, [mainUserId, checked, aiCreditsLeft, aiQuota, mainUserId]);

  const triggerThumbnails = async () => {
    const triggersRef = collection(db, "Users", mainUserId, "Triggers");
    const tDocRef = await addDoc(triggersRef, {
      trigger: "get_thumbnails_free",
      trigger_count: 51,
      trigger_date: serverTimestamp(),
    });

    const docRef = doc(db, "Users", mainUserId);
    const ref = await updateDoc(docRef, {
      latest_trigger: "get_thumbnails_free",
      latest_trigger_fulfilled: false,
      latest_trigger_date: serverTimestamp(),
    });
  }

  const handleExtensionModalClose = (e) => {
    if (e.target.id === "extension-modal") {
      setExtensionModal(false);
    };
  }

  const extensionModalClose = (e) => {
    setExtensionModal(false);
  }

  const searchModalClose = (e) => {
    setSearchModal(false);
  }

  const goToYoutubeHomepage = () => {
    //Attempt to set a test cookie
    document.cookie = 'test_cookie=1';
    //Check if the test cookie was set
    if (document.cookie.indexOf('test_cookie') !== -1) {

      const url = "https://www.youtube.com";
      let checkChromeExtension = Cookies.get('TG_CEF');

      if (checkChromeExtension === 'TRUE') {
        //Set Page Intent
        const intent = "OPENTESTER";
        const userDoc = doc(db, "Users", mainUserId)
        const data = {
          page_intent: intent
        }
        setDoc(userDoc, data, { merge: true }).then(res => {
          document.cookie = "tg_tt_current_page=" + intent + "; path=/;";
          window.open(url, '_blank');
          extensionModalClose();
        }).catch((e) => {
          toast("Unable to verify connection.", {
            icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
          });
        });

      } else {
        setExtensionModal(true);
      }

    } else {
      //Cookies are disabled
      //alert('Cookies are disabled in your browser. Please enable cookies to use this feature.');
      toast("Cookies are disabled in your browser. Please enable cookies to use this feature.", {
        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
      });
    }
  }

  const handleShowHelpModal = () => {
    setHelpModal(true);
  }

  const handleHelpModalClose = (e) => {
    if (e.target.id === "help-modal") {
      setHelpModal(false);
    }
  }

  const handleCopyClick = () => {
    const textToCopy = `Currently using thumbnailgeek and I'm definitely seeing a difference in Youtube channel growth. Its https://www.thumbnailgeek.com/ Thank me later.`;

    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const closePaymentSuccessPopup = () => {
    setPaymentSuccessModalOpen(false);
    navigate(location.pathname, { replace: true }); // Remove the hash from the URL
  };


  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | ThumbnailGeek</title>
        <meta name="description" content="Dashboard | ThumbnailGeek" />
      </Helmet>

      <ThumbnailUploadDialog show={uploadPopup} isVisible={uploadPopup} onClose={() => setUploadPopup(false)} pageUrl={'/testing-suite/upload'} />

      {/* <TutorialItems isOpen={modalOpen} onClose={() => setModalOpen(false)} initialOpenItemId={4} /> */}

      <PaymentSuccess isOpen={paymentSuccessModalOpen} onClose={closePaymentSuccessPopup} />

      <div id="extension-modal" onClick={handleExtensionModalClose} className={`${extensionModal === true ? '' : 'hidden'} transition ease-in duration-700 fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
        <div className="relative w-full h-full max-w-lg md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                Cannot Connect to Browser Extension
              </h3>
              <button onClick={extensionModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <p className="text-[15px] font-light tracking-[-0.015em] leading-relaxed text-gray-800">
                ThumbnailGeek browser extension is needed. Maybe you have not installed it yet, or disabled the extension in your browser.
              </p>
              <Hint hintText={'ThumbnailGeek Chrome Extension helps you perform advanced functions on Youtube with your ThumbnailGeek account.'} fontSizePx={'13px'} />
              {/* <div className="inline-flex text-[15px] font-light leading-relaxed text-gray-700 dark:text-gray-600">
                                <MdOutlineTipsAndUpdates className='text-[32px] text-blue-700 pt-1 mr-2 w-7' /> <span>ThumbnailGeek Chrome Extension is used to insert your thumbnail on Youtube homepage for comparison.</span>
                            </div> */}
            </div>
            <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Install Extension</button>
            </div>
          </div>
        </div>
      </div>

      <div id="help-modal" onClick={handleHelpModalClose} className={`${helpModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
        <div id='inner-container' className="bg-white p-5 justify-center items-center rounded-lg max-w-6xl border border-gray-400 shadow-md">
          <div className="mx-auto mt-2">
            <h3 className="text-base text-center font-semibold leading-6 text-gray-900">Here's how it works</h3>
          </div>
          <div className="mt-2">
            <div className="w-full rounded-md mb-2 mt-3 overflow-y-hidden duration-200">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                className="rounded-lg overflow-hidden"
                width="853px"
                height="505px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-50' >
        <SideBar selectedSideBarItem="dashboard" darkMode={false} />

        <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
          <div className="px-6 -my-6 min-h-screen bg-gray-50 ">

            <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 inline-flex items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7">
              <div className="">
                <h5 className={`text-xl text-gray-600 font-semibold lg:block`}>Hello {channelDisplayName} 👋</h5>
              </div>
              <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                <Avatar />
              </div>
            </div>

            <div className="mt-12 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mr-5">
              <div className="w-full h-full mx-auto">

                <div className="mx-auto max-w-7xl py-4">
                  <div className="overflow-hidden relative rounded-lg bg-gradient-to-r from-gray-900 to-gray-800 shadow-md lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="px-6 pb-8 pt-10 sm:px-8 sm:pt-8 lg:py-8 lg:pr-0 xl:px-8 xl:py-6">
                      <div className="lg:self-center">
                        <h2 className="text-xl font-bold tracking-tight text-white sm:text-4xl">
                          <span className="block">Ready to start leveling up?</span>
                          {/* <span className="block">Ready to dive in?</span> */}
                        </h2>
                        {/* <p className="mt-4 text-base leading-6 text-white">
                          Discover which of your thumbnails that will perform the best. Generate catchy titles & ideas. Accelerate your youtube growth!
                        </p> */}
                        {/* <p className="mt-4 text-base leading-6 text-white">
                          First, let's see how your thumbnail will look on Youtube homepage. Hit the upload button!
                        </p> */}
                        {/* <p className="mt-4 text-base leading-6 text-white">
                          First, let's find out which of your thumbnails is hurting your views. Hit the button!
                        </p> */}
                        <p className="mt-4 text-base leading-6 text-white">
                          First, let's see that type of thumbnail will best fit your video idea. Hit the button!
                        </p>
                        {/* <Link to={'/thumbnail-analysis/upload'}
                          className="mt-8 mr-2 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-blue-600 shadow hover:bg-gray-100"
                        >
                          Analyze your thumbnail
                        </Link> */}

                        <div className="relative mt-8 mr-2 inline-flex items-center ">
                          <Link to={'/thumbnail-visual-ideas'}
                            className="rounded-md border border-transparent  bg-white px-5 py-3 text-base font-medium text-blue-600 shadow hover:bg-gray-100"
                          >
                            Get Viral Thumbnail Ideas
                          </Link>
                        </div>

                        <button onClick={() => setIsModalOpen(true)}
                          className="mt-8 inline-flex items-center rounded-md border border-transparent ring-1 ring-blue-600  px-5 py-3 text-base font-medium text-white shadow hover:bg-blue-600/80"
                        >
                          See tutorial
                        </button>

                        <VideoPopupModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

                      </div>
                    </div>

                    {/* <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
                      <img className='w-[300px] self-end'
                        src={require('../../../assets/svgs/mad_scientist_thinking.svg').default}
                        alt='scientist' />
                    </div> */}

                    <div className="hidden xl:flex absolute right-24 w-96  ml-12 h-full">

                      <img className='absolute top-7 w-[90px] self-end -rotate-12 opacity-50'
                        src={require('../../../assets/svgs/th_mockup.svg').default}
                        alt='scientist' />

                      <img className='absolute left-40 bottom-1 w-[190px] opacity-50'
                        src={require('../../../assets/svgs/scientist_line.svg').default}
                        alt='scientist' />

                      <img className='absolute left-24 -top-16 w-[150px] scale-x-[-1] opacity-60'
                        src={require('../../../assets/svgs/thought_bubble.svg').default}
                        alt='thought' />

                      <img className='absolute left-16 top-1/2 translate-y-1/3 w-[90px] self-end rotate-12 opacity-70'
                        src={require('../../../assets/svgs/th_mockup.svg').default}
                        alt='scientist' />
                    </div>

                  </div>
                </div>

                <div className="grid grid-cols-12 gap-4 col-span-12 sm:col-span-12 xl:col-span-12 h-full mt-7">

                  <div className="col-span-12 md:col-span-8 ">
                    <h5 className={`text-sm text-gray-600 font-semibold lg:block mb-2`}>Smart Tools</h5>

                    <div className="mb-4">
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                        <Card
                          title="Viral Thumbnail Ideas"
                          description="Generate unique thumbnail design concepts and ideas for your video topic"
                          bgColor="bg-purple-500/90"
                          link="/thumbnail-visual-ideas"
                          linkText="Use AI"
                          IconComponent={Icons.PiSparkleThin}
                        />
                        <Card
                          title="Viral Thumbnail Library"
                          description="Get inspiration from the vast library of real viral thumbnails for your video idea"
                          bgColor="bg-red-400"
                          link="/viral-library"
                          linkText="Use AI"
                          IconComponent={Icons.PiStrategyThin}
                        />

                      </div>
                    </div>

                    <div className="">
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                        <Card
                          title="Title Refiner"
                          description="Generate or refine your title ideas into compelling viral ones"
                          bgColor="bg-orange-400"
                          link="/title-builder"
                          linkText="Use AI"
                          IconComponent={Icons.PiLightningAThin}
                        />
                        {/* <Card
                          title="Thumbnail Comparer"
                          description="Perform side-by-side comparisons of thumbnail variations to find the best one"
                          bgColor="bg-blue-400"
                          link="/testing-suite/upload"
                          linkText="Use tool"
                          IconComponent={Icons.PiSwapThin}
                        /> */}

                        <Card
                          title="Thumbnail Planner"
                          description="Get a description of what your thumbnail should look like for your video idea"
                          bgColor="bg-green-500"
                          link="/thumbnail-ideas"
                          linkText="Use AI"
                          IconComponent={Icons.PiFolderStarThin}
                        />

                        {/* <Card
                          title="Viral Library"
                          description="Discover winning thumbnail concepts from real viral videos"
                          bgColor="bg-blue-400"
                          link="/viral-library"
                          linkText="Use Tool"
                          IconComponent={Icons.PiAirTrafficControlThin}
                        /> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-span-12 md:col-span-4">
                    <h5 className={`text-sm text-gray-600 font-semibold lg:block mb-2`}>Activity stats</h5>
                    <div className="bg-white rounded-lg p-4 py-6 ring-1 flex flex-col items-center justify-center lg:h-52">

                      <div className="grid grid-cols-3 lg:grid-cols-3 grid-rows-2 gap-y-4 gap-x-8 justify-between">

                        <div className=" col-span-1">
                          <Tooltip content='Thumbnails tested on Youtube Home page' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/test_tube.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{testCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                        <div className=" col-span-1">
                          <Tooltip content='Thumbnail comparisons done' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/compare.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{splitTestCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                        <div className=" col-span-1 space-x-2">
                          <Tooltip content='Thumbnail Buckets created' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/target.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{boardCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                        <div className=" col-span-1 space-x-2">
                          <Tooltip content='Thumbnails analysed with AI' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/performance.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{thumbAnalysisCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                        <div className=" col-span-1 space-x-2">
                          <Tooltip content='Titles generated with AI' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/select_all.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{titleGenCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                        <div className=" col-span-1 space-x-2">
                          <Tooltip content='Thumbnail ideas generated with AI' position={'bottom'}>
                            <div className="inline-flex items-center justify-center space-x-2 cursor-default">
                              <img className='w-5 fill-slate-400'
                                src={require('../../../assets/svgs/test_account.svg').default}
                                alt='analysis' />
                              <div className="text-gray-700 text-xl font-bold leading-8">{thumbGenCount}</div>
                            </div>
                          </Tooltip>
                        </div>

                      </div>

                      <div className="border-t border-gray-900/20 mt-5">
                        <p className='text-[11px] text-center mt-3 text-gray-500 font-semibold'>Actions you've taken to improve your thumbnails.</p>
                      </div>

                    </div>

                    <h5 className={`text-sm text-gray-600 font-semibold lg:block mb-2 mt-4`}>Share with a friend</h5>
                    <div className="bg-white rounded-lg p-4 py-6 ring-1 flex flex-col items-center justify-center h-44">
                      <div className="mx-auto max-w-7xl px-6 lg:px-8">

                        <div className="border-b border-gray-900/20">
                          <p className='text-[11px] text-center mb-3 text-gray-500 font-medium'>Share with a friend to help them also grow on Youtube.</p>
                        </div>
                        <button
                          onClick={handleCopyClick}
                          type="button"
                          className="inline-flex w-full items-center justify-center gap-x-1.5 mx-auto rounded-md border 
                          border-blue-500 px-3 py-2 text-xs font-semibold mt-6
                          text-blue-500 shadow-sm"
                        >
                          <LinkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                          {isCopied ? 'Copied!' : 'Copy'}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div >
            </div >
          </div >
        </div >
      </div >

      {/* <WelcomePopup isVisible={firstUsePopup} onClose={() => setFirstUsePopup(false)} /> */}
    </>
  )
}

export default Dashboard


{/* <div className="bg-white rounded-lg border border-gray-200">
  <div className="flex items-start justify-between p-2 border-b rounded-t dark:border-gray-600">
    <h3 className="text-sm mt-1 ml-1 font-semibold text-gray-600 dark:text-white">
      Quick Actions
    </h3>
  </div>
  <div className="p-3 space-y-3">
    <button onClick={goToYoutubeTest} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdBiotech className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Test your thumbnail on Youtube before you upload.
      </span>
    </button>
    <button onClick={goToComparisonTest} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdRepeat className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Compare multiple thumbnails with each other to find the best one.
      </span>
    </button>
    <button onClick={goToFocusBoards} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdCallMerge className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Compare your thumbnail with others in your niche.
      </span>
    </button>
    <button onClick={goToTitleGenerator} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdRtt className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Generate a compelling title for your video using AI.
      </span>
    </button>
    <button onClick={goToReferrals} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdHowToReg className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Refer a friend for 1 free month.
      </span>
    </button>
    <button onClick={goToViralLibrary} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdInsights className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Get inspiration from the Viral Thumbnail library (5000+ thumbnails).
      </span>
    </button>
    <button onClick={goToFocusBoards} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdSportsScore className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Compare your thumbnail against other viral thumbnails.
      </span>
    </button>
    <button onClick={goToTutorials} className="inline-flex group cursor-pointer text-[13px] font-light leading-relaxed text-gray-800 rounded-lg border border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-full py-2 px-1">
      <MdOutlineHorizontalSplit className='text-[22px] text-gray-700 pt-[3px] w-7 mr-1' />
      <span className='pt-[3px]'>
        Access tutorials, tricks and how-tos.
      </span>
    </button>
  </div>
</div> */}


{/* <div className=" bg-white rounded-lg p-4 ring-1">

                      <button onClick={goToAnalysisSuite}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/performance.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Analyze thumbnails <br />with AI</span>
                        </div>
                      </button>

                      <button onClick={goToYoutubeHomepage}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/test_tube.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Test thumbnail on Youtube homepage</span>
                        </div>
                      </button>

                      <button onClick={goToScenePlanner}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/test_account.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Get viral idea for thumbnail design</span>
                        </div>
                      </button>

                      <button onClick={goToFocusBoards}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/compare.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Compare thumbnails <br />side-by-side</span>
                        </div>
                      </button>

                      <button onClick={goToViralLibrary}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/photo_gallery.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Get ideas from Viral <br />Thumbnail Library</span>
                        </div>
                      </button>

                      <button onClick={goToTitleGenerator}
                        className="inline-flex relative items-center justify-left group cursor-pointer text-[13px] 
                          font-light leading-relaxed text-gray-800 rounded-lg border border-gray-200 
                          hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 w-52 h-16 py-2 px-2 mr-2 mb-2 ">
                        <div className="inline-flex items-center justify-left">
                          <img className='w-[40px] self-end fill-slate-400 mr-2'
                            src={require('../../../assets/svgs/select_all.svg').default}
                            alt='scientist' />
                          <span className="text-gray-800 text-left">Refine your title<br /> using AI</span>
                        </div>
                      </button>

                    </div> */}