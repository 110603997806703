import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { getFunctions, httpsCallable } from "firebase/functions";
import fireEmbers1 from '../../assets/yt-fire-embers-1.jpg';
import { IoSparklesOutline, IoSparklesSharp } from 'react-icons/io5';
import DotDotDot from './DotDotDot';
import axios from 'axios';
import { db, storage } from '../../firebase-config';
import { PremiumCredentials } from '../../context/PremiumContext';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject, getStorage } from "@firebase/storage";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy, limit, onSnapshot, where } from "firebase/firestore";
import { BiRadar, BiTestTube } from 'react-icons/bi';
import { GiftIcon } from '@heroicons/react/24/outline';
import { GiTeePipe } from 'react-icons/gi';
import YouTubeThumbnail from './YoutubeThumbnail';
import { BsExclamation } from 'react-icons/bs';
import Tooltip from './Tooltip';
import BlockPopupNotEnough from './BlockPopupNotEnough';
import { HiRefresh } from 'react-icons/hi';

const LoadChannelThumbnails = ({ isVisible, onClose, setThumbnailUrl, setThumbnailTitle }) => {
    const navigate = useNavigate();
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { checked, checkDataStatus, premiumStatus, premiumTier, accountStatus, aiQuota, aiCreditsLeft, accessLevel, channelLinked, latestTriggerFulfilled } = PremiumCredentials()
    const [redirecting, setRedirecting] = useState(false);
    const [tnTData, setTnTData] = useState([]);
    const [errorState, setErrorState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [blockPopup, setBlockPopup] = useState(false);
    const [loadingState, setLoadingState] = useState('Loading...');
    const [message, setMessage] = useState('');
    const [speed, setSpeed] = useState(0.4);
    const [thumbnailUrls, setThumbnailUrls] = useState({});

    useEffect(() => {
        if (isVisible && latestTriggerFulfilled) {
            setSpeed(5)
            loadChannelThumbnails();
        } else {
            setLoading(true);
        }
    }, [isVisible, latestTriggerFulfilled]);

    useEffect(() => {
        if (loading === true) {
            const interval = setInterval(() => {
                setLoadingProgress(prevProgress => {
                    if (prevProgress < 97) {
                        const newProgress = prevProgress + speed;
                        if (newProgress >= 10 && newProgress < 10.1) {
                            setLoadingState('Checking your data...');
                        } else if (newProgress >= 20 && newProgress < 20.1) {
                            setLoadingState('Fetching your thumbnails...');
                        } else if (newProgress >= 40 && newProgress < 40.1) {
                            setLoadingState('Crunching the numbers...');
                        } else if (newProgress >= 70 && newProgress < 70.1) {
                            setLoadingState('Arranging into neat little boxes...');
                        }
                        return newProgress;
                    } else {
                        clearInterval(interval);
                        return prevProgress;
                    }
                });
            }, 70);

            return () => clearInterval(interval);
        }
    }, [loading]);

    const triggerThumbnails = async (e) => {
        e.preventDefault();

        if (aiCreditsLeft > 50) {
            setLoading(true);
            setLoadingState('Fetching your newest thumbnails...');

            const triggersRef = collection(db, "Users", mainUserId, "Triggers");
            const tDocRef = await addDoc(triggersRef, {
                trigger: "get_thumbnails",
                trigger_count: 51,
                trigger_date: serverTimestamp(),
            });

            const docRef = doc(db, "Users", mainUserId);
            const ref = await updateDoc(docRef, {
                latest_trigger: "get_thumbnails",
                latest_trigger_fulfilled: false,
                latest_trigger_date: serverTimestamp(),
            });
        } else {
            if (checked === true) {
                setLoading(false);
                setMessage('Insufficient credits! Please Recharge your credits.');
                setBlockPopup(true);
            }
        }
    }

    const handleThumbnailLoad = (videoId, url) => {
        setThumbnailUrls(prevState => ({
            ...prevState,
            [videoId]: url,
        }));
    };

    const getCurrentTnT = (videoId, title) => {
        const url = thumbnailUrls[videoId];
        if (url) {
            setThumbnailUrl(url);
            setThumbnailTitle(title);
            onClose();
        } else {
            setThumbnailUrl(null);
            setThumbnailTitle(null);
        }
    };

    const modalClose = (e) => {
        if (e.target.id === "this-modal" || e.target.tagName === 'BUTTON') {
            onClose();
        }
    };

    const loadChannelThumbnails = async () => {
        const colRef = collection(db, 'Users', mainUserId, 'ChannelThumbnailsData');
        const q = query(
            colRef,
            orderBy("position", "asc"),
            limit(30)
        );

        await getDocs(q)
            .then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setTnTData(newData);
                setLoading(false);
                setLoaded(true);
                setLoadingProgress(0);
                setSpeed(0.4)
            })
            .catch((error) => {
                console.error("Error fetching documents: ", error);
            });
    };

    if (!isVisible) return null;

    return (
        <>
            <BlockPopupNotEnough show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} />

            <div id="this-modal" className="transition ease-in duration-700 fixed top-0 left-0 right-0 z-[86875] w-full bg-gray-700 bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center" onClick={modalClose}>
                <div className="relative w-full max-w-4xl bg-white rounded-lg shadow">

                    {/* <Tooltip content='Get newest thumbnails from your channel' position='bottom'>
                        <button onClick={(e) => triggerThumbnails(e)}
                            type="button" className="absolute top-2 right-12 inline-flex rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <HiRefresh className='w-5 h-5 mr-2' />
                            Fetch newest thumbnails
                        </button>
                    </Tooltip> */}

                    <button onClick={onClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </button>

                    <div className="w-full pt-4 px-4 pb-2 duration-200">
                        <div className="text-base font-medium text-left text-gray-700">Select a thumbnail from your channel</div>
                    </div>
                    <div className="w-full p-4 max-h-[450px] overflow-y-auto duration-200">
                        {loading ? (
                            <div className="w-full h-40 flex flex-col justify-center items-center">
                                <div className="flex h-2 w-32 mb-4 mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                    <div
                                        style={{ width: `${loadingProgress}%` }}
                                        className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                    />
                                </div>
                                {loadingState}
                            </div>
                        ) : (
                            <>
                                {
                                    loaded === true && tnTData?.length === 0
                                        ?
                                        <div className="w-full h-40 flex flex-col justify-center items-center">
                                            <BsExclamation className="w-10 h-10 text-gray-500" />
                                            <div className="text-gray-700">No thumbnails found</div>
                                        </div>
                                        :
                                        < div className='w-full flex flex-wrap items-start justify-center'>
                                            {tnTData?.map((item, index) => (
                                                <div onClick={() => getCurrentTnT(item?.video_id, item?.title)} key={index} className='text-gray-800 w-64 pb-5 text-[0.9rem] mx-2 mb-5 col-span-1'>
                                                    <div className='flex-col w-64'>
                                                        <div className="mb-2 relative group cursor-pointer">
                                                            <div className="hover:ring-2 rounded-lg hover:ring-blue-500 active:ring-blue-300 inline-block overflow-hidden w-64 h-[80%] cursor-pointer duration-200">
                                                                <YouTubeThumbnail
                                                                    videoId={item?.video_id}
                                                                    title={item?.title}
                                                                    onImageLoad={(url) => handleThumbnailLoad(item?.video_id, url)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='flex'>
                                                            <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                                            </div>
                                                            <div className='flex-col w-9/12 ml-1'>
                                                                <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                    <p className='text-[14px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[15px] mb:text-mb'>
                                                                        {item?.title}
                                                                    </p>
                                                                </div>
                                                                <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                    <p className='text-gray-500 leading-4 line-clamp-1'>{channelDisplayName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div >
        </>

    );
};


export default LoadChannelThumbnails;
