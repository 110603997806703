import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import axios from 'axios';
import { toast } from 'react-toastify';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { FaChessKing, FaChessQueen, FaCrown, FaTshirt } from "react-icons/fa";
import { db, storage } from '../../../firebase-config';
import { collection, getDocs, query, orderBy, limit, where, addDoc, doc, setDoc, deleteDoc, startAfter, endBefore, serverTimestamp } from "firebase/firestore";
import { MdShortText, MdLandscape, MdTipsAndUpdates, MdChevronRight, MdOutlineShortText } from 'react-icons/md'
import { FcAdvertising, FcIdea, FcLandscape, FcPicture } from 'react-icons/fc'
import { AiFillPicture } from "react-icons/ai";
import Lottie from "lottie-react";
import logo from '../../../assets/logo_full_32.png';
import { Tab } from '@headlessui/react';
import Avatar from '../../components/Avatar';
import pulse from "../../../assets/animations/pulse_two.json";
import thinking from "../../../assets/animations/thinking.json";
import alotOfThings from "../../../assets/animations/alot-of-things-going-in-the-head.json";
import error from "../../../assets/animations/error.json";
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import TypingEffect from '../../components/TypingEffect';
import TypingEffectList from '../../components/TypingEffectList';
import OnboardingPopup from '../../components/OnboardingPopup';
import Tooltip from '../../components/Tooltip';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import { HiFire, HiOutlineChevronDoubleRight, HiOutlineChevronRight } from 'react-icons/hi';
import { BsChevronBarRight, BsChevronRight, BsMegaphone } from 'react-icons/bs';
import Typewriter from '../../components/Typewriter';
import SentenceBreaker from '../../components/SentenceBreaker';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';
import { GiJesterHat, GiMegaphone } from 'react-icons/gi';
import { IoMegaphone } from 'react-icons/io5';
import { GoMegaphone } from 'react-icons/go';
import { TbCursorText } from 'react-icons/tb';
import { TfiAnchor } from 'react-icons/tfi';

// const { Configuration, OpenAIApi } = require("openai");

const ThumbnailStrategist = () => {
    const navigate = useNavigate()
    const [selectedIndex, setSelectedIndex] = useState(0);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const { accessLevel, aiCreditsLeft, premiumStatus } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [userText, setUserText] = useState('');
    const [initialIdea, setInitialIdea] = useState('');

    const [thumbnailIdea, setThumbnailIdea] = useState([]);
    const [thumbnailIdeas, setThumbnailIdeas] = useState([]);
    const [generatedIndex, setGeneratedIndex] = useState(0);

    const [blockPopup, setBlockPopup] = useState(false)

    const [description, setDescription] = useState('');
    // const [description, setDescription] = useState([]);
    const [clothingColors, setClothingColors] = useState([]);
    const [sceneColors, setSceneColors] = useState([]);
    const [textColor, setTextColor] = useState('');
    const [foreground, setForeground] = useState('');
    const [background, setBackground] = useState('');
    const [thumbnailOverlayText, setThumbnailOverlayText] = useState('');
    // const [textColor, setTextColor] = useState([]);
    const [tips, setTips] = useState([]);

    const [getting, setGetting] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [standby, setStandby] = useState(true);
    const [responseSuccessful, setResponseSuccessful] = useState(null);
    const [ideaResponseSuccessful, setIdeaResponseSuccessful] = useState(null);
    const [gettingDetailedIdea, setGettingDetailedIdea] = useState(false);
    const [generatedDetailedIdea, setGeneratedDetailedIdea] = useState(false);
    const [standbyDetailedIdea, setStandbyDetailedIdea] = useState(true);
    const [responseSuccessfulDetailedIdea, setResponseSuccessfulDetailedIdea] = useState(null);
    const [sentences, setSentences] = useState([]);

    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)
    const [catItems, setCatItems] = useState()
    const [catItemsLoaded, setCatItemsLoaded] = useState()
    const [currentCategory, setCurrentCategory] = useState()

    // useEffect(() => {
    //     let countdown;
    //     if (gettingDetailedIdea || getting) {
    //         countdown = setInterval(() => {
    //             if (seconds > 0) {
    //                 setSeconds(seconds - 1);
    //             } else {
    //                 setSeconds(60);
    //                 clearInterval(countdown); // Stop the countdown when it reaches 0
    //             }
    //         }, 1000); // Update every 1 second
    //     } else {
    //         // Reset the timer when gettingDetailedIdea becomes false
    //         setSeconds(60);
    //         clearInterval(countdown);
    //     }

    //     return () => clearInterval(countdown); // Cleanup on unmount or when gettingDetailedIdea changes

    // }, [gettingDetailedIdea, getting, seconds]);

    const [seconds, setSeconds] = useState(1);

    // useEffect(() => {
    //     let countdown;
    //     if (gettingDetailedIdea || getting) {
    //         countdown = setInterval(() => {
    //             if (seconds < 100) { // Change the condition to check for less than 100 instead of greater than 0
    //                 setSeconds(seconds + 1);
    //             } else {
    //                 clearInterval(countdown); // Stop the countdown when it reaches 100
    //             }
    //         }, 850); // Update every 1 second
    //     } else {
    //         // Reset the timer when gettingDetailedIdea becomes false
    //         setSeconds(0);
    //         clearInterval(countdown);
    //     }
    // }, [gettingDetailedIdea, getting, seconds]);

    useEffect(() => {
        if (gettingDetailedIdea == true || getting == true) {

            const interval = setInterval(() => {
                if (seconds < 95) {
                    setSeconds(seconds + 0.2);
                } else {
                    clearInterval(interval);
                }
            }, 50);

            return () => clearInterval(interval);
        }

    }, [seconds, gettingDetailedIdea, getting]);


    const checkCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(`${name}=`)) {
                return true;
            }
        }
        return false;
    };

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const getRoughIdeas = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');
        setGeneratedIndex(0);
        setSeconds(0);

        //console.log(token);

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (userText.trimStart().trimEnd() !== '' && userText !== null && token !== null) {
                setGetting(true);
                setInitialIdea(userText.trimStart().trimEnd());
                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/generate-thumbnail-ideas/${mainUserId}`;

                const data = {
                    prompt: userText,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        //console.log('Response received')
                        if (response.status === 200) {
                            setSeconds(60);
                            setGetting(false);
                            setGenerated(true);
                            setResponseSuccessful(true);
                            setStandby(false);

                            // //console.log(response.data);
                            // var resultText = response.data.payload;
                            // var ideas = resultText.split('\n');
                            // // visualizeOnMockup(titles[0]);

                            const ideas = response.data.payload.ideas;
                            setThumbnailIdeas(ideas);

                            getCategory(ideas[0].category);
                            getIdea(ideas[0].idea);

                        } else if (response.status === 404) {
                            //console.log(response)
                            setSeconds(60);
                            setGetting(false);
                            setResponseSuccessful(false);

                        } else {
                            //console.log(response)
                            setSeconds(60);
                            setGetting(false);
                            setResponseSuccessful(false);
                            setStandby(false);

                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setGetting(false);
                        setStandby(true);
                        setResponseSuccessful(false);

                        if (error.response) {
                            //console.log('Data:', error.response.data);
                            //console.log('Status:', error.response.status);
                            //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            } else {
                toast.error('Please enter some text first', {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
        } else {
            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }
    }

    const getIdea = async (short_description) => {
        const token = getCookie('TG_AT');
        setSeconds(0);

        setGettingDetailedIdea(true);
        setGeneratedDetailedIdea(false);

        //console.log(short_description, "short_description");

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 4) {
            if (short_description.trimStart().trimEnd() !== '' && short_description !== null && token !== null) {
                // setCurrentCategory('');
                setGettingDetailedIdea(true);

                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/generate-thumbnail-idea/${mainUserId}`;
                // const promptText = userText
                const data = {
                    initial_prompt: initialIdea,
                    short_description: short_description,
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        //console.log('Response received')
                        if (response.status === 200) {

                            const { description, clothingColors, sceneColors, textColor, tips } = response.data.payload;

                            // Assuming 'response' is the variable containing the API response
                            const { data } = response; // Destructure the response object

                            // Check if payload exists in data
                            if (data && data.payload) {
                                const { payload } = data;

                                // Access each part of the payload
                                const description = payload.description;
                                const foreground = payload.foreground;
                                const background = payload.background;
                                const thumbnailOverlayText = payload.thumbnailOverlayText;
                                const clothingColors = payload.clothingColors;
                                const sceneColors = payload.sceneColors;
                                const textColor = payload.textColor;
                                const tips = payload.tips;

                                // getCategoryThumbs();
                                // Add error checks for each state update
                                if (description) {
                                    setDescription(description);
                                } else if (!foreground && !background) {
                                    setGettingDetailedIdea(false);
                                    setStandby(true);
                                    setIdeaResponseSuccessful(false);
                                    return handleInvalidData('Invalid description data');
                                }

                                setForeground(foreground ?? '');
                                setBackground(background ?? '');
                                setThumbnailOverlayText(thumbnailOverlayText);
                                setSceneColors(sceneColors);
                                setClothingColors(clothingColors);
                                setTextColor(textColor);
                                setTips(tips);

                                // if (Array.isArray(tips)) {
                                // setTips(tips);
                                // } else {
                                //     setGettingDetailedIdea(false);
                                //     setStandby(true);
                                //     setIdeaResponseSuccessful(false);
                                //     return handleInvalidData('Invalid tips data');
                                // }

                                // setDescription(description);
                                // setClothingColors(clothingColors);
                                // setSceneColors(sceneColors);
                                // setTextColor(textColor);
                                // setTips(tips);

                                //console.log(description);
                                //console.log(foreground);
                                //console.log(background);
                                //console.log(thumbnailOverlayText);
                                //console.log(clothingColors);
                                //console.log(sceneColors);
                                //console.log(textColor);
                                //console.log(tips);

                                setGettingDetailedIdea(false);
                                setGeneratedDetailedIdea(true);
                                setIdeaResponseSuccessful(true);
                                setStandbyDetailedIdea(false);
                            }
                            else {
                                // Handle the case when payload is missing
                                throw new Error('Invalid payload data');
                            }

                        } else if (response.status === 404) {
                            //console.log(response)
                            setGetting(false);
                            setIdeaResponseSuccessful(false);
                        } else {
                            //console.log(response)
                            setGetting(false);
                            setIdeaResponseSuccessful(false);
                            setStandby(false);
                            toast.error('Something went wrong. Please try again later.', {
                                position: "top-right",
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setGettingDetailedIdea(false);
                        setStandby(true);
                        setIdeaResponseSuccessful(false);

                        if (error.response) {
                            //console.log('Data:', error.response.data);
                            //console.log('Status:', error.response.status);
                            //console.log('Headers:', error.response.headers);

                            if (error.response.data.payload === "1494") {
                                toast.error(`${error.response.data.status}`, {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            } else {
                                toast.error('Something went wrong. Please try again later.', {
                                    position: "bottom-center",
                                    autoClose: 7000,
                                });
                            }

                        } else {
                            toast.error('Something went wrong. Please try again later.', {
                                position: "bottom-center",
                                autoClose: 7000,
                            });
                        }
                    });
            } else {
                toast.error('Please enter some text first', {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
        } else {

            if (premiumStatus === 'TRIALING') {
                setCreditBlockPopupTrial(true);
            } else {
                setCreditBlockPopup(true);
            }

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     setCreditBlockPopup(true);
        // }
    }

    // Helper function to handle invalid data
    const handleInvalidData = (errorMessage) => {
        console.error(errorMessage);
        return;
    }

    const extractColorCodes = (text) => {
        const regex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/g;
        const matches = text.match(regex);
        if (!matches) return [];

        return matches;
    };

    const separatePoints = (text) => {
        const points = text.split(/[\n\r]+/).filter(Boolean);
        return points.map((point, index) => ({ id: index + 1, content: point.trim() }));
    };

    const copyColorToClipBoard = (color) => {
        navigator.clipboard.writeText(color);
        toast.success('Color copied to clipboard', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    const copyTextToClipBoard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('Text copied to clipboard', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    const copyPromptToClipBoard = (color) => {
        navigator.clipboard.writeText(color);
        toast.success('Prompt copied to clipboard', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    useEffect(() => {
        document.title = 'Thumbnail Idea Generator';
        if (mainUserId === null) {
            navigate('/login')
        }
    }, [])

    const detailedDescription = (idea, i, category) => {
        if (aiCreditsLeft > 4) {
            if (i === 0) {
                if (gettingDetailedIdea == false) {
                    setGeneratedIndex(i);
                    getCategory(category);
                    getIdea(idea);
                } else {
                    toast.warn('Still getting the details for previous idea. Please wait.', {
                        position: "top-right",
                        autoClose: 7000,
                    });
                }
            } else {
                if (gettingDetailedIdea == false) {
                    //console.log(category)
                    getCategory(category);
                    setGeneratedIndex(i);
                    getIdea(idea);
                } else {
                    toast.warn('Still getting the details for previous idea. Please wait.', {
                        position: "top-right",
                        autoClose: 7000,
                    });
                }
            }
        } else {
            // if (premiumStatus === 'TRIALING') {
            //     setCreditBlockPopupTrial(true);
            // } else {
            //     setCreditBlockPopup(true);
            // }
            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
            // toast.warn('Not enough AI Credits left. Recharge in the Settings', {
            //     position: "top-right",
            //     autoClose: 7000,
            // });
        }
    }

    const saveIdea = () => {
        toast.info(`Coming soon!`, {
            position: "top-right",
            autoClose: 7000,
        });
    }

    const getCategoryThumbs = async (categoryId) => {
        //console.log(categoryId)

        if (categoryId) {
            const colRef = collection(db, 'Library');
            const q = query(
                colRef,
                where("category_id", "==", categoryId),
                // limit(25)
            );
            const snapshot = await getDocs(q);
            const allDocs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            // Randomly select 6 documents from allDocs
            const randomDocs = getRandomItems(allDocs, 4);

            //console.log(randomDocs);
            setCatItems(randomDocs);
            setCatItemsLoaded(true);
        }

    }

    // Function to select random items from an array
    const getRandomItems = (array, numItems) => {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, numItems);
    }

    const getCategory = (categoryName) => {
        let category = '';

        if (categoryName === 'Before & After') {
            category = 'rR1rbPEUHjWsdYkgCmA6';
        }

        if (categoryName === 'Show & Tell') {
            category = 'IYYKmbL6ZTQHThMBnZ5m';
        }

        if (categoryName === 'Creative Adaptation') {
            category = '3SKXjW0bZFd6OWq4Ooph';
        }

        if (categoryName === 'Image Only') {
            category = 'L5lTYfvGz3nTpJNzlXli';
        }

        if (categoryName === 'Negative Space') {
            category = 'P3Sc7qed5ZWBmJ7pR3rv';
        }

        if (categoryName === 'Face & Expression') {
            category = 'U3dJ6OC7xbiCVyJKCEZn';
        }

        if (categoryName === 'Bright & Eye-Catching Contrast') {
            category = '0CaQKsfhH0kbskjDUQkW';
        }

        if (categoryName === 'Text on Image') {
            category = 'uE5pHcuC1vdBAxf8hKhK';
        }

        if (categoryName === 'Pointers') {
            category = 'j7Odj6CnBcq26oqq0I33';
        }

        if (categoryName === 'Curiosity Inducing') {
            category = 'zWGArIaBbKpujrvBi14y';
        }

        //console.log(category)

        getCategoryThumbs(category);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Planner | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Planner | ThumbnailGeek" />
            </Helmet>

            {/* <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/thumbnail-ideas'} /> */}

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/testing-suite/upload'} />

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="thumbnail-planner" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className=" w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold inline-flex`}>Plan Your Perfect Thumbnail.</h5>
                            </div>
                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mt-[61px] md:mt-[71px] lg:mt-[41px] bg-gray-50">
                            <div className="mt-8 w-full">
                                <form onSubmit={getRoughIdeas}>
                                    <div className="mt-8">
                                        {
                                            generated === true || getting === true || responseSuccessful === false
                                                ?
                                                <></>
                                                :
                                                <div className={`flex flex-col justify-center items-center text-center mt-4 mb-4 text-gray-800 w-full`}>
                                                    <div className="w-32 h-32 mt-7">
                                                        <img src={require('../../../assets/svgs/scientist_female_idea.svg').default} alt='happyEmoji' />
                                                    </div>
                                                    <p className="mt-2"> Discover a great idea for your thumbnail. </p>
                                                </div>
                                        }

                                        <div className="relative pt-2">
                                            <input autoFocus onChange={(e) => setUserText(e.target.value)} type="text"
                                                className="p-4 bg-white text-gray-900 rounded-lg w-full pl-4 pr-32 text-lg ring-1 ring-blue-200 mt-4 focus:ring-blue-400 active:ring-blue-400"
                                                placeholder="Enter title of your video or what happens in your video" />

                                            <div className="absolute right-2 top-8 cursor-pointer">
                                                {
                                                    getting === true
                                                        ?
                                                        <button className={`${getting === true ? 'hover:cursor-default bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} 
                                                                     flex flex-row justify-center items-center rounded-lg py-3 px-4 text-white`}>
                                                            {/* <SlRefresh className='h-4 w-4 mt-[2px]' /> */}
                                                            <span className='ml-1 text-sm'>Please Wait...</span>
                                                        </button>
                                                        :
                                                        <>
                                                            {/* {
                                                                accessLevel !== 0 && accessLevel !== 1 && accessLevel !== 2 && accessLevel !== 5
                                                                    ?
                                                                    <button type='submit' className={`${userText === '' ? 'hover:cursor-default bg-gray-300' : 'cursor-pointer hover:bg-gray-400 bg-gray-500'}  
                                                                     inline-flex justify-center items-center rounded-lg  py-3 px-4 text-white`}>
                                                                        <FaCrown className='h-4 w-4 mt-[2px] mr-2 text-yellow-400' />
                                                                        <span className={`${userText === '' ? 'cursor-default ' : 'cursor-pointer'} text-sm`}>
                                                                            Describe
                                                                        </span>
                                                                    </button>
                                                                    : */}
                                                            <button type='submit' className={`${userText === '' ? 'cursor-default bg-gray-300' : 'cursor-pointer hover:bg-blue-700 bg-blue-600'}  
                                                                     flex flex-row justify-center items-center rounded-lg  py-3 px-4 text-white`}>
                                                                {
                                                                    generated === true
                                                                        ?
                                                                        <span className={`${userText === '' ? 'cursor-default ' : 'cursor-pointer'} ml-1 text-sm`}>
                                                                            Re-Describe
                                                                        </span>
                                                                        :
                                                                        <span className={`${userText === '' ? 'cursor-default ' : 'cursor-pointer'} ml-1 text-sm`}>
                                                                            Describe
                                                                        </span>
                                                                }
                                                            </button>
                                                            {/* } */}
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {
                                getting === true
                                    ?
                                    <div className="mt-2 relative">
                                        <div className='w-full flex flex-col items-center justify-center relative'>

                                            <div className='w-72 h-96'>
                                                <Lottie animationData={thinking} loop={true} />
                                            </div>
                                            <div className='flex-col items-center justify-center font-normal text-[14px] ml-7 mb-5 text-gray-500'>
                                                <span className='text-center ml-7'>Thinking...</span>
                                                {/* <span className='ml-1 font-bold'>{seconds}</span> seconds... */}
                                                <div className="flex h-2 w-32 mb-4 mt-2 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                    <div
                                                        style={{ width: `${seconds}%` }}
                                                        className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            responseSuccessful === false
                                                ?
                                                <div className='w-full flex flex-cols items-center justify-center relative mt-4'>
                                                    <div className='w-72 h-72'>
                                                        <Lottie animationData={error} loop={true} />
                                                    </div>
                                                    <h2 className='absolute bottom-[56px] right-1/2 translate-x-1/2 font-normal text-[14px] mb-5 text-gray-500'>Something went wrong... Try again later.</h2>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        standby === true
                                                            ?
                                                            <></>
                                                            :
                                                            <div className='flex flex-wrap w-full mt-12'>
                                                                {/* Left column */}
                                                                <div className={`flex flex-col w-full lg:w-5/12 lg:pr-5 ${standby === false ? 'opacity-100 -translate-y-1' : 'opacity-0 translate-y-4'} 
                                                                transform transition-transform duration-500 ease-in mb-24`}
                                                                    style={{
                                                                        opacity: standby === false ? 1 : 0,
                                                                        transform: `translateY(${standby === false ? 0 : '1rem'})`, // Adjust the initial translateY value as needed
                                                                    }}
                                                                >
                                                                    <div className="inline-flex items-baseline">
                                                                        <p className='mb-7 text-sm uppercase font-semibold mr-2'>🔥 Thumbnail Ideas</p>
                                                                        <p className=' text-[10px] uppercase font-semibold text-green-500'>IMPROVED</p>
                                                                    </div>

                                                                    {
                                                                        thumbnailIdeas?.map((ideas, i) => (
                                                                            <Tooltip content={generatedIndex === i ? "Details -->" : "Click for Detailed Description"} position="top" >
                                                                                <div key={i} onClick={() => detailedDescription(ideas.idea, i, ideas.category)}
                                                                                    className={`relative group hover:cursor-pointer flex flex-col mb-2 p-5 rounded-lg text-sm justify-between 
                                                                                        ${generatedIndex === i ? 'bg-white ring-1 ring-purple-400' : ' hover:bg-white hover:cursor-pointer ring-1 ring-gray-200'}`}>
                                                                                    <div onClick={() => detailedDescription(ideas.idea, i, ideas.category)} className='w-full text-[15px] leading-[25px] tracking-[-0.020em] text-gray-800 mr-2'>
                                                                                        {/* {ideas.idea} */}
                                                                                        <span dangerouslySetInnerHTML={{ __html: ideas.idea.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }} />
                                                                                    </div>

                                                                                    {ideas.category === 'Before & After' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[122px] inline-flex gr items-center gap-x-1.5 mt-3 rounded-md bg-sky-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/rR1rbPEUHjWsdYkgCmA6?source=category&name=Before%20&%20After'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Show & Tell' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[105px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-amber-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/IYYKmbL6ZTQHThMBnZ5m?source=category&name=Show%20&%20Tell'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Creative Adaptation' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[88px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/3SKXjW0bZFd6OWq4Ooph?source=category&name=Creative'} target='_blank'>
                                                                                                Creative
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Image Only' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[105px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-cyan-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/L5lTYfvGz3nTpJNzlXli?source=category&name=Image%20Only'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Negative Space' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-48 inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/P3Sc7qed5ZWBmJ7pR3rv?source=category&name=Negative%20Space'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Face & Expression' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-36 inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/U3dJ6OC7xbiCVyJKCEZn?source=category&name=Face%20&%20Expression'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Bright & Eye-Catching Contrast' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[150px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-teal-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/0CaQKsfhH0kbskjDUQkW?source=category&name=Bright%20&%20Interesting'} target='_blank'>
                                                                                                Bright & Interesting
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Text on Image' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[120px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/uE5pHcuC1vdBAxf8hKhK?source=category&name=Text%20on%20Image'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Pointers' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-[100px] inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-pink-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/j7Odj6CnBcq26oqq0I33?source=category&name=Pointers'} target='_blank'>
                                                                                                {ideas.category}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    {ideas.category === 'Curiosity Inducing' && (
                                                                                        <div onClick={(e) => e.stopPropagation()} className="w-32 inline-flex items-center gap-x-1.5 mt-3 rounded-md bg-emerald-100 px-2 py-1 text-xs font-medium text-gray-600 hover:underline">
                                                                                            <svg className="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6" aria-hidden="true">
                                                                                                <circle cx={3} cy={3} r={3} />
                                                                                            </svg>
                                                                                            <NavLink to={'/viral-library/category/zWGArIaBbKpujrvBi14y?source=category&name=Curiosity'} target='_blank'>
                                                                                                Curiosity
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    )}

                                                                                    <MdChevronRight className={`${generatedIndex === i ? 'block' : 'hidden'} group-hover:block w-5 h-5 absolute -right-2 bottom-1/2 translate-y-1/2 rounded-full 
                                                                                        bg-white ring-1 ring-purple-400`} />
                                                                                </div>
                                                                            </Tooltip>
                                                                        ))
                                                                    }
                                                                </div>

                                                                {/* Right column */}
                                                                <div className="flex flex-col w-full lg:w-7/12">
                                                                    {
                                                                        ideaResponseSuccessful === false
                                                                            ?
                                                                            <div className='flex flex-cols items-center justify-center  relative mt-4'>
                                                                                <Lottie className='w-72 h-72' animationData={error} loop={true} />
                                                                                <h2 className='absolute bottom-[56px] right-1/2 translate-x-1/2 font-normal text-[14px] mb-5 text-gray-500'>Something went wrong... Try again later.</h2>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    gettingDetailedIdea === true && (
                                                                                        <div className="mt-12 w-full flex flex-col items-center justify-center">

                                                                                            <div className='w-full flex flex-cols items-center justify-center relative'>
                                                                                                <Lottie className='w-72 h-72' animationData={alotOfThings} loop={true} />
                                                                                            </div>
                                                                                            <div className='w-full flex flex-col items-center justify-center text-center font-normal text-[14px] mb-5 text-gray-500'>
                                                                                                <span className='ml-1 font-medium'>Thinking of a detailed description</span>
                                                                                                <div className="mt-2 flex h-2 w-32 mb-4 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                                                    <div
                                                                                                        style={{ width: `${seconds}%` }}
                                                                                                        className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                    }
                                                                    {
                                                                        ideaResponseSuccessful === true && generatedDetailedIdea == true &&
                                                                        (
                                                                            <div className="flex flex-wrap w-full ">
                                                                                <div className={`opacity-0 w-full lg:w-8/12 
                                                                            ${generatedDetailedIdea ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} transform transition-transform duration-500 ease-in`}>

                                                                                    <h3 className="mb-[23px] leading-6 text-sm uppercase font-semibold">📝 Expanded Details of the idea</h3>

                                                                                    <div className="relative rounded-md bg-white border border-purple-400 mb-16">
                                                                                        <button
                                                                                            onClick={() => saveIdea()}
                                                                                            type="button"
                                                                                            className="absolute right-4 top-3 inline-flex h-6 rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                                        >
                                                                                            Save
                                                                                        </button>

                                                                                        <div className={`px-4 `}>
                                                                                            <div className="flex flex-row justify-between border-b items-center">
                                                                                                <div className="inline-flex w-full py-[10px]">
                                                                                                    <div className='rounded-full p-1 mr-3'>
                                                                                                        <AiFillPicture className='text-lg text-gray-700' />
                                                                                                    </div>
                                                                                                    <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Scene Description</span>
                                                                                                </div>

                                                                                            </div>

                                                                                            <div className="text-[#4A4A4A] text-[15px] space-y-2 leading-[25px] tracking-[-0.020em] bg-white py-2" style={{ whiteSpace: 'pre-line' }}>
                                                                                                {/* <SentenceBreaker text={description} /> */}
                                                                                                {description}
                                                                                            </div>
                                                                                        </div>

                                                                                        {
                                                                                            foreground !== '' && foreground !== undefined && foreground !== null && (
                                                                                                <div className={`px-4 ${description !== '' && description !== '' && description !== undefined ? 'mt-8' : ''}`}>
                                                                                                    <div className="inline-flex w-full py-[10px] border-b">
                                                                                                        <div className='rounded-full p-1 mr-3'>
                                                                                                            <HiFire className='text-lg text-gray-700' />
                                                                                                        </div>
                                                                                                        <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Foreground</span>
                                                                                                    </div>
                                                                                                    <div className="text-[#4A4A4A] text-[15px] space-y-2 leading-[25px] tracking-[-0.020em] bg-white py-2" style={{ whiteSpace: 'pre-line' }}>
                                                                                                        {foreground}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            background !== '' && background !== undefined && background !== null && (
                                                                                                <div className="px-4  mt-8">
                                                                                                    <div className="inline-flex w-full py-[10px] border-b">
                                                                                                        <div className='rounded-full p-1 mr-3'>
                                                                                                            <MdLandscape className='text-lg text-gray-700' />
                                                                                                        </div>
                                                                                                        <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>BACKGROUND</span>
                                                                                                    </div>
                                                                                                    <div className="text-[#4A4A4A] text-[15px] space-y-2 leading-[25px] tracking-[-0.020em] bg-white py-2" style={{ whiteSpace: 'pre-line' }}>
                                                                                                        {background}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }



                                                                                        <div className="grid grid-cols-3 space-x-2 mt-8 px-4">
                                                                                            {
                                                                                                clothingColors.length !== 0 && (
                                                                                                    <div className="">
                                                                                                        <div className="overflow-hidden rounded-lg ">
                                                                                                            <div className=" inline-flex w-full py-[10px]  border-b">
                                                                                                                <div className=' rounded-full p-1 mr-[4px]'>
                                                                                                                    <FaTshirt className='text-lg text-gray-700' />
                                                                                                                </div>
                                                                                                                <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Clothing</span>
                                                                                                            </div>
                                                                                                            <div className="flex flex-wrap items-center justify-center gap-x-2  py-3 pl-1">
                                                                                                                {clothingColors?.map((color, index) => (
                                                                                                                    <Tooltip content="Copy color code" position="top">
                                                                                                                        <div onClick={() => copyColorToClipBoard(color.trim())} key={index}
                                                                                                                            className="w-10 h-10 ring-1 ring-gray-400 hover:ring-2 hover:cursor-pointer hover:ring-gray-600 active:ring-1 rounded-lg"
                                                                                                                            style={{ backgroundColor: color.trim() }}></div>
                                                                                                                    </Tooltip>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }


                                                                                            {
                                                                                                sceneColors.length !== 0 && (
                                                                                                    <div className="">
                                                                                                        <div className="overflow-hidden rounded-lg">
                                                                                                            <div className=" inline-flex w-full py-[10px]  border-b">
                                                                                                                <div className=' rounded-full p-1 mr-[4px]'>
                                                                                                                    <MdLandscape className='text-lg text-gray-700' />
                                                                                                                </div>
                                                                                                                <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Scene</span>
                                                                                                            </div>
                                                                                                            <div className="flex flex-wrap gap-x-2 py-3 pl-1 items-center justify-center">
                                                                                                                {sceneColors?.map((color, index) => (
                                                                                                                    <Tooltip content="Copy color code" position="top">
                                                                                                                        <div onClick={() => copyColorToClipBoard(color.trim())} key={index}
                                                                                                                            className="w-10 h-10 rounded-lg ring-1 ring-gray-400 hover:ring-2 hover:cursor-pointer hover:ring-gray-600 active:ring-1 mb-3"
                                                                                                                            style={{ backgroundColor: color.trim() }}></div>
                                                                                                                    </Tooltip>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                textColor !== '' && (
                                                                                                    <div className="">
                                                                                                        <div className="rounded-lg">
                                                                                                            <div className="inline-flex w-full py-[10px] border-b">
                                                                                                                <div className='rounded-full p-1 mr-[4px]'>
                                                                                                                    <MdShortText className='text-lg text-gray-700' />
                                                                                                                </div>
                                                                                                                <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Text</span>
                                                                                                            </div>
                                                                                                            <div className="flex flex-wrap items-center justify-center gap-x-4 py-3 pl-1 space-x-1">
                                                                                                                <Tooltip content="Copy color code" position="top">
                                                                                                                    <div onClick={() => copyColorToClipBoard(textColor.trim())}
                                                                                                                        className="w-10 h-10 rounded-lg ring-1 ring-gray-400 hover:ring-2 hover:cursor-pointer hover:ring-gray-600 active:ring-1"
                                                                                                                        style={{ backgroundColor: textColor.trim() }}></div>
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>

                                                                                        {
                                                                                            thumbnailOverlayText !== '' && (
                                                                                                <div className="px-4  mt-8">
                                                                                                    <div className="inline-flex w-full py-[10px] border-b">
                                                                                                        <div className='rounded-full p-1 mr-3'>
                                                                                                            <TbCursorText className='text-lg text-gray-700' />
                                                                                                        </div>
                                                                                                        <div className="">
                                                                                                            <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px] mr-2'>TEXT ON THUMBNAIL</span>
                                                                                                            <span className='text-green-500 leading-6 text-[10px] uppercase font-semibold mt-[2px]'>IMPROVED</span>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="text-[#4A4A4A] uppercase font-bold text-[15px] ml-10 space-y-2 leading-[25px] tracking-[-0.020em] bg-white py-2" style={{ whiteSpace: 'pre-line' }}>
                                                                                                        <span onClick={() => copyTextToClipBoard(thumbnailOverlayText.trim())} className=" hover:cursor-pointer">{thumbnailOverlayText}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div className='space-y-5 mt-5 '>
                                                                                            <div className=" px-4">
                                                                                                <div className="inline-flex w-full py-[10px] border-b">
                                                                                                    <div className='rounded-full p-1 mr-3'>
                                                                                                        <MdTipsAndUpdates className='text-lg text-gray-700' />
                                                                                                    </div>
                                                                                                    <span className='text-gray-800 leading-6 text-xs uppercase font-semibold mt-[2px]'>Tips</span>
                                                                                                </div>
                                                                                                <div className="text-[#4A4A4A] text-[15px] leading-[25px] tracking-[-0.020em] py-4">
                                                                                                    <ul>
                                                                                                        {tips?.map((tip, index) => (
                                                                                                            <li key={index} className="mb-2 inline-flex">
                                                                                                                <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                {tip}
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="flex flex-col w-full lg:w-4/12 px-3">
                                                                                    <h3 className="mb-2 leading-6 text-sm uppercase font-semibold">OTHERS IN CATEGORY</h3>

                                                                                    {
                                                                                        catItemsLoaded === false
                                                                                            ?
                                                                                            <>
                                                                                                <div className="w-full flex flex-wrap ">
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                                                        <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                                                        </div>

                                                                                                        <div className="flex">
                                                                                                            <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                                                                <span> &nbsp; </span>
                                                                                                            </div>
                                                                                                            <div className="w-[80%]">
                                                                                                                <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                                <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                                                    <span> &nbsp; </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {catItems?.map((item, i) => (
                                                                                                    item.is_active !== false
                                                                                                        ?
                                                                                                        <>
                                                                                                            <div className={`relative text-gray-800 w-full max-w-[350px] mt-3 pt-2 pb-2 text-[0.9rem] mx-2 mb-0 group`}>

                                                                                                                <Tooltip content="Click for details [New Tab]" position="top">
                                                                                                                    <NavLink key={i} to={`/viral-library/details/${item.id} `} target='_blank'>
                                                                                                                        <div className="mb-2 cursor-pointer">
                                                                                                                            <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                                                                                                                                <img className=" @apply inline-block mx-0 w-full rounded-lg" src={item.item_data.thumbnail} alt="" />
                                                                                                                                <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </NavLink>
                                                                                                                </Tooltip>

                                                                                                                <NavLink key={i} to={`/viral-library/details/${item.id}`}>
                                                                                                                    <div className='flex'>
                                                                                                                        <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                                                                            <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.item_data.avatar} alt="" />
                                                                                                                        </div>
                                                                                                                        <div className='flex-col w-9/12'>
                                                                                                                            <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                                                                <p className='text-[13px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[14px] mb:text-mb'>{item.item_data.title}</p>
                                                                                                                            </div>
                                                                                                                            <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                                                                                <p className='text-gray-500 leading-4 line-clamp-1'>{item.item_data.name}
                                                                                                                                    {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </NavLink>
                                                                                                            </div>

                                                                                                        </>
                                                                                                        :
                                                                                                        <div key={item.id}></div>
                                                                                                ))}
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                    }
                                                                </div>


                                                            </div>
                                                    }
                                                </>
                                        }
                                    </>
                            }

                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default ThumbnailStrategist