import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Helmet } from "react-helmet";
import { NavLink, useNavigate, useLocation, Link, } from 'react-router-dom';
import SideBar from '../../components/SideBar'
import { toast } from 'react-toastify';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db, storage, storageStoryboards } from '../../../firebase-config';
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, onSnapshot, getDoc, getDocs, collection, query, orderBy, limit, deleteDoc, updateDoc } from "firebase/firestore";
import Lottie from "lottie-react";
import Avatar from '../../components/Avatar';
import thinking from "../../../assets/animations/ani6.json";
import alotOfThings from "../../../assets/animations/alot-of-things-going-in-the-head.json";
import error from "../../../assets/animations/error.json";
import Tooltip from '../../components/Tooltip';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';
import { FaTrashAlt } from 'react-icons/fa';
import DotDotDot from '../../components/DotDotDot';
import PreciseCounter from '../../components/PreciseCounter';
import PreferenceModal from '../../components/PreferenceModal';
import FeedbackBanner from '../../components/FeedbackBanner';
import StoryboardItem from '../../components/StoryboardItem';
import StoryboardsList from '../../components/StoryboardsList';
import OnboardingPopup from '../../components/OnboardingPopup';
import { BsPersonGear } from 'react-icons/bs';
import TrialUserPopup from '../../components/TrialUserPopup';
import PageOnboardingGifPopup from '../../components/PageOnboardingGifPopup';
import thumbnail_onboarding_gif from '../../../assets/gifs/thumbnail_storyboards_preview_3.gif';

const Spinner = () => (
    <svg className="animate-spin h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
);

const ThumbnailStoryboards = React.memo(() => {
    const navigate = useNavigate();
    const functions = getFunctions();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sId = params.get('sId');
    // const ilType = params.get('il_type');
    const createThumbnailStoryboard = httpsCallable(functions, 'createThumbnailStoryboard');
    const deleteStoryboardItemFromStorage = httpsCallable(functions, 'deleteStoryboardItemFromStorage');
    const redoEntireThumbnailStoryboard = httpsCallable(functions, 'redoEntireThumbnailStoryboard');

    const { accessLevel, aiCreditsLeft, premiumStatus } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId");
    const [userText, setUserText] = useState('');
    const [topTitle, setTopTitle] = useState('Creative Thumbnail Ideas');
    const [storyboardId, setStoryboardId] = useState(null);
    const [seconds, setSeconds] = useState(1);
    const modalRef = useRef(null);

    const [imageLoadType, setImageLoadType] = useState(0);
    const [getting, setGetting] = useState(false);
    const [currentSBSingle, setCurrentSBSingle] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [responseSuccessful, setResponseSuccessful] = useState(null);

    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)
    const [storyboardData, setStoryboardData] = useState(null);
    const [previousDocData, setPreviousDocData] = useState(null);
    const [storyboards, setStoryboards] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [retrieving, setRetrieving] = useState(false);
    const [loadingStoryboards, setLoadingStoryboards] = useState(true);

    const [deleteModal, setDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [processingItems, setProcessingItems] = useState({});
    const [overallProcessing, setOverallProcessing] = useState(false);

    const [loadingText, setLoadingText] = useState('Drawing straight lines...');

    const checkUserAccess = async () => {
        if (accessLevel === 5) {
            try {
                const storyboardsRef = collection(db, 'Users', mainUserId, 'ThumbnailStoryBoards');
                const q = query(storyboardsRef, limit(2)); // We only need to know if there's more than one
                const querySnapshot = await getDocs(q);

                if (querySnapshot.size > 1) {
                    handleOpenTrialLimitPopup();
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                console.error("Error checking user access:", error);
                return true;
            }
        } else {
            return true;
        }
    }

    // ---------Load Current Storyboard Details--------
    // ------------------------------------------------

    useEffect(() => {
        if (sId) {
            const getSingleStoryboard = async (id) => {
                setGetting(true);
                setStoryboardData(null);
                window.scrollTo({ top: 0, behavior: 'smooth' });

                setCurrentSBSingle(true);
                setGenerated(false);
                setResponseSuccessful(true);
                setRetrieving(true);

                const storyboardsDocRef = doc(db, 'Users', mainUserId, 'ThumbnailStoryBoards', id);
                try {
                    const unsubscribe = onSnapshot(storyboardsDocRef, (snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.data();
                            const id = snapshot.id;

                            let imagesLoaded = Object.keys(data.storyboards || {}).length;

                            if (imagesLoaded === 3) {
                                setStoryboardId(id);
                                setStoryboardData(data);
                                setTopTitle(data.title);
                                setGetting(false);
                                setOverallProcessing(false);
                                setGenerated(true);
                                setResponseSuccessful(true);
                                return () => unsubscribe();
                            }
                        } else {
                            setStoryboardData(null);
                            setGenerated(false);
                            setGetting(false);
                            setResponseSuccessful(false);
                            setOverallProcessing(false);
                            return () => unsubscribe();
                        }
                    });
                    return () => unsubscribe();
                } catch (error) {
                    console.error('Error fetching storyboard items:', error);
                    setGenerated(false);
                }
            };

            getSingleStoryboard(sId);
        }
    }, [sId, mainUserId]);

    const createStoryBoardMockUps = async (e) => {
        e.preventDefault();

        const hasAccess = await checkUserAccess();

        if (hasAccess) {

            if (overallProcessing || aiCreditsLeft <= 180) {
                toast.error(
                    overallProcessing
                        ? <>A task is already in progress.<br />Please wait for it to finish.</>
                        : <>Not enough processing credits.<br />Recharge to access this feature.</>,
                    { position: "top-right", autoClose: 7000 }
                );

                handleOpenTrialLimitPopup();
                return;
            }

            if (userText.trimStart().trimEnd() !== '' && userText !== null) {
                setGetting(true);
                setGenerated(false);
                setResponseSuccessful(false);
                setRetrieving(false);
                setSeconds(0);

                try {
                    setProcessing(true);
                    const response = await createThumbnailStoryboard({ videoIdeaText: userText });
                    if (response && response.data.storyboardId) {
                        setStoryboardData(null);
                        setUserText('');
                        navigate(`/thumbnail-visual-ideas?sId=${response.data.storyboardId}`);
                    }
                } catch (error) {
                    console.error("Error creating storyboard:", error);
                    toast.error('An error occurred while creating the idea board. Please try again.', {
                        position: "top-right",
                        autoClose: 7000,
                    });
                } finally {
                    setGetting(false);
                    setGenerated(true);
                    setResponseSuccessful(false);
                }
            } else {
                toast.error('Please enter some text first', {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
        } else {
            setGetting(false);
            setProcessing(false);
        }
    }

    // -----------Load Previous Storyboards----------
    // ----------------------------------------------

    const getStoryBoardItems = async () => {
        if (!mainUserId) return;

        const storyboardsRef = collection(db, 'Users', mainUserId, 'ThumbnailStoryBoards');
        const q = query(storyboardsRef, orderBy('date_created', 'desc'), limit(12));

        try {
            const querySnapshot = await getDocs(q);
            const items = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const filteredItems = items.filter(item => item.storyboards && Object.keys(item.storyboards).length > 0);
            setStoryboards(filteredItems);
            setLoadingStoryboards(false);
        } catch (error) {
            setLoadingStoryboards(false);
            console.error('Error fetching document:', error);
        }
    };

    useEffect(() => {
        getStoryBoardItems();
    }, [mainUserId]);

    // -----------Load Saved Storyboard-------------
    // ---------------------------------------------

    const handleLoadSavedStoryboard = (id, name) => {

        if (sId !== id) {
            setStoryboardData(null);
            navigate(`/thumbnail-visual-ideas?sId=${id}`);
        }

    };

    // --------------Get Fresh Ideas----------------
    // ---------------------------------------------

    const handleGetFreshIdeas = async () => {
        setGetting(true);

        const hasAccess = await checkUserAccess();

        if (hasAccess) {
            if (overallProcessing || aiCreditsLeft <= 180) {
                toast.error(
                    overallProcessing
                        ? <>A task is already in progress.<br />Please wait for it to finish.</>
                        : <>Not enough processing credits.<br />Recharge to access this feature.</>,
                    { position: "top-right", autoClose: 7000 }
                );
                return;
            }

            if (topTitle !== null && topTitle !== undefined && topTitle !== '') {
                setGetting(true);
                setGenerated(false);
                setResponseSuccessful(false);
                setRetrieving(false);
                setSeconds(0);
                try {
                    setProcessing(true);
                    const response = await createThumbnailStoryboard({ videoIdeaText: topTitle });
                    if (response && response.data.storyboardId) {
                        navigate(`/thumbnail-visual-ideas?sId=${response.data.storyboardId}`);
                    }
                } catch (error) {
                    setGetting(false);
                    setGenerated(true);
                    setResponseSuccessful(false);
                    console.error("Error creating storyboard:", error);
                    toast.error('An error occurred while creating the storyboard. Please try again.', {
                        position: "top-right",
                        autoClose: 7000,
                    });
                }
            } else {
                toast.error(<>Unable to process your current idea.<br />Please refresh & try again.</>, {
                    position: "top-right",
                    autoClose: 7000,
                });
            }
        } else {
            setGetting(false);
            setProcessing(false);
        }

    }

    // -----------Delete Saved Storyboard-----------
    // ---------------------------------------------


    const deleteSavedStoryboard = async (id) => {
        setDeleting(true);

        try {
            setStoryboards(storyboards.filter(board => board.id !== id));
            deleteModalClose();

            await deleteStoryboardItemFromStorage({ docId: id });
            ////console.log("Deletion from storage successful");

            toast("Idea Board Deleted", {
                icon: ({ theme, type }) => <FaTrashAlt className='text-green-500 h-3 w-3' />
            });

            getStoryBoardItems();
        } catch (error) {
            console.error("Error:", error.message);
            toast.error(error.message, {
                position: "top-right",
                autoClose: 7000,
            })
        } finally {
            setDeleting(false);
        }
    };

    const handleDeleteModalClose = (e) => {
        if (e.target.id === "st-item-delete-modal") {
            setDeleteModal(false);
        };
    }

    const deleteModalClose = (e) => {
        setDeleteModal(false);
    }

    const openDeleteModal = (name, id) => {
        setItemToDelete(name);
        setIdToDelete(id)
        setDeleteModal(true);
    }


    // ----------------Preferences------------------
    // ---------------------------------------------

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [preferences, setPreferences] = useState({ gender: '', race: '' });

    const handleOpenPreferencesModal = () => {
        setIsModalOpen(true);
    }

    const handleClosePreferencesModal = () => {
        setIsModalOpen(false);
    };

    const handleSavePreferences = (newPreferences) => {
        setPreferences(newPreferences);
        handleClosePreferencesModal();
    };

    // ---------------------------------------------
    // ---------------------------------------------

    useEffect(() => {
        document.title = 'Thumbnail Idea Boards';
        if (mainUserId === null) {
            navigate('/login')
        }
    }, [])

    const [isTrialLimitPopupOpen, setIsTrialLimitPopupOpen] = useState(false);

    const handleOpenTrialLimitPopup = () => {
        setIsTrialLimitPopupOpen(true);
        toast.warning(<>You have reached your limit for this feature. Upgrade for unlimited access.</>, {
            position: "top-right",
            autoClose: 7000,
        });
    };

    const handleCloseTrialLimitPopup = () => {
        setIsTrialLimitPopupOpen(false);
    };

    // ---------------------------------------------
    // ---------------------------------------------


    const handleProcessingStatusChange = useCallback((itemKey, isProcessing) => {
        // //console.log(`Item ${itemKey} processing status changed to: ${isProcessing}`);

        setProcessingItems(prev => {
            const newProcessingItems = { ...prev, [itemKey]: isProcessing };
            // //console.log('Current processing items:', newProcessingItems);

            const processingCount = Object.values(newProcessingItems).filter(status => status).length;
            // //console.log(`Number of items currently processing: ${processingCount}`);

            const allItemsReceived = Object.keys(newProcessingItems).length === 3;
            // //console.log(`All items received: ${allItemsReceived}`);

            const newOverallProcessing = processingCount > 0;
            // //console.log(`New overall processing status: ${newOverallProcessing}`);

            setOverallProcessing(newOverallProcessing);
            setProcessing(newOverallProcessing);

            // If we've received updates for all items and none are processing, we're done
            if (allItemsReceived && processingCount === 0) {
                // //console.log('All items finished processing');
                setOverallProcessing(false);
                setProcessing(false);
            }

            // if (storyboardData?.status === 'error') {
            //     setOverallProcessing(false);
            //     setProcessing(false);
            // }
            return newProcessingItems;
        });
    }, []);

    useEffect(() => {
        //console.log('Overall processing status changed to:', overallProcessing);
    }, [overallProcessing]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Storyboards | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Storybords | ThumbnailGeek" />
            </Helmet>

            <PageOnboardingGifPopup
                heading={'Creativity doesn\'t have to be hard.'}
                content={'Generate viral thumbnail styles and concepts for any video idea. Discover ideas that will make your videos stand out and cause viewers flock to your videos.'}
                imageSrc={thumbnail_onboarding_gif}
                buttonText={'Got it!'}
            />

            <TrialUserPopup isOpen={isTrialLimitPopupOpen} onClose={handleCloseTrialLimitPopup} />

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/testing-suite/upload'} />

            <div id="st-item-delete-modal" onClick={handleDeleteModalClose} className={`${deleteModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Are you sure?
                            </h3>
                            <button onClick={deleteModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                <div className="text-center mb-2">{itemToDelete} </div>
                                <div className="rounded-md bg-red-100 p-4">
                                    <div className="">
                                        <div className=" text-sm font-medium text-red-500">
                                            <ul role="list" className="space-y-1">
                                                <li>All associated images will also be deleted</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            deleting === false
                                ?
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                    <button onClick={() => deleteSavedStoryboard(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                                </div>
                                :
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="text-white bg-gray-800 inline-flex space-x-1 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        <span>Deleting</span>
                                        <DotDotDot className="pl-2" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className='bg-gray-50'>
                <SideBar selectedSideBarItem="thumbnail-visual-ideas" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold inline-flex`}>Find the Perfect Thumbnail Idea</h5>
                                <div className="inline-flex items-center">
                                    <span className="inline-flex items-center rounded-md bg-purple-100 ml-2 px-2 py-1 text-xs font-medium text-purple-700">
                                        BETA
                                    </span>
                                </div>
                            </div>
                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 mt-[61px] md:mt-[71px] lg:mt-[41px] bg-gray-50">
                            <div className="relative mt-8 w-full">
                                <form onSubmit={createStoryBoardMockUps}>
                                    <div className="mt-8">
                                        {
                                            generated === true || responseSuccessful === false || getting === true
                                                ?
                                                <></>
                                                :
                                                <div className={`flex flex-col justify-center items-center text-center mt-4 mb-4 text-gray-800 w-full`}>
                                                    <div className="w-60 h-60 mt-7">
                                                        <img src={require('../../../assets/svgs/writer.svg').default} alt='happyEmoji' />
                                                    </div>
                                                    {/* <p className="-mt-24">Explore & discover thumbnail concepts to use for your video idea</p> */}
                                                    <p className="-mt-24"> Quickly explore thumbnail concepts to use for your video idea</p>
                                                </div>
                                        }

                                        <div className="relative pt-2">
                                            <input autoFocus onChange={(e) => setUserText(e.target.value)} type="text"
                                                className="pl-12 p-4 bg-white text-gray-900 rounded-lg w-full pr-32 text-lg ring-1 ring-blue-400 mt-4 focus:ring-blue-400 active:ring-blue-400"
                                                placeholder="Enter title of your video or what happens in your video" />

                                            <div className="absolute right-2 top-8 cursor-pointer inline-flex items-center justify-center">

                                                {
                                                    getting === true || overallProcessing === true
                                                        ?
                                                        <button disabled className={`${getting === true || overallProcessing === true ? 'hover:cursor-not-allowed bg-gray-300' : 'hover:cursor-pointer hover:bg-blue-700 bg-blue-600'} 
                                                                     flex flex-row justify-center items-center rounded-lg py-3 px-4 text-white`}>
                                                            <span className='ml-1 text-sm'>Please Wait...</span>
                                                        </button>
                                                        :
                                                        <>
                                                            <button type='submit' className={`${userText === '' ? 'cursor-default bg-gray-300' : 'cursor-pointer hover:bg-blue-700 bg-blue-600'}  
                                                                     flex flex-row justify-center items-center rounded-lg  py-3 px-4 text-white`}>
                                                                <span className={`${userText === '' ? 'cursor-default ' : 'cursor-pointer'} ml-1 text-sm`}>
                                                                    Give me some ideas
                                                                </span>
                                                            </button>
                                                        </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </form>

                                <button onClick={handleOpenPreferencesModal} className={`absolute ${generated === true || responseSuccessful === false || getting === true ? 'top-[38px] left-3' : 'top-[250px] left-3'}  z-20 w-8 h-8 text-gray-400 hover:text-gray-700`}>
                                    <Tooltip content="Set Character Preferences">
                                        <BsPersonGear size={20} className='' />
                                    </Tooltip>
                                </button>

                                {
                                    (generated === true || getting === true) &&
                                    <div className="absolute -top-0 right-1/2 translate-x-1/2 text-xs text-gray-500 font-normal">
                                        <i>Hint: Enter the specific details of what happens in your video to get better results.</i>
                                    </div>
                                }

                                <PreferenceModal
                                    isOpen={isModalOpen}
                                    onClose={handleClosePreferencesModal}
                                    onSave={handleSavePreferences}
                                />
                            </div>


                            {/* Current Idea Board */}
                            {
                                getting === true
                                    ?
                                    (
                                        retrieving === true
                                            ?
                                            <div className="mt-2 relative">
                                                <div className='w-full flex flex-col items-center justify-center relative'>

                                                    <div className='w-72 '>
                                                        <Lottie animationData={thinking} loop={true} />
                                                    </div>
                                                    <div className='flex flex-col items-center justify-center font-normal text-[14px] -mt-12 ml-7 mb-5 text-gray-500'>
                                                        <div className='flex text-center'>Ok. Let's see...</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="mt-2 relative">
                                                {
                                                    <div className='w-full flex flex-col items-center justify-center relative'>
                                                        <div className='w-72 '>
                                                            <Lottie animationData={thinking} loop={true} />
                                                        </div>
                                                        <div className='flex flex-col items-center justify-center font-normal text-[14px] -mt-12 mb-5 text-gray-500'>
                                                            <div className='flex text-center'>What to do... Hmmm...</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                    )
                                    :
                                    <>
                                        {
                                            responseSuccessful === false && generated === true
                                                ?
                                                <div className='w-full flex flex-cols items-center justify-center relative mt-4'>
                                                    <div className='w-72 h-72'>
                                                        <Lottie animationData={error} loop={true} />
                                                    </div>
                                                    <h2 className='absolute bottom-[56px] right-1/2 translate-x-1/2 font-normal text-[14px] mb-5 text-gray-500'>Something went wrong... Try again later.</h2>
                                                </div>
                                                :
                                                <div className="w-full mt-4">
                                                    {generated && (
                                                        <div className='w-full flex flex-col items-center justify-center transition-all duration-200'>
                                                            <div className="w-full flex flex-row items-center justify-between mb-8 ring-1 ring-blue-200 p-2 rounded-lg bg-blue-50">
                                                                <div className="flex flex-col pl-2">
                                                                    <p className="font-bold mr-2 text-gray-800 line-clamp-1">{topTitle}</p>
                                                                    <p className="text-gray-500 text-xs">thumbnail concepts & styles</p>
                                                                </div>
                                                                <div className="flex flex-row items-center space-x-3">
                                                                    {
                                                                        overallProcessing === true
                                                                            ?
                                                                            <button disabled type="button" className={`rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 
                                                                                shadow-sm bg-gray-300 ring-0 cursor-not-allowed hover:bg-gray-300 disabled:opacity-50`}>
                                                                                <Spinner />
                                                                            </button>
                                                                            :
                                                                            <button onClick={handleGetFreshIdeas} type="button" className={`rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-gray-50
                                                                                    ${overallProcessing === false
                                                                                    ? 'bg-white ring-1 ring-blue-400 hover:bg-gray-50'
                                                                                    : 'bg-gray-300 ring-0 cursor-not-allowed hover:bg-gray-300'
                                                                                } disabled:opacity-50`}>
                                                                                {overallProcessing ? <Spinner /> : 'Get Fresh Ideas'}
                                                                            </button>
                                                                    }

                                                                </div>
                                                            </div>

                                                            <div className="w-full flex flex-wrap transition-all duration-200">
                                                                {storyboardData?.storyboards && Object.entries(storyboardData.storyboards).map(([key, storyboard]) => (
                                                                    <StoryboardItem
                                                                        key={key}
                                                                        storyboardId={storyboardId}
                                                                        storyboardData={storyboardData}
                                                                        storyboard={storyboard}
                                                                        itemKey={key}
                                                                        inititated={generated}
                                                                        overallProcessing={overallProcessing}
                                                                        onProcessingStatusChange={handleProcessingStatusChange}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                        }
                                    </>
                            }

                            {/* Saved Storyboards */}
                            <div className='mt-24 pt-8 border-t'>
                                <h2 className="text-base font-med mb-4 text-gray-600">Previous Thumbnail Visual Ideas</h2>
                                <StoryboardsList
                                    storyboards={storyboards}
                                    handleLoadSavedStoryboard={handleLoadSavedStoryboard}
                                    openDeleteModal={openDeleteModal}
                                    loadingStoryboards={loadingStoryboards}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {
                imagesLoaded.length > 0
                    ?
                    <FeedbackBanner currentPageUrl={'title-builder'} />
                    :
                    <></>
            }
        </>
    )
});

export default ThumbnailStoryboards