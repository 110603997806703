import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import { NavLink, useNavigate, useLocation, Link, } from 'react-router-dom';
import axios from 'axios';
import { PremiumCredentials } from '../../context/PremiumContext';
import { UserAuth } from '../../context/AuthContext'
import { AiFillFire, AiOutlinePicture } from 'react-icons/ai'
import { IoIosImages } from 'react-icons/io'
import { RxGear, RxChatBubble, RxMagnifyingGlass } from 'react-icons/rx'
import { TbBulb, TbCrown, TbMicroscope } from 'react-icons/tb'
import { FaBucket, FaRegNewspaper, FaVideo } from 'react-icons/fa'
import { BsBucket, BsPalette2, BsQuestionCircle } from "react-icons/bs";
import { db, storage } from '../../firebase-config';
import { getFunctions, httpsCallable } from "firebase/functions";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, } from "firebase/firestore";
import { IoSchoolOutline, IoHomeOutline, IoLibraryOutline, IoBrushOutline, IoCloudUploadOutline, IoAnalytics, IoImagesOutline } from 'react-icons/io5'
import logo from '../../assets/logo_full_32.png';
import { MdCenterFocusWeak, MdImageSearch } from 'react-icons/md'
import { toast } from 'react-toastify';
import { SHA256 } from 'crypto-js';
import Tooltip from './Tooltip';
import ThumbnailUploadDialog from './ThumbnailUploadDialog';
import { RiVideoLine } from 'react-icons/ri';
import TutorialItems from './TutorialItems';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';  // Use ISO week plugin
import PremiumBlocker from './PremiumBlocker';
import { CgClose, CgMediaLive, CgShoppingCart } from 'react-icons/cg';
import { SlMagnifier } from 'react-icons/sl';
import { GiBulb, GiMagnifyingGlass } from 'react-icons/gi';
import { BiAnalyse, BiPalette } from 'react-icons/bi';
import WelcomePopup from './WelcomePopup';

dayjs.extend(isoWeek);

const SideBar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const loginParam = params.get('login');
    const firstEntry = params.get('first-entry');
    const { user, logOut } = UserAuth();
    const mainUserId = localStorage.getItem("mainUserId");
    const chId = localStorage.getItem("channelId");
    const loginCookieSetDate = localStorage.getItem("login_cookie_setDate");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const [uploadPopup, setUploadPopup] = useState(false)
    const { premiumStatus, premiumTier, accountStatus, aiQuota, aiCreditsLeft, accessLevel, channelLinked, checked, ytUsername, bannedStatus, firstOpenPopup, } = PremiumCredentials();
    const [selectType, setSelectType] = useState(0);
    const [selectedImage, setSelectedImage] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [notPremium, setNotPremium] = useState(false);
    const [firstUsePopup, setFirstUsePopup] = useState(false);

    useEffect(() => {
        if (bannedStatus === true) {
            navigate('/restricted')
        }
    }, [bannedStatus]);

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
                    document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
                    // console.log('Token set : ' + response.data.token);

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, mainUserId]);

    useEffect(() => {
        if ((ytUsername === null || ytUsername === undefined || ytUsername === "") && checked === true) {
            getChannelUsername();
        }
    }, [ytUsername, checked]);

    useEffect(() => {
        if (firstOpenPopup === true) {
            setFirstUsePopup(true);
        }
    }, [firstOpenPopup]);

    const getChannelUsername = async () => {
        try {
            const apiKey = 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s';
            const response = await axios.get(
                `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${chId}&key=${apiKey}`
            );

            const channelData = response.data.items[0];
            if (channelData) {
                const customUrl = channelData.snippet.customUrl;
                if (customUrl) {
                    const userDoc = doc(db, "Users", mainUserId)
                    const data = {
                        yt_username: customUrl,
                    }
                    setDoc(userDoc, data, { merge: true }).then(res => {
                        console.log('Successfully added yt_username', res);
                    });
                } else {
                    const userDoc = doc(db, "Users", mainUserId)
                    const data = {
                        ytUsername: null
                    }
                    setDoc(userDoc, data, { merge: true });
                }
            }
        } catch (error) {
            console.error('Error', error);
        }
    };

    // useEffect(() => {
    //     console.log(premiumStatus);
    //     console.log(accessLevel);

    //     if (mainUserId !== null && mainUserId !== undefined) {
    //         if (checked === true) {
    //             // if (accessLevel === undefined || accessLevel === null) {
    //             //     navigate(`/mid/${mainUserId}`);
    //             //     return;
    //             // }

    //             // if (premiumStatus === 'NOT_INITIATED') {
    //             //     handleLogOut();

    //             //     return;
    //             // }

    //             if (channelLinked === false && premiumStatus === 'NOT_INITIATED') {
    //                 navigate(`/first-steps/connect/${mainUserId}`);
    //                 return;
    //             }

    //             if (channelLinked === true && premiumStatus === 'NOT_INITIATED') {
    //                 // navigate(`/first-steps/billing/plans/${mainUserId} `);
    //                 setNotPremium(true);
    //                 return;
    //             }

    //             if (premiumStatus === 'PAST_DUE' || premiumStatus === 'INCOMPLETE' || premiumStatus === 'UNPAID') {
    //                 const functions = getFunctions();
    //                 const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    //                 addMessage({
    //                     returnUrl: window.location.origin + '/account-settings',
    //                     locale: 'auto',
    //                 })
    //                     .then((result) => {
    //                         const data = result.data;
    //                         if (data.url) {
    //                             window.open(data.url);
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         // Getting the Error details.
    //                         const code = error.code;
    //                         const message = error.message;
    //                         const details = error.details;
    //                         // ...
    //                         console.error(code);
    //                         console.error(message);
    //                         console.error(details);
    //                         window.open('https://billing.stripe.com/p/login/bIY4inb1jcps1u8dQQ');
    //                     });
    //                 return;
    //             }

    //             if (premiumStatus === 'INCOMPLETE_EXPIRED' || premiumStatus === 'CANCELED') {
    //                 navigate(`/restricted`);
    //                 return;
    //             }

    //             if (channelLinked === true && premiumStatus === 'CANCELED' && premiumStatus !== 'PREMIUM' && premiumStatus !== 'TRIALING') {
    //                 navigate(`/restricted`);
    //                 return;
    //             }
    //         }
    //     } else {
    //         handleLogOut();
    //     }
    // }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, mainUserId]);

    // const fetchChannelUsername = async (id) => {
    //     let API_KEY = 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s'
    //     try {
    //         const response = await axios.get(
    //             `https://www.googleapis.com/youtube/v3/channels`,
    //             {
    //                 params: {
    //                     part: 'snippet',
    //                     id,
    //                     key: API_KEY,
    //                 },
    //             }
    //         );
    //         const channel = response.data.items[0];
    //         if (channel) {
    //             setChannelUsername(channel.snippet.title); // The channel's title or username
    //             setError(null);
    //         } else {
    //             setError('Channel not found');
    //             setChannelUsername('');
    //         }
    //     } catch (error) {
    //         setError('Failed to fetch channel information');
    //         setChannelUsername('');
    //     }
    // };

    const isStoredDateGreaterThanDays = (storedDate, noOfDays) => {
        if (storedDate) {
            // Convert the stored date to a JavaScript Date object
            const storedDateObj = new Date(storedDate);
            const currentDate = new Date();
            const timeDifference = currentDate - storedDateObj;
            const oneDayInMillis = noOfDays * 24 * 60 * 60 * 1000;
            return timeDifference > oneDayInMillis;
        } else {
            return false;
        }
    }

    const handleLogOut = async () => {
        try {
            console.log("Logout triggered");
            await logOut();
        } catch (error) {
            //console.log(error)
        }
    }

    const handleUploadClicked = async () => {
        setUploadPopup(true);
    }

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem("currentUser"));
        if (user == null && savedUser == null) {
            navigate("/login");
        }
    }, [user]);

    useEffect(() => {
        // updateStreak();

        const checkCookie = (name) => {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(`${name}=`)) {
                    return true;
                }
            }
            return false;
        };

        if (mainUserId) {
            if (!checkCookie('TG_AT') && !isStoredDateGreaterThanDays(loginCookieSetDate, 1)) {
                updateToken();
            }
        }

        if (!checkCookie('TG_Avatar')) {
            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
        }

        if (!checkCookie('TG_ChannelName')) {
            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
        }
    }, []);

    // const updateStreak = async () => {
    //     try {
    //         // Retrieve streak data from local storage
    //         const storedStreakData = JSON.parse(localStorage.getItem('streakData')) || {};

    //         const currentWeekStreak = getWeekNumber(new Date());

    //         if (!storedStreakData.currentWeekStreak ||
    //             !storedStreakData.date ||
    //             currentWeekStreak !== storedStreakData.currentWeekStreak
    //             || isDifferentDay(new Date(), new Date(storedStreakData.date))) {

    //             let newCurrentWeekStreak;

    //             if (!storedStreakData.currentWeekStreak) {
    //                 // First time saving streak data, initialize with default values
    //                 newCurrentWeekStreak = 1;
    //             } else if (currentWeekStreak !== storedStreakData.currentWeekStreak) {
    //                 // Week has changed, reset streak to 1
    //                 newCurrentWeekStreak = 1;

    //                 // Access Firestore to get the current data
    //                 const docRef = doc(db, 'Users', mainUserId);
    //                 const docSnap = await getDoc(docRef);

    //                 if (docSnap.exists()) {
    //                     const data = docSnap.data();
    //                     const currentWeekFirestore = getWeekNumber(new Date(data.lastUpdateDate));

    //                     if (currentWeekStreak !== currentWeekFirestore) {
    //                         const newLongestWeekStreak = Math.max(newCurrentWeekStreak, data.longestWeekStreak);

    //                         // Update Firestore document
    //                         await updateDoc(docRef, {
    //                             currentWeekStreak: newCurrentWeekStreak,
    //                             longestWeekStreak: newLongestWeekStreak,
    //                         });

    //                         console.log('Streaks updated for user:', mainUserId);
    //                     }
    //                 }

    //                 console.log('Firestore updated for user:', mainUserId);
    //             } else {
    //                 // Same week, do nothing
    //             }

    //             // Update local storage
    //             localStorage.setItem('streakData', JSON.stringify({
    //                 currentWeekStreak: newCurrentWeekStreak || 1,
    //                 longestWeekStreak: storedStreakData.longestWeekStreak || 1,
    //                 date: new Date().toISOString(),
    //             }));

    //             console.log('Streaks updated locally for user:', mainUserId);
    //         }

    //         console.log('mainUserId:', mainUserId);
    //     } catch (error) {
    //         console.error('Error updating streaks:', error);
    //     }
    // };

    // const updateUserUsageOncePerSession = (userId) => {
    //     const lastUpdated = sessionStorage.getItem('lastActiveDateUpdated');
    //     if (!lastUpdated || Date.now() - Number(lastUpdated) > 86400000) { // 1 day
    //         const userRef = doc(db, 'Users', userId);
    //         updateDoc(userRef, {
    //             last_active_date: new Date()
    //         }).then(() => {
    //             sessionStorage.setItem('lastActiveDateUpdated', Date.now().toString());
    //         });
    //     }
    // };

    // const updateStreak = async () => {
    //     const userRef = doc(db, 'Users', mainUserId);
    //     const userSnap = await getDoc(userRef);

    //     if (userSnap.exists()) {
    //         const userData = userSnap.data();
    //         let lastActive = null;
    //         if (userData.last_active_date != null) {
    //             lastActive = dayjs(userData.last_active_date.toDate());
    //         }
    //         const today = dayjs();
    //         let { currentWeekStreak, longestWeekStreak } = userData;
    //         let updateData = {};

    //         if (lastActive) {
    //             if (today.isoWeek() === lastActive.isoWeek()) {
    //                 // User already active this week, no update necessary
    //                 // console.log("No update needed: user already active this week.");
    //                 return;
    //             } else if (today.isoWeek() === lastActive.add(1, 'week').isoWeek()) {
    //                 // User is active in the consecutive week
    //                 currentWeekStreak += 1;
    //                 updateData.currentWeekStreak = currentWeekStreak;

    //             } else {
    //                 // More than a week gap
    //                 currentWeekStreak = 1;
    //                 updateData.currentWeekStreak = currentWeekStreak;
    //             }

    //             // Update the longest streak if the current streak is longer
    //             if (currentWeekStreak > longestWeekStreak) {
    //                 longestWeekStreak = currentWeekStreak;
    //                 updateData.longestWeekStreak = longestWeekStreak;
    //             }

    //             // Include last active date update
    //             updateUserUsageOncePerSession(mainUserId);

    //             // Only update Firestore if there is actual data to update
    //             if (Object.keys(updateData).length > 0) {  // Check if there's data
    //                 await updateDoc(userRef, updateData);
    //                 console.log("Firestore updated with new streak data:", updateData);
    //             } else {
    //                 console.log("No significant update required.");
    //             }
    //         } else {
    //             updateUserUsageOncePerSession(mainUserId);
    //             updateData.currentWeekStreak = 1;
    //             updateData.longestWeekStreak = 1;
    //             await updateDoc(userRef, updateData);
    //         }

    //     } else {
    //         console.error('User does not exist');
    //     }
    // }

    const isDifferentDay = (date1, date2) => {
        return date1.toDateString() !== date2.toDateString();
    };

    const getWeekNumber = (date) => {
        const today = new Date(date.getTime());
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() + 3 - ((today.getDay() + 6) % 7));
        const week1 = new Date(today.getFullYear(), 0, 4);
        return 1 + Math.round(((today - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    };

    const updateToken = (e) => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
        }
    }

    const redirectToPayment = async () => {
        navigate('/pricing');
    }



    // const {
    //     getRootProps,
    //     getInputProps,
    //     fileRejections,
    // } = useDropzone({
    //     accept: 'image/jpeg, image/jpg, image/png, image/gif',
    //     maxFiles: 1,
    //     maxSize: 2 * 1024 * 1024, // 2 MB in bytes
    //     onDrop: acceptedFiles => {

    //         if (fileRejections.length === 0) {
    //             setSelectedImage(acceptedFiles.map(file => Object.assign(file, {
    //                 preview: URL.createObjectURL(file)
    //             })));
    //             // setUploadBtnActive(true);
    //             // openUploadModal();
    //         } else {
    //             // Handle file rejection error if needed
    //             console.log('File rejected:', fileRejections);
    //         }
    //     }
    // });

    // const modalClose = (e) => {
    //     if (e.target.id === "thumbnail-upload-modal") {
    //         setUploadPopup(false);
    //     }
    // }
    // const uploadModalClose = (e) => {
    //     setUploadPopup(false);
    // }

    return (
        <>
            <ThumbnailUploadDialog show={uploadPopup} isVisible={uploadPopup} onClose={() => setUploadPopup(false)} pageUrl={'/testing-suite/upload'} />

            <TutorialItems isOpen={isModalOpen} onClose={() => setModalOpen(false)} initialOpenItemId={0} />

            <WelcomePopup isVisible={firstUsePopup} onClose={() => setFirstUsePopup(false)} />

            <div>
                <div className="hidden lg:flex px-6 py-4 mt-2">
                    <NavLink className="fixed text-2xl font-bold text-gray-800 lg:text-2xl hover:text-gray-700" to="/dashboard">
                        {/* <img className='md:h-5 lg:h-6 mt-1' src={logo} /> */}
                        <div className='relative w-2/3 mt-2 '>
                            <img className='w-full h-full' src={logo} />
                        </div>
                    </NavLink>
                </div>

                <div className='ml-2 pb-4'>
                    <div className={`lg:mt-[74px] overflow-y-scroll border rounded-md fixed z-10 top-[18px] lg:top-0 px-2 lg:px-4 w-[55px] lg:w-[260px] flex flex-col justify-between h-[88vh] border-r 
                ${props.darkMode === false ? 'bg-white' : 'bg-[#202124] border-gray-600'}  transition duration-300 lg:ml-0 lg:w-[20%] xl:w-[270px] 2xl:w-[270px] 2xl:max-w-[270px]`}>
                        <div>
                            <ul className="space-y-[5px] font-medium tracking-[-0.014em] mt-5 w-full">
                                <li className={`p-[1px] bg-gradient-to-r from-blue-600 to-[#FC2959] relative space-x-2 rounded-lg bg-gray-300 mb-6`}>
                                    <button onClick={() => setUploadPopup(true)} className='px-2 py-2 w-full cursor-pointer flex flex-col items-center justify-center rounded-[0.395rem] bg-slate-50 hover:bg-white' >
                                        <IoCloudUploadOutline className='lg:hidden h-5 w-5' />
                                        <span className="hidden lg:flex font-medium group-hover:text-gray-700 text-blue-800 lg:text-[12px]">Upload your thumbnail</span>
                                    </button>
                                </li>

                                <li>
                                    <NavLink to="/dashboard" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                        ${props.selectedSideBarItem === 'dashboard' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <IoHomeOutline className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Dashboard</span>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/channel-audit" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                        ${props.selectedSideBarItem === 'channel-audit' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <BiAnalyse className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Channel Insights</span>
                                        <span className={`hidden text-[10px] ${props.selectedSideBarItem === 'channel-audit' ? 'bg-gray-700' : 'bg-green-500 lg:flex'} rounded-md px-1 py-[2px] text-white`}>New</span>

                                    </NavLink>
                                </li> */}

                                <li>
                                    <hr />
                                </li>

                                <li className='hidden lg:flex '>
                                    <p className='pl-0 text-[10px] uppercase text-neutral-400'>CREATE</p>
                                </li>

                                <li>
                                    <NavLink to="/title-builder" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'title-composer' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <IoBrushOutline className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Improve Your Titles</span>
                                        <span className={`hidden lg:flex text-[12px] ${props.selectedSideBarItem === 'title-composer' ? 'bg-gray-700' : 'bg-purple-500'} rounded-md px-2 text-white`}>AI</span>
                                        {/* {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'title-composer' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        } */}
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/thumbnail-visual-ideas" className={`relative px-2 py-2 cursor-pointer flex items-center rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'thumbnail-visual-ideas' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <CgMediaLive className='h-5 w-5 mr-2' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] mr-2">Get Viral Thumbnail Ideas</span>
                                        <span className={`hidden lg:flex text-[12px] ${props.selectedSideBarItem === 'thumbnail-visual-ideas' ? 'bg-gray-700' : 'bg-purple-500'} rounded-md px-2 text-white mr-1`}>AI</span>
                                        {/* <span className={`hidden text-[10px] ${props.selectedSideBarItem === 'thumbnail-visual-ideas' ? 'bg-gray-700' : 'bg-green-500 lg:flex'} rounded-md px-1 py-[2px] text-white`}>New</span> */}
                                        {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'thumbnail-visual-ideas' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        }
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/thumbnail-ideas" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'thumbnail-planner' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <TbBulb className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Describe Thumbnail Visuals</span>
                                        <span className={`hidden lg:flex text-[12px] ${props.selectedSideBarItem === 'thumbnail-planner' ? 'bg-gray-700' : 'bg-purple-500'} rounded-md px-2 text-white`}>AI</span>
                                        {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'thumbnail-planner' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        }
                                    </NavLink>
                                </li>

                                {/* Analyse */}
                                <li>
                                    <NavLink to="/thumbnail-analysis/upload" className={`relative px-2 py-2 cursor-pointer  flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                        ${props.selectedSideBarItem === 'thumbnail-analysis' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <GiMagnifyingGlass className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] xl:">Analyze Your Thumbnail</span>
                                        <span className={`hidden lg:flex text-[12px] ${props.selectedSideBarItem === 'thumbnail-analysis' ? 'bg-gray-700' : 'bg-purple-500'} rounded-md px-2 text-white`}>AI</span>
                                        {/* {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'thumbnail-analysis' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        } */}
                                    </NavLink>
                                </li>


                                {/* <li>
                                    <NavLink to="/testing-suite/upload" className={`relative px-2 py-2 flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'testing-suite' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <IoIosImages className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Compare Your Thumbnails</span>
                                        {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'testing-suite' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        }
                                    </NavLink>
                                </li> */}

                                <li>
                                    <hr />
                                </li>

                                {/* <li className='hidden lg:flex '>
                                    <p className='pl-0 text-[10px] uppercase text-neutral-400'>Compare & Analyze</p>
                                </li> */}


                                {/* <li>
                                    <NavLink to="/focus-boards" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'focus-boards' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <MdCenterFocusWeak className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 text-[12px] ">Focus Board Comparison</span>
                                        {
                                            accessLevel === 0
                                                ?
                                                <span className={`hidden lg:flex text-[14px] ${props.selectedSideBarItem === 'focus-boards' ? 'bg-gray-700 text-yellow-500' : 'bg-yellow-500 text-white'} rounded-full p-[2px] text-white`}>
                                                    <TbCrown />
                                                </span>
                                                :
                                                <></>
                                        }
                                    </NavLink>
                                </li> */}

                                {/* <li>
                                    <hr />
                                </li> */}

                                <li className='hidden lg:flex '>
                                    <p className='pl-0 text-[10px] uppercase text-neutral-400'>IMPROVE</p>
                                </li>

                                <li>
                                    <NavLink to="/viral-library" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'viral-library' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <IoLibraryOutline className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] ">Viral Thumbnail Library</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/buckets" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'buckets' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <BsBucket className='h-[18px] w-[18px] mr-[2px]' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 text-[12px] ">Collect Thumbnails (Youtube)</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/tutorials?section=premium-downloads" className={`relative px-2 py-2 cursor-pointer  flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                        ${props.selectedSideBarItem === 'tutorials' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <IoSchoolOutline className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] xl:">Premium Downloads</span>
                                    </NavLink>
                                </li>

                                {/* <li>
                                <NavLink to="/referrals" className={`relative px-2 py-2 cursor-pointer  flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                ${props.selectedSideBarItem === 'referrals' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <FiUsers className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px] xl:">Referrals</span>
                                    </NavLink>
                                </li> */}

                                {/* <li>
                                    <NavLink to="/billing/plans" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                    ${props.selectedSideBarItem === 'billing' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <BsCreditCard className='h-5 w-5' />
                                        <span className="group-hover:text-gray-700 lg:text-[12px] ">Billing</span>
                                    </NavLink>
                                </li> */}

                                <li>
                                    <hr />
                                </li>

                                <li className='hidden lg:flex '>
                                    <p className='pl-0 text-[10px] uppercase text-neutral-400'>User Tools</p>
                                </li>

                                <li>
                                    <NavLink to="/account-settings" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'settings' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <RxGear className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px]">Settings</span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/pricing" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                            ${props.selectedSideBarItem === 'pricing' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <CgShoppingCart className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px]">Pricing - Buy Credits</span>
                                        {/* <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px]">Buy processing credits</span> */}
                                    </NavLink>
                                </li>

                                <li>
                                    <button onClick={() => setModalOpen(true)} className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r text-gray-600`}>
                                        <BsQuestionCircle className='h-5 w-5' />
                                        <span className="hidden lg:flex group-hover:text-gray-700 lg:text-[12px]">How to use ThumbnailGeek</span>
                                    </button>
                                </li>
                                {/* <li>
                                    <NavLink to="/account-settings" className={`relative px-2 py-2 cursor-pointer flex items-center space-x-2 rounded-lg bg-gradient-to-r 
                                    ${props.selectedSideBarItem === 'account-settings' ? 'from-blue-600 to-[#FC2959] text-white' : 'text-gray-600'}`}>
                                        <GiHorizonRoad className='h-5 w-5' />
                                        <span className="group-hover:text-gray-700 lg:text-[12px] ">Roadmap</span>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>

                        {/* <div className="lg:px-8 pt-4 flex flex-col space-y-3 lg:py-2 lg:space-y-0 lg:flex-row justify-between items-center border-t hover:cursor-pointer">
                            <Tooltip content='Email Support' position='top'>
                                <a href="mailto:hello@thumbnailgeek.com" className="text-gray-700 hover:text-gray-500 mt-6 lg:mt-0" aria-label="Email Support">
                                    <RxChatBubble className='h-5 w-5' />
                                </a>
                            </Tooltip>

                            <NavLink to="/referrals" aria-label="Earn with ThumbnailGeek" className="text-gray-700 hover:text-gray-500 mt-6 lg:mt-0">
                                <Tooltip content='Earn with ThumbnailGeek' position='top'>
                                    <div className="text-gray-700 hover:text-gray-500 rounded-md p-1 px-[6px] bg-purple-500 hover:bg-purple-600 mt-6 lg:mt-0">
                                        <div className="text-[10px] text-white">FREE</div>
                                    </div>
                                </Tooltip>
                            </NavLink>

                            <a href="https://headwayapp.co/thumbnailgeek-changelog" target="_blank" rel="noopener noreferrer"
                                className="text-gray-700 pt-4 lg:pt-0 hover:text-gray-500 mt-6 lg:mt-0" aria-label="Updates & New Features">
                                <Tooltip content='Updates & New Features' position='top'>
                                    <FaRegNewspaper className='h-5 w-5' />
                                </Tooltip>
                            </a>

                        </div> */}

                        <div className={`${premiumStatus === 'TRIALING' || aiCreditsLeft <= 10 ? 'mb-5' : ''} pt-4 flex flex-col space-y-3 lg:py-2 lg:space-y-0 lg:flex-row justify-between items-center border-t hover:cursor-pointer`}>
                            {
                                premiumStatus === 'TRIALING'
                                    ?
                                    <div className="w-full cursor-default">
                                        <NavLink to="/pricing" className='w-full'>
                                            <div className={`w-full inline-flex items-center lg:space-x-1 text-xs font-normal text-[12px] ${aiCreditsLeft < 200 ? 'bg-orange-100' : 'bg-purple-100'} rounded-[4px] px-2 py-2 text-gray-800`}>
                                                <AiFillFire className='hidden lg:block mt-[1px] w-4 h-4' />
                                                <div className={`hidden lg:block w-full ${aiCreditsLeft < 200 ? 'bg-orange-300' : 'bg-purple-300'} rounded-full h-2.5`}>
                                                    {
                                                        aiCreditsLeft < 150
                                                            ?
                                                            (
                                                                aiCreditsLeft < 55 ?
                                                                    <div className="bg-orange-600 h-2.5 rounded-full" style={{ width: '7%' }}></div>
                                                                    :
                                                                    <div className="bg-orange-600 h-2.5 rounded-full" style={{ width: `${((300 - aiCreditsLeft) / 300 * 100) - 30}%` }}></div>
                                                            )
                                                            :
                                                            (
                                                                aiCreditsLeft > 20000
                                                                    ? <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${(aiCreditsLeft / aiQuota) * 100}%` }}></div>
                                                                    : <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${((aiCreditsLeft / aiQuota) * 100) - 20}%` }}></div>
                                                            )
                                                    }
                                                </div>
                                                <p>{aiCreditsLeft !== undefined && aiCreditsLeft !== null && aiCreditsLeft > 0 ? aiCreditsLeft : 0}</p>

                                            </div>
                                        </NavLink>
                                        <p className='text-xs text-gray-500 w-full text-center mt-2'>
                                            {
                                                aiCreditsLeft < 150 ? 'Upgrade for more credits' : 'Trial Credits'
                                            }
                                        </p>
                                    </div>
                                    :
                                    <div className="w-full cursor-default">
                                        <NavLink to="/pricing" className='w-full'>
                                            <div className={`w-full inline-flex items-center lg:space-x-1 text-xs font-normal text-[12px] ${aiCreditsLeft < 200 ? 'bg-orange-100' : 'bg-purple-100'} rounded-[4px] px-2 py-2 text-gray-800`}>
                                                <AiFillFire className='hidden lg:block mt-[1px] w-4 h-4' />
                                                <div className={`hidden lg:block w-full ${aiCreditsLeft < 200 ? 'bg-orange-300' : 'bg-purple-300'} rounded-full h-2.5`}>
                                                    {
                                                        aiCreditsLeft < 200
                                                            ?
                                                            (
                                                                aiCreditsLeft < 55 ?
                                                                    <div className="bg-orange-600 h-2.5 rounded-full" style={{ width: '7%' }}></div>
                                                                    :
                                                                    <div className="bg-orange-600 h-2.5 rounded-full" style={{ width: `${((300 - aiCreditsLeft) / 300 * 100) - 30}%` }}></div>
                                                            )
                                                            :
                                                            (
                                                                aiCreditsLeft > 20000
                                                                    ? <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${(aiCreditsLeft / aiQuota) * 100}%` }}></div>
                                                                    : <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${((aiCreditsLeft / aiQuota) * 100) - 20}%` }}></div>
                                                            )
                                                    }
                                                </div>
                                                <p>{aiCreditsLeft !== undefined && aiCreditsLeft !== null && aiCreditsLeft > 0 ? aiCreditsLeft : 0}</p>

                                            </div>
                                        </NavLink>
                                        <p className='text-xs text-gray-500 w-full text-center mt-2'>
                                            {
                                                aiCreditsLeft < 200 ? 'Upgrade for more credits' : 'Processing Credits'
                                            }
                                        </p>
                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="fixed z-50 inset-x-0 bottom-0">
                {
                    premiumStatus === 'TRIALING' && accessLevel === 5 &&
                    (
                        <div className="flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                            <p className="text-sm leading-6 text-white">
                                <Link to="/pricing">
                                    <strong className="font-semibold">50% off Trial Period Deal 🔥</strong>
                                    <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                        <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <span className=''>Unlock the power of ThumbnailGeek while saving 50%. Offer valid only in Trial period</span>
                                    <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                        <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <button className='rounded p-1 px-2 bg-white text-black'>Get it Now</button>
                                </Link>
                            </p>
                            <div className="flex flex-1 justify-end opacity-0">
                                <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                                    <span className="sr-only">Dismiss</span>
                                    <CgClose className="h-5 w-5 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    )
                }
            </div> */}

            {
                (premiumStatus === 'TRIALING' && checked === true && accessLevel === 5 && aiCreditsLeft > 9) ?
                    (
                        <div className="fixed z-50 inset-x-0 bottom-0">
                            <div className="flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                                <p className="text-sm leading-6 text-white">
                                    <Link to="/pricing">
                                        <strong className="font-semibold text-yellow-300">50% off Trial Period Deal 🔥</strong>
                                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <span className=''>Unlock the power of ThumbnailGeek while saving 50%. Offer valid only in Trial period</span>
                                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <button className='rounded p-1 px-2 bg-blue-500 hover:bg-blue-600 text-white'>Get it Now</button>
                                    </Link>
                                </p>
                                <div className="flex flex-1 justify-end opacity-0">
                                    <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                                        <span className="sr-only">Dismiss</span>
                                        <CgClose className="h-5 w-5 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    (
                        aiCreditsLeft < 10 && checked === true &&
                        <div className="fixed z-50 inset-x-0 bottom-0">

                            <div className="flex items-center gap-x-6 bg-yellow-300 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                                <p className="text-sm leading-6 text-gray-900">
                                    <Link to="/pricing">
                                        <strong className="font-bold">You've run out of processing credits 💔 </strong>
                                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <span className=''>Buy Credits Now to unlock powerful tools to help grow your Youtube channel</span>
                                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <button className='rounded p-1 px-2 bg-blue-500 text-white'>Buy Now</button>
                                    </Link>
                                </p>
                                <div className="flex flex-1 justify-end opacity-0">
                                    <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                                        <span className="sr-only">Dismiss</span>
                                        <CgClose className="h-5 w-5 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>

                        </div>
                    )
            }

            <PremiumBlocker active={notPremium} />
        </>
    )
}

export default SideBar