import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import ConfettiExplosion from 'react-confetti-explosion';
import { CgBitbucket } from 'react-icons/cg';
import { FaCheckCircle } from 'react-icons/fa';
import { GiBeachBucket } from 'react-icons/gi';
import { PremiumCredentials } from '../../context/PremiumContext';

const BucketLimit = ({ isOpen, onClose }) => {
    let navigate = useNavigate();
    const { accessLevel, aiCreditsLeft, premiumStatus, bucketCount } = PremiumCredentials();
    let accessLevelName;

    switch (accessLevel) {
        case 0:
            accessLevelName = "Tiny";
            break;
        case 1:
            accessLevelName = "Basic pack";
            break;
        case 2:
            accessLevelName = "Max pack";
            break;
        case 3:
            accessLevelName = "Pro pack";
            break;
        case 4:
            accessLevelName = "Test";
            break;
        case 5:
            accessLevelName = "Trial";
            break;
        case 6:
            accessLevelName = "Plus";
            break;
        case 7:
            accessLevelName = "Premium";
            break;
        case 8:
            accessLevelName = "Enterprise";
            break;
        default:
            accessLevelName = "Unknown";
    }

    const redirectToPayment = async () => {
        navigate('/pricing');
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-2xl flex flex-col items-center">
                <button
                    onClick={onClose}
                    type="button"
                    className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                <div className="text-center flex flex-col items-center justify-center">
                    {/* <GiBeachBucket className="w-24 h-24 mb-2 text-orange-600" /> */}
                    <img className='w-[180px] self-center'
                        src={require('../../assets/svgs/bucket.svg').default}
                        alt='scientist' />
                    <h2 className="text-2xl font-bold mb-2">Bucket Limit Reached</h2>
                    <p className="mt-2">You have used <span className='font-bold text-orange-600'>{bucketCount} out of </span> <span className='font-bold text-orange-600'>{bucketCount}</span> buckets for your current pack [{accessLevelName}].</p>
                    <p className="mb-8">Upgrade to a <span className='font-bold'>higher pack</span> to increase the limit.</p>
                    <button onClick={redirectToPayment} className="bg-blue-500 hover:bg-blue-700 cursor-pointer z-[57] text-white px-4 py-2 rounded-lg" >
                        Expand Limit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BucketLimit;
