import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { useUserAuth } from '../../../context/na-UserAuthContext'
import { UserAuth } from '../../../context/AuthContext'
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell, HiSparkles } from "react-icons/hi";
import { logout } from '../../../redux/actions/auth.action';
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import pinBtn from '../../../assets/gifs/pinBtn.gif';
import tapOSB from '../../../assets/gifs/tapOSB.gif';
import tapPin from '../../../assets/gifs/tapPin.gif';
import { BsArrowBarUp, BsArrowClockwise, BsArrowsAngleContract, BsArrowsExpand, BsBookmark, BsBox, BsBricks, BsBucket, BsCartDash, BsCheck2Circle, BsFillHandIndexFill, BsLayoutSidebarInsetReverse, BsLightningCharge, BsSave, BsShuffle } from 'react-icons/bs';
import { FaChrome, FaHandPointer } from 'react-icons/fa';
import { BiBrain, BiCheckCircle, BiRefresh } from 'react-icons/bi';
import DotDotDot from '../../components/DotDotDot';
import { GoDiffAdded } from 'react-icons/go';
import { GiBrainFreeze, GiBrainLeak, GiCardPickup, GiSparkles } from 'react-icons/gi';
import { IoCreate } from 'react-icons/io5';

const ExtensionTips = () => {
  const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
  const channelDisplayName = localStorage.getItem("channelDisplayName");
  const mainUserId = localStorage.getItem("mainUserId");
  const channelId = localStorage.getItem("channelId");
  const [showSidebar, setShowSidebar] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [extensionExists, setExtensionExists] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [extInstalled, setExtInstalled] = useState(false);
  const [extension, setExtension] = useState(false)
  const [checking, setChecking] = useState(false);

  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Lax";
  }

  function getCookie(name) {
    // Encode the cookie name to escape special characters
    name = encodeURIComponent(name);

    // Split document.cookie on semicolons into an array of all the cookies
    const cookieArr = document.cookie.split(';');

    // Loop through the cookies array
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].trim().split('=');

      // Decode the cookie name and compare it to the given string
      if (cookiePair[0] === name) {
        // Decode the cookie value and return it
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if the cookie was not found
    return null;
  }

  useEffect(() => {
    const desiredCookie = getCookie('TG_CEF')
    if (desiredCookie !== null) {
      setExtension(true);
      console.log('The specific cookie has been set.');
    } else {
      console.log('The specific cookie has NOT been set.');
    }
  }, []);

  useEffect(() => {
    if (mainUserId !== null || mainUserId !== undefined && mainUserId !== "") {

      const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
      const requestBody = {
        loginStatus: true,
      };
      axios.post(apiUrl, requestBody)
        .then(response => {
          // console.log('Token ' + response.data.token);
          document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

          const dateSet = new Date();
          localStorage.setItem('login_cookie_setDate', dateSet);
        })
        .catch(error => {
          console.error('Error:', error);
        });

      document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
      document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
    }
  }, []);

  const installExtension = (url) => {
    const desiredCookie = getCookie('TG_CEF')

    if (desiredCookie !== null) {
      setExtInstalled(true);
      console.log('The specific cookie has been set.');
    } else {
      // Specify the window features for the popup (size, position, etc.)
      const windowFeatures = 'width=1300,height=900,resizable,scrollbars=yes,';
      const popupWindow = window.open(url, '_blank', windowFeatures);
      // Check if the popup was blocked
      if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
        console.log('Popup was blocked or unable to open.');
        window.open(url, '_blank');
      } else {
        console.log('Popup opened successfully.');
        setChecking(true)
        let checkCount = 0; // Counter for the number of checks
        let timer; // Reference to the timer

        const checkCookie = setInterval(() => {
          console.log('Checking for link to extension.');

          const desiredCookie = getCookie('TG_CEF')

          if (desiredCookie !== null) {
            setExtInstalled(true);
            console.log('The specific cookie has been set.');
            setChecking(false);
            clearInterval(checkCookie); // Stop checking for the cookie
            clearTimeout(timer); // Stop the timer
          }

          checkCount++; // Increment the check count

          if (checkCount === 20) {
            // Reached the desired number of checks
            setChecking(false);
            console.log('Reached the desired number of checks (20).');
            clearInterval(checkCookie); // Stop checking for the cookie
            clearTimeout(timer); // Stop the timer
          }
        }, 9000); // Interval time in milliseconds (9000 = 3 minutes / 20 checks)

        timer = setTimeout(() => {
          clearInterval(checkCookie); // Stop checking for the cookie
        }, 180000); // 3 minutes in milliseconds
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Extension Installed</title>
        <meta name="description" content="Error Page of Thumbnail Geek" />
      </Helmet>
      <div className='bg-gray-50 w-full min-h-screen min-w-screen' >

        <div className=" px-6 py-4">
          {/* Start Nav */}
          <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4'>
            <div className="flex">
              <a href="/dashboard" className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700'>
                <img src={logo} />
              </a>
            </div>

          </div>
          {/* End Nav */}
        </div>

        <div class="text-center flex flex-col items-center justify-center">

          <h1 class="mb-4 mt-7 text-3xl tracking-tight font-bold text-gray-900 md:text-5xl dark:text-white">ThumbnailGeek<br />Browser Extension</h1>

          <h2 className="text-base font-semibold leading-7 text-blue-600 mt-24">How to use</h2>

          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto mt-7 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <article className="flex flex-col items-center">
                <div className="relative w-full">
                  <img
                    src={pinBtn}
                    alt=""
                    className="aspect-[3/2] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <p className='mt-3 font-bold text-xl text-gray-700 t'>Pin to your browser [One-Time]</p>
              </article>

              <article className="flex flex-col items-center">
                <div className="relative w-full">
                  <img
                    src={tapOSB}
                    alt=""
                    className="aspect-[3/2] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <p className='mt-3 font-bold text-xl text-gray-700 t'>Use On-Screen Button</p>
              </article>

              <article className="flex flex-col items-center">
                <div className="relative w-full">
                  <img
                    src={tapPin}
                    alt=""
                    className="aspect-[3/2] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <p className='mt-3 font-bold text-xl text-gray-700 t'>Use Toolbar Button</p>
              </article>
            </div>

          </div>

          <NavLink to={'/dashboard'} className="mt-24">
            <button className='ring-1 ring-blue-600 text-blue-600 font-semibold rounded-lg px-4 py-2 mt-10'>
              Go to Dashboard
            </button>
          </NavLink>

          <div className="">
            <h2 className="text-xl font-semibold leading-7 text-blue-600 mt-24">What you can do with it</h2>

            <div className="overflow-hidden py-10 sm:py-10">
              <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-16 sm:gap-y-20">

                  <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4 col-span-1 lg:col-span-1">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Test your thumbnails<br />on Youtube</p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                        Visualize your thumbnail right on the youtube home page or in search. Compare and make a decisions.
                      </p>
                      <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none text-left">
                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BsBox className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Insert.
                          </dt>{' '}
                          <dd className="inline">
                            Seemlessly insert your thumbnail into Youtube home or search page.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BsArrowsAngleContract className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Compare.
                          </dt>{' '}
                          <dd className="inline">
                            Compare your thumbnail against others for brightness, catchiness, visibility, etc.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BsArrowClockwise className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Reposition.
                          </dt>{' '}
                          <dd className="inline">
                            Place your thumbnail in a new spot to get a clearer assessment of its impact.
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="sm:px-6 lg:px-0 col-span-1 lg:col-span-2">
                    <div className="relative isolate overflow-hidden bg-green-400 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                      <div
                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white"
                        aria-hidden="true"
                      />
                      <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                        <img
                          src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                          alt="Product screenshot"
                          width={2432}
                          height={1442}
                          className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                        />
                      </div>
                      <div
                        className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden py-24 sm:py-24">
              <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-16 sm:gap-y-20">

                  <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4 col-span-1 lg:col-span-2">
                    <div className="relative isolate overflow-hidden bg-blue-400 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                      <div
                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white"
                        aria-hidden="true"
                      />
                      <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                        <img
                          src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                          alt="Product screenshot"
                          width={2432}
                          height={1442}
                          className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                        />
                      </div>
                      <div
                        className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className=" sm:px-6 lg:px-0  col-span-1 lg:col-span-1">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                      {/* <h2 className="text-base font-semibold leading-7 text-blue-600">Deploy faster</h2> */}
                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Collect competitors thumbnails</p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                        Collect thumbnails that inspire you or thumbnails of your competitors.
                      </p>
                      <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none text-left">

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900">
                            <BsBookmark className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Select.
                          </dt>{' '}
                          <dd className="inline">
                            Select thumbnails that inspire you and add them to your collection.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900">
                            <BsBucket className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Create.
                          </dt>{' '}
                          <dd className="inline">
                            Create new bucket to store specific types of thumbnails.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900">
                            <BsSave className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Save.
                          </dt>{' '}
                          <dd className="inline">
                            Save the thumbnails you have chosen, seemlessly to your account.
                          </dd>
                        </div>

                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden py-24 sm:py-24">
              <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-16 sm:gap-y-20">

                  <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4 col-span-1 lg:col-span-1">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                      {/* <h2 className="text-base font-semibold leading-7 text-blue-600">Deploy faster</h2> */}
                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Compelling titles for your videos</p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                        Generate the most compelling titles that will make your videos stand out (based on viral psychology and Youtube trends).
                      </p>
                      <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none text-left">

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BsLightningCharge className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Refine.
                          </dt>{' '}
                          <dd className="inline">
                            Get better (more catchy) versions of title to use for your videos.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BsCartDash className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Multiple Options.
                          </dt>{' '}
                          <dd className="inline">
                            Choose from 5 options of refined titles to use for your videos.
                          </dd>
                        </div>

                        <div className="relative pl-9">
                          <dt className="inline font-semibold text-gray-900 ">
                            <BiBrain className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                            Maximum Curiosity.
                          </dt>{' '}
                          <dd className="inline">
                            Titles are based in viral psychology to make them more compelling.
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="sm:px-6 lg:px-0 col-span-1 lg:col-span-2">
                    <div className="relative isolate overflow-hidden bg-purple-400 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                      <div
                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white"
                        aria-hidden="true"
                      />
                      <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                        <img
                          src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                          alt="Product screenshot"
                          width={2432}
                          height={1442}
                          className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                        />
                      </div>
                      <div
                        className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div >
    </>
  )
}

export default ExtensionTips