import React, { useState } from 'react';

const YouTubeThumbnail = ({ videoId, title, onImageLoad, }) => {
    const [imgUrl, setImgUrl] = useState(`https://img.youtube.com/vi/${videoId}/hq720.jpg`);

    const handleImageLoad = (e) => {
        const notFoundWidth = 120; // Replace with actual width of "not found" image
        const notFoundHeight = 90; // Replace with actual height of "not found" image
        const img = e.target;

        // if (img.naturalWidth === notFoundWidth && img.naturalHeight === notFoundHeight) {
        //     setImgUrl(`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`);
        // }

        if (img.naturalWidth === notFoundWidth && img.naturalHeight === notFoundHeight) {
            const fallbackUrl = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
            setImgUrl(fallbackUrl);
            onImageLoad(fallbackUrl); // Notify parent component of the fallback URL
        } else {
            onImageLoad(imgUrl); // Notify parent component of the initial URL
        }
    };

    return (
        <div className="relative w-full pb-[56.25%] overflow-hidden">
            <img
                src={imgUrl}
                alt={title}
                onLoad={handleImageLoad}
                className="absolute top-0 left-0 w-full h-full object-cover"
            />
        </div>
    );
};

export default YouTubeThumbnail;
