import React, { useEffect, useState } from 'react'
import request from '../../api'
import moment from 'moment'
import numeral from 'numeral'
import reactStringReplace from 'react-string-replace';
// import { AiFillEye } from 'react-icons/ai'

const Video = ({ video }) => {
    const {
        id,
        snippet: {
            channelId,
            channelTitle,
            title,
            publishedAt,
            thumbnails: { high },
        },
    } = video

    const [views, setViews] = useState(null)
    const [duration, setDuration] = useState(null)
    const [channelIcon, setChannelIcon] = useState(null)

    const seconds = moment.duration(duration).asSeconds()
    const _duration = moment.utc(seconds * 1000).format('mm:ss')

    const _videoId = id?.videoId || id

    useEffect(() => {
        const get_video_details = async () => {
            const {
                data: { items },
            } = await request('/videos', {
                params: {
                    part: 'contentDetails,statistics',
                    id: _videoId,
                },
            })
            setDuration(items[0].contentDetails.duration)
            setViews(items[0].statistics.viewCount)
        }
        get_video_details()
    }, [_videoId])

    useEffect(() => {
        const get_channel_icon = async () => {
            const {
                data: { items },
            } = await request('/channels', {
                params: {
                    part: 'snippet',
                    id: channelId,
                },
            })
            setChannelIcon(items[0].snippet.thumbnails.default)
        }
        get_channel_icon()
    }, [channelId])

    return (
        <div>
            <div className="pb-5 text-[0.9rem]">
                <div className="mb-2 relative ">
                    <div className="@apply inline-block overflow-hidden w-full h-[80%]">
                        <img className="@apply inline-block mx-0 w-full h-[80%] mt-[-10%] mb-[-9.5%]" src={high.url} alt="" />
                    </div>

                    {/* <img className="w-full" src={medium.url} alt="" /> */}
                    <span className='font-roboto px-[0.2rem] text-[0.7rem] absolute right-1 bottom-[9px] bg-black text-white rounded-sm'>
                        {_duration}
                    </span>
                </div>

                <div className='flex'>
                    <div className="shrink-0 mx-0 my-0 mr-2 ml-2 h-8 w-8 object-cover">
                        <img className='rounded-full h-8 w-8' src={channelIcon?.url} alt="" />
                    </div>
                    <div className='flex-col pr-3'>
                        <div className="flex flex-col leading-none line-clamp-2 font-sans items-center justify-center tracking-normal line-">
                            <p className='font-roboto items-center leading-4 text-[14px] font-medium tracking-tight '>{title}</p>
                        </div>
                        <div className=''>
                            <div className='inline-flex text-[11px]'>
                                <p className='opacity-50 font-roboto tracking-tight'>{channelTitle}
                                    <span className='font-roboto tracking-tight ml-1  m-0 p-0'>• <span className='uppercase'>{numeral(views).format("0.a")}</span> views</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video