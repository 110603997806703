import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Helmet } from "react-helmet";
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { auth, db, provider } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { fetchSignInMethodsForEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, addDoc, deleteDoc, updateDoc, getFirestore, serverTimestamp, collection, query, where } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import logoImage from '../../../assets/logo192.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import analysis_data from '../../../assets/analysis_data1.png';
import AvatarNoPremium from '../../components/AvatarNoPremium';
import tester from '../../../assets/thumbnailgeek_tester_1.png';
import GTTMFS1 from '../../../assets/GTTMFS-1.jpg';
import HTMTITD3 from '../../../assets/HTMTITD-3.png';
import FOTO from '../../../assets/FOTO.jpg';
import IMWSVV from '../../../assets/IMWSVV.jpg';
import PekKQ4x from '../../../assets/PekKQ4x.jpg';
import FindChannelId from '../../../assets/gifs/find-your-channel-id-optimized.gif';
import ytLogo from '../../../assets/yt_logo_mono_light.png';
// import ytLogo from '../../../assets/yt_logo_rgb_light.png';
import Tooltip from '../../components/Tooltip';
import { CgChevronLeft } from 'react-icons/cg';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const ImageCarousel = ({ slides, interval = 5000 }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const nextSlide = useCallback(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, [slides.length]);

    const prevSlide = useCallback(() => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    }, [slides.length]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isPaused) {
                nextSlide();
            }
        }, interval);

        return () => clearInterval(timer);
    }, [nextSlide, interval, isPaused]);

    return (
        <div
            className="relative w-full p-4"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <div className="overflow-hidden">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                    {slides.map((slide, index) => (
                        <div key={index} className="w-full flex-shrink-0">
                            <div className="px-5 flex items-center justify-center">
                                <div className="aspect-[16/9] max-w-md relative p-1">
                                    <img
                                        className="w-full h-full object-cover rounded-xl ring-1 ring-gray-300"
                                        src={slide.image}
                                        alt={slide.imageAlt}
                                    />
                                    <div className="text-center text-white py-4">
                                        <p className="text-lg font-normal">{slide.caption}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
            >
                <BsChevronLeft className="h-6 w-6" />
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
            >
                <BsChevronRight className="h-6 w-6" />
            </button>
        </div>
    );
};

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
                <button onClick={onClose} className="float-right text-gray-600 hover:text-gray-800">
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

const FirstStepsConnect = () => {
    let { user_id } = useParams();
    localStorage.setItem('real_user_id', user_id);
    const { user, logOut } = UserAuth();
    const { checked, checkDataStatus } = PremiumCredentials()
    //const { checked, checkDataStatus } = useContext(PremiumCredentials);
    const navigate = useNavigate()
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserEmail = localStorage.getItem('mainEmail');
    const chId = localStorage.getItem("channelId");
    const [connect, setConnect] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [channelName, setChannelName] = useState("")
    const [channelImage, setChannelImage] = useState("")
    const [subscriberCount, setSubscriberCount] = useState("")
    const [finalEmail, setFinalEmail] = useState("")
    const [userDetails, setUserDetails] = useState(null);
    const [verifying, setVerifying] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [reAuthDone, setReAuthDone] = useState(false);
    const [popUpTitle, setPopUpTitle] = useState('');
    const [popUpContent, setPopUpContent] = useState('');
    const [channelEmail, setChannelEmail] = useState('');
    const [channelId, setChannelId] = useState('');

    const [reauthOpen, setReauthOpen] = useState(false)
    const cancelButtonRef = useRef(null)

    const [channelUrl, setChannelUrl] = useState('');
    const [isValidId, setIsValidId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const apiKey = 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s';

    const validateChannelId = (id) => {
        const regex = /^[A-Za-z0-9_-]{24}$/;
        return regex.test(id);
    };

    useEffect(() => {
        if (channelId) {
            setIsValidId(validateChannelId(channelId));
        } else {
            setIsValidId(null);
        }
    }, [channelId]);

    const handleIdSubmit = (e) => {
        e.preventDefault();
        if (isValidId) {
            const fullUrl = `https://www.youtube.com/channel/${channelId}`;
            // Handle the URL submission logic here
            //console.log('Submitted URL:', fullUrl);
            //console.log('Channel ID:', channelId);
        }
    };

    const getBorderColor = () => {
        if (isValidId === null) return 'border-gray-300';
        return isValidId ? 'border-green-500' : 'border-red-500';
    };

    const extractChannelId = (url) => {
        const match = url.match(/channel\/([A-Za-z0-9_-]{24})$/);
        return match ? match[1] : '';
    };

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // //console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';

                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (!checked) {
            //console.log('Initializing Premium Credentials');
            checkDataStatus();
        }
    }, [checked]);

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const findDocument = async (email) => {
        const q = query(collection(db, 'Users'), where('email', '==', email));
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                return true;
            });
            return false;
        } catch (error) {
            console.error('Error getting documents: ', error);
        }
    };

    useEffect(() => {
        const checkUserId = async () => {
            const firestore = getFirestore()
            const docRef = doc(firestore, "Users", user_id)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                navigate('/login')
            } else {
                setUserDetails(true)
                const channel_id = docSnap.data().channel_id;
                const channel_name = docSnap.data().channel_name;
                const channel_photo = docSnap.data().channel_photo_url;
                const channel_linked = docSnap.data().channel_linked;
                const channel_subs = docSnap.data().sub_count;
                const email_fixed = docSnap.data().email_fixed;
                const page_touch_connect = docSnap.data().page_touch_connect;

                if (channel_id !== '' && channel_id !== null) {
                    setConnect(true)

                    if (channel_linked === false) {
                        const userDoc = doc(db, "Users", user_id)
                        const data = {
                            channel_linked: true,
                        }
                        setDoc(userDoc, data, { merge: true });
                    }
                }

                if (channel_photo !== '' && channel_photo !== null) {
                    setChannelImage(channel_photo)
                }

                if (channel_name !== '' && channel_name !== null) {
                    setChannelName(channel_name)
                }

                if (channel_subs !== '' && channel_subs !== null) {
                    setSubscriberCount(channel_subs)
                }

                if (channel_linked === true && email_fixed === true) {

                    if (!page_touch_connect) {
                        // Update page_touch_connect here
                        const userDoc = doc(firestore, "Users", user_id);
                        await setDoc(userDoc, { page_touch_connect: true }, { merge: true });
                    }

                    window.location.href = `/mid/${user_id}`;
                    //window.location.href = `/first-steps/extension/${ user_id } `;
                }
            }
        }

        checkUserId();
    }, []);

    const connectChannel = async () => {
        setConnecting(true);

        const new_provider = new GoogleAuthProvider();
        new_provider.addScope('https://www.googleapis.com/auth/youtube.readonly')
        new_provider.setCustomParameters({
            prompt: 'select_account',
            login_hint: mainUserEmail
        });

        try {
            signInWithPopup(auth, new_provider).then(async (data) => {

                //This gives you a Google Access Token. You can use it to access Google APIs.
                const credential = GoogleAuthProvider.credentialFromResult(data);

                const accessToken = credential.accessToken;
                const refreshToken = data.user.refreshToken;
                setFinalEmail(data.user.email);

                localStorage.setItem('ytToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                //console.log(`------------Access Token--------------- ${accessToken} `);
                //console.log(`------------Refresh Token--------------${refreshToken} `);

                if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
                    getChannelIdThenSave(accessToken, refreshToken, data);
                    getChannelUsername(chId);
                }

            }).catch((error) => {
                setVerifying(false);
            });
        } catch (error) {
            setVerifying(false);
        }

    }

    const getChannelIdThenSave = async (accessToken, refreshToken, result) => {
        const config = {
            headers: { Authorization: `Bearer ${accessToken} ` }
        };

        try {
            await axios.get("https://youtube.googleapis.com/youtube/v3/channels?part=statistics&mine=true&key=AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s",
                config
            ).then(res => {
                //console.log(res.data);

                if (Array.isArray(res.data.items)) {
                    setConnecting(false);
                    //console.log(res.data.items[0].id);

                    const channelid = res.data.items[0].id ?? "";
                    const channelname = result.user?.displayName ?? "";
                    const channelemail = result.user?.email ?? "";
                    const accesstoken = accessToken ?? "";
                    const refreshtoken = refreshToken ?? "";
                    const channelphotourl = result.user?.providerData[0].photoURL ?? "";
                    const subscribercount = res.data.items[0].statistics.subscriberCount ?? "";

                    localStorage.setItem('channelPhotoUrl', channelphotourl);
                    localStorage.setItem('channelDisplayName', channelname);
                    localStorage.setItem('channelSubscribers', subscribercount);
                    localStorage.setItem('channelEmail', channelemail);
                    localStorage.setItem('channelId', channelid);

                    setChannelEmail(channelemail);
                    setChannelId(channelid);

                    //const docDuplicate = findDocument(mainUserEmail);
                    ////console.log('Channel Email - ', docDuplicate);

                    //docDuplicate
                    const userDoc = doc(db, "Users", user_id)
                    const data = {
                        channel_id: channelid,
                        channel_name: channelname,
                        channel_email: channelemail,
                        channel_token: accesstoken,
                        refresh_token: refreshtoken,
                        channel_photo_url: channelphotourl,
                        sub_count: subscribercount,
                        channel_linked: true,
                    }
                    setDoc(userDoc, data, { merge: true }).then(res => {
                        setChannelName(channelname);
                        setChannelImage(channelphotourl);
                        setSubscriberCount(subscribercount);
                        setConnect(true);
                        // getLatestVideoThenSave();
                    });

                    triggerThumbnails();

                    //TODO - Make not equal to mainUserEmail
                    if (mainUserEmail !== channelemail && reAuthDone === false) {
                        setPopUpTitle('Authentication Mismatch')
                        setPopUpContent(`Please select your account again to re - authenticate.\n\n(${mainUserEmail}) \n\nClick the button below to proceed.`)
                        setReauthOpen(true)
                    } else {
                        const userDoc = doc(db, "Users", user_id)
                        const data = {
                            email_fixed: true,
                        }
                        setDoc(userDoc, data, { merge: true });

                        setReAuthDone(true);
                    }

                    ////console.log('Existing Email - ', existingEmail)
                    ////console.log('Channel Email - ', result.user?.email)
                    //if (result.user?.email !== existingEmail) {
                    //    deleteDocument(result.user?.email);
                    //    //console.log('deleteDocument');
                    //}

                    //if (result.user?.email !== existingEmail && existingEmail !== "" && existingEmail !== null) {
                    //    //console.log('Existing Email - ', existingEmail)
                    //    //console.log('Channel Email - ', result.user?.email)

                    //    //deleteUserSignInMethods(result.user?.email);
                    //    deleteDocument(result.user?.email);
                    //}

                } else {
                    //console.log('Invalid channel');
                    setConnecting(false);
                    toast("Not a valid Youtube channel", {
                        icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
                    });
                }
            })
        } catch (error) {
            setConnecting(false);
            setConnect(false);
        }

    }


    const [channelInfo, setChannelInfo] = useState(null);

    const handleUrlSubmit = (e) => {
        e.preventDefault();
        if (isValidId) {
            getChannelIdFromLinkThenSave(channelId);
        }
    };

    const getChannelIdFromLinkThenSave = async (channelId) => {
        setChannelInfo(null);
        setError('');

        try {
            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch channel data');
            }

            const data = await response.json();

            if (data.items && data.items.length > 0) {
                const channel = data.items[0];

                setChannelInfo({
                    id: channel.id,
                    title: channel.snippet.title,
                    description: channel.snippet.description,
                    thumbnailUrl: channel.snippet.thumbnails.medium.url,
                    subscriberCount: channel.statistics.subscriberCount,
                    videoCount: channel.statistics.videoCount,
                    viewCount: channel.statistics.viewCount
                });

                setConnecting(false);

                const channelid = channel.id ?? "";
                const channelname = channel.snippet.title ?? "";
                const channelemail = mainUserEmail;
                const accesstoken = "N/A";
                const refreshtoken = "N/A";
                const channelphotourl = channel.snippet.thumbnails.medium.url ?? "";
                const subscribercount = channel.statistics.subscriberCount ?? "";
                const videoCount = channel.statistics.videoCount;
                const viewCount = channel.statistics.viewCount;

                localStorage.setItem('channelPhotoUrl', channelphotourl);
                localStorage.setItem('channelDisplayName', channelname);
                localStorage.setItem('channelSubscribers', subscribercount);
                localStorage.setItem('channelEmail', channelemail);
                localStorage.setItem('channelId', channelid);

                setChannelEmail(mainUserEmail);
                setChannelId(channelid);

                //docDuplicate
                const userDoc = doc(db, "Users", user_id)
                const ch_data = {
                    channel_id: channelid,
                    channel_name: channelname,
                    channel_email: channelemail,
                    channel_token: accesstoken,
                    refresh_token: refreshtoken,
                    channel_photo_url: channelphotourl,
                    channel_total_views: viewCount,
                    channel_total_videos: videoCount,
                    sub_count: subscribercount,
                    channel_linked: true,
                    email_fixed: true,
                }
                setDoc(userDoc, ch_data, { merge: true }).then(res => {
                    setChannelName(channelname);
                    setChannelImage(channelphotourl);
                    setSubscriberCount(subscribercount);
                    setConnect(true);

                    // getLatestVideoThenSave();
                });

                triggerThumbnails();
                setReAuthDone(true);

            } else {
                // setError('No channel found for this ID');
                toast("No channel found for this ID", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            }
        } catch (err) {
            setError('Error fetching channel info: ' + err.message);
            throw error;
        }
    };

    // const getLatestVideoThenSave = async () => {
    //     try {
    //         const apiKey = 'AIzaSyAWFhxxUQhPCgF1Cdb0WlumQqxtHQc6_1s';
    //         const chId = channelId;
    //         const maxResults = 1;
    //         await axios.get(
    //             `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${chId}&part=snippet,id&order=date&maxResults=${maxResults}`
    //         ).then(res => {
    //             const video = res.data.items[0];
    //             // setThumbnail(video.snippet.thumbnails.default.url);
    //             // setTitle(video.snippet.title);

    //             //write this function to save the image
    //             sendTTDataToDb(video.snippet.thumbnails.default.url, video.snippet.title);
    //         })
    //             .catch(error => {
    //                 console.error('Error fetching videos:', error);
    //             });
    //     } catch (error) {
    //         setConnecting(false);
    //         setConnect(false);
    //     }
    // }

    // const sendTTDataToDb = async (thumb, title) => {

    //     const token = getCookie('TG_AT');

    //     const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/initial-tt-analysis-save/${mainUserId}`;

    //     const data = {
    //         thumbnailUrl: thumb,
    //         title: title,
    //     };

    //     const headers = {
    //         headers: {
    //             'Authorization': `${token}`,
    //         }
    //     };

    //     axios.post(url, data, headers)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 //console.log(response.data.payload);
    //             } else {
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });

    // }

    const getChannelUsername = async (channelId) => {
        try {

            const response = await axios.get(
                `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${chId}&key=${apiKey}`
            );

            const channelData = response.data.items[0];
            if (channelData) {
                const customUrl = channelData.snippet.customUrl;
                if (customUrl) {
                    const userDoc = doc(db, "Users", user_id)
                    const data = {
                        yt_username: customUrl,
                    }
                    setDoc(userDoc, data, { merge: true }).then(res => {

                    });
                } else {
                    const userDoc = doc(db, "Users", user_id)
                    const data = {
                        yt_username: null
                    }
                    setDoc(userDoc, data, { merge: true });
                }
            }
        } catch (error) {
            console.error('Error', error);
        }
    };

    const updateConnectPage = async () => {
        const userDoc = doc(db, "Users", user_id)
        const data = {
            page_touch_connect: true,
        }
        setDoc(userDoc, data, { merge: true });
    }

    const triggerThumbnails = async () => {
        const triggersRef = collection(db, "Users", mainUserId, "Triggers");
        const tDocRef = await addDoc(triggersRef, {
            trigger: "get_thumbnails_first_use",
            trigger_count: 51,
            trigger_date: serverTimestamp(),
        });

        const docRef = doc(db, "Users", mainUserId);
        const ref = await updateDoc(docRef, {
            latest_trigger: "get_thumbnails_first_use",
            latest_trigger_fulfilled: false,
            latest_trigger_date: serverTimestamp(),
        });
        //console.log('Have set new trigger for get_thumbnails');
    }

    const goToNext = async () => {
        const existingEmail = localStorage.getItem('mainEmail');
        const channelemail = localStorage.getItem('channelEmail');
        if (connect === true) {
            if (existingEmail !== channelemail && reAuthDone === false) {
                setPopUpTitle('Authentication Mismatch')
                setPopUpContent(`Please select your account again to re - authenticate.\n\n(${existingEmail}) \n\nClick the button below to proceed.`)
                setReauthOpen(true)
            } else {
                updateToken(user_id);
                updateConnectPage();
                navigate(`/mid/${user_id}`);
                //navigate(`/first-steps/extension/${ user_id }`);
            }

        } else {
            toast("Connect your youtube channel first", {
                icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
            });
        }
    }

    //&& reAuthDone !== false
    // const goToDashboard = async (event) => {
    //     if (connect === true) {
    //         //navigate(`/login`);
    //         await logOut();
    //     } else {
    //         toast("Connect your youtube channel first", {
    //             icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
    //         });
    //     }
    // }

    const handleSignIn = () => {
        setReauthOpen(false);
        signInWithPopup(auth, provider).then(async (data) => {

            let idToken = await data.user.getIdToken();
            const oauthIdToken = localStorage.getItem('oauthIdToken');
            if (oauthIdToken) {
                localStorage.removeItem('oauthIdToken');
            }
            localStorage.setItem('oauthIdToken', idToken);
            validateAndSaveUserData(data?.user);

        }).catch((error) => {
            //console.log(error)
        });
    }

    const validateAndSaveUserData = async (user) => {
        try {
            const existingUserId = localStorage.getItem('mainUserId');
            const existingEmail = localStorage.getItem('mainEmail');

            if (user.email === mainUserEmail) {
                if (existingUserId) {
                    localStorage.removeItem('mainUserId');
                }
                localStorage.setItem('mainUserId', user.uid);

                if (existingEmail) {
                    localStorage.removeItem('mainEmail');
                }
                localStorage.setItem('mainEmail', user.email);

                const userDoc = doc(db, "Users", user_id)
                const data = {
                    email_fixed: true,
                }
                setDoc(userDoc, data, { merge: true }).then(res => {
                    updateToken(user.uid);
                    setReAuthDone(true);
                });
            } else {
                setPopUpTitle('Use the same email to authenticate')
                setPopUpContent('Please use the same login account to authenticate. Using a different account will cause a mismatch. Click button to continue.')
                setReauthOpen(true)
            }

        } catch (e) {
            console.error(e);
            localStorage.setItem('signUpTriggered', false);
        }
    };

    const updateToken = (userId) => {
        const channelPhoto = localStorage.getItem("channelPhotoUrl");
        const channelName = localStorage.getItem("channelDisplayName");

        if (userId !== null && userId !== undefined && userId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${userId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // //console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    document.cookie = `TG_Avatar = ${channelPhoto}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    const slides = [

        {
            image: FOTO,
            imageAlt: "",
            caption: "The Future of Food: Uncovering the Hidden World of Ocean Farming",
        },
        {
            image: GTTMFS1,
            imageAlt: "",
            caption: "I'm Giving Away $1000 to MY FIRST SUB!",
        },
        {
            image: PekKQ4x,
            imageAlt: "",
            caption: "From Scratch to Success: My 2-Year App Building Story",
        },
        {
            image: IMWSVV,
            imageAlt: "",
            caption: "I Made $10K in 30 Days on Youtube... Without a Single Viral Video!",
        },
    ];

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>First Steps - Add channel | ThumbnailGeek</title>
                <meta name="description" content="First Steps - Add channel | ThumbnailGeek" />
            </Helmet>

            <Transition.Root show={reauthOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setReauthOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="relative sm:flex sm:items-start">

                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">

                                            <Tooltip content='Accounts that once had Google Plus connected, have conflicting markers for their Youtube channels. Hence the need for re-authentication.'>
                                                <p className='absolute -top-3 -right-10 text-gray-600 text-xs cursor-pointer'>Why?</p>
                                            </Tooltip>

                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {popUpTitle}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-[15px] text-gray-600">
                                                    {popUpContent.split('\n').map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            {item}
                                                            {index < popUpContent.split('\n').length - 1 && <br />}
                                                        </React.Fragment>
                                                    ))}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-5 sm:mt-4 sm:flex sm:flex-col sm:items-center sm:justify-center">

                                        <button onClick={() => handleSignIn()}
                                            type="submit"
                                            className="w-full flex  mt-3 items-center justify-center hover:ring-red-700 rounded-md p-[8px] text-white shadow-md ring-1 ring-red-400"
                                        >
                                            <img src={ytLogo} alt="SIGN IN WITH YOUTUBE" className="w-40 py-0.5 mr-[24px]" />
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="flex flex-col h-screen">
                <div className="px-6 pt-4">
                    {/* Start Nav */}
                    <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4'>
                        <div className="flex">
                            <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                                <img src={require('../../../assets/svgs/tg_logo_full.svg').default} />
                            </a>
                        </div>
                        <AvatarNoPremium />
                    </div>
                    {/* End Nav */}
                </div>

                <div className="flex flex-1 px-10 h-full">
                    <div className="w-1/2 flex flex-1 flex-col justify-left lg:flex-none h-full">
                        <div className="w-full lg:w-full h-full">

                            <div className="flex flex-col h-full items-center justify-center">
                                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                                    <div className="max-w-2xl inline-flex space-x-3" aria-hidden="true">
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                        </div>
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                        </div>
                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                        </div>
                                        {/* <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                        </div> */}
                                    </div>

                                    <div className="">
                                        {
                                            userDetails === true && connect === true
                                                ?
                                                <>
                                                    <div className="pr-4">
                                                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                            Awesome!
                                                        </h2>
                                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                                            You will now be able to use the browser extension to test your thumbnails on Youtube homepage.
                                                        </p>
                                                    </div>
                                                </>
                                                :
                                                <div className="pr-4">
                                                    <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                        Connect your Youtube channel
                                                    </h2>
                                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                                        Which Youtube channel will you like to grow using thumbnailGeek?
                                                    </p>
                                                </div>
                                        }
                                    </div>

                                    <div className="mt-2">
                                        <div className="">
                                            {
                                                userDetails === true
                                                    ?
                                                    <div className="text-gray-600 body-font container mx-auto flex flex-col mt-12 justify-center items-center">
                                                        <div className="w-full max-w-xl flex flex-col mb-16 text-center">
                                                            {
                                                                connect === true
                                                                    ?
                                                                    <>
                                                                        <div className="mt-1">
                                                                            <div className="flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 p-2 text-left shadow-sm">
                                                                                <span className="flex min-w-0 flex-1 items-center space-x-3">
                                                                                    <span className="block flex-shrink-0">
                                                                                        <img className="h-10 w-10 rounded-full" src={channelImage} alt="" />
                                                                                    </span>
                                                                                    <span className="block min-w-0 flex-1">
                                                                                        <span className="block truncate text-sm font-medium text-gray-900">{channelName}</span>
                                                                                        <span className="block truncate text-xs font-medium text-green-600 mr-4">youtube channel connected</span>
                                                                                    </span>
                                                                                </span>
                                                                                <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                                                                    <IoCheckmarkCircleSharp className="h-5 w-5 text-green-600" aria-hidden="true" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="inline-flex mt-2">
                                                                                <p className='text-xs font-medium text-gray-600 mt-1'>You currently have </p>
                                                                                <p className='font-bold ml-1 mr-1'>{subscriberCount}</p>
                                                                                <p className='text-xs font-medium text-gray-600 mt-1'>subscribers</p>
                                                                            </div>




                                                                        </div>
                                                                        <button onClick={() => setConnect(false)} className='mt-2 text-sm font-normal text-gray-900 underline'>Change channel</button>

                                                                        {/* {subscriberCount < 1000 && (
                                                                            <div className=" w-full border-l-4 text-left border-yellow-400 bg-yellow-50 ">
                                                                                <div className="inline-flex items-center p-4">
                                                                                    <div className="flex-shrink-0">
                                                                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                                    </div>
                                                                                    <div className="ml-3 mt-2">
                                                                                        <p className="text-sm text-yellow-700">
                                                                                            ThumbnailGeek works best for channels between 1,000 and 100,000 subscribers.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )} */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="w-full max-w-lg mx-auto bg-white overflow-hidden">
                                                                            <div className="p-1 max-w-sm">
                                                                                <h2 className="text-sm font-normal text-left text-gray-800 mb-3">
                                                                                    Sign in with Your YouTube
                                                                                </h2>
                                                                                <div className="flex flex-col items-center space-y-7 py-2">
                                                                                    <button onClick={connectChannel}
                                                                                        type="submit"
                                                                                        className="w-full flex h-[40px] items-center justify-center hover:ring-red-700 rounded-md p-[8px] text-white shadow-md ring-1 ring-red-400"
                                                                                    >
                                                                                        <img src={ytLogo} alt="SIGN IN WITH YOUTUBE" className="w-32 p-4 mr-[24px]" />
                                                                                    </button>
                                                                                    <p className="text-xs text-center text-gray-400 mt-4">
                                                                                        We only request <span className="font-medium text-red-600">READ-ONLY</span> permissions for intelligent recommendations.
                                                                                        Your channel data will NOT be changed, edited or deleted.
                                                                                    </p>

                                                                                    <div className="flex items-center w-full">
                                                                                        <div className="flex-grow h-px bg-gray-300"></div>
                                                                                        <span className="px-4 text-sm text-gray-500 font-medium">OR</span>
                                                                                        <div className="flex-grow h-px bg-gray-300"></div>
                                                                                    </div>

                                                                                    <form onSubmit={handleUrlSubmit} className="w-full space-y-4">
                                                                                        <div className="space-y-2 mb-7">
                                                                                            <div className="flex items-center">
                                                                                                <span className="bg-gray-100 text-gray-500 text-sm px-3 py-2 rounded-l-md border border-r-0 border-gray-300">
                                                                                                    https://www.youtube.com/channel/
                                                                                                </span>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    placeholder="YOUR_CHANNEL_ID"
                                                                                                    value={channelId}
                                                                                                    onChange={(e) => setChannelId(e.target.value)}
                                                                                                    className={`flex-1 px-3 py-2 border text-sm rounded-r-md focus:outline-none focus:ring-2 focus:ring-red-500 transition-colors duration-200 ${getBorderColor()}`}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="flex justify-between items-center text-xs text-gray-500 italic">
                                                                                                <span>Paste your 24-character YouTube channel ID. </span>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => setIsModalOpen(true)}
                                                                                                    className="text-blue-500 hover:underline"
                                                                                                >
                                                                                                    Where can I find this?
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <button
                                                                                            type="submit"
                                                                                            disabled={!isValidId}
                                                                                            className={`w-full text-sm py-2 mt-3 rounded-md transition-colors duration-200 ${isValidId
                                                                                                ? 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                                                                                                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                                                                }`}
                                                                                        >
                                                                                            Submit Channel ID
                                                                                        </button>
                                                                                    </form>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                // <div className="block mt-1">
                                                                //     <p className="mt-3 mb-4 text-left font-bold text-gray-700">
                                                                //         Click to connect your channel
                                                                //     </p>

                                                                //     <button onClick={connectChannel}
                                                                //         type="submit"
                                                                //         className="w-64 flex h-[40px] mb-7 items-center justify-center hover:ring-red-700 rounded-md p-[8px] text-white shadow-md ring-1 ring-red-400"
                                                                //     >
                                                                //         <img src={ytLogo} alt="SIGN IN WITH YOUTUBE" className="w-32 p-4 mr-[24px]" />
                                                                //     </button>

                                                                //     <p className="mt-2 mb-4 text-left font-semibold text-red-700">
                                                                //         We DO NOT have permission to edit, change or delete your channel data. These are READ ONLY permissions. Needed for analysis and recommendations.
                                                                //     </p>
                                                                // </div>
                                                            }


                                                            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                                                                <div className="space-y-4">
                                                                    <h3 className="text-xl text-left font-semibold">How to Find Your YouTube Channel ID</h3>
                                                                    <div className="aspect-[16/9] w-full bg-gray-200 rounded-lg overflow-hidden">
                                                                        <img
                                                                            src={FindChannelId}
                                                                            alt="How to find YouTube Channel ID"
                                                                            className="object-cover w-full h-full"
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        onClick={() => setIsModalOpen(false)}
                                                                        className=' rounded-lg bg-gray-300 w-full py-2 text-sm hover:bg-gray-400 transition-colors duration-200'
                                                                    >
                                                                        Got it
                                                                    </button>
                                                                </div>
                                                            </Modal>
                                                            {
                                                                connect === true
                                                                    ?
                                                                    <div className="block mt-10 ml-6 max-w-xs">
                                                                        <button
                                                                            onClick={goToNext}
                                                                            className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                        >
                                                                            Next Step
                                                                            {/* Next Step (Re-login to Authenicate) */}
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="block mt-10 ml-6 max-w-xs">
                                                                        <button
                                                                            disable="true"
                                                                            className="flex w-full justify-center rounded-md bg-gray-300 px-3 py-2.5 text-sm font-semibold leading-6 text-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                                                        >
                                                                            Next Step
                                                                        </button>
                                                                    </div>
                                                            }

                                                            {error &&
                                                                <div className="absolute right-5 top-5 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
                                                                    <div className="flex items-center justify-center w-12 bg-red-500">
                                                                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                                                                        </svg>
                                                                    </div>

                                                                    <div className="px-4 py-2 -mx-3">
                                                                        <div className="mx-3">
                                                                            <p className="text-sm text-white dark:text-gray-600">{error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {success &&
                                                                <div className="absolute right-5 top-5 flex w-full max-w-sm mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-md dark:bg-white">
                                                                    <div className="flex items-center justify-center w-12 bg-emerald-500">
                                                                        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                                                                        </svg>
                                                                    </div>

                                                                    <div className="px-4 py-2 -mx-3">
                                                                        <div className="mx-3">
                                                                            <p className="text-sm text-white dark:text-gray-600">{success}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="w-full flex mt-28 justify-center items-center xl:px-8">
                                                        <div className="flex flex-col items-center justify-center">
                                                            <div className='relative w-2/3'>
                                                                <img className='w-16' src={logoImage} />
                                                            </div>
                                                            <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                                <path
                                                                    className="fill-gray-300"
                                                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                                <path
                                                                    className="fill-gray-500"
                                                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block h-full">
                        <div className="w-full lg:w-full h-full bg-gray-900">
                            <div className="mx-auto max-w-2xl flex flex-col h-full items-center justify-center">
                                <h2 className="text-2xl font-bold tracking-tight text-gray-100 sm:text-2xl">No more lost views.</h2>
                                {/* <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
                                    Analyze your thumbnails based on the psychology of <br />what causes people to click.
                                </p> */}
                                <p className="mt-2 mb-5 text-base leading-6 text-gray-200 text-center">
                                    Get unlimited thumbnail & title ideas for any video topic
                                </p>
                                <ImageCarousel slides={slides} />
                                {/* <img
                                    className=" w-2/3 object-cover overflow-hidden  mb-4 mt-5 rounded-md ring-1 ring-gray-300"
                                    src={analysis_data}
                                    alt=""
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstStepsConnect