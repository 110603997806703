import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition, Disclosure, Listbox } from '@headlessui/react'
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { FaChrome } from 'react-icons/fa';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db } from '../../../firebase-config';
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc, where, orderBy, query } from 'firebase/firestore';
import axios from 'axios';
import CountUp from 'react-countup';
import logo from '../../../assets/logo_full_32.png';
import logoImage from '../../../assets/logo192.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import DotDotDot from '../../components/DotDotDot';
import AvatarNoPremium from '../../components/AvatarNoPremium';
import tester from '../../../assets/thumbnailgeek_tester_1.png';
import YouTubeThumbnail from '../../components/YoutubeThumbnail';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../components/Tooltip';
import { RxDot, RxDotFilled } from 'react-icons/rx';
import { FiAlertTriangle } from 'react-icons/fi';
import { BiDownArrow, BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';

const goals = [
    { id: 1, name: 'Make better thumbnails' },
    { id: 2, name: 'Compare, test my thumbnails before I upload' },
    { id: 3, name: 'Analyse my thumbnails before I upload' },
    { id: 4, name: 'A little bit of everything' },
]

const qualities = [
    { id: 1, name: "I just don't get enough views. I don't know why" },
    { id: 2, name: "My video ideas just don't get enough views" },
    { id: 3, name: "I don't know how to improve my thumbnails" },
    { id: 4, name: 'Some other reason' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FirstStepsQA = () => {
    let { user_id } = useParams();
    const { user, token, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const navigate = useNavigate()
    const { checked, checkDataStatus, premiumStatus, premiumTier, accountStatus, aiQuota, aiCreditsLeft, accessLevel, channelLinked, latestTriggerFulfilled } = PremiumCredentials()
    const [selectedVQ, setSelectedVQ] = useState(qualities[0])
    const [selectedGoal, setSelectedGoal] = useState(goals[0])
    const [videos, setVideos] = useState([]);
    const [thumbnailData, setThumbnailData] = useState([]);
    const [allThumbnailData, setAllThumbnailData] = useState(null);
    const [viewTotal, setViewTotal] = useState(0);
    const [viewAverage, setViewAverage] = useState(0);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [loadingViewAverage, setLoadingViewAverage] = useState(false);
    const [limit, setLimit] = useState(8);
    const [currentItemDetails, setCurrentItemDetails] = useState(null);
    const [loadingState, setLoadingState] = useState('Viewing your thumbnails...');
    const [openDetails, setOpenDetails] = useState(false);
    const [fullDatasetStats, setFullDatasetStats] = useState(null);
    const [currentStats, setCurrentStats] = useState({
        totalViews: 0,
        averageViews: 0,
        medianViews: 0,
        maxViews: 0,
        minViews: 0,
        standardDeviation: 0,
        weightedRatio: 0,
    });
    const [explainerOpen, setExplainerOpen] = useState(false)
    const [currentExplainerTopic, setCurrentExplainerTopic] = useState('')
    const [currentExplainerDescription, setCurrentExplainerDescription] = useState('')
    const [currentExplainerActionableDescription, setCurrentExplainerActionableDescription] = useState('')
    const [percentageChange, setPercentageChange] = useState({
        medianChange: 0,
        standardDeviationChange: 0,
        viewRatioChange: 0,
    });
    const [triggerCheck, setTriggerCheck] = useState(false)
    const [blockPopup, setBlockPopup] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [numberOfCreditsNeeded, setNumberOfCreditsNeeded] = useState(150)
    const [lastUpdated, setLastUpdated] = useState('')
    const [currentThumbnail, setCurrentThumbnail] = useState('')
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [videoId, setVideoId] = useState(null);

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (!checked) {
            console.log('Initializing Premium Credentials');
            checkDataStatus();
        }
    }, [checked]);

    useEffect(() => {
        if (loading === true) {
            const interval = setInterval(() => {
                setLoadingProgress(prevProgress => {
                    if (prevProgress < 97) {
                        const newProgress = prevProgress + 0.4;
                        if (newProgress >= 10 && newProgress < 10.1) {
                            setLoadingState('Checking your data...');
                        } else if (newProgress >= 20 && newProgress < 20.1) {
                            setLoadingState('Fetching your thumbnails...');
                        } else if (newProgress >= 40 && newProgress < 40.1) {
                            setLoadingState('Crunching the numbers...');
                        } else if (newProgress >= 70 && newProgress < 70.1) {
                            setLoadingState('Arranging into neat little boxes...');
                        }
                        return newProgress;
                    } else {
                        clearInterval(interval);
                        return prevProgress;
                    }
                });
            }, 70);

            return () => clearInterval(interval);
        }
    }, [loading]);

    useEffect(() => {
        setLoading(true);
        if (latestTriggerFulfilled === true) {
            fetchFullDatasetStats();
            fetchThumbnails(limit);
        } else {
            setLoadingState('Loading...');
        }
    }, [latestTriggerFulfilled, mainUserId, limit]);

    const computeOutlierScore = (recordViews, averageViews) => {
        const percentageDifference = ((recordViews - averageViews) / averageViews) * 100;
        return Math.round(percentageDifference);
    }

    function convertViews(viewsString) {
        const regex = /^(\d+(\.\d+)?)([KkMm])? views$/;
        const match = viewsString.match(regex);

        if (!match) {
            throw new Error('Invalid input format');
        }

        const number = parseFloat(match[1]);
        const suffix = match[3];

        let multiplier = 1;
        if (suffix === 'K' || suffix === 'k') {
            multiplier = 1000;
        } else if (suffix === 'M' || suffix === 'm') {
            multiplier = 1000000;
        }

        return number * multiplier;
    }

    const fetchFullDatasetStats = async () => {
        const recordsRef = collection(db, "Users", mainUserId, "ChannelThumbnailsData");
        const q = query(
            recordsRef,
            where('position', '>=', 0),
            where('position', '<', 30),
            orderBy('position')
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return;
        }

        const data = querySnapshot.docs.map(doc => doc.data());
        setNumberOfCreditsNeeded((data.length * 3) + 30);

        const viewsArray = data.map(item => convertViews(item.views));
        const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
        const averageViews = totalViews / viewsArray.length;
        const sortedViews = [...viewsArray].sort((a, b) => a - b);
        const medianViews = sortedViews.length % 2 === 0
            ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
            : sortedViews[Math.floor(sortedViews.length / 2)];
        const maxViews = Math.max(...viewsArray);
        const minViews = Math.min(...viewsArray);
        const standardDeviation = Math.sqrt(viewsArray.reduce((acc, curr) => acc + Math.pow(curr - averageViews, 2), 0) / viewsArray.length);
        const aboveMedianViews = viewsArray.filter(view => view > medianViews).reduce((acc, curr) => acc + curr, 0);
        const belowOrEqualMedianViews = viewsArray.filter(view => view <= medianViews).reduce((acc, curr) => acc + curr, 0);
        const totalViewsConsidered = aboveMedianViews + belowOrEqualMedianViews;
        const weightedRatio = (aboveMedianViews / totalViewsConsidered) * 100;

        const fullStats = {
            totalViews,
            averageViews,
            medianViews,
            maxViews,
            minViews,
            standardDeviation,
            weightedRatio,
        };

        setFullDatasetStats(fullStats);
    };

    const fetchThumbnails = async (limit) => {
        // setLoadingProgress(70);
        setLoading(true);
        setLoadingState("Retrieving & calculating...");

        const recordsRef = collection(db, "Users", mainUserId, "ChannelThumbnailsData");
        const q = query(
            recordsRef,
            where('position', '>=', 0),
            where('position', '<', limit),
            orderBy('position')
        );

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            setEmpty(true);
            setLoading(false);
            setLoadingState('No thumbnails found');
            setMessage("No videos found on your channel.\nShorts and playlists are not supported.");
        } else {

            const data = querySnapshot.docs.map(doc => doc.data());

            if (data.length !== 0) {
                setLoadingProgress(0);
                const viewsArray = data.map(item => convertViews(item.views));
                const totalViews = viewsArray.reduce((acc, curr) => acc + curr, 0);
                const averageViews = totalViews / viewsArray.length;
                const sortedViews = [...viewsArray].sort((a, b) => a - b);
                const medianViews = sortedViews.length % 2 === 0
                    ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
                    : sortedViews[Math.floor(sortedViews.length / 2)];
                const maxViews = Math.max(...viewsArray);
                const minViews = Math.min(...viewsArray);
                const standardDeviation = Math.sqrt(viewsArray.reduce((acc, curr) => acc + Math.pow(curr - averageViews, 2), 0) / viewsArray.length);

                const aboveMedianViews = viewsArray.filter(view => view > medianViews).reduce((acc, curr) => acc + curr, 0);
                const belowOrEqualMedianViews = viewsArray.filter(view => view <= medianViews).reduce((acc, curr) => acc + curr, 0);
                const totalViewsConsidered = aboveMedianViews + belowOrEqualMedianViews;
                const weightedRatio = (aboveMedianViews / totalViewsConsidered) * 100;

                const newStats = {
                    totalViews,
                    averageViews,
                    medianViews,
                    maxViews,
                    minViews,
                    standardDeviation,
                    weightedRatio,
                };

                setCurrentStats(newStats);

                if (fullDatasetStats) {
                    const percentageChange = {
                        medianChange: ((newStats.medianViews - fullDatasetStats.medianViews) / fullDatasetStats.medianViews) * 100,
                        standardDeviationChange: ((newStats.standardDeviation - fullDatasetStats.standardDeviation) / fullDatasetStats.standardDeviation) * 100,
                        viewRatioChange: ((newStats.weightedRatio - fullDatasetStats.weightedRatio) / fullDatasetStats.weightedRatio) * 100,
                    };
                    setPercentageChange(percentageChange);
                }

                const transformedData = data.map(item => {
                    const viewsModified = convertViews(item.views);
                    return {
                        ...item,
                        viewsModified,
                        outlierScore: computeOutlierScore(viewsModified, medianViews),
                    };
                });

                const sortedTransformedData = transformedData.sort((a, b) => a.position - b.position);
                const filteredData = sortedTransformedData.filter(item => item.outlierScore <= 0);
                const selectedItem = filteredData[0] || sortedTransformedData.find(item => item.outlierScore === 0);

                if (selectedItem) {
                    setViewTotal(totalViews);
                    setViewAverage(medianViews);
                    setVideoId(selectedItem.video_id);
                    setThumbnailData([selectedItem]);
                    setSelectedThumbnail(selectedItem); // Set the selected thumbnail here
                } else {
                    setEmpty(true);
                    setMessage("No suitable thumbnails found.");
                }


                setViewTotal(totalViews);
                setViewAverage(medianViews);
                setThumbnailData(sortedTransformedData);
                setLoading(false);

            } else {
                setEmpty(true);
                setLoading(false);
                setLoadingState('No thumbnails found');
                setMessage("No thumbnails found on your channel.\nShorts and playlists are not supported.");
            }
            // dCredits(transformedData.length)
        }
    }

    const addQnAData = async () => {
        const userDoc = doc(db, "QnA", user_id)
        const data = {
            biggest_challenge: selectedVQ.name,
            how_speed_up: selectedGoal.name
        }
        setDoc(userDoc, data, { merge: true });
    }

    const goToNext = async (event) => {
        addQnAData();
        const userDoc = doc(db, "Users", user_id)
        const data = {
            page_touch_qa: true,
            channelData_medianViews_8: currentStats?.medianViews,
            channelData_standardDeviation_8: currentStats?.standardDeviation,
            channelData_weightedRatio_8: currentStats?.weightedRatio,
        }
        setDoc(userDoc, data, { merge: true }).then(() => {
            navigate(`/mid/${user_id}`);
        });
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>First Steps - QA | ThumbnailGeek</title>
                <meta name="description" content="First Steps - QA | ThumbnailGeek" />
            </Helmet>

            <div className="flex flex-col h-screen">
                <div className="px-6 pt-4">
                    {/* Start Nav */}
                    <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4'>
                        <div className="flex">
                            <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                                <img src={require('../../../assets/svgs/tg_logo_full.svg').default} />
                            </a>
                        </div>
                        <AvatarNoPremium />
                        {/* <img
                            className="h-8 w-8 rounded-full ring-1 ring-gray-600"
                            src={channelPhotoUrl == null ? '' : channelPhotoUrl}
                            alt=""
                        /> */}
                    </div>
                    {/* End Nav */}
                </div>

                <div className="flex flex-1 px-10 h-full">
                    <div className="w-1/2 flex flex-1 flex-col justify-left lg:flex-none h-full">
                        <div className="w-full lg:w-full h-full">

                            <div className="flex flex-col h-full justify-center">
                                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">

                                    {
                                        empty !== true
                                            ?
                                            loading === true
                                                ?
                                                (
                                                    <>
                                                        <div className="max-w-2xl inline-flex space-x-3" aria-hidden="true">
                                                            <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                                <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                                            </div>
                                                            <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                                <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                                            </div>
                                                            <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                                <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                                            </div>
                                                        </div>

                                                        <div className="pr-4">
                                                            <h2 className="mt-8  text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                                A few stats before we go on...
                                                            </h2>
                                                        </div>
                                                        <div className="min-h-[300px]">
                                                            <div className="w-full flex flex-col justify-center mt-32">
                                                                <div className="flex h-2 w-32 mb-4 mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                    <div
                                                                        style={{ width: `${loadingProgress}%` }}
                                                                        className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                    />
                                                                </div>
                                                                {loadingState}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                <>
                                                    <div className="max-w-2xl inline-flex space-x-3" aria-hidden="true">
                                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                                        </div>
                                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                                                        </div>
                                                        <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                                                            <div className="h-2 rounded-full bg-blue-500" style={{ width: '0%' }} />
                                                        </div>
                                                    </div>

                                                    <div className="pr-4">
                                                        <h2 className="mt-8  text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                            A few stats...
                                                        </h2>
                                                    </div>
                                                    <div className="min-h-[300px]">
                                                        <div className='flex flex-col'>
                                                            <div className="text-sm text-gray-700">
                                                                Based on data from latest videos
                                                            </div>
                                                            <div className="mt-9 ">

                                                                <div className='text-gray-800 max-w-xl pb-5 text-[0.9rem] mb-5'>
                                                                    <div className='flex flex-col justify-center w-full'>
                                                                        <div className='flex-col w-72'>
                                                                            <div className="mb-2 relative group cursor-pointer">
                                                                                <div className="hover:ring-2 rounded-lg hover:ring-blue-500 inline-block overflow-hidden w-80 h-[80%] cursor-pointer duration-200">
                                                                                    <YouTubeThumbnail
                                                                                        videoId={selectedThumbnail?.video_id}
                                                                                        title={selectedThumbnail?.title}
                                                                                        onImageLoad={setCurrentThumbnail}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='flex'>
                                                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={channelPhotoUrl} alt="" />
                                                                                </div>
                                                                                <div className='flex-col w-9/12 ml-1'>
                                                                                    <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                        <p className='text-[14px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[15px] mb:text-mb'>{selectedThumbnail?.title}</p>
                                                                                    </div>
                                                                                    <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                                        <p className='text-gray-500 leading-4 line-clamp-1'>{channelDisplayName}
                                                                                            {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                        </p>
                                                                                    </div>

                                                                                    <div className='text-[12px] font-roboto lg:text-[13px]'>
                                                                                        <p className='text-gray-500 leading-4 line-clamp-1 space-x-1 mt-[2px]'>
                                                                                            <span className='m-0 p-0'>{selectedThumbnail?.views}</span>
                                                                                            <span className='m-0 p-0'>•</span>
                                                                                            <span className='m-0 p-0'>{selectedThumbnail?.upload_date}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            <div className={`mt-9 w-full cursor-pointer bg-blue-100 text-blue-800 flex flex-col rounded-lg px-2.5 py-1.5 text-sm font-medium`}>
                                                                                <Tooltip content='Views calculated from channel views trends' position={'bottom'}>
                                                                                    <div className="inline-flex items-baseline">
                                                                                        <RxDot
                                                                                            className=" mr-0.5 h-5 w-5 flex-shrink-0 self-center text-blue-500"
                                                                                        />
                                                                                        <p className=' mr-1 text-base font-semibold'>{Math.abs(currentStats?.medianViews)} </p>
                                                                                        <p className='text-sm font-normal'> views expected</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>

                                                                            <div className='mt-2 inline-flex justify-between items-end w-full'>
                                                                                {
                                                                                    selectedThumbnail?.outlierScore === 0
                                                                                        ?
                                                                                        <div className={`w-full cursor-pointer bg-gray-100 text-gray-800 flex flex-col rounded-lg px-2.5 py-1.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                            <Tooltip content='How much change from expected views' position={'bottom'}>
                                                                                                <div className="inline-flex items-baseline">
                                                                                                    <RxDot
                                                                                                        className=" mr-0.5 h-5 w-5  flex-shrink-0 self-center text-gray-500"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                    <p className=' mr-1 text-base font-semibold'>{Math.abs(selectedThumbnail?.outlierScore)}% </p>
                                                                                                    <p className='text-sm font-normal'> no change in views</p>
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        :
                                                                                        selectedThumbnail?.outlierScore < 0
                                                                                            ?
                                                                                            <div className={`w-full cursor-pointer bg-red-100 text-red-800 flex flex-col rounded-lg px-2.5 py-1.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                                <Tooltip content='How much decrease from expected views' position={'bottom'}>
                                                                                                    <div className="inline-flex items-baseline">
                                                                                                        <BiDownArrowAlt
                                                                                                            className=" mr-0.5 h-5 w-5  flex-shrink-0 self-center text-red-500"
                                                                                                            aria-hidden="true"
                                                                                                        />
                                                                                                        <p className=' mr-1 text-base font-semibold'>{Math.abs(selectedThumbnail?.outlierScore)}% </p>
                                                                                                        <p className='text-sm font-normal'> decrease in views</p>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            </div>

                                                                                            :
                                                                                            <div
                                                                                                className={` w-full cursor-pointer bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0`}>
                                                                                                <ArrowUpIcon
                                                                                                    className=" mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                {Math.abs(selectedThumbnail?.outlierScore)}%
                                                                                            </div>
                                                                                }
                                                                            </div>

                                                                            <div className={`mt-2 w-full cursor-pointer bg-red-100 text-red-800 flex flex-col rounded-lg px-2.5 py-1.5 text-sm font-medium`}>
                                                                                <Tooltip content='How unstable your views are' position={'bottom'}>
                                                                                    <div className="inline-flex items-baseline">
                                                                                        <BiUpArrowAlt className="mr-0.5 h-5 w-5  flex-shrink-0 self-center text-red-500" />
                                                                                        <p className='mr-1 text-base font-semibold'>{fullDatasetStats?.standardDeviation ? fullDatasetStats.standardDeviation.toFixed(1) : '0.00'} </p>
                                                                                        <p className='text-sm font-normal'> view variance</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="block mt-10 w-80">
                                                                    <button onClick={() => goToNext()}
                                                                        className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-2.5 text-sm font-semibold 
                                                                        leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 
                                                                        focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                    >
                                                                        Got it. Take me in
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>


                                            :
                                            <>

                                                <div className="flex flex-col  justify-center">
                                                    <FiAlertTriangle className='w-16 h-16 animate-pulse text-gray-500' />
                                                    <div className="flex w-1/2 mb-4 mt-3 rounded-sm overflow-hidden text-left text-base text-orange-700">
                                                        {message}
                                                    </div>
                                                </div>

                                                <div className="block mt-10 w-80">
                                                    <button onClick={() => goToNext()}
                                                        className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-2.5 text-sm font-semibold 
                                                                        leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 
                                                                        focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                    >
                                                        Got it. Take me in
                                                    </button>
                                                </div>
                                            </>


                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block h-full">
                        <div className="w-full lg:w-full h-full bg-red-100">
                            <div className="mx-auto max-w-2xl flex flex-col h-full items-center justify-center">
                                <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">More Views Equals More Revenue.</h2>
                                <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
                                    No more guessing. Test your thumbnail on Youtube Homepage<br />before you upload. Plus more.
                                </p>
                                <img
                                    className=" w-2/3 object-contain mb-4 mt-5 rounded-md"
                                    src={tester}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FirstStepsQA