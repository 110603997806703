import React, { useEffect, useState } from 'react'
import sad_man from "../../../assets/gifs/raining_david_tennant.gif";

const plans = [
  {
    text: "It is difficult to use",
  },
  {
    text: "Did not work on my browser",
  },
  {
    text: "It is missing features I want",
  },
  {
    text: "It messes up my Youtube experience",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExtensionUninstall = () => {
  useEffect(() => {
    const removeCookie = () => {
      const cookieName = "TG_CEF";
      const domain = "app.thumbnailgeek.com";
      const path = "/";

      //Remove the cookie
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain};`;
    };

    removeCookie();
  }, []);

  return (
    <div>
      <>
        <div className="bg-gray-50 w-full min-h-screen min-w-screen">
          <div className=" px-6 py-4">
            {/* Start Nav */}
            <div className="border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4">
              <div className="flex justify-between w-full">
                {/* <a href="https://www.thumbnailgeek.com" className="-m-1.5 p-1.5">
                  <img src={require('../../../assets/svgs/tg_logo_full.svg').default} alt="ThumbnailGeek Logo" />
                </a> */}
                <a className='w-48 text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="https://www.thumbnailgeek.com">
                  <img src={require('../../../assets/svgs/tg_logo_full.svg').default} />
                </a>
                <a href="https://chromewebstore.google.com/u/1/detail/thumbnailgeek-ai-youtube/oiipoobgfajjjbiagjbpghlkldgaadpk">
                  <button className="px-3.5 py-1 ring-1 ring-blue-500 rounded-md text-blue-500 text-sm cursor-pointer">
                    Install Extension
                  </button>
                </a>
              </div>
            </div>
            {/* End Nav */}
          </div>

          <div className="text-center flex flex-col items-center justify-center">
            <h1 className="mb-4 mt-7 text-3xl tracking-tight font-bold text-gray-900 md:text-7xl">
              We are sorry
              <br />
              to see you go
            </h1>

            <h2 className="text-xl font-semibold leading-7 text-gray-600 mt-7 mb-16">
              Its not you, its us.
              <br />
              We did something wrong.
            </h2>

            <div className="grid grid-cols-2 pb-24">
              <div className="flex items-center justify-center">
                <img
                  src={sad_man}
                  alt="sad man gif"
                  width={700}
                  height={25}
                />
              </div>

              <div className="w-full flex flex-col items-start justify-start col-span-1 overflow-y-auto border-l border-gray-200 px-4 sm:px-6 lg:px-8 xl:block">
                <h3 className="text-xl font-bold leading-6 text-gray-900 text-left mb-10">
                  Please help us improve. Why did you uninstall?
                </h3>

                <div>
                  <legend className="sr-only">Options</legend>
                  <div className="space-y-9">
                    {plans.map((plan) => (
                      <div key={plan.id} className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id={plan.id}
                            aria-describedby={`${plan.id}-description`}
                            name="plan"
                            type="radio"
                            defaultChecked={plan.id === "small"}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-base leading-6">
                          <label
                            htmlFor={plan.id}
                            className="font-medium text-gray-900"
                          >
                            {plan.text}
                          </label>{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <button className="float-left bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10">
                  Send Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default ExtensionUninstall