import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import logo from '../../../assets/logo_full_32.png';
import { MdOutlineArrowBack } from "react-icons/md";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { db } from '../../../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import Avatar from '../../components/Avatar';

const SubCheckOut = () => {
    let { plan_id } = useParams();
    const navigate = useNavigate();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const mainEmail = localStorage.getItem("mainEmail")
    const mainUserId = localStorage.getItem("mainUserId")
    const [perks, setDocPerks] = useState([]);
    const [docData, setDocData] = useState([]);
    const [planId, setPlanId] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [paymentError, setPaymentError] = useState(false);

    const saveData = async (data) => {
        const subscriptionDoc = doc(db, "Subscriptions", data.subscriptionID)
        const putData = {
            user_id: mainUserId,
            order_id: data.orderID,
            subscription_plan: plan_id,
            subscription_id: data.subscriptionID,
            payment_source: data.paymentSource,
            facilitator_access_token: data.facilitatorAccessToken,
            status: "PENDING",
            plan_id: plan_id,
            plan_name: docData.title,
            plan_price: docData.price,
            date_created: serverTimestamp(),
        }
        await setDoc(subscriptionDoc, putData)
            .then(async res => {
                const userDoc = doc(db, "Users", mainUserId)
                const uData = {
                    subscription_id: data.subscriptionID,
                    subscription_plan: plan_id,
                    plan_name: docData.title,
                    plan_status: "PENDING",
                    account_status: "ACTIVE",
                    premium_status: "PREMIUM",
                    date_modified: serverTimestamp(),
                }

                await updateDoc(userDoc, uData)
                    .then(res => {
                        setAuthorized(true);
                        console.log('Subscribed!');
                    });

                // // Get the referring user's ID from the referral link
                // const referralLinkId = new URLSearchParams(window.location.search).get('referral');

                // // Query the referralLinks collection for the referral link document
                // const referralLinkRef = doc(firestore, 'referralLinks', referralLinkId);
                // await updateDoc(referralLinkRef, { credited: true });
            });
    }

    useEffect(() => {
        const getPricingDetails = async () => {
            const docRef = doc(db, 'Plans', plan_id);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                console.log("Document data:", docSnapshot.data());
                setPlanId(docSnapshot.data().planId)
                setDocPerks(docSnapshot.data().perks)
                setDocData(docSnapshot.data())
                if (docData) {
                    setLoaded(true);
                }
            } else {
                navigate(`/404`)
                console.log("No such document!");
            }
        }
        getPricingDetails();
    }, []);

    const onError = (data, actions) => {
        setPaymentError(true);
        setErrorMessage("Error with payment");
    };

    const createSubscription = (data, actions) => {
        console.log("Create Subscription function");
        console.log(data);
        console.log(actions);
        return actions.subscription.create({
            plan_id: planId,
        });
    };

    const onApprove = (data, detail) => {
        console.log("Paypal approved");
        console.log(data);

        saveData(data);
    };

    const goToDashBoard = () => {
        let path = `/dashboard/`;
        navigate(path);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Check Out</title>
                <meta name="description" content="Check Out Page for Thumbnail Geek" />
            </Helmet>
            <div className='bg-gray-50'>
                <div className="ml-auto mb-6 lg:w-[100%]">
                    <div className="px-6 min-h-screen">
                        {/* Start Nav */}
                        <div className='fixed top-0 left-0 z-50 bg-gray-50 px-8 py-3 pt-6 border-gray-200 w-full flex justify-between lg:border-b mb-4'>
                            <div className="flex">
                                <button onClick={() => navigate('/billing/plans')} className='text-gray-800 bg-gray-100 hover:bg-gray-200 hover:text-gray-600 mr-4 pr-2 py-2 pl-2 rounded-md inline-flex justify-center items-center text-xl duration-200'>
                                    <MdOutlineArrowBack />
                                </button>
                                <a className='w-48 text-gray-800 relative hover:text-gray-700 mt-1' href="#">
                                    <img src={logo} />
                                </a>
                            </div>
                            <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <!-- Profile dropdown --> */}
                                <Avatar />
                            </div>
                        </div>
                        {/* End Nav */}

                        <div className='pt-16'>
                            {
                                authorized === true
                                    ?
                                    <div className="relative flex justify-center items-center ">
                                        <div className="2xl:container 2xl:mx-auto py-10 px-4 md:px-28 flex justify-center items-center">
                                            <div className="w-96 md:w-auto dark:bg-gray-800 relative flex flex-col justify-center items-center bg-white py-16 px-4 md:px-24 xl:pt-20 xl:pb-24 xl:px-36 shadow-md rounded-lg border border-gray-200">
                                                <div className="mt-12">
                                                    <h1 role="main" className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Payment successful</h1>
                                                    <h3 role="sub" className="mt-6 text-lg dark:text-white lg:text-2xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Thank you!</h3>
                                                </div>
                                                <div className="mt">
                                                    <p className="mt-6 sm:w-96 text-base dark:text-white leading-7 text-center text-gray-800">Your account has been activated.<br />Get started with the incredible tools to help your youtube channel grow rapidly!</p>
                                                </div>
                                                <button onClick={() => { goToDashBoard() }} className="w-full dark:text-gray-800 dark:hover:bg-gray-100 dark:bg-white sm:w-auto mt-14 text-base leading-4 text-center rounded-xl text-white py-6 px-16 focus:outline-none bg-gray-700 hover:bg-gray-800 active:bg-black ">Back to dashboard</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="mt-12">
                                            {
                                                loaded === true
                                                    ?
                                                    <>
                                                        {
                                                            paymentError === true
                                                                ?

                                                                <div className="relative flex justify-center items-center ">
                                                                    <div className="2xl:container 2xl:mx-auto py-10 px-4 md:px-28 flex justify-center items-center">
                                                                        <div className="w-96 md:w-auto dark:bg-gray-800 relative flex flex-col justify-center items-center bg-white py-16 px-4 md:px-24 xl:pt-20 xl:pb-24 xl:px-36 shadow-md rounded-lg border border-gray-200">
                                                                            <div className="mt-12">
                                                                                <h1 role="main" className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Payment failed</h1>
                                                                                <h3 role="sub" className="mt-6 text-lg dark:text-white lg:text-2xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Kindly check your payment details and try again</h3>
                                                                            </div>
                                                                            <button onClick={() => { setPaymentError(false) }} className="w-full dark:text-gray-800 dark:hover:bg-gray-100 dark:bg-white sm:w-auto mt-14 text-base leading-4 text-center rounded-xl text-white py-6 px-16 focus:outline-none bg-gray-700 hover:bg-gray-800 active:bg-black ">Try Again</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                :

                                                                <div className="grid grid-cols-12 bg-white shadow-md rounded-lg border border-gray-200 max-w-5xl mx-auto">

                                                                    <div className="relative col-span-full md:col-span-7 px-6 pt-6 pb-7">
                                                                        <h2 className="text-3xl text-gray-800 font-normal text-left mt-2 mb-4 pb-4 border-b border-gray-300">Checkout</h2>
                                                                        <div className="w-full inline-flex justify-center items-center mt-10">
                                                                            <img className="h-10 w-10 rounded-full" src={channelPhotoUrl == null ? '' : channelPhotoUrl} alt="" />
                                                                            <div className="ml-3 ">{channelDisplayName}</div>
                                                                        </div>
                                                                        <div className="mt-5 text-center text-gray-500">Get access to incredible thumbnail testing tools with a <br />Debit/Credit Card or Paypal Checkout</div>
                                                                        <div className="xl:w-3/5 lg:4/5 mx-auto mt-10">
                                                                            <PayPalScriptProvider
                                                                                options={{
                                                                                    "client-id": `AU_CTNWyEXtrWpONOcVYacExZUhoSC_RC2wafuCX3jydVHh16tPNPGf-AP4RV_7YdmH4GXvK2WFnEOdc`,
                                                                                    intent: "subscription",
                                                                                    vault: true,
                                                                                }}>
                                                                                <PayPalButtons
                                                                                    style={{
                                                                                        layout: "vertical",
                                                                                        color: "silver",
                                                                                    }}
                                                                                    createSubscription={createSubscription}
                                                                                    onApprove={onApprove}
                                                                                    onError={onError}

                                                                                />
                                                                            </PayPalScriptProvider>
                                                                        </div>

                                                                    </div>

                                                                    <div className="relative col-span-full md:col-span-5 border-l border-yellow-900 p-6">
                                                                        <p>{docData.title}</p>
                                                                        <div className="inline-flex">
                                                                            <h2 className="text-6xl text-gray-800 font-semibold text-left mt-2">${docData.price}</h2>
                                                                            <p className="text-xl ml-1 mt-10">/ {docData.duration}</p>
                                                                        </div>
                                                                        <p className="text-[12px] text-gray-500 my-2">Automatically renews after 1 {docData.duration}</p>

                                                                        <ul className=" my-7 text-sm">
                                                                            {
                                                                                perks && perks?.map((perk, index) => (
                                                                                    <li key={index} className="flex items-center py-1">
                                                                                        <svg className="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                                                                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                                                                        </svg>
                                                                                        <div className="text-sm text-gray-700">{perk}</div>
                                                                                    </li>

                                                                                ))
                                                                            }
                                                                        </ul>

                                                                        <hr className='mt-16'></hr>
                                                                        <div className="mt-12 text-gray-700 w-full px-8">
                                                                            " This is the best decision I've made my channel. My views have grown from 6K per video to 30K per video "
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <div className="w-full flex flex-col justify-center mt-32 items-center">Doing some things...</div>
                                                    </>
                                            }
                                        </div>
                                    </>

                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SubCheckOut