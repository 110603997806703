import React, { useState, useEffect, useRef } from 'react';
import { BsStar } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { db, storage } from '../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, deleteDoc, setDoc, getDoc, doc, getDocs, orderBy, query, endBefore, limit } from "firebase/firestore";
import { FiExternalLink } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import DotDotDot from './DotDotDot';
import TrustPilotReviewButton from './TrustPilotReviewButton';

const FeedbackBanner = ({ currentPageUrl }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [isNegativePopupOpen, setIsNegativePopupOpen] = useState(false);
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const modalRef = useRef(null);


    useEffect(() => {
        const storedPreference = localStorage.getItem(`feedbackBannerDismissed_${currentPageUrl}`);
        if (storedPreference === 'true') {
            setIsVisible(false);
        }
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem(`feedbackBannerDismissed_${currentPageUrl}`, 'true');
    };

    const handleFeedback = (response) => {
        if (response === true) {
            // handleDismiss();
            setIsPopupOpen(true);
        }

        if (response === false) {
            // handleDismiss();
            setIsNegativePopupOpen(true);
        }
    }


    const saveUserFeedback = async (type) => {
        setSubmitting(true);
        try {
            const docRef = doc(db, "Users", mainUserId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();

                const feedback = {
                    date_created: serverTimestamp(),
                    review_type: type,
                    review_text: review,
                    rating_given: rating,
                    rating_max: 5,
                    page_reviewed: currentPageUrl ?? '',
                    user_name: data.user_name ?? '',
                    channelId: data.channel_id ?? '',
                    channel_name: data.channel_name ?? '',
                    channel_photo_url: data.channel_photo_url ?? '',
                    yt_username: data.yt_username ?? ''
                }
                const userFeedbackRef = collection(db, "UserFeedback")
                await addDoc(userFeedbackRef, feedback);
                console.log("Feedback saved successfully");
            } else {
                console.log("User document does not exist");
            }
        } catch (error) {
            console.error("Error saving feedback:", error);
            // Optionally, set an error state or notify the user
        } finally {
            setSubmitting(false);
        }
    }

    const handleSubmitReview = () => {
        console.log(`User submitted review: ${review} with rating: ${rating}`);
        saveUserFeedback('positive').then(() => {
            toast.success('Thank you for your feedback!', {
                position: "top-right",
                autoClose: 2000,
            });
            closeModal();
            handleDismiss();
        });
    };

    const handleSubmitNegativeFeedback = () => {
        console.log(`User submitted review: ${review} with rating: ${rating}`);
        saveUserFeedback('negative').then(() => {
            toast.success(`We'll work on it! Thank you.`, {
                position: "top-right",
                autoClose: 2000,
            });
            closeModal();
            handleDismiss();
        });
    };

    const handleExternalReview = (platform) => {
        console.log(`User chose to review on ${platform}`);
        closeModal();
        // handleDismiss();
    };

    const closeModal = () => {
        setIsPopupOpen(false);
        setIsNegativePopupOpen(false);
        setReview('');
        setRating(0);
    };

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    if (!isVisible) return null;

    return (
        <>
            <div className="pointer-events-none fixed z-50 inset-x-0 bottom-10 sm:flex sm:justify-center sm:px-6 sm:pb-3 lg:px-8">
                <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 ring-1 ring-white">
                    <p className="text-sm leading-6 text-white">
                        <strong className="font-semibold">Is this feature helpful?</strong>
                        <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        <button onClick={() => handleFeedback(true)} className="hover:underline">Yes</button>
                        <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        <button onClick={() => handleFeedback(false)} className="hover:underline">No</button>
                    </p>
                    <button type="button" className="-m-1.5 flex-none p-1.5" onClick={handleDismiss}>
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5 text-white" />
                    </button>
                </div>
            </div>

            {isPopupOpen && (
                <div onClick={handleOutsideClick} className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
                    <div ref={modalRef} className="relative bg-white p-4 pt-4 rounded-lg max-w-md w-full">
                        <div onClick={closeModal} className="absolute right-2 top-2 p-2 cursor-pointer rounded-lg hover:bg-gray-100"><MdClose /></div>
                        <h2 className="text-xl font-bold mb-4 text-gray-800"> Share Your Feedback</h2>
                        <p className="mb-4 text-sm text-gray-700">We're thrilled to hear you found this tool helpful! Your positive experience can inspire others. Would you mind sharing what you loved about it?</p>
                        <div className="flex justify-center space-x-2 mb-4">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <BsStar
                                    key={star}
                                    className={`cursor-pointer ${star <= rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`}
                                    onClick={() => setRating(star)}
                                />
                            ))}
                        </div>
                        <textarea
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            placeholder="Write your review here..."
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                            rows={4}
                        />
                        <div className="flex">
                            <div className="flex items-start pt-0.5">
                                {/* <p className="text-xs mb-1 pl-1">Share on:</p> */}
                                <div className="">
                                    {/* <button onClick={() => handleExternalReview('TrustPilot')} className="text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100"> */}
                                    <TrustPilotReviewButton />
                                    {/* <FiExternalLink className="inline-block ml-1 h-4 w-4" /> */}
                                    {/* </button> */}
                                    {/* <button onClick={() => handleExternalReview('Twitter')} className="text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100">
                                        X/Twitter <FiExternalLink className="inline-block ml-1 h-4 w-4" />
                                    </button> */}
                                </div>
                            </div>

                            {
                                submitting === true
                                    ?
                                    <button disabled className="w-40 h-10 flex flex-col items-center justify-center bg-gray-300 text-white rounded">
                                        <DotDotDot />
                                    </button>
                                    :
                                    <button onClick={handleSubmitReview} className="w-40 h-10 items-center bg-blue-500 text-white rounded hover:bg-blue-600">
                                        Submit Review
                                    </button>
                            }

                        </div>
                    </div>
                </div>
            )}

            {isNegativePopupOpen && (
                <div onClick={handleOutsideClick} className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
                    <div ref={modalRef} className="relative bg-white p-4 rounded-lg max-w-md w-full">
                        <div onClick={closeModal} className="absolute right-2 top-2 p-2 cursor-pointer rounded-lg hover:bg-gray-100"><MdClose /></div>
                        <h2 className="text-xl font-bold mb-4 text-gray-800">What can we do to improve?</h2>
                        <p className="mb-4 text-sm text-gray-700">Could you tell us what didn't meet your expectations? Your insights will directly contribute to making our tool better for everyone.</p>

                        <textarea
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            placeholder="Share what you would like to be improved..."
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                            rows={4}
                        />
                        <div className="flex justify-end items-center">

                            {
                                submitting === true
                                    ?
                                    <button disabled className="w-40 h-10 flex flex-col items-center justify-center bg-gray-300 text-white rounded">
                                        <DotDotDot />
                                    </button>
                                    :
                                    <button onClick={handleSubmitNegativeFeedback} className="w-40 h-10 flex flex-col items-center justify-center bg-blue-500 text-white rounded hover:bg-blue-600">
                                        Send Feedback
                                    </button>
                            }

                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default FeedbackBanner;