import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDropzone } from 'react-dropzone';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { db, storage } from '../../../firebase-config';
import useDynamicRefs from 'use-dynamic-refs';
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell } from "react-icons/hi";
import { FaTrashAlt } from "react-icons/fa";
import { SlRefresh } from "react-icons/sl";
import { TfiTrash } from "react-icons/tfi";
import { RiAlertFill } from "react-icons/ri";
import { IoSparkles, IoSparklesOutline, IoAlertCircle } from "react-icons/io5";
import { Slide, toast } from 'react-toastify';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { logout } from '../../../redux/actions/auth.action';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject, getStorage } from "@firebase/storage";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy, limit, onSnapshot, where } from "firebase/firestore";
import logo from '../../../assets/logo_full_32.png';
import Avatar from '../../components/Avatar';
import SplitTestCollage from '../../components/SplitTestCollage';
import DotDotDot from '../../components/DotDotDot';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import Lottie from "lottie-react";
import image_upload from "../../../assets/animations/image_uploader.json";
import OnboardingPopup from '../../components/OnboardingPopup';
import Tooltip from '../../components/Tooltip';
import { TbFileAnalytics } from 'react-icons/tb';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import { BsExclamationLg } from 'react-icons/bs';
import CreditsPopupStanProTrial from '../../components/CreditsPopupStanProTrial';
import CreditsPopupStanPro from '../../components/CreditsPopupStanPro';
import LoadChannelThumbnails from '../../components/LoadChannelThumbnails';
import YouTubeThumbnail from '../../components/YoutubeThumbnail';
import TrialUserPopup from '../../components/TrialUserPopup';
import TrialUserPopupAnalysis from '../../components/TrialUserPopupAnalysis';
import TrialUserPopupGeneric from '../../components/TrialUserPopupGeneric';

// const useQueryParams = () => {
//     const { search } = useLocation();
//     return new URLSearchParams(search);
// };

const AnalysisUpload = () => {
    const navigate = useNavigate()
    const captionRef = useRef([])
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const imageUrlEncoded = params.get('imageUrl');
    const extractedTitle = params.get('title');
    const [getRef, setRef] = useDynamicRefs();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const mainUserId = localStorage.getItem("mainUserId")
    const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked, checked, aiQuota, aiCreditsLeft } = PremiumCredentials();
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [linkUploading, setLinkUploading] = useState(false);
    const [analysisLoaded, setAnalysisLoaded] = useState(false);
    const [analyses, setAnalyses] = useState([]);
    const [uploadBtnActive, setUploadBtnActive] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const { pathname } = useLocation();
    const [deleting, setDeleting] = useState(false);
    const [refineText, setRefineText] = useState('');
    const [refining, setRefining] = useState(false);
    const [refinedTitles, setRefinedTitles] = useState([]);
    const [blockPopup, setBlockPopup] = useState(false)
    const [showFileRejections, setShowFileRejections] = useState(true);
    const [creditBlockPopup, setCreditBlockPopup] = useState(false)
    const [creditBlockPopupTrial, setCreditBlockPopupTrial] = useState(false)
    const [loadChannelThumbnails, setLoadChannelThumbnails] = useState(false)
    const [isChannelTT, setIsChannelTT] = useState(true);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [thumbnailTitle, setThumbnailTitle] = useState(null);
    const [processTnT, setProcessTnt] = useState(false);
    const fileInputRef = useRef(null);
    const isInitialRender = useRef(true);
    const [isTrialLimitPopupOpen, setIsTrialLimitPopupOpen] = useState(false);
    const [isTrialRestrictedPopupOpen, setIsTrialRestrictedPopupOpen] = useState(false);

    const handleOpenTrialLimitPopup = () => {
        setIsTrialLimitPopupOpen(true);
        toast.warning(<>You have reached your limit for this feature. Upgrade for unlimited access.</>, {
            position: "top-right",
            autoClose: 7000,
        });
    };

    const handleCloseTrialLimitPopup = () => {
        setIsTrialLimitPopupOpen(false);
    };

    const storage = getStorage();

    const checkUserAccess = async () => {
        if (accessLevel === 5) {
            try {
                const colRef = collection(db, 'Users', mainUserId, 'SoloAnalysis');
                const q = query(colRef, limit(2)); // We only need to know if there's more than one
                const querySnapshot = await getDocs(q);

                if (querySnapshot.size > 1) {
                    handleOpenTrialLimitPopup();
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                console.error("Error checking user access:", error);
                return true; // In case of error, allow access to avoid blocking users unnecessarily
            }
        } else {
            return true; // For non-trial users, always allow access
        }
    }

    useEffect(() => {
        const thumbnail = params.get('thnl');
        const title = params.get('title');
        let thumbnailLink = '';

        if (thumbnail && title) {
            //https://img.youtube.com/vi/${videoId}/0.jpg
            //https://img.youtube.com/vi/${videoId}/maxresdefault.jpg

            const videoId = extractVideoId(thumbnail);
            //console.log(thumbnail)
            //console.log(videoId)

            if (videoId !== null) {
                thumbnailLink = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                //console.log(thumbnailLink)

                setIsChannelTT(false)
                setThumbnailUrl(thumbnailLink)
                setThumbnailTitle(title)

                // Clear the query parameters from the URL
                window.history.replaceState({}, document.title, window.location.pathname);
            } else {
                toast.error(<>Unable to get thumbnail. Try again later.</>, {
                    position: "top-right",
                    autoClose: 5000,
                });
            }

        }

    }, []);

    function extractVideoId(link) {
        // Split the link by '/'
        const parts = link.split('/');
        // Find the index of 'vi' or 'vi_webp' in the parts array
        const viIndex = parts.findIndex(part => part === 'vi' || part === 'vi_webp');
        if (viIndex !== -1 && parts[viIndex + 1]) {
            return parts[viIndex + 1];
        }
        return null; // Return null if the video ID is not found
    }

    useEffect(() => {
        const getSoloAnalyses = async () => {
            const colRef = collection(db, 'Users', mainUserId, 'SoloAnalysis');
            const q = query(
                colRef,
                orderBy("date_created", "desc"),
                limit(15)
            );

            await getDocs(q)
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }))
                        .filter(doc => doc.thumbnail && doc.thumbnail !== "" && doc.title && doc.title !== ""); // Enhanced client-side filtering
                    // console.log(newData);
                    setAnalyses(newData);
                    setAnalysisLoaded(true);
                })
                .catch((error) => {
                    console.error("Error fetching documents: ", error);
                });

        }
        getSoloAnalyses();
    }, []);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            if (thumbnailUrl !== null && thumbnailTitle !== null) {
                if (checked) {
                    if (aiCreditsLeft > 100) {

                        setLinkUploading(true);
                        uploadImageFromLink(thumbnailUrl, thumbnailTitle);

                    } else {

                        setIsTrialRestrictedPopupOpen(true);

                        toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                            position: "top-right",
                            autoClose: 5000,
                        });
                    }
                } else {
                    toast.info(<>Preparing. Please wait...</>, {
                        position: "top-right",
                        autoClose: 5000,
                    });
                }
            }
        }
    }, [thumbnailUrl, thumbnailTitle, checked, processTnT]);

    //Check for latest trigger
    useEffect(() => {
        if (checked) {

            const checkLatestTrigger = async () => {
                try {
                    const docRef = doc(db, 'Users', mainUserId);
                    const docSnapshot = await getDoc(docRef);

                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data();
                        const today = new Date().toDateString();
                        const latestTriggerExists = data.hasOwnProperty('latest_trigger');
                        const latestTriggerDateExists = data.hasOwnProperty('latest_trigger_date');
                        const latestTriggerDate = latestTriggerDateExists ? data.latest_trigger_date.toDate().toDateString() : null;
                        const latestTriggerError = data.latest_trigger_error ?? false;

                        // //console.log('Data:', data);
                        // //console.log('Today:', today);
                        // //console.log('latestTriggerDate:', latestTriggerDate);
                        // //console.log('latestTriggerError:', latestTriggerError);

                        if ((latestTriggerExists && latestTriggerDateExists) || latestTriggerError) {
                            if (latestTriggerDate !== null && Date.parse(today) - Date.parse(latestTriggerDate) >= 7 * 24 * 60 * 60 * 1000) {
                                //console.log('Triggering get_thumbnails_free');
                                triggerThumbnails('get_thumbnails_free');
                            } else {
                                if (latestTriggerDate !== today) {
                                    //console.log('Triggering get_thumbnails_30');
                                    triggerThumbnails('get_thumbnails_30');
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error checking latest_trigger: ', error);
                }
            };

            checkLatestTrigger();
        }
    }, []);

    const triggerThumbnails = async (status) => {
        const triggersRef = collection(db, "Users", mainUserId, "Triggers");
        const tDocRef = await addDoc(triggersRef, {
            trigger: status,
            trigger_count: 51,
            trigger_date: serverTimestamp(),
        });

        const docRef = doc(db, "Users", mainUserId);
        const ref = await updateDoc(docRef, {
            latest_trigger: status,
            latest_trigger_fulfilled: false,
            latest_trigger_date: serverTimestamp(),
        });
    }

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    function validator(file) {
        if (file.size > '20000') {
            return {
                code: "size-too-large",
                message: `Image larger than 2MB`
            };
        }
        return null
    }

    const deleteAnalysis = async (analysis_id) => {
        //console.log(analysis_id)

        setDeleting(true);
        const docRef = doc(db, 'Users', mainUserId, 'SoloAnalysis', analysis_id);
        const docSnap = await getDoc(docRef);
        const soloAnalysis = docSnap.data();

        const thumbnailId = soloAnalysis.thumbnail_id;
        const thumbnailUrl = soloAnalysis.thumbnail;

        const thumbnailDocRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnailId);
        const thumbnailRef = ref(storage, thumbnailUrl);

        await deleteDoc(docRef);
        await deleteDoc(thumbnailDocRef);
        await deleteObject(thumbnailRef);

        deleteModalClose();
        setAnalyses((state) => state.filter((item) => item.id !== analysis_id));
        toast("Deleted", {
            icon: ({ theme, type }) => <FaTrashAlt className='text-green-500 h-3 w-3' />
        });

        setDeleting(false);
    }

    const goToAnalysisDetails = (id) => {
        let path = `/thumbnail-analysis/${id}`;
        navigate(path);
    }

    const uploadClicked = () => {
        setUploading(true);
        uploadImage();
    }

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const scaleFactor = 720 / img.width;
                    const canvas = document.createElement("canvas");
                    canvas.width = 720;
                    canvas.height = img.height * scaleFactor;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        blob.lastModifiedDate = new Date();
                        blob.name = file.name;
                        const newFile = new File([blob], file.name, { type: "image/png" });
                        const initialWidth = img.width;
                        const initialHeight = img.height;
                        resolve({ file: newFile, initialWidth, initialHeight });
                    }, "image/png", 0.9);
                };
            };
        });
    };

    const validateImageUpload = async (e) => {
        e.preventDefault();
        if (aiCreditsLeft > 100) {
            uploadImage()
        } else {
            setIsTrialRestrictedPopupOpen(true);

            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 5000,
            });
        }
    }

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const uploadImage = async () => {
        setIsChannelTT(false);
        setUploading(true);

        const hasAccess = await checkUserAccess();

        if (hasAccess) {
            const docRef = doc(db, "Users", mainUserId);
            const colRef = collection(docRef, "SoloAnalysis");
            const addedColDocRef = await addDoc(colRef, {
                user_id: mainUserId,
                test_type: "Analysis",
                date_created: serverTimestamp(),
            });

            //will always be only one image. until its not. :)
            const title = refineText.trimStart().trimEnd();
            //console.log(title);

            if (title !== "" && selectedImage !== null) {
                const { file: resizedImage, initialWidth, initialHeight } = await resizeImage(
                    selectedImage
                );

                const docRef1 = doc(db, "Users", mainUserId);
                const colRef1 = collection(docRef1, "UserThumbnails");
                const newImgResp = await addDoc(colRef1, {
                    user_id: mainUserId,
                    test_type: "Analysis",
                    should_analyse: true,
                    yt_ext: false,
                    predict: !isChannelTT,
                    date_created: serverTimestamp(),
                });
                const extension = resizedImage.type.split("/").pop();
                const imageRef = ref(
                    storage,
                    `/userThumbnails/${mainUserId}/${newImgResp.id}.${extension}`
                );
                const uploadTask = uploadBytesResumable(imageRef, resizedImage, {
                    contentType: "data_url",
                });
                await uploadTask;
                const downloadURL = await getDownloadURL(imageRef);
                const userDoc = doc(
                    db,
                    "Users",
                    mainUserId,
                    "UserThumbnails",
                    newImgResp.id
                );
                const data = {
                    thumbnail: downloadURL,
                    title: title,
                    resolution_width: initialWidth,
                    resolution_height: initialHeight,
                };
                setDoc(userDoc, data, { merge: true });
                updateDoc(
                    doc(db, "Users", mainUserId, "SoloAnalysis", addedColDocRef.id),
                    {
                        thumbnail_id: newImgResp.id,
                        thumbnail: downloadURL,
                        title: title,
                    }
                ).then(async () => {
                    setUploading(false);
                    navigate(`/thumbnail-analysis/${newImgResp.id}`);
                }).catch((e) => {
                    //console.log(e);
                    setUploading(false);
                    toast("An error occured. Unable to upload images!", {
                        icon: ({ theme, type }) => (
                            <HiOutlineBell className="text-red-500 h-7 w-7" />
                        ),
                    });
                });
            } else {
                toast("Please enter a title and select an image!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
                setUploading(false);
            }
        } else {
            setUploadModal(false);
            setSelectedImage([]);
            setIsChannelTT(false);
            setUploading(false);
        }


    };

    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // Remove leading/trailing spaces
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
            }
        }
        return null;
    }

    const uploadImageFromLink = async (thumbnail, title) => {
        setLinkUploading(true);

        const hasAccess = await checkUserAccess();

        if (hasAccess) {

            const token = getCookie('TG_AT');
            const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/analyze-thumbnail-from-link/${mainUserId}`;

            const data = {
                thumbnail: thumbnail,
                title: title,
                predict: !isChannelTT,
            };

            const headers = {
                headers: {
                    'Authorization': `${token}`,
                }
            };

            axios.post(url, data, headers)
                .then((response) => {
                    if (response.status === 200) {
                        const responseData = response.data;
                        // //console.log(responseData, 'data');

                        if (responseData.data.page_id !== null || responseData.data.page_id !== undefined) {
                            // //console.log(responseData.page_id, 'page_id');
                            setThumbnailUrl(null);
                            setThumbnailTitle(null);
                            navigate(`/thumbnail-analysis/${responseData.data.page_id}`);
                            setLinkUploading(false);
                        } else {
                            toast.error('Redirection error. Open your analysis from the list.', {
                                position: "top-right",
                                autoClose: 5000,
                            });
                            setThumbnailUrl(null);
                            setThumbnailTitle(null);
                            setLinkUploading(false);
                        }
                    } else {
                        toast.error('Something went wrong. Please try again later.', {
                            position: "top-right",
                            autoClose: 5000,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);

                    // if (error.response) {
                    //     // //console.log('Data:', error.response.data);
                    //     // //console.log('Status:', error.response.status);
                    //     // //console.log('Headers:', error.response.headers);

                    //     toast.error('Something went wrong. Please try again.', {
                    //         position: "bottom-center",
                    //         autoClose: 7000,
                    //     });
                    // } else {
                    toast.error('Something went wrong. Please try again.', {
                        position: "top-right",
                        autoClose: 5000,
                    });
                    // }

                    setLinkUploading(false);
                });

        } else {
            setUploadModal(false);
            setSelectedImage([]);
            setLinkUploading(false);
        }

    };

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png, image/gif',
        maxSize: 2 * 1024 * 1024, // 2 MB in bytes
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 1) {
                toast.warn('Only one file is accepted. Please select a single file.', {
                    position: "top-right",
                    autoClose: 7000,
                });

                // Clear the file input
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                return;
            }

            const acceptedFile = acceptedFiles[0];

            // Check trial limit before proceeding
            if (accessLevel === 5) {
                const hasAccess = await checkUserAccess();
                if (!hasAccess) {
                    return;
                }
            }

            if (aiCreditsLeft > 100) {
                try {
                    if (fileRejections.length === 0) {
                        const file = acceptedFile;

                        // Create an image object to get the dimensions
                        const img = new Image();
                        img.src = URL.createObjectURL(file);

                        if (file.type.startsWith('image/') && !file.type.startsWith('image/svg')) {
                            img.onload = () => {
                                const width = img.width;
                                const height = img.height;
                                const aspectRatio = width / height;

                                // Check if the aspect ratio is approximately 16:9
                                if (Math.abs(aspectRatio - (16 / 9)) < 0.01) {
                                    setSelectedImage(Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                    }));
                                    setUploadBtnActive(true);
                                    openUploadModal();
                                } else {
                                    // Handle file rejection error
                                    toast('Recommended aspect ratio is 16:9. Image will be cropped on Youtube!', {
                                        icon: ({ theme, type }) => <RiAlertFill className='text-yellow-500 h-5 w-5' />
                                    });

                                    setSelectedImage(Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                    }));
                                    setUploadBtnActive(true);
                                    openUploadModal();
                                }
                            };
                        } else {
                            // Handle rejection due to invalid file type
                            toast.error(`File rejected: ${file.name} - Invalid file type`, {
                                position: "top-right",
                                autoClose: 5000,
                            });
                            // Clear the file input
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            return;
                        }
                    } else {
                        // Handle file rejection and show toast for each rejected file
                        fileRejections.forEach(rejection => {
                            if (rejection.errors[0].code === "file-too-large") {
                                // Handle rejection due to file size
                                const sizeInMB = (rejection.file.size / (1024 * 1024)).toFixed(2);
                                toast.error(`Size Limit Exceeded (2MB Limit) - ${rejection.file.name} - Size: ${sizeInMB} MB`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                });
                                // Clear the file input
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                                return;
                            } else if (rejection.errors[0].code === "file-invalid-type") {
                                // Handle rejection due to invalid file type
                                toast.error(`Invalid File Type - ${rejection.file.name} - Only JPG, GIF, or PNG accepted`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                });
                                // Clear the file input
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                                return;
                            } else {
                                // Handle other types of rejections
                                toast.error(`File rejected - ${rejection.file.name}`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                });
                                // Clear the file input
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                                return;
                            }
                        });
                    }
                } catch (error) {
                    toast.error(error.message, {
                        icon: ({ theme, type }) => <RiAlertFill className='text-orange-400 h-5 w-5' />
                    });
                    // Clear the file input
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    return;
                }
            } else {

                setIsTrialRestrictedPopupOpen(true);

                // toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                //     position: "top-right",
                //     autoClose: 7000,
                // });
            }
        }
    });

    const openDeleteModal = (name, id) => {
        setItemToDelete(name);
        setIdToDelete(id)
        setDeleteModal(true);
    }

    const handleDeleteModalClose = (e) => {
        if (e.target.id === "ast-item-delete-modal") {
            setDeleteModal(false);
        };
    }

    const deleteModalClose = (e) => {
        setDeleteModal(false);
    }

    const openUploadModal = () => {
        setUploadModal(true);
    }

    const handleUploadModalClose = (e) => {
        if (e.target.id === "upload-items-modal" && uploading === false) {
            setUploadModal(false);
            setSelectedImage([])
        };
    }

    const handleUploadModalElementClick = (e) => {
        if (e.target.id === "upload-items-element"
            || e.target.id === "upload-items-image") {
            setRefinedTitles([]);
        };
    }

    const uploadModalClose = (e) => {
        if (uploading === false) {
            setUploadModal(false);
            setSelectedImage([])
        } else {
            toast('Cannot close while uploading!', {
                icon: ({ theme, type }) => <RiAlertFill className='text-orange-400 h-5 w-5' />
            });
        }
    }

    const handleOnTextChanged = (e) => {
        setRefineText(e.target.value);
    }

    const handleSelectOption = (title) => {
        setRefineText(title);
        setRefinedTitles([]);
    }

    const refineTitle = async (e) => {
        e.preventDefault();
        const token = getCookie('TG_AT');

        // if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
        if (aiCreditsLeft > 9) {

            if (refineText.trimStart().trimEnd() !== '' && refineText !== null) {
                setRefining(true);
                const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/optimize-ai-title/${mainUserId}`;
                const data = {
                    prompt: refineText
                };

                const headers = {
                    headers: {
                        'Authorization': `${token}`,
                    }
                };

                axios.post(url, data, headers)
                    .then((response) => {
                        setRefining(false);
                        //console.log(response.data.payload);
                        const titles = response.data.payload.titles;
                        setRefinedTitles(titles);
                        //console.log(titles);
                    })
                    .catch((error) => {
                        setRefining(false);
                        console.error(error);
                        toast.warn('Failed to refine.', {
                            position: "top-right",
                            autoClose: 7000,
                        });
                    });
            }

        } else {
            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }
        // } else {
        //     toast.warn('Upgrade to Basic or Pro or Max package.', {
        //         position: "top-right",
        //         autoClose: 7000,
        //     });
        // }
    }

    const verifyAccount = () => {
        if (accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8) {
            setBlockPopup(true);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Analysis | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Analysis | ThumbnailGeek" />
            </Helmet>

            <BlockPopupStanPro show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanPro show={creditBlockPopup} isVisible={creditBlockPopup} onClose={() => setCreditBlockPopup(false)} pageUrl={'/testing-suite/upload'} />

            <CreditsPopupStanProTrial show={creditBlockPopupTrial} isVisible={creditBlockPopupTrial} onClose={() => setCreditBlockPopupTrial(false)} pageUrl={'/testing-suite/upload'} />

            <LoadChannelThumbnails
                show={loadChannelThumbnails}
                isVisible={loadChannelThumbnails}
                onClose={() => setLoadChannelThumbnails(false)}
                setThumbnailUrl={setThumbnailUrl}
                setThumbnailTitle={setThumbnailTitle}
            />

            <TrialUserPopupAnalysis isOpen={isTrialLimitPopupOpen} onClose={handleCloseTrialLimitPopup} />

            <TrialUserPopupGeneric isOpen={isTrialRestrictedPopupOpen} onClose={() => setIsTrialRestrictedPopupOpen(false)} />

            <div id="upload-items-modal" onClick={handleUploadModalClose} className={`${uploadModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                {
                    uploading === false
                        ?
                        <>
                            <div id="upload-items-element" className="relative bg-white overflow-scroll rounded-lg shadow px-4 py-4">

                                <button onClick={uploadModalClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                                rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 
                                        11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>

                                <h2 className='mb-3 font-bold text-gray-700'>Analyze this with AI</h2>
                                <form onSubmit={validateImageUpload}>
                                    <div className="md:h-auto">

                                        <div className="w-[350px] rounded-md mb-2 flex flex-col relative">
                                            <div className="aspect-w-16 aspect-h-9 relative">
                                                <img id="upload-items-image" onClick={handleUploadModalElementClick} className="w-full border-0 object-cover rounded-md"
                                                    src={selectedImage.preview}
                                                    onLoad={() => { URL.revokeObjectURL(selectedImage.preview) }}
                                                    alt="" />
                                            </div>
                                            <input
                                                onChange={handleOnTextChanged}
                                                value={refineText}
                                                type="text"
                                                className="text-sm border-b border-gray-500 p-0 pl-1 pt-2 pb-1 mt-3 mb-5 
                                            focus:outline-none pr-12" placeholder="Title of your video"
                                            />
                                            <div className="absolute top-[210px] right-0 pr-0 inset-y-0 ">
                                                {
                                                    refineText.trimStart().trimEnd().length > 0
                                                        ?
                                                        <>
                                                            {
                                                                refining === true
                                                                    ?
                                                                    <Tooltip content='Refining...' position='top'>
                                                                        <button disabled="disabled" className={`hover:cursor-default text-gray-600 inline-flex p-[5px]`}>
                                                                            <SlRefresh className='h-4 w-4 mt-[2px] animate-spin'
                                                                                data-tooltip-content="Refining..." />
                                                                        </button>
                                                                    </Tooltip>
                                                                    :
                                                                    <>
                                                                        <Tooltip content='Refine title with AI' position='top'>
                                                                            <button onClick={refineTitle} className={`hover:cursor-pointer hover:text-blue-900 text-blue-500 inline-flex p-[5px]`}>
                                                                                <IoSparkles className='h-4 w-4 mt-[2px]' />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <Tooltip content='Input a title to refine with AI' position='top'>
                                                                <button disabled="disabled" type='submit' className={`hover:cursor-default text-gray-800 inline-flex p-[5px]`}>
                                                                    <IoSparklesOutline
                                                                        className='h-4 w-4 mt-[2px]' />
                                                                </button>
                                                            </Tooltip>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-col text-center">
                                            {
                                                refineText.trimStart().trimEnd().length > 0 && selectedImage != null
                                                    ?
                                                    <button type='submit' className="px-5 py-3 bg-blue-600 hover:bg-blue-700 active:bg-blue-800 hover:cursor-pointer text-white text-sm rounded-lg shadow-sm">
                                                        Begin Analysis
                                                    </button>
                                                    :
                                                    <button type='submit' disabled="disabled" className="px-5 py-3 bg-gray-200 text-gray-500 text-sm rounded-lg shadow-sm">
                                                        Begin Analysis
                                                    </button>
                                            }
                                        </div>
                                    </div>

                                </form>
                            </div>
                            {
                                refinedTitles.length > 0
                                    ?
                                    <div className="absolute mt-[350px] z-50 w-[350px] pr-1">
                                        <div id="dropdownDivider" className=" bg-white divide-y divide-gray-100 h-32 overflow-y-scroll border border-gray-200 rounded shadow dark:bg-gray-700 dark:divide-gray-600">
                                            <ul className="text-sm text-gray-700 dark:text-gray-200">
                                                {
                                                    refinedTitles?.map((titles, i) => (
                                                        titles.AnalysisUploadtitle !== ''
                                                            ?
                                                            <li key={i} onClick={() => handleSelectOption(titles.title)}>
                                                                <p className="hover:cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                    {titles.title}
                                                                    {/* {titles.title = titles.title.replace('"', '')
                                                                        .replace('"', '').replace('1. ', '').replace('2. ', '').replace('3. ', '').replace('4. ', '').replace('5. ', '')
                                                                        .replace('1.', '').replace('2.', '').replace('3.', '').replace('4.', '').replace('5.', '')
                                                                        .replace('1) ', '').replace('2) ', '').replace('3) ', '').replace('4) ', '').replace('5) ', '')
                                                                        .replace(/"/g, '').replace(/\d+\.\s?/g, '').replace(/\d+\)\s?/g, '')
                                                                    } */}
                                                                </p>
                                                            </li>
                                                            : ''
                                                    ))
                                                }
                                            </ul>

                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                        </>
                        :
                        <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg w-96 border  shadow-md">
                            <Lottie animationData={image_upload} loop={true} />
                        </div>
                }
            </div>

            <div id="ast-item-delete-modal" onClick={handleDeleteModalClose} className={`${deleteModal === true ? '' : 'hidden'} fixed top-0 left-0 right-0 z-50 w-full bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                <div className="relative w-full h-full max-w-lg md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-base mt-1 ml-1 font-semibold text-gray-900 dark:text-white">
                                Are you sure you want to delete?
                            </h3>
                            <button onClick={deleteModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className='text-center'>
                                {/* {itemToDelete} */}
                                <div className="rounded-md bg-red-100 p-4">
                                    <div className="">
                                        <div className="">
                                            <div className=" text-sm font-medium text-red-500">
                                                <ul role="list" className="space-y-1">
                                                    <li>Thumbnail, title and it's analysis will be deleted.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            deleting === false
                                ?
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button onClick={deleteModalClose} className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                                    <button onClick={() => deleteAnalysis(idToDelete)} className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Delete</button>
                                </div>
                                :
                                <div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <div className="text-white bg-gray-800 inline-flex space-x-1 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        <span>Deleting</span>
                                        <DotDotDot className="pl-2" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            {
                linkUploading === true && (
                    <div id="upload-link-modal" className={` fixed top-0 left-0 right-0 z-50 w-full py-16 bg-black bg-opacity-10 backdrop-blur-sm p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full flex justify-center items-center`}>
                        <div id='inner-container' className="bg-white p-5 mb-16 rounded-lg w-96 border  shadow-md">
                            <Lottie animationData={image_upload} loop={true} />
                        </div>
                    </div >
                )
            }

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="thumbnail-analysis" darkMode={false} />
                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="pr-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-10 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="inline-flex items-center">
                                {/* <h5 className={` text-sm lg:text-xl text-gray-600 leaging-6 font-semibold lg:inline-flex`}>Analyze <span className='hidden lg:flex'>&nbsp;Your&nbsp;</span>Thumbnail With AI & Psychology</h5> */}
                                <h5 className={` text-sm lg:text-xl text-gray-600 leaging-6 font-semibold lg:inline-flex`}>Thumbnail & Title Intelligence with Viral Psychology</h5>
                                <div className="">
                                    <span className="inline-flex items-center rounded-md bg-purple-100 ml-2 px-2 py-1 
                                text-xs font-medium text-purple-700">
                                        BETA
                                    </span>
                                </div>

                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="mt-16 2xl:max-w-7xl lg:ml-8 xl:ml-6 2xl:ml-8">

                            {/* <h2 className='text-center font-bold text-3xl mb-7 text-gray-600'>An impartial assessment of your thumbnail's ability to get clicks <br /></h2> */}
                            <h2 className='text-center font-bold text-3xl mb-7 text-gray-600'>Select an option to begin analysis <br /></h2>

                            <div className="w-full h-full mx-auto pr-3">

                                <>
                                    {/* {
                                        accessLevel === 0 || accessLevel === 1 || accessLevel === 2 || accessLevel === 3 || accessLevel === 5 || accessLevel === 6 || accessLevel === 7 || accessLevel === 8
                                            ? */}
                                    <div className="w-full h-full grid grid-cols-2 space-x-2">
                                        <label htmlFor="dropzone-file" className="col-span-1 flex flex-col justify-center items-center w-full h-52 mb-2 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer hover:bg-gray-100 " {...getRootProps()}>
                                            <div className="flex flex-col justify-center items-center text-sm pt-5 pb-5 px-5">
                                                <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <>
                                                    <span>Drag and Drop thumbnail here</span>
                                                    <span className="mt-1 text-gray-400">or</span>
                                                    <button className="bg-blue-500 rounded-lg p-2 px-3 mt-2 text-white">Browse Files</button>
                                                </>
                                            </div>
                                            <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                                        </label>

                                        <div className="relative w-full h-52 group">
                                            <div onClick={() => setLoadChannelThumbnails(true)} className="absolute top-0 left-0 flex flex-col items-center justify-center group-hover:cursor-pointer w-full h-full bg-gray-300 bg-opacity-50 hover:bg-opacity-70 rounded-lg">
                                                <button onClick={() => setLoadChannelThumbnails(true)} className="bg-blue-500 group-hover:bg-blue-600 group-hover:cursor-pointer rounded-lg p-2 px-3 mt-2 text-white">Choose from your channel</button>
                                            </div>
                                            <div onClick={() => setLoadChannelThumbnails(true)} className="w-full h-full bg-gray-50 p-4 border-2 border-dotted border-blue-500 rounded-lg flex flex-col bg-opacity-50 group-hover:cursor-pointer">
                                                <div className="w-full rounded-lg bg-gray-400 h-20 flex justify-center items-center">
                                                    <div className="w-full h-full rounded-lg bg-gray-300"></div>
                                                </div>

                                                <div className="inline-flex items-start justify-items-start">
                                                    <div className=" flex flex-col">
                                                        <div className="w-16 h-16 mr-3 mt-3 bg-gray-300 rounded-full"></div>
                                                    </div>

                                                    <div className="w-full mt-3">
                                                        <div className="w-32 h-6 bg-gray-300 rounded"></div>
                                                        <div className="w-48 h-4 mt-1 bg-gray-300 rounded"></div>
                                                        <div className="w-full max-w-5xl flex space-x-4 mt-1">
                                                            <div className="w-64 h-6 bg-gray-300 rounded"></div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    {/* :
                                            <div className="w-full h-full grid grid-cols-2 space-x-2">
                                                <label onClick={verifyAccount} htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-52 mb-8 bg-white rounded-lg border-2 border-blue-300 border-dashed cursor-pointer  hover:bg-gray-100 ">
                                                    <div className="flex flex-col justify-center items-center pt-5 pb-5 px-5">
                                                        <svg className="mb-3 w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                        </svg>
                                                        <>
                                                            <p className="mb-2 text-md text-gray-500 dark:text-gray-400">Upgrade to access this feature</p>
                                                        </>

                                                    </div>
                                                </label>
                                                <div className="relative w-full h-52 group">
                                                    <div className="absolute top-0 left-0 flex flex-col items-center justify-center group-hover:cursor-pointer w-full h-full bg-gray-300 bg-opacity-50 hover:bg-opacity-70 rounded-lg">
                                                        <button className="bg-gray-500 group-hover:bg-gray-600 group-hover:cursor-pointer rounded-lg p-2 px-3 mt-2 text-white">
                                                            Upgrade to access this feature
                                                        </button>
                                                    </div>
                                                    <div className="w-full h-full bg-gray-50 p-4 rounded-lg flex flex-col bg-opacity-50 group-hover:cursor-pointer">
                                                        <div className="w-full rounded-lg bg-gray-400 h-20 flex justify-center items-center">
                                                            <div className="w-full h-full rounded-lg bg-gray-300"></div>
                                                        </div>

                                                        <div className="inline-flex items-start justify-items-start">
                                                            <div className=" flex flex-col">
                                                                <div className="w-16 h-16 mr-3 mt-3 bg-gray-300 rounded-full"></div>
                                                            </div>

                                                            <div className="w-full mt-3">
                                                                <div className="w-32 h-6 bg-gray-300 rounded"></div>
                                                                <div className="w-48 h-4 mt-1 bg-gray-300 rounded"></div>
                                                                <div className="w-full max-w-5xl flex space-x-4 mt-1">
                                                                    <div className="w-64 h-6 bg-gray-300 rounded"></div>
                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                    } */}
                                </>

                                <div className='flex justify-center items-center w-full mx-4 mt-6'>
                                    {/* <h2 className="mb-5 text-sm text-gray-500"><span className='text-gray-500 font-semibold'>Recommended:</span> Upload 2 or 3 different thumbnails of your video for testing.</h2> */}
                                    <div className='flex flex-col items-center justify-center text-bold'>
                                        <p className="text-md font-bold text-gray-700 leading-tight mb-2">You can analyze only 1 thumbnail at a time</p>
                                        <p className="text-xs font-semibold text-gray-500 dark:text-gray-300">JPG, GIF, or PNG (1280x720)</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">• 2MB Limit •</p>
                                    </div>
                                </div>

                                <div className="mt-8">
                                    <div className=" lg:border-b pb-2 font-medium text-neutral-500">
                                        Previous insights
                                    </div>
                                    <div className="py-5 mx-auto">
                                        {
                                            analysisLoaded === true
                                                ?
                                                <>
                                                    {
                                                        <>
                                                            {
                                                                analyses.length === 0
                                                                    ?
                                                                    <div className="flex flex-col items-center justify-center text-center mt-12 mb-12">

                                                                        <TbFileAnalytics className='w-7 h-7 text-gray-600' />
                                                                        <h3 className="mt-2 text-sm font-semibold text-gray-600">No analysis yet</h3>
                                                                        <p className="mt-1 text-sm text-gray-500">Get started by uploading a thumbnail.</p>

                                                                    </div>
                                                                    :
                                                                    <div className="2xl:ml-3 flex flex-wrap gap-x-4 gap-y-1">
                                                                        {analyses?.map((doc, i) => {

                                                                            if (doc.thumbnail_analysis_done !== false) {
                                                                                return (
                                                                                    <div key={i} className="">
                                                                                        <div className="w-52 h-auto relative mb-5">
                                                                                            <div className=" rounded-md w-52 cursor-pointer group">

                                                                                                <div className="">
                                                                                                    <NavLink to={`/thumbnail-analysis/${doc.thumbnail_id}`}>
                                                                                                        <div className="relative pb-[56.25%] overflow-hidden aspect-video block w-full rounded-lg hover:ring-2 hover:ring-blue-500 active:ring-blue-600">

                                                                                                            <img src={doc.thumbnail} alt=""
                                                                                                                className="absolute rounded-lg top-0 left-0 w-full h-full object-cover" />
                                                                                                        </div>
                                                                                                    </NavLink>
                                                                                                    <div className='flex mt-3 pb-2'>
                                                                                                        <div onClick={() => goToAnalysisDetails(doc.thumbnail_id)} className='flex-col w-10/12'>
                                                                                                            <div className="flex flex-col px-2 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                                                <p className='font-roboto items-center leading-4 tracking-normal font-medium lg:text-[13px] mb:text-mb text-[#0f0f0f]'>{doc.title}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div onClick={() => { openDeleteModal(doc.name, doc.id) }} className="w-2/12 mr-2 p-2 -mt-1 hover:bg-neutral-300 rounded-lg h-8 hover:cursor-pointer">
                                                                                                            <TfiTrash className='ml-[1px] hover:' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        })}
                                                                    </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        <div className="w-full flex flex-col items-center justify-center text-gray-400 py-8 mt-7">
                                                            <div className="flex flex-col items-center justify-center mb-3">
                                                                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                                                                    <path
                                                                        className="fill-gray-300"
                                                                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                                    <path
                                                                        className="fill-gray-500"
                                                                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                                </svg>
                                                            </div>
                                                            <p className="text-xs">Loading previous insights...</p>
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {fileRejectionItems} */}
        </>
    )
}

export default AnalysisUpload



