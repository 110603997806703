import React, { useEffect, useState } from 'react'
import { db, storage } from '../../firebase-config';
import { query, where, orderBy, addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, onSnapshot } from "firebase/firestore";
import { FiAlertOctagon, FiCheck, FiZap } from 'react-icons/fi';
import { MdWarning } from 'react-icons/md';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import DotDotDot from './DotDotDot';

const TrialUserPopupAnalysis = ({ isOpen, onClose }) => {
  const location = useLocation();
  const mainUserId = localStorage.getItem("mainUserId");
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleOneTimePayment = async (id, pricing_id) => {
    console.log(pricing_id);
    console.log(window.location.origin);

    try {
      setPaymentLoading(true);
      await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), {
        price: pricing_id,
        mode: "payment",
        success_url: window.location.origin + `/dashboard#purchase-success`,
        cancel_url: window.location.origin + location.pathname + '?success=false',
      }).then((docRef) => {
        console.log(docRef.id);

        const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
          (document) => {
            console.log("Current data: ", document.data());
            const { error, url } = document.data();
            if (error) {
              console.log(`An error occured: ${error.message}`);
            }
            if (url) {

              const userDoc = doc(db, "Users", mainUserId)
              const data = {
                pricing_table_item_id: id.trim(),
              }
              setDoc(userDoc, data, { merge: true });

              window.location.assign(url);
              setPaymentLoading(false);
            }
          });
      });
    } catch (error) {
      setPaymentLoading(false);
      console.log(`An error occured: ${error.message}`);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[123123] bg-black bg-opacity-50 flex items-center justify-center p-4"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
        <div className="flex flex-col items-center justify-center py-4">
          <div className="flex flex-col items-center justify-center mb-4">
            <FiAlertOctagon className="text-amber-500 mr-2 mb-2" size={54} />
            <h2 className="text-2xl font-bold text-gray-800">Unlock Unlimited Access</h2>
          </div>
          {/* <p className="text-gray-700 text-center text-lg mb-4">
            You're just one step away from unlimited access to premium features!
          </p> */}
        </div>

        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <FiZap className="h-5 w-5 text-blue-500" />
            </div>
            <div className="ml-3">
              <p className="font-bold">Exclusive Offer</p>
              <p>Upgrade now for instant access to all premium features!</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center px-2 mt-3">
          {paymentLoading === false ? (
            <>
              <button
                onClick={() => handleOneTimePayment('prod_Q7lPt7symzv5do', 'price_1PHVsXD6B9LkfDQt3xx3PKPk')}
                className="w-full flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white py-4 px-4 rounded-lg transition duration-200 transform hover:scale-105"
              >
                <span className="font-bold text-xl mr-2">Upgrade Now</span>
                <span className="bg-yellow-400 text-blue-800 text-xs font-semibold px-2 py-1 rounded-full">$15</span>
              </button>
              <p className="text-sm text-gray-600 mt-2">One-time payment. No recurring fees.</p>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center text-gray-700">
                  <FiCheck className="text-green-500 mr-2" /> 3000 premium credits
                </li>
                <li className="flex items-center text-gray-700">
                  <FiCheck className="text-green-500 mr-2" /> Unlimited access to all features
                </li>
                <li className="flex items-center text-gray-700">
                  <FiCheck className="text-green-500 mr-2" /> Priority customer support
                </li>
                {/* <li className="flex items-center text-gray-700">
                  <FiCheck className="text-green-500 mr-2" /> Future updates included
                </li> */}
              </ul>
              <Link to={'/pricing'} className="cursor-pointer z-50 mt-4 text-blue-600 hover:text-blue-800 font-medium">
                View all plan options
              </Link>
            </>
          ) : (
            <button
              disabled
              className="w-full flex items-center justify-center bg-gray-300 text-white py-4 px-4 rounded-lg transition duration-200"
            >
              <span className="font-medium text-lg">Processing...</span>
            </button>
          )}
        </div>

        <p className="text-xs text-gray-500 text-center mt-4">
          100% of users who bought this were satisfied.
        </p>
      </div>
    </div>
  );
};

export default TrialUserPopupAnalysis;