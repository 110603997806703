import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import logo from '../../../assets/logo_full_32.png';
import { db, storage } from '../../../firebase-config';
import { MdOutlineArrowBack, MdOutlineFreeBreakfast } from "react-icons/md";
import { UserAuth } from '../../../context/AuthContext';
import { RadioGroup } from '@headlessui/react'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, getRedirectResult, GoogleAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { query, where, orderBy, addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, doc, getDocs, onSnapshot } from "firebase/firestore";
import AvatarNoPremium from '../../components/AvatarNoPremium';
import DotDotDot from '../../components/DotDotDot';
import { AiOutlineGift } from 'react-icons/ai';
import { RiRefund2Line } from 'react-icons/ri';
import ConfettiExplosion from 'react-confetti-explosion';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InsideBilling = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { user, token, logOut, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    const channelId = localStorage.getItem("channelId");
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [plans, setPlans] = useState([]);
    let { user_id } = useParams();
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const [productInfo, setProductInfo] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [frequency, setFrequency] = useState("1");
    const auth = getAuth();

    const { premiumStatus, premiumTier, accountStatus, channelLinked } = PremiumCredentials();

    useEffect(() => {
        if (accountStatus === 'ACTIVE' && premiumTier === 'TESTER') {
            return;
        }

        if (accountStatus === 'ACTIVE' && premiumStatus === 'TRIALING') {
            navigate(`/dashboard`);
            return;
        }

        if (accountStatus === 'ACTIVE' && premiumStatus === 'PREMIUM') {
            navigate(`/dashboard`);
            return;
        }
    }, [premiumStatus, premiumTier, accountStatus, channelLinked]);

    const logout = async () => {
        try {
            console.log("Logging out...");
            await logOut();
        } catch (error) {
            console.log(error)
        }
    }

    // Check if Auth user exists and is enabled
    const checkEmail = async (email) => {
        try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            // If the array of sign-in methods returned is not empty, the email is registered and enabled
            if (methods.length > 0) {
                console.log("Account auth active.");
                return true;
            } else {
                console.log("Account auth not active/found.");
                return false;
            }
        } catch (error) {
            console.error(error);
            // Handle any errors that occur during the check
        }
    };

    useEffect(() => {
        const getProductInfo = async () => {
            const pricingTableRef = collection(db, 'PricingTableData');
            const q = query(
                pricingTableRef,
                where('visible', '==', true),
                orderBy("order", "asc"),
            );
            const pricingTableDocs = await getDocs(q);

            const productInfo = await Promise.all(
                pricingTableDocs.docs.map(
                    async (pricingTableDoc) => {
                        const { id, product_id, monthly_pricing_id, monthly_pricing_id_test, yearly_pricing_id, daily_monthly_price, daily_yearly_price, ...rest } = pricingTableDoc.data();

                        const monthlyPriceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', monthly_pricing_id));
                        const monthly_price_data = monthlyPriceSnapshot.exists() ? monthlyPriceSnapshot.data() : null;

                        const yearlyPriceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', yearly_pricing_id));
                        const yearly_price_data = yearlyPriceSnapshot.exists() ? yearlyPriceSnapshot.data() : null;

                        return {
                            id,
                            product_id,
                            monthly_pricing_id,
                            monthly_pricing_id_test,
                            yearly_pricing_id,
                            monthly_price_data,
                            yearly_price_data,
                            daily_monthly_price,
                            daily_yearly_price,
                            ...rest
                        };
                    }));

            setProductInfo(productInfo);
            setIsExploding(true);
        };

        const authenticationCheck = checkEmail(mainEmail)

        getProductInfo();

        // if (authenticationCheck) {
        //     getProductInfo();
        // } else {
        //     logout();
        //     navigate('/signup', { replace: true });
        // }

    }, []);

    const newSubscription = (plan_id) => {
        let path = `/subscriptions/new/${plan_id}`;
        navigate(path);
    }

    const handleCheckout = async (id, pricing_id) => {
        console.log(pricing_id);
        console.log(window.location.origin);

        try {
            setPaymentLoading(true);
            await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), {
                // price: 'price_1N7PBKD6B9LkfDQtTF5fY0jE',
                price: pricing_id,
                do_trial: false,
                allow_promotion_codes: true,
                collect_shipping_address: false,
                success_url: window.location.origin + `/dashboard`,
                cancel_url: window.location.origin + location.pathname + '?success=false',
            }).then((docRef) => {
                console.log(docRef.id);

                const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
                    (document) => {
                        console.log("Current data: ", document.data());
                        const { error, url } = document.data();
                        if (error) {
                            console.log(`An error occured: ${error.message}`);
                            // alert(`An error occured: ${error.message}`);
                        }
                        if (url) {

                            const userDoc = doc(db, "Users", mainUserId)
                            const data = {
                                pricing_table_item_id: id.trim(),
                            }
                            setDoc(userDoc, data, { merge: true });

                            window.location.assign(url);
                            setPaymentLoading(false);
                        }
                    });
            });

        } catch (error) {
            setPaymentLoading(false);
            console.log(`An error occured: ${error.message}`);
            // alert(`An error occured: ${error.message}`);
        }
    };

    const handleOneTimePayment = async (id, pricing_id) => {
        console.log(pricing_id);
        console.log(window.location.origin);

        try {
            setPaymentLoading(true);
            await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), {
                // price: 'price_1N7PBKD6B9LkfDQtTF5fY0jE',
                price: pricing_id,
                do_trial: false,
                allow_promotion_codes: true,
                collect_shipping_address: false,
                success_url: window.location.origin + `/dashboard`,
                cancel_url: window.location.origin + location.pathname + '?success=false',
            }).then((docRef) => {
                console.log(docRef.id);

                const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
                    (document) => {
                        console.log("Current data: ", document.data());
                        const { error, url } = document.data();
                        if (error) {
                            console.log(`An error occured: ${error.message}`);
                            // alert(`An error occured: ${error.message}`);
                        }
                        if (url) {

                            const userDoc = doc(db, "Users", mainUserId)
                            const data = {
                                pricing_table_item_id: id.trim(),
                            }
                            setDoc(userDoc, data, { merge: true });

                            window.location.assign(url);
                            setPaymentLoading(false);
                        }
                    });
            });
        } catch (error) {
            setPaymentLoading(false);
            console.log(`An error occured: ${error.message}`);
            // alert(`An error occured: ${error.message}`);
        }
    };

    useEffect(() => {
        const getPlans = async () => {
            await getDocs(collection(db, 'Plans'))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setPlans(newData);
                })
        }

        getPlans();
    }, []);

    return (
        <div className='bg-gray-50'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Billing</title>
                <meta name="description" content="Billing Page of Thumbnail Geek" />
            </Helmet>
            <div className="ml-auto mb-6 lg:w-full">
                <div className="px-6 min-h-screen">
                    {/* Start Nav */}
                    <div className='fixed top-0 left-0 z-50 bg-gray-50 px-8 py-3 pt-4 border-gray-200 w-full flex justify-between lg:border-b mb-4'>
                        <div className="flex">
                            <Link to={`/dashboard`} className='cursor-pointer w-48 text-gray-800 relative hover:text-gray-700 mt-1'>
                                <img src={logo} />
                            </Link>
                        </div>
                        <div className="absolute right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                            {/* <!-- Profile dropdown --> */}
                            <AvatarNoPremium />
                        </div>
                    </div>
                    {/* End Nav */}

                    {isExploding &&
                        <>
                            <div className="absolute top-0 left-0 ">
                                <ConfettiExplosion zIndex={52} />
                            </div>
                            <div className="absolute top-0 right-1/2">
                                <ConfettiExplosion zIndex={52} />
                            </div>
                            <div className="absolute top-0 right-0">
                                <ConfettiExplosion zIndex={52} />
                            </div>
                        </>
                    }
                    <div className="mx-auto max-w-7xl pb-32 pt-16">
                        {
                            plans.length != 0
                                ?
                                <>
                                    <div className="flex flex-col justify-center antialiased text-gray-600 mt-12 ">
                                        <div className="h-full m-0 p-0 ">
                                            <div className="max-w-7xl mx-auto" x-data="{ annual: true }">
                                                {/* <h2 className="text-3xl text-gray-800 font-bold text-center mt-0 mb-16">Which plan would you like?</h2> */}

                                                <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                                                    {/* <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                                        Start getting the views you deserve & earning more from your hard work.
                                                    </p> */}

                                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                                        Start earning more for your hard work.
                                                    </p>
                                                </div>

                                                <div className="mt-16 flex flex-col justify-center">
                                                    <p className="mb-7 inline-flex mx-auto max-w-xl text-center text-sm leading-8 text-gray-800 px-3 bg-green-300 rounded-md">
                                                        <span className="font-extrabold pr-1 py-0">Get 2 free months</span>
                                                        <span className="">with yearly plans</span>
                                                    </p>
                                                    <div className="flex justify-center mt-2 mb-4">
                                                        <RadioGroup
                                                            value={frequency}
                                                            onChange={setFrequency}
                                                            className="grid grid-cols-2 gap-x-1 rounded-lg p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-400"
                                                        >
                                                            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>

                                                            <RadioGroup.Option
                                                                value='1'
                                                                className={({ checked }) =>
                                                                    classNames(
                                                                        checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                                                                        'cursor-pointer rounded-lg px-2.5 py-1'
                                                                    )
                                                                }
                                                            >
                                                                <span>Monthly</span>
                                                            </RadioGroup.Option>

                                                            <RadioGroup.Option
                                                                value='2'
                                                                className={({ checked }) =>
                                                                    classNames(
                                                                        checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                                                                        'cursor-pointer rounded-lg px-2.5 py-1'
                                                                    )
                                                                }
                                                            >
                                                                <span>Yearly</span>
                                                            </RadioGroup.Option>

                                                        </RadioGroup>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 gap-6 mt-8">
                                                    {productInfo?.map((product, index) => (
                                                        // <div  className="relative flex-none col-span-full md:col-span-4">
                                                        <div key={product.id} className={` relative flex-none col-span-full md:col-span-4 bg-white rounded-lg border 
                                                        ${product.highlighted ? 'border-blue-500' : 'border-gray-200'} `}>
                                                            {
                                                                product.highlighted === true
                                                                    ?
                                                                    <div className="absolute -top-4 right-1/2 px-5 py-1 translate-x-1/2 shadow bg-yellow-400 text-black text-sm font-semibold rounded-md">
                                                                        Best Value
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                            <div className="px-7 pt-10 pb-6 border-b border-gray-200">
                                                                <header className="flex items-center mb-2">
                                                                    <h3 className="text-lg text-gray-800 font-semibold">{product.name}</h3>
                                                                </header>
                                                                <div className="text-sm mb-2">{product.description}</div>

                                                                {
                                                                    frequency === '1'
                                                                        ?
                                                                        <>
                                                                            <div className="text-gray-800 font-bold mb-4 mt-7">
                                                                                <span className="text-2xl">$</span><span className="text-3xl" x-text="annual ? '34' : '39'">
                                                                                    {product.monthly_price_data.unit_amount / 100} { }
                                                                                </span>
                                                                                <span className="text-gray-500 font-medium text-sm">/mo</span>
                                                                                <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-sm">
                                                                                    (<span className="text-[12px]">$</span>{product.daily_monthly_price}/day)
                                                                                </span>
                                                                            </div>
                                                                            {
                                                                                paymentLoading === false
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            product.highlighted === true
                                                                                                ?
                                                                                                <button onClick={() => { handleCheckout(product.id, product.monthly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3 rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                        focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                                    Start 7-day free trial
                                                                                                </button>
                                                                                                :
                                                                                                <button onClick={() => { handleCheckout(product.id, product.monthly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3  rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                        focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                                    Start 7-day free trial
                                                                                                </button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                            shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                            focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                                        <div className="inline-flex space-x-1 text-center">
                                                                                            <span>Loading</span>
                                                                                            <DotDotDot className="pl-2" />
                                                                                        </div>
                                                                                    </button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="text-gray-800 font-bold mb-4 mt-7">
                                                                                <span className="text-2xl">$</span><span className="text-3xl">
                                                                                    {((product.yearly_price_data.unit_amount / 100) / 12).toFixed(0)}
                                                                                </span>
                                                                                <span className="text-gray-500 font-medium text-sm">/mo</span>
                                                                                {/* <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-sm">
                                                                                    (<span className="text-[12px]">$</span>{product.daily_yearly_price}/day)
                                                                                </span> */}
                                                                                <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-xs">
                                                                                    (<span className="text-[12px]">$</span>{(product.yearly_price_data.unit_amount / 100)} billed annually)
                                                                                </span>
                                                                            </div>
                                                                            {
                                                                                paymentLoading === false
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            product.highlighted === true
                                                                                                ?
                                                                                                <button onClick={() => { handleCheckout(product.id, product.yearly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3 rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                        focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                                    Start 7-day free trial
                                                                                                </button>
                                                                                                :
                                                                                                <button onClick={() => { handleCheckout(product.id, product.yearly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3  rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                        focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                                    Start 7-day free trial
                                                                                                </button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                            shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                            focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                                        <div className="inline-flex space-x-1 text-center">
                                                                                            <span>Loading</span>
                                                                                            <DotDotDot className="pl-2" />
                                                                                        </div>
                                                                                    </button>
                                                                            }
                                                                        </>
                                                                }

                                                            </div>

                                                            <div className="px-7 pt-4 pb-5">
                                                                <div className="text-xs text-gray-800 font-semibold uppercase mb-4">What's included</div>
                                                                <ul>
                                                                    {product.features.map((feature, index) => (
                                                                        <li key={index} className="flex gap-x-3 mb-3">
                                                                            <svg className="w-3 h-3 mt-2 flex-shrink-0 fill-current text-blue-500 mr-2" viewBox="0 0 12 12">
                                                                                <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                                                            </svg>
                                                                            <div className="text-sm">{feature}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        // </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="w-full flex flex-col justify-center mt-32 items-center">
                                        Arranging the things...
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsideBilling