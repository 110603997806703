import React, { useEffect, useState } from 'react'
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SideBar from '../../components/SideBar'
import Avatar from '../../components/Avatar'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { MdOutlineOndemandVideo, MdOutlineFileDownload, MdOutlineArrowBack, MdBook, MdLibraryBooks, } from 'react-icons/md'
import { BsListCheck } from 'react-icons/bs'
import { TbCrown } from 'react-icons/tb'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { BellIcon } from '@heroicons/react/24/outline'
import { db, storage } from '../../../firebase-config';
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject, getStorage } from "@firebase/storage";
import { addDoc, arrayUnion, collection, serverTimestamp, updateDoc, setDoc, getDoc, deleteDoc, doc, getDocs, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import OnboardingPopup from '../../components/OnboardingPopup';
import PremiumAssetPopup from '../../components/PremiumAssetPopup';
import BlockPopupStanPro from '../../components/BlockPopupStanPro';
import BlockPopupRestricted from '../../components/BlockPopupRestricted';
import { toast } from 'react-toastify';

const Tutorials = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, aiQuota, aiQuotaUsed, aiCreditsLeft, accountStatus, accessLevel } = PremiumCredentials();
    const channelId = localStorage.getItem("channelId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [downloadsLoaded, setDownloadsLoaded] = useState(false);
    const [sheetSections, setSheetSections] = useState([]);
    const [premiumDownloads, setPremiumDownloads] = useState([]);
    const [selectedCSSection, setSelectedCSSection] = useState(null);
    const [blockPopup, setBlockPopup] = useState(false)

    useEffect(() => {
        setSheetSections(null);
        setLoaded(false);

        const fetchData = async () => {
            if (section === 'cheat-sheets') {
                setSheetSections(null);
                setLoaded(false);

                const collectionRef = collection(db, 'LC_CheatSheets');
                const q = query(
                    collectionRef,
                    orderBy("date_created", "asc"),
                );
                const collectionSnapshot = await getDocs(q);

                const sectionPromises = collectionSnapshot.docs.map(async (doc) => {
                    const sectionRef = collection(doc.ref, 'Sections');
                    const sectionSnapshot = await getDocs(sectionRef);
                    const sectionData = sectionSnapshot.docs.map((sectionDoc) => sectionDoc.data());

                    return {
                        id: doc.id,
                        title: doc.data().title,
                        image: doc.data().image,
                        published: doc.data().published,
                        sections: sectionData,
                    };
                });

                const sectionData = await Promise.all(sectionPromises);
                console.log(sectionData);
                setSheetSections(sectionData);
                setLoaded(true);
            } else if (section === 'how-tos') {
                setSheetSections(null);
                setLoaded(false);
            } else if (section === 'premium-downloads') {
                setSheetSections(null);
                setLoaded(false);

                const collectionRef = collection(db, 'LC_Downloads');
                const q = query(
                    collectionRef,
                    orderBy("date_created", "asc"),
                );
                await getDocs(q)
                    .then((querySnapshot) => {
                        const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                        console.log(newData);
                        setPremiumDownloads(newData);
                        setDownloadsLoaded(true);
                    })

            } else if (section === 'guides') {
                setSheetSections(null);
                setLoaded(false);
            }
        };

        fetchData();
    }, [section]);

    // useEffect(() => {
    //     async function getUserPremiumCredentials() {
    //         const docRef = doc(db, "Users", user.uid)
    //         const docSnap = await getDoc(docRef)
    //         if (!docSnap.exists()) {
    //             navigate(`/signup`);
    //         } else {
    //             const premiumStatus = docSnap.data().premium_status;
    //             const premiumTier = docSnap.data().premium_tier;
    //             const premiumExpiry = docSnap.data().premium_expiry;
    //             console.log(premiumStatus);

    //             if (premiumStatus !== 'trialing') {
    //                 // navigate(`/first-steps/billing/plans/${user.uid}`);
    //             }
    //         }
    //     }

    //     getUserPremiumCredentials()
    // }, []);

    const handleClick = (section) => {
        setSelectedCSSection(section);
    };

    const handleReset = () => {
        setSelectedCSSection(null);
    };

    const formatVariable = (text) => {
        const regex = /\[(.*?)\]/g;
        const formattedText = text.replace(regex, '<span className="font-bold">$1</span>');

        return <p dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    const openDoc = (doc) => {
        if (accessLevel === 5 || premiumStatus === 'TRIALING') {
            // setBlockPopup(true);
            toast.error(<>Feature not available in Trial. <br />Upgrade to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        } else {
            window.open(doc, '_blank');
        }

    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (section === 'cheat-sheets') {
    //             const collectionRef = collection(db, 'LC_CheatSheets');
    //             const collectionSnapshot = await getDocs(collectionRef);

    //             const sectionPromises = collectionSnapshot.docs.map(async (doc) => {
    //                 const sectionRef = collection(doc.ref, 'Sections');
    //                 const sectionSnapshot = await getDocs(sectionRef);
    //                 const sectionData = sectionSnapshot.docs.map((sectionDoc) => sectionDoc.data());

    //                 return {
    //                     id: doc.id,
    //                     title: doc.data().title,
    //                     sections: sectionData,
    //                 };
    //             });

    //             const sectionData = await Promise.all(sectionPromises);
    //             console.log(sectionData);
    //             setSheetSections(sectionData);
    //         } else if (section === 'how-tos') {
    //             // Other conditions or actions based on different sections
    //         } else if (section === 'downloads') {
    //             // Other conditions or actions based on different sections
    //         } else if (section === 'guides') {
    //             // Other conditions or actions based on different sections
    //         }
    //     };

    //     fetchData();
    // }, [section]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learning Center | ThumbnailGeek</title>
                <meta name="description" content="Learning Center | ThumbnailGeek" />
            </Helmet>

            {/* <OnboardingPopup
                heading={'Level up. On Easy mode.'}
                content={'Change the game with new skills that will blow away your competition and make viewers flock to your videos.'}
                imageSrc={require('../../../assets/svgs/writer.svg').default}
                buttonText={'Nice!'}
            /> */}

            {/* <PremiumAssetPopup /> */}

            <BlockPopupRestricted show={blockPopup} isVisible={blockPopup} onClose={() => setBlockPopup(false)} />

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="tutorials" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] 2xl:ml-[300px] mb-6 w-[calc(100%)] lg:w-[calc(100%_-_55px)] [lg:w-[calc(100%_-_240px)]] 2xl:w-[calc(100%_-_280px)]">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b lg:pb-4 lg:pt-7 inline-flex">
                            <div className="">
                                <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold lg:flex `}> Resources to Help You Get Better at Making Thumbnails</h5>
                            </div>

                            <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-full lg:w-[calc(100%-55px)] lg:ml-8 xl:ml-2 2xl:ml-8">
                            <div className="w-full h-full mx-auto flex flex-row">
                                <div className={`fixed flex flex-col lg:pr-4 border-r pb-3 px-2 justify-between h-[88vh]`}>
                                    <ul className="space-y-2 tracking-wide mt-16 w-[190px] font-medium">

                                        {/* <li className={` hover:cursor-pointer rounded-lg hover:bg-gray-200 w-full text-blue-600
                                        ${section === '' ? 'bg-gray-200' : ''}`}>
                                            <a href="https://learn.thumbnailgeek.com" target="_blank" className={`py-2 px-3 pb-[6px] inline-flex space-x-2 w-full`}>
                                                <MdLibraryBooks className='mt-[2px]' />
                                                <span className="text-[12px]">How to Use</span>
                                            </a>
                                        </li>

                                        <li className={` hover:cursor-pointer rounded-lg hover:bg-gray-200 w-full text-blue-600
                                        ${section === 'tutorial-videos' ? 'bg-gray-200' : ''}`}>
                                            <NavLink to="/tutorials?section=tutorial-videos" className={`py-2 px-3 pb-[6px] inline-flex space-x-2 w-full`}>
                                                <MdOutlineOndemandVideo className='mt-[2px]' />
                                                <span className="text-[12px]">Tutorial Videos</span>
                                            </NavLink>
                                        </li> */}

                                        <li className={` hover:cursor-pointer rounded-lg hover:bg-gray-200 w-full text-blue-600
                                        ${section === 'premium-downloads' ? 'bg-gray-200' : ''}`}>
                                            <NavLink to="/tutorials?section=premium-downloads" className={`py-2 px-3 pb-[6px] inline-flex space-x-2 w-full`}>
                                                <MdOutlineFileDownload className='mt-[2px]' />
                                                <span className="text-[12px]">Premium Downloads</span>
                                            </NavLink>
                                        </li>

                                        {/* <li onClick={() => setSelectedCSSection(null)} className={` hover:cursor-pointer rounded-lg hover:bg-gray-200 w-full text-blue-600
                                        ${section === 'cheat-sheets' ? 'bg-gray-200' : ''}`}>
                                            <NavLink to="/tutorials?section=cheat-sheets" className={`py-2 px-3 pb-[6px] inline-flex space-x-2 w-full`}>
                                                <BsListCheck className='mt-[2px]' />
                                                <span className="text-[12px]">Cheat Sheets</span>
                                            </NavLink>
                                        </li> */}

                                        {/* <li className={` hover:cursor-pointer rounded-lg hover:bg-gray-200 w-full text-blue-600
                                        ${section === 'guides' ? 'bg-gray-200' : ''}`}>
                                            <NavLink to="/tutorials?section=guides" className={`py-2 px-3 pb-[6px] inline-flex space-x-2 w-full`}>
                                                <FaChalkboardTeacher className='mt-[2px]' />
                                                <span className="text-[12px]">Guides & Tutorials</span>
                                            </NavLink>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className=' 2xl:max-w-7xl mt-8 p-3 px-6 pt-8 ml-52 flex flex-wrap lg:ml-[208px] mb-6 w-[calc(100%_-_208px)] lg:w-[calc(100%_-_208px)]'>
                                    {
                                        section === 'cheat-sheets'
                                            ?
                                            <>
                                                {
                                                    loaded === true
                                                        ?
                                                        <>
                                                            {selectedCSSection ? (
                                                                <div className='w-full'>
                                                                    <div className="flex justify-between mb-7 w-full">
                                                                        <div className=" ml-2">
                                                                            <h2 className='font-bold text-3xl text-gray-600'>{selectedCSSection.title}</h2>
                                                                            <p className="font-medium text-gray-500">Cheat Sheet for Creating Thumbnails That Get Clicks</p>
                                                                        </div>

                                                                        <button onClick={handleReset} className={`text-gray-800 py-2 px-2 w-10 h-10
                                                                            bg-gray-300 rounded-md inline-flex items-center text-xl justify-self-start`}>
                                                                            <MdOutlineArrowBack />
                                                                        </button>
                                                                    </div>

                                                                    <div className="grid grid-cols-2 gap-4">
                                                                        <div className="flex flex-col ">
                                                                            {selectedCSSection.sections.map((subDoc, index) => {
                                                                                if (index % 2 === 0) {
                                                                                    return (
                                                                                        <div key={subDoc.id} className="mb-4">
                                                                                            <div className="bg-gray-50 space-y-5">
                                                                                                <div className="overflow-hidden rounded-lg border border-gray-300">
                                                                                                    <div className="bg-slate-100 inline-flex w-full px-4 py-[10px] border-b">
                                                                                                        <span className="text-gray-700 text-[15px] font-bold mt-[2px]">{subDoc.title}</span>
                                                                                                    </div>
                                                                                                    <div className="text-[#4A4A4A] text-[15px] leading-[25px] tracking-[-0.020em] bg-white p-4">
                                                                                                        <ul>
                                                                                                            {subDoc.items?.map((item, index) => (
                                                                                                                <li key={index} className="mb-2 pb-2 border-b flex flex-col items-start justify-start">
                                                                                                                    <div className="pb-2 inline-flex items-start justify-start">
                                                                                                                        <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                        <span className="">{formatVariable(item.text)}</span>
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        item.image !== ""
                                                                                                                            ?
                                                                                                                            <div className="my-7">
                                                                                                                                <img src={item.image} alt="" className="rounded-md" />
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </div>
                                                                        <div className="flex flex-col">
                                                                            {selectedCSSection.sections.map((subDoc, index) => {
                                                                                if (index % 2 !== 0) {
                                                                                    return (
                                                                                        <div key={subDoc.id} className="mb-4">
                                                                                            <div className="bg-gray-50 space-y-5">
                                                                                                <div className="overflow-hidden rounded-lg border border-gray-300">
                                                                                                    <div className="bg-slate-100 inline-flex w-full px-4 py-[10px] border-b">
                                                                                                        <span className="text-gray-700 text-[15px] font-bold mt-[2px]">{subDoc.title}</span>
                                                                                                    </div>
                                                                                                    <div className="text-[#4A4A4A] text-[15px] leading-[25px] tracking-[-0.020em] bg-white p-4">
                                                                                                        <ul>
                                                                                                            {subDoc.items?.map((item, index) => (
                                                                                                                <li key={index} className="mb-2 pb-2 border-b flex flex-col items-start justify-start">
                                                                                                                    <div className="pb-2 inline-flex items-start justify-start">
                                                                                                                        <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                        <span className="">{formatVariable(item.text)}</span>
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        item.image !== ""
                                                                                                                            ?
                                                                                                                            <div className="my-7">
                                                                                                                                <img src={item.image} alt="" className="rounded-md" />
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </li>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                sheetSections.map((section) => {
                                                                    if (section.published) {
                                                                        return (
                                                                            <div onClick={() => handleClick(section)} key={section.id} className='lg:w-1/2 xl:w-72 mr-4 mb-7 hover:cursor-pointer'>
                                                                                <div className="aspect-w-16 aspect-h-9 block lg:w-1/2 xl:w-72 rounded-md hover:ring-2 hover:ring-blue-300">
                                                                                    <img src={section.image} alt="" className="object-cover rounded-md" />
                                                                                </div>
                                                                                <h2 className='font-medium text-sm mt-2 ml-2'>{section.title}</h2>
                                                                                <p className='font-medium text-xs mt-2 ml-2 text-gray-500'>Cheat Sheet</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null; // Skip rendering for unpublished sections
                                                                })
                                                            )}
                                                        </>
                                                        :
                                                        <div className="w-full h-full flex flex-col items-center justify-center mt-16">
                                                            Loading Premium Assets...
                                                        </div>
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        section === 'premium-downloads'
                                            ?
                                            <>
                                                {
                                                    downloadsLoaded === true
                                                        ?
                                                        <div className='flex flex-wrap space-x-3'>
                                                            {premiumDownloads?.map((doc, i) => (
                                                                <div key={doc.id} className="w-[300px] mr-5 ">
                                                                    <div className="w-full h-auto relative mb-5 mr-5">
                                                                        <button onClick={() => openDoc(doc.file)} target='_blank'>
                                                                            <div className="border border-gray-300 rounded-md w-72 bg-gray-300 cursor-pointer overflow-hidden group">
                                                                                <img src={doc.artwork} className="object-contain rounded-md" />
                                                                            </div>
                                                                        </button>
                                                                        <div className="w-full">
                                                                            <p className='text-[13px] ml-2 mt-2'>{doc.alt_title}</p>
                                                                            <>
                                                                                <div className="inline-flex">
                                                                                    <TbCrown className='ml-2 text-yellow-500 mt-[1px]' />
                                                                                    <button onClick={() => openDoc(doc.file)} target='_blank'>
                                                                                        <p className='ml-2 text-yellow-500 text-[13px]'>Download</p>
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="w-full h-full flex flex-col items-center justify-center mt-16">
                                                                Loading Cheat Sheets...
                                                            </div>
                                                        </>
                                                }

                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Tutorials