import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { HiOutlineBell } from 'react-icons/hi';
import { UserAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase-config';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import axios from 'axios';
import logo from '../../../assets/logo_full_32.png';
import journey from '../../../assets/journey.png';
import { ToastContainer, toast } from 'react-toastify';
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-toastify/dist/ReactToastify.css';
import ConfettiExplosion from 'react-confetti-explosion';
import AvatarNoPremium from '../../components/AvatarNoPremium';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import logoImage from '../../../assets/logo192.png';

const FirstStepsSuccess = () => {
    let { user_id } = useParams();
    localStorage.setItem('real_user_id', user_id);
    const { user, token, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserId = localStorage.getItem("mainUserId");
    const navigate = useNavigate()
    const [connect, setConnect] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [channelName, setChannelName] = useState("")
    const [channelImage, setChannelImage] = useState("")
    const [userDetails, setUserDetails] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [working, setWorking] = useState(false);
    const [userDocument, setUserDocument] = useState(null);
    const [userDocumentLoaded, setUserDocumentLoaded] = useState(false);
    const [pricingData, setPricingData] = useState([]);
    const [pricingDataLoaded, setPricingDataLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);
    // const [isLargeExploding, setIsLargeExploding] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const auth = getAuth();

    const { premiumStatus, premiumTier, accountStatus, channelLinked } = PremiumCredentials();

    useEffect(() => {
        updateToken();
        const fetchDocument = async () => {
            try {
                const docRef = doc(db, 'Users', mainUserId);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    setUserDocument(docSnapshot.data());
                    setUserDocumentLoaded(true);

                    let pricingPlan = (docSnapshot.data().pricing_table_item_id);
                    console.log(pricingPlan)

                    try {

                        const docRef1 = doc(db, 'PricingTableData', pricingPlan);
                        const querySnapshot1 = await getDoc(docRef1);

                        if (docSnapshot.exists()) {
                            setPricingData(querySnapshot1.data());
                            setPricingDataLoaded(true);

                            setLoaded(true);
                            setIsExploding(true)
                            updateToken();

                            console.log(querySnapshot1.data());
                        }

                    } catch (error) {
                        console.error('Error fetching pricing data:', error);
                    }
                } else {
                    console.log('Document not found.');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        fetchDocument();
    }, []);

    function deleteCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    const updateToken = (e) => {

        console.log('Doing First load sequence');
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
        }
    }

    const goToDashboard = async (event) => {
        navigate(`/dashboard?first-entry=1`);
    }

    // const goToDashboard = async (event) => {
    //     if (connect === true) {
    //         navigate(`/first-steps/billing/plans/${user_id}`);
    //     } else {
    //         toast("Connect your youtube channel first", {
    //             icon: ({ theme, type }) => <HiOutlineBell className='text-red-500 h-7 w-7' />
    //         });
    //     }
    // }

    if (loaded === false) {
        return <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
            <div className="flex flex-col items-center justify-center">
                {/* <div className='relative w-2/3'> */}
                <img className='w-12' src={logoImage} />
                {/* </div> */}
                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                    <path
                        className="fill-gray-300"
                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path
                        className="fill-gray-500"
                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>
        </div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Onboarding</title>
                <meta name="description" content="Onboarding - ThumbnailGeek" />
            </Helmet>
            <div className=" px-6 pt-4">
                {/* Start Nav */}
                <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4'>
                    <div className="flex">
                        <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                            <img src={logo} />
                        </a>
                    </div>
                    <AvatarNoPremium />
                    {/* <img
                        className="h-8 w-8 rounded-full ring-1 ring-gray-600"
                        src={channelPhotoUrl == null ? '' : channelPhotoUrl}
                        alt=""
                    /> */}
                </div>
                {/* End Nav */}
            </div>

            {isExploding &&
                <>
                    <div className="absolute top-0 left-0 ">
                        <ConfettiExplosion zIndex={52} />
                    </div>
                    <div className="absolute top-0 right-1/2">
                        <ConfettiExplosion zIndex={52} />
                    </div>
                    <div className="absolute top-0 right-0">
                        <ConfettiExplosion zIndex={52} />
                    </div>
                </>
            }

            <div className="relative flex justify-center items-center ">
                <div className="2xl:container 2xl:mx-auto px-4 md:px-28 flex justify-center items-center">
                    <div className="w-96 md:w-auto dark:bg-gray-800 relative flex flex-col justify-center items-center bg-white py-4 px-4 md:px-24 xl:pb-24 xl:px-36 rounded-lg ">
                        <div className="mt-12">
                            {/* <img src={journey} alt="" className="w-96" /> */}
                            <h1 role="main" className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Congratulations!</h1>
                            {/* <h3 role="sub" className="mt-4 text-lg dark:text-white lg:text-2xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">Your journey begins...</h3> */}
                        </div>
                        {/* <div className="mt">
                            <p className="mt-6 sm:w-96 text-base dark:text-white leading-7 text-center text-gray-800">
                                Status: 14-day free trial.
                            </p>
                        </div> */}
                        <div className="mt">
                            <p className="mt-6 sm:w-96 text-base dark:text-white leading-7 text-center text-gray-800">
                                Lets get started with the tools that will help your Youtube channel grow 10x faster!</p>
                        </div>

                        <div className="lg:col-start-3 lg:row-end-1 mt-7">
                            <h2 className="sr-only">Tools</h2>

                            <div className="max-w-3xl rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 pb-6">
                                <dl className="flex flex-wrap">
                                    <div className="flex-auto pl-6 pt-6">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Enjoy your premium tools </dt>
                                    </div>

                                    <div className="flex-none self-end px-6 pt-4">
                                        <dt className="sr-only">Plan</dt>
                                        <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                            {pricingData.name}
                                        </dd>
                                    </div>
                                    <div className="mt-6 text-gray-500 w-full gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                        {
                                            pricingData.features?.map((item, index) => (
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            ))
                                        }
                                    </div>
                                    {/* <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                        <dt className="flex-none">
                                            <span className="sr-only">Start date</span>
                                            <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd className="text-sm leading-6 text-gray-500">
                                            <time dateTime="2023-01-31">January 31, 2023</time>
                                        </dd>
                                    </div> */}

                                </dl>
                            </div>

                        </div>

                        <button onClick={goToDashboard} className="w-full sm:w-auto mt-14 text-base leading-4 text-center rounded-xl text-white 
                                        py-5 px-16 focus:outline-none bg-blue-600 hover:bg-blue-700 active:bg-black ">
                            Start your accelerated growth
                        </button>
                    </div>
                </div>

            </div>


            {/* <footer className="bg-white">
                <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                        <NavLink to="/privacy" className="text-gray-400 text-xs hover:text-gray-500">
                            <span className="sr-only">Privacy Policy</span>
                            <span>Privacy Policy</span>
                        </NavLink>
                        <NavLink to="/terms" className="text-gray-400  text-xs hover:text-gray-500">
                            <span className="sr-only">Terms of Use</span>
                            <span>Terms of Use</span>
                        </NavLink>
                    </div>
                    <div className="mt-8 md:order-1 md:mt-0">
                        <p className="text-center text-xs leading-5 text-gray-500">
                            &copy; {new Date().getFullYear()} ThumbnailGeek. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer> */}

            < footer className="bg-white  w-full" >
                <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                        <a href="/privacy" className="text-gray-400 text-xs hover:text-gray-500">
                            <span className="sr-only">Privacy Policy</span>
                            <span>Privacy Policy</span>
                        </a>
                        <a href="/terms" className="text-gray-400 text-xs hover:text-gray-500">
                            <span className="sr-only">Terms of Use</span>
                            <span>Terms of Use</span>
                        </a>
                    </div>
                    <div className="mt-8 md:order-1 md:mt-0">
                        <p className="text-center text-xs leading-5 text-gray-500">
                            &copy; {new Date().getFullYear()} ThumbnailGeek. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default FirstStepsSuccess