import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import { AiOutlinePicture } from "react-icons/ai";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import { BsStars, BsListTask, BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { BiCategoryAlt, BiTrash } from 'react-icons/bi';
import { TfiDownload } from 'react-icons/tfi';
import SideBar from '../../components/SideBar'
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db, storage } from '../../../firebase-config';
import { collection, getDocs, query, orderBy, limit, where, addDoc, doc, setDoc, deleteDoc, startAfter, endBefore, serverTimestamp } from "firebase/firestore";
import logo from '../../../assets/logo_full_32.png';
import { toast } from 'react-toastify';
import Lottie from "lottie-react";
import loading_animation from "../../../assets/animations/loading_tg.json";
import { Tab } from '@headlessui/react';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';
import GoToYoutubeBtn from '../../components/GoToYoutubeBtn';
import OnboardingPopup from '../../components/OnboardingPopup';
import { IoHeartDislikeOutline } from 'react-icons/io5';

const ViralLibrary = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const index = params.get('tab');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const { premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked } = PremiumCredentials();
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [showSidebar, setShowSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [vTTItems, setVTTItems] = useState([]);
    const [favItemsFeed, setFavItemsFeed] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [favLoaded, setFavLoaded] = useState(false);
    const [favLoading, setFavLoading] = useState(false);
    const [favEmpty, setFavEmpty] = useState(false);
    const [vCatItems, setVCatItems] = useState([]);
    const [page, setPage] = useState(1);
    const [favPage, setFavPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [favLastDoc, setFavLastDoc] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [favoriteItems, setFavoriteItems] = useState([]);
    const { pathname } = useLocation();
    const PAGE_SIZE = 6;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const tab = Number(index)
        if (tab === 1 || tab === 2) {
            setSelectedIndex(tab);
            if (params.has('tab')) {
                params.delete('tab');
            }
        }
    }, []);

    const toggleFavorite = async (itemId, thumbnail, saved_thumbnail, title, avatar, name) => {
        if (favoriteItems.includes(itemId)) {
            // Item is already favorited, remove it from the list
            setFavoriteItems(favoriteItems.filter((id) => id !== itemId));

            try {
                const userDoc = doc(db, 'Users', mainUserId, 'ViralPicks', itemId);
                await deleteDoc(userDoc);
                toast("Removed thumbnail pick", {
                    position: 'top-center',
                    autoClose: 1000,
                    icon: ({ theme, type }) => <BiTrash className='text-red-500 h-7 w-7' />
                });
            } catch (error) {
                console.error('Error deleting document from subcollection:', error);
            }
        } else {
            // Item is not favorited, add it to the list
            setFavoriteItems([...favoriteItems, itemId]);

            try {
                const userDoc = doc(db, "Users", mainUserId, "ViralPicks", itemId);
                const data = {
                    id: itemId,
                    thumbnail: thumbnail,
                    saved_thumbnail: saved_thumbnail,
                    title: title,
                    avatar: avatar,
                    name: name,
                    date_created: serverTimestamp(),
                }
                setDoc(userDoc, data, { merge: true })
                    .then(async res => {
                        toast("Saved thumbnail pick", {
                            position: 'top-right',
                            autoClose: 1000,
                            icon: ({ theme, type }) => <AiOutlinePicture className='text-green-500 h-7 w-7' />
                        });
                    });
            } catch (error) {
                console.error('Error adding document to subcollection: ', error);
            }
        }
    };

    const toggleFavoritePicks = async (itemId, thumbnail, saved_thumbnail, title, avatar, name) => {
        if (favoriteItems.includes(itemId)) {
            setFavoriteItems(favoriteItems.filter((id) => id !== itemId));

            try {
                const userDoc = doc(db, 'Users', mainUserId, 'ViralPicks', itemId);
                await deleteDoc(userDoc);

                toast("Removed thumbnail pick", {
                    position: 'top-center',
                    autoClose: 1000,
                    icon: ({ theme, type }) => <BiTrash className='text-red-500 h-7 w-7' />
                });

                // setVCatItems((state) => state.filter((item) => item.id !== itemId));

            } catch (error) {
                console.error('Error deleting document from subcollection:', error);
            }
        } else {
            setFavoriteItems([...favoriteItems, itemId]);

            try {
                const userDoc = doc(db, "Users", mainUserId, "ViralPicks", itemId);
                const data = {
                    id: itemId,
                    thumbnail: thumbnail,
                    saved_thumbnail: saved_thumbnail,
                    title: title,
                    avatar: avatar,
                    name: name,
                    date_created: serverTimestamp(),
                }
                setDoc(userDoc, data, { merge: true })
                    .then(async res => {
                        toast("Saved thumbnail pick", {
                            position: 'top-center',
                            autoClose: 1000,
                            icon: ({ theme, type }) => <AiOutlinePicture className='text-green-500 h-7 w-7' />
                        });
                    });
            } catch (error) {
                console.error('Error adding document to subcollection: ', error);
            }
        }
    };

    useEffect(() => {
        getViralUserFavorites();
        getItems();
    }, []);

    const getItems = async () => {
        const colRef = collection(db, 'Library');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            endBefore(null),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                // //console.log(lastVisible);
                setLastDoc(lastVisible);
                setVTTItems(newData);
                setLoaded(true);
            })
    }

    const fetchMore = async () => {
        setLoading(true);

        const colRef = collection(db, 'Library');
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            startAfter(lastDoc),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const isQuerySnapshotEmpty = documentSnapshots.size === 0;

                // //console.log(isQuerySnapshotEmpty);
                // //console.log(lastDoc);

                if (!isQuerySnapshotEmpty) {
                    const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    setLastDoc(lastVisible);
                    setVTTItems((vTTItems) => [...vTTItems, ...newData]);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        fetchMore();
    }, [page])

    // const onScroll = () => {
    //     const scrollTop = document.documentElement.scrollTop
    //     const scrollHeight = document.documentElement.scrollHeight
    //     const clientHeight = document.documentElement.clientHeight

    //     if (scrollTop + clientHeight >= scrollHeight) {
    //         setPage(page + 1)
    //     }
    // }

    const onScroll = () => {
        const scrollTop = window.scrollY;
        const scrollHeight = document.body.scrollHeight;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            setPage(page + 1);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [vTTItems])

    useEffect(() => {
        if (selectedIndex === 1) {
            getCategoryItems();
            // getFavoriteItems();
        } else if (selectedIndex === 2) {
            window.scrollTo(0, 0);
            getFavoriteItems();
        }
    }, [selectedIndex]);

    const getFavoriteItems = async () => {
        const colRef = collection(db, "Users", mainUserId, "ViralPicks",);
        const q = query(
            colRef,
            orderBy('date_created', 'desc'),
            endBefore(null),
            limit(12)
        );

        try {
            const documentSnapshots = await getDocs(q);
            const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

            setFavItemsFeed(newData);
            setFavLastDoc(lastVisible);
            setFavLoaded(true);
        } catch (error) {
            console.error('Error getting items:', error);
        }
    };

    const fetchMoreFavs = async () => {
        setFavLoading(true);

        const colRef = collection(db, "Users", mainUserId, "ViralPicks",);
        const q = query(
            colRef,
            orderBy("date_created", "desc"),
            startAfter(favLastDoc),
            limit(12)
        );
        await getDocs(q)
            .then((documentSnapshots) => {
                const isQuerySnapshotEmpty = documentSnapshots.size === 0;
                if (!isQuerySnapshotEmpty) {
                    const newData = documentSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    setFavLastDoc(lastVisible);
                    setFavItemsFeed((favItemsFeed) => [...favItemsFeed, ...newData]);
                    setFavLoading(false);
                } else {
                    setFavLoading(false);
                    setFavEmpty(true);
                }
            })
    }

    useEffect(() => {
        fetchMoreFavs();
    }, [favPage])

    const onScrollFavs = () => {
        // const scrollTop = document.documentElement.scrollTop
        // const scrollHeight = document.documentElement.scrollHeight
        // const clientHeight = document.documentElement.clientHeight

        // if (scrollTop + clientHeight >= scrollHeight) {
        //     setFavPage(favPage + 1)
        // }

        const scrollTop = window.scrollY;
        const scrollHeight = document.body.scrollHeight;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            setFavPage(favPage + 1);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollFavs)
        return () => window.removeEventListener('scroll', onScrollFavs)
    }, [favItemsFeed])

    const getCategoryItems = async () => {
        const colRef = collection(db, 'LibraryCategories');
        const q = query(
            colRef,
            // orderBy("date_created", "desc"),
        );
        await getDocs(q)
            .then((querySnapshot) => {
                const theData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setVCatItems(theData);
                // //console.log(boards, newData);
            })
    }

    const getViralUserFavorites = async () => {
        try {
            const viralPicksCollection = collection(db, 'Users', mainUserId, 'ViralPicks');
            const q = query(viralPicksCollection);
            const querySnapshot = await getDocs(q);

            const ids = [];
            querySnapshot.forEach((doc) => {
                ids.push(doc.id);
            });

            setFavoriteItems(ids);
        } catch (error) {
            console.error('Error getting favorite items:', error);
        }
    }

    const goToItemDetails = (id) => {
        window.scrollTo(0, 0);
        let path = `/viral-library/details/${id}`;
        navigate(path);
    }

    // Function to extract the section from the URL
    const extractSectionFromURL = (url) => {
        const sections = url.split('/');
        return sections[4]; // Change the index as needed, assuming dlteGDtxsqs is always at the 4th position.
    };

    const downloadThumbnail = async (url) => {
        if (accessLevel === 2) {
            const videoId = extractSectionFromURL(url);
            const newLink = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
            window.open(newLink, '_blank');
        } else {
            toast.error(<>Not enough processing credits.<br />Recharge to access this feature.</>, {
                position: "top-right",
                autoClose: 7000,
            });
        }

    }

    const goToCategoryDetails = (id, name) => {
        let path = `/viral-library/category/${id}?source=category&name=${name}`;
        navigate(path);
    }

    return (
        <>
            <div className='bg-gray-50' >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Viral Library | ThumbnailGeek</title>
                    <meta name="description" content="Viral Library | ThumbnailGeek" />
                </Helmet>

                {/* <OnboardingPopup
                    heading={'Be inspired to create a masterpiece.'}
                    content={'Get delicious inspiration from hundreds of viral thumbnail ideas. These thumbnails & titles have gained hundreds of thousands of views for real youtube videos.'}
                    imageSrc={require('../../../assets/svgs/black_princess.svg').default}
                    buttonText={'Start exploring'}
                /> */}

                <div className='bg-gray-50' >
                    <SideBar selectedSideBarItem="viral-library" darkMode={false} />

                    <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                        <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                            <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 py-1 fixed z-30 right-0 top-0 items-center justify-between xl:pt-7 lg:border-b sm:pb-4 lg:pb-4 lg:pt-7 inline-flex">
                                <div className="">
                                    <h5 className={`text-sm lg:text-xl text-gray-600 font-semibold `}>Library of Viral Thumbnails & Titles for Inspiration</h5>
                                </div>


                                <div className="absolute right-0 flex items-center sm:static sm:ml-6 sm:pr-0">
                                    <Avatar />
                                </div>
                            </div>

                            <div className="w-[calc(100%)] lg:w-[calc(100%_-_55px)] lg:ml-8 xl:ml-2 2xl:ml-8 lg:pt-[20px] mr-12">
                                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                    <Tab.List className=' max-w-7xl w-full xl:max-w-6xl 2xl:max-w-7xl border-gray-200 bg-gray-50 mt-7 lg:mt-0 text-gray-800 border-b text-sm fixed z-20 r-0 mx-auto sm:pr-6 lg:pr-8 pt-5'>

                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className={`inline-flex p-3`}>
                                                <BsListTask className='mt-1 mr-1.5' />
                                                <p className='hidden lg:block'>All Concepts</p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'px-8 outline-0  rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="inline-flex p-3">
                                                <BiCategoryAlt className='mt-1 mr-1.5' />
                                                <p className='hidden lg:block'>Categories</p>
                                            </span>
                                        </Tab>
                                        <Tab className={({ selected }) =>
                                            selected
                                                ? 'px-8 outline-0 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group'
                                                : 'px-8 outline-0 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'}
                                        >
                                            <span className="inline-flex p-3">
                                                <BsStars className='mt-1 mr-1.5' />
                                                <p className='hidden lg:block'>My Picks</p>
                                            </span>
                                        </Tab>

                                    </Tab.List>

                                    <Tab.Panels className='max-w-7xl w-full pt-4 flex flex-wrap justify-start overflow-scroll min-h-screen'>
                                        {/* //All Concepts */}
                                        <Tab.Panel className=''>
                                            <div className="pl-0 mt-[48px] pt-8 flex flex-wrap justify-start overflow-scroll min-h-screen">
                                                {
                                                    loaded === false
                                                        ?
                                                        <>
                                                            {/* Animation */}
                                                            <div className="w-full flex flex-wrap ">
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit mt-5 pt-2 pb-3 mb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {vTTItems?.map((item, i) => (
                                                                item.is_active !== false
                                                                    ?
                                                                    <div key={i} className={`relative text-gray-800 w-64 2xl:w-72 mt-5 pt-2 pb-3 text-[0.9rem] mx-2 mb-5 group`}>

                                                                        <div className="mb-2 cursor-pointer">
                                                                            <div className="absolute opacity-0 group-hover:opacity-100 right-2 top-4 z-10 ">
                                                                                <button onClick={() => downloadThumbnail(item.item_data.thumbnail)}
                                                                                    className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">

                                                                                    <TfiDownload className="text-gray-600 text-xl px-1 py-1" />
                                                                                </button>
                                                                                <button onClick={() => toggleFavorite(item.id, item.item_data.thumbnail, item.item_data.saved_thumbnail, item.item_data.title, item.item_data.avatar, item.item_data.name)}
                                                                                    className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">
                                                                                    {favoriteItems.includes(item.id) ? (
                                                                                        <RiHeart3Fill className="text-gray-600 text-xl px-1 py-1" />
                                                                                    ) : (
                                                                                        <RiHeart3Line className="text-gray-400 hover:text-gray-600 text-xl px-1 py-1" />
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                            <Tooltip content="Click for details" position="top">
                                                                                <NavLink to={`/viral-library/details/${item.id}`}>
                                                                                    <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                                                                                        <img className=" @apply inline-block mx-0 w-full rounded-lg" src={item.item_data.saved_thumbnail} alt=""
                                                                                            onError={(e) => {
                                                                                                e.target.src = item.item_data.thumbnail;
                                                                                            }} />
                                                                                        <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                                                                                        </div>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </Tooltip>
                                                                        </div>

                                                                        <NavLink to={`/viral-library/details/${item.id}`}>
                                                                            <div className='flex'>
                                                                                <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                                    <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.item_data.avatar} alt="" />
                                                                                </div>
                                                                                <div className='flex-col w-9/12'>
                                                                                    <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                        <p className='text-[13px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[14px] mb:text-mb'>{item.item_data.title}</p>
                                                                                    </div>
                                                                                    <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                                        <p className='text-gray-500 leading-4 line-clamp-1'>{item.item_data.name}
                                                                                            {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                    :
                                                                    <div key={item.id}></div>
                                                            ))}
                                                        </>
                                                }
                                                <div className="w-full mb-6 text-sm flex flex-col justify-center items-center">
                                                    {
                                                        loading &&
                                                        <>
                                                            <div className="w-full flex flex-wrap ">
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit  pb-5">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Lottie className='w-32 h-32' animationData={loading_animation} loop={true} />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Panel>

                                        {/* //Categories */}
                                        <Tab.Panel className='w-full max-w-7xl pt-12'>
                                            <div className="w-full mt-[28px] pb-24 flex flex-wrap justify-start">
                                                {vCatItems?.map((item, i) => (
                                                    <div onClick={() => goToCategoryDetails(item.id, item.category_name)} key={item.id} className="w-64 h-44 group cursor-pointer 2xl:w-72 text-[0.9rem] mx-2 mb-2 mt-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-300 active:bg-gray-400 hover:border-gray-400">
                                                        <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{item.category_name}</h5>
                                                        <p className="mb-3 font-normal text-sm text-gray-500 group-active:text-gray-100 group-hover:text-gray-600">{item.category_details}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab.Panel>

                                        {/* //My Picks */}
                                        <Tab.Panel className='w-full max-w-7xl'>
                                            <div className=" w-full mt-[48px] pt-8 flex flex-wrap justify-start overflow-scroll min-h-screen">
                                                {
                                                    favLoaded === false
                                                        ?
                                                        <>
                                                            {/* Animation */}
                                                            <div className="w-full flex flex-wrap ">
                                                                <div className="w-64 2xl:w-72 mx-2 group animate-pulse h-fit pt-2 pb-3">
                                                                    <div className="text-center flex flex-wrap items-center justify-center rounded-md w-full h-[145px] 2xl:h-[162px] bg-gray-200">
                                                                    </div>

                                                                    <div className="flex">
                                                                        <div className="group-hover:cursor-pointer border shrink-0 mt-3 mr-4 md:mr-2 xl:mr-1 h-9 w-9 rounded-full bg-gray-200 duration-200">
                                                                            <span> &nbsp; </span>
                                                                        </div>
                                                                        <div className="w-[80%]">
                                                                            <div className="group-hover:cursor-pointer border mt-3 p-0 text-sm bg-gray-200 h-7 w-full rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                            <div className="group-hover:cursor-pointer border mt-2 p-0 text-sm bg-gray-200 h-4 w-[34%] rounded-[5px] duration-200">
                                                                                <span> &nbsp; </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                favItemsFeed.length !== 0
                                                                    ?
                                                                    <>
                                                                        {favItemsFeed?.map((item, i) => (
                                                                            <div key={i} className={`relative text-gray-800 w-64 2xl:w-72 mt-2 pt-2 pb-3 text-[0.9rem] mx-2 mb-2 group`}>
                                                                                {/* <div className="absolute opacity-0 group-hover:opacity-100 right-1/2 translate-x-1/2 -top-6 z-20 rounded-md bg-white px-3 py-1 text-xs shadow-md ">Click for details</div> */}

                                                                                <div className="absolute opacity-0 group-hover:opacity-100 right-2 top-4 z-10 ">
                                                                                    <button onClick={() => downloadThumbnail(item.thumbnail)}
                                                                                        className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">

                                                                                        <TfiDownload className="text-gray-600 text-xl px-1 py-1" />
                                                                                    </button>
                                                                                    <button onClick={() => toggleFavoritePicks(item.id, item.thumbnail, item.saved_thumbnail, item.title, item.avatar, item.name)}
                                                                                        className="rounded-md border border-gray-400 m-[1px] pb-[-2px] hover:bg-gray-200 hover:border-gray-600 bg-white">
                                                                                        {favoriteItems.includes(item.id) ? (
                                                                                            <RiHeart3Fill className="text-gray-600 text-xl px-1 py-1" />
                                                                                        ) : (
                                                                                            <RiHeart3Line className="text-gray-400 hover:text-gray-600 text-xl px-1 py-1" />
                                                                                        )}
                                                                                    </button>
                                                                                </div>

                                                                                <div className="mb-2 cursor-pointer">
                                                                                    <NavLink to={`/viral-library/details/${item.id}`}>
                                                                                        <div className="relative inline-block overflow-hidden w-full h-[80%] duration-200 ">
                                                                                            <img className=" @apply inline-block mx-0 w-full rounded-lg" src={item.saved_thumbnail} alt=""
                                                                                                onError={(e) => {
                                                                                                    e.target.src = item.thumbnail;
                                                                                                }} />
                                                                                            <div className="rounded-md absolute top-0 left-0 w-full h-full text-white bg-white grid place-items-center opacity-0 group-hover:opacity-10 group-active:opacity-5 duration-200">
                                                                                            </div>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>

                                                                                <NavLink to={`/viral-library/details/${item.id}`}>
                                                                                    <div className='flex'>
                                                                                        <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-1 h-9 w-9 object-cover">
                                                                                            <img className='rounded-full md:h-9 md:w-9 xl:h-8 xl:w-8' src={item.avatar} alt="" />
                                                                                        </div>
                                                                                        <div className='flex-col w-9/12'>
                                                                                            <div className="cursor-pointer flex flex-col mb-[0.1rem] leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                                                <p className='text-[13px] text-gray-900 font-roboto items-center leading-4 tracking-normal font-bold lg:text-[14px] mb:text-mb'>{item.title}</p>
                                                                                            </div>
                                                                                            <div className='text-[12px] font-roboto lg:text-[13px] mt-[7px]'>
                                                                                                <p className='text-gray-500 leading-4 line-clamp-1'>{item.name}
                                                                                                    {/* <span className='ml-1 m-0 p-0'>• {numeral(views).format("0.a")} views</span> */}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="w-full flex flex-col items-center justify-center">
                                                                            <div className="flex flex-col mt-24 items-center justify-center h-full">
                                                                                <IoHeartDislikeOutline className='text-lg' />
                                                                                <p className="text-gray-500">No picks saved yet.</p>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                            }

                                                        </>

                                                }
                                                <div className="w-full text-sm flex flex-col justify-center items-center">
                                                    {
                                                        favLoading && !favEmpty &&
                                                        <>
                                                            <Lottie className='w-32 h-32' animationData={loading_animation} loop={true} />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default ViralLibrary