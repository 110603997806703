import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Disclosure } from '@headlessui/react'
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { MdMoneyOff, MdOutlineFreeBreakfast } from 'react-icons/md';
import { RiRefund2Line } from 'react-icons/ri';
import { AiOutlineGift } from 'react-icons/ai';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { db } from '../../../firebase-config';
import { getAuth, getRedirectResult, GoogleAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { collection, getDocs, query, where, orderBy, addDoc, updateDoc, doc, setDoc, getFirestore, getDoc, onSnapshot } from 'firebase/firestore';
import axios from 'axios';
import { Stripe } from '@stripe/stripe-js';
import logo from '../../../assets/logo_full_32.png';
import logoImage from '../../../assets/logo192.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import DotDotDot from '../../components/DotDotDot';
import AvatarNoPremium from '../../components/AvatarNoPremium';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, MinusSmallIcon, PlusSmallIcon, PuzzlePieceIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { FiAlertCircle } from 'react-icons/fi';
import ConfettiExplosion from 'react-confetti-explosion';

const tiers = [
    {
        name: 'Standard',
        id: 'tier-standard',
        href: '#',
        priceMonthly: '$39',
        description: "The perfect plan if you're just getting started with our product.",
        features: [
            'Over 500 viral thumbnails library for inspiration',
            'Test Thumbnails on Youtube Home Page',
            'Unlimited Thumbnail Tests and Comparisons',
            'Gather thumbnails from Youtube home & search page',
            'Create up to 21 Focus Boards for In-Depth Tests and Comparisons',
            'Generate up to 1800 compelling titles (monthly) with AI',
        ],
        featured: true,
    },
    {
        name: 'Pro',
        id: 'tier-pro',
        href: '#',
        priceMonthly: '$89',
        description: 'A plan that scales with your rapidly growing business.',
        features: [
            'Over 500 viral thumbnails library for inspiration',
            'Test Thumbnails on Youtube Home Page & Search Page',
            'Unlimited Thumbnail Tests and Comparisons',
            'Gather thumbnails from Youtube home, search & channel pages',
            'Unlimited Focus Boards for In-Depth Tests and Comparisons',
            'Generate up to 5000 compelling titles (monthly) with AI',
            'Advanced AI powered insights on your thumbnails',
            'Thumbnail Design Ideation with AI',
            'Premium downloads for creating thumbnails',
        ],
        featured: false,
    },
]

const faqs = [
    {
        id: 1,
        question: "What is ThumbnailGeek?",
        answer:
            "ThumbnailGeek is a one of a kind Youtube growth tool to help you get the views you deserve. It's an intelligent tool that makes sure that every thumbnail you use, captures the attention of viewers. \nIt uses AI thumbnail analysis & comparison based on the psychology of what makes people click. This is to ensure your videos have the best chance of capturing the attention of viewers to get them to click. \nOur goal is to make it as easy as possible for your videos to receive the views they deserve.",
    },
    {
        id: 2,
        question: "Who is ThumbnailGeek for?",
        answer:
            "ThumbnailGeek is for youtubers, brands and creators looking to increase their Youtube views and click-through rate. Best part is, it is designed by youtubers. \nWhether you're just starting out with a few videos or have an established channel with thousands of subscribers, ThumbnailGeek can help you take your Youtube presence to the next level. With its best-in-class tools, ThumbnailGeek makes it simple to get the most views from your videos.",
    },
    {
        id: 3,
        question: "Can I create thumbnails on ThumbnailGeek?",
        answer:
            "No, ThumbnailGeek is an intelligent platform that helps you evaluate and analyze your YouTube video thumbnails to determine their effectiveness in attracting clicks and views. \nIt is primarily for helping you make informed decisions on which thumbnail will get the most views and how to optimize your thumbnails to make them appealing to potential viewers. It currently does not have the capability to create new thumbnails.",
    },
    {
        id: 3,
        question: "Does it work with Youtube Shorts?",
        answer:
            "No. It only works with regular youtube videos that have a thumbnail and title.",
    },
    {
        id: 4,
        question: "I don't get it. How does comparing my thumbnails give me more views?",
        answer:
            "Comparing your thumbnail with others allows you to see how your thumbnail stacks up against the competition. Everytime you post a video, you are competing with others for the attention of a viewer. \nThumbnailGeek uses advanced algorithms and AI to help you analyze your thumbnail using real data & viral psychology to provide you with insights. These are insights on how to make your thumbnail more eye-catching and appealing to potential viewers. \nBy making changes to your thumbnail based on these insights, you can increase the chances of people clicking on your video, leading to more views, a higher click-through rate, more revenue etc.",
    },
    {
        id: 5,
        question: "Will it work for any old average video?",
        answer:
            "A video thumbnail and title is just one important factor in attracting viewers to your content. However, if your videos are not engaging or of high quality, having an eye-catching thumbnail may not make much of a difference in terms of attracting and retaining viewers. \nIt may hurt your channel rather than help it. So, it's important to focus on creating engaging content first and then use ThumbnailGeek to make it more compelling to your audience.",
    },
    {
        id: 6,
        question: "How much does it cost?",
        answer:
            "All plans come with a 7-day free trial. ThumbnailGeek's pricing starts at $9/mo for the basic plan, $29/mo for the standard plan and $49/mo for the pro plan. \nThe basic plan only provides access to thumbnail visualization on Youtube Homepage and Search along with access to the Viral library and other resources. It does not have access to ThumbnailGeek's AI Title Generation, Advanced AI Thumbnail Analysis and Comparison tools. These can be found in the higher plans. \nWe may also offer discounts based on the number of subscribers your YouTube channel has.",
    },
    {
        id: 7,
        question: "What if I don't like it?",
        answer:
            "If ThumbnailGeek isn't right for you, it's no problem. You can easily cancel your subscription at any time directly from the platform or by reaching out to our support team. We have a 'no friction' cancelation policy.",
    },
    {
        id: 8,
        question: "What if I'm not ready yet?",
        answer:
            "YouTube is a powerful platform that can bring a lot of visibility to your brand or channel. Whether you're an individual content creator, influencer, or an enterprise, you can benefit from the large audience and engaged community on YouTube. \nHowever, with millions of creators on the platform, competition is tough. That's why it's important to start early and be consistent in your efforts, rather than waiting and missing out on potential growth and opportunities. \nOn the other hand, if you don't feel that YouTube is the right fit for you, it's okay to hold off for now. There's no rush and you can always start when you're ready. \nBut if you're serious about using YouTube to reach a wider audience and grow your channel, now is the time to take action and start creating engaging content and optimizing your channel with ThumbnailGeek's features.",
    },
    {
        id: 9,
        question: "Is ThumbnailGeek safe to use?",
        answer:
            "At ThumbnailGeek, the security and privacy of our users is of the utmost importance. We understand the importance of trust and work tirelessly to maintain that trust by adhering to all guidelines set forth by YouTube. \nOur features are designed to respect YouTube's policies and terms of use, and we continuously monitor and update our technology to ensure that we stay compliant. Rest assured that your data and content is safe. \nThumbnailGeek does not have the ability to write, edit, or delete data on your YouTube channel. Our technology only uses read-only permissions. Meaning, we can only view your data & provide insights, but not make any changes to it. This ensures that your account and content remains secure and under your control at all times.",
    },
    //More questions...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FirstStepsBillingPlans = () => {
    let { user_id } = useParams();
    const location = useLocation();
    const { user, token, logOut, providerId, photoUrl, connectYoutubeChannel } = UserAuth()
    const { premiumStatus, premiumTier, accountStatus, channelLinked, accessLevel } = PremiumCredentials();
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const mainUserId = localStorage.getItem("mainUserId");
    const mainEmail = localStorage.getItem("mainEmail");
    const navigate = useNavigate()
    const [productInfo, setProductInfo] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [frequency, setFrequency] = useState("1");
    const [isExploding, setIsExploding] = useState(false);
    const auth = getAuth();
    //const success_url = window.location.origin + `/first-steps/billing/${user_id}/success`;
    //const cancel_url = window.location.origin + location.pathname + '?success=false';
    // cancel_url: window.location.origin + location.pathname + '?success=false',

    useEffect(() => {

        if (channelLinked === false && premiumStatus === 'NOT_INITIATED') {
            navigate(`/first-steps/connect/${mainUserId}`);
            return;
        }

        if (accountStatus === 'ACTIVE' && premiumStatus === 'TRIALING') {
            navigate(`/dashboard`);
            return;
        }

        if (accountStatus === 'ACTIVE' && premiumStatus === 'PREMIUM') {
            navigate(`/dashboard`);
            return;
        }
    }, [premiumStatus, premiumTier, accountStatus, accessLevel, channelLinked]);

    useEffect(() => {
        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
            const requestBody = {
                loginStatus: true,
            };
            axios.post(apiUrl, requestBody)
                .then(response => {
                    // console.log('Token ' + response.data.token);
                    //Remove previous cookie if it exists
                    document.cookie = 'TG_AT=; max-age=0;';
                    document.cookie = 'TG_Avatar=; max-age=0;';
                    document.cookie = 'TG_ChannelName=; max-age=0;';
                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax; `;

                    document.cookie = `TG_Avatar = ${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax; `;
                    document.cookie = `TG_ChannelName = ${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax; `;

                    const dateSet = new Date();
                    localStorage.setItem('login_cookie_setDate', dateSet);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        }
    }, []);

    const logout = async () => {
        try {
            console.log("Logging out...");
            await logOut();
        } catch (error) {
            console.log(error)
        }
    }

    //Check if Auth user exists and is enabled
    const checkEmail = async (email) => {
        try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            //If the array of sign-in methods returned is not empty, the email is registered and enabled
            if (methods.length > 0) {
                console.log("Account auth active.");
                return true;
            } else {
                console.log("Account auth not active/found.");
                return false;
            }
        } catch (error) {
            console.error(error);
            //Handle any errors that occur during the check
        }
    };

    useEffect(() => {
        const getProductInfo = async () => {
            const pricingTableRef = collection(db, 'PricingTableData');
            const q = query(
                pricingTableRef,
                where('visible', '==', true),
                orderBy("order", "asc"),
            );
            const pricingTableDocs = await getDocs(q);

            const productInfo = await Promise.all(
                pricingTableDocs.docs.map(
                    async (pricingTableDoc) => {
                        const { id, product_id, monthly_pricing_id, monthly_pricing_id_test, yearly_pricing_id, ...rest } = pricingTableDoc.data();

                        const monthlyPriceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', monthly_pricing_id));
                        const monthly_price_data = monthlyPriceSnapshot.exists() ? monthlyPriceSnapshot.data() : null;

                        const yearlyPriceSnapshot = await getDoc(doc(db, 'Products', product_id, 'prices', yearly_pricing_id));
                        const yearly_price_data = yearlyPriceSnapshot.exists() ? yearlyPriceSnapshot.data() : null;

                        return {
                            id,
                            product_id,
                            monthly_pricing_id,
                            monthly_pricing_id_test,
                            yearly_pricing_id,
                            monthly_price_data,
                            yearly_price_data,
                            ...rest
                        };
                    }));

            setProductInfo(productInfo);
            setLoaded(true);
            setIsExploding(true)
        };

        const authenticationCheck = checkEmail(mainEmail)

        if (authenticationCheck) {
            getProductInfo();
        } else {
            logout();
            navigate('/signup', { replace: true });
        }

    }, []);

    const handleCheckout = async (index, id, pricing_id) => {
        console.log(pricing_id);
        console.log(window.location.origin);

        const checkoutData = {
            price: pricing_id,
            do_trial: true,
            allow_promotion_codes: true,
            collect_shipping_address: false,
            success_url: window.location.origin + `/first-steps/billing/${user_id}/success`,
            cancel_url: window.location.origin + `/dashboard?p=f`,
        };

        if (index !== 0) {
            checkoutData.promotion_code = "promo_1P6l4bD6B9LkfDQtIJZ3qPtn";
        }

        try {
            setPaymentLoading(true);
            await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), checkoutData).then((docRef) => {
                console.log(docRef.id);

                const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
                    (document) => {
                        console.log("Current data: ", document.data());
                        const { error, url } = document.data();
                        if (error) {
                            console.log(`An error occured: ${error.message}`);
                            setPaymentLoading(false);
                        }
                        if (url) {
                            const userDoc = doc(db, "Users", mainUserId)
                            const data = {
                                pricing_table_item_id: id.trim(),
                            }
                            setDoc(userDoc, data, { merge: true });

                            window.location.assign(url);
                        }
                        //setPaymentLoading(false);
                    });
            });
        } catch (error) {
            setPaymentLoading(false);
            console.log(`An error occured: ${error.message}`);
            toast("An error odccured. Kindly refresh page.", {
                icon: ({ theme, type }) => <FiAlertCircle className='text-white h-7 w-7' />
            });
        }
    };

    const handleOneTimePayment = async (index, id, pricing_id) => {
        console.log(pricing_id);
        console.log(window.location.origin);

        const checkoutData = {
            price: pricing_id,
            do_trial: true,
            allow_promotion_codes: true,
            collect_shipping_address: false,
            success_url: window.location.origin + `/first-steps/billing/${user_id}/success`,
            cancel_url: window.location.origin + `/dashboard?p=f`,
        };

        if (index !== 0) {
            checkoutData.promotion_code = "promo_1P6l4bD6B9LkfDQtIJZ3qPtn";
        }

        try {
            setPaymentLoading(true);
            await addDoc(collection(db, "Users", mainUserId, "checkout_sessions"), checkoutData).then((docRef) => {
                console.log(docRef.id);

                const unsub = onSnapshot(doc(db, "Users", mainUserId, "checkout_sessions", docRef.id),
                    (document) => {
                        console.log("Current data: ", document.data());
                        const { error, url } = document.data();
                        if (error) {
                            console.log(`An error occured: ${error.message}`);
                            setPaymentLoading(false);
                        }
                        if (url) {
                            const userDoc = doc(db, "Users", mainUserId)
                            const data = {
                                pricing_table_item_id: id.trim(),
                            }
                            setDoc(userDoc, data, { merge: true });

                            window.location.assign(url);
                        }
                        //setPaymentLoading(false);
                    });
            });
        } catch (error) {
            setPaymentLoading(false);
            console.log(`An error occured: ${error.message}`);
            toast("An error odccured. Kindly refresh page.", {
                icon: ({ theme, type }) => <FiAlertCircle className='text-white h-7 w-7' />
            });
        }
    };

    if (loaded === false) {
        return <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
            <div className="flex flex-col items-center justify-center">
                {/* <div className='relative w-2/3'> */}
                <img className='w-12' src={logoImage} />
                {/* </div> */}
                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                    <path
                        className="fill-gray-300"
                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path
                        className="fill-gray-500"
                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>
        </div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>First Steps - Plans | ThumbnailGeek</title>
                <meta name="description" content="First Steps - Plans | ThumbnailGeek" />
            </Helmet>
            <div className=" px-6 pt-4">
                {/* Start Nav */}
                <div className='border-gray-200 w-full flex justify-between lg:border-b pb-4 lg:pb-4 mb-4'>
                    <div className="flex">
                        <a className='w-48 pt-[6px] text-gray-800 relative text-2xl font-bold lg:text-2xl hover:text-gray-700' href="#">
                            <img src={logo} />
                        </a>
                    </div>
                    <AvatarNoPremium />
                </div>
                {/* End Nav */}
            </div>

            <div className="w-full flex flex-col items-center justify-center">
                <div className="mx-auto max-w-2xl px-6 sm:px-6 lg:px-8 inline-flex space-x-3" aria-hidden="true">
                    <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                        <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                    </div>
                    <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                        <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                    </div>
                    <div className="overflow-hidden w-5 rounded-full bg-gray-200">
                        <div className="h-2 rounded-full bg-blue-500" style={{ width: '100%' }} />
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center">
                <div className="sm:mx-auto sm:w-full sm:max-w-7xl">
                    <div className="max-w-7xl px-6 pt-16 sm:pt-16 lg:px-8">
                        <div className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl opacity-50" aria-hidden="true">
                            <div
                                className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>

                        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Start earning more for your hard work.
                            </p>
                            <p className="mt-7 text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl">
                                Try ThumbnailGeek free for 7 days, your card will NOT be charged.<br />Cancel anytime in app.
                            </p>
                            {/* <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Start getting the views you deserve & earning more from your hard work.
                            </p> */}
                        </div>

                        {/* 
                        <div className="mt-6">
                            <div className="flex overflow-x-auto">
                                <div className="mx-auto flex space-x-12 whitespace-nowrap px-4 py-3 sm:px-6 lg:space-x-24 lg:px-8">

                                    <div className="flex items-center text-sm font-medium text-gray-600">
                                        <AiOutlineGift className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                        <p>7 day free trial</p>
                                    </div>

                                    <div className="flex items-center text-sm font-medium text-gray-600">
                                        <RiRefund2Line className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                        <p>Cancel Anytime</p>
                                    </div>

                                    <div className="flex items-center text-sm font-medium text-gray-600">
                                        <RiRefund2Line className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                        <p>14 day money back guarantee</p>
                                    </div>

                                    <div className="flex items-center text-sm font-medium text-gray-600">
                                        <MdOutlineFreeBreakfast className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                        <p>0$ due today</p>
                                    </div>
                                </div>

                            </div>
                        </div> */}

                        <div className="mt-8 flex flex-col items-center justify-center">
                            {/* <p className="inline-flex mx-auto max-w-xl text-center text-sm leading-8 text-gray-800 my-6 px-3 bg-green-500 rounded-md">
                                <span className="font-extrabold pr-1 py-0 text-white">Get 2 months free</span>
                                <span className="text-white">with yearly plans</span>
                            </p> */}

                            <div className="flex justify-center mt-7">

                                <RadioGroup
                                    value={frequency}
                                    onChange={setFrequency}
                                    className="grid grid-cols-2 gap-x-1 rounded-lg p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-400"
                                >
                                    <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                                    <RadioGroup.Option
                                        value='1'
                                        className={({ checked }) =>
                                            classNames(
                                                checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                                                'cursor-pointer rounded-lg px-2.5 py-1'
                                            )
                                        }
                                    >
                                        <span>Monthly</span>
                                    </RadioGroup.Option>
                                    <RadioGroup.Option
                                        value='2'
                                        className={({ checked }) =>
                                            classNames(
                                                checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                                                'cursor-pointer rounded-lg px-2.5 py-1'
                                            )
                                        }
                                    >
                                        <span>Yearly</span>
                                    </RadioGroup.Option>
                                </RadioGroup>
                            </div>
                        </div>


                        {isExploding &&
                            <>
                                <div className="absolute top-0 left-0 ">
                                    <ConfettiExplosion zIndex={52} />
                                </div>
                                <div className="absolute top-0 right-1/2">
                                    <ConfettiExplosion zIndex={52} />
                                </div>
                                <div className="absolute top-0 right-0">
                                    <ConfettiExplosion zIndex={52} />
                                </div>
                            </>
                        }

                        <div className="grid grid-cols-12 gap-6 mt-16">
                            {productInfo?.map((product, index) => (

                                <div key={product.id} className={`relative flex-none col-span-full md:col-span-4 bg-white rounded-lg border 
                                ${product.highlighted ? 'border-blue-500' : 'border-gray-200'} `}>
                                    <div className="">
                                        {
                                            product.highlighted === true
                                                ?
                                                <div className="absolute -top-4 right-1/2 px-5 py-1 translate-x-1/2 shadow bg-yellow-400 text-black text-sm font-semibold rounded-md">
                                                    Best Value
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="px-7 pt-10 pb-6 border-b border-gray-200">
                                            <header className="flex items-center mb-2">
                                                <h3 className="text-lg text-gray-800 font-semibold">{product.name}</h3>
                                            </header>
                                            <div className="text-sm mb-2">{product.description}</div>

                                            {
                                                frequency === '1'
                                                    ?
                                                    <>
                                                        <div className=''>

                                                            {
                                                                index === 0 && (
                                                                    <div className="text-gray-800 font-bold mb-0 mt-7 opacity-0">
                                                                        <span className="text-lg">$</span>
                                                                        <span className="text-xl line-through">
                                                                            {product.monthly_price_data.unit_amount / 100} { }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                index === 1 && (
                                                                    <div className="text-gray-800 font-bold mb-0 mt-7 ">
                                                                        <span className="text-lg">$</span>
                                                                        <span className="text-xl line-through">
                                                                            {product.monthly_price_data.unit_amount / 100} { }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                index === 2 && (
                                                                    <div className="text-gray-800 font-bold mb-0 mt-7 ">
                                                                        <span className="text-lg">$</span>
                                                                        <span className="text-xl line-through">
                                                                            {product.monthly_price_data.unit_amount / 100} { }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                index === 3 && (
                                                                    <div className="text-gray-800 font-bold mb-0 mt-7 ">
                                                                        <span className="text-lg">$</span>
                                                                        <span className="text-xl line-through">
                                                                            {product.monthly_price_data.unit_amount / 100} { }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="text-gray-800 font-bold mb-4 mt-0">
                                                                <span className="text-2xl">$</span>
                                                                <span className="text-3xl">
                                                                    {index === 1 || index === 2 || index === 3 ? (product.monthly_price_data.unit_amount / 100) - 10 : product.monthly_price_data.unit_amount / 100} { }
                                                                </span>
                                                                <span className="text-gray-500 font-medium text-sm">/mo</span>
                                                                {/* {index === 1 || index === 2 || index === 3
                                                                    ?
                                                                    <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-xs">
                                                                        (10<span className="text-[12px]">$</span> off first 2 months)
                                                                    </span>
                                                                    :
                                                                    <></>
                                                                } */}
                                                            </div>
                                                        </div>

                                                        {
                                                            paymentLoading === false
                                                                ?
                                                                <>
                                                                    {
                                                                        product.highlighted === true
                                                                            ?
                                                                            <button onClick={() => { handleCheckout(index, product.id, product.monthly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3 rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                        focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                Start 7-day free trial
                                                                            </button>
                                                                            :
                                                                            <button onClick={() => { handleCheckout(index, product.id, product.monthly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3  rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                        focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                Start 7-day free trial
                                                                            </button>
                                                                    }
                                                                </>
                                                                :
                                                                <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                            shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                            focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                    <div className="inline-flex space-x-1 text-center">
                                                                        <span>Loading</span>
                                                                        <DotDotDot className="pl-2" />
                                                                    </div>
                                                                </button>
                                                        }
                                                    </>
                                                    :
                                                    <>

                                                        <div className="text-gray-800 font-bold mb-4 mt-7">
                                                            <span className="text-2xl">$</span><span className="text-3xl">
                                                                {((product.yearly_price_data.unit_amount / 100) / 12).toFixed(0)}
                                                            </span>
                                                            <span className="text-gray-500 font-medium text-sm">/mo</span>
                                                            {/* <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-sm">
                                                                (<span className="text-[12px]">$</span>{product.daily_yearly_price}/day)
                                                            </span> */}
                                                            <span className="text-gray-600 font-normal mb-4 mt-7 ml-3 text-xs">
                                                                (<span className="text-[12px]">$</span>{(product.yearly_price_data.unit_amount / 100) - 10} billed annually)
                                                            </span>
                                                        </div>
                                                        {
                                                            paymentLoading === false
                                                                ?
                                                                <>
                                                                    {
                                                                        product.highlighted === true
                                                                            ?
                                                                            <button onClick={() => { handleCheckout(index, product.id, product.yearly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3 rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-500 focus:outline-none hover:text-white
                                                                        focus-visible:ring-2 hover:bg-blue-700 text-white w-full">
                                                                                Start 7-day free trial
                                                                            </button>
                                                                            :
                                                                            <button onClick={() => { handleCheckout(index, product.id, product.yearly_pricing_id) }} className="font-medium text-sm inline-flex items-center 
                                                                        justify-center px-3 py-3  rounded-lg leading-5 
                                                                        shadow-sm transition duration-150 ease-in-out bg-blue-400 focus:outline-none 
                                                                        focus-visible:ring-2 hover:bg-blue-500 text-white w-full">
                                                                                Start 7-day free trial
                                                                            </button>
                                                                    }
                                                                </>
                                                                :
                                                                <button disabled={true} className="font-medium text-sm inline-flex items-center justify-center px-3 py-3 border border-transparent rounded-lg leading-5 
                                                                            shadow-sm transition duration-150 ease-in-out bg-slate-600 focus:outline-none 
                                                                            focus-visible:ring-2 hover:bg-slate-700 text-white w-full">
                                                                    <div className="inline-flex space-x-1 text-center">
                                                                        <span>Loading</span>
                                                                        <DotDotDot className="pl-2" />
                                                                    </div>
                                                                </button>
                                                        }
                                                    </>
                                            }
                                        </div>

                                        <div className="px-7 pt-4 pb-5">
                                            <div className="text-xs text-gray-800 font-semibold uppercase mb-4">What's included</div>
                                            <ul>
                                                {product.features.map((feature, index) => (
                                                    <li key={index} className="flex gap-x-3 mb-3">
                                                        <svg className="w-3 h-3 mt-2 flex-shrink-0 fill-current text-blue-500 mr-2" viewBox="0 0 12 12">
                                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                                        </svg>
                                                        <div className="text-sm">{feature}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>

                        {/* <p className="mx-auto mt-16 max-w-2xl text-center text-xs leading-8 text-gray-600">
                            Or get your money back within 5 days of your purchase, if you received zero value, but forgot to cancel.
                        </p> */}
                    </div>
                </div>
            </div>

            {/* <div className="bg-white py-6 sm:py-6">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Lifetime membership (limited time offer)</h3>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                Get in early and take advantage of our unbelievable lifetime membership plan and enjoy permanent access to our core features.
                            </p>
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">What’s included</h4>
                                <div className="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul
                                role="list"
                                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                            >
                                <li className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-blue-600" aria-hidden="true" />
                                    All features of the Lite plan
                                </li>
                            </ul>
                        </div>
                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                <div className="mx-auto max-w-xs px-8">
                                    <p className="text-base font-semibold text-gray-600">Pay once, use it forever</p>
                                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">$69</span>
                                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                                    </p>
                                    <a
                                        href="#"
                                        className="mt-10 block w-full rounded-md bg-blue-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                    >
                                        Get access
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <div className="mx-auto max-w-7xl py-12 px-6 lg:py-24 lg:px-8">

                <div className="max-w-4xl w-[700px] ring-2 ring-yellow-400 p-5 rounded-lg relative pt-9 bg-blue-100/70 text-gray-700 my-2 mx-auto mb-16">
                    <p className="text-sm font-semibold absolute right-1/2 translate-x-1/2 top-0 bg-yellow-400 
                                rounded-br-lg rounded-bl-lg px-4 py-1 text-gray-800">Special Offer</p>

                    <p className="text-base font-medium text-center max-w-2xl flex flex-col my-3">
                        <span className='mb-1'>Start your trial today and get access to these premium resources for free!</span>
                        <span className='font-semibold mt-2'>• 72 Title templates that get clicks everytime ($15 value)</span>
                        <span className='font-semibold mt-1'>• 31 Facial Expressions that make your thumbnails irresistable($10 value)</span>
                    </p>
                    {/* <p className="text-sm text-center font-medium ">Plus 10$ off your 12 months for Pro & Max plans.</p> */}
                </div>

                <div className="mt-6">
                    <div className="flex overflow-x-auto">
                        <div className="mx-auto flex space-x-12 whitespace-nowrap px-4 py-3 sm:px-6 lg:space-x-24 lg:px-8">

                            <div className="flex items-center text-sm font-medium text-gray-600">
                                <AiOutlineGift className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                <p>7 day free trial with full features</p>
                            </div>

                            <div className="flex items-center text-sm font-medium text-gray-600">
                                <RiRefund2Line className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                <p>Cancel Anytime</p>
                            </div>

                            <div className="flex items-center text-sm font-medium text-gray-600">
                                <MdOutlineFreeBreakfast className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                <p>14-day money back guarantee</p>
                            </div>

                            {/* <div className="flex items-center text-sm font-medium text-gray-600">
                                <MdOutlineFreeBreakfast className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />
                                <p>0$ due today</p>
                            </div> */}
                        </div>

                    </div>
                </div>

                {/* <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-center text-gray-900">Still have questions?</h2>
                    <dl className="mt-16 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">
                                                {faq.answer.split('\n').map((line, index) => (
                                                    <span key={index}>
                                                        {line}
                                                        <br /><br />
                                                    </span>
                                                ))}
                                            </p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div> */}
            </div >

            <footer className=" w-full">
                <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                        <a href="/privacy" className="text-gray-400 text-xs hover:text-gray-500">
                            <span className="sr-only">Privacy Policy</span>
                            <span>Privacy Policy</span>
                        </a>
                        <a href="/terms" className="text-gray-400 text-xs hover:text-gray-500">
                            <span className="sr-only">Terms of Use</span>
                            <span>Terms of Use</span>
                        </a>
                    </div>
                    <div className="mt-8 md:order-1 md:mt-0">
                        <p className="text-center text-xs leading-5 text-gray-500">
                            &copy; {new Date().getFullYear()} ThumbnailGeek. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FirstStepsBillingPlans