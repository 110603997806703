import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { db } from '../../../firebase-config';
import { UserAuth } from '../../../context/AuthContext';
import { PremiumCredentials } from '../../../context/PremiumContext';
import { getAuth, getRedirectResult, GoogleAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, addDoc, getFirestore, serverTimestamp, collection, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import logoImage from '../../../assets/logo192.png';

const Validator = () => {
    const { auth, googleSignIn } = UserAuth();
    const { premiumStatus, premiumTier, accountStatus, channelLinked } = PremiumCredentials();
    const mainUserId = localStorage.getItem("mainUserId");
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const navigate = useNavigate();
    const authG = getAuth();
    const [validating, setValidating] = useState(true)
    const [banned, setBanned] = useState(false)

    useEffect(() => {
        // console.log('Validating');
        //Check account info
        const checkUserId = async () => {
            if (mainUserId !== undefined && mainUserId !== null && mainUserId !== "") {
                const firestore = getFirestore()
                const docRef = doc(firestore, "Users", mainUserId)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    const banned = docSnap.data().banned;
                    const account_status = docSnap.data().account_status;
                    const premium_status = docSnap.data().premium_status;
                    const premium_tier = docSnap.data().premium_tier;
                    const premium_expiry = docSnap.data().premium_expiry;

                    if (banned === true) {
                        //Banned
                        setBanned(true)
                        setValidating(false)
                        return;
                    }

                    if (account_status === "ACTIVE") {
                        //If token expired create new token
                        if (mainUserId !== null && mainUserId !== undefined && mainUserId !== "") {
                            const apiUrl = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/uv/set/${mainUserId}`;
                            const requestBody = {
                                loginStatus: true,
                            };
                            axios.post(apiUrl, requestBody)
                                .then(response => {
                                    // console.log('Token ' + response.data.token);
                                    document.cookie = `TG_AT=${response.data.token}; max-age=2592000; path='/'; SameSite=Lax;`;

                                    const dateSet = new Date();
                                    localStorage.setItem('login_cookie_setDate', dateSet);
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });

                            document.cookie = `TG_Avatar=${channelPhotoUrl}; max-age=2592000; path='/'; SameSite=Lax;`;
                            document.cookie = `TG_ChannelName=${channelDisplayName}; max-age=2592000; path='/'; SameSite=Lax;`;
                        } else {
                            navigate(`/login`);
                        }
                        setValidating(false)
                    } else {
                        console.log('Something else is wrong');
                        navigate(`/login`);
                    }
                } else {
                    navigate(`/signup`);
                }
            } else {
                navigate(`/login`);
            }
        }
        checkUserId();
    }, []);

    if (validating === true) {
        return <div className="w-full flex lg:h-screen justify-center items-center bg-gray-100 xl:px-8">
            <div className="flex flex-col items-center justify-center -mt-24">
                <div className='relative'>
                    <img className='w-12' src={logoImage} />
                </div>
                <p className='mt-2 text-center font-normal text-lg text-gray-700'>Validating...</p>
                <svg className="animate-spin h-5 w-5 text-gray-500 mt-3" viewBox="3 3 18 18">
                    <path
                        className="fill-gray-300"
                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path
                        className="fill-gray-500"
                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>
        </div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Validator | ThumbnailGeek</title>
                <meta name="description" content="Login | ThumbnailGeek" />
            </Helmet>

            <div className="w-full lg:h-screen flex flex-col justify-between items-center bg-gray-100 xl:px-8">
                <div className="flex flex-col items-center justify-center flex-grow -mt-24">
                    <div className='relative'>
                        <img className='w-12' src={logoImage} alt="Logo" />
                    </div>
                    {
                        !banned
                            ? (
                                <div className="flex flex-col items-center justify-center text-center">
                                    <h2 className='mt-2 text-center font-medium text-xl text-gray-700'>Validation Complete!</h2>
                                    <div className="inline-flex space-x-3 mt-12">
                                        <a className='text-center border border-blue-600 bg-blue-50 rounded-md px-3 py-2 hover:text-blue-700' href='https://app.thumbnailgeek.com/dashboard'>
                                            Go to Dashboard
                                        </a>
                                        <a className='text-center border border-red-400 bg-red-50 rounded-md px-3 py-2 hover:text-red-700' href='https://www.youtube.com'>
                                            Go to Youtube
                                        </a>
                                    </div>
                                </div>
                            )
                            : (
                                <>
                                    <h2>Account Disabled!</h2>
                                    <a href='mailto:hello@thumbnailgeek.com'>Find out why?</a>
                                </>
                            )
                    }
                </div>
                <div className="w-full flex justify-center items-center py-4">
                    <div className="font-normal text-xs text-gray-700">thumbnailGeek.com &copy; {new Date().getFullYear()}</div>
                </div>
            </div>
        </>
    )
}
export default Validator 