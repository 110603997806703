import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { Dialog, Transition } from '@headlessui/react'
import { ChevronUpIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import axios from 'axios';
import useDynamicRefs from 'use-dynamic-refs';
import SideBar from '../../components/SideBar'
import { HiOutlineMenuAlt2, HiOutlineBell, HiHeart, HiOutlineLightBulb, HiOutlineLink, HiSparkles, HiChevronDown } from "react-icons/hi";
import Avatar from '../../components/Avatar';
import Lottie from "lottie-react";
import Tooltip from '../../components/Tooltip';
import Cookies from 'js-cookie'
import CountUp from 'react-countup';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from "./../../components/ProgressProvider";
import 'react-circular-progressbar/dist/styles.css';
import { PiLinkSimpleHorizontalBreakBold } from "react-icons/pi";
import { MdLightMode, MdOutlineFaceRetouchingNatural, MdCenterFocusWeak, MdOutlineArrowBack, MdTipsAndUpdates, MdTextFormat, MdTextSnippet, MdOutlineTextFields, MdAnalytics, MdError } from 'react-icons/md'
import { BiCaretDown, BiEnvelope, BiError, BiLaugh, BiPackage, BiPhone } from 'react-icons/bi'
import { Tab } from '@headlessui/react';
import { db, storage } from '../../../firebase-config';
import { addDoc, arrayUnion, collection, serverTimestamp, getFirestore, updateDoc, onSnapshot, setDoc, getDoc, getDocs, query, orderBy, doc, limit } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage";
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react'
import logo from '../../../assets/logo_full_32.png';
import image_analyze from "../../../assets/animations/image_analyze.json";
import Hint from '../../components/Hint';
import error from "../../../assets/animations/error.json";
import { GiBrain, GiBrainstorm, GiProcessor, GiSparkyBomb } from 'react-icons/gi';
import RotatingDial from '../../components/RotatingDial';
import { IoFlashOutline, IoImageOutline, IoSparkles, IoSparklesSharp, IoStatsChartSharp, IoThumbsUp, IoTrendingUpSharp } from 'react-icons/io5';
import SentenceBreaker from '../../components/SentenceBreaker';
import DotDotDot from '../../components/DotDotDot';
import { IoIosStats, IoMdInformationCircle, IoMdThumbsUp } from 'react-icons/io';
import { CgArrowDown, CgClose } from 'react-icons/cg';
import { MinusIcon } from '@heroicons/react/20/solid';
import { RxArrowDown } from 'react-icons/rx';
import { TbChevronDown, TbConfetti, TbReportAnalytics, TbTrendingDown } from 'react-icons/tb';
import { SlMagicWand, SlRefresh } from 'react-icons/sl';
import { FiAlertTriangle, FiPackage, FiThumbsUp } from 'react-icons/fi';
import { FcStatistics } from 'react-icons/fc';
import VideoPredictions from '../../components/VideoPredictions';
import ViewsTrendIcon from '../../components/ViewsTrendIcon';
import { FaRegThumbsUp, FaThumbsUp } from 'react-icons/fa';
import { GoThumbsup } from 'react-icons/go';
import { BsExclamation, BsExclamationTriangle, BsHandThumbsUp } from 'react-icons/bs';
import FeedbackBanner from '../../components/FeedbackBanner';

const AnalysisDetails = () => {
    let { thumbnail_id, pd } = useParams();
    let navigate = useNavigate()
    const channelPhotoUrl = localStorage.getItem("channelPhotoUrl");
    const channelDisplayName = localStorage.getItem("channelDisplayName");
    const channelId = localStorage.getItem("channelId");
    const mainUserId = localStorage.getItem("mainUserId");
    const [thumbnailData, setThumbnailData] = useState(null);
    const [title, setTitle] = useState('');
    const [colorAnalysis, setColorAnalysis] = useState([]);
    const [thumbnail, setThumbnail] = useState('');
    const [docTitle, setDocTitle] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [titleStatsLoaded, setTitleStatsLoaded] = useState(false);
    const [thumbnailStatsLoaded, setThumbnailStatsLoaded] = useState(false);
    const [tnTSentimentLoaded, setTntSentimentLoaded] = useState(false);
    const [tntSyncLoaded, setTntSyncLoaded] = useState(false);
    const [finishedAnalysis, setFinishedAnalysis] = useState();
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const savedUser = JSON.parse(localStorage.getItem("currentUser"));
    const [openFixes, setOpenFixes] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [pulse, setPulse] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [imageBrightness, setImageBrightness] = useState(0);
    const [readabilityBg, setReadabilityBg] = useState('');

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    const [overallScore, setOverallScore] = useState(0);

    const [overallClarityScore, setOverallClarityScore] = useState(0);
    const [clarityFromValue, setClarityFromValue] = useState(0);
    const [clarityCurrentValue, setClarityCurrentValue] = useState(0);


    const [overallCuriosityScore, setOverallCuriosityScore] = useState(0);
    const [curiosityFromValue, setCuriosityFromValue] = useState(0);
    const [curiosityCurrentValue, setCuriosityCurrentValue] = useState(0);


    const [overallVPScore, setOverallVPScore] = useState(0);
    const [vPFromValue, setVPFromValue] = useState(0);
    const [vPCurrentValue, setVPCurrentValue] = useState(0);


    const [mainScoreAccent, setMainScoreAccent] = useState('');
    const [mainScoreBg, setMainScoreBg] = useState('');
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    const [topColorAnalysis, setTopColorAnalysis] = useState([]);
    const [titleReadingLevel, setTitleReadingLevel] = useState('');
    const [titleReadingLevelGrade, setTitleReadingLevelGrade] = useState(0);

    // ------------------------------------------------------------------------

    const [clarityCalculated, setClarityCalculated] = useState();
    const [curiosityCalculated, setCuriosityCalculated] = useState();
    const [vPCalculated, setVPCalculated] = useState();

    // ------------------------------------------------------------------------

    const [titleFleschScore, setTitleFleschScore] = useState(0);
    const [resolution, setResolution] = useState();

    const [titleEvalState, setTitleEvalState] = useState({
        eval: {
            curiosity: 0,
            readingDifficulty: 0,
            complexity: 0,
            conciseness: 0,
        },
        fixes: {
            curiosityTip: '',
            readingDifficultyTip: '',
            complexityTip: '',
            concisenessTip: ''
        }
    });

    // -----------------------------------------------------------------------

    const [thumbnailEvalState, setThumbnailEvalState] = useState({
        colorScience: {
            colorBrightness: 0,
            colorEyeCatchiness: 0,
        },
        textOnImage: {
            size: 0,
            style: 0,
            readabilityAgainstImage: 0,
            textColorContrast: 0,
            numberOfWords: 0,
        },
        visualComplexity: {
            complexity: 0,
            explanation: 0 // Assuming this is visualComplexityExplanation
        },
        visualConcepts: {
            conceptCount: 0,
            conceptDifferences: 0,
        },
        visualUnderstandability: {
            conceptUnderstandability: 0,
        }
    });

    // -----------------------------------------------------------------------

    const [tntSentimentState, setTntSentimentState] = useState({
        context: {
            contextClarity: 0,
            missingContext: 0,
        },
        detailAndComposition: {
            intrigueOfElements: 0,
            focusAndBackgroundAssessment: 0,
        },
        subjectAndTheme: {
            subjectPresentation: 0,
            narrativeSuggestion: 0,
        },
        emotionAndResponse: {
            emotionalResponse: 0,
            emotionDrivenCuriosity: 0,
        },
        unansweredQuestions: {
            quantityOfQuestionsRaised: 0,
            depthOfQuestionsRaised: 0,
        }
    });

    // -----------------------------------------------------------------------

    const [tntSyncState, setTntSyncState] = useState({
        curiosityGap: 0,
        emotionalAndCognitiveAppeal: 0,
        thumbnailTitleHarmony: 0,
        visualTextualBalance: 0,
    });

    // -----------------------------------------------------------------------

    const [titleNotes, setTitleNotes] = useState();
    const [titleIssues, setTitleIssues] = useState();
    const [titleFixes, setTitleFixes] = useState();
    const [thumbnailEvalNotes, setThumbnailEvalNotes] = useState();
    const [thumbnailEvalIssues, setThumbnailEvalIssues] = useState();
    const [thumbnailEvalFixes, setThumbnailEvalFixes] = useState();
    const [tntSentimentNotes, setTntSentimentNotes] = useState();
    const [tntSentimentIssues, setTntSentimentIssues] = useState();
    const [tntSentimentFixes, setTntSentimentFixes] = useState();
    const [tntSyncNotes, setTntSyncNotes] = useState();
    const [tntSyncIssues, setTntSyncIssues] = useState();
    const [tntSyncFixes, setTntSyncFixes] = useState();


    // -----------------------------------------------------------------------

    const [clarityColor, setClarityColor] = useState();
    const [curiosityColor, setCuriosityColor] = useState();
    const [vPColor, setVPColor] = useState();
    const [vPCaption, setVPCaption] = useState('Still Processing...');

    const [advisory, setAdvisory] = useState(false);
    const [predictionAdvisory, setPredictionAdvisory] = useState('');
    const [predictionAdvisoryExplanation, setPredictionAdvisoryExplanation] = useState('');

    const lowAccent = '#dc2626';
    const lowBg = '#fecaca'; //'#FFAFA2';
    const midAccent = '#EE992A'; //'#D3B54B';
    const midBg = '#8F8FA4';
    const highAccent = '#00AD02';
    const highBg = '#bbf7d0';  //'#B7FFB9'; //'#BBFFB0'; 

    // const highResponseCaption = [
    //     'Absolutely Arresting',
    //     'Fully Loaded',
    //     'Strikingly Magnetic',
    //     'Instantly Captivating',
    //     'Unquestionably High',
    //     'Visual Mastery',
    //     'Peak Temptation',
    //     'Fully Charged',
    //     'Simply Irresistible',
    //     'Sheer Magnetism',
    //     'Unusually Effective',
    //     'Exceptionally Crafted',
    //     'Engagingly Perfect',
    //     'Visually Mastered',
    //     'Distinctly Magnetic',
    //     'Utterly Compelling',
    //     'Click Magnet!',
    // ]

    // const goodResponseCaption = [
    //     'Great Implementation',
    //     'Works Nicely',
    //     'Hits the spot',
    //     'Good to go',
    //     'Just Delicious',
    // ]

    // const midResponseCaption = [
    //     "Needs Enhancement",
    //     "Needs further refinement",
    //     "Upgrade the Quality",
    //     "Additional Polish Needed",
    //     "Essential Upgrades Needed",
    //     "Improve the look",
    //     "Refinement Needed",
    //     "Enhancement Needed"
    // ]

    // const lowResponseCaption = [
    //     "Rethink Idea Entirely",
    //     "Start Afresh",
    //     "New Approach Needed",
    //     "Rethink The Concept",
    //     "Total Redo Needed",
    //     "Re-imagine Completely",
    //     "Try A Different Idea",
    //     "Idea Reboot Needed",
    //     "Re-evaluate Idea",
    //     "Scrap & Redesign",
    //     "Different Concept Needed",
    //     "Reset Your Approach",
    //     "Redraft Needed",
    //     "Rework The Idea",
    //     "Transform Idea Completely"
    // ]

    const highResponseCaption = [
        'Very likely to get clicks!',
    ]

    const goodResponseCaption = [
        'Good to go!',
    ]

    const midResponseCaption = [
        "Needs Extra Work",
    ]

    const lowResponseCaption = [
        "Don't upload this!",
    ]

    const getRandomResponse = (responses) => {
        const randomIndex = Math.floor(Math.random() * responses.length);
        return responses[randomIndex];
    }

    const goBack = () => {
        // if (window.history.length > 1) {
        //     navigate(-1);
        // } else {
        navigate('/thumbnail-analysis/upload');
        // }
    };

    const incomingData = (data) => {
        if (data.average_brightness !== undefined || data.average_brightness !== null) {
            setLoaded(true)
            computeData(data);
        }

        if (data.thumbnail !== undefined || data.title !== undefined) {
            setLoaded(true)
            computeData(data);
        }

        if (data.openai_title_analysis_done === true) {
            computeTitleData(data.openai_title_analysis_result, data.openai_thumbnail_analysis_result);
        }

        if (data.openai_thumbnail_analysis_done === true) {
            computeThumbnailData(data.openai_thumbnail_analysis_result, data.openai_title_analysis_result);
        }

        if (data.openai_tnt_sentiment_analysis_done === true) {
            computeSentimentData(data.openai_tnt_sentiment_analysis_result, data.openai_tnt_sync_analysis_result);
        }

        if (data.openai_tnt_sync_analysis_done === true) {
            computeSyncData(data.openai_tnt_sentiment_analysis_result, data.openai_tnt_sync_analysis_result);
        }

        if (data.prediction_views_baseline !== undefined) {
            setExpectedViews(data.prediction_views_baseline);
            setAverageViews(data.prediction_average_views);
            setExpectedViewsDate(data.prediction_date_created);
        }

        if (data.prediction_advisory_status === 'success') {
            setAdvisory(true)
            setPredictionAdvisory(data.prediction_advisory);
            setPredictionAdvisoryExplanation(data.prediction_advisoryExplanation);
        }


        if (data.openai_title_analysis_verdict === 'error' || data.openai_thumbnail_analysis_verdict === 'error' || data.openai_tnt_sentiment_analysis_verdict === 'error') {
            setErrorState(true);
        }

        if (data.openai_title_analysis_verdict === 'sorry' || data.openai_thumbnail_analysis_verdict === 'sorry' || data.openai_tnt_sentiment_analysis_verdict === 'sorry') {
            setErrorState(true);
        }

        if (data.overall_analysis_verdict_counter === 4) {
            if (data.prediction_advisory_status === 'in_progress') {
                //waiting for advisory
                setFinishedAnalysis(false);
            } else if (data.prediction_advisory_status === 'success' || data.prediction_advisory_status === 'failed') {
                setFinishedAnalysis(true);
            } else {
                setFinishedAnalysis(true);
            }

            // if (((tntSyncIssues?.length ?? 0) + (tntSentimentIssues?.length ?? 0) + ((vPCurrentValue < 80 ? thumbnailEvalIssues?.length : 0) ?? 0)) > 0) {
            //     setSelectedIndex(1);
            // }
        } else if (data.overall_analysis_verdict_counter > 0) {
            setLoaded(true);
            computeData(data);
            // setLoadingMessage('Analysing...');
        } else {
            setLoadingMessage('Extracting data...');
        }
    };

    useEffect(() => {
        const getThumbnailData = async () => {
            const userThumbnailRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnail_id);

            try {
                const unsubscribe = onSnapshot(userThumbnailRef, (snapshot) => {
                    console.log(snapshot.data());

                    if (snapshot.exists()) {
                        const data = snapshot.data();

                        if (data.average_brightness !== undefined || data.average_brightness !== null) {
                            setLoaded(true)
                            incomingData(data);
                        }

                        if (data.thumbnail !== undefined || data.title !== null) {
                            setLoaded(true)
                            incomingData(data);
                        }

                        if (data.overall_analysis_verdict === "in_progress") {
                            incomingData(data);
                        }

                    } else {
                        // Document does not exist
                        setErrorState(true);
                        setErrorMessage("Document does not exist.");
                        // navigate('/404');
                        toast("Document doesn't exist.", {
                            icon: ({ theme, type }) => (
                                <HiOutlineBell className="text-red-500 h-7 w-7" />
                            ),
                        });
                    }
                });
                // Cleanup the listener on unmount or storyBoardId change
                return () => unsubscribe();
            } catch (error) {
                setErrorState(true);
                toast("Error fetching document!", {
                    icon: ({ theme, type }) => (
                        <HiOutlineBell className="text-red-500 h-7 w-7" />
                    ),
                });
            }
        }

        getThumbnailData();
    }, []);

    useEffect(() => {
        setClarityFromValue(clarityCurrentValue); // Update clarityFromValue to the last clarityCurrentValue
        setClarityCurrentValue(Math.round(overallClarityScore)); // Update clarityCurrentValue with the new rounded score
        calculateVPScore(overallClarityScore, overallCuriosityScore);
    }, [overallClarityScore]);

    useEffect(() => {
        setCuriosityFromValue(curiosityCurrentValue); // Update clarityFromValue to the last clarityCurrentValue
        setCuriosityCurrentValue(Math.round(overallCuriosityScore)); // Update clarityCurrentValue with the new rounded score
        calculateVPScore(overallClarityScore, overallCuriosityScore);
    }, [overallCuriosityScore]);

    useEffect(() => {
        setVPFromValue(vPCurrentValue); // Update clarityFromValue to the last clarityCurrentValue
        setVPCurrentValue(Math.round(overallVPScore)); // Update clarityCurrentValue with the new rounded score
    }, [overallVPScore]);

    const countSyllables = (word) => {
        try {
            word = word.toLowerCase();
            if (word.length <= 3) { return 1; } // Assuming words of 3 letters or less have 1 syllable
            word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, ''); // Removing suffixes
            word = word.replace(/^y/, ''); // Removing leading 'y'
            const matches = word.match(/[aeiouy]{1,2}/g); // Finding vowel sequences
            return matches ? matches.length : 1; // If no matches, assume 1 syllable; adjust based on your needs
        } catch (error) {
            console.log(error)
        }
    };

    const calculateFleschReadingEase = (text) => {
        try {
            const sentences = text.split(/[.!?]+/).filter(Boolean);
            const words = text.split(/\s+/).filter(Boolean);
            const syllables = words.reduce((acc, word) => acc + countSyllables(word), 0);
            const wordCount = words.length;
            const sentenceCount = sentences.length;

            // Guard against division by zero and nonsensical inputs
            if (wordCount === 0 || sentenceCount === 0) return 'N/A';

            // Apply the formula
            const readingEase = 206.835 - 1.015 * (wordCount / sentenceCount) - 84.6 * (syllables / wordCount);
            // Ensure the score does not exceed the logical bounds of the Flesch Reading Ease formula
            const boundedScore = Math.min(100, Math.max(0, readingEase));

            return boundedScore.toFixed(0);
        } catch (error) {
            console.log(error, 'Flethch failed');
        }

    };

    // Helper function to update clarity color and calculated status
    function updateClarity(percentage) {
        if (Math.round(percentage) > 79) {
            setClarityColor(highAccent);
        } else if (Math.round(percentage) > 59) {
            setClarityColor(midAccent);
        } else {
            setClarityColor(lowAccent);
        }
        setClarityCalculated(true);
    }

    function updateCuriosity(percentage) {
        if (Math.round(percentage) > 79) {
            setCuriosityColor(highAccent);
        } else if (Math.round(percentage) > 59) {
            setCuriosityColor(midAccent);
        } else {
            setCuriosityColor(lowAccent);
        }
        setCuriosityCalculated(true);
    }

    const calculateClarityScore = (titleEval, thumbnailEval) => {
        let titleScore = titleEval ? calculateTitleScore(titleEval) : 0;
        let thumbnailScore = thumbnailEval ? calculateThumbnailScore(thumbnailEval) : 0;

        let clarityScore;
        if (titleScore && thumbnailScore) {
            clarityScore = (titleScore * 0.3) + (thumbnailScore * 0.7);
        } else if (titleScore) {
            clarityScore = titleScore;
        } else if (thumbnailScore) {
            clarityScore = thumbnailScore;
        } else {
            clarityScore = 0; // Default to 0 if both are missing
        }

        // Update overall clarity score, color, and calculation status based on the final clarity score
        setOverallClarityScore(clarityScore);
        updateClarity(clarityScore); // Reusing the helper function to handle color and status

        return clarityScore;
    }

    const calculateCuriosityScore = (tntSentiment, tntSync) => {
        let tntSentScore = tntSentiment !== null ? calculateSentScore(tntSentiment) : 0;
        let tntSyncScore = tntSync !== null ? calculateTntScore(tntSync) : 0;
        let curiosityScore = 0;

        if (tntSentScore !== 0 && tntSyncScore !== 0) {
            curiosityScore = (tntSentScore + tntSyncScore) / 2;
        } else if (tntSentScore !== 0 && tntSyncScore === 0) {
            curiosityScore = tntSentScore;
        } else if (tntSentScore === 0 && tntSyncScore !== 0) {
            curiosityScore = tntSyncScore;
        }

        setOverallCuriosityScore(curiosityScore);
        updateCuriosity(curiosityScore);

        // // Setting clarity color based on percentage
        // if (Math.round(curiosityScore) > 79.99) {
        //     setCuriosityColor(highAccent);
        // } else if (Math.round(curiosityScore) > 44.99) {
        //     setCuriosityColor(midAccent);
        // } else {
        //     setCuriosityColor(lowAccent);
        // }

        // setCuriosityCalculated(true);
    }

    const calculateVPScore = (clarityScore, curiosityScore) => {
        let vpScore = 0;

        if (clarityScore !== 0 && curiosityScore !== 0) {
            vpScore = (clarityScore * 0.2) + (curiosityScore * 0.8);
            setOverallVPScore(vpScore);

            if (Math.round(vpScore) > 89.99) {
                setVPCaption(getRandomResponse(highResponseCaption));
                setVPColor(highAccent);
            } else if (Math.round(vpScore) > 79.99) {
                setVPCaption(getRandomResponse(goodResponseCaption));
                setVPColor(highAccent);
            } else if (Math.round(vpScore) > 59.99) {
                setVPCaption(getRandomResponse(midResponseCaption));
                setVPColor(midAccent);
            } else {
                setVPCaption(getRandomResponse(lowResponseCaption));
                setVPColor(lowAccent);
            }

            setVPCalculated(true);
        } else if (clarityScore !== 0 && curiosityScore === 0) {
            setOverallVPScore(clarityScore);

            if (Math.round(clarityScore) > 79) {
                setVPColor(highAccent);
            } else if (Math.round(clarityScore) > 44) {
                setVPColor(midAccent);
            } else {
                setVPColor(lowAccent);
            }

            setVPCalculated(true);
        } else if (clarityScore === 0 && curiosityScore !== 0) {
            setOverallVPScore(curiosityScore);

            if (Math.round(curiosityScore) > 79.99) {
                setVPColor(highAccent);
            } else if (Math.round(curiosityScore) > 59.99) {
                setVPColor(midAccent);
            } else {
                setVPColor(lowAccent);
            }

            setVPCalculated(true);
        }
    }

    const calculateTitleScore = (titleEval) => {
        // Extracting individual scores
        let { readingDifficulty, complexity, conciseness } = titleEval.eval;

        // Ensure scores do not exceed their maximum
        readingDifficulty.score = Math.min(readingDifficulty.score, 3);
        complexity.score = Math.min(complexity.score, 3);
        conciseness.score = Math.min(conciseness.score, 3);

        // Calculating total and maximum possible scores
        let totalScore = readingDifficulty.score + complexity.score + conciseness.score;
        let maxScore = 3 * 3; // Maximum score for each category is 3

        // Calculating percentage score
        let percentage = (totalScore / maxScore) * 100;

        // Update clarity color and calculation status
        updateClarity(percentage); // This is a new helper function to update the color and calculation status

        return percentage;
    }

    const calculateThumbnailScore = (thumbnailEval) => {
        const scores = [
            thumbnailEval.colorScience.colorBrightness,
            thumbnailEval.colorScience.colorEyeCatchiness,
            thumbnailEval.textOnImage.size,
            thumbnailEval.textOnImage.style,
            thumbnailEval.textOnImage.readabilityAgainstImage,
            thumbnailEval.textOnImage.textColorContrast,
            thumbnailEval.textOnImage.numberOfWords,
            thumbnailEval.visualComplexity.complexity,
            thumbnailEval.visualConcepts.conceptCount,
            thumbnailEval.visualConcepts.conceptDifferences,
        ].filter(value => value !== null && !isNaN(+value)).map(score => Math.min(score, 3)); // Cap scores at 3 and filter out invalid values

        // Calculate total score
        const totalScore = scores.reduce((acc, score) => acc + score, 0);
        const maxPossibleScore = scores.length * 3;
        const thumbnailPercentage = (totalScore / maxPossibleScore) * 100;

        // Update clarity color and calculation status
        updateClarity(thumbnailPercentage); // Use the same helper function

        return thumbnailPercentage;
    }

    const calculateSentScore = (tntSentiment) => {

        const scores = [
            tntSentiment.simplicityOfIdea,
            tntSentiment.untoldStory,
            tntSentiment.facesWithEmotion,
            tntSentiment.thumbnailStrength,
            tntSentiment.titleStrength,
        ].filter(value => value !== null && !isNaN(+value));

        // Calculate total score (sum of all valid scores)
        const totalScore = scores.reduce((acc, score) => acc + score, 0);
        const countOfValidScores = scores.length;
        const maxPossibleScore = countOfValidScores * 3;
        const sentPercentage = (totalScore / maxPossibleScore) * 100;

        // if (Math.round(sentPercentage) > 79.99) {
        //     setCuriosityColor(highAccent);
        // } else if (Math.round(sentPercentage) > 44.99) {
        //     setCuriosityColor(midAccent);
        // } else {
        //     setCuriosityColor(lowAccent);
        // }

        // setCuriosityCalculated(true);

        setOverallCuriosityScore(sentPercentage);
        updateCuriosity(sentPercentage);

        return sentPercentage;
    }

    const calculateTntScore = (tntSync) => {
        // Extracting individual scores
        let { curiosityGap, emotionalAndCognitiveAppeal, thumbnailTitleHarmony, visualTextualBalance } = tntSync;

        // Calculate weighted sum of scores and max possible score
        const sumScores = +curiosityGap + +emotionalAndCognitiveAppeal + +thumbnailTitleHarmony + +visualTextualBalance;
        const maxPossibleScore = 12;

        // Calculate and set the weighted percentage
        const percentage = (sumScores / maxPossibleScore) * 100;

        //How do I detect if the thumbnailPercentage falls between 44.99 and 79.99? 

        // if (Math.round(percentage) > 79) {
        //     setCuriosityColor(highAccent);
        // } else if (Math.round(percentage) > 44.99) {
        //     setCuriosityColor(midAccent);
        // } else {
        //     setCuriosityColor(lowAccent);
        // }
        // setCuriosityCalculated(true);

        setOverallCuriosityScore(percentage);
        updateCuriosity(percentage);

        return percentage;
    }

    const computeTitleData = (titleEval, thumbnailEval) => {
        try {
            setTitleEvalState({
                eval: {
                    readingDifficulty: titleEval.eval.readingDifficulty.score,
                    complexity: titleEval.eval.complexity.score,
                    conciseness: titleEval.eval.conciseness.score,
                }
            });

            setTitleNotes(titleEval.notes)
            setTitleIssues(titleEval.issues)
            setTitleFixes(titleEval.fixes)
            setTitleStatsLoaded(true);

            calculateClarityScore(titleEval, thumbnailEval);
        } catch (error) {
            console.log(error)
        }

    }

    const computeThumbnailData = (thumbnailEval, titleEval) => {
        try {
            setThumbnailEvalState({
                colorScience: {
                    colorBrightness: thumbnailEval.colorScience.colorBrightness,
                    colorEyeCatchiness: thumbnailEval.colorScience.colorEyeCatchiness,
                },
                textOnImage: {
                    style: thumbnailEval.textOnImage.style,
                    readabilityAgainstImage: thumbnailEval.textOnImage.readabilityAgainstImage,
                    textColorContrast: thumbnailEval.textOnImage.textColorContrast,
                    numberOfWords: thumbnailEval.textOnImage.numberOfWords,
                },
                visualComplexity: {
                    complexity: thumbnailEval.visualComplexity.complexity,
                },
                visualConcepts: {
                    conceptCount: thumbnailEval.visualConcepts.conceptCount,
                    conceptDifferences: thumbnailEval.visualConcepts.conceptDifferences,
                },
                visualUnderstandability: {
                    conceptUnderstandability: thumbnailEval.visualUnderstandability.conceptUnderstandability,
                }
            });

            setThumbnailEvalNotes(thumbnailEval.notes);
            setThumbnailEvalIssues(thumbnailEval.issues);
            setThumbnailEvalFixes(thumbnailEval.fixes);
            setThumbnailStatsLoaded(true);

            calculateClarityScore(titleEval, thumbnailEval);
        } catch (error) {
            console.log(error)
        }
    }

    const computeSentimentData = (tntSentiment, tntEval) => {
        try {
            setTntSentimentState({
                simplicityOfIdea: tntSentiment.simplicityOfIdea,
                untoldStory: tntSentiment.untoldStory,
                facesWithEmotion: tntSentiment.facesWithEmotion,
                thumbnailStrength: tntSentiment.thumbnailStrength,
                titleStrength: tntSentiment.titleStrength,
            });

            setTntSentimentNotes(tntSentiment.notes);
            setTntSentimentIssues(tntSentiment.issues);
            setTntSentimentFixes(tntSentiment.fixes);
            setTntSentimentLoaded(true);

            calculateCuriosityScore(tntSentiment, tntEval);

        } catch (error) {
            console.error("Failed to update state:", error);
        }
    }

    const computeSyncData = (tntSentiment, tntSync) => {
        try {
            setTntSyncState({
                curiosityGap: tntSync.curiosityGap,
                emotionalAndCognitiveAppeal: tntSync.emotionalAndCognitiveAppeal,
                thumbnailTitleHarmony: tntSync.thumbnailTitleHarmony,
                visualTextualBalance: tntSync.visualTextualBalance,
            });

            setTntSyncNotes(tntSync.notes);
            setTntSyncIssues(tntSync.issues);
            setTntSyncFixes(tntSync.fixes);
            setTntSyncLoaded(true);

            calculateCuriosityScore(tntSentiment, tntSync);

        } catch (error) {
            console.error("Failed to update state:", error);
        }
    }

    const computeNerdyStats = (data) => {

        // console.log(`${data.title} - data.title`)

        let overallBrightness = (Math.round((data.average_brightness / 255) * 100));
        let overallBrightnessScore = Math.max(5, Math.min(95, overallBrightness));

        // console.log(overallBrightnessScore, 'overallBrightnessScore')

        setImageBrightness(overallBrightnessScore);
        setResolution(`${data.resolution_width} x ${data.resolution_height}`);

        const score = calculateFleschReadingEase(data.title);
        setTitleFleschScore(score);
    }

    const computeDials = (data) => {


    }

    const computeData = (data) => {
        setThumbnail(data.thumbnail);
        setTitle(data.title);

        setPulse(false);

        computeNerdyStats(data);
        computeDials(data)

        if (data.openai_title_analysis_verdict === 'error' || data.openai_thumbnail_analysis_verdict === 'error') {
            setErrorState(true);
            setLoaded(false);
        } else {
            setLoaded(true);
        }

        setLoaded(true);

    }

    const [progress, setProgress] = useState(0);
    const [titleProgress, setTitleProgress] = useState(0);
    const [thumbnailProgress, setThumbnailProgress] = useState(0);
    const [sentProgress, setSentProgress] = useState(0);
    const [syncProgress, setSyncProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 95) {
                setProgress(progress + 0.5);
            } else {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [progress]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (titleProgress < 97) {
                setTitleProgress(titleProgress + 0.2);
            } else {
                clearInterval(interval);
            }
        }, 70);

        return () => clearInterval(interval);
    }, [titleProgress]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (thumbnailProgress < 97) {
                setThumbnailProgress(thumbnailProgress + 0.15);
            } else {
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [thumbnailProgress]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (sentProgress < 97) {
                setSentProgress(sentProgress + 0.15);
            } else {
                clearInterval(interval);
            }
        }, 120);

        return () => clearInterval(interval);
    }, [sentProgress]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (syncProgress < 97) {
                setSyncProgress(syncProgress + 0.15);
            } else {
                clearInterval(interval);
            }
        }, 130);

        return () => clearInterval(interval);
    }, [syncProgress]);

    useEffect(() => {
        let topColorsByScore = colorAnalysis.sort((a, b) => parseFloat(b.score) - parseFloat(a.score)).slice(0, 5);
        let sortedTopColors = topColorsByScore.sort((a, b) => parseFloat(b.pixelFraction) - parseFloat(a.pixelFraction));
        setTopColorAnalysis(sortedTopColors);
    }, []);

    // /// For obtaining the channel views for prediction
    // useEffect(() => {
    //     async function getThumbnailsData() {
    //         const colRef = collection(db, 'Users', mainUserId, 'ChannelThumbnailsData');
    //         const q = query(
    //             colRef,
    //             orderBy("position", "asc"),
    //             limit(15)
    //         );

    //         await getDocs(q)
    //             .then((querySnapshot) => {
    //                 const newData = querySnapshot.docs.map((doc) => {
    //                     const data = doc.data();
    //                     return {
    //                         upload_date: data.upload_date,
    //                         views: data.views,
    //                     };
    //                 });
    //                 // upload_date and views
    //                 const channelVideosDataString = JSON.stringify(newData);
    //                 console.log(channelVideosDataString);
    //             })
    //             .catch((error) => {
    //                 console.error("Error fetching documents: ", error);
    //             });
    //     }

    //     getThumbnailsData();
    // }, []);

    const [predictedViews, setPredictedViews] = useState(null);
    const [debugInfo, setDebugInfo] = useState({});
    const [videosExtractedCount, setvideosExtractedCount] = useState(16);
    const [expectedViews, setExpectedViews] = useState(null);
    const [averageViews, setAverageViews] = useState(null);
    const [expectedViewsDate, setExpectedViewsDate] = useState(null);
    const [channelDataFound, setChannelDataFound] = useState(false);
    const [titleSentimentAdvisory, setTitleSentimentAdvisory] = useState(false);

    // Function to parse view count strings
    const parseViewCount = (viewString) => {
        const num = parseFloat(viewString.replace(/[^0-9.]/g, ''));
        if (viewString.includes('K')) {
            return num * 1000;
        }
        if (viewString.includes("M")) {
            return num * 1000000;
        }
        return num;
    };

    // Function to calculate adjustment factor based on overallVPScore
    const calculateAdjustmentFactor = (score) => {
        if (score >= 95) {
            // Very good: potential for multiples of upside // 5x to 10x
            return 3 + (score - 95) * 1; // Range from 5x to 10x
        } else if (score >= 90) {
            // Very good: potential for multiples of upside // 1.5x to 5x
            return 1.5 + (score - 90) * 0.7; // Range from 1.5x to 5x
        } else if (score >= 80) {
            // Good: slightly above expected
            return 1 + (score - 80) * 0.05; // Range from 1x to 1.5x
        } {
            // Bad: definitely below expected
            return Math.max(0.01, 0.3 - (80 - score) * 0.01); // Range from 0.5x to 0.9x
        }
    };

    useEffect(() => {
        async function getThumbnailsData() {
            if (averageViews && expectedViews) {
                channelDataFound(true)
                // Calculate adjustment factor based on overallVPScore
                const adjustmentFactor = calculateAdjustmentFactor(overallVPScore);

                // Apply adjustment to expected views
                const adjustedPrediction = expectedViews * adjustmentFactor;

                setDebugInfo(prev => ({ ...prev, adjustmentFactor, adjustedPrediction }));

                // Blend with historical average
                const blendFactor = 0.3; // 30% weight to historical data, 70% to adjusted prediction
                const finalPrediction = (averageViews * blendFactor) + (adjustedPrediction * (1 - blendFactor));

                setDebugInfo(prev => ({ ...prev, blendFactor, finalPrediction }));
                setPredictedViews(Math.round(finalPrediction));
            } else {
                channelDataFound(false)
            }
        }

        getThumbnailsData();
    }, [mainUserId, overallVPScore]);

    // useEffect(() => {
    //     async function getThumbnailsData() {
    //         const colRef = collection(db, 'Users', mainUserId, 'ChannelThumbnailsData');
    //         const q = query(
    //             colRef,
    //             orderBy("position", "asc"),
    //             limit(videosExtractedCount)
    //         );

    //         try {
    //             const querySnapshot = await getDocs(q);
    //             const newData = querySnapshot.docs.map((doc) => {
    //                 const data = doc.data();
    //                 return {
    //                     upload_date: data.upload_date,
    //                     views: parseViewCount(data.views),
    //                     title: data.title,
    //                 };
    //             });

    //             if (newData.length === 0) {
    //                 setChannelDataFound(false);
    //                 console.log("No data found");
    //             } else {
    //                 setChannelDataFound(true);

    //                 setvideosExtractedCount(newData.length);
    //                 setDebugInfo(prev => ({ ...prev, newData }));

    //                 // Calculate average views
    //                 const totalViews = newData.reduce((sum, video) => sum + video.views, 0);
    //                 const averageViews = totalViews / newData.length;

    //                 // Calculate Median
    //                 const sortedViews = [...newData].map(video => video.views).sort((a, b) => a - b);
    //                 const medianViews = sortedViews.length % 2 === 0
    //                     ? (sortedViews[sortedViews.length / 2 - 1] + sortedViews[sortedViews.length / 2]) / 2
    //                     : sortedViews[Math.floor(sortedViews.length / 2)];

    //                 // Calculate 10% Trimmed Mean
    //                 const trimPercent = 0.1;
    //                 const trimCount = Math.floor(trimPercent * sortedViews.length);
    //                 const trimmedViews = sortedViews.slice(trimCount, sortedViews.length - trimCount);
    //                 const totalTrimmedViews = trimmedViews.reduce((acc, curr) => acc + curr, 0);
    //                 const trimmedMean = totalTrimmedViews / trimmedViews.length;

    //                 // Calculate the Average of the Median and the Trimmed Mean
    //                 const expectedViewsCombinedAverage = Math.round((medianViews + trimmedMean) / 2);
    //                 setExpectedViews(expectedViewsCombinedAverage);

    //                 setDebugInfo(prev => ({ ...prev, totalViews, averageViews }));

    //                 // Calculate adjustment factor based on overallVPScore
    //                 const adjustmentFactor = calculateAdjustmentFactor(overallVPScore);

    //                 // Apply adjustment to expected views
    //                 const adjustedPrediction = expectedViewsCombinedAverage * adjustmentFactor;

    //                 setDebugInfo(prev => ({ ...prev, adjustmentFactor, adjustedPrediction }));

    //                 // Blend with historical average
    //                 const blendFactor = 0.3; // 30% weight to historical data, 70% to adjusted prediction
    //                 const finalPrediction = (averageViews * blendFactor) + (adjustedPrediction * (1 - blendFactor));

    //                 setDebugInfo(prev => ({ ...prev, blendFactor, finalPrediction }));

    //                 setPredictedViews(Math.round(finalPrediction));
    //             }
    //         } catch (error) {
    //             console.error("Error fetching documents: ", error);
    //             setDebugInfo(prev => ({ ...prev, error: error.message }));
    //         }
    //     }

    //     getThumbnailsData();
    // }, [mainUserId, overallVPScore]);

    // const getTitleSentimentAdvisory = (titles) => {
    //     const userThumbnailRef = doc(db, 'Users', mainUserId, 'UserThumbnails', thumbnail_id);

    //     const docSnap = getDoc(userThumbnailRef);
    //     if (docSnap.exists()) {
    //         const data = docSnap.data();
    //         if (data && 'title_sentiment_advisory' in data) {
    //             const titleSentiment = data.title_sentiment_advisory;
    //             setTitleSentimentAdvisory(titleSentiment);
    //         } else {
    //             sendTitlesToAIForAdvisory(titles);
    //         }
    //     }
    // };

    // function getCookie(cookieName) {
    //     const cookies = document.cookie.split(';');
    //     for (let i = 0; i < cookies.length; i++) {
    //         const cookie = cookies[i].trim(); // Remove leading/trailing spaces
    //         if (cookie.startsWith(cookieName + '=')) {
    //             return cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character
    //         }
    //     }
    //     return null;
    // }

    // const sendTitlesToAIForAdvisory = (titles) => {
    //     const token = getCookie('TG_AT');
    //     const url = `https://us-central1-thumbnailgeek.cloudfunctions.net/app/api/get-channel-sentiment/${mainUserId}`;
    //     const requestBody = {
    //         titles: titles,
    //         thumbnail_id: thumbnail_id
    //     };
    //     const headers = {
    //         headers: {
    //             'Authorization': `${token}`,
    //         }
    //     };
    //     axios.post(url, requestBody, headers)
    //         .then(response => {

    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };

    const [markerPosition, setMarkerPosition] = useState('0%');
    useEffect(() => {
        const timer = setTimeout(() => {
            setMarkerPosition('40%');
        }, 0); // Start immediately

        return () => clearTimeout(timer);
    }, []);

    const [isTitleAnalysisExpanded, setIsTitleAnalysisExpanded] = useState(false);
    const [isThumbnailAnalysisExpanded, setIsThumbnailAnalysisExpanded] = useState(false);
    const [isTntSentimentExpanded, setIsTntSentimentExpanded] = useState(false);
    const [isTntSyncExpanded, setIsTntSyncExpanded] = useState(false);
    const toggleExpand = () => setIsTitleAnalysisExpanded(!isTitleAnalysisExpanded);
    const toggleThumbExpand = () => setIsThumbnailAnalysisExpanded(!isThumbnailAnalysisExpanded);
    const toggleSentimentExpand = () => setIsTntSentimentExpanded(!isTntSentimentExpanded);
    const toggleSyncExpand = () => setIsTntSyncExpanded(!isTntSyncExpanded);

    const textStyles = {
        color: vPColor, // Use vpColor for text color
    };

    const textHoverStyles = {
        color: vPColor, // Use vpColor for hover text color
        textDecoration: 'underline',
        cursor: 'pointer',
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Thumbnail Analysis | ThumbnailGeek</title>
                <meta name="description" content="Thumbnail Analysis | ThumbnailGeek" />
            </Helmet>

            <div className='bg-gray-50' >
                <SideBar selectedSideBarItem="thumbnail-analysis" darkMode={false} />

                <div className="ml-[55px] lg:ml-[240px] xl:ml-[280px] pb-24">
                    <div className="px-6 pt-[11px] -my-6 min-h-screen bg-gray-50 ">

                        <div className="w-[calc(100%_-_105px)] lg:w-[calc(100%_-_310px)] 2xl:w-[calc(100%_-_330px)] bg-gray-50 mx-6 pt-6 fixed z-10 right-0 top-0 justify-between xl:pt-7 lg:border-b pb-4 lg:pb-4 inline-flex">

                            <div className="flex flex-row justify-center items-center">
                                <button onClick={() => goBack()} className={`text-gray-800 bg-gray-100 hover:bg-gray-200
                             mr-4 py-2 px-2 rounded-md inline-flex justify-center items-center text-xl duration-200 justify-self-start`}>
                                    <MdOutlineArrowBack />
                                </button>
                                <div className="">
                                    <h5 hidden className={`text-xl text-gray-600 font-medium lg:block`}>Thumbnail & Title Intelligence with Viral Psychology</h5>
                                </div>
                                <div className="">
                                    <span className="inline-flex items-center rounded-md bg-purple-100 ml-2 px-2 py-1 
                                text-xs font-medium text-purple-700">
                                        BETA
                                    </span>
                                </div>
                            </div>

                            <div className="hidden absolute right-0 lg:flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
                                {/* <GoToYoutubeBtn /> */}
                                <Avatar />
                            </div>
                        </div>

                        <div className="w-full flex flex-wrap mt-16 2xl:max-w-7xl lg:ml-8 xl:ml-2 2xl:ml-8 items-start justify-start">
                            {
                                loaded === true && errorState === false
                                    ?
                                    <div className=" flex flex-col xl:flex-row">

                                        <div className="flex flex-wrap items-center justify-center w-full mb-12 xl:w-8/12 pr-4 xl:pr-4">
                                            {/* The Dials */}
                                            <div className="flex flex-col items-center justify-center lg:flex-row w-full mb-0">
                                                {
                                                    clarityCalculated
                                                        ?
                                                        <div className="m-7 relative w-48">
                                                            <ProgressProvider valueStart={0} valueEnd={overallClarityScore.toFixed(0)} >
                                                                {overallClarityScore => <CircularProgressbar className='-rotate-[126deg]'
                                                                    value={overallClarityScore}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: clarityColor,
                                                                        trailColor: mainScoreBg,
                                                                        backgroundColor: 'mainScoreBg',
                                                                    })}
                                                                />}
                                                            </ProgressProvider>
                                                            <span className='absolute top-[120px] left-[34px] text-xs font-bold text-gray-600'>0</span>
                                                            <span className='absolute top-[55px] left-[33px] text-xs font-bold text-gray-600'>25</span>
                                                            <span className='absolute top-[21px] left-[89px] text-xs font-bold text-gray-600'>50</span>
                                                            <span className='absolute top-[57px] right-[33px] text-xs font-bold text-gray-600'>75</span>
                                                            <span className='absolute top-[120px] right-[34px] text-xs font-bold text-gray-600'>100</span>

                                                            <div className="absolute left-[88px] top-[18px]">
                                                                <RotatingDial fromValue={clarityFromValue} value={clarityCurrentValue} color={clarityColor} className='' />
                                                            </div>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                <span className='font-bold tracking-tighter text-3xl'>
                                                                    <CountUp
                                                                        start={clarityFromValue}
                                                                        end={clarityCurrentValue}
                                                                        duration={2.75}
                                                                    />
                                                                </span>
                                                                <span className='font-medium text-sm'>Clarity</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="m-7 relative w-48">
                                                            <ProgressProvider valueStart={0} valueEnd={0} >
                                                                {overallClarityScore => <CircularProgressbar className='-rotate-[125deg]'
                                                                    value={0}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: mainScoreAccent,
                                                                        trailColor: mainScoreBg,
                                                                        backgroundColor: 'mainScoreBg',
                                                                    })}
                                                                />}
                                                            </ProgressProvider>

                                                            <span className='absolute left-[85px] top-[88px] text-xs font-bold text-gray-300'>
                                                                <DotDotDot />
                                                            </span>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                <span className='font-medium text-sm'>Clarity</span>
                                                            </div>
                                                        </div>
                                                }

                                                {
                                                    curiosityCalculated
                                                        ?
                                                        <div className="m-7 relative w-48">
                                                            <ProgressProvider valueStart={0} valueEnd={overallCuriosityScore.toFixed(0)}>
                                                                {overallCuriosityScore => <CircularProgressbar className='-rotate-[126deg]'
                                                                    value={overallCuriosityScore}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    rot
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: curiosityColor,
                                                                    })}
                                                                />}
                                                            </ProgressProvider>
                                                            <span className='absolute top-[120px] left-[34px] text-xs font-bold text-gray-600'>0</span>
                                                            <span className='absolute top-[55px] left-[33px] text-xs font-bold text-gray-600'>25</span>
                                                            <span className='absolute top-[21px] left-[89px] text-xs font-bold text-gray-600'>50</span>
                                                            <span className='absolute top-[57px] right-[33px] text-xs font-bold text-gray-600'>75</span>
                                                            <span className='absolute top-[120px] right-[34px] text-xs font-bold text-gray-600'>100</span>

                                                            <div className="absolute left-[88px] top-[18px]">
                                                                <RotatingDial fromValue={curiosityFromValue} value={curiosityCurrentValue} color={curiosityColor} className='' />
                                                                {/* <RotatingDial value={overallCuriosityScore.toFixed(0)} color={curiosityColor} className='' /> */}
                                                            </div>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                <span className='font-bold text-3xl'>
                                                                    <CountUp
                                                                        start={curiosityFromValue}
                                                                        end={curiosityCurrentValue}
                                                                        duration={2.75}
                                                                    />
                                                                </span>
                                                                <span className='font-medium text-sm'>Curiosity</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={`m-7 relative w-48`}>
                                                            <ProgressProvider valueStart={0} valueEnd={overallScore} >
                                                                {overallScore => <CircularProgressbar className='-rotate-[126deg]'
                                                                    value={overallScore}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: mainScoreAccent,
                                                                        trailColor: mainScoreBg,
                                                                        backgroundColor: 'mainScoreBg',
                                                                    })}
                                                                />}
                                                            </ProgressProvider>


                                                            <span className='absolute left-[85px] top-[88px] text-xs font-bold text-gray-300'>
                                                                <DotDotDot />
                                                            </span>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                {/* <span className='font-bold text-3xl'>{overallScore}</span> */}
                                                                <span className='font-medium text-sm'>Curiosity</span>
                                                            </div>
                                                        </div>
                                                }

                                                {
                                                    vPCalculated
                                                        ?
                                                        <div className={`m-7 relative w-48`}>
                                                            <ProgressProvider valueStart={0} valueEnd={overallVPScore}>
                                                                {overallVPScore => <CircularProgressbar className='-rotate-[125deg]'
                                                                    value={overallVPScore}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: vPColor,
                                                                        // trailColor: readabilityBg,
                                                                        // backgroundColor: 'mainScoreBg',
                                                                    })}
                                                                />}
                                                            </ProgressProvider>
                                                            <span className='absolute top-[120px] left-[34px] text-xs font-bold text-gray-600'>0</span>
                                                            <span className='absolute top-[55px] left-[33px] text-xs font-bold text-gray-600'>25</span>
                                                            <span className='absolute top-[21px] left-[89px] text-xs font-bold text-gray-600'>50</span>
                                                            <span className='absolute top-[57px] right-[33px] text-xs font-bold text-gray-600'>75</span>
                                                            <span className='absolute top-[120px] right-[34px] text-xs font-bold text-gray-600'>100</span>

                                                            <div className="absolute left-[88px] top-[18px]">
                                                                <RotatingDial fromValue={vPFromValue} value={vPCurrentValue} color={vPColor} className='' />
                                                            </div>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                <span className='font-bold text-3xl'>
                                                                    <CountUp
                                                                        start={vPFromValue}
                                                                        end={vPCurrentValue}
                                                                        duration={2.75}
                                                                    />
                                                                </span>
                                                                <span className='font-medium text-sm'>Viral Potential</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={`m-7 relative w-48`}>
                                                            <ProgressProvider valueStart={0} valueEnd={overallScore} >
                                                                {overallScore => <CircularProgressbar className='-rotate-[125deg]'
                                                                    value={overallScore}
                                                                    background={false}
                                                                    circleRatio={0.7}
                                                                    strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'butt',
                                                                        pathTransitionDuration: 3.55,
                                                                        pathColor: mainScoreAccent,
                                                                        trailColor: mainScoreBg,
                                                                        backgroundColor: 'mainScoreBg',
                                                                    })}
                                                                />}
                                                            </ProgressProvider>

                                                            <span className='absolute left-[85px] top-[88px] text-xs font-bold text-gray-300'>
                                                                <DotDotDot />
                                                            </span>

                                                            <div className="absolute left-1/2 -translate-x-1/2 bottom-[5px] flex flex-col items-center">
                                                                {/* <span className='font-bold text-3xl'>{overallScore}</span> */}
                                                                <span className='font-medium text-sm'>Viral Potential</span>
                                                            </div>
                                                        </div>
                                                }

                                            </div>

                                            {
                                                !finishedAnalysis &&

                                                <div className="flex flex-col items-center justify-center w-full ring-3 ring-red-400 p-3 pt-0 rounded-lg">
                                                    <div className="uppercase font-bold text-[27px] text-purple-600">
                                                        <span className='uppercase font-bold text-[27px] text-gray-600 animate-pulse'>Still processing...</span>
                                                    </div>
                                                </div>
                                            }

                                            <div className="flex flex-col items-center justify-center w-full ring-3 ring-red-400 mt-2 rounded-lg">
                                                <div className="w-full ">
                                                    <Tab.Group selectedIndex={!finishedAnalysis ? 1 : selectedIndex} onChange={setSelectedIndex}>
                                                        <Tab.List className='bg-gray-50 mt-7 lg:mt-0 text-gray-800 text-sm pt-5 flex mx-1 '>
                                                            <Tab className={({ selected }) =>
                                                                selected
                                                                    ? 'flex-1 outline-0 rounded-lg border-2 border-orange-600 active group mx-2'
                                                                    : 'flex-1 outline-0 rounded-lg border-2 border-orange-200/50 hover:text-gray-600 hover:border-orange-400 group mx-2'}
                                                            >
                                                                {

                                                                }
                                                                <div className="flex items-center p-2 2xl:p-4 ">
                                                                    <div className="text-orange-500 text-4xl mr-4 w-auto ">
                                                                        <FiAlertTriangle />
                                                                    </div>
                                                                    <div className='text-left'>
                                                                        <div className="text-lg ">
                                                                            {(tntSyncIssues?.length ?? 0) + (tntSentimentIssues?.length ?? 0) + ((vPCurrentValue < 80 && thumbnailEvalIssues?.length) ?? 0)} critical issues
                                                                        </div>
                                                                        <div className="text-xs  group-hover:underline cursor-pointer">View Recommendations</div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab className={({ selected }) =>
                                                                selected
                                                                    ? 'flex-1 outline-0 rounded-lg border-2 border-purple-600 active group mx-2'
                                                                    : 'flex-1 outline-0 rounded-lg border-2 border-purple-200/70 hover:text-gray-600 hover:border-purple-400 group mx-2'}
                                                            >
                                                                <div className="flex items-center p-2 2xl:p-4 ">
                                                                    <div className=" text-purple-600 text-4xl mr-4 w-auto ">
                                                                        <TbReportAnalytics />
                                                                    </div>
                                                                    <div className='text-left'>
                                                                        <div className="text-lg ">Full Analysis Details</div>
                                                                        <div className="text-xs  group-hover:underline cursor-pointer">Click to View Details</div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            <Tab className={({ selected }) =>
                                                                selected
                                                                    ? 'flex-1 outline-0  rounded-lg border-2 border-blue-600 active group mx-2'
                                                                    : 'flex-1 outline-0 rounded-lg border-2 border-blue-200/70 hover:text-gray-600 hover:border-blue-400 group mx-2'}
                                                            >
                                                                <div className="flex items-center p-3 2xl:p-4 ">
                                                                    <div className="text-blue-500 text-4xl mr-4 w-auto ">
                                                                        <IoTrendingUpSharp />
                                                                    </div>
                                                                    <div className='text-left'>
                                                                        <div className="text-lg ">Expected Views</div>
                                                                        <div className="text-xs  group-hover:underline cursor-pointer">See Views<span className='hidden'>Performance</span> Prediction</div>
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                        </Tab.List>

                                                        <Tab.Panels className=' w-full pt-4 flex flex-wrap justify-start overflow-scroll'>

                                                            <Tab.Panel className='w-full pt-2 flex flex-wrap justify-start'>

                                                                {((tntSyncIssues?.length ?? 0) + (tntSentimentIssues?.length ?? 0) + ((vPCurrentValue < 80 && thumbnailEvalIssues?.length) ?? 0)) > 0
                                                                    ?
                                                                    <div className="mx-1 relative sm:px-6 sm:py-4 w-full ring-1 ring-orange-600/40 bg-white overflow-y-scroll flex flex-wrap transition-all mb-5 rounded-lg sm:mt-0 sm:text-left duration-200">

                                                                        <div className="w-full px-3 lg:pl-0 pr-4 transition-all">
                                                                            <h3 className="text-base font-semibold leading-6 text-orange-600 mb-3">
                                                                                Issues
                                                                            </h3>
                                                                            <p className="text-sm text-gray-700">
                                                                                {tntSyncIssues?.length > 0 && <div className='font-semibold my-2 '>Psychology of Viewer Curiosity</div>}
                                                                                <ul>
                                                                                    {tntSyncIssues?.map((issue, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-orange-500 mr-2 -mt-1">•</span>
                                                                                            {issue}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>

                                                                                {tntSentimentIssues?.length > 0 && <div className='font-semibold my-2 '>Grabbing Attention</div>}
                                                                                <ul>
                                                                                    {tntSentimentIssues?.map((issue, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-orange-500 mr-2 -mt-1">•</span>
                                                                                            {issue}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>

                                                                                {thumbnailEvalIssues?.length > 0 && vPCurrentValue < 80 && <div className='font-semibold my-2 '>Thumbnail Visual</div>}
                                                                                <ul>
                                                                                    {vPCurrentValue < 80 && thumbnailEvalIssues?.map((issue, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-orange-500 mr-2 -mt-1">•</span>
                                                                                            {issue}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>

                                                                            </p>
                                                                        </div>
                                                                        {/* <div className="w-full lg:w-1/2 lg:border-l px-3 lg:pl-5 transition-all">
                                                                            <h3 className="text-base font-semibold  leading-6 text-blue-600 mb-3">
                                                                                Recommendations
                                                                            </h3>
                                                                            <p className="text-sm text-gray-700">

                                                                                {tntSyncIssues?.length > 0 && <div className='font-semibold my-2 '>Psychology of Viewer Curiosity</div>}
                                                                                <ul>
                                                                                    {tntSyncFixes?.map((fix, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-blue-500 mr-2 -mt-1">•</span>
                                                                                            {fix}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>


                                                                                {tntSentimentIssues?.length > 0 && <div className='font-semibold my-2 '>Grabbing Attention</div>}
                                                                                <ul>
                                                                                    {tntSentimentFixes?.map((fix, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-blue-500 mr-2 -mt-1">•</span>
                                                                                            {fix}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>


                                                                                {thumbnailEvalIssues?.length > 0 && vPCurrentValue < 80 && <div className='font-semibold my-2 '>Thumbnail Visual</div>}
                                                                                <ul>
                                                                                    {vPCurrentValue < 80 && thumbnailEvalFixes?.map((fix, index) => (
                                                                                        <li key={index} className="mb-2 inline-flex">
                                                                                            <span className="text-2xl text-blue-500 mr-2 -mt-1">•</span>
                                                                                            {fix}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>

                                                                            </p>
                                                                        </div> */}

                                                                    </div>
                                                                    :
                                                                    <div className="mx-1 relative sm:px-6 sm:py-4 w-full ring-1 ring-orange-600/40 bg-white overflow-y-scroll flex flex-wrap transition-all mb-5 rounded-lg sm:mt-0 sm:text-left duration-200">
                                                                        <div className="w-full flex flex-col items-center justify-center py-7">
                                                                            <FaRegThumbsUp className='w-7 h-7 text-gray-700' />
                                                                            <span className="mt-2 text-center text-sm">No Critical Issues.<br />All Good</span>
                                                                        </div>

                                                                    </div>
                                                                }

                                                                <div className="inline-flex p-3 pt-0 pl-6">
                                                                    <GiProcessor className='text-gray-500' />
                                                                    <p className="text-xs ml-1 text-gray-500">Suggestions by ThumbnailGeek AI</p>
                                                                </div>
                                                            </Tab.Panel>

                                                            <Tab.Panel className='w-full  pt-2 flex flex-wrap justify-start px-1 py-3'>
                                                                {/* Breakdows */}
                                                                <div className="w-full flex flex-wrap justify-center">
                                                                    <div className={`w-full lg:w-1/2 flex flex-col space-y-4 lg:pr-2 mb-4 lg:mb-0 transition-all duration-500`}>
                                                                        <div className="w-full rounded-lg bg-white ring-1 ring-gray-200">
                                                                            <div className="flex flex-col w-full p-3 text-sm">
                                                                                <div className="flex items-center space-x-3 pb-2 w-full justify-between">
                                                                                    <h3 className="truncate text-sm font-bold text-purple-600">Viral Psychology</h3>
                                                                                    <PiLinkSimpleHorizontalBreakBold />
                                                                                </div>

                                                                                {
                                                                                    tntSyncLoaded
                                                                                        ?
                                                                                        <div className={`flex flex-col mt-1 w-full transition-opacity duration-1000 ${tntSyncLoaded ? 'opacity-100' : 'opacity-0'}`}>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between'>
                                                                                                <span className='font-medium inline-flex text-gray-600'>Curiosity Gap
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute z-[34234] bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            The missing information that makes a viewer curious to watch
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSyncState.curiosityGap === 3 ? highAccent : tntSyncState.curiosityGap === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSyncState.curiosityGap === 3 ? 'High' : tntSyncState.curiosityGap === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Emotional Pull
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How the thumbnail+title is able to evoke emotion in the viewer
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSyncState.emotionalAndCognitiveAppeal === 3 ? highAccent : tntSyncState.emotionalAndCognitiveAppeal === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSyncState.emotionalAndCognitiveAppeal === 3 ? 'High' : tntSyncState.emotionalAndCognitiveAppeal === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>
                                                                                                    Strength of Concept

                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How much the text and visuals of the thumbnail & title come together as one idea
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSyncState.thumbnailTitleHarmony === 3 ? highAccent : tntSyncState.thumbnailTitleHarmony === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSyncState.thumbnailTitleHarmony === 3 ? 'High' : tntSyncState.thumbnailTitleHarmony === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>


                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Visual Simplicity
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How much easy it is to recognize what the thumbnail is showing.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSyncState.visualTextualBalance === 3 ? highAccent : tntSyncState.visualTextualBalance === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSyncState.visualTextualBalance === 3 ? 'High' : tntSyncState.visualTextualBalance === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            {isTntSyncExpanded && (
                                                                                                <>
                                                                                                    <div className='mt-0 w-full'>
                                                                                                        <div className='mt-1 mr-2 w-full p-4'>
                                                                                                            <>
                                                                                                                {tntSyncNotes?.map((note, index) => (
                                                                                                                    <li key={index} className="mb-2 inline-flex text-[13px]">
                                                                                                                        <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                        {note}
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </>
                                                                                            )}

                                                                                            {
                                                                                                tntSyncNotes?.length > 0 &&
                                                                                                <div className='w-full text-center pt-2'>
                                                                                                    <button
                                                                                                        className="text-gray-500 text-[12px]"
                                                                                                        onClick={toggleSyncExpand}
                                                                                                    >
                                                                                                        {!isTntSyncExpanded ? "What works" : "Hide"}
                                                                                                    </button>
                                                                                                </div>
                                                                                            }


                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                errorState === true
                                                                                                    ?
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <BiError className='w-12 h-12 text-yellow-500' />
                                                                                                        Failed.
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <GiProcessor className='w-12 h-12 animate-pulse text-gray-500' />
                                                                                                        <div className="flex h-2 w-32 mb-4  mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                                                            <div
                                                                                                                style={{ width: `${syncProgress}%` }}
                                                                                                                className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full rounded-lg bg-white ring-1 ring-gray-200">
                                                                            <div className="flex flex-col w-full p-3 text-sm">
                                                                                <div className="flex items-center space-x-3 pb-2 w-full justify-between">
                                                                                    <h3 className="truncate text-sm font-bold text-purple-600">Thumbnail Analysis</h3>
                                                                                    <IoImageOutline />
                                                                                </div>
                                                                                {
                                                                                    thumbnailStatsLoaded
                                                                                        ?
                                                                                        <div className={`flex flex-col mt-1 w-full transition-opacity duration-1000 ${thumbnailStatsLoaded ? 'opacity-100' : 'opacity-0'}`}>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between'>
                                                                                                <span className='font-medium inline-flex text-gray-600'>Visual Clutter
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How messy or not the thumbnail feels. Too many elements makes it difficult to understand at a glance.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: thumbnailEvalState.visualComplexity.complexity === 3 ? highAccent : thumbnailEvalState.visualComplexity.complexity === 2 ? midAccent : lowAccent }}>
                                                                                                    {thumbnailEvalState.visualComplexity.complexity === 3 ? 'Low' : thumbnailEvalState.visualComplexity.complexity === 2 ? 'Average' : 'High'}
                                                                                                </span>
                                                                                            </span>

                                                                                            {
                                                                                                thumbnailEvalState.colorScience.colorBrightness !== 'Good' &&
                                                                                                (
                                                                                                    <>
                                                                                                        <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                            <span className='font-medium inline-flex text-gray-600'>Color Brightness
                                                                                                                <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                    </svg>
                                                                                                                    <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                        How bright the colors of the thumbnail are. Brighter thumbnails stand out more.
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                            <span className='font-bold' style={{ color: thumbnailEvalState.colorScience.colorBrightness === 3 ? highAccent : thumbnailEvalState.colorScience.colorBrightness === 2 ? midAccent : lowAccent }}>
                                                                                                                {thumbnailEvalState.colorScience.colorBrightness === 3 ? 'Bright' : thumbnailEvalState.colorScience.colorBrightness === 2 ? 'Mid' : 'Dull Colors'}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Eye-Catchiness
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How vibrant the colors in the thumbnail are & how easily eye-catching they are.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: thumbnailEvalState.colorScience.colorEyeCatchiness === 3 ? highAccent : thumbnailEvalState.colorScience.colorEyeCatchiness === 2 ? midAccent : lowAccent }}>
                                                                                                    {thumbnailEvalState.colorScience.colorEyeCatchiness === 3 ? 'High' : thumbnailEvalState.colorScience.colorEyeCatchiness === 2 ? 'Average' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Complexity
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How many competing concepts are in the same thumbnail. Fewer is better.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: thumbnailEvalState.visualConcepts.conceptCount < 4 && thumbnailEvalState.visualConcepts.conceptDifferences === 3 ? highAccent : thumbnailEvalState.visualConcepts.conceptCount < 4 && thumbnailEvalState.visualConcepts.conceptDifferences === 2 ? midAccent : lowAccent }}>
                                                                                                    {thumbnailEvalState.visualConcepts.conceptCount < 4 && thumbnailEvalState.visualConcepts.conceptDifferences === 3 ? 'Low' : thumbnailEvalState.visualConcepts.conceptCount < 4 && thumbnailEvalState.visualConcepts.conceptDifferences === 2 ? 'Mid' : 'High'}
                                                                                                </span>
                                                                                            </span>

                                                                                            {
                                                                                                thumbnailEvalState.textOnImage.style !== undefined &&
                                                                                                thumbnailEvalState.textOnImage.style !== null &&
                                                                                                thumbnailEvalState.textOnImage.style !== 'Bold' &&
                                                                                                (
                                                                                                    <>
                                                                                                        <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                            <span className='font-medium inline-flex text-gray-600'>Text Style
                                                                                                                <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                    </svg>
                                                                                                                    <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                        How bold or not the text on the image is. Fancy text is hard to read at a glance.
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                            <span className='font-bold' style={{ color: thumbnailEvalState.textOnImage.style === 3 ? highAccent : thumbnailEvalState.textOnImage.style === 2 ? midAccent : lowAccent }}>
                                                                                                                {thumbnailEvalState.textOnImage.style === 3 ? 'Bold' : thumbnailEvalState.textOnImage.style === 2 ? 'Mid' : 'Not Bold'}
                                                                                                            </span>

                                                                                                        </span>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                thumbnailEvalState.textOnImage.textColorContrast !== undefined &&
                                                                                                thumbnailEvalState.textOnImage.textColorContrast !== null &&
                                                                                                (
                                                                                                    <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                        <span className='font-medium inline-flex text-gray-600'>Text Color
                                                                                                            <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                </svg>
                                                                                                                <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                    How vibrant or contrasty the text color is. High contrast makes text easy to read.
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                        <span className='font-bold' style={{ color: thumbnailEvalState.textOnImage.textColorContrast === 3 ? highAccent : thumbnailEvalState.textOnImage.textColorContrast === 2 ? midAccent : lowAccent }}>
                                                                                                            {thumbnailEvalState.textOnImage.textColorContrast === 3 ? 'Visible' : thumbnailEvalState.textOnImage.textColorContrast === 2 ? 'Average' : 'Hard to See'}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                thumbnailEvalState.textOnImage.readabilityAgainstImage !== undefined &&
                                                                                                thumbnailEvalState.textOnImage.readabilityAgainstImage !== null &&
                                                                                                (
                                                                                                    <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                        <span className='font-medium inline-flex text-gray-600'>Text Readability
                                                                                                            <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                </svg>
                                                                                                                <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                    How easy it is for people to read the text on the image at a glance.
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                        <span className='font-bold' style={{ color: thumbnailEvalState.textOnImage.readabilityAgainstImage === 3 ? highAccent : thumbnailEvalState.textOnImage.readabilityAgainstImage === 2 ? midAccent : lowAccent }}>
                                                                                                            {thumbnailEvalState.textOnImage.readabilityAgainstImage === 3 ? 'Clear' : thumbnailEvalState.textOnImage.readabilityAgainstImage === 2 ? 'Average' : 'UnClear'}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                )
                                                                                            }


                                                                                            {
                                                                                                thumbnailEvalState.textOnImage.numberOfWords !== undefined &&
                                                                                                thumbnailEvalState.textOnImage.numberOfWords !== null &&
                                                                                                (
                                                                                                    <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                        <span className='font-medium inline-flex text-gray-600'>Length of Text
                                                                                                            <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                </svg>
                                                                                                                <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                    Measure of how many words appear on the image. More than 4 words is too long.
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                        <span className='font-bold' style={{ color: thumbnailEvalState.textOnImage.numberOfWords < 4 ? highAccent : thumbnailEvalState.textOnImage.numberOfWords < 6 ? midAccent : lowAccent }}>
                                                                                                            {thumbnailEvalState.textOnImage.numberOfWords < 4 ? 'Perfect' : thumbnailEvalState.textOnImage.numberOfWords < 6 ? 'Acceptable' : 'Too Long'}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                )
                                                                                            }

                                                                                            {isThumbnailAnalysisExpanded && (
                                                                                                <>
                                                                                                    <div className='mt-0 w-full'>
                                                                                                        <div className='mt-1 mr-2 w-full pt-4 px-3 text-[13px]'>
                                                                                                            <>
                                                                                                                {thumbnailEvalNotes?.map((note, index) => (
                                                                                                                    <li key={index} className="w-full mb-2 inline-flex items-center">
                                                                                                                        <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                        {note}
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </>
                                                                                            )}


                                                                                            {
                                                                                                thumbnailEvalNotes?.length > 0 &&
                                                                                                <div className='w-full text-center pt-2'>
                                                                                                    <button
                                                                                                        className="text-gray-500 text-[12px]"
                                                                                                        onClick={toggleThumbExpand}
                                                                                                    >
                                                                                                        {!isThumbnailAnalysisExpanded ? "What works" : "Hide"}
                                                                                                    </button>
                                                                                                </div>
                                                                                            }


                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                errorState === true
                                                                                                    ?
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <BiError className='w-12 h-12 text-yellow-500' />
                                                                                                        Failed.
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <GiProcessor className='w-12 h-12 animate-pulse text-gray-500' />
                                                                                                        <div className="flex h-2 w-32 mb-4  mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                                                            <div
                                                                                                                style={{ width: `${thumbnailProgress}%` }}
                                                                                                                className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`w-full lg:w-1/2 flex flex-col space-y-4 lg:pl-2 transition-all duration-500`}>
                                                                        <div className="w-full rounded-lg bg-white ring-1 ring-gray-200">
                                                                            <div className="flex flex-col w-full p-3 text-sm">

                                                                                <div className="flex items-center space-x-3 pb-2 w-full justify-between">
                                                                                    <h3 className="truncate text-sm font-bold text-purple-600">Attention Hijacking</h3>
                                                                                    <IoFlashOutline />
                                                                                </div>

                                                                                {
                                                                                    tnTSentimentLoaded
                                                                                        ?
                                                                                        <div className={`flex flex-col mt-1 w-full transition-opacity duration-1000 ${tnTSentimentLoaded ? 'opacity-100' : 'opacity-0'}`}>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between'>
                                                                                                <span className='font-medium inline-flex text-gray-600'>Simplicity of Idea
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How it is to understand what's going on at a glance.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSentimentState.simplicityOfIdea === 3 ? highAccent : tntSentimentState.simplicityOfIdea === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSentimentState.simplicityOfIdea === 3 ? 'High' : tntSentimentState.simplicityOfIdea === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Untold Story
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            Evaluation of if there is an untold story, which will be explained to the viewer when they click & watch.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSentimentState.untoldStory === 3 ? highAccent : tntSentimentState.untoldStory === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSentimentState.untoldStory === 3 ? 'High' : tntSentimentState.untoldStory === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            {
                                                                                                tntSentimentState.facesWithEmotion !== undefined &&
                                                                                                tntSentimentState.facesWithEmotion !== null &&
                                                                                                (
                                                                                                    <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                        <span className='font-medium inline-flex text-gray-600'>Face(s) with Emotion
                                                                                                            <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                                </svg>
                                                                                                                <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                                    How much emotion the face(s) in the image is able to evoke in the viewer
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                        <span className='font-bold' style={{ color: tntSentimentState.facesWithEmotion === 3 ? highAccent : tntSentimentState.facesWithEmotion === 2 ? midAccent : lowAccent }}>
                                                                                                            {tntSentimentState.facesWithEmotion === 3 ? 'High' : tntSentimentState.facesWithEmotion === 2 ? 'Mid' : 'Low'}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                )
                                                                                            }


                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Thumbnail Catchiness
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How much the thumbnail on its own, is able catch attention & cause the viewer to stop scrolling.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSentimentState.thumbnailStrength === 3 ? highAccent : tntSentimentState.thumbnailStrength === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSentimentState.thumbnailStrength === 3 ? 'High' : tntSentimentState.thumbnailStrength === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Title Strength
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How much the title makes the thumbnail make sense.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold' style={{ color: tntSentimentState.titleStrength === 3 ? highAccent : tntSentimentState.titleStrength === 2 ? midAccent : lowAccent }}>
                                                                                                    {tntSentimentState.titleStrength === 3 ? 'High' : tntSentimentState.titleStrength === 2 ? 'Mid' : 'Low'}
                                                                                                </span>
                                                                                            </span>

                                                                                            {isTntSentimentExpanded && (
                                                                                                <>
                                                                                                    <div className='mt-0 w-full'>
                                                                                                        <div className='mt-1 mr-2 w-full p-4'>
                                                                                                            <>
                                                                                                                {tntSentimentNotes?.map((note, index) => (
                                                                                                                    <li key={index} className="mb-2 inline-flex justify-start items-start text-[13px]">
                                                                                                                        <span className="text-2xl  text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                        {note}
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </>
                                                                                            )}

                                                                                            {
                                                                                                tntSentimentNotes?.length > 0 &&
                                                                                                <div className='w-full text-center pt-2'>
                                                                                                    <button
                                                                                                        className="text-gray-500 text-[12px]"
                                                                                                        onClick={toggleSentimentExpand}
                                                                                                    >
                                                                                                        {!isTntSentimentExpanded ? "What works" : "Hide"}
                                                                                                    </button>
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                errorState === true
                                                                                                    ?
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <BiError className='w-12 h-12 text-yellow-500' />
                                                                                                        Failed.
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <GiProcessor className='w-12 h-12 animate-pulse text-gray-500' />
                                                                                                        <div className="flex h-2 w-32 mb-4  mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                                                            <div
                                                                                                                style={{ width: `${sentProgress}%` }}
                                                                                                                className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full rounded-lg bg-white ring-1 ring-gray-200">
                                                                            <div className="flex flex-col w-full p-3 text-sm">

                                                                                <div className="flex items-center space-x-3 pb-2 w-full justify-between">
                                                                                    <h3 className="truncate text-sm font-bold text-purple-600">Title Analysis</h3>
                                                                                    <MdOutlineTextFields />
                                                                                </div>
                                                                                {
                                                                                    titleStatsLoaded
                                                                                        ?
                                                                                        <div className={`flex flex-col mt-2 w-full transition-opacity duration-1000 ${titleStatsLoaded ? 'opacity-100' : 'opacity-0'}`}>

                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Reading Difficulty
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5 ">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How easy it is for most people to read and understand the title
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold'
                                                                                                    style={{ color: titleEvalState.eval.readingDifficulty === 3 ? highAccent : titleEvalState.eval.readingDifficulty === 2 ? midAccent : lowAccent }}>
                                                                                                    {titleEvalState.eval.readingDifficulty === 3 ? 'Easy' : titleEvalState.eval.readingDifficulty === 2 ? 'Average' : 'Hard'}</span>
                                                                                            </span>
                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Title Complexity
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How complex or down-to-earth the title concept is made to seem.
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold'
                                                                                                    style={{ color: titleEvalState.eval.complexity === 3 ? highAccent : titleEvalState.eval.complexity === 2 ? midAccent : lowAccent }}>
                                                                                                    {titleEvalState.eval.complexity === 3 ? 'Simple' : titleEvalState.eval.complexity === 2 ? 'Average' : 'Complex'}</span>
                                                                                            </span>
                                                                                            <span className='border-b p-2 border-gray-200/90 w-full flex justify-between' >
                                                                                                <span className='font-medium inline-flex text-gray-600'>Conciseness
                                                                                                    <div className="group relative flex items-center ml-1 mt-0.5">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                                        </svg>
                                                                                                        <span className="absolute bottom-full mb-2 hidden group-hover:inline-block bg-gray-100 ring-1 ring-gray-400 text-gray-500 text-sm p-2 rounded-md shadow-lg w-48">
                                                                                                            How straight to the point the title is without unneccessary words
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <span className='font-bold'
                                                                                                    style={{ color: titleEvalState.eval.conciseness === 3 ? highAccent : titleEvalState.eval.conciseness === 2 ? midAccent : lowAccent }}>
                                                                                                    {titleEvalState.eval.conciseness === 3 ? 'High' : titleEvalState.eval.conciseness === 2 ? 'Average' : 'Low'}</span>
                                                                                            </span>

                                                                                            {isTitleAnalysisExpanded && (
                                                                                                <div className='mt-0 w-full'>
                                                                                                    <div className='mt-1 mr-2 w-full pt-4 px-3 text-[13px]'>
                                                                                                        <>
                                                                                                            {titleNotes?.map((note, index) => (
                                                                                                                <li key={index} className="w-full mb-2 inline-flex items-center">
                                                                                                                    <span className="text-2xl text-blue-400 mr-2 -mt-1">•</span>
                                                                                                                    {note}
                                                                                                                </li>
                                                                                                            ))}
                                                                                                        </>
                                                                                                    </div>

                                                                                                </div>
                                                                                            )}

                                                                                            {
                                                                                                titleNotes?.length > 0 &&
                                                                                                <div className='w-full text-center pt-2'>
                                                                                                    <button
                                                                                                        className="text-gray-500 text-[12px]"
                                                                                                        onClick={toggleExpand}
                                                                                                    >
                                                                                                        {!isTitleAnalysisExpanded ? "What works" : "Hide"}
                                                                                                    </button>
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                errorState === true
                                                                                                    ?
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <BiError className='w-12 h-12 text-yellow-500' />
                                                                                                        Failed.
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="flex flex-col items-center justify-center my-7">
                                                                                                        <GiProcessor className='w-12 h-12 animate-pulse text-gray-500' />
                                                                                                        <div className="flex h-2 w-32 mb-4  mt-3 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                                                            <div
                                                                                                                style={{ width: `${titleProgress}%` }}
                                                                                                                className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                                                            />
                                                                                                        </div>
                                                                                                        {/* Processing... */}
                                                                                                    </div>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="w-full flex flex-wrap justify-center">

                                                                            <div className="w-full lg:w-1/2 flex flex-col space-y-4 lg:pr-2 mb-4 lg:mb-0 transition-all duration-500">

                                                                            </div>

                                                                            <div className="w-full lg:w-1/2 flex flex-col space-y-4 lg:pl-2 transition-all duration-500">

                                                                            </div>
                                                                        </div> */}

                                                            </Tab.Panel>

                                                            <Tab.Panel className='w-full  pt-2 flex flex-wrap justify-start px-1 py-3'>

                                                                {
                                                                    channelDataFound === true
                                                                        ?
                                                                        <div className="w-full min-h-[250px] flex flex-col text-gray-500">

                                                                            {predictedViews !== null ? (
                                                                                <div className="w-full flex space-x-4 p-4 bg-white rounded-lg border border-blue-400">
                                                                                    <div className="flex-1 pr-4">
                                                                                        <h2 className="text-base font-semibold text-gray-700">Your Views Baseline</h2>
                                                                                        <div className="flex items-baseline mt-2">
                                                                                            <span className="text-3xl font-bold text-blue-600">{expectedViews.toLocaleString()}</span>
                                                                                        </div>
                                                                                        <p className="mt-1 text-sm text-gray-500">Based on your last {videosExtractedCount} videos</p>
                                                                                    </div>
                                                                                    <div className="flex-1">
                                                                                        <h2 className="text-base font-semibold text-gray-700">Views Prediction</h2>
                                                                                        <div className="flex items-baseline mt-2">
                                                                                            <span className="text-3xl font-bold text-blue-600">{predictedViews.toLocaleString()}</span>
                                                                                            {
                                                                                                predictedViews > expectedViews
                                                                                                    ?
                                                                                                    <span className="ml-2 text-sm font-medium text-green-600">+{Math.round(((predictedViews - expectedViews) / expectedViews) * 100)} %</span>
                                                                                                    :
                                                                                                    <span className="ml-2 text-sm font-medium text-red-600">{Math.round(((predictedViews - expectedViews) / expectedViews) * 100)} %</span>
                                                                                            }


                                                                                        </div>
                                                                                        <p className="mt-1 text-sm text-gra[y-500">Based on VB and CVP data</p>
                                                                                    </div>
                                                                                    <div className="flex-1 ">
                                                                                        <ViewsTrendIcon predictedViews={predictedViews} expectedViews={expectedViews} />
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <p>Calculating prediction...</p>
                                                                            )}
                                                                            <div className="inline-flex p-3 pt-0 mt-3">
                                                                                <GiProcessor className='text-gray-500' />
                                                                                <p className="text-xs ml-1 text-gray-500"> There are lots of other factors that can affect how many views a video will get. These predictions are estimates based on calculations from limited data. Hence, there's no way to know for sure. Proceed accordingly.</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="w-full flex flex-col items-center justify-center py-8 bg-white rounded-lg border border-blue-400">
                                                                            <BsExclamationTriangle className='w-12 h-12 text-orange-500' />
                                                                            <span className="mt-1 text-center text-sm">Insufficient data from your channel. <br />Could not perform prediction.</span>
                                                                        </div>
                                                                }



                                                            </Tab.Panel>

                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="relative w-full pr-4 lg:px-6 xl:w-4/12 transition-all duration-500">

                                            <div className="">
                                                <div className="transition-all duration-500">
                                                    <div className="aspect-w-16 aspect-h-9 lg:mt-5">
                                                        <div className="w-full max-w-[360px] block overflow-hidden rounded-lg">
                                                            <img
                                                                src={thumbnail} alt=""
                                                                className="object-cover aspect-video"
                                                            />
                                                        </div>
                                                        <div className='flex mt-3'>
                                                            <div className="shrink-0 mx-0 my-0 mr-2 md:mr-2 xl:mr-2 h-11 w-11 object-cover">
                                                                <img className='rounded-full h-11 w-11 ' src={channelPhotoUrl} alt="" />
                                                            </div>
                                                            <div className='flex-col w-10/12 mr-2 md:mr-2 xl:mr-2'>
                                                                <div className="flex flex-col mb-0 leading-none line-clamp-2 font-sans items-center justify-center tracking-normal">
                                                                    <p className='font-roboto items-center leading-4 tracking-normal mr-3 
                                                                font-medium lg:text-[15px] mb:text-mb text-[#0f0f0f]'>{title}</p>
                                                                </div>
                                                                <div className=''>
                                                                    <div className='inline-flex text-[1px] font-roboto lg:text-[13px] text-[#606060]'>
                                                                        <p className='font-[400]'>{channelDisplayName}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    {
                                                        overallVPScore >= 80 &&
                                                        <div className='max-w-[360px] mt-8'>
                                                            {/* <Hint hintText={'If your Viral Potential score is above 90, your thumbnail may generally be expected to perform well. However the issues detected may also be of some importance. Evaluate and refine as you see fit.'} fontSizePx={'13px'} /> */}
                                                            <Hint hintText={'If your thumbnail is not performing well, the concept may not be a good fit for your audience. Even with a high VP score. In such a case, you may need to change the concept. Please evaluate and refine as you see fit.'} fontSizePx={'13px'} />
                                                        </div>
                                                    }
                                                    {/* {
                                                        advisory &&
                                                        (
                                                            <div className="w-full max-w-[360px] mt-8 p-4 border rounded-lg">
                                                                <div className="w-full flex flex-col text-base font-bold">{predictionAdvisory}</div>
                                                                <div className="w-full flex flex-col text-[13px] items-center justify-center">{predictionAdvisoryExplanation}</div>
                                                            </div>
                                                        )
                                                    } */}
                                                </div>

                                                <div className="pt-4 transition-all duration-500">
                                                    <div className="lg:mt-9 w-80 space-y-4">
                                                        <div className="w-full flex flex-col space-y-4 transition-all duration-500 ">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            errorState === false
                                                ?
                                                <div className='w-full h-full flex flex-col items-center justify-center'>
                                                    <div className="flex flex-col items-center justify-center mt-32">
                                                        {
                                                            loadingMessage == 'Processing...'
                                                                ?
                                                                <GiProcessor className='w-24 h-24 animate-pulse text-gray-500' />
                                                                :
                                                                <Lottie className='w-40 h-40' animationData={image_analyze} loop={true} />
                                                        }
                                                        <p>{loadingMessage}</p>
                                                        <div className="relative pt-1">
                                                            <div className="flex h-2 w-32 mb-4 rounded-sm overflow-hidden text-xs bg-blue-200">
                                                                <div
                                                                    style={{ width: `${progress}%` }}
                                                                    className="flex flex-col justify-center rounded-sm text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='w-full flex flex-col items-center justify-center mt-4'>
                                                    <div className='w-72 h-72'>
                                                        <Lottie animationData={error} loop={true} className='w-72 h-72' />
                                                    </div>
                                                    {errorMessage !== '' ? (
                                                        <div className='max-w-2xl p-2 ring-1 ring-blue-400 rounded-md flex flex-col items-center justify-center'>
                                                            <div className="text-[13px]">{errorMessage}</div>
                                                        </div>
                                                    ) : (
                                                        <h2 className=' font-normal text-[14px] mb-5 text-gray-500 flex flex-col items-center justify-center'>
                                                            Something went wrong... Upload image again or try again later.
                                                        </h2>
                                                    )}
                                                    {/* <div className='max-w-2xl mt-4'>
                                                        <Hint hintText={'The AI may at times not respond correctly to uploaded images. This may sometimes be due to the content of the image being restricted or some other miscellanious error. Kindly retry later or choose a different image. Your AI credits were not deducted for this request.'} fontSizePx={'13px'} />
                                                    </div> */}

                                                </div>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                finishedAnalysis &&
                <FeedbackBanner currentPageUrl={'analysis'} />
            }
        </>
    )
}

export default AnalysisDetails



